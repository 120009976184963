// generated by the plop

import { MatterExtended } from '@app/models/matter-extended';
import { PROGRESS_STATUSES } from '@app/shared/symbols';
import { Paginator } from '@app/models/paginator';

export interface MainMatterStateModel {
  matterExtended: Paginator<MatterExtended>;
  loadMatterExtendedStatus: PROGRESS_STATUSES;
}

export const MAIN_MATTER_STATE_DEFAULT: MainMatterStateModel = {
  matterExtended: null,
  loadMatterExtendedStatus: PROGRESS_STATUSES.NOT_INITIALIZED
};
