import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ErrorResponse, HttpErrorHandlerService } from '@app/helpers/http-error-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private httpErrorHandler: HttpErrorHandlerService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') {
      // Якщо це JSONP, просто пропускаємо його далі без будь-яких модифікацій
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 204 /*&& request.url.includes('api')*/) {
          const body = event.body;

          if (body.success === false && body.errors.length > 0) {
            console.log([request.url, body]);
            this.httpErrorHandler.handle(body as ErrorResponse);
          }
          if (body['@type'] === 'hydra:Error') {
            this.httpErrorHandler.handle(body as ErrorResponse);
          }
        }
        return event;
      }),
      catchError(err => {
        if (err.status === 401) {
        }

        return throwError(err);
      }),
    );
  }
}
