// generated by the plop

import { Paginator } from '@app/models/paginator';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { BankAccountsTotal } from '@app/models/interfaces/bankAccounts-total';
import { PROGRESS_STATUSES } from '@app/shared/symbols';

export interface BankAccountStateModel {
  paginator: Paginator<BankAccountExtended>;
  bankAccountsByContact: BankAccountExtended[];
  bankAccountsByCurrency: BankAccountExtended[];
  selectedBankAccount: BankAccountExtended;
  bankAccountTotal: BankAccountsTotal;
  loadBankAccountsStatus: PROGRESS_STATUSES;
  loadBankAccountsByCurrencyStatus: PROGRESS_STATUSES;
}

export const BANK_ACCOUNT_STATE_DEFAULT: BankAccountStateModel = {
  paginator: null,
  bankAccountsByContact: [],
  bankAccountsByCurrency: [],
  selectedBankAccount: null,
  bankAccountTotal: null,
  loadBankAccountsStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
  loadBankAccountsByCurrencyStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
};
