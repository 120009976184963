import { IAppState } from '@app/store/state/app.state';
import { Paginator } from '@app/models/paginator';
import { IUser } from '@app/models/IUser';

export const selectTeamMemberList = (state: IAppState): IUser[] => Object.values(state.teamMemberState.members);
export const selectTeamMemberPagination = (state: IAppState): Paginator<IUser> => state.teamMemberState.paginator;
export const selectTeamMemberFromStore = (state: IAppState): IUser => state.teamMemberState.members[state.teamMemberState.selected];
export const invitedTeamMember = (state: IAppState) => state.teamMemberState.invited;

export const selectListUsersForSelect = (state: IAppState) => state.teamMemberState.listUsersForSelect;

export const selectReportsListUsersForSelect = (state: IAppState) => state.teamMemberState.listAllUsersForReportsSelect;

export const selectAllListUsersForSelect = (state: IAppState) => state.teamMemberState.listAllUsersForSelect;

export const selectListUsersForEntitySelect = (state: IAppState) => state.teamMemberState.listUsersForEntitySelect;

export const selectAllListAdministratorsAndAccountsForSelect = (state: IAppState) =>
  state.teamMemberState.listAllAdministratorsAndAccountsForSelect;

export const selectCountries = (state: IAppState) => state.teamMemberState.countries;
export const updateUserStatus = (state: IAppState) => state.teamMemberState.updateUserStatus;
