// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-line:hover .custom-tooltip-container {
  opacity: 1;
}

.bar-line .custom-tooltip-container .tooltip-wrapper {
  width: auto;
}

.permission-container {
  padding: 15px 11px;
}
.permission-container .no-permissions {
  width: 100%;
}
.permission-container .no-permissions .permissions-placeholder {
  padding: 142px 0 118px;
}
.permission-container .no-permissions .permissions-placeholder span {
  display: block;
  font-size: 34px;
  color: #1252af;
  text-align: center;
  margin-bottom: 11px;
}
.permission-container .no-permissions .permissions-placeholder p {
  color: #1252af;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}
.permission-container .no-permissions .learn-more-text {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  background-color: #e5efff;
  padding: 6px 7px;
}
.permission-container .no-permissions .learn-more-text span {
  font-size: 21px;
  color: #1252af;
  margin-right: 9px;
}
.permission-container .no-permissions .learn-more-text p {
  font-size: 14px;
  color: #000000;
  margin: 0;
}
.permission-container .no-permissions .learn-more-text p a {
  color: #1252af;
  text-decoration: underline;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/permission-block/permission-block.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;AACE;EACE,WAAA;AACJ;AACI;EACE,sBAAA;AACN;AACM;EACE,cAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACR;AAEM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,kBAAA;AAAR;AAII;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AAFN;AAIM;EACE,eAAA;EACA,cAAA;EACA,iBAAA;AAFR;AAKM;EACE,eAAA;EACA,cAAA;EACA,SAAA;AAHR;AAKQ;EACE,cAAA;EACA,0BAAA;EACA,iBAAA;AAHV","sourcesContent":[".bar-line:hover {\n  .custom-tooltip-container {\n    opacity: 1;\n  }\n}\n.bar-line .custom-tooltip-container .tooltip-wrapper {\n  width: auto;\n}\n\n.permission-container {\n  padding: 15px 11px;\n  .no-permissions {\n    width: 100%;\n\n    .permissions-placeholder {\n      padding: 142px 0 118px;\n\n      span {\n        display: block;\n        font-size: 34px;\n        color: #1252af;\n        text-align: center;\n        margin-bottom: 11px;\n      }\n\n      p {\n        color: #1252af;\n        font-size: 15px;\n        font-weight: 600;\n        margin: 0;\n        text-align: center;\n      }\n    }\n\n    .learn-more-text {\n      display: flex;\n      align-items: center;\n      width: fit-content;\n      border-radius: 3px;\n      background-color: #e5efff;\n      padding: 6px 7px;\n\n      span {\n        font-size: 21px;\n        color: #1252af;\n        margin-right: 9px;\n      }\n\n      p {\n        font-size: 14px;\n        color: #000000;\n        margin: 0;\n\n        a {\n          color: #1252af;\n          text-decoration: underline;\n          font-weight: bold;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
