// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kanban-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  background-color: #fff;
  margin-left: 10px;
  padding: 0;
  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.031372549);
  border: 1px solid #eaeef2;
}
.kanban-button,
.kanban-button i {
  transition: 0.3s ease;
}
.kanban-button i {
  display: block;
  color: #858B98;
}
.kanban-button i.j2-icon-kanban-to-kanban {
  font-size: 12px;
}
.kanban-button i.j2-icon-kanban-to-table {
  font-size: 10px;
}
.kanban-button:hover {
  background-color: #FCFCFC;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
}
.kanban-button:hover i {
  color: #1252af;
}

.table-wrapper-view {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/reusable/tasks/components/tasks/tasks.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,UAAA;EACA,eAAA;EACA,kDAAA;EACA,yBAAA;AACF;AACE;;EAEE,qBAAA;AACJ;AAEE;EACE,cAAA;EACA,cAAA;AAAJ;AAEI;EACE,eAAA;AAAN;AAGI;EACE,eAAA;AADN;AAKE;EACE,yBAAA;EACA,wCAAA;AAHJ;AAKI;EACE,cAAA;AAHN;;AAQA;EACE,YAAA;AALF","sourcesContent":[".kanban-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 28px;\n  height: 28px;\n  border-radius: 6px;\n  background-color: #fff;\n  margin-left: 10px;\n  padding: 0;\n  cursor: pointer;\n  box-shadow: 0px 1px 1px #00000008;\n  border: 1px solid #eaeef2;\n\n  &,\n  i {\n    transition: 0.3s ease;\n  }\n\n  i {\n    display: block;\n    color: #858B98;\n\n    &.j2-icon-kanban-to-kanban {\n      font-size: 12px;\n    }\n\n    &.j2-icon-kanban-to-table {\n      font-size: 10px;\n    }\n  }\n\n  &:hover {\n    background-color: #FCFCFC;\n    box-shadow: 0px 1px 1px #00000000;\n\n    i {\n      color: #1252af;\n    }\n  }\n}\n\n.table-wrapper-view {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
