import { TranslateService } from '@ngx-translate/core';

export interface IEnumType {
  value: string;
  name: string;
}

export class SharedEnumService {
  constructor(private translate: TranslateService) {}

  enumTypeFee: IEnumType[] = [
    { value: 'fixed', name: this.translate.instant('fixed') },
    { value: 'hourly', name: this.translate.instant('hourly') }
  ];
  enumTypePay: IEnumType[] = [
    { value: 'prepay', name: this.translate.instant('prepay') },
    { value: 'postpay', name: this.translate.instant('postpay') }
  ];
  enumMatterStatus: IEnumType[] = [
    { value: 'open', name: this.translate.instant('matter.open') },
    { value: 'pending', name: this.translate.instant('matter.pending') },
    { value: 'closed', name: this.translate.instant('matter.closed') }
  ];
  enumLang: IEnumType[] = [
    { value: 'uk', name: this.translate.instant('uk') },
    { value: 'en', name: this.translate.instant('en') },
    { value: 'pl', name: this.translate.instant('pl') },
    { value: 'cs', name: this.translate.instant('cs') },
    { value: 'fr', name: this.translate.instant('fr') },
    { value: 'es', name: this.translate.instant('es') },
    { value: 'de', name: this.translate.instant('de') }
  ];
  enumTypeMoneyTime: IEnumType[] = [
    { value: 'money', name: this.translate.instant('money') },
    { value: 'time', name: this.translate.instant('time') }
  ];
  enumTaskType: IEnumType[] = [
    { value: 'event', name: this.translate.instant('event') },
    { value: 'task', name: this.translate.instant('task') }
  ];
  enumTaskStatus: IEnumType[] = [
    { value: 'new', name: this.translate.instant('new') },
    { value: 'in_work', name: this.translate.instant('in_work') },
    { value: 'cancel', name: this.translate.instant('cancel') },
    { value: 'wait', name: this.translate.instant('wait') },
    { value: 'done', name: this.translate.instant('done') }
  ];
  enumTemplateType: IEnumType[] = [
    { value: 'contact', name: this.translate.instant('contact') },
    { value: 'contract', name: this.translate.instant('contract') },
    { value: 'matter', name: this.translate.instant('matter') },
    { value: 'bill', name: this.translate.instant('bill') }
  ];
  enumNotificationSettings: IEnumType[] = [
    { value: 'all_on', name: this.translate.instant('employee.notificationSettings.dropdown.all_on') },
    { value: 'all_off', name: this.translate.instant('employee.notificationSettings.dropdown.all_off') },
    { value: 'only_system', name: this.translate.instant('employee.notificationSettings.dropdown.only_system') }
  ];
  enumActivitiesBillStatus: IEnumType[] = [
    { value: 'none', name: this.translate.instant('activities.filter.status.none') },
    { value: 'billed', name: this.translate.instant('activities.filter.status.billed') },
    { value: 'unbilled', name: this.translate.instant('activities.filter.status.un_billed') }
  ];
  enumExpenses: IEnumType[] = [
    { value: 'not_approve', name: this.translate.instant('expense.not_approve') },
    { value: 'approve', name: this.translate.instant('expense.approve') },
    { value: 'submit', name: this.translate.instant('expense.submit') }
  ];

}
