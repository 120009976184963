// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container .weak-password-container {
  display: none;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}
.input-container .weak-password-container span {
  border-radius: 3px;
  background-color: #ebebeb;
  color: #db9330;
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
  padding: 1px 15px 2px;
}
.input-container.password-weak .weak-password-container {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/password-change-modal/password-change-modal.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AAAN;AAKI;EACE,cAAA;AAHN","sourcesContent":[".input-container {\n  .weak-password-container {\n    display: none;\n    color: #222222;\n    font-size: 14px;\n    font-weight: 400;\n    margin-bottom: 8px;\n\n    span {\n      border-radius: 3px;\n      background-color: #ebebeb;\n      color: #db9330;\n      font-size: 14px;\n      font-weight: 600;\n      margin-right: 6px;\n      padding: 1px 15px 2px;\n    }\n  }\n\n  &.password-weak {\n    .weak-password-container {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
