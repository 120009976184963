import { Action } from '@ngrx/store';
import { CompanySettings, CompanySubSettings } from '@app/models/company-settings';
import { SubscriptionSub } from '@app/models/subscription-sub';
import { SubTariff } from '@app/models/sub-tariff';
import { Tariff } from '@app/models/tariff';

export enum CompanySettingActionTypes {
  LOAD_COMPANY_SETTING = '[COMPANY_SETTING] Load CompanySetting',
  LOAD_COMPANY_SETTING_SUCCESS = '[COMPANY_SETTING] Load CompanySetting Success',
  LOAD_COMPANY_SETTING_FAILURE = '[COMPANY_SETTING] Load CompanySetting Failure',
  UPDATE_COMPANY_SETTING = '[COMPANY_SETTING] Update CompanySetting Category',
  UPDATE_COMPANY_SETTING_SUCCESS = '[COMPANY_SETTING] Update CompanySetting Category Success',
  UPDATE_COMPANY_SETTING_FAILURE = '[COMPANY_SETTING] Update CompanySetting Category Failure',
  LOAD_SUBSCRIPTIONS_SUBS = '[COMPANY_SETTING] Load Subscription Subs',
  LOAD_SUBSCRIPTIONS_SUBS_SUCCESS = '[COMPANY_SETTING] Load Subscription Subs Success',
  LOAD_COMPANY_SUB_SETTING = '[COMPANY_SETTING] Load Company Sub Settings',
  LOAD_COMPANY_SUB_SETTING_SUCCESS = '[COMPANY_SETTING] Load Company Sub Settings Success',
  LOAD_SUB_TARIFF = '[COMPANY_SETTING] Load Sub Tariff',
  LOAD_SUB_TARIFF_SUCCESS = '[COMPANY_SETTING] Load Sub Tariff Success',
  LOAD_TARIFF = '[COMPANY_SETTING] Load Tariff',
  LOAD_TARIFF_SUCCESS = '[COMPANY_SETTING] Load Tariff Success',
  LOAD_SUBSCRIBED_SUBSCRIPTION_SUB = '[COMPANY_SETTING] Load Subscribed Subscription',
  LOAD_SUBSCRIBED_SUBSCRIPTION_SUB_SUCCESS = '[COMPANY_SETTING] Load Subscribed Subscription Success'
}

export class LoadCompanySettingAction implements Action {
  readonly type = CompanySettingActionTypes.LOAD_COMPANY_SETTING;
}

export class LoadCompanySettingSuccessAction implements Action {
  readonly type = CompanySettingActionTypes.LOAD_COMPANY_SETTING_SUCCESS;

  constructor(public payload: CompanySettings) {}
}

export class LoadCompanySettingFailureAction implements Action {
  readonly type = CompanySettingActionTypes.LOAD_COMPANY_SETTING_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadSubscriptionSubs implements Action {
  readonly type = CompanySettingActionTypes.LOAD_SUBSCRIPTIONS_SUBS;
}

export class LoadSubscriptionSubSuccess implements Action {
  readonly type = CompanySettingActionTypes.LOAD_SUBSCRIPTIONS_SUBS_SUCCESS;

  constructor(public payload: SubscriptionSub[]) {}
}

export class LoadCompanySubSetting implements Action {
  readonly type = CompanySettingActionTypes.LOAD_COMPANY_SUB_SETTING;
}

export class LoadCompanySubSettingSuccess implements Action {
  readonly type = CompanySettingActionTypes.LOAD_COMPANY_SUB_SETTING_SUCCESS;

  constructor(public payload: CompanySubSettings[]) {}
}


export class UpdateCompanySettingAction implements Action {
  readonly type = CompanySettingActionTypes.UPDATE_COMPANY_SETTING;

  constructor(public payload: CompanySettings) {}
}

export class UpdateCompanySettingSuccessAction implements Action {
  readonly type = CompanySettingActionTypes.UPDATE_COMPANY_SETTING_SUCCESS;

  constructor(public payload: CompanySettings) {}
}

export class UpdateCompanySettingFailureAction implements Action {
  readonly type = CompanySettingActionTypes.UPDATE_COMPANY_SETTING_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadSubTariff implements Action {
  readonly type = CompanySettingActionTypes.LOAD_SUB_TARIFF;

  constructor(public payload?: string) {}
}

export class LoadSubTariffSuccess implements Action {
  readonly type = CompanySettingActionTypes.LOAD_SUB_TARIFF_SUCCESS;

  constructor(public payload: SubTariff[], public currency: string) {}
}

export class LoadTariff implements Action {
  readonly type = CompanySettingActionTypes.LOAD_TARIFF;

  constructor(public payload?: string) {}
}

export class LoadTariffSuccess implements Action {
  readonly type = CompanySettingActionTypes.LOAD_TARIFF_SUCCESS;

  constructor(public payload: Tariff[]) {}
}

export class LoadSubscribedSubscription implements Action {
  readonly type = CompanySettingActionTypes.LOAD_SUBSCRIBED_SUBSCRIPTION_SUB;

  constructor() {}
}

export class LoadSubscribedSubscriptionSuccess implements Action {
  readonly type = CompanySettingActionTypes.LOAD_SUBSCRIBED_SUBSCRIPTION_SUB_SUCCESS;

  constructor(public payload: SubscriptionSub[]) {}
}

export type CompanySettingAction =
  | LoadCompanySettingAction
  | LoadCompanySettingSuccessAction
  | LoadCompanySettingFailureAction
  | UpdateCompanySettingAction
  | UpdateCompanySettingSuccessAction
  | UpdateCompanySettingFailureAction
  | LoadSubscriptionSubs
  | LoadSubscriptionSubSuccess
  | LoadCompanySubSetting
  | LoadCompanySubSettingSuccess
  | LoadSubTariff
  | LoadSubTariffSuccess
  | LoadTariff
  | LoadTariffSuccess
  | LoadSubscribedSubscription
  | LoadSubscribedSubscriptionSuccess
  ;
