import { Component, OnInit, Input } from '@angular/core';
import { Note } from '@app/models/note';
import { Contact } from '@app/models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { SelectNote, DeleteNote } from '@app/store/actions/note.actions';
import { DisplayNoteModalComponent } from '@app/commonComponents/modals/other-modals/display-note-modal/display-note-modal.component';
import { NoteModalComponent } from '@app/commonComponents/modals/default-modals/note-modal/note-modal.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { selectContactList } from '@app/modules/contacts/store/selectors/contact.selector';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input() note: Note;
  contacts: Contact[];
  dateFormat = CustomDateAdapter.currentDateFormat;

  constructor(
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private router: Router
  ) {
    this.loadContacts();
  }
  ngOnInit() {}

  displayNoteModal() {
    this.store.dispatch(new SelectNote(this.note));

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'single-note-modal'];
    this.dialog.open(DisplayNoteModalComponent, dialogConfig);
  }

  onEdit() {
    this.store.dispatch(new SelectNote(this.note));

    this.noteModal();
  }
  noteModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { matter: this.note.matter };
    dialogConfig.data = { ...dialogConfig.data, contact: this.note.contact };
    dialogConfig.panelClass = ['default-mat-dialog', 'note-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(NoteModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }
  onDelete(note: Note) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteTxt'
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DeleteNote(note.id));
      } else {
        dialogRef.close();
      }
    });
  }
  loadContacts() {
    this.store.select(selectContactList).subscribe(data => {
      this.contacts = data;
    });
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
  goToWorker(user) {
    this.router.navigateByUrl(`/worker/${user.id}`).then(r => '/');
  }

  linkTransform(str) {
    return Helpers.linkTransform(str);
  }
}
