import { Paginator } from '../../../../models/paginator';

import { TimeEntryType } from '../../../../models/index';

export interface ITimeEntryTypeState {
  paginator: Paginator<TimeEntryType>;
  selectedTimeEntryType: TimeEntryType;
  list: {
    success: boolean;
    data: Array<TimeEntryType>;
  };
  loading: boolean;
  error: Error;
}

export const initialTimeEntryTypeState: ITimeEntryTypeState = {
  paginator: null,
  selectedTimeEntryType: null,
  list: null,
  loading: false,
  error: undefined
};
