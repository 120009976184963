import { IAppState } from '../state/app.state';
import { MatterExtended } from '@app/models/matter-extended';

export const selectMattersPaginator = (state: IAppState) => state.matterState.paginator;
export const selectMattersList = (state: IAppState): MatterExtended[] => state.matterState.paginator.member;

// export const selectSelectedMatter = (state: IAppState) => state.matterState.selectedMatter;

export const selectMatterListForSelect = (state: IAppState): MatterExtended[] => state.matterState.listMatterForSelect;

export const selectAllMatterListForSelect = (state: IAppState): MatterExtended[] => state.matterState.listAllMatterForSelect;

export const SelectCreateMatter = (state: IAppState) => state.matterState.create;
export const SelectTrackContactMatter = (state: IAppState) => state.matterState.trackContactMatter.contact;

export const SelectTrackWorkerMatter = (state: IAppState) => state.matterState.trackContactMatter.responsible;
