// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-select-field .left-item {
  max-width: calc(100% - 88px);
  width: 100%;
}
.text-select-field .right-item {
  width: 88px;
}

.custom-tooltip-activator .j2-icon-info {
  margin: 0 0 3px 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/tax-create-modal/tax-create-modal.component.scss"],"names":[],"mappings":"AACE;EACE,4BAAA;EACA,WAAA;AAAJ;AAGE;EACE,WAAA;AADJ;;AAME;EACE,mBAAA;AAHJ","sourcesContent":[".text-select-field {\n  .left-item {\n    max-width: calc(100% - 88px);\n    width: 100%;\n  }\n\n  .right-item {\n    width: 88px;\n  }\n}\n\n.custom-tooltip-activator {\n  .j2-icon-info {\n    margin: 0 0 3px 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
