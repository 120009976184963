// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  min-width: 500px;
  max-width: 600px;
}
.modal-container .input-container {
  margin-top: 15px;
}

.modal-header {
  height: 47px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
}
.modal-header h3 {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0 20px;
}

.modal-body {
  padding: 5px 20px 8px 20px;
}
.modal-body p {
  color: #222222;
  font-size: 14px;
  margin: 0;
}

.modal-footer .button-container {
  display: flex;
  justify-content: flex-end;
}
.modal-footer .button-container button {
  min-width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/other-modals/create-automation-modal/create-automation-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gCAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,eAAA;AACJ;;AAGA;EACE,0BAAA;AAAF;AAEE;EACE,cAAA;EACA,eAAA;EACA,SAAA;AAAJ;;AAIA;EACE,aAAA;EACA,yBAAA;AADF;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".modal-container {\n  min-width: 500px;\n  max-width: 600px;\n  .input-container {\n    margin-top: 15px;\n  }\n}\n\n.modal-header {\n  height: 47px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #ededed;\n\n  h3 {\n    color: #222222;\n    font-size: 18px;\n    font-weight: 600;\n    margin: 0;\n    padding: 0 20px;\n  }\n}\n\n.modal-body {\n  padding: 5px 20px 8px 20px;\n\n  p {\n    color: #222222;\n    font-size: 14px;\n    margin: 0;\n  }\n}\n\n.modal-footer .button-container {\n  display: flex;\n  justify-content: flex-end;\n  button {\n    min-width: 120px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
