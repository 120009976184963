import { Injectable } from '@angular/core';
import { MediaExtended } from '@app/models/media-extended';
import { environment } from '@environments/environment';
import { Observable, ReplaySubject, map, mergeMap, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const DEFAULT_MEDIA_TYPE = 'avatar';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(private http: HttpClient) {
  }

  getMediaById(id: number): Observable<MediaExtended> {
    return this.http.get<MediaExtended>(`${environment.apiUrl}/media/${id}`);
  }

  addMedia(media: File, type?: string): Observable<MediaExtended> {
    const formData = {
      'type': type || DEFAULT_MEDIA_TYPE,
      'file': ''
    };
    return this.convertFile(media).pipe(
      switchMap(str => {
        formData.file = str;
        return this.http.post<MediaExtended>(`${environment.apiUrl}/media`, formData);

      })
    );
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

}
