import { INotification } from '@app/models/interfaces/notifications.interface';

export interface INotificationState {
  success: boolean;
  data: INotification[];
  countUnRead: number;
  page: number;
}

export const initialNotificationState: INotificationState = {
  success: false,
  data: [],
  countUnRead: 0,
  page: 1
};
