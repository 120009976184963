// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-template .modal-container {
  width: 540px;
  height: 100%;
}
.modal-template .modal-header-not-available {
  position: relative;
  height: 311px;
  background-image: url("/assets/images/modal-not-available.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.modal-template .modal-body-not-available {
  padding: 45px 38px 17px;
}
.modal-template .modal-body-not-available h2 {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 13px;
}
.modal-template .modal-body-not-available p {
  color: #637283;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 42px;
  max-width: 410px;
}
.modal-template .modal-body-not-available p a {
  color: #1252af;
  text-decoration: underline;
}
.modal-template .modal-body-not-available p.learn-text {
  margin: 0 auto;
}
.modal-template .modal-body-not-available button.btn-template.dropdown-button.btn-blue {
  min-width: 260px;
  margin: 0 auto 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/system-modals/not-available-modal/not-available-modal.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,YAAA;AAAJ;AAGE;EACE,kBAAA;EACA,aAAA;EACA,+DAAA;EACA,2BAAA;EACA,sBAAA;EACA,4BAAA;AADJ;AAIE;EACE,uBAAA;AAFJ;AAII;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAFN;AAKI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AAHN;AAKM;EACE,cAAA;EACA,0BAAA;AAHR;AAMM;EACE,cAAA;AAJR;AAQI;EACE,gBAAA;EACA,mBAAA;AANN","sourcesContent":[".modal-template {\n  .modal-container {\n    width: 540px;\n    height: 100%;\n  }\n\n  .modal-header-not-available {\n    position: relative;\n    height: 311px;\n    background-image: url('/assets/images/modal-not-available.png');\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n\n  .modal-body-not-available {\n    padding: 45px 38px 17px;\n\n    h2 {\n      color: #222222;\n      font-size: 18px;\n      font-weight: 600;\n      text-align: center;\n      margin: 0 0 13px;\n    }\n\n    p {\n      color: #637283;\n      font-size: 14px;\n      font-weight: 400;\n      text-align: center;\n      margin: 0 auto 42px;\n      max-width: 410px;\n\n      a {\n        color: #1252af;\n        text-decoration: underline;\n      }\n\n      &.learn-text {\n        margin: 0 auto;\n      }\n    }\n\n    button.btn-template.dropdown-button.btn-blue {\n      min-width: 260px;\n      margin: 0 auto 13px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
