import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NewsComponent } from './news/news.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { TimekeeperComponent } from './timekeeper/timekeeper.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { PerfectScrollbarModule } from '@app/perfect-scrollbar/perfect-scrollbar.module';
import { SharedModule } from '@app/shared/shared.modules';
import { LoaderModule } from '@app/commonComponents/loader/loader.module';
import { SanitizeHtmlPipe } from '@app/modules/layout/notifications/pipes/sanitizeHtml.pipe';
import { NewsService } from '@app/modules/layout/news/services/news.service';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { PaginationModule } from '@app/commonComponents/pagination/pagination.module';
import { SwitchLangComponent } from './switch-lang/switch-lang.component';
import { ColumnsModule } from '@app/shared/columns/columns.module';
import { CommonsModule } from '@app/shared/common/commons.module';
import { NewMessageCommunicationComponent } from './new-message-communication/new-message-communication.component';
import { TimekeeperService } from '@app/modules/layout/timekeeper/timekeeper.service';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    NotificationsComponent,
    NewsComponent,
    NotificationPopupComponent,
    TimekeeperComponent,
    SanitizeHtmlPipe,
    SwitchLangComponent,
    NewMessageCommunicationComponent,
  ],
  exports: [HeaderComponent, SidebarComponent, SanitizeHtmlPipe, SwitchLangComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    PerfectScrollbarModule,
    CalendarModule,
    SharedModule,
    LoaderModule,
    PaginationModule,
    ColumnsModule,
    CommonsModule,
  ],
  providers: [SanitizeHtmlPipe, NewsService, UntypedFormBuilder, ReactiveFormsModule, TimekeeperService],
})
export class LayoutModule {
}
