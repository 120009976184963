import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[custom-toast-component]',
  styles: [
    `
          :host {
            position: relative;
            overflow: hidden;
            margin: 0 0 6px;
            padding: 10px 10px 10px 10px;
            color: #ffffff;
            pointer-events: all;
            cursor: pointer;
          }
        `
  ],
  template: `
    <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
      <span aria-hidden="true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 128 128"
          height="10px"
          id="Слой_1"
          version="1.1"
          viewBox="0 0 128 128"
          width="10px"
          xml:space="preserve"
        >
          <g>
            <polygon
              fill="#ffffff"
              points="123.5429688,11.59375 116.4765625,4.5185547 64.0019531,56.9306641 11.5595703,4.4882813     4.4882813,11.5595703 56.9272461,63.9970703 4.4570313,116.4052734 11.5244141,123.4814453 63.9985352,71.0683594     116.4423828,123.5117188 123.5126953,116.4414063 71.0732422,64.0019531   "
            />
          </g>
        </svg>
      </span>
    </button>
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
      <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alertdialog"
      aria-live="polite"
      [class]="options.messageClass"
      [innerHTML]="message"
    ></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alertdialog"
      aria-live="polite"
      [class]="options.messageClass"
      [attr.aria-label]="message"
    >
      {{ message }}
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}'))
    ])
  ],
  preserveWhitespaces: false
})
export class CustomToasterComponent extends Toast {
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}
