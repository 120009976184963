import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { disappearanceAnimation } from '@app/helpers';
import { ContactService } from '@app/modules/contacts/services/contact.service';

@Component({
  selector: 'app-users-popup-list',
  templateUrl: './users-popup-list.component.html',
  styleUrls: ['./users-popup-list.component.scss'],
  animations: [disappearanceAnimation]
})
export class UsersPopupListComponent {

  constructor(
    private router: Router,
    private contactService: ContactService,
  ) { }

  @Input() list;
  @Output() openSpinner = new EventEmitter();
  @Output() hideSpinner = new EventEmitter();
  memberPosition = {
    visible: false,
    y: 0,
    x: 0
  };

  menuOpen(event) {
    const elementPosition = event.target.getBoundingClientRect();

    this.memberPosition = {
      visible: true,
      y: elementPosition.y + 25,
      x: elementPosition.x + 28
    };
  }

  menuClose(event) {
    this.memberPosition.visible = false;
  }

  goToWorker(user) {
    this.openSpinner.emit();
    this.contactService.getUserById(user.id).subscribe(res => {
      this.hideSpinner.emit();
      if (res.id) {
        this.router.navigateByUrl(`/worker/${user.id}`);
      }
    });
  }
}
