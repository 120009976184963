import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Correspondence } from '@app/models/correspondence';
import { Observable, map } from 'rxjs';
import { environment } from '@environments/environment';
import { Helpers } from '@app/helpers/helpers';
import { ResponseBodyFull } from '@app/models';
import { AuthService } from '@app/auth/_services/auth.service';
import { CorrespondenceMessage } from '@app/models/correspondence-message';
import { CorrespondenceFilters } from '@app/models/correspondence-filters';
import { Paginator } from '@app/models/paginator';

@Injectable()
export class CorrespondenceService {
  static BASE_URL = `${environment.apiUrl}/correspondences`;
  constructor(private http: HttpClient, private authService: AuthService) {}

  get(
    page = 1,
    itemsPerPage = environment.defaultItemsCount,
    filters: CorrespondenceFilters = null
  ): Observable<Paginator<Correspondence>> {
    let params = new HttpParams().set('partial', 'false');

    if (page && itemsPerPage) {
      params = params.set('page', page.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<Correspondence>>(CorrespondenceService.BASE_URL, { params })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<Correspondence>>();
    }
  }

  getTimeline(id: number) {
    return this.http.get<Correspondence>(`${CorrespondenceService.BASE_URL}`, {
      params: new HttpParams().set('root', id.toString())
    }).pipe(map(response => (response['hydra:member'])));
  }

  getById(id: number) {
    if (this.authService.currentUserValue && id) {
      return this.http.get<Correspondence>(`${CorrespondenceService.BASE_URL}/${id.toString()}`);
    } else {
      return new Observable<Correspondence>();
    }
  }

  createMessage(data) {
    return this.http.post<CorrespondenceMessage>(`${environment.apiUrl}/correspondence_messages`, data);
  }

  getMessage(correspondenceId: number): Observable<Paginator<CorrespondenceMessage>> {
    return this.http.get<ResponseBodyFull<CorrespondenceMessage>>(
      `${environment.apiUrl}/correspondence_messages?correspondence.id=${correspondenceId}`
    )
    .pipe(map(response => ({member: response['hydra:member']})));
  }

  deleteCorrespondence(id: number) {
    return this.http.delete(`${CorrespondenceService.BASE_URL}/${id}`);
  }

  setCorrespondence(data: Correspondence) {
    return this.http.post<Correspondence>(CorrespondenceService.BASE_URL, data);
  }

  updateCorrespondence(data: Correspondence, id: number) {
    return this.http.patch<Correspondence>(`${CorrespondenceService.BASE_URL}/${id.toString()}`, data);
  }

  track(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/tracks/${id}`);
  }
}
