import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tracker-error-pay-modal',
  templateUrl: './tracker-error-pay-modal.component.html',
  styleUrls: ['./tracker-error-pay-modal.component.scss']
})
export class TrackerErrorPayModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TrackerErrorPayModalComponent>) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.dialogRef.close({ retry: true });
  }
}
