import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { forkJoin, map, Observable } from 'rxjs';
import { FiltersForGetReports } from '@app/modules/home/symbols';

@Injectable()
export class HomeDashboardService {
  private BASE_URL = `${environment.apiUrl}/dashboard_reports`;
  private BASE_URL_MONTH = `${environment.apiUrl}/dashboard_month_reports`;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getMainInfo(filters: any = null) {
    let params = new HttpParams();
    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http.get(`${this.BASE_URL}/${this.authService.currentUserValue.companyId}`, { params });
    } else {
      return new Observable<any>();
    }
  }

  getMonthReports(filters: FiltersForGetReports = null) {
    let params = new HttpParams();
    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http.get(`${this.BASE_URL_MONTH}/${this.authService.currentUserValue.companyId}`, { params });
    } else {
      return new Observable<any>();
    }
  }

  getTaskProductivity(userId, startDate, endDate) {
    return this.http.get(`${environment.apiUrl}/users/report?itemsPerPage=10000&includeCompletedTasks=true&p[]=timeActualDone&p[]=timeEstimateDone&includeTimeEstimates=true&companyAccesses.enable=true&dateFilter[after]=${startDate}&dateFilter[before]=${endDate}&id[]=${userId}`)
    .pipe(map(resp => resp['hydra:member'][0]));
  }

  getTaskUsersProductivity(startDate, endDate) {
    return this.http.get(`${environment.apiUrl}/users/report?itemsPerPage=10000&includeCompletedTasks=true&p[]=timeActualDone&p[]=timeEstimateDone&includeTimeEstimates=true&companyAccesses.enable=true&dateFilter[after]=${startDate}&dateFilter[before]=${endDate}`)
    .pipe(map(resp => resp['hydra:member']));
  }


  getTotalPrepeyment(startDate, endDate) {
    const awaiting = this.http.get(`${environment.apiUrl}/bill_totals/current?page=1&itemsPerPage=25&status=awaiting_payment&dateInvoice[after]=${startDate}&typePay=prepay&dateInvoice[before]=${endDate}`);
    const paid = this.http.get(`${environment.apiUrl}/bill_totals/current?page=1&itemsPerPage=25&status=paid&dateInvoice[after]=${startDate}&typePay=prepay&dateInvoice[before]=${endDate}`);
    return forkJoin([awaiting, paid]).pipe(
      map(([awaiting, paid]) => {
        return {
          awaiting,
          paid
        };
      })
    );
  }
}
