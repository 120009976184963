import { IPracticeBranchState, initialPracticeBranchState } from '../state/practice-branch.state';

import { EPracticeBranchesActions, PracticeBranchActions } from '../actions/practice-branches.actions';

export const practiceBranchReducers = (state = initialPracticeBranchState, action: PracticeBranchActions): IPracticeBranchState => {
  switch (action.type) {
    case EPracticeBranchesActions.GET_PRACTICE_BRANCHES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case EPracticeBranchesActions.ADD_PRACTICE_BRANCH_SUCCESS: {
      return {
        ...state
      };
    }
    case EPracticeBranchesActions.UPDATE_PRACTICE_BRANCH_SUCCESS: {
      return {
        ...state
      };
    }

    case EPracticeBranchesActions.DELETE_PRACTICE_BRANCH_SUCCESS: {
      return {
        ...state
      };
    }

    case EPracticeBranchesActions.DELETE_PRACTICE_BRANCHES_SUCCESS: {
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
