import { Payload, IUser } from '@app/models';

export interface IAuthState {
  isAuthenticated: boolean;
  user: IUser;
  userPayload: Payload;
  errorMessage: string | null;
  requestStatus: boolean;
}

export const initialAuthState: IAuthState = {
  isAuthenticated: false,
  user: null,
  userPayload: null,
  errorMessage: null,
  requestStatus: null
};
