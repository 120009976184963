import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddMedia } from '@app/store/actions/media.actions';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { selectAddedMedia } from '@app/store/selectors/media.selectors';
import { ToastrService } from 'ngx-toastr';
import { CompressImageService } from '@app/helpers/compress-image.service';
import { take } from 'rxjs/operators';

const MAX_FILE_SIZE_IN_MB = 1;

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
  @Input() public iconUrl: string | ArrayBuffer;
  @Input() typeIcon: string;
  @Output() private returnAddedImageData = new EventEmitter();
  @Output() private removeImage = new EventEmitter();
  @Output() private loading = new EventEmitter();
  @Output() private startLoading = new EventEmitter();

  @ViewChild('previewIcon', { static: false }) public previewIcon: ElementRef;

  constructor(private store: Store<IAppState>, private toastrService: ToastrService, private compressImage: CompressImageService) {
    this.subscribeToAddedMedia();
  }

  public processFile(file) {
    const fileData: File = file.target.files[0] as File;
    console.log(fileData);

    if (fileData) {
      const reader = new FileReader();
      const fileSize: number = +(fileData.size / 1024 / 1024).toFixed(4);
      console.log(fileSize);

      const mimeTypesArray = ['image/png', 'image/jpeg', 'image/tiff'];

      console.log(`Image size before compressed: ${fileData.size} bytes.`);

      if (fileSize > MAX_FILE_SIZE_IN_MB) {
        reader.onloadend = () => {
          this.iconUrl = reader.result;
        };
        reader.readAsDataURL(fileData);
        // this.toastrService.error('Максимальний розмір завантажуваного файлу має бути не більше 1 МБ');
        this.startLoading.emit();


        this.compressImage
          .compress(fileData)
          .pipe(take(1))
          .subscribe((compressedImage) => {
            console.log(compressedImage);
            console.log(
              `Image size after compressed: ${compressedImage.size} bytes.`
            );
            this.loading.emit(true);
            this.store.dispatch(new AddMedia(compressedImage, 'avatar'));
            // now you can do upload the compressed image
          });


        // this.imageCompress.compressFile(fileData, 0.9).subscribe(
        //   result => {
        //     const newFileData = new File([result], result.name);
        //     this.loading.emit(true);

        //     this.store.dispatch(new AddMedia(newFileData, 'avatar'));
        //   },
        //   error => {
        //     this.imageCompress.compressFile(fileData, 0.9).subscribe(result => {
        //       const newFileData = new File([result], result.name);

        //       this.loading.emit(true);

        //       this.store.dispatch(new AddMedia(newFileData, 'avatar'));
        //     });
        //   }
        // );
      } else if (mimeTypesArray.indexOf(fileData.type) === -1) {
        this.toastrService.error('Цей формат не підтримується в Jusnote. Завантажте фото у форматі PNG, JPEG або TIFF');
      } else {
        reader.onloadend = () => {
          this.iconUrl = reader.result;
        };
        reader.readAsDataURL(fileData);

        this.loading.emit(true);

        this.store.dispatch(new AddMedia(fileData, 'avatar'));
      }
    }
  }

  deleteImage() {
    this.previewIcon.nativeElement.value = '';
    this.iconUrl = '';
    this.removeImage.emit();
  }

  private subscribeToAddedMedia() {
    this.store.select(selectAddedMedia).subscribe(addedMedia => {
      this.returnContactIconData(addedMedia);
      this.loading.emit(false);
    });
  }

  private returnContactIconData(addedMedia) {
    this.returnAddedImageData.emit(addedMedia);
  }
}
