import { Injectable } from '@angular/core';
import { SubscriptionSub } from '@app/models/subscription-sub';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { LiqPay } from '@app/models/liq-pay';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { AuthService } from '@app/auth/_services/auth.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { LoadSubscriptionSubs, LoadCompanySubSetting } from '../store/actions/company-setting.actions';
import { CompanySubSettings } from '@app/models/company-settings';
import { PaddleService } from 'ngx-paddle-wrapper';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsSubService {
  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
    private authService: AuthService,
    private paddleService: PaddleService) {}
  public callbackSubscriptionSub: SubscriptionSub;
  private SUBSCRIPTIONS_SUB_ENDPOINT = `${environment.apiUrl}/subscription_subs`;

  payCreditCardSubsSib(tariff, companySettings, checkMonth) {
    this.createCompanySubSettings({
      subType: tariff.subType,
      company: companySettings.id
    }).subscribe(res => {
      this.payCreditCardSubsSibIsActiveJuscourt(tariff, companySettings, checkMonth);
    });
  }

  payCreditCardSubsSibIsActiveJuscourt(tariff, companySettings, checkMonth) {
    if (tariff.id !== 1) {
      // створення підписки
      this.createSubscriptionSub(this.buildSubscriptionSubPayload(tariff, companySettings, checkMonth)).subscribe(createres => {
        // відправка на оплату
        this.payPaddle(createres.id);
      });
    } else {
      this.store.dispatch(new LoadSubscriptionSubs());
      this.store.dispatch(new LoadCompanySubSetting());
    }
  }




  createSubscriptionOrder(payload): Observable<any> {
    return this.http.post<any>(`${this.SUBSCRIPTIONS_SUB_ENDPOINT}/upgrade`, payload);
  }

  // buildLiqPayCheckoutLink(liqpay: LiqPay) {
  //   if (!liqpay) {
  //     throw Error('No LiqPay data get from backend call back');
  //   }
  //   return environment.liqpay.url.replace(
  //     /\{\{(\w+)\}\}/g,
  //     (m, n) =>
  //       ({
  //         data: liqpay.data,
  //         signature: liqpay.signature
  //       }[n] || m)
  //   );
  // }

  buildSubscriptionSubPayload(tariff, companySettings, checkMonth): SubscriptionSub {
    const subscription = {
      period: checkMonth ? tariff.prices[0].period : tariff.prices[1].period,
      tariff: tariff.id,
      contact: companySettings.payer.id,
      currency: 'EUR', // companySettings.currency.code, TODO коли будуть інші subscriptionSub - зробити рефакторинг
      subType: tariff.subType
    };
    return subscription;
  }

  getSubscriptionSub(): Observable<SubscriptionSub[]> {
    const expireAtDate = CustomDateAdapter.getDateWithTimeUtcZ(new Date());
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<SubscriptionSub>>(
          `${this.SUBSCRIPTIONS_SUB_ENDPOINT}?status[]=active&status[]=disable&expiredAt[after]=${expireAtDate}`
        )
        .pipe(map(res => res['hydra:member']));
    } else {
      return new Observable<SubscriptionSub[]>();
    }
  }

  getSubscribedSubscriptionSub(): Observable<SubscriptionSub[]> {
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<SubscriptionSub>>(`${this.SUBSCRIPTIONS_SUB_ENDPOINT}?subscribed=true`)
        .pipe(map(res => res['hydra:member']));
    } else {
      return new Observable<SubscriptionSub[]>();
    }
  }


  createSubscriptionSub(payload: SubscriptionSub): Observable<SubscriptionSub> {
    if (this.authService.currentUserValue && this.authService.isCheckCurrentSubscriptionId()) {
      return this.http
        .post<SubscriptionSub>(this.SUBSCRIPTIONS_SUB_ENDPOINT, payload);
    } else {
      return new Observable<SubscriptionSub>();
    }
  }

  getCompanySubSettings(): Observable<CompanySubSettings[]> {
    if (this.authService.currentUserValue && this.authService.isCheckCurrentSubscriptionId()) {
      return this.http.get<ResponseBodyFull<CompanySubSettings>>(`${environment.apiUrl}/company_sub_settings`).pipe(map(d => d['hydra:member']));
    } else {
      return new Observable<CompanySubSettings[]>();
    }
  }

  // TODO перевірити
  unSubscribeSub(activeSubs: SubscriptionSub): Observable<any> {
    if (this.authService.currentUserValue && this.authService.isCheckCurrentSubscriptionId()) {
      return this.http.patch<any>(`${this.SUBSCRIPTIONS_SUB_ENDPOINT}/${activeSubs.id}/${activeSubs.subType}/unsubscribe`, {});
    } else {
      return new Observable<any>();
    }
  }

  createCompanySubSettings(data) {
    return this.http.post<CompanySubSettings>(`${environment.apiUrl}/company_sub_settings`, data);
  }

  removeCompanySubSettings(id) {
    return this.http.delete(`${environment.apiUrl}/company_sub_settings/${id}`);
  }



  payPaddle(id) {
    this.paddleService.create({
      vendor: environment.paddleVendorId
    });
    this.getPaddle(id).subscribe(paddle => {
      if (environment.production) {
        this.paddleService.open({
          override: paddle.paddle_redirect_url
        });
      } else {
        window.location.href = paddle.paddle_redirect_url;
      }
    });
  }

  getPaddle(id: number) {
    return this.http.get<any>(`${this.SUBSCRIPTIONS_SUB_ENDPOINT}/${id}/paddle/redirect-url`).pipe(map((resp: any) => resp.data));
  }
}
