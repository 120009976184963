export function getPhoneMask(entered: string) {
  if (entered.startsWith('7')) {
    return '+0 000 000 00 00';
  }
  if (entered.startsWith('38')) {
    return '+00 000 000 00 00';
  }
  if (entered.startsWith('1')) {
    return '+0 000 000 00009';
  }

  return '+00 000 000 00 00';
}
