// generated by the plop

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MAIN_MATTER_STATE_DEFAULT, MainMatterStateModel } from './main-matter.model';
import { LoadMattersForList, LoadMattersForListFail, LoadMattersForListSuccess } from './main-matter.actions';
import { PROGRESS_STATUSES } from '@app/shared/symbols';
import { MatterService } from '@app/modules/main-matters/services/matter.service';

@State({ name: 'MainMatter', defaults: MAIN_MATTER_STATE_DEFAULT })
@Injectable()
export class MainMatterState {

  constructor(private matterService: MatterService) {
  }

  @Selector()
  static matterExtended(state: MainMatterStateModel): MainMatterStateModel['matterExtended'] {
    return state.matterExtended;
  }

  @Selector()
  static loadMatterExtendedStatus(state: MainMatterStateModel): MainMatterStateModel['loadMatterExtendedStatus'] {
    return state.loadMatterExtendedStatus;
  }

  @Action(LoadMattersForList)
  setExampleField(ctx: StateContext<MainMatterStateModel>, { payload }: LoadMattersForList): void {
    ctx.patchState({
      loadMatterExtendedStatus: PROGRESS_STATUSES.IN_PROGRESS
    });

    this.matterService.get(payload.page, payload.itemsPerPage, { status: 'open' })
      .subscribe({
        next: matterExtended => ctx.dispatch(new LoadMattersForListSuccess(matterExtended)),
        error: err => ctx.dispatch(new LoadMattersForListFail(err))
      });
  }

  @Action(LoadMattersForListSuccess)
  setExampleFieldSuccess(ctx: StateContext<MainMatterStateModel>, { payload }: LoadMattersForListSuccess): void {
    ctx.patchState({
      matterExtended: payload,
      loadMatterExtendedStatus: PROGRESS_STATUSES.SUCCEED
    });
  }

  @Action(LoadMattersForListFail)
  setExampleFieldFail(ctx: StateContext<MainMatterStateModel>, { error }: LoadMattersForListFail): void {
    ctx.patchState({
      loadMatterExtendedStatus: PROGRESS_STATUSES.INTERRUPTED
    });
  }
}


