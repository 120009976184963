import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, switchMap, map, share, tap } from 'rxjs/operators';
import { ContractService } from '@app/modules/contracts/services/contract.service';
import {
  LoadContractAction,
  LoadContractSuccessAction,
  LoadContractFailureAction,
  AddContractAction,
  AddContractSuccessAction,
  AddContractFailureAction,
  ContractActionsTypes,
  GetContractAction,
  GetContractSuccessAction,
  GetContractFailureAction,
  EditContractAction,
  EditContractSuccessAction,
  EditContractFailureAction,
  DeleteContractAction,
  DeleteContractFailureAction,
  DeleteContractSuccessAction
} from '../actions/contract.actions';
import { of } from 'rxjs';
import { SelectCreateMatter } from '../selectors/matter.selector';
import { CreateMatter } from '../actions/matter.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Contract } from '@app/models/contract';

@Injectable()
export class ContractEffects {
  
  loadContract$ = createEffect(() => this.actions$.pipe(
    ofType<LoadContractAction>(ContractActionsTypes.LOAD_CONTRACT),
    mergeMap(() =>
      this.contractService.get().pipe(
        map(data => new LoadContractSuccessAction(data)),
        catchError(error => of(new LoadContractFailureAction(error)))
      )
    )
  ));
  
  getContract$ = createEffect(() => this.actions$.pipe(
    ofType<GetContractAction>(ContractActionsTypes.GET_CONTRACT),
    switchMap(action => {
      if (action.payload == null) {
        return [new GetContractSuccessAction(null)];
      }
      return this.contractService.getContract(action.payload).pipe(
        mergeMap(resp => {
          return [new GetContractSuccessAction(resp as Contract)];
        }),
        catchError(error => of(new GetContractFailureAction(error)))
      );
    })
  ));
  
  addContract$ = createEffect(() => this.actions$.pipe(
    ofType<AddContractAction>(ContractActionsTypes.ADD_CONTRACT),
    switchMap(action => {
      return this.contractService.setContract(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('contractAdded'));
            return of(new AddContractSuccessAction(x));
          }
        }),
        catchError(error => of(new AddContractFailureAction(error)))
      );
    }),
    share()
  ));

  
  addContractSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddContractSuccessAction>(ContractActionsTypes.ADD_CONTRACT_SUCCESS),
    tap(x => {
      this.addContractToCreatingMatter(x);
    })
  ), { dispatch: false });

  
  editContract$ = createEffect(() => this.actions$.pipe(
    ofType<EditContractAction>(ContractActionsTypes.EDIT_CONTRACT),
    switchMap(action => {
      return this.contractService.updateContract(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('itemEdited'));
            this.location.back();
            return of(new EditContractSuccessAction(x));
          }
          return of(new EditContractFailureAction(new Error()));
        }),
        catchError(error => of(new EditContractFailureAction(error)))
      );
    })
  ));

  
  deleteContract$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteContractAction>(ContractActionsTypes.DELETE_CONTRACT),
    switchMap(action => {
      return this.contractService.deleteContract(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('contractDeleted'));
          return of(new DeleteContractSuccessAction(action.payload));
        }),
        catchError(error => of(new DeleteContractFailureAction(error)))
      );
    }),
    share()
  ));

  /**
   * Without damn `tap`
   */
  
  deleteContractSuccess$ = createEffect(() => this.actions$.pipe(ofType<DeleteContractSuccessAction>(ContractActionsTypes.DELETE_CONTRACT_SUCCESS)), { dispatch: false });

  
  deleteMatterSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteContractSuccessAction>(ContractActionsTypes.DELETE_CONTRACT_SUCCESS),
    tap(x => {
      this.location.back();
    })
  ), { dispatch: false });
  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private location: Location,
    private toastrService: ToastrService,
    private contractService: ContractService,
    private translate: TranslateService
  ) {}

  addContractToCreatingMatter(x) {
    let matters;
    this.store.select(SelectCreateMatter).subscribe(data => {
      if (data) {
        matters = data;
      }
    });
    if (matters) {
      matters.contact = x.payload.client;
      matters.contract = { id: x.payload.id };
      this.store.dispatch(new CreateMatter(matters));
    }
    this.location.back();
  }
}
