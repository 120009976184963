import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { DocumentCategory } from '@app/models/document-category';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { Observable } from 'rxjs';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class DocumentCategoryService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL = `${environment.apiUrl}/document_categories`;

  get(): Observable<Paginator<DocumentCategory>> {
    const queryString = `${environment.apiUrl}/document_categories`;
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<DocumentCategory>>(queryString)
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<DocumentCategory>>();
    }
  }
  getDocumentCategories(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters?) {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<DocumentCategory>>(this.BASE_URL, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<DocumentCategory>>();
    }
  }

  addDocumentCategory(documentCategory: DocumentCategory) {
    const queryString = `${environment.apiUrl}/document_categories`;
    return this.http.post<DocumentCategory>(queryString, documentCategory);
  }

  updateDocumentCategory(documentCategory: DocumentCategory) {
    const queryString = `${environment.apiUrl}/document_categories/${documentCategory.id}`;
    return this.http.patch<DocumentCategory>(queryString, documentCategory);
  }

  deleteDocumentCategory(id: number) {
    const queryString = `${environment.apiUrl}/document_categories/${id}`;
    return this.http.delete(queryString);
  }
}
