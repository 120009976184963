export interface IUser {
  status?: string;
  email?: string;
  phone?: string;
  password?: string;
  account?: IAccount;
  person?: IPerson;
  settings?: IUserSettings[];
  securities?: ISecurities[];
  workGroups?: IWorkGroups[];
  companyAccesses?: ICompanyAccesses[];
  rates?: IUserRates[];
  rate?: string;
  likeNews?: number[];
  readNews?: number[];
  goal?: string;
  roles?: string[];
  id?: number;
  fromModal?: boolean;
  '2fa'?: number;
  username?: string;
}

export interface IAccount {
  id?: number;
}

export interface IPerson {
  username?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  fullName?: string;
  jobTitle?: string;
  contact?: IPersonContact;
  id?: number;
}

export interface IPersonContact {
  media?: IMediaPersonContact;
  id?: number;
  fullName?: string;
}

export interface IMediaPersonContact {
  url?: string;
  id?: number;
}

export interface IUserSettings {
  googleAccount?: IGoogleAccount;
  outlookAccount?: IOutlookAccount;
  viberAccount?: IViberAccount;
  telegramAccount?: ITelegramAccount;
  countDaysInYear?: number;
  countHoursInDay?: number;
  defaultPayCountDays?: number;
  lang?: string;
  notificationMatter?: string;
  notificationTaskEvent?: string;
  notificationCorrespondence?: string;
  notificationBill?: string;
  notificationPayment?: string;
  notificationBudgetExhausted?: string;
  notificationReminder?: string;
  notificationComments?: string;
  notificationDocumentSigned?: string;
  notificationJuscourt?: string;
  sendDailyEmails?: boolean;
  id?: number;
  showSidebar?: boolean;
  twoFactorAuthentication?: boolean;
  notificationTime?: string;
  timeZone?: string;
  docusignAccount?: any;
  synchronizedSubscriptions?: boolean;
}

export interface ISecurities {
  id?: number;
}

export interface IWorkGroups {
  id?: number;
}

export interface IUserRates {
  currency?: string;
  rate?: string;
  id?: number;
}

export interface IGoogleAccount {
  accessToken?: [string];
  enabled?: boolean;
}

export interface IOutlookAccount {
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: number;
  enabled?: boolean;
}

export interface IViberAccount {
  id?: string;
  token?: string;
  enabled?: boolean;
}

export interface ITelegramAccount {
  id?: string;
  token?: string;
  enabled?: boolean;
}

export interface ICompanyAccesses {
  enable?: true;
  company?: {
    id?: number;
  };
  id?: number;
}
