import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from '@app/store/state/app.state';
import { select, Store } from '@ngrx/store';
import { Logout, UpdateSidebarStatus } from '@app/auth/_store/actions/auth.actions';
import { translateAnimation } from '@app/helpers/fadeAnimation';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { Subject, Subscription } from 'rxjs';
import { IUser } from '@app/models';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { Router } from '@angular/router';
import { selectSubscribedSubscription, selectSubscriptionJuscourt } from '@app/modules/settings/store/selectors/company-setting.selector';
import { LoadSubscribedSubscription, LoadSubscriptionSubs } from '@app/modules/settings/store/actions/company-setting.actions';
import { NotificationsService } from '../notifications/services/notifications.service';
import { ETeamMemberRoles } from '@app/enums/team-member-roles';
import { TranslateService } from '@ngx-translate/core';
const {version} = require('../../../../../package.json');
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [translateAnimation]
})
export class SidebarComponent implements OnInit, OnDestroy {
  version = version;
  isToggledSidebar: boolean;
  sidebarProfileMenu = false;
  subscriptions = new Subscription();
  currentUserId = 0;
  currentUser: IUser;
  user: IUser;
  unsubscribe = new Subject<void>();
  defaultImage = '/assets/images/card-image-placeholder.png';
  readonly adminRole = ETeamMemberRoles.admin;

  // for tracker
  plaginTrouble = false;

  constructor(
    private store: Store<IAppState>,
    private appSettingsService: AppSettingsService,
    private translate: TranslateService,
    private router: Router,
    private notificationService: NotificationsService
  ) {
    const subscription = appSettingsService.getSidebar.subscribe(sidebar => {
      this.isToggledSidebar = sidebar;
    });
    this.subscriptions.add(subscription);
    this.store.pipe(select(selectCurrentUser)).subscribe(user => {
      if (!user) {
        return;
      }
      this.currentUserId = user.id;
      this.currentUser = user;
    });
  }

  subscribedSubscription;
  subscriptionJuscourt;
  ngOnInit() {
    this.store.select(selectSubscribedSubscription).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadSubscribedSubscription());
      } else {
        this.subscribedSubscription = res;
        this.plaginTrouble = this.notificationService.checkVisibleTroubleJuscourt(
          'sidebar',
          this.subscribedSubscription,
          this.subscriptionJuscourt
        );
      }
    });
    this.store.select(selectSubscriptionJuscourt).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadSubscriptionSubs());
      } else {
        if (res[0]) {
          this.subscriptionJuscourt = res[0];
          this.plaginTrouble = this.notificationService.checkVisibleTroubleJuscourt(
            'sidebar',
            this.subscribedSubscription,
            this.subscriptionJuscourt
          );
        }
      }
    });
  }

  troubleHide() {
    if (this.plaginTrouble) {
      this.plaginTrouble = false;
      localStorage.setItem('troubleClickSidebarSetting', 'false');
    }
  }
  logout(): void {
    this.store.dispatch(new Logout());
    this.router.navigateByUrl('/auth/login');
  }

  checkUserRole(IUser) {
    return (IUser.roles.indexOf(this.adminRole) === -1 && this.translate.instant('user')) || this.translate.instant('ROLE_SITE_ADMIN');
  }

  postSidebarStatus() {
    if (this.currentUser === undefined) {
      return;
    }

    let settings;
    settings = {
      settings: [{ showSidebar: !this.isToggledSidebar, id: this.currentUser.settings[0].id }]
    };
    this.store.dispatch(new UpdateSidebarStatus(settings, this.currentUser.id));
    // Optimistic action, will be overwritten in AppSettingsService after store update
    this.isToggledSidebar = !this.isToggledSidebar;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unsubscribe.next();
  }
  checkActiveLink(entity: string): boolean {
    const url = this.router.url.split('/')[1];
    if (entity === 'home' && this.router.url === '/') {
      return true;
    }
    return url === entity || url.includes(entity);
  }
}
