import { Pipe, PipeTransform } from '@angular/core';
import { ContactFormBlock } from '@app/modules/contacts/symbols/contacts-form-config';

@Pipe({
  name: 'defineFormBlock',
  standalone: true,
})
export class DefineFormBlockPipe implements PipeTransform {

  transform(availableBlocks: ContactFormBlock[], currentBlock: ContactFormBlock | ContactFormBlock[]): boolean {
    return Array.isArray(currentBlock) ?
      currentBlock.some(block => availableBlocks.includes(block)) :
      availableBlocks.includes(currentBlock);
  }

}
