import { ExpenseTypeActions, EExpenseTypesActions } from '../actions/expense-types.actions';

import { IExpenseTypeState, initialExpenseTypeState } from '../state/expense-type.state';

export const expenseTypeReducers = (state = initialExpenseTypeState, action: ExpenseTypeActions): IExpenseTypeState => {
  switch (action.type) {
    case EExpenseTypesActions.LOAD_EXPENSE_TYPES:
      return {
        ...state,
        loading: true
      };
    case EExpenseTypesActions.LOAD_EXPENSE_TYPES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: true
      };
    case EExpenseTypesActions.LOAD_EXPENSE_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case EExpenseTypesActions.LOAD_INTERNAL_EXPENSE_TYPES:
      return {
        ...state,
        loading: true
      };
    case EExpenseTypesActions.LOAD_INTERNAL_EXPENSE_TYPES_SUCCESS:
      return {
        ...state,
        internalList: action.payload,
        loading: true
      };
    case EExpenseTypesActions.LOAD_INTERNAL_EXPENSE_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case EExpenseTypesActions.GET_EXPENSE_TYPES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case EExpenseTypesActions.UPDATE_EXPENSE_TYPE_SUCCESS: {
      state.list = {
        success: true,
        data: state.paginator.member
      };
      return {
        ...state
      };
    }
    case EExpenseTypesActions.DELETE_EXPENSE_TYPE_SUCCESS: {
      if (state.list) {
        state.list.data = state.list.data.filter(elem => {
          return +elem.id !== +action.payload;
        });
      }
      return {
        ...state
      };
    }
    case EExpenseTypesActions.DELETE_EXPENSE_TYPES_SUCCESS: {
      if (state.list) {
        for (const payload of action.payload) {
          state.list.data = state.list.data.filter(elem => {
            return +elem.id !== +payload;
          });
        }
      }
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
