// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-template .modal-container {
  width: 660px;
  height: 100%;
}
.modal-template .modal-header-note {
  padding: 8px 12px 12px;
}
.modal-template .modal-header-note .header-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.modal-template .modal-header-note span {
  font-size: 20px;
  color: #1252af;
}
.modal-template .modal-header-note .date-container {
  color: #637283;
  font-size: 14px;
  font-weight: 600;
}
.modal-template .modal-header-note .title-text {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}
.modal-template .modal-body-note {
  padding: 8px 0;
  height: calc(100% - 80px);
}
.modal-template .modal-body-note .content-container {
  padding: 0 12px;
}
.modal-template .modal-body-note p {
  color: #637283;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  white-space: pre-line;
}

::ng-deep .cropp-link {
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/other-modals/display-note-modal/display-note-modal.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,YAAA;AAAJ;AAGE;EACE,sBAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AADN;AAII;EACE,eAAA;EACA,cAAA;AAFN;AAKI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAHN;AAMI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AAJN;AAQE;EACE,cAAA;EACA,yBAAA;AANJ;AAQI;EACE,eAAA;AANN;AASI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,qBAAA;AAPN;;AAaE;EACE,qBAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,sBAAA;AAVJ","sourcesContent":[".modal-template {\n  .modal-container {\n    width: 660px;\n    height: 100%;\n  }\n\n  .modal-header-note {\n    padding: 8px 12px 12px;\n\n    .header-top-container {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      margin-bottom: 16px;\n    }\n\n    span {\n      font-size: 20px;\n      color: #1252af;\n    }\n\n    .date-container {\n      color: #637283;\n      font-size: 14px;\n      font-weight: 600;\n    }\n\n    .title-text {\n      color: #000000;\n      font-size: 18px;\n      font-weight: 500;\n      margin: 0;\n    }\n  }\n\n  .modal-body-note {\n    padding: 8px 0;\n    height: calc(100% - 80px);\n\n    .content-container {\n      padding: 0 12px;\n    }\n\n    p {\n      color: #637283;\n      font-size: 14px;\n      font-weight: 600;\n      margin: 0;\n      white-space: pre-line;\n    }\n  }\n}\n\n::ng-deep {\n  .cropp-link {\n    display: inline-block;\n    max-width: 90%;\n    white-space: nowrap;\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n    vertical-align: bottom;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
