// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jt-input {
  display: flex;
  flex-direction: column;
}

.disabled {
  opacity: 0.6;
}

.close-icon {
  position: relative;
}
.close-icon:before {
  content: "\\e953";
  font-family: "e-icons";
  font-style: normal;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
}

.input-container input {
  padding-right: 35px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/jn-form-fields/jn-input/jn-input.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,gBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;AACJ;;AAIE;EACE,mBAAA;AADJ","sourcesContent":[".jt-input {\n  display: flex;\n  flex-direction: column;\n}\n\n.disabled {\n  opacity: .6;\n}\n\n.close-icon {\n  position: relative;\n\n  &:before {\n    content: \"\\e953\";\n    font-family: \"e-icons\";\n    font-style: normal;\n    font-size: 12px;\n    color: #333;\n    cursor: pointer;\n    position: absolute;\n    right: 10px;\n    top: 0;\n  }\n}\n\n.input-container {\n  input {\n    padding-right: 35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
