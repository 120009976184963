import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Payment } from '@app/models/payment';
import { ResponseBodyFull } from '@app/models';
import { TransactionFilters } from '@app/models/transaction-filters';
import { Helpers } from '@app/helpers/helpers';
import { Transaction, TransactionSummary } from '@app/models/transaction';
import { AuthService } from '@app/auth/_services/auth.service';
import { Observable } from 'rxjs';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient,  private authService: AuthService) {}

  getPayments(page = 1, itemsPerPage = environment.defaultItemsCount, bill?: number): Observable<Paginator<Payment>> {
    const params = new HttpParams()
      .set('partial', 'false')
      .set('page', page.toString())
      .set('itemsPerPage', itemsPerPage.toString())
      .set('bill.id', bill.toString());
    return this.http.get<ResponseBodyFull<Payment>>(`${environment.apiUrl}/payments`, { params })
    .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));;
  }

  getTransactions(page = 1, itemsPerPage = environment.defaultItemsCount, filter: TransactionFilters): Observable<Paginator<Transaction>> {
    const filters = filter ? JSON.parse(JSON.stringify(filter)) : {};
    let params = new HttpParams()
      .set('partial', 'false')
      .set('page', page.toString())
      .set('itemsPerPage', itemsPerPage.toString());

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }

    return this.http.get<ResponseBodyFull<Transaction>>(`${environment.apiUrl}/transactions`, { params })
    .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));

  }

  getTransactionSummary(page = 1, itemsPerPage: number = environment.defaultItemsCount, filter: TransactionFilters): Observable<TransactionSummary> {
    const filters = filter ? JSON.parse(JSON.stringify(filter)) : {};
    let params = new HttpParams()
      .set('partial', 'false')
      .set('page', page.toString())
      .set('itemsPerPage', itemsPerPage.toString());

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    return this.http.get<TransactionSummary>(`${environment.apiUrl}/transactions/summary`, { params });
  }

  createPayment(paymentData: Payment) {
    return this.http.post<Payment>(`${environment.apiUrl}/payments`, paymentData)
  }

  updatePayment(id: number, paymentData: Payment) {
    return this.http
      .patch<Payment>(`${environment.apiUrl}/payments/${id}`, paymentData);
  }

  deletePayment(id: number) {
    return this.http.delete(`${environment.apiUrl}/payments/${id}`);
  }
}
