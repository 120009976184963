import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/auth/_services/auth.service';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import {
  DocumentUploadModalComponent,
} from '@app/commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import { TimeModalDebounceEnum } from '@app/enums/debounce-keys.enum';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { checkRole } from '@app/helpers/check-role';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { DebounceService } from '@app/helpers/debounce.service';
import { fadeTopAnimation } from '@app/helpers/fadeAnimation';
import { Helpers } from '@app/helpers/helpers';
import { TimeFormatter } from '@app/helpers/time-formatter';
import { ActivityExtended, IUser, TaskExtended } from '@app/models';
import { CompanySettings } from '@app/models/company-settings';
import { DocRelation } from '@app/models/doc-relation';
import { DocumentSave } from '@app/models/document-save';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { TimeEntryParams } from '@app/models/interfaces/time-entry-params';
import { MatterExtended } from '@app/models/matter-extended';
import { MatterFilters } from '@app/models/matter-filters';
import { ReminderExtended } from '@app/models/reminder-extended';
import { TimeEntrySave } from '@app/models/time-entry-save';
import { TimekeeperService } from '@app/modules/layout/timekeeper/timekeeper.service';
import { ActivitiesService } from '@app/modules/main-activities/services/activities.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { GetTimeEntryTypes } from '@app/modules/settings/store/actions/time-entry-types.actions';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { selectTimeEntryTypesPaginator } from '@app/modules/settings/store/selectors/time-entry-type.selector';
import { AddActivity, UpdateActivity } from '@app/store/actions/activities.actions';
import { AddDocuments, DeleteDocuments, GetDocumentsByActivity } from '@app/store/actions/document.actions';
import { LoadMattersForList } from '@app/store/actions/matter.actions';
import { ActivitiesEffects } from '@app/store/effects/activities.effects';
import { selectCurrentActivityDocuments } from '@app/store/selectors/document.selectors';
import { selectMatterListForSelect } from '@app/store/selectors/matter.selector';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';

@Component({
  selector: 'app-time-modal',
  templateUrl: './time-modal.component.html',
  styleUrls: ['./time-modal.component.scss'],
  animations: [fadeTopAnimation],
})
export class TimeModalComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private activitiesService: ActivitiesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      time?: ActivityExtended;
      date?: Date;
      contact?: { id: number };
      responsible?: { id: number };
      matter?: { id: number, nonBillable: boolean };
      task?: TaskExtended;
    },
    public dialogRef: MatDialogRef<TimeModalComponent>,
    private dialog: MatDialog,
    public timeFormatter: TimeFormatter,
    private matterService: MatterService,
    private teamMemberService: TeamMemberService,
    private store: Store<IAppState>,
    private authService: AuthService,
    public timekeeperService: TimekeeperService,
    private appSettingsService: AppSettingsService,
    private toastrService: ToastrService,
    private activitiesEffects: ActivitiesEffects,
    private debounceService: DebounceService,
  ) {
    const addSubscr = this.activitiesEffects.addActivity$.subscribe(() => {
      this.initData();
    });
    this.subscription.add(addSubscr);
  }

  get f() {
    return this.timeEntryForm;
  }

  private subscription = new Subscription();
  // private matters: MatterExtended[];

  // pairs of documents and media to save them when timeEntry is saved
  documentsToAdd: { document: DocumentSave; mediaFile: File; reminders: ReminderExtended; relations: DocRelation[] }[] = [];
  // documents ids to delete when timeEntry is saved
  documentsToDelete: number[] = [];
  // documents to show in list
  documentsToShow: { name: string; id?: number; media: { type: 'document' } }[] = [];
  fileData: File = null;

  timeEntryForm: UntypedFormGroup;
  dateFormat = CustomDateAdapter.dateFormat;

  submittedForm = false;
  @ViewChild('file', { static: false }) inputFile: ElementRef;

  matters: MatterExtended[];
  timeEntryTypes$ = new BehaviorSubject({ dataSource: [], value: 'id', parentValue: 'parentId', text: 'name', child: 'children' });
  users: IUser[];
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  initialDuration: number;
  disableDuration = false;
  companySettings: CompanySettings;
  public locale$ = this.appSettingsService.getLang;

  filterMatters: MatterFilters = {};
  @ViewChild('mattersComboBox', { static: false }) public comboBoxMatter: ComboBoxComponent;

  filtersUser: TeamMemberFilter = {
    name: '',
    status: 'active',
    'companyAccesses.enable': true,
  };

  @ViewChild('usersComboBox', { static: false }) public comboBoxUser: ComboBoxComponent;

  descriptionLanguage = null;
  rateCurrency = null;
  getStartRates = true;

  timerTranslate = this.translate.instant('time.start');
  duration = '';
  descIn = 'unfocus';

  ngOnInit() {
    this.store.dispatch(
      new GetTimeEntryTypes({
        page: 1,
        itemsPerPage: 10000,
        filters: { name: '', 'exists[parent]': 'false' },
      }),
    );
    this.createForm();
    this.initData();
  }

  initData() {
    if (this.data && !this.data.time) {
      this.getStartRates = false;
    }
    if (this.data && this.data.time && !this.data.time.matter) {
      this.getStartRates = false;
    }
    if (!this.data) {
      this.getStartRates = false;
    }
    if (this.data && this.data.task && this.data.matter && !this.data.time) {
      this.onChangeMatter(this.data.matter);
    }
    if (this.data && this.data.matter && !this.data.task && !this.data.time) {
      this.timeEntryForm.get('matterId').disable();
      this.onChangeMatter(this.data.matter);
    }
    if (this.data && this.data.responsible) {
      this.timeEntryForm.get('responsiblePersonId').disable();
    }
    if (this.data && this.data?.matter?.nonBillable) {
      this.timeEntryForm.get('nonBillable').setValue(this.data.matter.nonBillable);
    }

    if (this.data && this.data.time) {
      this.store.dispatch(new GetDocumentsByActivity(this.data.time.id));
      this.initFormValues(this.data.time);
      const documentsSubscr = this.store.pipe(select(selectCurrentActivityDocuments)).subscribe(x => {
        this.documentsToShow = [];
        if (x && x.length > 0) {
          this.documentsToShow = x.map(doc => {
            return { name: doc.name, id: doc.id, media: { type: 'document' } };
          });
        }
      });
      // this.getStartRates = false;
      this.subscription.add(documentsSubscr);
    }
    this.setSelectModels();

    this.store.select(selectCompanySetting).subscribe(data => {
      if (!data?.id) {
        this.store.dispatch(new LoadCompanySettingAction());
      } else {
        this.companySettings = data;
      }
    });

    if (this.data && !this.data.time && this.data.task) {
      this.timeEntryForm.get('description').setValue(this.data.task.name);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.timekeeperService
      .fetchTimeEntries({
        'date[before]': moment(new Date()).format(TimekeeperService.MYSQL_DATE_FORMAT),
        'date[after]': moment(new Date()).format(TimekeeperService.MYSQL_DATE_FORMAT),
      })
      // .pipe(timeout(2500))
      .subscribe();
    this.debounceService.removeDebounceData();
  }

  checkSubmit() {
    this.submittedForm = true;
  }

  shouldDisable(): boolean {
    /**
     * From TS 3.7+ we will have optional chaining operator `?.`
     */
    if (!this.timekeeperService.isActive) {
      return false;
    }
    return !!(this.data && this.data.time && this.data.time.timer);
  }

  onFileChanged(fileInput) {
    this.fileData = fileInput.target.files[0] as File;
    if (!Helpers.checkSizeFile(this.fileData, 20)) {
      this.fileData = null;
      this.toastrService.error(this.translate.instant('documents.fileTooBig20'));
      return;
    }
    if (this.fileData) {
      this.documentUploadModal(this.fileData);
    }
    this.inputFile.nativeElement.value = null;
  }

  deleteDocument(document: { name: string; id?: number; media: { type: 'document' } }) {
    if (document.id) {
      this.documentsToDelete.push(document.id);
    } else {
      const position = this.documentsToAdd.indexOf(this.documentsToAdd.filter(x => x.document.name === document.name)[0]);
      if (position !== -1) {
        this.documentsToAdd.splice(position, 1);
      }
    }
    this.documentsToShow.splice(this.documentsToShow.indexOf(document), 1);
  }

  saveTimeEntryAndClose() {
    this.timeEntryForm.updateValueAndValidity();
    this.checkSubmit();
    if (!this.timeEntryForm.valid) {
      return;
    }
    if (this.data && this.data.time) {
      this.updateTimeEntry();
    } else {
      this.addTimeEntry();
    }
    this.dialogRef.close(true);
  }

  saveTimeEntryAndClear(): void {
    this.timeEntryForm.updateValueAndValidity();
    this.checkSubmit();
    if (!this.timeEntryForm.valid) {
      return;
    }
    if (this.data && this.data.time) {
      this.updateTimeEntry();
    } else {
      this.addTimeEntry();
    }
    this.documentsToDelete = [];
    this.documentsToShow = [];
    this.documentsToAdd = [];
    if (this.data && this.data.time) {
      delete this.data.time;
    }
    this.resetFormValues();
  }

  saveTimeEntryAndContinue(): void {
    this.timeEntryForm.updateValueAndValidity();
    this.checkSubmit();
    if (!this.timeEntryForm.valid) {
      return;
    }
    if (this.data && this.data.time) {
      this.updateTimeEntry();
    } else {
      this.addTimeEntry();
    }
    this.documentsToDelete = [];
    this.documentsToShow = [];
    this.documentsToAdd = [];
    if (this.data && this.data.time) {
      delete this.data.time;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private createForm() {
    this.timeEntryForm = this.formBuilder.group({
      duration: this.formBuilder.control(null, [Validators.required, this.timeFormatter.correctTimeFormatValidator()]),
      rate: this.formBuilder.control(null, [Validators.required, Validators.min(0)]),
      date: this.formBuilder.control(this.data && this.data.date ? new Date(this.data.date) : new Date(), [Validators.required]),
      matterId: this.formBuilder.control(
        { value: this.data && this.data.matter ? this.data.matter.id : null, disabled: this.data && this.data.task },
        [Validators.required],
      ),
      timeEntryType: this.formBuilder.control(null),
      description: this.formBuilder.control(''),
      responsiblePersonId: this.formBuilder.control(null, [Validators.required]),
      nonBillable: this.formBuilder.control(false),
    });
    this.timeEntryForm.patchValue({
      responsiblePersonId: this.authService.currentUserValue.id,
    });
    if (!checkRole(['ROLE_SITE_ADMIN', 'ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ACCOUNTS_MANAGER'])) {
      this.timeEntryForm.get('rate').setValidators(null);
      this.timeEntryForm.get('rate').setValue(null);
    }
  }

  private initFormValues(timeEntry: ActivityExtended): void {
    this.timeEntryForm.get('duration').setValue(this.timeFormatter.convertSecondsToTableString(timeEntry.duration));
    this.duration = this.timeFormatter.convertSecondsToTableString(timeEntry.duration);

    this.timeEntryForm.get('rate').setValue(timeEntry.rate);
    this.timeEntryForm.get('date').setValue(new Date(timeEntry.date));
    this.timeEntryForm.get('matterId').setValue(timeEntry.matter ? timeEntry.matter.id : null);
    this.timeEntryForm.get('timeEntryType').setValue(timeEntry.timeEntryType ? timeEntry.timeEntryType.id : null);
    this.timeEntryForm.get('description').setValue(timeEntry.description);
    this.timeEntryForm.get('responsiblePersonId').setValue(timeEntry.responsiblePerson ? timeEntry.responsiblePerson.id : null);
    this.timeEntryForm.get('nonBillable').setValue(timeEntry.nonBillable);

    this.initialDuration = timeEntry.duration;

    if (this.shouldDisable()) {
      // todo Move it to i18n
      this.timeEntryForm.get('duration').setValue(this.timerTranslate);
      this.duration = this.timerTranslate;
      this.timeEntryForm.get('duration').disable();
      this.disableDuration = true;
      this.timeEntryForm.get('date').disable();
      this.timeEntryForm.get('responsiblePersonId').disable();
    }
  }

  private resetFormValues(): void {
    this.timeEntryForm.get('duration').reset();
    this.timeEntryForm.get('rate').reset();
    this.timeEntryForm.get('date').setValue(new Date());
    this.timeEntryForm.get('matterId').reset();
    this.timeEntryForm.get('timeEntryType').reset();
    this.timeEntryForm.get('description').setValue('');
    this.timeEntryForm.get('nonBillable').setValue(false);
    this.timeEntryForm.get('responsiblePersonId').setValue(this.authService.currentUserValue.id);
    this.submittedForm = false;
  }

  private formDataToTimeEntrySave(isUpdated: boolean): TimeEntrySave {
    const matter = this.matters && this.matters.find(x => x.id === this.timeEntryForm.get('matterId').value);
    const timeEntry: TimeEntrySave = {
      id: isUpdated ? this.data.time.id : null,
      currency: matter.currencyBill && matter.currencyBill.code, // this.companySettings.currency.code
      date: CustomDateAdapter.convertToUniversalDateString(this.timeEntryForm.get('date').value),
      description: this.timeEntryForm.get('description').value,
      document: null,
      duration: this.timeFormatter.convertStringToSeconds(this.timeEntryForm.get('duration').value),
      matter: this.timeEntryForm.get('matterId').value as number,
      rate: this.timeEntryForm.get('rate').value ? this.timeEntryForm.get('rate').value.toString() : null,
      responsiblePerson: this.timeEntryForm.get('responsiblePersonId').value as number,
      timeEntryType: this.timeEntryForm.get('timeEntryType').value as number,
      task: this.data && this.data.task ? this.data.task.id : this.data?.time?.task ? this.data?.time?.task.id : null,
      nonBillable: this.timeEntryForm.get('nonBillable').value,
    };
    if (this.shouldDisable()) {
      timeEntry.duration = this.initialDuration;
    }
    return timeEntry;
  }

  private addTimeEntry(): void {
    const timeEntry = this.formDataToTimeEntrySave(false);
    if (!timeEntry.rate) {
      timeEntry.rate = 'withOutRate';
    }
    if (this.documentsToAdd.length > 0) {
      this.store.dispatch(new AddActivity({ activity: timeEntry, typeActivity: 'time_entries', documents: this.documentsToAdd }));
    } else {
      this.store.dispatch(new AddActivity({ activity: timeEntry, typeActivity: 'time_entries' }));
    }
  }

  private updateTimeEntry(): void {
    const timeEntry = this.formDataToTimeEntrySave(true);
    if (!timeEntry.rate) {
      timeEntry.rate = 'withOutRate';
    }
    this.store.dispatch(new UpdateActivity({ activity: timeEntry, typeActivity: 'time_entries' }));
    if (this.documentsToAdd.length > 0) {
      this.store.dispatch(new AddDocuments(this.documentsToAdd));
    }
    if (this.documentsToDelete.length > 0) {
      this.store.dispatch(new DeleteDocuments(this.documentsToDelete));
    }
  }

  // handler for uploading of the documents
  private documentUploadModal(file: File) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { fileName: file.name, skipMatter: true };

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    const subscr = dialogRef.afterClosed().subscribe(x => {
      if (x) {
        x.document.activity = this.data && this.data.time ? this.data.time['@id'] : null;

        this.documentsToAdd.push({ document: x.document, mediaFile: this.fileData, reminders: x.reminders, relations: x.relations });
        this.documentsToShow.push({ name: x.document.name, media: { type: 'document' } });
      }
    });
    this.subscription.add(subscr);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  setSelectModels() {
    this.store.select(selectTimeEntryTypesPaginator).subscribe(data => {
      if (data) {
        this.timeEntryTypes$.next({ ...this.timeEntryTypes$.value, dataSource: data.member });
        if (this.data && this.data.time) {
          this.timeEntryForm.get('timeEntryType').setValue(this.data.time.timeEntryType ? [this.data.time.timeEntryType.id + ''] : null);
        }
      }
    });


    this.loadMatter();
    this.loadUsers();
  }

  loadMatter() {
    if (this.data?.contact) {
      this.filterMatters['contact.id'] = this.data.contact.id;
      this.filterMatters.status = 'open';
      this.subscription.add(
        this.matterService.get(environment.defaultPage, environment.countItemInSelect, this.filterMatters).subscribe(responce => {
          this.matters = responce.member;
          const matter = this.matters && this.matters.find(mat => mat.id === this.timeEntryForm.get('matterId').value);
          if (!matter && this.timeEntryForm.get('matterId').value) {
            this.matterService
              .getMatterById(this.timeEntryForm.get('matterId').value)
              .pipe(take(1))
              .subscribe(res => {
                this.matters.push(res);
              });
          }
          this.setMattersData();
        }),
      );
    } else {
      this.subscription.add(
        this.store.select(selectMatterListForSelect).subscribe(matters => {
          if (!matters) {
            this.store.dispatch(
              new LoadMattersForList({
                page: environment.defaultPage,
                itemsPerPage: environment.countItemInSelect,
              }),
            );
            return;
          }
          this.matters = matters;
          const matter = this.matters && this.matters.find(x => x.id === this.timeEntryForm.get('matterId').value);
          if (!matter && this.timeEntryForm.get('matterId').value) {
            this.matterService
              .getMatterById(this.timeEntryForm.get('matterId').value)
              .pipe(take(1))
              .subscribe(res => {
                this.matters.push(res);
              });
          }
          this.setMattersData();
        }),
      );
    }
  }

  private setMattersData() {
    if (this.data && this.data.matter && !this.data.time) { //  && this.initFirstMatter
      if (this.matters && !this.matters.find(item => +item.id === +this.data.matter.id)) {
        this.matters.push(this.data.matter);
      }
      this.timeEntryForm.get('matterId').setValue(this.data.matter ? this.data.matter.id : null);
    }
    if (this.data && this.data.time && this.data.time.matter) {
      if (this.matters && !this.matters.find(item => +item.id === +this.data.time.matter.id)) {
        this.matters.push(this.data.time.matter);
        this.onChangeMatter(this.data.time.matter);
      } else {
        this.onChangeMatter(this.matters.find(item => +item.id === +this.data.time.matter.id));
      }
      this.timeEntryForm.get('matterId').setValue(this.data.time.matter ? this.data.time.matter.id : null);
    }
  }

  loadUsers() {
    this.subscription.add(
      this.store.select(selectListUsersForSelect).subscribe(resp => {
        if (resp == null) {
          this.store.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (resp) {
          this.users = resp;
          this.timeEntryForm
            .get('responsiblePersonId')
            .setValue(this.authService.currentUserValue ? this.authService.currentUserValue.id : null);
          if (this.data && this.data.time) {
            this.timeEntryForm
              .get('responsiblePersonId')
              .setValue(this.data.time.responsiblePerson ? this.data.time.responsiblePerson.id : null);
          }
          if (this.data && this.data.responsible) {
            this.timeEntryForm.get('responsiblePersonId').setValue(this.data.responsible ? this.data.responsible.id : null);
          }
          if (!this.users.find(x => x.id === this.authService.currentUserValue.id)) {
            this.store.select(selectCurrentUser).pipe(filter(v => !!v), first()).subscribe(currentUser => {
              this.users.push(currentUser);
              this.timeEntryForm.get('responsiblePersonId').setValue(currentUser.id);
            });
          }
        }
      }),
    );
  }

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.filterMatters.name = e.text;
    this.filterMatters.status = 'open';
    this.comboBoxMatter?.showSpinner();
    this.debounceService.loadDataDebounce(TimeModalDebounceEnum.matters, this.matterService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filterMatters,
    ]);
    this.debounceService.getValue(TimeModalDebounceEnum.matters).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.matters = resp?.member as any;
      this.comboBoxMatter?.hideSpinner();
    });
  };

  public onFilteringResponsible: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersUser.name = e.text;
    this.comboBoxUser?.showSpinner();
    this.debounceService.loadDataDebounce(TimeModalDebounceEnum.responsible, this.teamMemberService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filtersUser,
    ]);
    this.debounceService.getValue(TimeModalDebounceEnum.responsible).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.users = resp?.member;
      this.comboBoxUser?.hideSpinner();
    });
  };

  startTimer() {
    if (this.timekeeperService.isPending) {
      return;
    }
    this.timekeeperService.isPending = true;

    const timerData = this.getTimerData();

    this.timekeeperService.createTimer(timerData).subscribe(res => {
      if (!this.data || !this.data.time || !this.data.time.timer) {
        const params: TimeEntryParams = {
          'date[after]': moment().format('YYYY-MM-DD'),
          'date[before]': moment().format('YYYY-MM-DD'),
        };
        this.timekeeperService.fetchTimeEntries(params).subscribe(value => {
          this.data = { ...this.data, time: this.timekeeperService.timeEntries.find(t => t.timer) };
          // this.data.time = this.timekeeperService.timeEntries.find(t => t.timer);
          this.timekeeperService.activeTimeEntry = this.data.time;
        });
      } else {
        this.timekeeperService.activeTimeEntry = this.data.time;
        this.data.time.timer = res;
      }
      this.timeEntryForm.get('duration').setValue(this.timerTranslate);
      this.duration = this.timerTranslate;
      this.disableDuration = true;
      this.timeEntryForm.get('duration').disable();
      this.timeEntryForm.get('date').disable();
      this.timeEntryForm.get('responsiblePersonId').disable();
      this.timekeeperService.isPending = false;
    });
  }

  stopTimer() {
    if (this.timekeeperService.isPending || !this.data || !this.data.time) {
      return;
    }
    this.timekeeperService.isPending = true;

    this.data.time.duration = this.timekeeperService.activeTimer;

    let timerId;
    if (this.timekeeperService.activeTimeEntry && this.timekeeperService.activeTimeEntry.timer) {
      timerId = this.timekeeperService.activeTimeEntry.timer.id;
    } else if (this.data.time && this.data.time.timer) {
      timerId = this.data.time.timer.id;
    } else {
      timerId = null;
    }

    this.data.time.timer = null;
    this.timekeeperService.removeTimer(timerId).subscribe(() => {
      this.timeEntryForm.get('duration').setValue(this.timeFormatter.convertSecondsToTableString(this.data.time.duration));
      this.duration = this.timeFormatter.convertSecondsToTableString(this.data.time.duration);
      this.disableDuration = false;
      this.timeEntryForm.get('duration').enable();
      this.timeEntryForm.get('date').enable();
      this.timeEntryForm.get('responsiblePersonId').enable();

      this.timekeeperService.isPending = false;
    });
  }

  getTimerData() {
    const data = {
      timeEntry: null,
      initialValue: null,
    };

    const responsiblePersonId = this.timeEntryForm.get('responsiblePersonId').value || this.authService.currentUserValue.id;

    if (this.data && this.data.time) {
      data.timeEntry = this.data.time;
      data.initialValue = this.data.time.duration;
    } else {
      data.timeEntry = {
        currency: this.companySettings.currency.code,
        responsiblePerson: responsiblePersonId,
        date: CustomDateAdapter.convertToUniversalString(new Date()),
      };
      data.initialValue = 0;
    }

    return data;
  }

  onChangeMatter(item) {
    const userId = this.timeEntryForm.get('responsiblePersonId').value;
    this.checkSelectedMatter(item);
    this.descriptionLanguage = item ? item.lang : '';
    this.timeEntryForm.get('timeEntryType').setValue(item.timeEntryType ? [item.timeEntryType.id + ''] : null);
    this.timeEntryForm.get('nonBillable').setValue(item.nonBillable);

    if (!checkRole(['ROLE_SITE_ADMIN', 'ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ACCOUNTS_MANAGER'])) {
      return;
    }

    if (item && item.id && userId && !this.getStartRates) {
      this.activitiesService.getRateMatterUser(item.id, userId).subscribe(res => {
        this.timeEntryForm.patchValue({
          rate: res.rate,
        });
      });
    }
    this.rateCurrency = item && item.currencyBill ? item.currencyBill.code : '';
    this.getStartRates = false;

  }

  checkSelectedMatter(item) {
    const matter = this.matters && item && this.matters.find(x => x.id === item.id);
    if (!matter && item) {
      this.matterService
        .getMatterById(item.id)
        .pipe(take(1))
        .subscribe(res => {
          if (this.matters) {
            this.matters.push(res);
          } else {
            this.matters = [res];
          }
        });
    }
  }

  onChangeResponsible(item) {
    const matterId = this.timeEntryForm.get('matterId').value;
    if (!checkRole(['ROLE_SITE_ADMIN', 'ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ACCOUNTS_MANAGER'])) {
      return;
    }
    if (item && item.id && matterId && !this.getStartRates) {
      this.activitiesService.getRateMatterUser(matterId, item.id).subscribe(res => {
        this.timeEntryForm.patchValue({
          rate: res.rate,
        });
      });
    }
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  fixDuration(event) {
    // від 1 до 9 і :
    if ((event.which >= 48 && event.which <= 57) || event.which === 58) {
      return true;
    }
    return false;
  }

  fixCountDuration(event) {
    setTimeout(() => {
      const currentValue = event.split(':');
      const countAfter = currentValue[currentValue.length - 1].length;
      if (event.includes(':') && countAfter === 1) {
        if (currentValue[1] > 5) {
          currentValue[1] = 5;
          this.duration = currentValue[0] + ':' + currentValue[1];
        }
      }
      if (event.includes(':') && countAfter > 2) {
        if (currentValue[1][0] > 5) {
          this.duration = currentValue[0] + ':' + '5' + currentValue[1][1];
        } else {
          this.duration = currentValue[0] + ':' + currentValue[1][0] + currentValue[1][1];
        }
      }
      this.timeEntryForm.get('duration').setValue(this.duration);
    }, 200);
  }

  public filterTimeEntryTypes: EmitType<any> = (e: FilteringEventArgs) => {
    if (e.text === '') {
      e.updateData(this.timeEntryTypes$.value as any);
    } else {
      const filteringData = [];
      this.timeEntryTypes$.value.dataSource.forEach(i => {
        if (i.name.toUpperCase().indexOf(e.text.toUpperCase()) !== -1) {
          filteringData.push(i);
        }
      });
      e.updateData(filteringData);
    }
  };

  fixAmount(event) {
    setTimeout(() => {
      let temp = event.target.value;
      const currentValue = temp.split('.');
      if (currentValue.length > 2) {
        currentValue.pop();
        temp = currentValue[0] + '.' + currentValue[1];
      }
      const countAfter = currentValue[currentValue.length - 1].length;
      if (temp.includes('.') && countAfter > 2) {
        if (currentValue[1][0] > 9) {
          temp = currentValue[0] + '.' + '9' + currentValue[1][1];
        } else {
          temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
        }
      }
      this.timeEntryForm.get('rate').setValue(temp);
    }, 100);

    if ((event.which >= 48 && event.which <= 57) || event.which === 46) {
      return true;
    }
    return false;
  }

  public onChangeActivityType($event: any) {
    this.timeEntryForm.get('timeEntryType').setValue($event.itemData.id);
  }
}
