import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, tap, share } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  GetBankAccounts,
  GetBankAccountsSuccess,
  GetBankAccount,
  GetBankAccountSuccess,
  EBankAccountsActions,
  AddBankAccount,
  AddBankAccountSuccess,
  UpdateBankAccount,
  UpdateBankAccountSuccess,
  DeleteBankAccount,
  DeleteBankAccountSuccess,
  GetBankAccountsByCurrency,
  GetBankAccountsByCurrencySuccess,
  GetBankAccountTotal,
  GetBankAccountTotalSuccess,
  GetBankAccountsFailure,
  GetBankAccountsByCurrencyFailure,
  GetBankAccountFailure,
  AddBankAccountFailure,
  UpdateBankAccountFailure,
  DeleteBankAccountFailure,
  GetBankAccountTotalFailure
} from '../actions/bank-account.actions';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { BankAccountService } from '@app/modules/bank-accounts/services/bank-account.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RoutesService } from '@app/helpers/routes.service';

@Injectable()
export class BankAccountEffects {

  getBankAccounts$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccounts>(EBankAccountsActions.GET_BANK_ACCOUNTS),
    switchMap(action => {
      return this.bankAccountService.getBankAccounts(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetBankAccountsSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetBankAccountsFailure(error));
        })
      );
    }),
    share()
  ));


  getBankAccountsByCurrency$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountsByCurrency>(EBankAccountsActions.GET_BANK_ACCOUNTS_BY_CURRENCY),
    switchMap(action => {
      return this.bankAccountService.getBankAccountsByCurrency(action.payload).pipe(
        mergeMap(resp => {
          return [new GetBankAccountsByCurrencySuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetBankAccountsByCurrencyFailure(error));
        })
      );
    }),
    share()
  ));


  getBankAccount$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccount>(EBankAccountsActions.GET_BANK_ACCOUNT),
    switchMap(action => {
      return this.bankAccountService.getBankAccountById(action.payload).pipe(
        mergeMap(resp => {
          return [new GetBankAccountSuccess(resp as BankAccountExtended)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetBankAccountFailure(error));
        })
      );
    }),
    share()
  ));


  addBankAccount$ = createEffect(() => this.actions$.pipe(
    ofType<AddBankAccount>(EBankAccountsActions.ADD_BANK_ACCOUNT),
    switchMap(action => {
      return this.bankAccountService.addBankAccount(action.payload).pipe(
        mergeMap(x => {
          this.toastrService.success(this.translate.instant('bankAccountAdded'));
          return of(new AddBankAccountSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddBankAccountFailure(error));
        })
      );
    })
  ));


  addBankAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddBankAccountSuccess>(EBankAccountsActions.ADD_BANK_ACCOUNT_SUCCESS),
    tap(() => {
      this.router.navigateByUrl('/bank-accounts');
    })
  ), { dispatch: false });


  updateBankAccount$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBankAccount>(EBankAccountsActions.UPDATE_BANK_ACCOUNT),
    switchMap(action => {
      return this.bankAccountService.updateBankAccount(action.payload).pipe(
        mergeMap(x => {
          return of(new UpdateBankAccountSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new UpdateBankAccountFailure(error));
        })
      );
    })
  ));


  updateBankAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBankAccountSuccess>(EBankAccountsActions.UPDATE_BANK_ACCOUNT_SUCCESS),
    switchMap(() => {
      this.toastrService.success(this.translate.instant('bankAccountEdited'));
      return of(new GetBankAccountSuccess(null));
    }),
    tap(() => {
      if (this.routesService.getPreviousUrl() && this.routesService.getPreviousUrl() !== 'null') {
        this.router.navigateByUrl(this.routesService.getPreviousUrl());
      } else {
        this.router.navigateByUrl('/bank-accounts');
      }
    })
  ), { dispatch: false });


  deleteBankAccount$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteBankAccount>(EBankAccountsActions.DELETE_BANK_ACCOUNT),
    switchMap(action => {
      return this.bankAccountService.deleteBankAccount(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.success(this.translate.instant('bankAccountDeleted'));
          return of(new DeleteBankAccountSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteBankAccountFailure(error));
        })
      );
    })
  ));


  deleteBankAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteBankAccountSuccess>(EBankAccountsActions.DELETE_BANK_ACCOUNT_SUCCESS),
    switchMap(() => {
      return of(new GetBankAccountSuccess(null));
    }),
    tap(() => {
      this.router.navigateByUrl('/bank-accounts');
    })
  ), { dispatch: false });


  getBankAccountTotal$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountTotal>(EBankAccountsActions.GET_BANK_ACCOUNT_TOTAL),
    switchMap(action => {
      return this.bankAccountService.getBankAccountTotal(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetBankAccountTotalSuccess(resp)];
        }),
        catchError(error => {
          // will add error handler later
          return of(new GetBankAccountTotalFailure(error));
        })
      );
    }),
    share()
  ));

  constructor(
    private actions$: Actions,
    private bankAccountService: BankAccountService,
    private router: Router,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private routesService: RoutesService
  ) {}
}
