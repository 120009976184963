import { Action } from '@ngrx/store';
import { IRegisterDto } from '@app/models/interfaces/register.interface';
import { Payload, IUser, ResponseBodyAuth } from '@app/models';

export enum EAuthActions {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  SAVE_JWT_PAYLOAD = '[Auth] Save JWT payload',
  LOGIN_FAILURE = '[Auth] Login Failure',
  REGISTER = '[Auth] Register',
  REGISTER_SUCCESS = '[Auth] Register Success',
  REGISTER_FAILURE = '[Auth] Register Failure',
  LOGOUT = '[Auth] Logout',
  FORGOT_CONFIRM = '[Auth] Forgot Confirm',
  FORGOT_CONFIRM_SUCCESS = '[Auth] Forgot Confirm Success',
  FORGOT_CONFIRM_FAILURE = '[Auth] Forgot Confirm Failure',
  GET_CURRENT_USER = '[Auth] Get Current User',
  GET_CURRENT_USER_SUCCESS = '[Auth] Get Current User Success',
  UPDATE_USER = '[TEAM MEMBER] Update user',
  UPDATE_USER_SUCCESS = '[TEAM MEMBER] Update user success',
  UPDATE_SIDEBAR_STATUS = '[TEAM MEMBER] Update sidebar status',
  UPDATE_SIDEBAR_STATUS_SUCCESS = '[TEAM MEMBER] Update sidebar status success',
  OPTIMISTIC_UPDATE_VIBER = '[TEAM MEMBER] Optimistic update viber',
  OPTIMISTIC_UPDATE_TELEGRAM = '[TEAM MEMBER] Optimistic update telegram',
  UPDATE_USER_LANG = '[USER] Update locale',
  UPDATE_USER_LANG_SUCCESS = '[USER] Update locale success'
}

export class Login implements Action {
  public readonly type = EAuthActions.LOGIN;

  constructor(public payload: IUser) {}
}

export class LoginSuccess implements Action {
  public readonly type = EAuthActions.LOGIN_SUCCESS;

  constructor(public payload: ResponseBodyAuth, public fromModal: boolean) {}
}

export class SaveJWTPayload implements Action {
  public readonly type = EAuthActions.SAVE_JWT_PAYLOAD;

  constructor(public payload: Payload) {}
}

export class LoginFailure implements Action {
  public readonly type = EAuthActions.LOGIN_FAILURE;

  constructor(public payload: ResponseBodyAuth) {}
}

export class Register implements Action {
  public readonly type = EAuthActions.REGISTER;

  constructor(public payload: IRegisterDto) {}
}

export class RegisterSuccess implements Action {
  public readonly type = EAuthActions.REGISTER_SUCCESS;

  constructor(public payload: ResponseBodyAuth) {}
}

export class RegisterFailure implements Action {
  public readonly type = EAuthActions.REGISTER_FAILURE;

  constructor(public payload: ResponseBodyAuth) {}
}

export class Logout implements Action {
  public readonly type = EAuthActions.LOGOUT;

  constructor() {}
}

export class ForgotConfirm implements Action {
  public readonly type = EAuthActions.FORGOT_CONFIRM;

  constructor(public payload: { password: string; hash: string }) {}
}

export class ForgotConfirmSuccess implements Action {
  public readonly type = EAuthActions.FORGOT_CONFIRM_SUCCESS;

  constructor(public payload: any) {}
}

export class ForgotConfirmFailure implements Action {
  public readonly type = EAuthActions.FORGOT_CONFIRM_FAILURE;

  constructor(public payload: any) {}
}

export class GetCurrentUser implements Action {
  public readonly type = EAuthActions.GET_CURRENT_USER;
  constructor(public payload: number) {}
}

export class GetCurrentUserSuccess implements Action {
  public readonly type = EAuthActions.GET_CURRENT_USER_SUCCESS;
  constructor(public payload: IUser) {}
}

export class UpdateUser implements Action {
  readonly type = EAuthActions.UPDATE_USER;

  constructor(public payload: IUser, public id: number) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = EAuthActions.UPDATE_USER_SUCCESS;

  constructor(public payload: IUser) {}
}

export class UpdateSidebarStatus implements Action {
  readonly type = EAuthActions.UPDATE_SIDEBAR_STATUS;

  constructor(public payload: { settings: [{ showSidebar: boolean; id: number }] }, public id: number) {}
}

export class UpdateSidebarStatusSuccess implements Action {
  readonly type = EAuthActions.UPDATE_SIDEBAR_STATUS_SUCCESS;

  constructor(public payload: IUser) {}
}

export class OptimisticUpdateViber implements Action {
  readonly type = EAuthActions.OPTIMISTIC_UPDATE_VIBER;

  constructor(public payload: boolean) {}
}

export class OptimisticUpdateTelegram implements Action {
  readonly type = EAuthActions.OPTIMISTIC_UPDATE_TELEGRAM;

  constructor(public payload: boolean) {}
}

export class UpdateUserLang implements Action {
  readonly type = EAuthActions.UPDATE_USER_LANG;

  constructor(public payload: IUser, public userId: number) {}
}

export class UpdateUserLangSuccess implements Action {
  readonly type = EAuthActions.UPDATE_USER_LANG_SUCCESS;

  constructor(public payload: IUser) {}
}

export type AuthActions =
  | Login
  | LoginSuccess
  | SaveJWTPayload
  | LoginFailure
  | Register
  | RegisterSuccess
  | RegisterFailure
  | Logout
  | ForgotConfirm
  | ForgotConfirmSuccess
  | ForgotConfirmFailure
  | GetCurrentUser
  | GetCurrentUserSuccess
  | UpdateUser
  | UpdateUserSuccess
  | UpdateSidebarStatus
  | UpdateUserLang
  | UpdateUserLangSuccess
  | UpdateSidebarStatusSuccess
  | OptimisticUpdateViber
  | OptimisticUpdateTelegram;
