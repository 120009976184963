import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Centrifuge from 'centrifuge';
import { environment } from '@environments/environment';
import { ITokenCentrifuge } from '@app/models/interfaces/notifications.interface';
import { selectUserState } from '@app/auth/_store/selectors/auth.selector';
import { Store } from '@ngrx/store';
import { Store as StoreNGXS } from '@ngxs/store';
import { IAppState } from '@app/store/state/app.state';
import { filter, first } from 'rxjs/operators';
import { ChatWSMessage } from '@app/chat/states/chat/chat.actions';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  public centrifugo = new Centrifuge(environment.ws, {});

  constructor(private http: HttpClient, private store: Store<IAppState>, private storeNGXS: StoreNGXS) {
    this.store.select(selectUserState)
      .pipe(
        filter(user => !!user),
        first(),
      ).subscribe((user) => {
      this.centrifugo.subscribe(`chat#${user.id}`, payload => {
        this.storeNGXS.dispatch(new ChatWSMessage(payload));
      });
    });
  }


  getCentrifugoCredentials() {
    return this.http.get(environment.wsToken);
  }

  webSocketConnect() {
    this.getCentrifugoCredentials().subscribe((token: ITokenCentrifuge) => {
      this.centrifugo.setToken(token.token);
      if (token) {
        this.centrifugo.connect();
      }
    });
  }

  webSocketDisconnect() {
    this.centrifugo.disconnect();
  }
}
