// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-video-template {
  position: relative;
  width: 800px;
  background-color: #000;
}
.modal-video-template .close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
  cursor: pointer;
}
.modal-video-template .close-modal span {
  font-size: 15px;
  color: #fff;
}
.modal-video-template .video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.modal-video-template iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/other-modals/video-modal/video-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,UAAA;EACA,eAAA;AACJ;AACI;EACE,eAAA;EACA,WAAA;AACN;AAGE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,sBAAA;AADJ;AAIE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;AAFJ","sourcesContent":[".modal-video-template {\n  position: relative;\n  width: 800px;\n  background-color: #000;\n\n  .close-modal {\n    position: absolute;\n    top: 5px;\n    right: 5px;\n    z-index: 2;\n    cursor: pointer;\n\n    span {\n      font-size: 15px;\n      color: #fff;\n    }\n  }\n\n  .video-container {\n    position: relative;\n    width: 100%;\n    height: 0;\n    padding-bottom: 56.25%;\n  }\n\n  iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
