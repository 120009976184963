// generated by the plop
import { v4 as uuidv4 } from 'uuid';
import {
  ChatWSMessage,
  ClearMessages,
  CreateMessage,
  CreateMessageFail,
  CreateMessageSuccess,
  ExpandChat,
  GetMessages,
  GetMessagesFail,
  GetMessagesSuccess,
  LoadCountPinMessages,
  LoadCountPinMessagesFail,
  LoadCountPinMessagesSuccess,
  LoadPinnedMessages,
  LoadPinnedMessagesFail,
  LoadPinnedMessagesSuccess,
  PinMessage,
  PinMessageFail,
  PinMessageSuccess,
  ReadMessages,
  ReadMessagesFail,
  ReadMessagesSuccess,
  RemoveMessage,
  RemoveMessageFail,
  RemoveMessageSuccess,
  RemoveOverloadMessages,
  SetCurrentChat,
  UpdateMessage,
  UpdateMessageFail,
  UpdateMessageSuccess,
} from './chat.actions';
import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { CHAT_STATE_DEFAULT, ChatStateModel } from './chat.model';
import { ChatService } from '@app/chat/services/chat.service';
import { PROGRESS_STATUSES } from '@app/shared/symbols';
import { MessageModel, ReadMessageModel } from '@app/chat/symbols/symbols';
import { AuthService } from '@app/auth/_services/auth.service';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';

@State({ name: 'Chat', defaults: CHAT_STATE_DEFAULT })
@Injectable()
export class ChatState implements NgxsOnInit {

  constructor(private chatService: ChatService, private authService: AuthService) {
  }


  @Selector()
  static removeMessagesStatus(state: ChatStateModel): ChatStateModel['removeMessagesStatus'] {
    return state.removeMessagesStatus;
  }

  @Selector()
  static updateMessageStatus(state: ChatStateModel): ChatStateModel['updateMessageStatus'] {
    return state.updateMessageStatus;
  }


  @Selector()
  static pinStatuses(state: ChatStateModel): ChatStateModel['changePinStatus'] {
    return state.changePinStatus;
  }

  @Selector()
  static chatMessages(state: ChatStateModel): ChatStateModel['chatMessages'] {
    return state.chatMessages;
  }

  @Selector()
  static chatPinnedMessages(state: ChatStateModel): ChatStateModel['chatPinnedMessages'] {
    return state.chatPinnedMessages;
  }


  @Selector()
  static countPinMessages(state: ChatStateModel): number {
    return state.countPinMessages;
  }

  @Selector()
  static loadingMessagesStatus(state: ChatStateModel): ChatStateModel['loadingMessagesStatus'] {
    return state.loadingMessagesStatus;
  }

  @Selector()
  static isExpanded(state: ChatStateModel): ChatStateModel['isExpanded'] {
    return state.isExpanded;
  }

  @Selector()
  static createMessageStatus(state: ChatStateModel): ChatStateModel['createMessageStatus'] {
    return state.createMessageStatus;
  }

  @Selector()
  static currentChat(state: ChatStateModel): ChatStateModel['currentChat'] {
    return state.currentChat;
  }

  public ngxsOnInit(ctx: StateContext<ChatStateModel>) {
    ctx.patchState({
      entityName: null,
      entityId: null,
    });
  }

  @Action(SetCurrentChat)
  setCurrentChat(ctx: StateContext<ChatStateModel>, { chat }: SetCurrentChat): void {
    ctx.patchState({
      currentChat: chat,
    });
  }

  @Action(ExpandChat)
  setExpanded(ctx: StateContext<ChatStateModel>): void {
    ctx.patchState({
      isExpanded: !ctx.getState().isExpanded,
    });
  }

// added actions by plop
  @Action(GetMessages)
  getMessages(ctx: StateContext<ChatStateModel>, { filter, isLazyLoading }: GetMessages): void {

    if (!isLazyLoading) {
      ctx.dispatch(new ClearMessages());
    }

    ctx.setState(patch({
      loadingMessagesStatus: PROGRESS_STATUSES.IN_PROGRESS,
      entityName: filter.entityName,
      entityId: filter.entityId,
    }));

    this.chatService.getMessages(filter).subscribe({
      next: res => ctx.dispatch(new GetMessagesSuccess(res, isLazyLoading)),
      error: err => ctx.dispatch(new GetMessagesFail(err)),
    });
  }

  @Action(GetMessagesSuccess)
  getMessagesSuccess(ctx: StateContext<ChatStateModel>, { messages, isLazyLoading }: GetMessagesSuccess): void {
    const state = ctx.getState();
    ctx.patchState({
      chatMessages: isLazyLoading ? [
        ...messages.map(message => ({
          ...message,
          uuid: `${message.id}`,
        })).reverse(),
        ...state.chatMessages,
      ] : messages.map(message => ({
        ...message,
        uuid: `${message.id}`,
      })).reverse(),
      loadingMessagesStatus: PROGRESS_STATUSES.SUCCEED,
    });

  }

  @Action(GetMessagesFail)
  getMessagesFail(ctx: StateContext<ChatStateModel>, { err }: GetMessagesFail): void {
    ctx.patchState({
      loadingMessagesStatus: PROGRESS_STATUSES.INTERRUPTED,
    });
  }

// added actions by plop
  @Action(CreateMessage)
  createMessage(ctx: StateContext<ChatStateModel>, { message, documents, tasks }: CreateMessage): void {
    const uuid = uuidv4();
    ctx.patchState({
      createMessageStatus: PROGRESS_STATUSES.IN_PROGRESS,
      chatMessages: [
        ...ctx.getState().chatMessages,
        {
          ...message,
          id: null,
          uuid,
          isReaded: true,
          seenUsers: [],
          isPinned: false,
          createdAt: new Date().toISOString(),
          createdBy: {
            id: this.authService.currentUserValue.id,
            fullName: this.authService.currentUserValue.username,
          },
          documents: documents.sort((a, b) => a.id - b.id) ,
          tasks: tasks.sort((a, b) => a.id - b.id)
        },
      ],
    });

    this.chatService.createMessage(message).subscribe({
      next: res => ctx.dispatch(new CreateMessageSuccess(res, uuid)),
      error: err => ctx.dispatch(new CreateMessageFail(err)),
    });
  }

  @Action(CreateMessageSuccess)
  createMessageSuccess(ctx: StateContext<ChatStateModel>, { message, uuid }: CreateMessageSuccess): void {
    ctx.setState(patch({
          createMessageStatus: PROGRESS_STATUSES.SUCCEED,
          chatMessages: updateItem<Partial<MessageModel>>(msg => msg.uuid === uuid, {
            ...message,
            uuid,
          }),
        },
      ),
    );
  }

  @Action(CreateMessageFail)
  createMessageFail(ctx: StateContext<ChatStateModel>, { error }: CreateMessageFail): void {
    ctx.patchState({
      createMessageStatus: PROGRESS_STATUSES.INTERRUPTED,
    });
  }

  @Action(ChatWSMessage)
  chatWSMessage(ctx: StateContext<ChatStateModel>, { payload }: ChatWSMessage): void {
    const state = ctx.getState();


    let message = null;
    if (payload.data?.comment) {
      message = JSON.parse(payload.data.comment as unknown as string) as MessageModel;
    }
    if (message && (state.currentChat.entityId !== message.entityId || state.currentChat.entityName !== message.entityName)) {
      return;
    }

    ctx.patchState({
      currentChat: {
        ...state.currentChat,
        countUnread: payload.data.countUnread,
      },
    });

    switch (payload.data.type) {
      case 'post':
        if (message.createdBy.id !== this.authService.currentUserValue.id) {
          ctx.patchState({
            chatMessages: [...state.chatMessages, { ...message, isReaded: false, uuid: message.id }],
          });
        }
        if (message.createdBy.id !== this.authService.currentUserValue.id) {
          this.chatService.playNotificationsPing();
        }
        break;
      case 'delete':
        if (message.createdBy.id !== this.authService.currentUserValue.id) {
          ctx.patchState({
            chatMessages: state.chatMessages.filter(msg => msg.id !== message.id),
          });
        }
        break;
      case 'patch':
        if (message.createdBy.id !== this.authService.currentUserValue.id) {
          ctx.setState(patch({
            chatMessages: updateItem<Partial<MessageModel>>(msg => msg.id === message.id, message),
          }));
        }
        break;
      case 'read':

        const comments = JSON.parse(payload.data.comments as unknown as string) as ReadMessageModel[];

        ctx.patchState({
          chatMessages: state.chatMessages.map(msg => {
            const comment = comments.find(comment => comment.id === msg.id);
            if (comment) {
              return {
                ...msg,
                isReaded: true,
                seenUsers: comment.seenUsers,
              } as MessageModel;
            }
            return msg;
          }),
        });

        break;

    }

  }


// added actions by plop
  @Action(ReadMessages)
  readMessages(ctx: StateContext<ChatStateModel>, { messageIds }: ReadMessages): void {
    ctx.patchState({
      readMessagesStatus: PROGRESS_STATUSES.IN_PROGRESS,
    });
    this.chatService.readMessages(messageIds).subscribe({
      next: res => ctx.dispatch(new ReadMessagesSuccess(messageIds)),
      error: err => ctx.dispatch(new ReadMessagesFail(err)),
    });
  }

  @Action(ReadMessagesSuccess)
  readMessagesSuccess(ctx: StateContext<ChatStateModel>, { messageIds }: ReadMessagesSuccess): void {
    ctx.patchState({
      readMessagesStatus: PROGRESS_STATUSES.SUCCEED,
      currentChat: {
        ...ctx.getState().currentChat,
        countUnread: 0,
      },
      chatMessages: ctx.getState().chatMessages.map(msg => {
        if (messageIds.includes(msg.id)) {
          return {
            ...msg,
            isReaded: true,
          };
        }
        return msg;
      }),
    });

  }

  @Action(ReadMessagesFail)
  readMessagesFail(ctx: StateContext<ChatStateModel>, { error }: ReadMessagesFail): void {
    ctx.patchState({
      readMessagesStatus: PROGRESS_STATUSES.INTERRUPTED,
    });
  }

  @Action(ClearMessages)
  clearMessages(ctx: StateContext<ChatStateModel>): void {
    ctx.patchState({
      chatMessages: [],
    });
  }

// added actions by plop
  @Action(PinMessage)
  pinMessage(ctx: StateContext<ChatStateModel>, { messageId, pin }: PinMessage): void {

    ctx.patchState({
      changePinStatus: {
        ...ctx.getState().changePinStatus,
        [messageId]: PROGRESS_STATUSES.IN_PROGRESS,
      },
    });

    if (pin) {
      this.chatService.pinMessage(messageId, this.authService.currentUserValue.id).subscribe({
        next: res => ctx.dispatch(new PinMessageSuccess(res, messageId, pin)),
        error: err => ctx.dispatch(new PinMessageFail(err, messageId)),
      });
    } else {
      this.chatService.unpinMessage(messageId, this.authService.currentUserValue.id).subscribe({
        next: res => ctx.dispatch(new PinMessageSuccess(res, messageId, pin)),
        error: err => ctx.dispatch(new PinMessageFail(err, messageId)),
      });
    }
  }

  @Action(PinMessageSuccess)
  pinMessageSuccess(ctx: StateContext<ChatStateModel>, { messageId, pin }: PinMessageSuccess): void {

    ctx.setState(patch({
          changePinStatus: {
            ...ctx.getState().changePinStatus,
            [messageId]: PROGRESS_STATUSES.SUCCEED,
          },

          chatMessages: updateItem<Partial<MessageModel>>(message => message.id === messageId, {
            ...ctx.getState().chatMessages.find(msg => msg.id === messageId),
            isPinned: pin,
          }),

          countPinMessages: ctx.getState().countPinMessages + (pin ? 1 : -1),
        },
      ),
    );

    ctx.dispatch(new LoadPinnedMessages({
      entityName: ctx.getState().entityName,
      entityId: ctx.getState().entityId,
      page: 1,
      itemsPerPage: 1000,
      'pinUsers.id': this.authService.currentUserValue.id,
    }));
  }

  @Action(PinMessageFail)
  pinMessageFail(ctx: StateContext<ChatStateModel>, { error, messageId }: PinMessageFail): void {
    ctx.patchState({
      changePinStatus: {
        ...ctx.getState().changePinStatus,
        [messageId]: PROGRESS_STATUSES.INTERRUPTED,
      },
    });
    console.error(error);
  }

// added actions by plop
  @Action(RemoveMessage)
  removeMessage(ctx: StateContext<ChatStateModel>, { messageId }: RemoveMessage): void {
    ctx.setState(patch({
        removeMessagesStatus: {
          ...ctx.getState().changePinStatus,
          [messageId]: PROGRESS_STATUSES.IN_PROGRESS,
        },
        chatMessages: removeItem<Partial<MessageModel>>(msg => msg.id === messageId),
      }),
    );

    this.chatService.removeMessage(messageId).subscribe({
      next: () => ctx.dispatch(new RemoveMessageSuccess(messageId)),
      error: err => ctx.dispatch(new RemoveMessageFail(err, messageId)),
    });
  }

  @Action(RemoveMessageSuccess)
  removeMessageSuccess(ctx: StateContext<ChatStateModel>, { messageId }: RemoveMessageSuccess): void {
    const state = ctx.getState();
    ctx.patchState({
      removeMessagesStatus: {
        ...ctx.getState().changePinStatus,
        [messageId]: PROGRESS_STATUSES.SUCCEED,
      },
    });
  }

  @Action(RemoveMessageFail)
  removeMessageFail(ctx: StateContext<ChatStateModel>, { error, messageId }: RemoveMessageFail): void {
    ctx.patchState({
      removeMessagesStatus: {
        ...ctx.getState().changePinStatus,
        [messageId]: PROGRESS_STATUSES.IN_PROGRESS,
      },
    });
  }

// added actions by plop
  @Action(UpdateMessage)
  updateMessage(ctx: StateContext<ChatStateModel>, { message, tasks, documents }: UpdateMessage): void {
    ctx.setState(patch({
        updateMessageStatus: PROGRESS_STATUSES.IN_PROGRESS,
        chatMessages: updateItem<Partial<MessageModel>>(msg => msg.id === message.id, patch({
          ...message,
          documents,
          tasks,
        })),
      }),
    );

    this.chatService.updateMessage(message).subscribe({
      next: () => ctx.dispatch(new UpdateMessageSuccess()),
      error: err => ctx.dispatch(new UpdateMessageFail(err)),
    });
  }

  @Action(UpdateMessageSuccess)
  updateMessageSuccess(ctx: StateContext<ChatStateModel>): void {
    ctx.patchState({
      updateMessageStatus: PROGRESS_STATUSES.SUCCEED,
    });

  }

  @Action(UpdateMessageFail)
  updateMessageFail(ctx: StateContext<ChatStateModel>): void {
    ctx.patchState({
      updateMessageStatus: PROGRESS_STATUSES.INTERRUPTED,
    });
  }

// added actions by plop
  @Action(LoadCountPinMessages)
  loadCountPinMessages(ctx: StateContext<ChatStateModel>, { userId, entityId, entityName }: LoadCountPinMessages): void {

    const filter = {
      entityId,
      entityName,
    };

    this.chatService.getPinMessagesCount(userId, filter).subscribe({
      next: res => ctx.dispatch(new LoadCountPinMessagesSuccess(res)),
      error: err => ctx.dispatch(new LoadCountPinMessagesFail(err)),
    });
  }

  @Action(LoadCountPinMessagesSuccess)
  loadCountPinMessagesSuccess(ctx: StateContext<ChatStateModel>, { count }: LoadCountPinMessagesSuccess): void {
    ctx.patchState({
      countPinMessages: count,
    });
  }

  @Action(LoadCountPinMessagesFail)
  loadCountPinMessagesFail(ctx: StateContext<ChatStateModel>, { error }: LoadCountPinMessagesFail): void {
    console.error(error);
  }

// added actions by plop
  @Action(LoadPinnedMessages)
  loadPinnedMessages(ctx: StateContext<ChatStateModel>, { filter }: LoadPinnedMessages): void {

    this.chatService.getMessages(filter).subscribe({
      next: res => ctx.dispatch(new LoadPinnedMessagesSuccess(res)),
      error: err => ctx.dispatch(new LoadPinnedMessagesFail(err)),
    });
  }

  @Action(LoadPinnedMessagesSuccess)
  loadPinnedMessagesSuccess(ctx: StateContext<ChatStateModel>, { messages }: LoadPinnedMessagesSuccess): void {

    ctx.patchState({
      chatPinnedMessages: messages.reverse(),
    });
  }

  @Action(LoadPinnedMessagesFail)
  loadPinnedMessagesFail(ctx: StateContext<ChatStateModel>, { error }: LoadPinnedMessagesFail): void {
    console.error(error);
  }


  @Action(RemoveOverloadMessages)
  removeOverloadMessages(ctx: StateContext<ChatStateModel>): void {
    ctx.patchState({
      chatMessages: ctx.getState().chatMessages.slice(60, ctx.getState().chatMessages.length),
    });
  }
}


