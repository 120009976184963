// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  margin: 0 0 20px 0;
}

:host button {
  margin-top: 0;
}
:host .sended-text {
  color: #687385;
  position: relative;
}
:host .sended-text .resend-btn {
  margin-top: 15px;
  box-shadow: 0px 1px 1px rgba(98, 98, 98, 0.0509803922);
  border: 1px solid #DFE5EB;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: #222222;
  padding: 0px 10px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.24s;
  font-size: 13px;
  line-height: 13px;
  cursor: pointer;
}
:host .sended-text .resend-btn:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(199, 221, 255) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
  border: 1px solid #CFE0FA;
}
:host .sended-text .email {
  color: #1252AF;
}
:host .sended-text .air-container {
  position: absolute;
  top: -92px;
}
:host p {
  color: #687385;
  padding-right: 20px;
  margin-bottom: 24px;
}
:host .form-container {
  position: relative;
}

.left-container {
  top: calc(50% - 114px);
}

.right-container {
  top: calc(50% - 70px);
}`, "",{"version":3,"sources":["webpack://./src/app/auth/components/forgot/forgot.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAKE;EACE,aAAA;AAFJ;AAIE;EACE,cAAA;EA4BA,kBAAA;AA7BJ;AAGI;EACE,gBAAA;EACA,sDAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AADN;AAEM;EACE,wQAAA;EACA,yBAAA;AAAR;AAGI;EACE,cAAA;AADN;AAII;EACE,kBAAA;EACA,UAAA;AAFN;AAKE;EACE,cAAA;EACA,mBAAA;EACA,mBAAA;AAHJ;AAKE;EACE,kBAAA;AAHJ;;AAQA;EACE,sBAAA;AALF;;AASA;EACE,qBAAA;AANF","sourcesContent":["h1 {\n  margin: 0 0 20px 0;\n}\n\n\n\n:host {\n  button {\n    margin-top: 0;\n  }\n  .sended-text {\n    color: #687385;\n\n    .resend-btn {\n      margin-top: 15px;\n      box-shadow: 0px 1px 1px #6262620D;\n      border: 1px solid #DFE5EB;\n      border-radius: 6px;\n      font-weight: 600;\n      letter-spacing: 0.13px;\n      color: #222222;\n      padding: 0px 10px;\n      height: 28px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      width: fit-content;\n      transition: 0.240s;\n      font-size: 13px;\n      line-height: 13px;\n      cursor: pointer;\n      &:hover {\n        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(199, 221, 255) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;\n        border: 1px solid #CFE0FA;\n      }\n    }\n    .email {\n      color: #1252AF;\n    }\n    position: relative;\n    .air-container {\n      position: absolute;\n      top: -92px;\n    }\n  }\n  p {\n    color: #687385;\n    padding-right: 20px;\n    margin-bottom: 24px;\n  }\n  .form-container {\n    position: relative;\n  }\n}\n\n\n.left-container {\n  top: calc(50% - 114px);\n\n}\n\n.right-container {\n  top: calc(50% - 70px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
