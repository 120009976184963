import { initialNewsState } from '@app/modules/layout/news/store/state/news.state';
import * as Actions from '../actions/news.actions';
import { ENewsActions } from '../actions/news.actions';

export const newsReducers = (state = initialNewsState, action: Actions.NewsActions) => {
  switch (action.type) {
    case ENewsActions.GET_NEWS_SUCCESS: {
      let nextPage = state.currentPage;

      if (action.payload.member.length) {
        nextPage += 1;
      }
      if (!action.currentPage) {
        nextPage = 1;
      }
      if (action.renew) {
        state.member = [];
        nextPage = 2;
      }
      action.payload.member = state.member.concat(action.payload.member);
      return { ...state, ...action.payload, page: nextPage };
    }
    case ENewsActions.ADD_NEWS: {
      const data = state.member;
      data.unshift(action.payload.data);
      return { ...state, data };
    }
    case ENewsActions.LIKE_NEWS: {
      const news = state.member;
      if (action.payload.news.isLiked === false) {
        news[action.payload.id] = { ...action.payload.news, isLiked: true };
      }
      return { ...state, data: news };
    }
    case ENewsActions.READ_NEWS: {
      const news = state.member;
      if (action?.payload?.isReaded === false) {
        const idx = news.findIndex(item => item.id === action.payload.id);
        news[idx] = { ...action.payload, isReaded: true };
      }
      return { ...state, data: news };
    }
    default:
      return state;
  }
};
