import { Currency } from '@app/enums/currency.enum';
import { Language } from '@app/enums/language.enum';
import { TaskType } from '@app/enums/task-type.enum';
import { TemplateTypes } from '@app/enums/template-types.enum';
import { TypeFee } from '@app/enums/type-fee.enum';
import { TypePay } from '@app/enums/type-pay.enum';
import { checkTariff } from '@app/helpers/check-tariff';

export class Helpers {
  static populateFilters(params, filters, multiSelect = false) {
    Object.keys(filters).map(filter => {
      if (filters.hasOwnProperty(filter) && (filters[filter] || (typeof filters[filter] === 'boolean'))) {
        if (multiSelect && filter.includes('[]') || filter.includes('tagContacts.tag.id')) {
          const arrayIds = typeof filters[filter] === 'string' ? filters[filter].split(',') : filters[filter];
          for (const id of arrayIds) {
            params = params.append(filter, id);
          }
        } else {
          params = params.set(filter, filters[filter]);
        }
      }
    });
    return params;
  }

  static getCurrenciesList() {
    if (checkTariff([2, 3])) {
      return Object.keys(Currency).filter(x => x.length > 1 && x !== 'USDT' && x !== 'EURT');
    } else {
      return Object.keys(Currency).filter(x => x.length > 1);
    }
  }
  static getLanguageList() {
    let keys = Object.keys(Language);
    keys = keys.splice(keys.length / 2);
    return keys;
  }
  static getTypeFeeList() {
    let keys = Object.keys(TypeFee);
    keys = keys.splice(keys.length / 2);
    return keys;
  }
  static getTypePayList() {
    let keys = Object.keys(TypePay);
    keys = keys.splice(keys.length / 2);
    return keys;
  }

  static getTaskTypeList() {
    let keys = Object.keys(TaskType);
    keys = keys.splice(keys.length / 2);
    return keys;
  }
  static getTemplateTypesList() {
    let keys = Object.keys(TemplateTypes);
    keys = keys.splice(keys.length / 2);
    return keys;
  }

  static linkTransform(str: string) {
    if (str && str.trim()) {
      const urls = str.match(/(((ftp|https?):\/\/)[\-\wа-я@:%_\+.~#?,&\/\/=]+)/g);
      if (urls) {
        urls.forEach(url => {
          str = str.replace(url, '<a class="cropp-link" target="_blank" href="' + url + '">' + url + '</a>');
        });
      }
      return str;
    }
    return '';
  }

  static getCurrentIconForDocumentUrl(url) {
    if (url.search('pdf') !== -1) {
      return 'j-icon-doc-pdf';
    }
    // if (list.media.url.search('png') !== -1) {
    //   return 'j-icon-doc-pdf';
    // }
    if (url.search('doc') !== -1) {
      return 'j-icon-doc_doc';
    }
    if (url.search('xlsx') !== -1) {
      return 'j-icon-doc-xls';
    }
    if (url.search('cloud') !== -1) {
      return 'j2-icon-cloud';
    }
    return 'j-icon-doc-empty';
  }

  static getCurrentImgForDocumentUrl(url) {

    if (url.search('warrant') !== -1) {
      return 'table-icon-warrant';
    }
    if (url.search('attorney') !== -1) {
      return 'table-icon-attorney';
    }
    if (url.search('pdf') !== -1) {
      return 'table-icon-pdf';
    }
    // if (list.media.url.search('png') !== -1) {
    //   return 'j-icon-doc-pdf';
    // }
    if (url.search('doc') !== -1) {
      return 'table-icon-doc';
    }
    if (url.search('xlsx') !== -1) {
      return 'table-icon-xls';
    }
    if (url.search('cloud') !== -1) {
      return 'table-icon-cloud';
    }
    return 'table-icon-defined';
  }

  static getCurrentTypeForExportDoc(url) {
    if (url.search('pdf') !== -1) {
      return 'pdf';
    }
    if (url.search('doc') !== -1) {
      return 'doc/docx';
    }
    if (url.search('xlsx') !== -1) {
      return 'xls/xlsx';
    }
    if (url.search('cloud') !== -1) {
      return 'cloud';
    }
    if (url.search('png') !== -1) {
      return 'png';
    }
    if (url.search('jpg1') !== -1) {
      return 'jpg';
    }
    if (url.search('jpeg') !== -1) {
      return 'jpeg';
    }
    if (url.search('tiff') !== -1) {
      return 'tiff';
    }
    return 'indefinedFile';
  }

  static checkSizeFile(file, size) {
    const fileSize: number = +(file.size / 1024 / 1024).toFixed(4);
    if (fileSize > size) {
      return false;
    }
    return true;
  }

  static setInputFilter(textbox, inputFilter) {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(event => {
      textbox.addEventListener(event, function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty('oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = '';
        }
      });
    });
  }
  static checkValidEmail(email) {
    const pat = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/g;
    return pat.test(email);
  }

  static arrayUnique(array, key = 'id') {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i]?.[key] === a[j]?.[key]) {
          a.splice(j--, 1);
        }
      }
    }
    return a;
  }

  static hideAllLoader() {
    const els = document.getElementsByClassName("overlay-loader");
    Array.from(els).forEach((el) => {
      console.log(el?.remove());
  });
  }
}
