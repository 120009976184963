import { IAppState } from '../state/app.state';

export const selectDocumentsPaginator = (state: IAppState) => state.documentState.paginator;

export const selectCurrentDocument = (state: IAppState) => state.documentState.selectedDocument;

export const selectCurrentActivityDocuments = (state: IAppState) => state.documentState.documentsByActivity;

export const selectCurrentTaskDocuments = (state: IAppState) => state.documentState.documentsByTask;

export const selectCorrespondenceDocuments = (state: IAppState) => state.documentState.documentsByCorrespondence;

export const selectContractDocuments = (state: IAppState) => state.documentState.documentsByContract;

export const selectCurrentBillDocuments = (state: IAppState) => state.documentState.documentsByBill;

