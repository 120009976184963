import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { fadeLeftAnimation } from '@app/helpers';
import { MatterExtended } from '@app/models/matter-extended';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { environment } from '@environments/environment';
import { CheckBoxSelectionService, ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';

import { AdditionalMatterDebounceEnum } from '../../enums/debounce-keys.enum';
import { DebounceService } from '../../helpers/debounce.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-additional-matter',
  templateUrl: './additional-matter.component.html',
  styleUrls: ['./additional-matter.component.scss'],
  providers: [CheckBoxSelectionService],
  animations: [fadeLeftAnimation],
})
export class AdditionalMatterComponent implements OnInit, OnDestroy {

  constructor(
    public fb: UntypedFormBuilder,
    private matterService: MatterService,
    private debounceService: DebounceService,
    private translateService: TranslateService,
  ) {
  }

  @Output() changeMatterEmit = new EventEmitter();
  @Output() changeDatesEmit = new EventEmitter();

  @Input() modalSwitch;
  @Input() submittedFormBill;

  @Input() set matter(matter: MatterExtended) {
    this._matter = matter;
    this.form.controls.mainMatter.setValue(matter?.id || null);
    this.form.controls.mainMatter.disable();
    this.selectedMainMatter = matter;
    this.changeClient(matter?.contact?.id);
  }


  private _matter: MatterExtended;

  get matter(): MatterExtended {
    return this._matter;
  }


  @ViewChild('sample', { static: false })
  public comboBoxObj: ComboBoxComponent;
  allowCurrent = false;
  form = new FormGroup({
    mainMatter: new FormControl(null, [Validators.required]),
    additionalMatters: new FormControl([]),
  });

  @Input() matters: MatterExtended[] = [];
  @Input() additionListMatters: MatterExtended[] = [];
  addionalMatters: MatterExtended[] = [];
  mainMatters: MatterExtended[] = [];
  @Input() contactId: number;
  submittedForm = false;
  showAddSelect = false;
  selectedMainMatter;
  filtersMatters = {
    name: '',
    'contact.id': null,
    status: 'open',
  };
  changingAddMatters = false;

  public mode = 'CheckBox';
  public fields: Object = { text: 'name', value: 'id' };


  ngOnInit(): void {
    this.form.get('mainMatter').disable();
  }

  ngOnChanges(e) {
    if (e.matters) {
      this.mainMatters = this.matters.map(el => el);
    }
    if (e.additionListMatters) {
      if (this.matter) {
        this.addionalMatters = this.additionListMatters.map(el => el).filter(m => m.id !== this.matter.id);
      } else {
        this.addionalMatters = this.additionListMatters.map(el => el);
      }
    }
  }

  ngOnDestroy(): void {
    this.debounceService.removeDebounceData();
  }

  changeMultiValue(selected) {
    const values = document.getElementsByClassName('e-delim-view e-delim-values')[0];
    const valueCustom = document.getElementsByClassName('e-delim-view e-delim-values custom-value')[0];
    if (selected.length) {
      if (!valueCustom) {
        const test = document.createElement('span');
        test.classList.value = 'e-delim-view e-delim-values custom-value';
        values.parentNode.insertBefore(test, values.nextSibling);
        test.innerHTML = `${this.translateService.instant('bill.mattersSelected')}: ${selected.length}`;
        return;
      }
      if (valueCustom) {
        valueCustom.innerHTML = `${this.translateService.instant('bill.mattersSelected')}: ${selected.length}`;
      }
    } else {
      if (valueCustom) {
        valueCustom.innerHTML = ``;
      }
    }
  }

  changeAddMatters(e: number[]) {
    this.changeMultiValue(e);
    this.mainMatters = this.matters.filter(el => !e.find(id => id === el.id));
    this.form.get('additionalMatters').setValue(e);
    if (!this.changingAddMatters) {
      this.changingAddMatters = true;
      setTimeout(() => {
        this.changeMatterEmit.emit(this.selectedMainMatter);
        this.changingAddMatters = false;
      });
    }

    if (!e?.length) {
      const elmts = document.getElementsByClassName('e-list-item e-item-focus') as any;
      for (const el of elmts) {
        el.classList.remove('e-item-focus');
      }
    }

  }


  showAdditionalSelect() {
    this.submittedFormBill = true;
    if (this.form.get('mainMatter').value) {
      this.showAddSelect = true;
    }
  }

  // очищення всіх додактових проектів
  changeClient(contactId) {
    this.form.get('additionalMatters').setValue([]);
    if (!this.matter) {
      this.form.get('mainMatter').setValue(null);
    }
    if (!contactId) {
      this.form.get('mainMatter').disable();
    } else {
      if (!this.matter) {
        this.form.get('mainMatter').enable();
      }
    }
  }

  removeAddMatters() {
    this.form.get('additionalMatters').setValue([]);
    this.showAddSelect = false;
  }

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs, idx: number) => {
    this.filtersMatters.name = e.text;
    this.filtersMatters['contact.id'] = this.contactId;
    const selectedMatters = this.form.get('additionalMatters').value;
    selectedMatters.push(this.form.get('mainMatter').value);

    this.debounceService.loadDataDebounce(
      AdditionalMatterDebounceEnum.matters + idx,
      this.matterService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersMatters, selectedMatters]);
    this.debounceService.getValue(AdditionalMatterDebounceEnum.matters + idx).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.mainMatters = resp?.member;
    });
  };
  onFilteringAddMatters: EmitType<any> = (e: FilteringEventArgs) => {
    e.updateData(this.additionListMatters.filter(el => el.name.toLowerCase().includes(e.text.toLowerCase())) as any);
  };

  changeMatter(matter) {
    this.selectedMainMatter = matter;
    this.addionalMatters = this.additionListMatters.filter(el => el?.id !== this.selectedMainMatter?.id);
    this.changeMatterEmit.emit(matter);
  }

  changeDates(e) {
    this.changeDatesEmit.emit(e);
  }
}
