import { Component, OnInit, Inject, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { disappearanceAnimation } from '@app/helpers/fadeAnimation';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { selectTemplates } from '@app/modules/settings/store/selectors/template.selector';
import { GetTemplates } from '@app/modules/settings/store/actions/template.actions';
import { selectMatterListForSelect } from '@app/store/selectors/matter.selector';
import { LoadMattersForList } from '@app/store/actions/matter.actions';
import { environment } from '@environments/environment';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { LoadAllContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { selectAllContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { TemplateEffects } from '@app/modules/settings/store/effects/template.effects';
import { Template } from '@app/models/template.model';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DebounceService } from '../../../../helpers/debounce.service';
import { DocumentFromTemplateUploadModalDebounceEnum } from '../../../../enums/debounce-keys.enum';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';

@Component({
  selector: 'app-document-from-template-upload-modal',
  templateUrl: './document-from-template-upload-modal.component.html',
  styleUrls: ['./document-from-template-upload-modal.component.scss'],
  animations: [disappearanceAnimation]
})
export class DocumentFromTemplateUploadModalComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<DocumentFromTemplateUploadModalComponent>,
    private contactService: ContactService,
    private matterService: MatterService,
    @Inject(MAT_DIALOG_DATA)
    public data: { type: string; billId?: number; matterId?: number; contactId?: number },
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    private templateEffects: TemplateEffects,
    private appSettingsService: AppSettingsService,
    private debounceService: DebounceService
  ) {}

  get f() {
    return this.templateForm;
  }
  private subscription = new Subscription();
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  public sorting = 'Ascending';
  fields: object = { text: 'name', value: 'id' };
  templateForm: UntypedFormGroup;
  clients;
  matters;
  templates: Template[];
  submittedForm = false;
  shortModal = true;
  matterOrContact = null;
  public locale$ = this.appSettingsService.getLang;
  dateFormat = CustomDateAdapter.dateFormat;

  @ViewChild('template', { static: false }) public comboBoxTemplate: ComboBoxComponent;
  @ViewChild('contactBox', { static: false }) public comboBoxContact: ComboBoxComponent;
  @ViewChild('matterBox', { static: false }) public comboBoxMatter: ComboBoxComponent;

  ngOnInit() {
    this.createForm();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.setSelectModels();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  onSubmit() {
    this.submittedForm = true;
    this.templateForm.updateValueAndValidity();
    if (!this.templateForm.valid) {
      return;
    }
    this.dialogRef.close(this.templateForm.value);
  }

  public closeModal() {
    this.dialogRef.close();
  }

  private createForm() {
    this.templateForm = this.formBuilder.group({
      template: new UntypedFormControl(null, [Validators.required]),
      item: new UntypedFormControl(null, [Validators.required]),
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      startDate: new UntypedFormControl(null),
      endDate: new UntypedFormControl(null),
    });
    if (this.data.type === 'bill') {
      this.templateForm.patchValue({
        item: this.data.billId
      });
      this.shortModal = false;
    }
    if (this.data.type === 'matter') {
      this.templateForm.patchValue({
        item: this.data.matterId
      });
      this.shortModal = false;
    }
    if (this.data.type === 'contact') {
      this.templateForm.patchValue({
        item: this.data.contactId
      });
      this.shortModal = false;
    }
  }

  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.comboBoxContact?.showSpinner();
    this.debounceService.loadDataDebounce(
      DocumentFromTemplateUploadModalDebounceEnum.contacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, { name: e.text }]);
    this.debounceService.getValue(DocumentFromTemplateUploadModalDebounceEnum.contacts).subscribe(resp => {
      this.clients = resp?.member;
      e.updateData(resp?.member as any);
      this.comboBoxContact?.hideSpinner();
    });
  }

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.comboBoxMatter?.showSpinner();
    this.debounceService.loadDataDebounce(
      DocumentFromTemplateUploadModalDebounceEnum.contacts,
      this.matterService, 'get',
      [environment.defaultPage, environment.countItemInSelect, { name: e.text }]);
    this.debounceService.getValue(DocumentFromTemplateUploadModalDebounceEnum.contacts).subscribe(resp => {
      this.matters = resp?.member;
      e.updateData(resp?.member as any);
      this.comboBoxMatter?.hideSpinner();
    });
  }

  private setSelectModels(): void {
    if (this.comboBoxTemplate) {
      this.comboBoxTemplate.showSpinner();
    }
    this.store.dispatch(new GetTemplates({ filters: { type: this.data.type } }));

    this.templateEffects.getTemplates$.subscribe(() => {
      this.store.select(selectTemplates).subscribe(templates => {
        if (templates) {
          this.templates = templates;
          if (this.comboBoxTemplate) {
            this.comboBoxTemplate.hideSpinner();
          }
        }
      });
    });

    this.loadMatter();
    this.loadContacts();
  }
  loadMatter() {
    this.subscription.add(
      this.store.select(selectMatterListForSelect).subscribe(matters => {
        if (!matters) {
          this.store.dispatch(new LoadMattersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (matters) {
          this.matters = matters;
        }
      })
    );
  }
  loadContacts() {
    this.subscription.add(
      this.store.select(selectAllContactListForSelect).subscribe(data => {
        if (!data) {
          this.store.dispatch(new LoadAllContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        this.clients = data;
      })
    );
  }
  changeTemplate(event) {
    if (event.itemData) {
      if (event.itemData.type === 'matter') {
        if (this.comboBoxMatter) {
          this.comboBoxMatter.showSpinner();
        }
        this.matterOrContact = 'matter';
      }
      if (event.itemData.type === 'contact') {
        if (this.comboBoxContact) {
          this.comboBoxContact.showSpinner();
        }
        this.matterOrContact = 'client';
      }
      this.getCurrentTypeTemplateFromName(event.itemData.fileName);
      this.templateForm.patchValue({
        name: event.itemData.name,
        type: this.getCurrentTypeTemplateFromName(event.itemData.fileName)
      });
    } else {
      this.matterOrContact = null;
    }
    if (this.comboBoxMatter) {
      this.comboBoxMatter.hideSpinner();
    }
    if (this.comboBoxContact) {
      this.comboBoxContact.hideSpinner();
    }
  }
  getCurrentTypeTemplateFromName(nameTemplate) {
    let type = '';
    type = nameTemplate.split('.')[nameTemplate.split('.').length - 1];
    return type;
  }

  get minEndDate(): Date {
    return this.templateForm.get('startDate').value || CustomDateAdapter.startDate;
  }
  updateDateEnd(dateStart) {
    const endDate = new Date(this.templateForm.get('endDate').value);
    if (dateStart.value > endDate && this.templateForm.get('endDate').value) {
      this.templateForm.get('endDate').setValue(dateStart.value);
    }
  }
}
