import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/auth/_services/auth.service';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-first-login-modal',
  templateUrl: './first-login-modal.component.html',
  styleUrls: ['./first-login-modal.component.scss']
})
export class FirstLoginModalComponent implements OnInit {
  public isLoggedIn: boolean;
  lang:string;

  shareLink = [
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/sharer/sharer.php?u='
    },
    {
      name: 'LinkedIn',
      link: 'https://www.linkedin.com/shareArticle?mini=true&url='
    },
    {
      name: 'Twitter',
      link: 'https://twitter.com/intent/tweet?source=tweetbutton&url='
    },
  ]
  @ViewChild('shareList') shareList: DropDownListComponent;

  constructor(
    public dialogRef: MatDialogRef<FirstLoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public news,
    private teamMemberService: TeamMemberService,
    private authService: AuthService
  ) {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  ngOnInit() {
    if (!this.news.isReaded) {
      this.teamMemberService.changeOneUser(this.authService.getPayload().id, { readNews: [`news/${this.news.id}`] }).pipe(take(1)).subscribe();
    }
  }
  selectShare(item) {
    if (item) {
      window.open(item?.link + this.news?.knowledgeUrl);
      this.shareList.value = null;
    }


  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
