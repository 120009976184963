import { Inject, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  data: string;

  constructor(public dialogRef: MatDialogRef<VideoModalComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.data = data.replace('watch?v=', 'embed/').replace('&feature=youtu.be', '');
  }
  // https://www.youtube.com/watch?v=BqOKhIDrmoc&feature=youtu.be
  // https://www.youtube.com/BqOKhIDrmoc
  // https://youtu.be/_oBQedAeCz4
  ngOnInit() {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
