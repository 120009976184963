import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExchangeRatesService } from '@app/api/exchange-rates.service';

@Component({
  selector: 'app-converter-modal',
  templateUrl: './converter-modal.component.html',
  styleUrls: ['./converter-modal.component.scss']
})
export class ConverterModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConverterModalComponent>,
    private exchangeService: ExchangeRatesService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  // валюта біла
  currencyBill;

  // валюта компанії (якщо проекту немає і валюта біла != валюті компанії), або валюта проекту, коли він є
  toCur;
  // валюта рахунка
  currencyBankAccount;
  // рейт по якому конвертувати коли валюта рахунку != валюті біла
  rateFromBillToBankAccount;
  amount = 1;
  rateFromBillToCompanyOrMatterCurrency;
  fromBilling;
  settingOrMatter;
  closeModal() {
    this.dialogRef.close();
  }
  ngOnInit() {
    (this.fromBilling = this.dialogData ? this.dialogData.fromBilling : null),
      (this.currencyBill = this.dialogData ? this.dialogData.fromCurrency : null);
    this.toCur = this.dialogData ? this.dialogData.toCurrency : null;
    this.rateFromBillToBankAccount = this.dialogData ? this.dialogData.rate : null;
    this.amount = this.dialogData ? this.dialogData.amount : 1;
    this.currencyBankAccount = this.dialogData ? this.dialogData.currencyBankAccount : null;
    if (this.toCur) {
      this.exchangeService.getRate(this.toCur, this.currencyBill).subscribe(res => {
        this.rateFromBillToCompanyOrMatterCurrency = (+res.rate).toFixed(6);
      });
    }
    this.settingOrMatter = this.dialogData ? this.dialogData.settingOrMatter : null;
  }

  saveRate() {
    let req = {
      rateClientorMatter: this.rateFromBillToCompanyOrMatterCurrency,
      rateCompanyBankAccount: this.rateFromBillToBankAccount
    };
    if (this.dialogData.fromBillEdit) {
      req = this.dialogData.uniqCurrency;
    }

    this.dialogRef.close(req);
  }
}
