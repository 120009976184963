import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { selectSelectedBill } from '@app/store/selectors/bill.selector';
import { Bill } from '@app/models/bill';
import { Contact } from '@app/models';
import { CompanySettings } from '@app/models/company-settings';
import { CreatePayment, GetPaymentFromStore, GetPayments, UpdatePayment } from '@app/store/actions/payment.actions';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PaymentEffects } from '@app/store/effects/payment.effects';
import { selectSelectedPayment } from '@app/store/selectors/payment.selector';
import { Payment } from '@app/models/payment';
import { GetBillById } from '@app/store/actions/bill.actions';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { BillStatus } from '@app/enums/bills/bill-status';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { ConverterModalComponent } from '../../other-modals/converter-modal/converter-modal.component';
import { ExchangeRatesService } from '@app/api/exchange-rates.service';
import { environment } from '@environments/environment';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { FilteringEventArgs, ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { BankAccountService } from '@app/modules/bank-accounts/services/bank-account.service';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { Currency } from '@app/models/currency';
import { DebounceService } from '../../../../helpers/debounce.service';
import { BillPaymentRegistrationModalDebounceEnum } from '../../../../enums/debounce-keys.enum';

@Component({
  selector: 'app-bill-payment-registration-modal',
  templateUrl: './bill-payment-registration-modal.component.html',
  styleUrls: ['./bill-payment-registration-modal.component.scss']
})
export class BillPaymentRegistrationModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<BillPaymentRegistrationModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private exchangeService: ExchangeRatesService,
    private store: Store<IAppState>,
    private contactService: ContactService,
    private billService: BillService,
    private bankAccountService: BankAccountService,
    private paymentsEffect: PaymentEffects,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: { bankAccount?: BankAccountExtended; billId?: number; paymentId: number },
    private appSettingsService: AppSettingsService,
    private debounceService: DebounceService
  ) {}
  disButton = false;

  get openBalance(): number {
    let openBalance = 0;

    if (this.payment) {
      openBalance += parseFloat(this.payment.amount);
    }

    if (this.clientBills && this.clientBills.length > 0) {
      this.clientBills.map(bill => {
        openBalance += this.calculateBillOpenBalance(bill);
      });
    }
    return openBalance;
  }

  get isValidForm() {
    return this.paymentForm.valid;
  }

  get f(): UntypedFormGroup {
    return this.paymentForm;
  }

  get hasBills(): boolean {
    const billsControl = this.paymentForm.get('bills') as UntypedFormArray;

    return this.clientBills && this.clientBills.length && billsControl && !!billsControl.controls.length;
  }
  dateFormat = CustomDateAdapter.dateFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  clientBills: Bill[] = [];
  paymentClient: Contact = {
    fullName: '',
    id: null
  };
  paymentExecutor: Contact;
  paymentCurrency: Currency;
  paymentAmount: number;
  billId: number;
  bill: Bill;
  payment: Payment;
  contacts: Contact[];
  bankAccounts: BankAccountExtended[] = [];
  additionFields = false;
  isContactsSelectHidden = false;
  receiverId: number;
  paymentForm: UntypedFormGroup;
  companySettings: CompanySettings;
  format = CustomDateAdapter.dateFormat;
  contactsTerm = '';
  submittedPaymentForm = false;

  private currentIndex: number;
  private totalBillCount: number;
  private payload: Payment;
  private paymentFormValue;
  private subscriptions = new Subscription();

  currentCompanyBankAccount;

  currentBill: Bill;
  currentCompanySettings;

  public sorting = 'Ascending';

  filtersContacts = {
    'order[fullName]': 'ASC',
    which_user_portal: true,
    name: '',
    'bills.currency': '',
    'bills.status': ''
  };
  @ViewChild('contactsComboBox', { static: false }) public comboBoxContact: ComboBoxComponent;
  public locale$ = this.appSettingsService.getLang;
  ngOnInit() {
    this.billId = this.dialogData.billId;
    if (!this.billId) {
      this.loadContacts();
    } else {
      this.store.dispatch(new GetBillById(this.billId));
    }

    this.populatePaymentData();

    this.populateBillData();

    this.populateClientData();

    this.updatePaymentForm();

    this.afterPaymentCreatedActions();

    this.afterPaymentUpdatedActions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    delete this.subscriptions;
    this.debounceService.removeDebounceData();
  }

  getBankAccounts() {
    if (this.paymentExecutor) {
      this.bankAccountService.getBankAccountsByContact(this.paymentExecutor.id).subscribe(res => {
        this.bankAccounts = res;
      });
    }
  }

  populateBills(bills: Bill[]) {
    const billsForm = this.paymentForm.get('bills') as UntypedFormArray;
    if (billsForm) {
      bills.map(bill => {
        if (billsForm.value.filter(item => item.bill === bill.id).length === 0) {
          billsForm.push(this.billItem(bill.id));
        }
      });
    }
  }

  billItem(id?: number, amount?: number): UntypedFormGroup {
    return this.formBuilder.group({
      bill: this.control(id || null),
      amount: this.control(amount || null, true)
    });
  }

  onCurrentContactChanged(event) {
    this.paymentExecutor = event;
    this.getBankAccounts();
  }

  onSubmit(): void {
    this.submittedPaymentForm = true;
    if (this.paymentForm.invalid) {
      return;
    }

    const bills = this.paymentForm.get('bills') as UntypedFormArray;
    this.totalBillCount = bills.controls.length;
    this.paymentFormValue = this.paymentForm.getRawValue();
    this.currentIndex = 0;

    if (this.totalBillCount > 0) {
      this.payload = {
        payAt: CustomDateAdapter.convertToDate(this.paymentFormValue.payAt),
        type: this.paymentFormValue.type,
        companyBankAccount: this.paymentFormValue.companyBankAccount,
        bill: this.paymentFormValue.bills[this.currentIndex].bill,
        amount: this.paymentFormValue.bills[this.currentIndex].amount + ''
      };

      if (this.dialogData.paymentId) {
        this.disButton = true;
        this.updatePayment();
      } else {
        for (const bill of bills.controls) {
          if (bill.get('amount').value > 0) {
            this.disButton = true;
            this.convertorMoney();
            // this.createPayment();
            break;
          }

          this.currentIndex++;
        }
      }
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  updateForm(data: Payment): void {
    if (this.dialogData.bankAccount) {
      this.bankAccounts.push(this.dialogData.bankAccount);
    }

    this.paymentForm = this.formBuilder.group({
      type: this.control(data.type),
      amount: this.control(data.amount, true),
      payAt: this.control(data.payAt),
      bills: this.formBuilder.array([this.billItem((data.bill as Bill).id, +data.amount)]),
      totalAmount: this.control(data.amount),
      companyBankAccount: this.control({ value: data.companyBankAccount.id, disabled: (this.dialogData.bankAccount || this.dialogData?.paymentId) ? true : false }),
      contact: this.control(null)
    });
    if (this.dialogData?.paymentId) {
      setTimeout(() => {
        this.paymentForm.get('companyBankAccount').setValue(data.companyBankAccount.id);
        }, 500);
    }

  }

  fixAmount(event, bill, index) {
    const openBalance = this.calculateBillOpenBalance(bill);
    let value = event.target.value;

    value = +value < openBalance ? value : openBalance;

    this.paymentForm
      .get('bills')
      .get([index])
      .get('amount')
      .patchValue(value);

    let totalAmount = 0;
    this.paymentForm.get('bills').value.map(item => {
      totalAmount += +item.amount;
    });
    this.paymentForm.get('totalAmount').patchValue(totalAmount);
  }

  fixTotalAmount(value) {
    value = value <= this.openBalance ? value : this.openBalance.toFixed(2);

    this.paymentForm.get('totalAmount').patchValue(value);

    this.populateBillsAmounts(value);
  }

  fixTextBoxes(event) {
    setTimeout(() => {
      let temp = event.target.value;
      const currentValue = temp.split('.');
      if (currentValue.length > 2) {
        currentValue.pop();
        temp = currentValue[0] + '.' + currentValue[1];
      }
      const countAfter = currentValue[currentValue.length - 1].length;
      if (temp.includes('.') && countAfter > 2) {
        if (currentValue[1][0] > 9) {
          temp = currentValue[0] + '.' + '9' + currentValue[1][1];
        } else {
          temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
        }
      }
      this.paymentForm.get('totalAmount').setValue(temp);
    }, 200);

    if ((event.which >= 48 && event.which <= 57) || event.which === 46) {
      return true;
    }
    return false;
  }

  fixAmounts() {
    const bills = this.paymentForm.get('bills').value;

    let amounts = 0;
    if (bills && bills.length > 0) {
      bills.map(bill => {
        amounts += +bill.amount;
      });
      const totalAmount = amounts < this.openBalance ? amounts : this.openBalance;
      this.paymentForm.get('totalAmount').patchValue(totalAmount);
    }
  }

  calculateBillOpenBalance(bill: Bill): number {
    return (Math.round(parseFloat((parseFloat(bill.amount) - parseFloat(bill.balance)).toString()) * 100) / 100) || 0
  }

  changeClient(client) {
    if (client && !this.dialogData.billId) {
      this.paymentClient = client;
      this.fetchBillList();
    }
  }

  private populateBillsAmounts(amount) {
    const bills = this.paymentForm.get('bills') as UntypedFormArray;

    this.clientBills.map((bill, index) => {
      const openBalance = this.calculateBillOpenBalance(bill);
      const amountToPay = (+amount && (+amount > openBalance ? openBalance : amount)) || 0;

      bills
        .get([index])
        .get('amount')
        .patchValue(amountToPay);
      amount -= amountToPay;
    });
  }

  private control(value, optional = false): UntypedFormControl {
    return this.formBuilder.control(value, optional ? [] : Validators.required);
  }

  private populateBillData() {
    if (this.billId) {
      this.subscriptions.add(
        this.billService.getById(this.billId).subscribe(res => {
          if (res.id) {
            this.clientBills = [res];
            this.populateBills(this.clientBills);
            this.contacts = [res.client];
            if (this.paymentForm) {
              this.paymentForm.get('contact').setValue(res.client.id);
            }
            this.paymentClient = res.client;
            this.paymentExecutor = res.executor;
            this.paymentCurrency = res.currency;
            if (this.paymentExecutor) {
              this.getBankAccounts();
              this.isContactsSelectHidden = true;
            }

            // this.bankAccounts = this.filterBankAccounts(this.bankAccounts);
          }
        })
      );
    }
  }

  loadContacts() {
    this.filtersContacts['bills.currency'] = this.dialogData.bankAccount.currency.code;
    this.filtersContacts['bills.status'] = 'awaiting_payment';
    this.subscriptions.add(
      this.contactService.get(environment.defaultPage, environment.countItemInSelect, this.filtersContacts).subscribe(x => {
        this.contacts = x.member;
        if (this.dialogData && this.dialogData.bankAccount) {
          this.paymentForm.get('contact').setValue(this.paymentClient.id);
        }
      })
    );
  }

  private updatePaymentForm() {
    if (this.dialogData.bankAccount && this.paymentForm) {
      const bankAccountControl = this.paymentForm.get('companyBankAccount');
      this.bankAccounts.push(this.dialogData.bankAccount);
      bankAccountControl.patchValue(this.dialogData.bankAccount.id);
      bankAccountControl.disable();
    }
  }

  private populateClientData() {
    if (this.dialogData.bankAccount) {
      this.paymentCurrency = this.dialogData.bankAccount.currency;
      this.isContactsSelectHidden = true;
      this.getCompanySettings();
    }
  }

  private fetchBillList() {
    this.subscriptions.add(
      this.billService
        .get(1, 100, {
          'client.id': this.paymentClient.id,
          currencyPayment: this.paymentCurrency ? this.paymentCurrency.code : '',
          status: BillStatus.awaiting
        })
        .subscribe(res => {
          if (res.member) {
            this.clientBills = res.member;
            if (this.paymentForm) {
              this.populateBills(this.clientBills);
            }
          }
        })
    );
  }

  private populatePaymentData() {
    if (this.dialogData.paymentId) {
      this.store.dispatch(new GetPayments(this.billId));
      this.store.dispatch(new GetPaymentFromStore(this.dialogData.paymentId));
      this.subscriptions.add(
        this.store.select(selectSelectedPayment).subscribe(payment => {
          if (payment) {
            this.payment = payment;
            this.updateForm(this.payment);
            this.paymentForm.get('contact').setValue(this.bill ? this.bill.client.id : null);
          }
        })
      );
    } else {
      this.createForm();
      this.populateBills(this.clientBills);
    }
  }

  private createForm(): void {
    this.paymentForm = this.formBuilder.group({
      type: this.control('money'),
      payAt: this.control(new Date()),
      totalAmount: this.control('', true),
      bills: this.formBuilder.array([]),
      companyBankAccount: this.control(null),
      contact: this.control(null)
    });
  }

  private createPayment(rates?) {
    if (this.currentIndex < this.totalBillCount && this.paymentFormValue.bills[this.currentIndex]) {
      const amount = this.paymentFormValue.bills[this.currentIndex].amount || 0;
      this.payload.bill = this.paymentFormValue.bills[this.currentIndex].bill;
      this.payload.amount = amount.toString();
      if (rates) {
        if (rates.rateCompanyBankAccount) {
          this.payload.rateCompanyBankAccount = +rates.rateCompanyBankAccount;
        }
        if (this.currentBill.matter) {
          this.payload.rateMatter = +rates.rateClientorMatter;
        } else {
          this.payload.rateClient = +rates.rateClientorMatter;
        }
      }
      if (amount > 0) {
        this.store.dispatch(new CreatePayment(this.payload));
      }

      this.currentIndex++;
    } else {
      this.closeModal();
    }
    if (this.currentIndex === this.totalBillCount) {
      this.closeModal();
    }
  }

  private updatePayment() {
    if (this.currentIndex < this.totalBillCount && this.paymentFormValue.bills[this.currentIndex]) {
      const amount = this.paymentFormValue.bills[this.currentIndex].amount || 0;
      this.payload.bill = this.paymentFormValue.bills[this.currentIndex].bill;
      this.payload.amount = amount.toString();

      if (amount > 0) {
        this.store.dispatch(new UpdatePayment(this.dialogData.paymentId, this.payload));
      }

      this.currentIndex++;
    } else {
      this.closeModal();
    }
  }

  private getCompanySettings() {
    this.paymentExecutor = this.dialogData.bankAccount.contact;

    this.subscriptions.add(
      this.store
        .select(selectCompanySetting)
        .pipe(filter(x => !!x?.id))
        .subscribe(x => {
          this.companySettings = x;
        })
    );
  }

  private afterPaymentCreatedActions() {
    this.subscriptions.add(
      this.paymentsEffect.create$.subscribe(response => {
        if (this.currentIndex > this.totalBillCount) {
          // this.loader.close();
          this.closeModal();
        } else {
          this.createPayment();

          if (this.billId && response) {
            this.store.dispatch(new GetBillById(this.billId));
          }
        }
      })
    );
  }

  private afterPaymentUpdatedActions() {
    this.subscriptions.add(
      this.paymentsEffect.update$.subscribe(response => {
        if (this.currentIndex > this.totalBillCount) {
          // this.loader.close();
        } else {
          this.updatePayment();
        }
      })
    );
  }
  // поточний компані банк акаунт
  onCurrentCompanyBankAccount(companyBankAccount) {
    this.currentCompanyBankAccount = companyBankAccount;
  }
  convertorMoney() {
    // ОТРИМАННЯ ПЕРЕДОПЛАТИ
    this.store.select(selectSelectedBill).subscribe(data => {
      this.currentBill = data;
    });
    this.store.select(selectCompanySetting).subscribe(x => {
      if (x) {
        this.currentCompanySettings = x;
      }
    });

    // якщо поттрібно конвертувати коли потрібно конвертувати на баланси, клієнта або матера
    if (this.currentBill && !this.currentBill.matter) {
      // відбувається коли проекту в біла немає
      if (this.currentBill.currency.code !== this.currentCompanySettings.currency.code) {
        // відбувається коли валюта біла не співпадає з валютою компаніСетінг
        this.openTransaction(this.currentBill, this.currentCompanySettings.currency.code, false);
        return;
      } else {
        if (this.currentBill.currency.code !== this.currentCompanyBankAccount.currency.code) {
          this.openTransaction(this.currentBill);
          return;
        }
        // відбувається тоді, коли проекту немає, валюта біла і компаніСеттінг - СПІВПАДАЄ
        this.createPayment();
        return;
      }
    } else if (this.currentBill && this.currentBill.matter) {
      // відбувається коли проект в біла є
      if (this.currentBill.currency.code !== this.currentBill.matter.currencyBill.code) {
        // відбувається коли валюта біла не співпадає з валютою проекту
        this.openTransaction(this.currentBill, this.currentBill.matter.currencyBill.code, true);
        return;
      } else {
        if (this.currentBill.currency.code !== this.currentCompanyBankAccount.currency.code) {
          this.openTransaction(this.currentBill);
          return;
        }
        // відбувається тоді, коли проект є, і валюта біла і проекту - СПІВПАДАЄ
        this.createPayment();
        return;
      }
    } else {
      if (
        this.currentBill &&
        this.currentCompanyBankAccount &&
        this.currentBill.currency.code !== this.currentCompanyBankAccount.currency.code
      ) {
        this.openTransaction(this.currentBill);
        return;
      }
      // відбувається тоді, коли проекту немає, і валюта біла і компаніСеттінг - співпадає
      // відбувається тоді, коли проект є, і валюта біла і проекту - співпадає
      this.createPayment();
    }
  }

  openTransaction(currentBill, currencySettingOrMatter?, settingOrMatter?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];

    let rate;

    // валюта компанії (якщо проекту немає і валюта біла != валюті компанії), або валюта проекту, коли він є
    let toCurrency;
    if (settingOrMatter === true && currencySettingOrMatter && this.currentBill.currency.code !== currencySettingOrMatter) {
      toCurrency = currencySettingOrMatter;
    }
    if (settingOrMatter === false) {
      toCurrency = this.currentCompanySettings.currency.code;
    }

    // валюта рахунку
    const currencyBankAccount = this.currentCompanyBankAccount.currency.code;

    this.exchangeService.getRate(currencyBankAccount, currentBill.currency.code).subscribe(res => {
      rate = (+res.rate).toFixed(6);
      dialogConfig.data = {
        fromCurrency: currentBill.currency.code,
        toCurrency,
        amount: this.paymentForm.get('totalAmount').value,
        rate,
        currencyBankAccount,
        settingOrMatter
      };

      this.dialog
        .open(ConverterModalComponent, dialogConfig)
        .afterClosed()
        .subscribe(resp => {
          if (resp) {
            this.createPayment(resp);
          } else {
            this.disButton = false;
          }
        });
    });
  }
  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs, keyData) => {
    this.filtersContacts['bills.currency'] = this.dialogData.bankAccount.currency.code;
    this.filtersContacts['bills.status'] = 'awaiting_payment';
    this.filtersContacts.name = e.text;
    this.comboBoxContact?.showSpinner();
    this.debounceService.loadDataDebounce(
      keyData,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(keyData).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxContact?.hideSpinner();
    });
  }

  get validAmount() {
    let validAmount = false;
    (this.f.get('bills') as UntypedFormArray).controls.map(item => {
      validAmount = this.submittedPaymentForm ? item.invalid : false;
    });
    return validAmount;
  }

  public get billPaymentRegistrationModalDebounceEnum(): typeof BillPaymentRegistrationModalDebounceEnum {
    return BillPaymentRegistrationModalDebounceEnum;
  }
}
