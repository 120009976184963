import { Paginator } from '../../../../models/paginator';
import { environment } from '@environments/environment';
import { Template } from '@app/models/template.model';

export interface ITemplateState {
  paginator: Paginator<Template>;
  currentTemplate: Template;
}

export const initialTemplateState: ITemplateState = {
  paginator: {
    member: null,
    countItems: null,
    totalItems: environment.defaultItemsCount,
    currentPage: 1,
    itemsPerPage: environment.defaultItemsCount,
    lastPage: null
  },
  currentTemplate: null
};
