import { Contract } from '@app/models/contract';

export interface ContractState {
  list: Array<Contract>;
  loading: boolean;
  error: Error;
  currentContract: Contract;
}

export const initialContractState: ContractState = {
  list: null,
  loading: false,
  error: undefined,
  currentContract: null
};
