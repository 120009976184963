import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Helpers } from '@app/helpers/helpers';
import { IUser, ResponseBodyFull, Country, ResponseBody } from '@app/models';
import { BillingPreferences } from '@app/models/interfaces/billing-preferences';
import { AuthService } from '@app/auth/_services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { IGooglecalendarUrl, IOutlookUrl, IViberUrl, ITelegramUrl } from '@app/models/interfaces/apps.interface';
import { MATTER_FINANCE_BLOCK } from '@app/helpers/role-arrays';
import { Paginator } from '@app/models/paginator';

@Injectable({ providedIn: 'root' })
export class TeamMemberService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  get(page = 1, itemsPerPage = environment.defaultItemsCount, filters: TeamMemberFilter = null): Observable<Paginator<IUser>> {
    let params = new HttpParams().set('partial', 'false');

    if (page && itemsPerPage) {
      params = params.set('page', page.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<IUser>>(`${environment.apiUrl}/users`, { params })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<IUser>>();
    }
  }

  getById(id: number) {
    return this.http.get<IUser>(`${environment.apiUrl}/users/${id}`);
  }

  invite(data) {
    return this.http.post<any>(`${environment.apiUrl}/company_user_accesses`, data);
  }

  updateBillingPreferences(data: BillingPreferences, id: number) {
    return this.http.patch<IUser>(`${environment.apiUrl}/users/${id}`, data);
  }

  getGoogleUrl() {
    return this.http.get<ResponseBody<IGooglecalendarUrl>>(`${environment.apiUrl}/google/auth-url`).pipe(map(el => el.data));
  }

  getOutlookUrl() {
    return this.http.get<ResponseBody<IOutlookUrl>>(`${environment.apiUrl}/outlook/auth-url`).pipe(map(el => el.data));
  }

  getViberUrl() {
    return this.http.get<ResponseBody<IViberUrl>>(`${environment.apiUrl}/user/viber`).pipe(map(el => el.data));
  }

  getTelegramUrl() {
    return this.http.get<ResponseBody<ITelegramUrl>>(`${environment.apiUrl}/user/telegram`).pipe(map(el => el.data));
  }

  create(data) {
    return this.http.post(`${environment.apiUrl}/create-user`, data);
  }

  update(id: number, data) {
    return this.http.patch<IUser>(`${environment.apiUrl}/company_user_accesses/${id}`, data);
  }

  fetchUserRoleById(id: number) {
    return this.http.get<IUser>(`${environment.apiUrl}/company_user_accesses/${id}`);
  }

  changePassword(data) {
    return this.http.patch<IUser>(`${environment.apiUrl}/registration/password/change`, data);
  }

  getAccountCompaniesId(id) {
    return this.http.get<IUser>(`${environment.apiUrl}/users?account.companies.id[]=${id}`);
  }

  getCountries(): Observable<Paginator<Country>> {
    return this.http.get<ResponseBodyFull<Country>>(`${environment.apiUrl}/countries?itemsPerPage=2510&page=1`)
    .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));;
  }

  changeOneUser(id: number, payload): Observable<IUser> {
    return this.http.patch<IUser>(`${environment.apiUrl}/users/${id}`, payload);
  }

  getStaff() {
    return this.http.get<any>(`${environment.apiUrl}/reg_staff_qty`).pipe(map(resp => resp['hydra:member']));
  }

  getPosition() {
    return this.http.get<any>(`${environment.apiUrl}/reg_position`).pipe(map(resp => resp['hydra:member']));
  }

  getAdministratorsAndAccounts(
    page = 1,
    itemsPerPage = environment.defaultItemsCount,
    filters: TeamMemberFilter = null,
    withRoles = true
  ): Observable<Paginator<IUser>> {
    let params = new HttpParams().set('partial', 'false');

    if (page && itemsPerPage) {
      params = params.set('page', page.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<IUser>>(
        `${environment.apiUrl}/users?${withRoles ? this.getFiltersRoles(MATTER_FINANCE_BLOCK) : ''}`,
        { params }
      )
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<IUser>>();
    }
  }

  getFiltersRoles(roles) {
    let arrayRoles = '';
    for (const role of roles) {
      arrayRoles += `&companyAccesses.roles[]=${role}`;
    }
    return arrayRoles;
  }
}
