import { Action } from '@ngrx/store';
import { ICompanyPortalSettings, ICompanyPortalSettingsFilters } from '@app/models/company-portal-settings';
import { Paginator } from '@app/models/paginator';
import { ICompanyUserPortalAccesses } from '@app/models/company-user-portal-accesses';

export enum ECompanyPortalSettingsActions {
  GET_COMPANY_PORTAL_SETTINGS = '[CompanyPortalSettings] Get CompanyPortalSettings',
  GET_COMPANY_PORTAL_SETTINGS_SUCCESS = '[CompanyPortalSettings] Get CompanyPortalSettings Success',
  GET_COMPANY_PORTAL_SETTINGS_FAILURE = '[CompanyPortalSettings] Get CompanyPortalSettings Failure',

  GET_COMPANY_PORTAL_SETTING = '[CompanyPortalSettings] Get CompanyPortalSetting',
  GET_COMPANY_PORTAL_SETTING_SUCCESS = '[CompanyPortalSettings] Get CompanyPortalSetting Success',
  GET_COMPANY_PORTAL_SETTING_FAILURE = '[CompanyPortalSettings] Get CompanyPortalSetting Failure',

  POST_COMPANY_PORTAL_SETTINGS = '[CompanyPortalSettings] Post CompanyPortalSettings',
  POST_COMPANY_PORTAL_SETTINGS_SUCCESS = '[CompanyPortalSettings] Post CompanyPortalSettings Success',
  POST_COMPANY_PORTAL_SETTINGS_FAILURE = '[CompanyPortalSettings] Post CompanyPortalSettings Failure',

  PUT_COMPANY_PORTAL_SETTINGS = '[CompanyPortalSettings] Put CompanyPortalSettings',
  PUT_COMPANY_PORTAL_SETTINGS_SUCCESS = '[CompanyPortalSettings] Put CompanyPortalSettings Success',
  PUT_COMPANY_PORTAL_SETTINGS_FAILURE = '[CompanyPortalSettings] Put CompanyPortalSettings Failure',

  DELETE_COMPANY_PORTAL_SETTINGS = '[CompanyPortalSettings] Delete CompanyPortalSettings',
  DELETE_COMPANY_PORTAL_SETTINGS_SUCCESS = '[CompanyPortalSettings] Delete CompanyPortalSettings Success',
  DELETE_COMPANY_PORTAL_SETTINGS_FAILURE = '[CompanyPortalSettings] Delete CompanyPortalSettings Failure',
  PUT_COMPANY_USER_PORTAL_ACCESS = '[CompanyPortalSettings] Put CompanyUserPortalAccess',
  PUT_COMPANY_USER_PORTAL_ACCESS_SUCCESS = '[CompanyPortalSettings] Put CompanyUserPortalAccess',
  PUT_COMPANY_USER_PORTAL_ACCESS_FAILURE = '[CompanyPortalSettings] Put CompanyUserPortalFailure',
  POST_COMPANY_USER_PORTAL_ACCESS = '[CompanyPortalSettings] Post CompanyUserPortalAccess',
  POST_COMPANY_USER_PORTAL_ACCESS_SUCCESS = '[CompanyPortalSettings] Post CompanyUserPortalAccess',
  POST_COMPANY_USER_PORTAL_ACCESS_FAILURE = '[CompanyPortalSettings] Post CompanyUserPortalFailure',
}

export class GetCompanyPortalSettings implements Action {
  public readonly type = ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTINGS;

  constructor(public payload?: ICompanyPortalSettingsFilters) {
  }
}

export class GetCompanyPortalSettingsSuccess implements Action {
  public readonly type = ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTINGS_SUCCESS;

  constructor(public payload: Paginator<ICompanyPortalSettings>) {
  }
}

export class GetCompanyPortalSettingsFailure implements Action {
  readonly type = ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetCompanyPortalSetting implements Action {
  public readonly type = ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTING;

  constructor(public payload: number) {
  }
}

export class GetCompanyPortalSettingSuccess implements Action {
  public readonly type = ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTING_SUCCESS;

  constructor(public payload: ICompanyPortalSettings) {
  }
}

export class GetCompanyPortalSettingFailure implements Action {
  readonly type = ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTING_FAILURE;

  constructor(public payload: Error) {
  }
}

export class PostCompanyPortalSettings implements Action {
  public readonly type = ECompanyPortalSettingsActions.POST_COMPANY_PORTAL_SETTINGS;

  constructor(public payload: ICompanyPortalSettings) {
  }
}

export class PostCompanyPortalSettingsSuccess implements Action {
  public readonly type = ECompanyPortalSettingsActions.POST_COMPANY_PORTAL_SETTINGS_SUCCESS;

  constructor(public payload: ICompanyPortalSettings) {
  }
}

export class PostCompanyPortalSettingsFailure implements Action {
  readonly type = ECompanyPortalSettingsActions.POST_COMPANY_PORTAL_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class PutCompanyPortalSettings implements Action {
  public readonly type = ECompanyPortalSettingsActions.PUT_COMPANY_PORTAL_SETTINGS;

  constructor(public payload: ICompanyPortalSettings) {
  }
}

export class PutCompanyPortalSettingsSuccess implements Action {
  public readonly type = ECompanyPortalSettingsActions.PUT_COMPANY_PORTAL_SETTINGS_SUCCESS;

  constructor(public payload: ICompanyPortalSettings) {
  }
}

export class PutCompanyPortalSettingsFailure implements Action {
  readonly type = ECompanyPortalSettingsActions.PUT_COMPANY_PORTAL_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteCompanyPortalSettings implements Action {
  public readonly type = ECompanyPortalSettingsActions.DELETE_COMPANY_PORTAL_SETTINGS;

  constructor(public payload: number) {
  }
}

export class DeleteCompanyPortalSettingsSuccess implements Action {
  public readonly type = ECompanyPortalSettingsActions.DELETE_COMPANY_PORTAL_SETTINGS_SUCCESS;

  constructor(public payload: number) {
  }
}

export class DeleteCompanyPortalSettingsFailure implements Action {
  readonly type = ECompanyPortalSettingsActions.DELETE_COMPANY_PORTAL_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}


export class PutCompanyUserPortalAccesses implements Action {
  readonly type = ECompanyPortalSettingsActions.PUT_COMPANY_USER_PORTAL_ACCESS;

  constructor(public payload: Partial<ICompanyUserPortalAccesses>) {
  }
}

export class PutCompanyUserPortalAccessesSuccess implements Action {
  readonly type = ECompanyPortalSettingsActions.PUT_COMPANY_USER_PORTAL_ACCESS_SUCCESS;

  constructor(public payload: ICompanyUserPortalAccesses) {
  }
}

export class PutCompanyUserPortalAccessesFailure implements Action {
  readonly type = ECompanyPortalSettingsActions.PUT_COMPANY_USER_PORTAL_ACCESS_FAILURE;

  constructor(public payload: Error) {
  }
}


export class PostCompanyUserPortalAccess implements Action {
  readonly type = ECompanyPortalSettingsActions.POST_COMPANY_USER_PORTAL_ACCESS;

  constructor(public payload: Partial<ICompanyUserPortalAccesses>) {
  }
}

export class PostCompanyUserPortalAccessSuccess implements Action {
  readonly type = ECompanyPortalSettingsActions.POST_COMPANY_USER_PORTAL_ACCESS_SUCCESS;

  constructor(public payload: ICompanyUserPortalAccesses) {
  }
}

export class PostCompanyUserPortalAccessFailure implements Action {
  readonly type = ECompanyPortalSettingsActions.POST_COMPANY_USER_PORTAL_ACCESS_FAILURE;

  constructor(public payload: Error) {
  }
}

export type CompanyPortalSettingsActions =
  | GetCompanyPortalSettings
  | GetCompanyPortalSettingsSuccess
  | GetCompanyPortalSettingsFailure
  | GetCompanyPortalSetting
  | GetCompanyPortalSettingSuccess
  | GetCompanyPortalSettingFailure
  | PostCompanyPortalSettings
  | PostCompanyPortalSettingsSuccess
  | PostCompanyPortalSettingsFailure
  | PutCompanyPortalSettings
  | PutCompanyPortalSettingsSuccess
  | PutCompanyPortalSettingsFailure
  | DeleteCompanyPortalSettings
  | DeleteCompanyPortalSettingsSuccess
  | DeleteCompanyPortalSettingsFailure
  | PutCompanyUserPortalAccesses
  | PutCompanyUserPortalAccessesSuccess
  | PutCompanyUserPortalAccessesFailure
  | PostCompanyUserPortalAccess
  | PostCompanyUserPortalAccessSuccess
  | PostCompanyUserPortalAccessFailure;
