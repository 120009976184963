import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { Observable } from 'rxjs';
import { Note } from '@app/models/note';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { NoteFilters } from '@app/models/note-filters';
import { Logs } from '@app/models/logs';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class MatterNoteService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL = `${environment.apiUrl}/notes`;
  private BASE_URL_LOG = `${environment.apiUrl}/logs`;
  getAll(filters: NoteFilters = null): Observable<Note[]> {
    let params = new HttpParams();
    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }

    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<Note>>(this.BASE_URL, { params })
        .pipe(map(response => (response['hydra:member'])));
    } else {
      return new Observable<Note[]>();
    }
  }

  getNoteById(id: number): Observable<Note> {
    return this.http.get<Note>(`${this.BASE_URL}/${id}`);
  }

  deleteNote(id) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  addNote(data: Note) {
    return this.http.post<Note>(this.BASE_URL, data);
  }

  updateNote(note: Note): Observable<Note> {
    return this.http.patch<Note>(`${this.BASE_URL}/${note.id}`, note);
  }

  getLogs(currentPage = 1, itemsPerPage = 10, filters: any = null): Observable<Paginator<Logs>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    return this.http.get<ResponseBodyFull<Logs>>(this.BASE_URL_LOG, { params }).pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));;
  }
}
