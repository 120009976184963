import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { DocumentUploadModalComponent } from '@app/commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import { CorrespondenceType } from '@app/enums/correspondence-type.enum';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CorrespondenceSettings } from '@app/helpers/correspondence-settings';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { disappearanceAnimation, fadeTopAnimation } from '@app/helpers/fadeAnimation';
import { Helpers } from '@app/helpers/helpers';
import { Contact, IUser } from '@app/models';
import { CompanySettings } from '@app/models/company-settings';
import { Correspondence } from '@app/models/correspondence';
import { CorrespondenceFilters } from '@app/models/correspondence-filters';
import { DocRelation } from '@app/models/doc-relation';
import { DocumentSave } from '@app/models/document-save';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { TrackInfo } from '@app/models/interfaces/track-info';
import { MatterExtended } from '@app/models/matter-extended';
import { ReminderExtended } from '@app/models/reminder-extended';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { LoadAllContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { selectAllContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { CorrespondenceService } from '@app/modules/main-correspondence/services/correspondence.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { LoadCompanySettingAction, UpdateCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import {
  CreateCorrespondence,
  CreateCorrespondenceMessage,
  CreateCorrespondenceSuccess,
  CreateCorrespondenceWithDocuments,
  ECorrespondenceActions,
  GetCorrespondenceDropdown,
  GetCorrespondenceMessage,
  GetTrackInfo,
  UpdateCorrespondence
} from '@app/store/actions/correspondence.actions';
import { AddDocuments, DeleteDocuments, GetDocumentsByCorrespondence } from '@app/store/actions/document.actions';
import { LoadMattersForList } from '@app/store/actions/matter.actions';
import { CorrespondenceEffects } from '@app/store/effects/correspondence.effects';
import { selectDropDownCorrespondences, selectNotifications, selectTrackInfo } from '@app/store/selectors/correspondence.selector';
import { selectMatterListForSelect } from '@app/store/selectors/matter.selector';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CorrespondenceNewModalDebounceEnum } from '@app/enums/debounce-keys.enum';
import { DebounceService } from '@app/helpers/debounce.service';
import { CompanySettingService } from '@app/modules/settings/services/company-setting.service';
import { selectCorrespondenceDocuments } from '@app/store/selectors/document.selectors';
import { PerfectScrollbarComponent } from '@app/perfect-scrollbar/perfect-scrollbar.component';

export interface ICorrespondenceNewModalData {
  matter: MatterExtended;
  correspondence: Correspondence;
}

@Component({
  selector: 'app-correspondence-new-modal',
  templateUrl: './correspondence-new-modal.component.html',
  styleUrls: ['./correspondence-new-modal.component.scss'],
  animations: [disappearanceAnimation, fadeTopAnimation]
})
export class CorrespondenceNewModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<CorrespondenceNewModalComponent>,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private correspondenceEffects: CorrespondenceEffects,
    private store: Store<IAppState>,
    private contactService: ContactService,
    private matterService: MatterService,
    private teamMemberService: TeamMemberService,
    private correspondenceService: CorrespondenceService,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICorrespondenceNewModalData,
    private appSettingsService: AppSettingsService,
    private toastrService: ToastrService,
    private debounceService: DebounceService,
    private companySettingService: CompanySettingService
  ) {
    this.subscription.add(this.store.select(selectTrackInfo).subscribe(trackInfo => (this.trackInfo = trackInfo)));
  }

  get haveTrackNumber() {
    return this.correspondenceForm.get('postNumber').value.length > this.minTrackNumberLength;
  }

  get isNotFoundStatus(): boolean {
    return this.trackInfo && this.trackInfo.status === null && this.haveTrackNumber;
  }

  get isIncoming(): boolean {
    return this.correspondenceForm && this.correspondenceForm.get('type').value === CorrespondenceType.incoming;
  }

  get isEditing(): boolean {
    return !!(this.dialogData && this.dialogData.correspondence);
  }

  get f(): UntypedFormGroup {
    return this.correspondenceForm;
  }
  matters: MatterExtended[];
  selectedMatterId: number;
  correspondence: Correspondence[] = [];
  correspondenceForm: UntypedFormGroup;
  trackInfo: TrackInfo;
  remindFields = false;
  additionFields = false;
  users: IUser[];
  contacts: Contact[];

  companySettings: CompanySettings;
  correspondenceId: number = null;

  documentsToAdd: { document: DocumentSave; mediaFile: File; reminders: ReminderExtended; relations: DocRelation[] }[] = [];
  // documents ids to delete when expense is saved
  documentsToDelete: number[] = [];
  // documents to show in list
  documentsToShow: { name: string; id?: number; media: { type: 'document' } }[] = [];
  submittedCorrespondenceForm = false;
  modalSwitch = false;
  filters: Correspondence;
  fileData: File = null;

  remindToggleButton = false;

  @ViewChild('file', { static: false }) inputFile: ElementRef;
  @ViewChild('correspondencePerfectScroll', { static: false }) perfectScroll: PerfectScrollbarComponent;

  descIn = 'unfocus';

  // readonly notFoundStatus = 'З таким номером кореспонденції не знайдено';
  readonly minTrackNumberLength = 5;
  readonly dateFormat = CustomDateAdapter.dateFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  readonly registrationDate = 'registrationDate';
  readonly senderDate = 'senderDate';
  readonly documentDate = 'documentDate';
  readonly emptyEvent = { value: '' };

  private readonly mediaType = 'document';
  private subscription = new Subscription();
  private incomingNumber = '';

  checkIfStatus;
  public sorting = 'Ascending';

  filtersUser: TeamMemberFilter = {
    name: '',
    status: 'active',
    'companyAccesses.enable': true
  };
  @ViewChild('usersComboBox', { static: false }) public comboBoxUser: ComboBoxComponent;

  filtersCorrespondence: CorrespondenceFilters = {
    title: ''
  };
  @ViewChild('correspondenceComboBox', { static: false }) public comboBoxCorrespondence: ComboBoxComponent;

  filterMatters = {
    name: '',
    status: 'open'
  };
  @ViewChild('mattersComboBox', { static: false }) public comboBoxMatter: ComboBoxComponent;

  filtersContacts = {
    'order[fullName]': 'ASC',
    name: ''
  };
  @ViewChild('senderContact', { static: false }) public comboBoxSenderContact: ComboBoxComponent;
  @ViewChild('recipientContact', { static: false }) public comboBoxRecipientContact: ComboBoxComponent;
  @ViewChild('senderSignatoryContact', { static: false }) public comboBoxSenderSignatoryContact: ComboBoxComponent;
  @ViewChild('receiverContact', { static: false }) public comboBoxReceiverContact: ComboBoxComponent;
  public locale$ = this.appSettingsService.getLang;
  ifErrorMatterValue = false;

  ngOnInit() {
    this.populateFormData();
    this.getCompanySettings();
    this.loadContacts();
    this.loadMatters();
    this.loadUsers();
    this.loadCorrespondence();
    this.subscribeToEffects();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  postNumberChange(value) {
    if (value.length > this.minTrackNumberLength) {
      this.store.dispatch(new GetTrackInfo(value));
    }
    if (value.length === 0) {
      this.trackInfo = null;
    }
  }

  switchType(state) {
    const correspondenceData = this.dialogData ? this.dialogData.correspondence : null;

    //  if(correspondenceData) {
    if (!state) {
      this.populateDefaultIncomingForm(correspondenceData);
    } else {
      this.populateDefaultOutgoingForm(correspondenceData);
    }
    // }

    this.setUpNumber();
    this.modalSwitch = state;
  }

  remindValue(event) {
    this.remindFields = event.target.checked;
  }

  switchAdditionFields() {
    this.additionFields = !this.additionFields;
  }

  closeModal() {
    this.dialogRef.close();
  }

  onFileChanged(fileInput) {
    this.fileData = fileInput.target.files[0] as File;
    if (!Helpers.checkSizeFile(this.fileData, 20)) {
      this.fileData = null;
      this.toastrService.error(this.translate.instant('documents.fileTooBig20'));
      return;
    }
    if (this.fileData) {
      this.documentUploadModal(this.fileData);
    }
    this.inputFile.nativeElement.value = null;
  }

  deleteDocument(document: { name: string; id?: number; media: { type: 'document' } }) {
    if (document.id) {
      this.documentsToDelete.push(document.id);
    } else {
      const position = this.documentsToAdd.indexOf(this.documentsToAdd.filter(x => x.document.name === document.name)[0]);
      if (position !== -1) {
        this.documentsToAdd.splice(position, 1);
      }
    }
    this.documentsToShow.splice(this.documentsToShow.indexOf(document), 1);
  }

  keydownEvent(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  updateCompanySettingsInNum() {
    const newSettings = {
      correspondenceInNum: this.companySettings.correspondenceInNum + 1,
      id: this.companySettings.id,
      withoutRoute: true
    };
    this.store.dispatch(new UpdateCompanySettingAction(newSettings));
  }

  updateCompanySettingsOutNum() {
    const newSettings = {
      correspondenceOutNum: this.companySettings.correspondenceOutNum + 1,
      id: this.companySettings.id,
      withoutRoute: true
    };
    this.store.dispatch(new UpdateCompanySettingAction(newSettings));
  }

  onSubmit(): void {
    if (this.isEditing && this.dialogData.correspondence) {
      this.saveCorrespondence();
    } else {
      this.companySettingService.getCompanySetting().pipe(take(1)).subscribe(resp => {
        const type = this.modalSwitch ? 'correspondenceOutNum' : 'correspondenceInNum';
        if (this.companySettings[type] !== resp[type]) {
          this.confirmChangeNum(this.modalSwitch ? 'out' : 'in', resp);
        } else {
          this.saveCorrespondence();
        }
      });
    }
  }

  confirmChangeNum(type, newCompanySettings) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      modalTyle: 'blue',
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: this.translate.instant('correspondence.modal.setting.title'),
      mainTxt: this.translate.instant(
        'correspondence.modal.setting.text.' + type,
        {number:
            type === 'in' ? CorrespondenceSettings.incomingNumber(newCompanySettings)
              : CorrespondenceSettings.outgoingNumber(newCompanySettings)}
      )
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.companySettings = newCompanySettings;
        if (type === 'in') {
          this.incomingNumber = CorrespondenceSettings.incomingNumber(this.companySettings);
          this.f.get('incomingNumber').setValue(this.incomingNumber);
        } else if (type === 'out') {
          const outgoingNumber = CorrespondenceSettings.outgoingNumber(this.companySettings);
          this.f.get('outgoingNumber').setValue(outgoingNumber);
        }
        this.saveCorrespondence();
        confirmDialogRef.close();
      } else if (result === false) {
        this.saveCorrespondence();
        confirmDialogRef.close();
      }
    });
    confirmDialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(confirmDialogRef);
    });
  }

  saveCorrespondence(): void  {
    this.submittedCorrespondenceForm = true;
    if (this.correspondenceForm.get('matter').value && Number.isInteger(this.correspondenceForm.get('matter').value)) {
      this.ifErrorMatterValue = false;
    } else { this.ifErrorMatterValue = this.correspondenceForm.get('matter').value; }
    if (this.correspondenceForm.valid && !this.ifErrorMatterValue) {
      const formValue = { ...this.correspondenceForm.value };

      if (!this.modalSwitch) {
        this.updateCompanySettingsInNum();
      } else {
        this.updateCompanySettingsOutNum();
      }

      if (this.dialogData && this.dialogData.matter) {
        formValue.matter = this.dialogData.matter.id;
      }

      if (formValue.documentDate && typeof formValue.documentDate === 'object') {
        formValue.documentDate = CustomDateAdapter.convertToDate(formValue.documentDate);
      }

      if (formValue.registrationDate && typeof formValue.registrationDate === 'object') {
        formValue.registrationDate = CustomDateAdapter.convertToDate(formValue.registrationDate);
      }
      if (formValue.senderDate && typeof formValue.senderDate === 'object') {
        formValue.senderDate = CustomDateAdapter.convertToDate(formValue.senderDate);
      }
      if (formValue.recipient === null) {
        formValue.recipient = '';
      }

      if (this.isEditing && this.dialogData.correspondence) {
        this.store.dispatch(new UpdateCorrespondence(formValue, this.correspondenceId));
        if (this.documentsToAdd.length > 0) {
          this.store.dispatch(new AddDocuments(this.documentsToAdd));
        }
        this.dialogRef.close();
      } else {
        formValue.companySettings = this.companySettings.id;
        if (this.documentsToAdd.length > 0) {
          this.store.dispatch(new CreateCorrespondenceWithDocuments({ documents: this.documentsToAdd, correspondence: formValue }));
        } else {
          this.store.dispatch(new CreateCorrespondence(formValue));
        }
        this.dialogRef.close();
      }
    }
  }

  changeMatter() {
    this.ifErrorMatterValue = false;
  }
  checkValidation(event) {
    this.checkIfStatus = event;

    this.checkStatus();
  }

  checkStatus() {
    const postNumber = this.correspondenceForm.get('postNumber');

    const postNumberValidators: ValidatorFn[] = [Validators.required];

    if (this.checkIfStatus === true) {
      postNumber.setValidators(postNumberValidators);
    } else {
      postNumber.clearValidators();
    }
    postNumber.updateValueAndValidity();
  }

  private getMessages() {
    const messageControl = this.correspondenceForm.get('message');
    const usersControl = this.correspondenceForm.get('users');

    this.store.dispatch(new GetCorrespondenceMessage(this.correspondenceId));

    this.subscription.add(
      this.store
        .select(selectNotifications)
        .pipe(filter(x => !!x && !!x.length))
        .subscribe(messages => {
          this.remindFields = true;
          messages.map(message => {
            messageControl.patchValue(message.message);
            messageControl.disable();
            usersControl.patchValue(message.users.map(user => user.id));
            usersControl.disable();
          });
        })
    );
  }

  private deleteDocumentsAsync(ids: number[]) {
    this.store.dispatch(new DeleteDocuments(ids));
  }

  private createDocumentsAsync(correspondenceId: number) {
    for (const documentToAdd of this.documentsToAdd) {
      documentToAdd.document.correspondence = correspondenceId;
    }
    this.store.dispatch(new AddDocuments(this.documentsToAdd));
  }

  private createForm() {
    this.correspondenceForm = this.formBuilder.group({
      [this.documentDate]: this.control(null),
      [this.registrationDate]: this.control(new Date(), true),
      [this.senderDate]: this.control(null, true),
      outgoingNumber: this.formBuilder.control('', [Validators.maxLength(30), Validators.required]),
      incomingNumber: this.formBuilder.control(this.incomingNumber, [Validators.maxLength(30)]),
      sender: this.formBuilder.control('', [Validators.maxLength(250), Validators.required]),
      description: this.control('', true),
      senderSignatory: this.formBuilder.control('', [Validators.maxLength(250)]),
      matter: this.control(null, true),
      recipient: this.formBuilder.control('', [Validators.maxLength(250)]),
      receiver: this.formBuilder.control('', [Validators.maxLength(250)]),
      parent: this.control(null, true),
      needTrack: this.control(false, true),
      postNumber: this.control('', true),
      message: this.control('', true),
      users: this.control([], true),
      companySettings: this.control(null, true),
      type: this.control(CorrespondenceType.incoming, true)
    });
  }

  private updateForm(correspondenceData: Correspondence) {
    this.selectedMatterId = correspondenceData.matter ? correspondenceData.matter.id : null;
    const matterName = correspondenceData.matter ? correspondenceData.matter.name : null;

    this.correspondenceForm = this.formBuilder.group({
      [this.documentDate]: this.control(moment(correspondenceData.documentDate).toDate() || null),
      [this.registrationDate]: this.control(
        (correspondenceData.registrationDate && moment(correspondenceData.registrationDate).toDate()) || null,
        true
      ),
      [this.senderDate]: this.control((correspondenceData.senderDate && moment(correspondenceData.senderDate).toDate()) || null, true),
      outgoingNumber: this.control(correspondenceData.outgoingNumber),
      incomingNumber: this.control(correspondenceData.incomingNumber, true),
      sender: this.control(correspondenceData.sender),
      description: this.control(correspondenceData.description, true),
      matter: this.control(matterName, true),
      senderSignatory: this.control(correspondenceData.senderSignatory, true),
      recipient: this.control(correspondenceData.recipient, true),
      receiver: this.control(correspondenceData.receiver, true),
      needTrack: this.control(correspondenceData.needTrack, true),
      parent: this.control(correspondenceData.parentId, true),
      postNumber: this.control(correspondenceData.postNumber, true),
      companySettings: this.control(correspondenceData.companySettings.id, true),
      type: this.control(correspondenceData.type, true),
      message: this.control('', true),
      users: this.control([], true)
    });
    this.checkValidation(correspondenceData.needTrack);
  }

  private subscribeToEffects() {
    const actionAfterUpdate = correspondence => {
      this.createDocumentsAsync(correspondence.payload.id);
      this.deleteDocumentsAsync(this.documentsToDelete);

      const users = this.correspondenceForm.get('users');
      const message = this.correspondenceForm.get('message');
      if (users.value && users.value.length && message.value) {
        this.store.dispatch(
          new CreateCorrespondenceMessage({
            correspondence: correspondence.payload.id,
            message: message.value,
            users: users.value
          })
        );
      }
    };

    this.correspondenceEffects.create$
      .pipe(ofType<CreateCorrespondenceSuccess>(ECorrespondenceActions.CREATE_CORRESPONDENCE_SUCCESS))
      .subscribe(correspondence => {
        actionAfterUpdate(correspondence);
        this.router.navigate(['/correspondence/card', correspondence.payload.id]);
      });
    this.correspondenceEffects.update$.subscribe(actionAfterUpdate);
  }

  private documentUploadModal(file: File) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];
    dialogConfig.disableClose = true;
    let ifSelectedMatter;
    ifSelectedMatter = this.matters.find(matter => matter.id === this.f.get('matter').value);
    dialogConfig.data = { fileName: file.name, skipMatter: true, ifSelectedMatter };

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    const subscr = dialogRef.afterClosed().subscribe(x => {
      if (x) {
        x.document.correspondence = this.correspondenceId;
        this.documentsToAdd.push({ document: x.document, mediaFile: this.fileData, reminders: x.reminders, relations: x.relations });
        this.documentsToShow.push({ name: x.document.name, media: { type: this.mediaType } });
      }
    });
    this.subscription.add(subscr);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  private getCompanySettings() {
    this.store.select(selectCompanySetting).subscribe(x => {
      if (x?.id) {
        this.companySettings = x;
        this.correspondenceForm.get('companySettings').patchValue(x.id);

        if (this.isEditing) {
          this.switchType(this.dialogData.correspondence.type === CorrespondenceType.outgoing);
        }
        this.setUpNumber();
      } else {
        this.store.dispatch(new LoadCompanySettingAction());
      }
    });
  }

  private setUpNumber() {
    if (this.correspondenceForm && !this.correspondenceId) {
      const type = this.correspondenceForm.value.type === CorrespondenceType.incoming ? 'incomingNumber' : 'outgoingNumber';

      this.correspondenceForm.get(type).patchValue(CorrespondenceSettings[type](this.companySettings));
    }

    this.incomingNumber = CorrespondenceSettings.incomingNumber(this.companySettings);
  }

  private control(value, optional = false): UntypedFormControl {
    return this.formBuilder.control(value, optional ? [] : Validators.required);
  }

  private populateDefaultIncomingForm(correspondenceData) {
    this.correspondenceForm.get('type').patchValue(CorrespondenceType.incoming);
    this.correspondenceForm
      .get('outgoingNumber')
      .patchValue((this.correspondenceId && correspondenceData && correspondenceData.outgoingNumber) || '');
    this.correspondenceForm.get('incomingNumber').patchValue((correspondenceData && correspondenceData.incomingNumber) || '');
    this.correspondenceForm
      .get(this.senderDate)
      .patchValue(correspondenceData ? this.fillDate(correspondenceData, this.senderDate, true) : null);
    this.correspondenceForm.get(this.registrationDate).patchValue(this.fillDate(correspondenceData, this.registrationDate, true));
    this.correspondenceForm.get(this.documentDate).patchValue(this.fillDate(correspondenceData, this.documentDate));
  }

  private populateDefaultOutgoingForm(correspondenceData) {
    this.correspondenceForm.get('type').patchValue(CorrespondenceType.outgoing);
    this.correspondenceForm.get('incomingNumber').patchValue((correspondenceData && correspondenceData.incomingNumber) || '');
    this.correspondenceForm.get('outgoingNumber').patchValue((correspondenceData && correspondenceData.outgoingNumber) || '');
    this.correspondenceForm.get(this.senderDate).patchValue(this.fillDate(correspondenceData, this.senderDate, true));
    this.correspondenceForm
      .get(this.registrationDate)
      .patchValue(correspondenceData ? this.fillDate(correspondenceData, this.registrationDate, true) : null);
    this.correspondenceForm.get(this.documentDate).patchValue(this.fillDate(correspondenceData, this.documentDate, true));
  }

  private fillDate(correspondenceData, fieldName, isToday = false) {
    if (correspondenceData && (fieldName === 'registrationDate' || fieldName === 'senderDate')) {
      if (correspondenceData[fieldName]) {
        return moment(correspondenceData[fieldName]).toDate();
      } else { return null; }
    }

    if (correspondenceData && correspondenceData[fieldName]) {
      return moment(correspondenceData[fieldName]).toDate();
    }
    return isToday ? CustomDateAdapter.today() : null;
  }

  private populateFormData() {
    if (this.isEditing) {
      this.updateForm(this.dialogData.correspondence);
      this.correspondenceId = this.dialogData.correspondence.id;
      this.getMessages();
      this.store.dispatch(new GetDocumentsByCorrespondence(this.correspondenceId));
        this.store.select(selectCorrespondenceDocuments).subscribe(response => {
          this.documentsToShow = response.map(doc => {
            return { name: doc.name, id: doc.id, media: { type: 'document' } };
          });
        })
      this.store.dispatch(new GetTrackInfo(this.dialogData.correspondence.postNumber));
    } else {
      this.createForm();
      this.store.dispatch(new GetTrackInfo(null));
    }
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
  loadContacts() {
    this.subscription.add(
      this.store.select(selectAllContactListForSelect).subscribe(data => {
        if (!data) {
          this.store.dispatch(new LoadAllContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (data) {
          this.contacts = data;
        }
      })
    );
  }
  loadMatters() {
    this.subscription.add(
      this.store.select(selectMatterListForSelect).subscribe(matters => {
        if (!matters) {
          this.store.dispatch(new LoadMattersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (matters) {
          this.matters = matters;
          this.correspondenceForm.patchValue({
            matter: this.selectedMatterId
          });

          if (this.dialogData && this.dialogData.correspondence && this.dialogData.correspondence.matter) {
            if (!this.matters.find(matter => matter.id === this.dialogData.correspondence.matter.id)) {
              this.matters.push(this.dialogData.correspondence.matter);
            }
            this.correspondenceForm.patchValue({
              matter: this.dialogData.correspondence.matter.id
            });
          }

          if (this.dialogData && this.dialogData.matter) {
            if (!this.matters.find(matter => matter.id === this.dialogData.matter.id)) {
              this.matters.push(this.dialogData.matter);
            }
            this.correspondenceForm.patchValue({
              matter: this.dialogData.matter.id
            });
            this.correspondenceForm.get('matter').disable();
          }
        }
      })
    );
  }
  loadUsers() {
    this.subscription.add(
      this.store.select(selectListUsersForSelect).subscribe(resp => {
        if (resp == null) {
          this.store.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (resp) {
          this.users = resp;
        }
      })
    );
  }
  loadCorrespondence() {
    this.subscription.add(
      this.store.select(selectDropDownCorrespondences).subscribe(list => {
        if (!list) {
          this.store.dispatch(
            new GetCorrespondenceDropdown({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect })
          );
        }
        if (list) {
          this.correspondence = list.filter(x => x.id !== this.correspondenceId);
        }
      })
    );
  }

  public onFilteringCorrespondence: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersCorrespondence.title = e.text;
    this.comboBoxCorrespondence?.showSpinner();
    this.debounceService.loadDataDebounce(CorrespondenceNewModalDebounceEnum.correspondence, this.correspondenceService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filtersCorrespondence
    ]);
    this.debounceService.getValue(CorrespondenceNewModalDebounceEnum.correspondence).subscribe(resp => {
      e.updateData(resp?.member?.filter(correspondence => correspondence.id !== this.correspondenceId) as any);
      this.comboBoxCorrespondence?.hideSpinner();
    });
  }

  public onFilteringUsers: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersUser.name = e.text;
    this.comboBoxUser?.showSpinner();
    this.debounceService.loadDataDebounce(CorrespondenceNewModalDebounceEnum.users, this.teamMemberService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filtersUser
    ]);
    this.debounceService.getValue(CorrespondenceNewModalDebounceEnum.users).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxUser?.hideSpinner();
    });
  }

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.filterMatters.name = e.text;
    this.comboBoxMatter?.showSpinner();
    this.debounceService.loadDataDebounce(CorrespondenceNewModalDebounceEnum.matters, this.matterService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filterMatters
    ]);
    this.debounceService.getValue(CorrespondenceNewModalDebounceEnum.matters).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxMatter?.hideSpinner();
    });
  }

  public onFilteringContacts: EmitType<any> = (e: any, keyData) => {
    this.filtersContacts.name = e.text;
    this.showComboSpinner(e.baseEventArgs.target.previousElementSibling.name);
    this.debounceService.loadDataDebounce(keyData, this.contactService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filtersContacts
    ]);
    this.debounceService.getValue(keyData).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.hideComboSpinner(e.baseEventArgs.target.previousElementSibling.name);
    });
  }

  showComboSpinner(whofilters) {
    switch (whofilters) {
      case 'sender':
        if (this.comboBoxSenderContact) {
          this.comboBoxSenderContact.showSpinner();
        }
        break;
      case 'recipient':
        if (this.comboBoxRecipientContact) {
          this.comboBoxRecipientContact.showSpinner();
        }
        break;
      case 'senderSignatory':
        if (this.comboBoxSenderSignatoryContact) {
          this.comboBoxSenderSignatoryContact.showSpinner();
        }
        break;
      case 'receiver':
        if (this.comboBoxReceiverContact) {
          this.comboBoxReceiverContact.showSpinner();
        }
        break;
    }
  }
  hideComboSpinner(whofilters) {
    switch (whofilters) {
      case 'sender':
        if (this.comboBoxSenderContact) {
          this.comboBoxSenderContact.hideSpinner();
        }
        break;
      case 'recipient':
        if (this.comboBoxRecipientContact) {
          this.comboBoxRecipientContact.hideSpinner();
        }
        break;
      case 'senderSignatory':
        if (this.comboBoxSenderSignatoryContact) {
          this.comboBoxSenderSignatoryContact.hideSpinner();
        }
        break;
      case 'receiver':
        if (this.comboBoxReceiverContact) {
          this.comboBoxReceiverContact.hideSpinner();
        }
        break;
    }
  }
  public get correspondenceNewModalDebounceEnum(): typeof CorrespondenceNewModalDebounceEnum {
    return CorrespondenceNewModalDebounceEnum;
  }

  fixHeightList(selectName) {
    setTimeout(() => {
      this[selectName].focusOut();
    }, 0);
  }
  selectUser() {
    setTimeout(() => {
      const multiselectInput = document.querySelector('.correspondence .e-dropdownbase');
      multiselectInput.setAttribute('placeholder', this.translate.instant('correspondence.remindPlaceholder'));
    }, 10);
  }
  fixPopUp(event, selectName) {
    const e = event?.event || event;
    if (!(e['target'] && e['target']['className'] === 'e-dropdownbase')) {
      this[selectName].hidePopup();
    }
  }
}
