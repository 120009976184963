import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { IRegisterDto } from '@app/models/interfaces/register.interface';
import { Payload, IUser, ResponseBodyAuth } from '@app/models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthProxyService {
  private jwtHelper: JwtHelperService;
  constructor(private http: HttpClient, private authenticationApi: AuthService) {
    this.jwtHelper = new JwtHelperService();
  }

  login(user: IUser): Observable<ResponseBodyAuth> {
    user['usid'] = [];
    try {
      user['usid'] = localStorage.getItem('usids') && JSON.parse(localStorage.getItem('usids'));
      if (!Array.isArray(user['usid'])) {
        user['usid'] = [];
      }
    } catch (error) {
      user['usid'] = [];
    }
    return this.http.post<ResponseBodyAuth>(`${environment.apiUrl}/jwt/auth`, user)
      .pipe(
        mergeMap((resp: ResponseBodyAuth) => {
          if (resp?.usid) {
            this.setToCookieUsid(resp.usid);
          }
          this.authenticationApi.currentUserSubject.next(this.getPayload(resp.token));
          return of(resp);
        })
      );
  }

  setToCookieUsid(usid) {
    let usids = localStorage.getItem('usids') && JSON.parse(localStorage.getItem('usids'));
    if (usids) {
      usids.push(usid);
    } else {
      usids = [usid];
    }
    localStorage.setItem('usids', JSON.stringify(usids.filter(this.onlyUnique)));
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  register(input: IRegisterDto): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/registration`, input);
  }

  resetPassword(data) {
    return this.http.post(`${environment.apiUrl}/registration/password/reset`, data);
  }

  passwordChange(data) {
    return this.http.patch(`${environment.apiUrl}/registration/password/change`, data);
  }

  getPayload(token): Payload {
    return this.jwtHelper.decodeToken(token);
  }
}
