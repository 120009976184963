import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { TimeEntryParams } from '@app/models/interfaces/time-entry-params';
import { map } from 'rxjs/operators';
import { ActivityExtended, ResponseBodyFull } from '@app/models';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TimeEntryApi {
  static TIME_ENTRY_ENDPOINT = `${environment.apiUrl}/activities?typeObject[]=time_entry`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  get(params?: TimeEntryParams): Observable<ActivityExtended[]> {
    params['responsiblePerson.id'] = this.authService.currentUserValue.id;
    if (this.authService.currentUserValue && this.authService.isCheckCurrentSubscriptionId()) {
      return this.http
        .get<ResponseBodyFull<ActivityExtended>>(TimeEntryApi.TIME_ENTRY_ENDPOINT, {
          params: params as HttpParams
        })
        .pipe(map(response => response['hydra:member']));
    } else {
      return new Observable<ActivityExtended[]>();
    }
  }
}
