import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Note } from '@app/models/note';
import { NoteFilters } from '@app/models/note-filters';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NoteEffects } from '@app/store/effects/note.effects';
import { selectNotes } from '@app/store/selectors/note.selector';
import { NoteModalComponent } from '@app/commonComponents/modals/default-modals/note-modal/note-modal.component';
import { SelectNote, GetNotes, IfFromContact, IfFromMatter } from '@app/store/actions/note.actions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, AfterViewInit {
  notes: Note[];
  @Input() matter?;
  @Input() contact?;
  filters: NoteFilters = {};
  readonly spinnerName = 'notes';
  showLoader = false;

  searchStringSubject = new Subject();

  constructor(
    private store: Store<IAppState>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private matterNoteEffect: NoteEffects
  ) {
    this.openSpinner();
    matterNoteEffect.getNotes$.subscribe(() => this.hideSpinner());
    this.store.select(selectNotes).subscribe(data => {
      if (data) {
        this.notes = data;
      }
    });
  }
  ngOnInit() {}

  noteModal() {
    const dialogConfig = new MatDialogConfig();
    if (this.matter) {
      dialogConfig.data = { matter: this.matter };
    }
    if (this.contact) {
      dialogConfig.data = { contact: this.contact };
    }

    dialogConfig.panelClass = ['default-mat-dialog', 'note-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(NoteModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.ifModalClosed();
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }
  ifModalClosed() {
    this.store.dispatch(new SelectNote(null));
  }
  ngAfterViewInit() {
    if (this.matter) {
      this.filters['matter.id'] = this.matter.id;
    }
    if (this.contact) {
      this.filters['contact.id'] = this.contact.id;
    }
    this.getFilteredData(this.filters);

    this.searchStringSubject
      .pipe(debounceTime(environment.textSearchDebounce), distinctUntilChanged())
      .subscribe(query => this.textFilter(query));
  }
  textFilter(value) {
    if (this.filters.subject !== value) {
      this.getFilteredData({ ...this.filters, subject: value });
    }
  }
  getFilteredData(noteFilter: NoteFilters) {
    this.openSpinner();
    this.filters = noteFilter;
    this.chekIf();
    this.store.dispatch(new GetNotes(noteFilter));
  }
  chekIf() {
    if (this.filters['contact.id']) {
      this.store.dispatch(new IfFromContact(this.filters['contact.id']));
    }
    if (this.filters['matter.id']) {
      this.store.dispatch(new IfFromMatter(this.filters['matter.id']));
    }
  }
  private openSpinner() {
    this.showLoader = true;
  }
  private hideSpinner() {
    this.showLoader = false;
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
}
