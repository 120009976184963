import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helpers } from '@app/helpers/helpers';
import { ResponseBodyFull } from '@app/models';
import { Paginator } from '@app/models/paginator';
import { Tag } from '@app/models/tag';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  constructor(private http: HttpClient) {}

  getAllTags(): Observable<ResponseBodyFull<Tag>> {
    const params = new HttpParams().set('page', 1).set('itemsPerPage', 10000000);
    return this.http.get<ResponseBodyFull<Tag>>(`${environment.apiUrl}/tags`, { params });
  }

  getAllTagPaginator(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters: any = null): Observable<Paginator<Tag>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      if (filters.status === 'all') {
        filters.status = null;
      }
      params = Helpers.populateFilters(params, filters);
    }

    return this.http
      .get<ResponseBodyFull<Tag>>(`${environment.apiUrl}/tags`, { params })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
  }

  addTag(tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(`${environment.apiUrl}/tags`, tag);
  }

  getTag(tag_id: Tag): Observable<Tag> {
    return this.http.get<Tag>(`${environment.apiUrl}/tags/${tag_id}`);
  }

  updateTag(tag: Tag): Observable<Tag> {
    return this.http.patch<Tag>(`${environment.apiUrl}/tags/${tag?.id}`, tag);
  }

  deleteTag(tagId: number): Observable<Tag> {
    return this.http.delete<Tag>(`${environment.apiUrl}/tags/${tagId}`);
  }

}
