import { ChangeDetectorRef, Component } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'jn-check-box',
  standalone: true,
  imports: [
    ReactiveFormsModule,
  ],
  templateUrl: './jn-check-box.component.html',
  styleUrl: './jn-check-box.component.scss',
})
export class JnCheckBoxComponent implements ControlValueAccessor {

  formControl = new FormControl();

  constructor(protected cd: ChangeDetectorRef, public ngControl: NgControl) {
    // Use the `ngControl` to access the related form control.
    // The `NG_VALUE_ACCESSOR` provider was removed from the component, as it is assigned here directly.
    ngControl.valueAccessor = this;
  }


  /** Triggered whether the value is changed. Will be overwritten by Angular forms. */
  public onChange?: (value: boolean) => void;

  /** Triggered whether the control is touched. Will be overwritten by Angular forms. */
  public onTouched?: () => void;

  /**
   * Required by ControlValueAccessor.
   * Function for overriding the `onChange` class method.
   */
  public registerOnChange(fn: (value: any) => void): void {
    // Override the class method with provided function.
    this.onChange = fn;
  }

  /**
   * Required by ControlValueAccessor.
   * Function for overriding the `onTouched` class method.
   */
  public registerOnTouched(fn: () => void): void {
    // Override the class method with provided function.
    this.onTouched = fn;
  }

  /**
   * Required by ControlValueAccessor.
   * Describes how to update the form model when the value changes.
   */
  public writeValue(value: boolean): void {
    this.formControl.setValue(value, { emitEvent: false });
  }

}
