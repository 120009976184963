import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, share, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
  AddTimeEntryType,
  AddTimeEntryTypeFailure,
  AddTimeEntryTypeSuccess,
  DeleteTimeEntryType,
  DeleteTimeEntryTypeFailure,
  DeleteTimeEntryTypes,
  DeleteTimeEntryTypesSuccess,
  DeleteTimeEntryTypeSuccess,
  ETimeEntryTypesActions,
  GetTimeEntryTypes,
  GetTimeEntryTypesFailure,
  GetTimeEntryTypesSuccess,
  LoadTimeEntryTypesAction,
  LoadTimeEntryTypesFailureAction,
  LoadTimeEntryTypesSuccessAction,
  UpdateTimeEntryType,
  UpdateTimeEntryTypeFailure,
  UpdateTimeEntryTypeSuccess,
} from '../actions/time-entry-types.actions';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TimeEntryTypesService } from '../../services/time-entry-types.service';

@Injectable()
export class TimeEntryTypeEffects {
  loadTimeEntryTypes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadTimeEntryTypesAction>(ETimeEntryTypesActions.LOAD_TIME_ENTRY_TYPES),
    mergeMap(() =>
      this.timeEntryTypeService.get().pipe(
        map(data => new LoadTimeEntryTypesSuccessAction(data)),
        catchError(error => of(new LoadTimeEntryTypesFailureAction(error))),
      ),
    ),
  ));

  getTimeEntryTypes$ = createEffect(() => this.actions$.pipe(
    ofType<GetTimeEntryTypes>(ETimeEntryTypesActions.GET_TIME_ENTRY_TYPES),
    switchMap(action => {
      return this.timeEntryTypeService.getTimeEntryTypes(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetTimeEntryTypesSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetTimeEntryTypesFailure(error));
        }),
      );
    }),
    share(),
  ));

  addTimeEntryType$ = createEffect(() => this.actions$.pipe(
    ofType<AddTimeEntryType>(ETimeEntryTypesActions.ADD_TIME_ENTRY_TYPE),
    switchMap(action => {
      return this.timeEntryTypeService.addTimeEntryType(action.payload).pipe(
        mergeMap(x => {
          return of(new AddTimeEntryTypeSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddTimeEntryTypeFailure(error));
        }),
      );
    }),
  ));

  addTimeEntryTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddTimeEntryTypeSuccess>(ETimeEntryTypesActions.ADD_TIME_ENTRY_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetTimeEntryTypes({
          page: storeState.timeEntryTypeState.paginator.currentPage,
          itemsPerPage: storeState.timeEntryTypeState.paginator.itemsPerPage,
          filters: { name: '', 'exists[parent]': 'false' },
        }),
      );
    }),
  ));

  updateTimeEntryType$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTimeEntryType>(ETimeEntryTypesActions.UPDATE_TIME_ENTRY_TYPE),
    switchMap(action => {
      return this.timeEntryTypeService.updateTimeEntryType(action.payload).pipe(
        mergeMap(x => {
          return of(new UpdateTimeEntryTypeSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new UpdateTimeEntryTypeFailure(error));
        }),
      );
    }),
  ));


  updateTimeEntryTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTimeEntryTypeSuccess>(ETimeEntryTypesActions.UPDATE_TIME_ENTRY_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetTimeEntryTypes({
          page: storeState.timeEntryTypeState.paginator.currentPage,
          itemsPerPage: storeState.timeEntryTypeState.paginator.itemsPerPage,
          filters: { name: '', 'exists[parent]': 'false' },
        }),
      );
    }),
  ));

  deleteTimeEntryType$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTimeEntryType>(ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPE),
    switchMap(action => {
      const deleteMethods = action.payload.map(x => this.timeEntryTypeService.deleteTimeEntryType(x));
      return forkJoin(deleteMethods).pipe(
        mergeMap(() => of(new DeleteTimeEntryTypeSuccess())),
        catchError(error => of(new DeleteTimeEntryTypeFailure(error))),
      );
    }),
  ));
  deleteTimeEntryTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTimeEntryTypeSuccess>(ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemDeleted'));
      return of(
        new GetTimeEntryTypes({
          page: storeState.timeEntryTypeState.paginator.currentPage,
          itemsPerPage: storeState.timeEntryTypeState.paginator.itemsPerPage,
          filters: { name: '', 'exists[parent]': 'false' },
        }),
      );
    }),
  ));

  deleteTimeEntryTypes$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTimeEntryTypes>(ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPES),
    switchMap(action => {
      const deleteMethods = [];
      action.payload.forEach(x => {
        deleteMethods.push(this.timeEntryTypeService.deleteTimeEntryType(x));
      });
      return forkJoin(deleteMethods).pipe(
        mergeMap(() => {
          return of(new DeleteTimeEntryTypesSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteTimeEntryTypes(error));
        }),
      );
    }),
  ));

  deleteTimeEntryTypesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTimeEntryTypesSuccess>(ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPES_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemsDeleted'));
      return of(
        new GetTimeEntryTypes({
          page: storeState.timeEntryTypeState.paginator.currentPage,
          itemsPerPage: storeState.timeEntryTypeState.paginator.itemsPerPage,
        }),
      );
    }),
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private timeEntryTypeService: TimeEntryTypesService,
  ) {
  }
}
