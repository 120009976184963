import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, mergeMap, share, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  EAuthActions,
  ForgotConfirm,
  ForgotConfirmFailure,
  ForgotConfirmSuccess,
  GetCurrentUser,
  GetCurrentUserSuccess,
  Login,
  LoginFailure,
  LoginSuccess,
  Logout,
  Register,
  RegisterFailure,
  RegisterSuccess,
  UpdateSidebarStatus,
  UpdateSidebarStatusSuccess,
  UpdateUser,
  UpdateUserLang,
  UpdateUserLangSuccess,
  UpdateUserSuccess
} from '../actions/auth.actions';
import { AuthProxyService } from '@app/auth/_services/auth-proxy.service';
import { AuthService } from '@app/auth/_services/auth.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { GetPracticeBranches } from '@app/modules/settings/store/actions/practice-branches.actions';
import { LoadExpenseTypesAction } from '@app/modules/settings/store/actions/expense-types.actions';
import { LoadTimeEntryTypesAction } from '@app/modules/settings/store/actions/time-entry-types.actions';
import { LoadDocumentCategories } from '@app/modules/settings/store/actions/document-categories.actions';
import { LoadRelationshipTypesAction } from '@app/modules/settings/store/actions/relationship-types.actions';
import { LoadPersonTypesAction } from '@app/modules/settings/store/actions/person-types.actions';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { GetAnyoneWorkGroups, GetMyAndAnyoneWorkGroups } from '@app/modules/settings/store/actions/team.actions';
import { ETeamMemberRoles } from '@app/enums/team-member-roles';
import { HttpErrorHandlerService } from '@app/helpers/http-error-handler.service';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => this.actions$.pipe(
    ofType<Login>(EAuthActions.LOGIN),
    switchMap(action => {
      return this.authProxyService.login(action.payload).pipe(
        mergeMap(resp => {
          return [new LoginSuccess(resp, action.payload.fromModal)];
        }),
        catchError(errorResp => {
          return [new LoginFailure(errorResp.error)];
        })
      );
    }),
    share()
  ));


  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoginSuccess>(EAuthActions.LOGIN_SUCCESS),
    tap(resp => {
      if (this.authService.decodeToken(resp.payload.token)?.roles.find(item => item === ETeamMemberRoles.portal)) {
        this.toastrService.error(this.translate.instant('security.access_denied'));
      } else {
        if (!resp.payload.hasOwnProperty('2fa')) {
          this.authService.doLoginUser(resp.payload);
          const expired = this.authService.checkSubscription(resp.fromModal); // Navigation moved here
          if (resp.payload.success !== false && !expired) {
            // LOAD ALL LIBRARY AND SETTING AFTER SUCCESS AUTH
            this.loadLibraryAndSetting();
            if (resp.fromModal) {
              this.store.dispatch(new GetCurrentUser(this.authService.getPayload().id));
            }
          }
          if (expired) {
            this.loadStoreForExpired();
          }
        }
      }
    })
  ), { dispatch: false });


  loginFailure$ = createEffect(() => this.actions$.pipe(
    ofType<LoginFailure>(EAuthActions.LOGIN_FAILURE),
    tap(resp => {
      this.httpErrorHandlerService.handle(resp.payload as any);
    })
  ), { dispatch: false });


  register$ = createEffect(() => this.actions$.pipe(
    ofType<Register>(EAuthActions.REGISTER),
    switchMap(action => {
      return this.authProxyService.register(action.payload).pipe(
        mergeMap((resp: any) => {
          return [resp.id ? new RegisterSuccess(resp) : new RegisterFailure(resp)];
        }),
        catchError(errorResp => {
          return [new RegisterFailure(errorResp.error)];
        })
      );
    }),
    share()
  ));


  registerSuccess$ = createEffect(() => this.actions$.pipe(ofType<RegisterSuccess>(EAuthActions.REGISTER_SUCCESS)), { dispatch: false });


  registerFailure$ = createEffect(() => this.actions$.pipe(
    ofType<RegisterFailure>(EAuthActions.REGISTER_FAILURE),
    tap(resp => {
      this.httpErrorHandlerService.handle(resp.payload as any);
    })
  ), { dispatch: false });


  logout$ = createEffect(() => this.actions$.pipe(
    ofType<Logout>(EAuthActions.LOGOUT),
    tap(() => {
      this.authService.logout();
    })
  ), { dispatch: false });

  forgotConfirm$ = createEffect(() => this.actions$.pipe(
    ofType<ForgotConfirm>(EAuthActions.FORGOT_CONFIRM),
    switchMap(action => {
      return this.authProxyService.passwordChange(action.payload).pipe(
        mergeMap((resp) => {
          return [resp ? new ForgotConfirmSuccess(resp) : new ForgotConfirmFailure(resp)];
        }),
        catchError(error => {
          return of(new ForgotConfirmFailure(error));
        })
      );
    }),
    share()
  ));

  forgotConfirmSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<ForgotConfirmSuccess>(EAuthActions.FORGOT_CONFIRM_SUCCESS),
    tap(resp => {
      if (this.authService.doLoginUser(resp.payload)) {
        this.router.navigate(['/']);
      }
    })
  ), { dispatch: false });


  forgotConfirmFailure$ = createEffect(() => this.actions$.pipe(
      ofType<ForgotConfirmFailure>(EAuthActions.FORGOT_CONFIRM_FAILURE),
      tap(error => {
        // TODO зробити обробник на помилку
      })),
    { dispatch: false }
  );


  fetchUser$ = createEffect(() => this.actions$.pipe(
    ofType<GetCurrentUser>(EAuthActions.GET_CURRENT_USER),
    switchMap(action => {
      return this.authService.getUserById(action.payload).pipe(
        mergeMap(response => {
          return [new GetCurrentUserSuccess(response)];
        })
      );
    }),
    share()
  ));


  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUser>(EAuthActions.UPDATE_USER),
    switchMap(action => {
      return this.teamMemberService.changeOneUser(action.id, action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          this.toastrService.success(this.translate.instant('itemAdded'));
          return [new UpdateUserSuccess(response)];
        })
      );
    }),
    share()
  ));


  updateSidebarStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateSidebarStatus>(EAuthActions.UPDATE_SIDEBAR_STATUS),
    switchMap(action => {
      return this.teamMemberService.changeOneUser(action.id, action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          return [new UpdateSidebarStatusSuccess(response)];
        })
      );
    }),
    share()
  ));


  updateUserLang$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserLang>(EAuthActions.UPDATE_USER_LANG),
    switchMap(action => {
      return this.teamMemberService.changeOneUser(action.userId, action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          return [new UpdateUserLangSuccess(response)];
        })
      );
    }),
    share()
  ));

  updateUserLangSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserLangSuccess>(EAuthActions.UPDATE_USER_LANG_SUCCESS),
    tap(() => {
      this.loadAfterChangeLang();
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private authProxyService: AuthProxyService,
    private authService: AuthService,
    private router: Router,
    private store: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private teamMemberService: TeamMemberService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {
  }

  loadLibraryAndSetting() {
    this.store.dispatch(new LoadCompanySettingAction());
    this.store.dispatch(new GetPracticeBranches({ page: 1, itemsPerPage: 10000 }));
    this.store.dispatch(new LoadExpenseTypesAction());
    this.store.dispatch(new LoadTimeEntryTypesAction());
    this.store.dispatch(new LoadDocumentCategories());
    this.store.dispatch(new LoadRelationshipTypesAction());
    this.store.dispatch(new LoadPersonTypesAction());
  }

  loadStoreForExpired() {
    this.store.dispatch(new LoadCompanySettingAction());
  }

  loadAfterChangeLang() {
    this.store.dispatch(new GetAnyoneWorkGroups());
    this.store.dispatch(new GetMyAndAnyoneWorkGroups());
  }
}
