import { Paginator } from '../../../../models/paginator';

import { PersonType } from '../../../../models/index';

export interface IPersonTypeState {
  paginator: Paginator<PersonType>;
  selectedPersonType: PersonType;
  list: Array<PersonType>;
  loading: boolean;
  error: Error;
}

export const initialPersonTypeState: IPersonTypeState = {
  paginator: null,
  selectedPersonType: null,
  list: null,
  loading: false,
  error: undefined
};
