import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { AddedMedia, AddMedia, AddMediaFailure, EMediaActions } from '../actions/media.actions';
import { switchMap, mergeMap, catchError, share } from 'rxjs/operators';
import { of } from 'rxjs';
import { MediaService } from '@app/modules/main-documents/services/media.service';


@Injectable()
export class MediaEffects {

  addMedia$ = createEffect(() => this.actions$.pipe(
    ofType<AddMedia>(EMediaActions.ADD_MEDIA),
    switchMap(action => {
      return this.mediaService.addMedia(action.payload, action.fileType).pipe(
        mergeMap(resp => {
          return [new AddedMedia(resp)];
        }),
        catchError((error) => {
          return of(new AddMediaFailure(error));
        })
      );
    }),
    share()
  ));

  constructor(private actions$: Actions, private mediaService: MediaService) {}
}
