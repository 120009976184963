import { Action } from '@ngrx/store';
import { TaskExtended, TaskSave } from '@app/models';
import { DocumentSave } from '@app/models/document-save';
import { TasksFetch } from '@app/models/interfaces/tasks-fetch';
import { TaskStatus } from '@app/enums/task-status.enum';
import { Paginator } from '@app/models/paginator';
import { TasksFilters } from '@app/models/tasks-filters';

export enum ETaskActions {
  GET_TASKS = '[TASKS] Get Tasks',
  GET_TASKS_SUCCESS = '[TASKS] Get Tasks Success',
  GET_TASKS_FAILURE = '[TASKS] Get Tasks Failure',
  GET_TASK = '[TASKS] Get Task',
  GET_TASK_SUCCESS = '[TASKS] Get Task Success',
  GET_TASK_FAILURE = '[TASKS] Get Task Failure',
  ADD_TASK = '[TASKS] Add Task',
  ADD_TASK_SUCCESS = '[TASKS] Add Task Success',
  ADD_TASK_FAILURE = '[TASKS] Add Task Failure',
  ADD_TASK_WITH_DOCUMENTS = '[TASKS] Add Task With Documents',
  ADD_TASK_WITH_DOCUMENTS_SUCCESS = '[TASKS] Add Task With Documents Success',
  ADD_TASK_WITH_DOCUMENTS_FAILURE = '[TASKS] Add Task With Documents Failure',
  UPDATE_TASK = '[TASKS] Update Task',
  UPDATE_TASK_SUCCESS = '[TASKS] Update Task Success',
  UPDATE_TASK_FAILURE = '[TASKS] Update Task Failure',
  UPDATE_TASK_STATUS = '[TASKS] Update Task Status',
  UPDATE_TASK_STATUS_SUCCESS = '[TASKS] Update Task Status Success',
  UPDATE_TASK_STATUS_FAILURE = '[TASKS] Update Task Status Failure',
  DELETE_TASK = '[TASKS] Delete Task',
  DELETE_TASK_SUCCESS = '[TASKS] Delete Task Success',
  DELETE_TASK_FAILURE = '[TASKS] Delete Task Failure',
  ADD_TASK_FILTERS = '[TASKS] Add Tasks Filters',
  CLEAR_TASKS = '[TASKS] Clear Tasks'
}
export class ClearTasks implements Action {
  readonly type = ETaskActions.CLEAR_TASKS;

  constructor() {}
}

export class GetTasks implements Action {
  readonly type = ETaskActions.GET_TASKS;

  constructor(public payload: TasksFetch) {}
}

export class GetTasksSuccess implements Action {
  readonly type = ETaskActions.GET_TASKS_SUCCESS;

  constructor(public payload: Paginator<TaskExtended>) {}
}

export class GetTasksFailure implements Action {
  readonly type = ETaskActions.GET_TASKS_FAILURE;

  constructor(public error: Error) {}
}

export class GetTask implements Action {
  readonly type = ETaskActions.GET_TASK;

  constructor(public payload: number) {}
}

export class GetTaskSuccess implements Action {
  readonly type = ETaskActions.GET_TASK_SUCCESS;

  constructor(public payload: TaskExtended) {}
}

export class GetTaskFailure implements Action {
  readonly type = ETaskActions.GET_TASK_FAILURE;

  constructor(public error: Error) {}
}

export class AddTask implements Action {
  readonly type = ETaskActions.ADD_TASK;

  constructor(public payload: { task: TaskExtended | TaskSave; reminder: any }) {}
}

export class AddTaskSuccess implements Action {
  readonly type = ETaskActions.ADD_TASK_SUCCESS;

  constructor(public payload: TaskExtended) {}
}

export class AddTaskFailure implements Action {
  readonly type = ETaskActions.ADD_TASK_FAILURE;

  constructor(public error: Error) {}
}

export class AddTaskWithDocuments implements Action {
  readonly type = ETaskActions.ADD_TASK_WITH_DOCUMENTS;

  constructor(public payload: { task: TaskSave; documents: { document: DocumentSave; mediaFile: File }[]; reminder: any }) {}
}

export class AddTaskWithDocumentsSuccess implements Action {
  readonly type = ETaskActions.ADD_TASK_WITH_DOCUMENTS_SUCCESS;

  constructor(public payload: TaskExtended) {}
}

export class AddTaskWithDocumentsFailure implements Action {
  readonly type = ETaskActions.ADD_TASK_WITH_DOCUMENTS_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateTask implements Action {
  readonly type = ETaskActions.UPDATE_TASK;

  constructor(public payload: { task: TaskExtended | TaskSave; reminder?: any }) {}
}

export class UpdateTaskSuccess implements Action {
  readonly type = ETaskActions.UPDATE_TASK_SUCCESS;

  constructor(public payload: TaskExtended) {}
}

export class UpdateTaskFailure implements Action {
  readonly type = ETaskActions.UPDATE_TASK_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateTaskStatus implements Action {
  readonly type = ETaskActions.UPDATE_TASK_STATUS;

  constructor(public payload: { id: number; status: TaskStatus }) {}
}

export class UpdateTaskStatusSuccess implements Action {
  readonly type = ETaskActions.UPDATE_TASK_STATUS_SUCCESS;

  constructor(public payload: TaskExtended) {}
}

export class UpdateTaskStatusFailure implements Action {
  readonly type = ETaskActions.UPDATE_TASK_STATUS_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteTask implements Action {
  readonly type = ETaskActions.DELETE_TASK;

  constructor(public payload: number) {}
}

export class DeleteTaskSuccess implements Action {
  readonly type = ETaskActions.DELETE_TASK_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteTaskFailure implements Action {
  readonly type = ETaskActions.DELETE_TASK_FAILURE;

  constructor(public error: Error) {}
}

export class AddTaskFilters implements Action {
  readonly type = ETaskActions.ADD_TASK_FILTERS;

  constructor(public payload: TasksFilters) {}
}

export type TaskActions =
  | ClearTasks
  | GetTasks
  | GetTasksSuccess
  | GetTasksFailure
  | GetTask
  | GetTaskSuccess
  | GetTaskFailure
  | AddTask
  | AddTaskSuccess
  | AddTaskFailure
  | AddTaskWithDocuments
  | AddTaskWithDocumentsSuccess
  | AddTaskWithDocumentsFailure
  | UpdateTask
  | UpdateTaskSuccess
  | UpdateTaskFailure
  | UpdateTaskStatus
  | UpdateTaskStatusSuccess
  | UpdateTaskStatusFailure
  | DeleteTask
  | DeleteTaskSuccess
  | DeleteTaskFailure
  | AddTaskFilters;
