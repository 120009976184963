// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-block_days {
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.right-block_days p {
  letter-spacing: 0.14px;
  color: #707B86;
  font-size: 12px;
  margin: 0;
}
.right-block_days a {
  cursor: pointer;
  color: #1252af;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/subscription-left/subscription-left.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;AAEE;EACE,sBAAA;EACA,cAAA;EACA,eAAA;EACA,SAAA;AAAJ;AAEE;EACE,eAAA;EACA,cAAA;AAAJ","sourcesContent":[".right-block_days {\n  display: flex;\n  align-items: center;\n  padding-left: 5px;\n\n\n  p {\n    letter-spacing: 0.14px;\n    color: #707B86;\n    font-size: 12px;\n    margin: 0;\n  }\n  a {\n    cursor: pointer;\n    color: #1252af;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
