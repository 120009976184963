import { Action } from '@ngrx/store';
import { Contact } from '@app/models/contact';
import { Paginator } from '@app/models/paginator';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { ContactFilters } from '@app/models/contact-filters';

export enum EContactActions {
  CREATE_CONTACT = '[Contact] Create',
  CREATE_CONTACT_SUCCESS = '[Contact] Created',
  GET_CONTACTS = '[Contact] Fetch',
  GET_CONTACTS_SUCCESS = '[Contact] Fetched',
  GET_CONTACTS_FAILURE = '[Contact] Get contacts failure',
  GET_CONTACT_BY_ID = '[Contact] Get contact by id',
  GET_CONTACT_BY_ID_SUCCESS = '[Contact] Get contact by id success',
  SELECT_CONTACT = '[Contact] Select contact from store',

  SELECT_PERSON = '[Contact] Select person from store',
  REMOVE_SELECTED_DIRECTOR = '[Contact] Remove selected director',
  REMOVE_SELECTED = '[Contact] Remove selected',
  REMOVE_CONTACT = '[Contact] Remove',
  REMOVE_CONTACT_SUCCESS = '[Contact] Removed',
  UPDATE_CONTACT = '[Contact] Update contact',
  UPDATE_CONTACT_SUCCESS = '[Contact] Update contact success',

  LOAD_ALL_CONTACTS_FOR_LIST = '[Contact] Load All Contacts for list',
  LOAD_ALL_CONTACTS_FOR_LIST_SUCCESS = '[Contact] Load All Contacts for list Success',
  LOAD_ALL_CONTACTS_FOR_LIST_FAILURE = '[Contact] Load All Contacts for list Failure',

  LOAD_PERSON_CONTACTS_FOR_LIST = '[Contact] Load Person Contacts for list',
  LOAD_PERSON_CONTACTS_FOR_LIST_SUCCESS = '[Contact] Load Person Contacts for list Success',
  LOAD_PERSON_CONTACTS_FOR_LIST_FAILURE = '[Contact] Load Person Contacts for list Failure',

  LOAD_CONTACTS_FOR_LIST = '[Contact] Load Contacts for list',
  LOAD_CONTACTS_FOR_LIST_SUCCESS = '[Contact] Load Contacts for list Success',
  LOAD_CONTACTS_FOR_LIST_FAILURE = '[Contact] Load Contacts for list Failure',
  UPDATE_CONTACTS_FOR_LIST = '[Contact] Update Contacts for list',
  UPDATE_CONTACTS_FOR_LIST_SUCCESS = '[Contact] Update Contacts for list Success',
  UPDATE_CONTACTS_FOR_LIST_FAILURE = '[Contact] Update Contacts for list Failure',

  LOAD_CONTACTS_FOR_LIST_DIRECTOR = '[Contact] Load Contacts for list director',
  LOAD_CONTACTS_FOR_LIST_DIRECTOR_SUCCESS = '[Contact] Load Contacts for list director Success',
  LOAD_CONTACTS_FOR_LIST_DIRECTOR_FAILURE = '[Contact] Load Contacts for list director Failure',

  LOAD_ONLY_CLIENTS_FOR_LIST = '[Contact] Load Only Clients for list',
  LOAD_ONLY_CLIENTS_FOR_LIST_SUCCESS = '[Contact] Load Only Clients for list Success',
  LOAD_ONLY_CLIENTS_FOR_LIST_FAILURE = '[Contact] Load Only Clients for list Failure',
  UPDATE_ONLY_CLIENTS_FOR_LIST = '[Contact] Update Only Clients for list',
  UPDATE_ONLY_CLIENTS_FOR_LIST_SUCCESS = '[Contact] Update Only Clients for list Success',
  UPDATE_ONLY_CLIENTS_FOR_LIST_FAILURE = '[Contact] Update Only Clients for list Failure',

  LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST = '[Contact] Load Active Person and  Contacts for list',
  LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST_SUCCESS = '[Contact] Load Load Active Person and Contacts for list Success',
  LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST_FAILURE = '[Contact] Load Load Active Person and Contacts for list Failure',

  CLEAR_CONTACTS = '[Contact] Clear Contacts',
}

export class LoadPersonContactsForList implements Action {
  public readonly type = EContactActions.LOAD_PERSON_CONTACTS_FOR_LIST;

  constructor(public payload: FetchPayload<ContactFilters>) {}
}

export class LoadPersonContactsForListSuccess implements Action {
  public readonly type = EContactActions.LOAD_PERSON_CONTACTS_FOR_LIST_SUCCESS;

  constructor(public payload: Contact[]) {}
}
export class LoadPersonContactsForListFailure implements Action {
  public readonly type = EContactActions.LOAD_PERSON_CONTACTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadActivePersonAndContactsForList implements Action {
  public readonly type = EContactActions.LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST;

  constructor(public payload: FetchPayload<ContactFilters>) {}
}

export class LoadActivePersonAndContactsForListSuccess implements Action {
  public readonly type = EContactActions.LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST_SUCCESS;

  constructor(public payload: Contact[]) {}
}
export class LoadActivePersonAndContactsForListFailure implements Action {
  public readonly type = EContactActions.LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadAllContactsForList implements Action {
  public readonly type = EContactActions.LOAD_ALL_CONTACTS_FOR_LIST;

  constructor(public payload: FetchPayload<ContactFilters>) {}
}

export class LoadAllContactsForListSuccess implements Action {
  public readonly type = EContactActions.LOAD_ALL_CONTACTS_FOR_LIST_SUCCESS;

  constructor(public payload: Contact[]) {}
}
export class LoadAllContactsForListFailure implements Action {
  public readonly type = EContactActions.LOAD_ALL_CONTACTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadOnlyClientsForList implements Action {
  public readonly type = EContactActions.LOAD_ONLY_CLIENTS_FOR_LIST;

  constructor(public payload: FetchPayload<ContactFilters>) {}
}
export class LoadOnlyClientsForListSuccess implements Action {
  public readonly type = EContactActions.LOAD_ONLY_CLIENTS_FOR_LIST_SUCCESS;

  constructor(public payload: Contact[]) {}
}
export class LoadOnlyClientsForListFailure implements Action {
  public readonly type = EContactActions.LOAD_ONLY_CLIENTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateOnlyClientsForList implements Action {
  public readonly type = EContactActions.UPDATE_ONLY_CLIENTS_FOR_LIST;

  constructor(public payload: number) {}
}
export class UpdateOnlyClientsForListSuccess implements Action {
  public readonly type = EContactActions.UPDATE_ONLY_CLIENTS_FOR_LIST_SUCCESS;

  constructor(public payload: Contact) {}
}
export class UpdateOnlyClientsForListFailure implements Action {
  public readonly type = EContactActions.UPDATE_ONLY_CLIENTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

// exists[contactPerson.user]=false
export class LoadContactsForList implements Action {
  public readonly type = EContactActions.LOAD_CONTACTS_FOR_LIST;

  constructor(public payload: FetchPayload<ContactFilters>) {}
}
export class LoadContactsForListSuccess implements Action {
  public readonly type = EContactActions.LOAD_CONTACTS_FOR_LIST_SUCCESS;

  constructor(public payload: Contact[]) {}
}
export class LoadContactsForListFailure implements Action {
  public readonly type = EContactActions.LOAD_CONTACTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateContactsForList implements Action {
  public readonly type = EContactActions.UPDATE_CONTACTS_FOR_LIST;

  constructor(public payload: number) {}
}
export class UpdateContactsForListSuccess implements Action {
  public readonly type = EContactActions.UPDATE_CONTACTS_FOR_LIST_SUCCESS;

  constructor(public payload: Contact) {}
}
export class UpdateContactsForListFailure implements Action {
  public readonly type = EContactActions.UPDATE_CONTACTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadContactsForListDirector implements Action {
  public readonly type = EContactActions.LOAD_CONTACTS_FOR_LIST_DIRECTOR;

  constructor(public payload: FetchPayload<ContactFilters>) {}
}

export class LoadContactsForListDirectorSuccess implements Action {
  public readonly type = EContactActions.LOAD_CONTACTS_FOR_LIST_DIRECTOR_SUCCESS;

  constructor(public payload: Contact[]) {}
}
export class LoadContactsForListDirectorFailure implements Action {
  public readonly type = EContactActions.LOAD_CONTACTS_FOR_LIST_DIRECTOR_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateContact implements Action {
  public readonly type = EContactActions.CREATE_CONTACT;

  constructor(public payload: Contact, public isNestedPerson: boolean, public isMatter: boolean, public createMatter: boolean = false) {}
}

export class CreateContactSuccess implements Action {
  public readonly type = EContactActions.CREATE_CONTACT_SUCCESS;

  constructor(public payload: Contact, public isNestedPerson: boolean, public isMatter: boolean, public createMatter: boolean = false) {}
}

export class RemoveSelectedDirector implements Action {
  public readonly type = EContactActions.REMOVE_SELECTED_DIRECTOR;

  constructor() {}
}

export class RemoveSelected implements Action {
  public readonly type = EContactActions.REMOVE_SELECTED;

  constructor() {}
}

export class RemoveContact implements Action {
  public readonly type = EContactActions.REMOVE_CONTACT;

  constructor(public payload: number) {}
}

export class SelectContact implements Action {
  public readonly type = EContactActions.SELECT_CONTACT;

  constructor(public payload: number) {}
}

export class SelectPerson implements Action {
  public readonly type = EContactActions.SELECT_PERSON;

  constructor(public payload: number) {}
}

export class RemoveContactSuccess implements Action {
  public readonly type = EContactActions.REMOVE_CONTACT_SUCCESS;

  constructor(public payload: number) {}
}

export class GetContactById implements Action {
  public readonly type = EContactActions.GET_CONTACT_BY_ID;

  constructor(public payload: number) {}
}

export class GetContactByIdSuccess implements Action {
  public readonly type = EContactActions.GET_CONTACT_BY_ID_SUCCESS;

  constructor(public payload: Contact) {}
}

export class GetContacts implements Action {
  public readonly type = EContactActions.GET_CONTACTS;

  constructor(public payload) {}
}

export class GetContactsSuccess implements Action {
  public readonly type = EContactActions.GET_CONTACTS_SUCCESS;

  constructor(public payload: Paginator<Contact>) {}
}

export class GetContactsFailure implements Action {
  public readonly type = EContactActions.GET_CONTACTS_FAILURE;

  constructor(public payload: Paginator<Contact>) {}
}

export class EditContact implements Action {
  public readonly type = EContactActions.UPDATE_CONTACT;

  constructor(public payload: Contact, public id: number, public createMatter: boolean = false) {}
}

export class EditContactSuccess implements Action {
  public readonly type = EContactActions.UPDATE_CONTACT_SUCCESS;

  constructor(public payload: Contact, public createMatter: boolean = false) {}
}

export class ClearContacts implements Action {
  public readonly type = EContactActions.CLEAR_CONTACTS;

  constructor() {}
}

export type ContactActions =
  | SelectContact
  | SelectPerson
  | CreateContact
  | CreateContactSuccess
  | RemoveContact
  | RemoveContactSuccess
  | GetContacts
  | GetContactById
  | GetContactByIdSuccess
  | GetContactsSuccess
  | LoadPersonContactsForList
  | LoadPersonContactsForListSuccess
  | EditContactSuccess
  | RemoveSelectedDirector
  | RemoveSelected
  | EditContact
  | LoadContactsForList
  | LoadContactsForListSuccess
  | LoadContactsForListFailure
  | UpdateContactsForList
  | UpdateContactsForListSuccess
  | UpdateContactsForListFailure
  | LoadAllContactsForList
  | LoadAllContactsForListSuccess
  | LoadAllContactsForListFailure
  | LoadOnlyClientsForList
  | LoadOnlyClientsForListSuccess
  | LoadOnlyClientsForListFailure
  | UpdateOnlyClientsForList
  | UpdateOnlyClientsForListSuccess
  | UpdateOnlyClientsForListFailure
  | LoadContactsForListDirector
  | LoadContactsForListDirectorSuccess
  | LoadContactsForListDirectorFailure
  | LoadActivePersonAndContactsForList
  | LoadActivePersonAndContactsForListSuccess
  | LoadActivePersonAndContactsForListFailure
  | ClearContacts;
