import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-tax-create-modal',
  templateUrl: './tax-create-modal.component.html',
  styleUrls: ['./tax-create-modal.component.scss']
})
export class TaxCreateModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TaxCreateModalComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}
  public taxFrom: UntypedFormGroup;
  public submittedTaxForm = false;
  disableDefault = false;

  ngOnInit() {
    this.createForm(this.data.list[this.data.idx]);
  }

  get f(): UntypedFormGroup {
    return this.taxFrom;
  }

  private createForm(item) {
    this.taxFrom = this.formBuilder.group({
      name: new UntypedFormControl(item.name, [Validators.required]),
      rate: new UntypedFormControl(item.rate, [Validators.required, Validators.min(0.01), Validators.max(100)]),
      default: new UntypedFormControl(item.default)
    });
    if (this.data.idx === 0) {
      if (this.data.list[1].default) {
        this.f.get('default').disable();
        this.disableDefault = true;
      }
    }
    if (this.data.idx === 1) {
      if (!this.data.list[0].default) {
        this.f.get('default').disable();
        this.disableDefault = true;
      }
    }
  }
  saveTax() {
    this.submittedTaxForm = true;
    if (this.taxFrom.valid) {
      this.closeModal(this.taxFrom.value);
    }
  }
  public closeModal(data?) {
    this.dialogRef.close(data);
  }

  onlyNumber(event) {
    if (+event.key >= 0 && +event.key <= 9) {
      setTimeout(() => {
        if (this.f.get('rate').value > 100) {
          this.f.get('rate').setValue(100);
        }
      }, 100);
      return true;
    }
    return false;
  }
}
