import { Component, OnInit } from '@angular/core';
import { SwitchNotificationsService } from '@app/helpers';
import { INews } from '@app/models/interfaces/news.interface';
import { select, Store } from '@ngrx/store';
import { GetNews, LikeNews } from '@app/modules/layout/news/store/actions/news.actions';
import { selectGetAllNews } from '@app/modules/layout/news/store/selectors/news.selectors';
import { IAppState } from '@app/store/state/app.state';
import { NewsEffects } from './store/effects/news.effect';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FirstLoginModalComponent } from '@app/commonComponents/modals/system-modals/first-login-modal/first-login-modal.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  public news: INews[];
  page = 1;
  constructor(
    private switchNotificationsService: SwitchNotificationsService,
    private store: Store<IAppState>,
    private dialog: MatDialog,
    private newsEffects: NewsEffects) {
    this.newsEffects.getNews$.subscribe(() => this.showLoader = false);
  }

  ngOnInit() {
    this.store.pipe(select(selectGetAllNews)).subscribe(news => {
      this.news = news.member;
    });
  }

  spinnerName = 'newsList';
  showLoader = false;

  openNewsModal(news) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];
    dialogConfig.data = news;
    this.dialog.open(FirstLoginModalComponent, dialogConfig);
  }

  likeNews(news, id) {
    if (!news.isLiked) {
      this.store.dispatch(new LikeNews({ news, id }));
    }
  }

  closeNews() {
    this.switchNotificationsService.notifications.news = false;
  }

  onScrollingToEnd() {
    this.showLoader = true;
    this.page++;
    this.getNews(this.page, 10);
  }

  getNews(page = 2, itemsPerPage = 10, renew = false) {
    this.store.dispatch(new GetNews({ page, itemsPerPage, renew }));
  }
}
