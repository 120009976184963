import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { AuthComponent } from './components/auth.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { PasswordCreateComponent } from './components/password-create/password-create.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '',
                redirectTo: '/login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: LoginComponent,
                data: {
                    showLogin: false,
                    showSignUp: true
                }
            },
            {
                path: 'register',
                component: RegisterComponent,
                data: {
                    showLogin: true,
                    showSignUp: false
                }
            },
            {
                path: 'forgot',
                component: ForgotComponent,
                data: {
                    showLogin: true,
                    showSignUp: false
                }
            },
            {
                path: 'password/:hash',
                component: PasswordCreateComponent,
                data: {
                    showLogin: false,
                    showSignUp: true
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule { }
