import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedEnumService } from '@app/enums/shared-enum.service';
import { IAppState } from '@app/store/state/app.state';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IUser } from '@app/models';
import { UpdateUser } from '@app/auth/_store/actions/auth.actions';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { Roles } from '@app/helpers/check-role';
import { AppSettingsService } from '@app/helpers/app-settings.service';
// import * as timeZones from '../../../../../assets/l10n/time-zones.json';
import { LocaleService } from '@app/helpers/locale.service';

@Component({
  selector: 'app-notification-settings-modal',
  templateUrl: './notification-settings-modal.component.html',
  styleUrls: ['./notification-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsModalComponent implements OnInit, OnDestroy {
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  settingsForm!: UntypedFormGroup;
  notificationSettingsDropdown;
  sharedData = new SharedEnumService(this.translate);
  selectCurrentUser$: Observable<IUser> = this.store.pipe(select(selectCurrentUser));
  currentUser: IUser;
  dropdownsStatus: boolean;
  private unsubscribe = new Subject<void>();
  billsRoles: Roles[] = ['ROLE_SITE_SUBSCRIPTIONS_MANAGER', 'ROLE_SITE_ADMIN', 'ROLE_SITE_ACCOUNTS_MANAGER', 'ROLE_SITE_BILLING_MANAGER'];
  public locale$ = this.appSettingsService.getLang;
  private _jsonURL = 'assets/l10n/time-zones.json';

  timeZoneList; // = timeZones.timeZones;

  constructor(
    public dialogRef: MatDialogRef<NotificationSettingsModalComponent>,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private store: Store<IAppState>,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private localeService: LocaleService
  ) {
    this.notificationSettingsDropdown = this.sharedData.enumNotificationSettings;

    this.selectCurrentUser$.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      const q = Object.values(data.settings[0])
        .filter(x => typeof x === 'string')
        .splice(1);
      this.dropdownsStatus = q.find(x => x === 'all_on');
    });
    this.loadTimeZones();
  }

  ngOnInit() {
    this.store.select(selectCurrentUser).subscribe(currentUser => {
      this.currentUser = currentUser;
    });
    this.initFormGroup();
    this.disableSelectAll();
    this.selectAll();
  }

  initFormGroup() {
    this.settingsForm = this.fb.group({
      allNotification: [this.dropdownsStatus],
      settings: this.fb.group({
        notificationMatter: [this.currentUser.settings[0].notificationMatter],
        notificationTaskEvent: [this.currentUser.settings[0].notificationTaskEvent],
        notificationCorrespondence: [this.currentUser.settings[0].notificationCorrespondence],
        notificationBill: [this.currentUser.settings[0].notificationBill],
        notificationPayment: [this.currentUser.settings[0].notificationPayment],
        notificationBudgetExhausted: [this.currentUser.settings[0].notificationBudgetExhausted],
        notificationReminder: [this.currentUser.settings[0].notificationReminder],
        notificationComments: [this.currentUser.settings[0].notificationComments],
        notificationDocumentSigned: [this.currentUser.settings[0].notificationDocumentSigned],
        notificationJuscourt: [this.currentUser.settings[0].notificationJuscourt],
        sendDailyEmails: [this.currentUser.settings[0].sendDailyEmails],
        notificationTime: [this.currentUser.settings[0].notificationTime],
        timeZone: [this.currentUser.settings[0].timeZone],
        id: [this.currentUser.settings[0].id]
      })
    });
  }

  selectAll() {
    const formGroup = this.settingsForm.get('settings');
    const controls = [
      formGroup.get('notificationMatter'),
      formGroup.get('notificationTaskEvent'),
      formGroup.get('notificationCorrespondence'),
      formGroup.get('notificationBill'),
      formGroup.get('notificationPayment'),
      formGroup.get('notificationBudgetExhausted'),
      formGroup.get('notificationReminder'),
      formGroup.get('notificationComments'),
      formGroup.get('notificationDocumentSigned'),
      formGroup.get('notificationJuscourt')
    ];
    this.settingsForm
      .get('allNotification')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe(() => {
        controls.forEach(item => {
          this.settingsForm.get('allNotification').value
            ? item.setValue('all_on', { emitEvent: false })
            : item.setValue('all_off', { emitEvent: false });
        });
        this.cdRef.detectChanges();
      });
  }

  disableSelectAll() {
    const selectAllControl = this.settingsForm.get('allNotification');
    this.settingsForm
      .get('settings')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe(control => {
        (this.dropdownsStatus as any) = Object.values(control).find(x => x === 'all_on');
        if (this.dropdownsStatus) {
          selectAllControl.setValue(true, { emitEvent: false });
        } else {
          selectAllControl.setValue(false, { emitEvent: false });
        }
        this.cdRef.detectChanges();
      });
  }

  goToApp() {
    this.router.navigate(['/settings/apps']);
    this.closeModal();
  }

  deleteViber() {
    const settings = {
      settings: [{viberAccount: {enabled: false}, id: this.currentUser.settings[0].id}]
    };
    if (this.currentUser.settings[0].viberAccount.enabled) {
      this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
    }
  }

  deleteTelegram() {
    const settings = {
      settings: [{telegramAccount: {enabled: false}, id: this.currentUser.settings[0].id}]
    };
    if (this.currentUser.settings[0].telegramAccount.enabled) {
      this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
    }
  }
  deleteOutlook() {
    const settings = {
      settings: [{outlookAccount: {enabled: false}, id: this.currentUser.settings[0].id}]
    };
    if (this.currentUser.settings[0].outlookAccount.enabled) {
      this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
    }
  }
  deleteMC() {
    const settings = {
      settings: [{ googleAccount: { enabled: false }, id: this.currentUser.settings[0].id }]
    };
    if (this.currentUser.settings[0].googleAccount.enabled) {
      this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    const settings = {
      settings: [this.settingsForm.get('settings').value]
    };
    if (typeof settings.settings[0].notificationTime === 'object') {
      const notificationTime = new Date(settings.settings[0].notificationTime);
      settings.settings[0].notificationTime = `${notificationTime.getHours()}:${notificationTime.getMinutes()}`;
    }
    this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  changeNotificationTime() {
    this.clearSelection();
  }
  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document['selection']) { document['selection'].empty(); }
  }

  changeInput() {
    this.cdRef.detectChanges();
  }

  loadTimeZones() {
    this.localeService.getJSON(this._jsonURL).subscribe(resp => {
      this.timeZoneList = resp.timeZones;
      this.cdRef.detectChanges();
    });
  }
}
