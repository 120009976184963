import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CreateBill,
  CreateBillSuccess,
  EBillActions,
  GetBills,
  GetBillById,
  GetBillsSuccess,
  GetBillByIdSuccess,
  RemoveBill,
  UpdateBill,
  UpdateBillSuccess,
  GetBillsTotalSuccess,
  RemoveBillSuccess,
  RemoveBillFailure
} from '@app/store/actions/bill.actions';
import { catchError, filter, mergeMap, share, switchMap, tap } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { Store} from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { Bill } from '@app/models/bill';

@Injectable()
export class BillEffects {
  constructor(
    private toastrService: ToastrService,
    private actions$: Actions,
    private store: Store<IAppState>,
    private billService: BillService,
    private translate: TranslateService
  ) {}

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType<GetBills>(EBillActions.GET_BILLS),
    switchMap(action => {
      return this.billService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(response => {
          return [new GetBillsSuccess(response)];
        })
      );
    }),
    share()
  ));


  fetchTotal$ = createEffect(() => this.actions$.pipe(
    ofType<GetBills>(EBillActions.GET_BILLS),
    switchMap(action => {
      return this.billService.getTotal(action.payload.filters, action.payload.page, action.payload.itemsPerPage).pipe(
        mergeMap(response => {
          return [new GetBillsTotalSuccess(response)];
        })
      );
    }),
    share()
  ));


  fetchById$ = createEffect(() => this.actions$.pipe(
    ofType<GetBillById>(EBillActions.GET_BILL_BY_ID),
    switchMap(action => {
      return this.billService.getById(action.payload).pipe(
        mergeMap(response => {
          return (response && [new GetBillByIdSuccess(response)]) || [];
        })
      );
    }),
    share()
  ));


  create$ = createEffect(() => this.actions$.pipe(
    ofType<CreateBill>(EBillActions.CREATE_BILL),
    switchMap(action => {
      return this.billService.setBill(action.payload).pipe(
        mergeMap(response => {
          this.toastrService.success(this.translate.instant('invoiceAdded'));
          return (response && [new CreateBillSuccess(response)]) || [];
        })
      );
    }),
    share()
  ));

  createSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<CreateBillSuccess>(EBillActions.CREATE_BILL_SUCCESS),
    tap(x => {
      this.refreshCompanySettings();
    })
  ), { dispatch: false });

  remove$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveBill>(EBillActions.REMOVE_BILL),
    switchMap(action => {
      return this.billService.deleteBill(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('invoiceDeleted'));
          return of(new RemoveBillSuccess(action.payload));
        }),
        catchError(error => of(new RemoveBillFailure(error)))
      );
    }),
    share()
  ));

  update$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBill>(EBillActions.UPDATE_BILL),
    switchMap(action => {
      return this.billService.updateBill(action.id, action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          if (response.status === 'awaiting_payment') {
            this.toastrService.success(this.translate.instant('invoiceApproved'));
          } else {
            this.toastrService.success(this.translate.instant('invoiceEdited'));
          }
          return response && [new UpdateBillSuccess(response)];
        })
      );
    }),
    share()
  ));


  refreshCompanySettings() {
    this.store.dispatch(new LoadCompanySettingAction());
  }
}
