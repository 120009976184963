import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, share, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
  AddExpenseType,
  AddExpenseTypeFailure,
  AddExpenseTypeSuccess,
  DeleteExpenseType,
  DeleteExpenseTypesFailure,
  DeleteExpenseTypesSuccess,
  DeleteExpenseTypeSuccess,
  EExpenseTypesActions,
  GetExpenseTypes,
  GetExpenseTypesFailure,
  GetExpenseTypesSuccess,
  LoadExpenseTypesAction,
  LoadExpenseTypesFailureAction,
  LoadExpenseTypesSuccessAction,
  LoadInternalExpenseTypesAction,
  LoadInternalExpenseTypesFailureAction,
  LoadInternalExpenseTypesSuccessAction,
  UpdateExpenseType,
  UpdateExpenseTypeFailure,
  UpdateExpenseTypeSuccess,
} from '../actions/expense-types.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ExpenseTypesService } from '../../services/expense-types.service';
import { DeleteTimeEntryType } from '@app/modules/settings/store/actions/time-entry-types.actions';

@Injectable()
export class ExpenseTypeEffects {
  loadExpenseTypes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadExpenseTypesAction>(EExpenseTypesActions.LOAD_EXPENSE_TYPES),
    mergeMap(() =>
      this.expenseTypeService.get().pipe(
        map(data => new LoadExpenseTypesSuccessAction({ data: data.member })),
        catchError(error => of(new LoadExpenseTypesFailureAction(error))),
      ),
    ),
  ));

  loadInternalExpenseTypes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadInternalExpenseTypesAction>(EExpenseTypesActions.LOAD_INTERNAL_EXPENSE_TYPES),
    mergeMap(() =>
      this.expenseTypeService.getInternalExpenseType().pipe(
        map(data => new LoadInternalExpenseTypesSuccessAction(data)),
        catchError(error => of(new LoadInternalExpenseTypesFailureAction(error))),
      ),
    ),
  ));


  getExpenseTypes$ = createEffect(() => this.actions$.pipe(
    ofType<GetExpenseTypes>(EExpenseTypesActions.GET_EXPENSE_TYPES),
    switchMap(action => {
      return this.expenseTypeService.getExpenseTypes(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetExpenseTypesSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetExpenseTypesFailure(error));
        }),
      );
    }),
    share(),
  ));


  addExpenseType$ = createEffect(() => this.actions$.pipe(
    ofType<AddExpenseType>(EExpenseTypesActions.ADD_EXPENSE_TYPE),
    switchMap(action => {
      return this.expenseTypeService.addExpenseType(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('itemAdded'));
            return of(new AddExpenseTypeSuccess(x));
          }
          return of(new AddExpenseTypeFailure(new Error()));
        }),
        finalize(() => this.refreshExpenseTypes()),
        catchError(error => of(new AddExpenseTypeFailure(error))),
      );
    }),
    share(),
  ));


  updateExpenseType$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateExpenseType>(EExpenseTypesActions.UPDATE_EXPENSE_TYPE),
    switchMap(action => {
      return this.expenseTypeService.updateExpenseType(action.payload).pipe(
        mergeMap(x => {
          this.toastrService.success(this.translate.instant('itemEdited'));
          return of(new UpdateExpenseTypeSuccess(x));
        }),
        finalize(() => this.refreshExpenseTypes()),
        catchError((error) => {
          // will add error handler later
          return of(new UpdateExpenseTypeFailure(error));
        }),
      );
    }),
    share(),
  ));


  deleteExpenseType$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteExpenseType>(EExpenseTypesActions.DELETE_EXPENSE_TYPE),
    switchMap(action => {
      return this.expenseTypeService.deleteExpenseType(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('itemDeleted'));
          return of(new DeleteExpenseTypeSuccess(action.payload));
        }),
        finalize(() => this.refreshExpenseTypes()),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteExpenseTypeSuccess(error));
        }),
      );
    }),
    share(),
  ));


  deleteExpenseTypes$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTimeEntryType>(EExpenseTypesActions.DELETE_EXPENSE_TYPES),
    switchMap(action => {
      const deleteMethods = action.payload.map(x => this.expenseTypeService.deleteExpenseType(x));
      return forkJoin(deleteMethods).pipe(
        mergeMap(() => {
          this.refreshExpenseTypes();
          this.toastrService.error(this.translate.instant('itemDeleted'));
          return of(new DeleteExpenseTypesSuccess(action.payload));
        }),
        catchError((error) => of(new DeleteExpenseTypesFailure(error))),
      );
    }),
    share(),
  ));

  refreshExpenseTypes() {
    this.store.dispatch(new LoadInternalExpenseTypesAction());
    this.store.dispatch(new LoadExpenseTypesAction());

    this.store.dispatch(new GetExpenseTypes({
      page: 1,
      itemsPerPage: 10001,
      filters: { 'exists[parent]': 'false' },
    }));
  }

  constructor(
    private actions$: Actions,
    private expenseTypeService: ExpenseTypesService,
    private store: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {
  }
}
