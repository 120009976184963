import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CompanySettings } from '@app/models/company-settings';
import { AuthService } from '@app/auth/_services/auth.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Rate } from '@app/models/rate';
import { ResponseBodyFull } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getCompanySetting(): Observable<CompanySettings> {
    if (this.authService.currentUserValue) {
      return this.http.get<CompanySettings>(
        `${environment.apiUrl}/company_settings/${this.authService.currentUserValue.companySettingsId}`
      );
    } else {
      return new Observable<CompanySettings>();
    }
  }

  getCompanyDefaultRates(): Observable<Rate[]> {
    return this.http.get<ResponseBodyFull<Rate>>(`${environment.apiUrl}/company_default_rates`).pipe(
      map(response => {
        return response['hydra:member']
      })
    );
  }
  /*
  приймає два значення
  1 - перший масив, новий, який потрібно щоб залишився на беку
  2 - другий масив, який дозволяє відсортувати які валюти є нові
      а які вже були в цьому масиві
  */
  changeCompanyDefaultRates(rate: Rate[], responseRate: Rate[]) {
    let deleteItems = [];
    this.filtrRate(rate, responseRate);

    deleteItems = this.unique(rate, responseRate);

    for (const r of rate) {
      r.rate += '';
      if (!r.id) {
        this.http.post<Rate>(`${environment.apiUrl}/company_default_rates`, r).pipe(take(1)).subscribe();
      } else {
        this.http.patch<Rate>(`${environment.apiUrl}/company_default_rates/${r.id}`, r).pipe(take(1)).subscribe();
      }
    }
    for (const deleteItem of deleteItems) {
      if (deleteItem) {
        this.http.delete(`${environment.apiUrl}/company_default_rates/${deleteItem}`).pipe(take(1)).subscribe();
      }
    }
  }

  updateCompanySettings(settings: CompanySettings): Observable<CompanySettings> {
    return this.http
      .patch<CompanySettings>(`${environment.apiUrl}/company_settings/${settings.id}`, settings);
  }

  filtrRate(rate: Rate[], responseRate: Rate[]) {
    for (const resRate of responseRate) {
      if (resRate) {
        for (const r of rate) {
          if (r.currency === resRate.currency.code) {
            r.id = resRate.id;
          }
        }
      }
    }
  }
  unique(rate, responseRate) {
    const rateIds = [];
    const responseRateIds = [];
    // беремо всі нові ідішки які є
    for (const r of rate) {
      rateIds.push(r.id);
    }
    // беремо всі старі ідішки які були
    for (const rRate of responseRate) {
      if (rRate) {
        responseRateIds.push(rRate.id);
      }
    }
    // порівнюємо всі старі ідшки з новими, і фільтруємо, якщо старих ідішок немає
    // то ми їх видаляємо
    let result;
    result = responseRateIds.filter(el => -1 === rateIds.indexOf(el));

    return result;
  }

  changeCompanyUser(companyId, userId) {
    return this.http.patch(`${environment.apiUrl}/companies/${companyId}`, { account: userId });
  }
}
