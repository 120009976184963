import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  constructor(private router: Router, private location: Location) {}
  private urlAfterLogin = new BehaviorSubject<string>('/');
  public urlAfterLogin$ = this.urlAfterLogin.asObservable();

  private history: string[] = [];

  setPreviousUrl(previousUrl: string) {
    sessionStorage.setItem('previousUrl', previousUrl);
  }
  getPreviousUrl() {
    return sessionStorage.getItem('previousUrl');
  }

  setUrl(url: string, isFromCard = false) {
    if (this.checkIfSameRoute(url, isFromCard)) {
      this.history[0] = url;
    } else {
      this.history.unshift(url);
    }
  }

  setAfterLoginUrl(url: string) {
    this.urlAfterLogin.next(url);
  }

  navigateBack() {
    if (this.history.length === 0) {
      return;
    }
    if (this.history[0] === this.router.url) {
      this.history.shift();
    }
    this.router.navigateByUrl(this.history.shift());
  }

  clearRoutesHistory() {
    this.history = [];
    this.urlAfterLogin.next('/');
  }

  private checkIfSameRoute(url: string, isFromCard: boolean): boolean {
    if (this.history.length === 0) {
      return false;
    }
    const baseCheckedUrl = this.getBaseUrl(url, isFromCard);
    const lastBaseUrl = this.getBaseUrl(this.history[0], isFromCard);
    return baseCheckedUrl === lastBaseUrl;
  }

  private getBaseUrl(url: string, checkByTwoSegments: boolean): string {
    if (checkByTwoSegments) {
      return url
        .split('/')
        .splice(0, 2)
        .join('/');
    } else {
      const segments = url.split('?');
      if (segments.length > 1) {
        segments.pop();
      }
      return segments.join('/');
    }
  }
}
