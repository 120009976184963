import { Paginator } from '@app/models/paginator';
import { Action } from '@ngrx/store';
import { RelationshipType } from '@app/models';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum ERelationshipTypesActions {
  LOAD_RELATIONSHIP_TYPES = '[RELATIONSHIP_TYPES] Load RelationshipTypes',
  LOAD_RELATIONSHIP_TYPES_SUCCESS = '[RELATIONSHIP_TYPES] Load RelationshipTypes Success',
  LOAD_RELATIONSHIP_TYPES_FAILURE = '[RELATIONSHIP_TYPES] Load RelationshipTypes Failure',
  GET_RELATIONSHIP_TYPES = '[RelationshipTypes] Get Relationship Types',
  GET_RELATIONSHIP_TYPES_SUCCESS = '[RelationshipTypes] Get Relationship Types Success',
  GET_RELATIONSHIP_TYPES_FAILURE = '[RelationshipTypes] Get Relationship Types Failure',
  ADD_RELATIONSHIP_TYPE = '[RelationshipTypes] Add Relationship Type',
  ADD_RELATIONSHIP_TYPE_SUCCESS = '[RelationshipTypes] Add Relationship Type Success',
  ADD_RELATIONSHIP_TYPE_FAILURE = '[RelationshipTypes] Add Relationship Type Failure',
  UPDATE_RELATIONSHIP_TYPE = '[RelationshipTypes] Update Relationship Type',
  UPDATE_RELATIONSHIP_TYPE_SUCCESS = '[RelationshipTypes] Update Relationship Type Success',
  UPDATE_RELATIONSHIP_TYPE_FAILURE = '[RelationshipTypes] Update Relationship Type Failure',
  DELETE_RELATIONSHIP_TYPE = '[RelationshipTypes] Delete Relationship Type',
  DELETE_RELATIONSHIP_TYPE_SUCCESS = '[RelationshipTypes] Delete Relationship Type Success',
  DELETE_RELATIONSHIP_TYPE_FAILURE = '[RelationshipTypes] Delete Relationship Type Failure',
  DELETE_RELATIONSHIP_TYPES = '[RelationshipTypes] Delete Relationship Types',
  DELETE_RELATIONSHIP_TYPES_SUCCESS = '[RelationshipTypes] Delete Relationship Types Success',
  DELETE_RELATIONSHIP_TYPES_FAILURE = '[RelationshipTypes] Delete Relationship Types Failure'
}
export class LoadRelationshipTypesAction implements Action {
  readonly type = ERelationshipTypesActions.LOAD_RELATIONSHIP_TYPES;
}
export class LoadRelationshipTypesSuccessAction implements Action {
  readonly type = ERelationshipTypesActions.LOAD_RELATIONSHIP_TYPES_SUCCESS;

  constructor(public payload: Array<RelationshipType>) {}
}
export class LoadRelationshipTypesFailureAction implements Action {
  readonly type = ERelationshipTypesActions.LOAD_RELATIONSHIP_TYPES_FAILURE;

  constructor(public payload: Error) {}
}
export class GetRelationshipTypes implements Action {
  public readonly type = ERelationshipTypesActions.GET_RELATIONSHIP_TYPES;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetRelationshipTypesSuccess implements Action {
  public readonly type = ERelationshipTypesActions.GET_RELATIONSHIP_TYPES_SUCCESS;

  constructor(public payload: Paginator<RelationshipType>) {}
}

export class GetRelationshipTypesFailure implements Action {
  readonly type = ERelationshipTypesActions.GET_RELATIONSHIP_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddRelationshipType implements Action {
  public readonly type = ERelationshipTypesActions.ADD_RELATIONSHIP_TYPE;

  constructor(public payload: RelationshipType) {}
}

export class AddRelationshipTypeSuccess implements Action {
  public readonly type = ERelationshipTypesActions.ADD_RELATIONSHIP_TYPE_SUCCESS;

  constructor(public payload: RelationshipType) {}
}

export class AddRelationshipTypeFailure implements Action {
  readonly type = ERelationshipTypesActions.ADD_RELATIONSHIP_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateRelationshipType implements Action {
  public readonly type = ERelationshipTypesActions.UPDATE_RELATIONSHIP_TYPE;

  constructor(public payload: RelationshipType) {}
}

export class UpdateRelationshipTypeSuccess implements Action {
  public readonly type = ERelationshipTypesActions.UPDATE_RELATIONSHIP_TYPE_SUCCESS;

  constructor(public payload: RelationshipType) {}
}

export class UpdateRelationshipTypeFailure implements Action {
  readonly type = ERelationshipTypesActions.UPDATE_RELATIONSHIP_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteRelationshipType implements Action {
  public readonly type = ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPE;

  constructor(public payload: number) {}
}

export class DeleteRelationshipTypeSuccess implements Action {
  public readonly type = ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPE_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteRelationshipTypeFailure implements Action {
  readonly type = ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteRelationshipTypes implements Action {
  public readonly type = ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPES;

  constructor(public payload: number[]) {}
}

export class DeleteRelationshipTypesSuccess implements Action {
  public readonly type = ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPES_SUCCESS;

  constructor(public payload: number[]) {}
}

export class DeleteRelationshipTypesFailure implements Action {
  readonly type = ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export type RelationshipTypeActions =
  | LoadRelationshipTypesAction
  | LoadRelationshipTypesSuccessAction
  | LoadRelationshipTypesFailureAction
  | GetRelationshipTypes
  | GetRelationshipTypesSuccess
  | GetRelationshipTypesFailure
  | AddRelationshipType
  | AddRelationshipTypeSuccess
  | AddRelationshipTypeFailure
  | UpdateRelationshipType
  | UpdateRelationshipTypeSuccess
  | UpdateRelationshipTypeFailure
  | DeleteRelationshipType
  | DeleteRelationshipTypeSuccess
  | DeleteRelationshipTypeFailure
  | DeleteRelationshipTypes
  | DeleteRelationshipTypesSuccess
  | DeleteRelationshipTypesFailure;
