import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, map, withLatestFrom, share } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import {
  GetRelationshipTypes,
  ERelationshipTypesActions,
  GetRelationshipTypesSuccess,
  AddRelationshipType,
  AddRelationshipTypeSuccess,
  UpdateRelationshipType,
  UpdateRelationshipTypeSuccess,
  DeleteRelationshipType,
  DeleteRelationshipTypeSuccess,
  DeleteRelationshipTypes,
  DeleteRelationshipTypesSuccess,
  LoadRelationshipTypesAction,
  LoadRelationshipTypesFailureAction,
  LoadRelationshipTypesSuccessAction,
  GetRelationshipTypesFailure,
  AddRelationshipTypeFailure,
  DeleteRelationshipTypeFailure,
  DeleteRelationshipTypesFailure
} from '../actions/relationship-types.actions';
import { RelationshipTypeService } from '@app/modules/settings/services/relationship-type.service';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RelationshipTypeEffects {

  loadRelationshipTypes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadRelationshipTypesAction>(ERelationshipTypesActions.LOAD_RELATIONSHIP_TYPES),
    mergeMap(() =>
      this.relationshipTypeService.get().pipe(
        map(data => new LoadRelationshipTypesSuccessAction(data)),
        catchError(error => of(new LoadRelationshipTypesFailureAction(error)))
      )
    )
  ));


  getRelationshipTypes$ = createEffect(() => this.actions$.pipe(
    ofType<GetRelationshipTypes>(ERelationshipTypesActions.GET_RELATIONSHIP_TYPES),
    switchMap(action => {
      return this.relationshipTypeService
        .getRelationshipTypes(action.payload.page, action.payload.itemsPerPage, action.payload.filters)
        .pipe(
          mergeMap(resp => {
            return [new GetRelationshipTypesSuccess(resp)];
          }),
          catchError((error) => {
            // will add error handler later
            return of(new GetRelationshipTypesFailure(error));
          })
        );
    }),
    share()
  ));


  addRelationshipType$ = createEffect(() => this.actions$.pipe(
    ofType<AddRelationshipType>(ERelationshipTypesActions.ADD_RELATIONSHIP_TYPE),
    switchMap(action => {
      return this.relationshipTypeService.addRelationshipType(action.payload).pipe(
        mergeMap(x => {
          return of(new AddRelationshipTypeSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddRelationshipTypeFailure(error));
        })
      );
    })
  ));


  addRelationshipTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddRelationshipTypeSuccess>(ERelationshipTypesActions.ADD_RELATIONSHIP_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetRelationshipTypes({
          page: storeState.relationshipTypeState.paginator.currentPage,
          itemsPerPage: storeState.relationshipTypeState.paginator.itemsPerPage
        })
      );
    })
  ));


  updateRelationshipType$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateRelationshipType>(ERelationshipTypesActions.UPDATE_RELATIONSHIP_TYPE),
    switchMap(action => {
      return this.relationshipTypeService.updateRelationshipType(action.payload).pipe(
        mergeMap(x => {
          return of(new UpdateRelationshipTypeSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new UpdateRelationshipType(error));
        })
      );
    })
  ));


  updateRelationshipTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateRelationshipTypeSuccess>(ERelationshipTypesActions.UPDATE_RELATIONSHIP_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetRelationshipTypes({
          page: storeState.relationshipTypeState.paginator.currentPage,
          itemsPerPage: storeState.relationshipTypeState.paginator.itemsPerPage
        })
      );
    })
  ));


  deleteRelationshipType$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteRelationshipType>(ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPE),
    switchMap(action => {
      return this.relationshipTypeService.deleteRelationshipType(action.payload).pipe(
        mergeMap(() => {
          return of(new DeleteRelationshipTypeSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteRelationshipTypeFailure(error));
        })
      );
    })
  ));


  deleteRelationshipTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteRelationshipTypeSuccess>(ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemDeleted'));
      return of(
        new GetRelationshipTypes({
          page: storeState.relationshipTypeState.paginator.currentPage,
          itemsPerPage: storeState.relationshipTypeState.paginator.itemsPerPage
        })
      );
    })
  ));


  deleteRelationshipTypes$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteRelationshipTypes>(ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPES),
    switchMap(action => {
      const deleteMethods = [];
      action.payload.forEach(x => {
        deleteMethods.push(this.relationshipTypeService.deleteRelationshipType(x));
      });
      return forkJoin(deleteMethods).pipe(
        mergeMap(() => {
          return of(new DeleteRelationshipTypesSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteRelationshipTypesFailure(error));
        })
      );
    })
  ));


  deleteRelationshipTypesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteRelationshipTypesSuccess>(ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPES_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemsDeleted'));
      return of(
        new GetRelationshipTypes({
          page: storeState.relationshipTypeState.paginator.currentPage,
          itemsPerPage: storeState.relationshipTypeState.paginator.itemsPerPage
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private relationshipTypeService: RelationshipTypeService,
  ) {}
}
