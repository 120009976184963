import { EPaymentActions, PaymentActions } from '@app/store/actions/payment.actions';
import { paymentState } from '@app/store/state/payment.state';
import { PaymentState } from '@app/models/interfaces/payment-state';
import { Payment } from '@app/models/payment';

export const paymentReducers = (state = paymentState, action: PaymentActions): PaymentState => {
  switch (action.type) {
    case EPaymentActions.CREATE_PAYMENT_SUCCESS: {
      state.payments[action.payload.id] = action.payload;

      return { ...state };
    }
    case EPaymentActions.SELECT_PAYMENT_FROM_STORE: {
      return {
        ...state,
        selected: action.payload
      };
    }
    case EPaymentActions.GET_PAYMENTS: {
      const payments = {};

      return {
        ...state,
        payments,
        selected: null,
        paginator: null
      };
    }
    case EPaymentActions.UPDATE_PAYMENT_SUCCESS: {
      const { payload } = action;

      if (state.payments.hasOwnProperty(payload.id.toString())) {
        state.payments[payload.id.toString()] = action.payload;
      }

      return {
        ...state
      };
    }
    case EPaymentActions.FETCHED_TRANSACTION_SUCCESS: {
      const { member, countItems, totalItems, currentPage, itemsPerPage, lastPage } = action.payload;
      return {
        ...state,
        transactions: member,
        transactionPaginator: {
          member,
          countItems,
          totalItems,
          currentPage,
          itemsPerPage,
          lastPage
        }
      };
    }
    case EPaymentActions.FETCHED_TRANSACTION_SUMMARY_SUCCESS: {
      return {
        ...state,
        transactionSummary: {...action.payload}
      };
    }
    case EPaymentActions.REMOVE_PAYMENT: {
      const { payments } = state;

      delete payments[action.payload];

      return {
        ...state,
        payments
      };
    }
    case EPaymentActions.GET_PAYMENTS_SUCCESS: {
      const { member, countItems, totalItems, currentPage, itemsPerPage, lastPage } = action.payload;
      const payments = {};

      if (Array.isArray(member)) {
        member.map((x: Payment) => {
          payments[x.id] = x;
        });
      }

      return {
        ...state,
        payments,
        paginator: {
          member,
          countItems,
          totalItems,
          currentPage,
          itemsPerPage,
          lastPage
        }
      };
    }

    default:
      return state;
  }
};
