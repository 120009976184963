import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { MatterCardService } from '@app/modules/matter-card/services/matter-card.service';
import { MatterFinance } from '@app/models/matter-extended';
import {
  LoadMatterFinanceAction,
  MatterCardActionsTypes,
  LoadMatterFinanceSuccessAction,
  LoadMatterFinanceFailureAction
} from '../actions/matter-card.actions';

@Injectable()
export class MatterCardEffects {
  
  getMatterFinance$ = createEffect(() => this.actions$.pipe(
    ofType<LoadMatterFinanceAction>(MatterCardActionsTypes.LOAD_MATTER_FINANCE),
    switchMap(action => {
      return this.matterCardService.getFinance(action.payload).pipe(
        mergeMap(resp => {
          return [new LoadMatterFinanceSuccessAction(resp as MatterFinance)];
        }),
        catchError(error => of(new LoadMatterFinanceFailureAction(error)))
      );
    })
  ));

  constructor(private actions$: Actions, private matterCardService: MatterCardService) {}
}
