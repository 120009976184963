import { Action } from '@ngrx/store';
import { Paginator } from '@app/models/paginator';
import { ActivityTotal } from '@app/models/interfaces/activity-total';
import { ActivityExtended, ExpenseSave, TimeEntrySave } from '@app/models';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { ActivitiesFilters } from '@app/models/activities-filters';
import { DocumentSave } from '@app/models/document-save';
import { DocRelation } from '@app/models/doc-relation';
import { ReminderExtended } from '@app/models/reminder-extended';

export enum EActivitiesActions {
  GET_ACTIVITIES = '[Activities] Get Activities',
  GET_ACTIVITIES_SUCCESS = '[Activities] Get Activities Success',
  GET_ACTIVITIES_FAILURE = '[Activities] Get Activities Failure',

  CLEAR_ACTIVITIES = '[Activities] Clear Activities',

  GET_ACTIVITIES_TOTAL = '[Activities] Get Activities Total',
  GET_ACTIVITIES_TOTAL_SUCCESS = '[Activities] Get Activities Total Success',
  GET_ACTIVITIES_TOTAL_FAILURE = '[Activities] Get Activities Total Failure',

  GET_ACTIVITY = '[Activities] Get Activity',
  GET_ACTIVITY_SUCCESS = '[Activities] Get Activity Success',
  GET_ACTIVITY_FAILURE = '[Activities] Get Activity Failure',

  ADD_ACTIVITY = '[Activities] Add Activity',
  ADD_ACTIVITY_SUCCESS = '[Activities] Add Activity Success',
  CHANGE_ADD_ACTIVITIES_STATUS = '[Activities] Change Add Status',
  ADD_ACTIVITY_FAILURE = '[Activities] Get Activity Failure',

  UPDATE_ACTIVITY = '[Activities] Update Activity',
  UPDATE_ACTIVITY_SUCCESS = '[Activities] Update Activity Success',
  UPDATE_ACTIVITY_FAILURE = '[Activities] Get Activity Failure',

  DELETE_ACTIVITY = '[Activities] Delete Activity',
  DELETE_ACTIVITY_SUCCESS = '[Activities] Delete Activity Success',
  DELETE_ACTIVITY_FAILURE = '[Activities] Get Activity Failure'
}

export class GetActivities implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITIES;

  constructor(public payload: { fetchPayload: FetchPayload<ActivitiesFilters>; typeActivity: string }) {
  }
}

export class GetActivitiesSuccess implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITIES_SUCCESS;

  constructor(public payload: Paginator<ActivityExtended>) {
  }
}

export class GetActivitiesFailure implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITIES_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ClearActivities implements Action {
  public readonly type = EActivitiesActions.CLEAR_ACTIVITIES;

  constructor() {
  }
}

export class GetActivitiesTotal implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITIES_TOTAL;

  constructor(public payload: { fetchPayload: FetchPayload<ActivitiesFilters>; typeActivityTotal: string }) {
  }
}

export class GetActivitiesTotalSuccess implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITIES_TOTAL_SUCCESS;

  constructor(public payload: ActivityTotal) {
  }
}

export class GetActivitiesTotalFailure implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITIES_TOTAL_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetActivity implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITY;

  constructor(public payload: { id: number; typeActivity: string }) {
  }
}

export class GetActivitySuccess implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITY_SUCCESS;

  constructor(public payload: ActivityExtended) {
  }
}

export class GetActivityFailure implements Action {
  public readonly type = EActivitiesActions.GET_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddActivity implements Action {
  public readonly type = EActivitiesActions.ADD_ACTIVITY;

  constructor(
    public payload: {
      activity: ActivityExtended | ExpenseSave | TimeEntrySave;
      typeActivity: string;
      documents?: {
        document: DocumentSave;
        mediaFile: File;
        reminders?: ReminderExtended;
        relations?: DocRelation[];
      }[];
    }
  ) {
  }
}

export class AddActivitySuccess implements Action {
  public readonly type = EActivitiesActions.ADD_ACTIVITY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class AddActivityFailure implements Action {
  readonly type = EActivitiesActions.ADD_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateActivity implements Action {
  public readonly type = EActivitiesActions.UPDATE_ACTIVITY;

  constructor(public payload: { activity: any; typeActivity: string }) {
  }
}

export class UpdateActivitySuccess implements Action {
  public readonly type = EActivitiesActions.UPDATE_ACTIVITY_SUCCESS;

  constructor(public payload: ActivityExtended) {
  }
}

export class UpdateActivityFailure implements Action {
  public readonly type = EActivitiesActions.UPDATE_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteActivity implements Action {
  public readonly type = EActivitiesActions.DELETE_ACTIVITY;

  constructor(public payload: { id: number; typeActivity: string }) {
  }
}

export class DeleteActivitySuccess implements Action {
  public readonly type = EActivitiesActions.DELETE_ACTIVITY_SUCCESS;

  constructor(public payload: { id: number; typeActivity: string }) {
  }
}

export class DeleteActivityFailure implements Action {
  public readonly type = EActivitiesActions.DELETE_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export type ActivitiesActions =
  | GetActivities
  | GetActivitiesSuccess
  | GetActivitiesFailure
  | ClearActivities
  | GetActivitiesTotal
  | GetActivitiesTotalSuccess
  | GetActivitiesTotalFailure
  | GetActivity
  | GetActivitySuccess
  | GetActivityFailure
  | AddActivity
  | AddActivitySuccess
  | AddActivityFailure
  | UpdateActivity
  | UpdateActivitySuccess
  | UpdateActivityFailure
  | DeleteActivity
  | DeleteActivitySuccess
  | DeleteActivityFailure;
