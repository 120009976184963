import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expire-modal',
  templateUrl: './expire-modal.component.html',
  styleUrls: ['./expire-modal.component.scss']
})
export class ExpireModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
