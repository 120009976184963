import { BankAccountExtended } from '@app/models/bank-account-extended';
import { Paginator } from '@app/models/paginator';
import { BankAccountsTotal } from '@app/models/interfaces/bankAccounts-total';

export interface IBankAccountState {
  paginator: Paginator<BankAccountExtended>;
  bankAccountsByContact: BankAccountExtended[];
  bankAccountsByCurrency: BankAccountExtended[];
  selectedBankAccount: BankAccountExtended;
  bankAccountTotal: BankAccountsTotal;
}

export const initialBankAccountState: IBankAccountState = {
  paginator: null,
  bankAccountsByContact: [],
  bankAccountsByCurrency: [],
  selectedBankAccount: null,
  bankAccountTotal: null
};
