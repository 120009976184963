import { IAppState } from '@app/store/state/app.state';

export const selectPaymentList = (state: IAppState) => Object.values(state.paymentState.payments);

export const selectTransactions = (state: IAppState) => Object.values(state.paymentState.transactions);

export const selectPayments = (state: IAppState) => state.paymentState.payments;

export const selectSelectedId = (state: IAppState) => state.paymentState.selected;

export const selectPaymentPaginator = (state: IAppState) => state.paymentState.paginator;

export const selectTransactionPaginator = (state: IAppState) => state.paymentState.transactionPaginator;
export const selectTransactionSummary = (state: IAppState) => state.paymentState.transactionSummary;

export const selectSelectedPayment = (state: IAppState) => state.paymentState.payments[state.paymentState.selected];
