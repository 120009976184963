import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';
import { AccountDeleting } from '@app/models/account-deleting';

@Injectable()
export class AccountDeletingService {
  constructor(private http: HttpClient) {}

  private BASE_URL = `${environment.apiUrl}/reg_account_delete_type`;
  private BASER_URL2 = `${environment.apiUrl}/account_deleteds`;

  getInfo(): Observable<any> {
    return this.http.get(this.BASE_URL).pipe(map(resp => resp['hydra:member']));
  }

  postInfo(data: AccountDeleting): Observable<AccountDeleting> {
    return this.http.post<AccountDeleting>(`${this.BASER_URL2}`, data);
  }
}
