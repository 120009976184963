import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';
import { Automation } from '@app/models/automation';
import { Note } from '@app/models/note';
import { selectSelectedNote } from '@app/store/selectors/note.selector';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-create-automation-modal',
  templateUrl: './create-automation-modal.component.html',
  styleUrls: ['./create-automation-modal.component.scss']
})
export class CreateAutomationModalComponent implements OnInit {

  readonly dateFormat = CustomDateAdapter.dateFormat;

  automationForm = new FormGroup({
    title: new FormControl('', Validators.required)
  })
  submittedForm = false;
  constructor(
    private dialogRef: MatDialogRef<CreateAutomationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      automation?: Automation;
    },
    ) {}

  ngOnInit() {
    if (this.data?.automation) {
      this.automationForm.patchValue({
        title: this.data.automation.title
      })
    }
  }


  submit() {
    console.log('save');
    this.submittedForm = true;
    if (this.automationForm.valid) {
      this.dialogRef.close(this.automationForm.value);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
