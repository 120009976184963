import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RelationsModalComponent } from '@app/commonComponents/modals/default-modals/relations-modal/relations-modal.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-relation-graph-item',
  templateUrl: './relation-graph-item.component.html',
  styleUrls: ['./relation-graph-item.component.scss']
})
export class RelationGraphItemComponent implements OnInit {
  @Input() relation;
  @Input() contact;
  @Output() afterCreateRelation = new EventEmitter();
  @Input() selectedItem;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private contactService: ContactService,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}
  editRelation(item) {
    this.openRelationModal(item);
  }
  refreshRelations() {
    this.afterCreateRelation.emit();
  }
  addNewRelation() {
    this.openRelationModal();
  }
  deleteRelation(item) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteTxt'
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.contactService.deleteContactRelationship(item.id).subscribe(res => {
          this.refreshRelations();
          this.toastrService.error(this.translate.instant('itemDeleted'));
        });
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
  openRelationModal(relation?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'relations-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      fromContact: true,
      contact: this.selectedItem ? this.selectedItem : this.contact,
      relationPerson: { id: this.relation.id, fullName: this.relation.name },
      relation
    };
    const dialogRef = this.dialog.open(RelationsModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.id) {
        this.contactService.createContactRelationship(result).subscribe(res => {
          this.toastrService.success(this.translate.instant('itemAdded'));
          this.refreshRelations();
        });
      }
      if (result && result.id) {
        this.contactService.updateContactRelationship(result).subscribe(res => {
          this.toastrService.success(this.translate.instant('itemEdited'));
          this.refreshRelations();
        });
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  goToContact() {
    this.contactService.getById(this.relation.id).subscribe(res => {
      if (res.id) {
        this.router.navigateByUrl(`contact/${this.relation.id}`);
      }
    });
  }
}
