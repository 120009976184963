import { MatterFinance } from '@app/models/matter-extended';

export interface MatterCardState {
  finance: MatterFinance;
  loading: boolean;
  error: Error;
}

export const initialMatterCardState: MatterCardState = {
  finance: null,
  loading: false,
  error: null
};
