import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FillPipe } from './fill.pipe';
import { DateTimePipe } from './date-time.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';

@NgModule({
  declarations: [FillPipe, DateTimePipe, LocalizedDatePipe],
  exports: [FillPipe, DateTimePipe, LocalizedDatePipe],
  imports: [CommonModule],
  providers: []
})
export class PipesModule {}
