import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { INotification } from '@app/models/interfaces/notifications.interface';
import { ResponseBodyFull } from '@app/models';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { GetCountUnreadNotifications } from '../store/actions/notification.actions';
import { CourtSession } from '@app/models/court-session';
import { Helpers } from '@app/helpers/helpers';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {}

  getAllNotifications(page = 1, itemsPerPage = 10, filters): Observable<Paginator<INotification>> {
    let params = new HttpParams().set('partial', 'false');
    if (page && itemsPerPage) {
      params = params.set('page', page.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (filters) {
      params = Helpers.populateFilters(params, filters, true);
    }
    return this.http
      .get<ResponseBodyFull<INotification>>(`${environment.apiUrl}/notifications`, {
        params
      })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
  }

  isReadNotification(id, payload): Observable<INotification> {
    return this.http.patch<INotification>(`${environment.apiUrl}/notifications/${id}`, payload).pipe(
      map(d => {
        this.store.dispatch(new GetCountUnreadNotifications());
        return d;
      })
    );
  }

  readNotification(id, payload): Observable<INotification> {
    return this.http.patch<INotification>(`${environment.apiUrl}/notifications/${id}`, payload);
  }

  getCountUnread(): Observable<number> {
    return this.http
      .get<ResponseBodyFull<INotification>>(`${environment.apiUrl}/notifications?partial=false&page=1&itemsPerPage=1&isRead=0`)
      .pipe(map(d => d.pagination.totalItems));
  }

  getCourtSessionData(id) {
    return this.http.get<CourtSession>(`${environment.apiUrl}/court_sessions/${id}`);
  }

  checkVisibleTroubleJuscourt(where, subscribedSubscription, subscriptionJuscourt) {
    const plaginSubscribedSubscription = subscribedSubscription && subscribedSubscription.length ? false : true;
    let plaginSubscriptionJuscourt = false;
    let plaginTroubleClick = false;

    if (subscriptionJuscourt) {
      const date1 = new Date();
      const date2 = new Date(subscriptionJuscourt.expiredAt);
      // кількість між датою початку і датою завершення (100%)
      const allDays = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
      if (allDays < 3 && subscriptionJuscourt.tariff.type !== 'free') {
        plaginSubscriptionJuscourt = true;
      }
    }
    switch (where) {
      case 'app':
        plaginTroubleClick = localStorage.getItem('troubleClickAppSetting')
          ? JSON.parse(localStorage.getItem('troubleClickSidebarSetting'))
          : true;
        break;
      case 'sidebar':
        plaginTroubleClick = localStorage.getItem('troubleClickSidebarSetting')
          ? JSON.parse(localStorage.getItem('troubleClickSidebarSetting'))
          : true;
        break;
      case 'apps':
        plaginTroubleClick = true;
        break;
      default:
        break;
    }

    return plaginSubscribedSubscription && plaginSubscriptionJuscourt && plaginTroubleClick;
  }
}
