import { IAppState } from '@app/store/state/app.state';
import { Contact } from '@app/models';

export const selectContactsPaginator = (state: IAppState) => state.contactState.paginator;
export const selectContactList = (state: IAppState): Contact[] => Object.values(state.contactState.contacts);
export const selectDirector = (state: IAppState): Contact => state.contactState.selectedDirector;
export const selectPaginationData = (state: IAppState) => state.contactState.paginator;
export const selectContactFromStore = (state: IAppState): Contact => state.contactState.contacts[state.contactState.selected] || null;
export const selectCurrentContact = (state: IAppState): Contact => state.contactState.currentContact;
export const selectPersonFromStore = (state: IAppState): Contact => state.contactState.persons[state.contactState.selectedPerson] || null;

export const selectContactListForSelect = (state: IAppState) => state.contactState.contactListForSelect;
export const selectContactListForSelectDirector = (state: IAppState) => state.contactState.contactListForSelectDirector;

export const selectPersonContactListForSelect = (state: IAppState) => state.contactState.persons;

export const selectAllContactListForSelect = (state: IAppState) => state.contactState.allContactListForSelect;

export const selectAllOnlyClientsListForSelect = (state: IAppState) => state.contactState.allOnlyClientsForSelect;

export const selectActivePersonAndContactsForSelect = (state: IAppState) => state.contactState.activePersonsAndContact;
