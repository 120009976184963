// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-container p {
  white-space: pre-wrap;
}

::ng-deep .cropp-link {
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/notes/components/note/note.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;;AAKE;EACE,qBAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,sBAAA;AAFJ","sourcesContent":[".text-container {\n  p {\n    white-space: pre-wrap;\n  }\n}\n\n::ng-deep {\n  .cropp-link {\n    display: inline-block;\n    max-width: 90%;\n    white-space: nowrap;\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n    vertical-align: bottom;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
