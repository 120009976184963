import { Action } from '@ngrx/store';
import { Contract } from '@app/models/contract';

export enum ContractActionsTypes {
  LOAD_CONTRACT = '[CONTRACT] Load Contract',
  LOAD_CONTRACT_SUCCESS = '[CONTRACT] Load Contract Success',
  LOAD_CONTRACT_FAILURE = '[CONTRACT] Load Contract Failure',
  GET_CONTRACT = '[CONTRACT] Get Contract',
  GET_CONTRACT_SUCCESS = '[CONTRACT] Get Contract Success',
  GET_CONTRACT_FAILURE = '[CONTRACT] Get Contract Failure',
  ADD_CONTRACT = '[CONTRACT] Add Contract',
  ADD_CONTRACT_SUCCESS = '[CONTRACT] Add Contract Success',
  ADD_CONTRACT_FAILURE = '[CONTRACT] Add Contract Failure',
  EDIT_CONTRACT = '[CONTRACT] Edit Contract',
  EDIT_CONTRACT_SUCCESS = '[CONTRACT] Edit Contract Success',
  EDIT_CONTRACT_FAILURE = '[CONTRACT] Edit Contract Failure',
  DELETE_CONTRACT = '[CONTRACT] Delete Contract',
  DELETE_CONTRACT_SUCCESS = '[CONTRACT] Delete Contract Success',
  DELETE_CONTRACT_FAILURE = '[CONTRACT] Delete Contract Failure',
  ADD_TO_STORE_CURRENT_CONTRACT = '[CONTRACT] Add contract to store'
}
export class AddToStoreCurrentContractAction implements Action {
  readonly type = ContractActionsTypes.ADD_TO_STORE_CURRENT_CONTRACT;

  constructor(public payload: Contract) {}
}
export class LoadContractAction implements Action {
  readonly type = ContractActionsTypes.LOAD_CONTRACT;
}
export class LoadContractSuccessAction implements Action {
  readonly type = ContractActionsTypes.LOAD_CONTRACT_SUCCESS;

  constructor(public payload: Array<Contract>) {}
}
export class LoadContractFailureAction implements Action {
  readonly type = ContractActionsTypes.LOAD_CONTRACT_FAILURE;

  constructor(public payload: Error) {}
}

export class GetContractAction implements Action {
  readonly type = ContractActionsTypes.GET_CONTRACT;

  constructor(public payload: number) {}
}
export class GetContractSuccessAction implements Action {
  readonly type = ContractActionsTypes.GET_CONTRACT_SUCCESS;

  constructor(public payload: Contract) {}
}
export class GetContractFailureAction implements Action {
  readonly type = ContractActionsTypes.GET_CONTRACT_FAILURE;

  constructor(public payload: Error) {}
}

export class AddContractAction implements Action {
  readonly type = ContractActionsTypes.ADD_CONTRACT;

  constructor(public payload: Contract) {}
}
export class AddContractSuccessAction implements Action {
  readonly type = ContractActionsTypes.ADD_CONTRACT_SUCCESS;

  constructor(public payload: Contract) {}
}
export class AddContractFailureAction implements Action {
  readonly type = ContractActionsTypes.ADD_CONTRACT_FAILURE;

  constructor(public payload: Error) {}
}

export class EditContractAction implements Action {
  readonly type = ContractActionsTypes.EDIT_CONTRACT;

  constructor(public payload: Contract) {}
}
export class EditContractSuccessAction implements Action {
  readonly type = ContractActionsTypes.EDIT_CONTRACT_SUCCESS;

  constructor(public payload: Contract) {}
}
export class EditContractFailureAction implements Action {
  readonly type = ContractActionsTypes.EDIT_CONTRACT_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteContractAction implements Action {
  readonly type = ContractActionsTypes.DELETE_CONTRACT;

  constructor(public payload: string) {}
}
export class DeleteContractSuccessAction implements Action {
  readonly type = ContractActionsTypes.DELETE_CONTRACT_SUCCESS;

  constructor(public payload: string) {}
}
export class DeleteContractFailureAction implements Action {
  readonly type = ContractActionsTypes.DELETE_CONTRACT_FAILURE;

  constructor(public payload: Error) {}
}

export type ContractActions =
  | AddContractAction
  | AddContractSuccessAction
  | AddContractFailureAction
  | DeleteContractAction
  | DeleteContractSuccessAction
  | DeleteContractFailureAction
  | LoadContractAction
  | LoadContractSuccessAction
  | LoadContractFailureAction
  | AddToStoreCurrentContractAction
  | GetContractAction
  | GetContractFailureAction
  | GetContractSuccessAction;
