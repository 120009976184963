import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, share, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TemplateService } from '../../services/template.service';
import {
  GetTemplates,
  ETemplatesActions,
  GetTemplatesSuccess,
  GetTemplatesFailure,
  GetTemplate,
  GetTemplateSuccess,
  GetTemplateFailure,
  AddTemplate,
  AddTemplateSuccess,
  AddTemplateFailure,
  UpdateTemplate,
  UpdateTemplateSuccess,
  UpdateTemplateFailure,
  DeleteTemplate,
  DeleteTemplateSuccess,
  DeleteTemplateFailure
} from '../actions/template.actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Template } from '@app/models/template.model';
import { Router } from '@angular/router';

@Injectable()
export class TemplateEffects {
  
  getTemplates$ = createEffect(() => this.actions$.pipe(
    ofType<GetTemplates>(ETemplatesActions.GET_TEMPLATES),
    switchMap(action => {
      return this.templatesService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetTemplatesSuccess(resp)];
        }),
        catchError(error => of(new GetTemplatesFailure(error)))
      );
    }),
    share()
  ));

  
  getTemplate$ = createEffect(() => this.actions$.pipe(
    ofType<GetTemplate>(ETemplatesActions.GET_TEMPLATE),
    switchMap(action => {
      if (action.payload == null) {
        return [new GetTemplateSuccess(null)];
      }
      return this.templatesService.getTemplate(action.payload).pipe(
        mergeMap(resp => {
          return [new GetTemplateSuccess(resp)];
        }),
        catchError(error => of(new GetTemplateFailure(error)))
      );
    })
  ));

  
  addTemplate$ = createEffect(() => this.actions$.pipe(
    ofType<AddTemplate>(ETemplatesActions.ADD_TEMPLATE),
    switchMap(action => {
      return this.templatesService.addTemplate(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('templateAdded'));
            return of(new AddTemplateSuccess(x));
          }
          return of(new AddTemplateFailure(new Error()));
        }),
        catchError(error => of(new AddTemplateFailure(error)))
      );
    }),
    share()
  ));

  
  addTemplateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddTemplateSuccess>(ETemplatesActions.ADD_TEMPLATE_SUCCESS),
    tap(x => {
      this.router.navigateByUrl(`/settings/templates`);
    })
  ), { dispatch: false });

  
  updateTemplate$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTemplate>(ETemplatesActions.UPDATE_TEMPLATE),
    switchMap(action => {
      return this.templatesService.updateTemplate(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('templateEdited'));
            return of(new UpdateTemplateSuccess(x));
          }
          return of(new UpdateTemplateFailure({ success: false, error: new Error() }));
        }),
        catchError(error => of(new UpdateTemplateFailure({ success: false, error })))
      );
    }),
    share()
  ));

  
  updateTemplateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTemplateSuccess>(ETemplatesActions.UPDATE_TEMPLATE_SUCCESS),
    tap(() => {
      // return of(new GetTemplate(null));
    })
  ), { dispatch: false });

  
  deleteTemplate$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTemplate>(ETemplatesActions.DELETE_TEMPLATE),
    switchMap(action => {
      return this.templatesService.deleteTemplate(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('templateDeleted'));
          return of(new DeleteTemplateSuccess(action.payload));
        }),
        catchError(error => of(new DeleteTemplateFailure(error)))
      );
    }),
    share()
  ));

  
  deleteTemplateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTemplateSuccess>(ETemplatesActions.DELETE_TEMPLATE_SUCCESS),
    switchMap(() => {
      return of(new GetTemplateSuccess(null));
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private templatesService: TemplateService
  ) {}
}
