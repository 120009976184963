import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
export interface ICountryByIp {
  countryCode: string,
  callingCode: number,
  expired?: Date
}
@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private localeSubject: BehaviorSubject<ICountryByIp> = new BehaviorSubject<ICountryByIp>(null);
  public localeSubject$ = this.localeSubject.asObservable();

  constructor(private http: HttpClient) {}

  getUserIp() {
    return this.http.jsonp('https://api.ipify.org?format=jsonp', 'callback');
  }

  getLocaleByIp(ip): Observable<ICountryByIp>{
    if(!localStorage.getItem('userData') || (JSON.parse(localStorage.getItem('userData'))?.countryCode3)) {
      return this.http.get<ICountryByIp>(`${environment.apiUrl}/country-by-ip/${ip}`).pipe(map(resp => {
        let D = new Date();
        D.setMonth(D.getMonth() + 1);
        resp['expired'] = D;
        localStorage.setItem('userData', JSON.stringify(resp));
        return resp;
      }))
    } else {
      const countryData = localStorage.getItem('userData') && (JSON.parse(localStorage.getItem('userData')) as ICountryByIp);
      if(new Date(countryData.expired) < new Date()) {
        localStorage.removeItem('userData');
        return this.getLocaleByIp(ip);
      }
      return new BehaviorSubject(countryData) as Observable<ICountryByIp>;
    }

  }


  passLocale(locale) {
    this.localeSubject.next(locale);
  }
  public getJSON(jsonUrl) {
    return this.http.get(jsonUrl).pipe(
     map((response:any) => response))
  }
}

export enum LocaleMap {
  en = 'en-US',
  ua = 'uk-UA',
  uk = 'uk-UA',
  pl = 'pl_PL',
  fr = 'fr-FR',
  es = 'es-ES',
  de = 'de-DE',
  cs = 'cs-CZ',
}

interface IIp {
  ip: string;
}
