// generated by the plop

import { ChatStateModel } from '@app/chat/states/chat/chat.model';
import { ChatMessageWS, MessageModel, MessagesFilter } from '@app/chat/symbols/symbols';
import { DocumentExtended, TaskExtended } from '@app/models';

export class ExpandChat {
  static type = '[chat] SetExpanded';
}

// added by plop
export class GetMessages {
  static type = '[chat] GetMessages';

  constructor(public filter?: Partial<MessagesFilter>, public isLazyLoading = false) {
  }
}


export class GetMessagesSuccess {
  static type = '[chat] GetMessagesSuccess';

  constructor(public messages: ChatStateModel['chatMessages'], public isLazyLoading = false) {
  }
}


export class GetMessagesFail {
  static type = '[chat] GetMessagesFail';

  constructor(public err: any) {
  }
}

// added by plop
export class CreateMessage {
  static type = '[chat] CreateMessage';

  constructor(public message: Partial<MessageModel>,  public documents: DocumentExtended[], public tasks: TaskExtended[]) {
  }
}


export class CreateMessageSuccess {
  static type = '[chat] CreateMessageSuccess';

  constructor(public message: MessageModel, public uuid?: string) {
  }
}


export class CreateMessageFail {
  static type = '[chat] CreateMessageFail';

  constructor(public error: any) {
  }
}

export class ChatWSMessage {
  static type = '[chat] ChatWSMessage';

  constructor(public payload: ChatMessageWS) {
  }
}

export class SetCurrentChat {
  static type = '[chat] SetCurrentChat';

  constructor(public chat: ChatStateModel['currentChat']) {
  }
}

// added by plop
export class ReadMessages {
  static type = '[chat] ReadMessages';

  constructor(public messageIds: number[]) {
  }
}


export class ReadMessagesSuccess {
  static type = '[chat] ReadMessagesSuccess';

  constructor(public messageIds: number[]) {
  }
}


export class ReadMessagesFail {
  static type = '[chat] ReadMessagesFail';

  constructor(public error: any) {
  }
}

export class ClearMessages {
  static type = '[chat] ClearMessages';
}

// added by plop
export class PinMessage {
  static type = '[chat] PinMessage';

  constructor(public messageId: number, public pin = true) {
  }
}


export class PinMessageSuccess {
  static type = '[chat] PinMessageSuccess';

  constructor(public messages: MessageModel[], public messageId: number | string, public pin: boolean) {
  }
}


export class PinMessageFail {
  static type = '[chat] PinMessageFail';

  constructor(public error: any, public messageId: number | string) {
  }
}

// added by plop
export class RemoveMessage {
  static type = '[chat] RemoveMessage';

  constructor(public messageId: number) {
  }
}


export class RemoveMessageSuccess {
  static type = '[chat] RemoveMessageSuccess';

  constructor(public messageId: number) {
  }
}


export class RemoveMessageFail {
  static type = '[chat] RemoveMessageFail';

  constructor(public error: any, public messageId: number) {
  }
}

// added by plop
export class UpdateMessage {
  static type = '[chat] UpdateMessage';

  constructor(public message: Partial<MessageModel>, public documents: DocumentExtended[], public tasks: TaskExtended[]) {
  }
}


export class UpdateMessageSuccess {
  static type = '[chat] UpdateMessageSuccess';

  constructor() {
  }
}


export class UpdateMessageFail {
  static type = '[chat] UpdateMessageFail';

  constructor(public error: any) {
  }
}

// added by plop
export class LoadCountPinMessages {
  static type = '[chat] LoadCountPinMessages';

  constructor(public userId: number, public entityId: number, public entityName: string) {
  }
}


export class LoadCountPinMessagesSuccess {
  static type = '[chat] LoadCountPinMessagesSuccess';

  constructor(public count: number) {
  }
}


export class LoadCountPinMessagesFail {
  static type = '[chat] LoadCountPinMessagesFail';

  constructor(public error: any) {
  }
}

// added by plop
export class LoadPinnedMessages {
  static type = '[chat] LoadPinnedMessages';

  constructor(public filter?: Partial<MessagesFilter>) {
  }
}


export class LoadPinnedMessagesSuccess {
static type = '[chat] LoadPinnedMessagesSuccess';
  constructor(public messages: ChatStateModel['chatMessages']) {
  }
}


export class LoadPinnedMessagesFail {
static type = '[chat] LoadPinnedMessagesFail';
  constructor(public error: any) {
  }
}

export class RemoveOverloadMessages {
  static type = '[chat] Remove overloading messages';
}
