// generated by the plop

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BANK_ACCOUNT_STATE_DEFAULT, BankAccountStateModel } from './bank-account.model';
import {
  GetBankAccounts,
  GetBankAccountsByCurrency,
  GetBankAccountsByCurrencyFail,
  GetBankAccountsByCurrencySuccess,
  GetBankAccountsFail,
  GetBankAccountsSuccess
} from '@app/modules/bank-accounts/states/bank-account/bank-account.actions';
import { PROGRESS_STATUSES } from '@app/shared/symbols';
import { BankAccountService } from '@app/modules/bank-accounts/services/bank-account.service';

@State({ name: 'BankAccount', defaults: BANK_ACCOUNT_STATE_DEFAULT })
@Injectable()
export class BankAccountState {


  @Selector()
  static getBankAccounts(state: BankAccountStateModel): BankAccountStateModel['paginator'] {
    return state.paginator;
  }

  @Selector()
  static getLoadBankAccountsStatus(state: BankAccountStateModel): BankAccountStateModel['loadBankAccountsStatus'] {
    return state.loadBankAccountsStatus;
  }

  @Selector()
  static getBankAccountsByCurrency(state: BankAccountStateModel): BankAccountStateModel['bankAccountsByCurrency'] {
    return state.bankAccountsByCurrency;
  }

  @Selector()
  static getLoadBankAccountsByCurrencyStatus(state: BankAccountStateModel): BankAccountStateModel['loadBankAccountsByCurrencyStatus'] {
    return state.loadBankAccountsByCurrencyStatus;
  }

  constructor(private bankAccountService: BankAccountService) {
  }


  @Action(GetBankAccounts)
  getBankAccounts(ctx: StateContext<BankAccountStateModel>, { payload }: GetBankAccounts): void {
    ctx.patchState({
      loadBankAccountsStatus: PROGRESS_STATUSES.IN_PROGRESS
    });

    this.bankAccountService.getBankAccounts(payload.page, payload.itemsPerPage, payload.filters).subscribe({
      next: (resp) => ctx.dispatch(new GetBankAccountsSuccess(resp)),
      error: err => ctx.dispatch(new GetBankAccountsFail(err))
    });
  }

  @Action(GetBankAccountsSuccess)
  getBankAccountsSuccess(ctx: StateContext<BankAccountStateModel>, { payload }: GetBankAccountsSuccess): void {
    ctx.patchState({
      paginator: payload,
      loadBankAccountsStatus: PROGRESS_STATUSES.SUCCEED
    });
  }

  @Action(GetBankAccountsFail)
  getBankAccountsFail(ctx: StateContext<BankAccountStateModel>, { error }: GetBankAccountsFail): void {
    ctx.patchState({
      loadBankAccountsStatus: PROGRESS_STATUSES.INTERRUPTED
    });
  }

  @Action(GetBankAccountsByCurrency)
  getBankAccountsByCurrency(ctx: StateContext<BankAccountStateModel>, { currency }: GetBankAccountsByCurrency): void {
    ctx.patchState({
      loadBankAccountsByCurrencyStatus: PROGRESS_STATUSES.IN_PROGRESS
    });

    this.bankAccountService.getBankAccountsByCurrency(currency).subscribe({
      next: (resp) => ctx.dispatch(new GetBankAccountsByCurrencySuccess(resp)),
      error: err => ctx.dispatch(new GetBankAccountsByCurrencyFail(err))
    });
  }

  @Action(GetBankAccountsByCurrencySuccess)
  getBankAccountsByCurrencySuccess(ctx: StateContext<BankAccountStateModel>, { payload }: GetBankAccountsByCurrencySuccess): void {
    ctx.patchState({
      bankAccountsByCurrency: payload,
      loadBankAccountsByCurrencyStatus: PROGRESS_STATUSES.SUCCEED
    });
  }

  @Action(GetBankAccountsByCurrencyFail)
  getBankAccountsByCurrencyFail(ctx: StateContext<BankAccountStateModel>, { error }: GetBankAccountsByCurrencyFail): void {
    ctx.patchState({
      loadBankAccountsByCurrencyStatus: PROGRESS_STATUSES.INTERRUPTED
    });
  }

}


