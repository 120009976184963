import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseBodyFull, ExpenseType } from '@app/models';
import { Helpers } from '@app/helpers/helpers';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/_services/auth.service';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class ExpenseTypesService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL = `${environment.apiUrl}/expense_types`;

  get(): Observable<Paginator<ExpenseType>> {
    const queryString = `${environment.apiUrl}/expense_types?partial=false&page=1&itemsPerPage=10000&exists[parent]=false&type[]=common&type[]=external`;
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<ExpenseType>>(queryString)
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<ExpenseType>>();
    }
  }
  getInternalExpenseType() {
    const queryString = `${environment.apiUrl}/expense_types?partial=false&page=1&itemsPerPage=10000&type[]=common&type[]=internal&exists[parent]=false`;
    return this.http.get<ResponseBodyFull<ExpenseType>>(queryString)
    .pipe(map(response => (response['hydra:member'])));
  }
  getExpenseTypes(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters?): Observable<Paginator<ExpenseType>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters, true);
    }
    return this.http
      .get<ResponseBodyFull<ExpenseType>>(this.BASE_URL, { params })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
  }

  addExpenseType(expenseType: ExpenseType) {
    const queryString = `${environment.apiUrl}/expense_types`;
    return this.http.post<ExpenseType>(queryString, expenseType);
  }

  updateExpenseType(expenseType: ExpenseType) {
    const queryString = `${environment.apiUrl}/expense_types/${expenseType.id}`;
    return this.http.patch<ExpenseType>(queryString, expenseType);
  }

  deleteExpenseType(id: number) {
    const queryString = `${environment.apiUrl}/expense_types/${id}`;
    return this.http.delete(queryString);
  }
}
