// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container.checkbox-container label {
  display: inline-flex;
}
.input-container.checkbox-container label .j2-icon-info {
  margin-left: 5px;
}

.modal-template .modal-body {
  padding: 0;
}
.modal-template .btn-template:disabled {
  transition: none;
}
.modal-template .btn-template:disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.checkbox-item__invalid {
  border-color: rgba(224, 59, 75, 0) !important;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, #FABCC7 0 0 0 2px, rgba(0, 0, 0, 0) 0 0 0 0, rgba(64, 68, 82, 0) 0 0 0 1px, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/settings-library-modal/library-checkbox-create-modal/library-checkbox-create-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAIE;EACE,UAAA;AADJ;AAGE;EACE,gBAAA;AADJ;AAEI;EACE,qCAAA;AAAN;;AAKA;EACE,6CAAA;EACA,yMAAA;AAFF","sourcesContent":[".input-container.checkbox-container label {\n  display: inline-flex;\n\n  .j2-icon-info {\n    margin-left: 5px;\n  }\n}\n\n.modal-template {\n  .modal-body {\n    padding: 0;\n  }\n  .btn-template:disabled {\n    transition: none;\n    &:hover {\n      background-color: rgba(0, 0, 0, 0.12);\n    }\n  }\n}\n\n.checkbox-item__invalid {\n  border-color: rgba(224, 59, 75, 0) !important;\n  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, #FABCC7 0 0 0 2px, rgba(0, 0, 0, 0) 0 0 0 0, rgba(64, 68, 82, 0) 0 0 0 1px, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
