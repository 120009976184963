import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { IUser, IUserSettings } from '@app/models';
import { AuthService } from '@app/auth/_services/auth.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { catchError, map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private LOCAL_STORAGE_KEY = 'userSettings';
  private currentUser$ = new BehaviorSubject<IUser>(null);
  private settings$ = new BehaviorSubject<any>({ lang: 'en' });

  constructor(private authService: AuthService, private store: Store<IAppState>) {
    this.store.select(selectCurrentUser).subscribe(user => {
      if (user && 'settings' in user) {
        this.currentUser$.next(user);
      }
    });
    this.loadFromStorage();
    this.setSync();
  }

  setSync() {
    this.currentUser$.pipe(skipWhile(user => !user)).subscribe(user => {
      const { settings } = user;
      if (typeof settings[0] !== 'undefined') {
        const userSettings = settings[0];
        this.storeSettings(userSettings);
      }
    });
  }

  get getLang() {
    return this.settings$.pipe(
      map(settings => (settings ? settings.lang : 'en')),
      catchError(() => of('en'))
    );
  }

  get getSidebar() {
    return this.settings$.pipe(
      map(settings => {
        return settings ? settings.showSidebar : false;
      })
    );
  }

  getKey(key: string) {
    const rawSettings = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    const settings = this.parse(rawSettings) || {};
    if (key in settings) {
      return settings[key];
    }
    return null;
  }

  storeSettings(settings: IUserSettings) {
    const { showSidebar, lang } = settings;
    const filteredSettings = { showSidebar, lang };
    this.settings$.next(filteredSettings);
    const jsonSettings = this.prepareSettings(filteredSettings);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, jsonSettings);
  }

  private prepareSettings(settings: IUserSettings) {
    return this.serialize(settings);
  }

  private serialize(obj) {
    return JSON.stringify(obj);
  }

  private parse(settings: string) {
    return JSON.parse(settings);
  }

  removeStorage() {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
  }

  private loadFromStorage() {
    this.settings$.next(this.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY)));
  }
}
