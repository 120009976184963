import { Injectable } from '@angular/core';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';
import { TimeFormatter } from '@app/helpers/time-formatter';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(
    private translate: TranslateService,
    private timeFormatter: TimeFormatter
  ) {}

  buildVisibleDataForRow(columns, restList, entity, bankAccount) {
    for (const visColumn of columns) {
      visColumn.value = [];
      visColumn.exportValue = [];
      for (let j = 0; restList && j < restList.length; j++) {
        let value;
        let exportValue;
        if (visColumn.key === 'type') {
          exportValue = restList[j][visColumn.key];
          value = `<span class="j-icon-${restList[j][visColumn.key]}"></span>`;
            if (restList[j][visColumn.key] === 'person') {
              value = `<span class="j2-icon-table-contact"></span>`;
            }
            if (restList[j][visColumn.key] === 'company') {
              value = `<span class="j2-icon-resp-user"></span>`;
            }
            if (restList[j][visColumn.key] === 'time_entry') {
              value = `<span class="j2-icon-n-activities"></span>`;
            } else if (restList[j][visColumn.key] === 'expense') {
              value = `<span class="j2-icon-expense"></span>`;
            }
          if (this.visibleTotalByEntity(['document', 'activity-card-document', 'Document'], entity)) {
            exportValue = Helpers.getCurrentTypeForExportDoc(restList[j].media?.url ? restList[j].media.url : '');
            value = `<span class="${this.getCurrentIconForDocument(restList[j])}"></span>`;
            if (restList[j]?.eSignature?.status === 'signed') {
              value += `<span class="docusign" data-text="${this.translate.instant('apps.docusignLabelList')}"></span>`;
            }
            if (restList[j].cloudLink) {
              exportValue = 'cloud';
              value = `<span class="table-icon-cloud"></span>`;
            }
          }
          if (this.visibleTotalByEntity(['templates'], entity)) {
            exportValue = Helpers.getCurrentTypeForExportDoc(restList[j].mimeType);
            value = `<span class="${Helpers.getCurrentImgForDocumentUrl(restList[j].mimeType)}"></span>`;
            if (restList[j]?.eSignature?.status === 'signed') {
              value += `<span class="docusign" data-text="${this.translate.instant('apps.docusignLabelList')}"></span>`;
            }
          }
          if (restList[j][visColumn.key] === 'event') {
            value = `<span class="table-icon-event"></span>`;
          }
          if (restList[j][visColumn.key] === 'task') {
            value = `<span class="table-icon-task"></span>`;
          }
          if (restList[j][visColumn.key] === 'in') {
            value = `<span class="table-icon-corr-in"></span>`;
          }
          if (restList[j][visColumn.key] === 'out') {
            value = `<span class="table-icon-corr-out"></span>`;
          }
          if (this.visibleTotalByEntity(['communication'], entity)) {
            if (restList[j]['lastComment']?.createdBy.type === 'user_portal') {
              exportValue = 'in';
              value = `<div class="icon"> <span class="inbox_icon"></span>  `;
            }
            if (restList[j]['lastComment']?.createdBy.type === 'user') {
              exportValue = 'out';
              value = `<div class="icon">
              <span class="outbox_icon"></span>
              `;
            }
            value += `${
              restList[j]['lastComment']?.userPortalRead &&
              restList[j]['lastComment']?.createdBy?.type === 'user' &&
              restList[j]['lastComment']?.published
                ? '<span class="is-readed-icon"></span> '
                : ''
            }`;
            value += ' </div>';
          }
          visColumn.exportValue.push(exportValue);
        }
        if (visColumn.key === 'status') {
          const dataValue = this.translate.instant(restList[j][visColumn.key]);
          value = `<span class="${this.checkStatus(restList[j])}">${dataValue}</span>`;
        }
        if (visColumn.key === 'priority') {
          value = `
          <span class="j2-icon-${restList[j][visColumn.key]}-priority"></span>
          <span> ${this.translate.instant('taskAndEvents.priority.' + restList[j][visColumn.key])} </span>
          `;
        }

        if (visColumn.key === 'task-name' || visColumn.key === 'doc-name') {
          value = {
            name: restList[j].name,
            id: restList[j].id,
            type: visColumn.type
          };
        }
        if (visColumn.key === 'matter-name') {
          value = {
            name: restList[j].name,
            isPinned: restList[j].isPinned,
            id: restList[j].id,
            type: visColumn.type
          };
        }

        if (visColumn.key === 'id') {
          value = {
            name: restList[j].id,
            id: restList[j].id,
            type: visColumn.type
          };
        }

        if (visColumn.key === 'tags') {
          const entityName = visColumn?.entity;
          if (restList[j][entityName] && restList[j][entityName]?.length > 0) {
            value = {
              name: restList[j][entityName]?.length > 0 ? restList[j][entityName] : null,
              type: visColumn.type
            };
          }
        }

        if (visColumn.key === 'inner-matter-name') {
          value = {
            name: restList[j].matter.name,
            id: restList[j].matter.id,
            type: visColumn.type
          };
        }
        // contact Name
        if (visColumn.key === 'fullName') {
          value = {
            name: restList[j].fullName,
            id: restList[j].id,
            type: visColumn.type
          };
        }
        if (visColumn.key === 'contact' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        if (visColumn.key === 'responsibleSolicitor' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].person.fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        if (visColumn.key === 'contactResponsiblePerson' && visColumn.visible) {
          value = restList[j].contact?.responsiblePerson
            ? {
                name: restList[j].contact.responsiblePerson.person.fullName,
                id: restList[j].contact.responsiblePerson.id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        if (visColumn.key === 'taskUsers' && visColumn.visible) {
          value = restList[j][visColumn.key];
        }

        if (visColumn.key === 'responsiblePersons' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key][0]?.person.fullName,
                id: restList[j][visColumn.key][0]?.id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        if (visColumn.key === 'correspondence' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].outgoingNumber,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        if (visColumn.key === 'outgoingNumber' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key],
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        //  matter   //originatingSolicitor
        if (visColumn.key === 'originatingSolicitor' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].person.fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        if (visColumn.key === 'matter-date' && visColumn.visible) {
          value = restList[j].startDate
            ? `<div class='date-from'>${CustomDateAdapter.convertToDate(restList[j].startDate)}</div>`
            : '';
          value += restList[j].closedDate
            ? `<div class='date-to'>${CustomDateAdapter.convertToDate(restList[j].closedDate)}</div>`
            : '';
        }
        if (visColumn.key === 'task-date' && visColumn.visible) {
          if (restList[j].type === 'task') {
            exportValue = `
            ${CustomDateAdapter.convertToDate(restList[j].startDate)} /
            ${CustomDateAdapter.convertToDate(restList[j].endDate)}
            `;
            value = restList[j].startDate
              ? `<div class='date-from'>${CustomDateAdapter.convertToDate(restList[j].startDate)}</div>`
              : '—';
            value += restList[j].endDate
              ? `<div class='date-to'>${CustomDateAdapter.convertToDate(restList[j].endDate)}</div>`
              : '';
          } else {
            exportValue = `
            ${CustomDateAdapter.convertToUniversalString(restList[j].startDate)} /
            ${CustomDateAdapter.convertToUniversalString(restList[j].endDate)}
            `;
            value = restList[j].startDate
              ? `<div class='date-from'>${CustomDateAdapter.convertToUniversalString(restList[j].startDate)}</div>`
              : '—';
            value += restList[j].endDate
              ? `<div class='date-to'>${CustomDateAdapter.convertToUniversalString(restList[j].endDate)}</div>`
              : '';
          }
          visColumn.exportValue.push(exportValue);
        }
        if (visColumn.key === 'practiceBranch' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key].name : '—';
        }
        // matter
        if (visColumn.key === 'balance' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? restList[j][visColumn.key] + (restList[j].currencyMatterAmount ? restList[j].currencyMatterAmount.code : '')
            : '—';
        }
        // account-balance
        if (visColumn.key === 'account-balance' && visColumn.visible) {
          value = restList[j].balance ? this.getBalanceVal(restList[j].balance) : '—';
        }
        // payment account-balance
        if (visColumn.key === 'payment-account-balance' && visColumn.visible) {
          value = restList[j].amount ? this.getBalanceVal(restList[j].amount) : '—';
        }
        // matter/document
        if (visColumn.key === 'contract' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: this.translate.instant('contract.titleShort', {
                  contractNumber: restList[j][visColumn.key].number,
                  contractFrom: CustomDateAdapter.convertToDate(new Date(restList[j][visColumn.key].date))
                }),
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // companyWorkGroup contact/matter
        if (visColumn.key === 'companyWorkGroup' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? restList[j][visColumn.key]['matterId']
              ? this.translate.instant('individualGroup')
              : restList[j][visColumn.key].name
            : this.translate.instant('table.all');
        }
        // matter currencyBill
        if (visColumn.key === 'currencyBill' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key].code : '—';
        }
        // matter budgetAmount
        if (visColumn.key === 'budgetAmount' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // matter matterAmount
        if (visColumn.key === 'matterAmount' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? restList[j][visColumn.key] +
              ' ' +
              (restList[j].currencyMatterAmount ? restList[j].currencyMatterAmount.code : '')
            : '—';
        }
        // matter matterStatus
        if (visColumn.key === 'matterStatus' && visColumn.visible) {
          value = restList[j].status ? this.translate.instant(restList[j].status) : '—';
        }
        // amount bill
        if (visColumn.key === 'amount' && visColumn.visible) {
          value = restList[j][visColumn.key];
          if (this.visibleTotalByEntity(['bill'], entity)) {
            value = {
              name: restList[j][visColumn.key],
              id: restList[j].id,
              type: visColumn.type
            };
          }
        }
        // unpaid bill
        if (visColumn.key === 'unpaid' && visColumn.visible) {
          value = restList[j].debit;
        }
        if (visColumn.key === 'amount-in' && visColumn.visible) {
          value = '';
          if (restList[j].type === 'in') {
            value = restList[j].amount;
          }
        }
        if (visColumn.key === 'amount-out' && visColumn.visible) {
          value = '';
          if (restList[j].type === 'out') {
            value = restList[j].amount;
          }
        }
        // balanceAfter
        if (visColumn.key === 'balanceAfter' && visColumn.visible) {
          value = restList[j][visColumn.key];
        }
        // inn contact
        if (visColumn.key === 'inn' && visColumn.visible) {
          value = restList[j][visColumn.key];
        }
        // comment contact
        if (visColumn.key === 'comment' && visColumn.visible) {
          value = restList[j][visColumn.key];
        }
        // emails contact
        if (visColumn.key === 'emails' && visColumn.visible) {
          value = restList[j][visColumn.key].length ? restList[j][visColumn.key][0].email : '—';
        }
        // phones contact
        if (visColumn.key === 'phones' && visColumn.visible) {
          value = restList[j][visColumn.key].length ? restList[j][visColumn.key][0].phone : '—';
        }
        // websites contact
        if (visColumn.key === 'websites' && visColumn.visible) {
          value = restList[j][visColumn.key].length ? restList[j][visColumn.key][0].address : '—';
        }
        // birthDate contact
        if (visColumn.key === 'birthDate' && visColumn.visible) {
          value =
            restList[j].contactPerson && restList[j].contactPerson.birthDate
              ? CustomDateAdapter.convertToDate(restList[j].contactPerson.birthDate)
              : '';
        }
        // payAt
        if (visColumn.key === 'payAt' && visColumn.visible) {
          value = restList[j].payAt
              ? CustomDateAdapter.convertToDate(restList[j].payAt)
              : '';
        }

        // responsiblePerson contact
        if (visColumn.key === 'responsiblePerson-contact' && visColumn.visible) {
          value = restList[j].responsiblePerson
            ? {
                name: restList[j].responsiblePerson.fullName,
                id: restList[j].responsiblePerson.id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        // date activity
        if (visColumn.key === 'date' && visColumn.visible) {
          value = restList[j][visColumn.key] ? CustomDateAdapter.convertToDate(restList[j][visColumn.key]) : { name: '—' };
        }
        // documentDate correspondence
        if (visColumn.key === 'documentDate' && visColumn.visible) {
          value = restList[j][visColumn.key] ? CustomDateAdapter.convertToDate(restList[j][visColumn.key]) : { name: '—' };
        }
        // estimate-endDate tasks
        if (visColumn.key === 'estimate-endDate' && visColumn.visible) {
          if (restList[j].type === 'task') {
            value = restList[j].estimate ? this.timeFormatter.convertSecondsToTableString(restList[j].estimate) : '—';
          } else {
            value = restList[j].endDate ?
              this.timeFormatter.convertSecondsToTableString(
                CustomDateAdapter.getDiffInHours(restList[j].startDate, restList[j].endDate)
              ) : '—';
          }
        }
        // description tasks/matter
        if (visColumn.key === 'description' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // description activity
        if (visColumn.key === 'activity-description' && visColumn.visible) {
          value = {
            name: restList[j].description ? restList[j].description : '—',
            id: restList[j].id,
            type: visColumn.type
          };
        }
        // matter
        if (visColumn.key === 'matter' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].name,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // duration activity
        if (visColumn.key === 'duration' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: this.timeFormatter.convertSecondsToTableString(restList[j].duration),
                id: null,
                type: null
              }
            : { name: '—' };
          // value = restList[j].duration ? this.timeFormatter.convertSecondsToTableString(restList[j].duration) : '—';
        }
        // quantity activity
        if (visColumn.key === 'quantity' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j].quantity,
                id: null,
                type: null
              }
            : { name: '—' };
        }

        // quantityTime format activity
        if (visColumn.key === 'quantityTime' && visColumn.visible) {
          const seconts = this.timeFormatter.convertDoublePatternStringToSeconds(restList[j].quantity);
          // reports.hours
          // settings.time.min
          value = restList[j].quantity
            ? {
                name: this.timeFormatter.convertSecondsToTableStringWithNameTime(seconts),
                id: null,
                type: null
              }
            : { name: '—' };
        }

        // currency activity/bill
        if (visColumn.key === 'currency' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key].code : '';

          // bill
          if (this.visibleTotalByEntity(['bill'], entity)) {
            value = restList[j].currency
              ? {
                  name: restList[j].currency.code,
                  id: restList[j].id,
                  type: visColumn.type
                }
              : { name: '—' };
          }
        }
        // rate activity
        if (visColumn.key === 'rate' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // location tasks
        if (visColumn.key === 'tasks' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // amount-activity
        if (visColumn.key === 'amount-activity' && visColumn.visible) {
          value =
            restList[j].type === 'time_entry'
              ? (restList[j].quantity * +restList[j].rate).toFixed(2)
              : restList[j].amount.toFixed(2);
        }
        // expenseType-timeEntryType
        if (visColumn.key === 'expenseType-timeEntryType' && visColumn.visible) {
          value = restList[j].timeEntryType ? restList[j].timeEntryType.name : '—';
        }

        // expenseType-expenseCategory
        if (visColumn.key === 'expenseType-expenseCategory' && visColumn.visible) {
          value = restList[j].expenseType ? restList[j].expenseType.name : '—';
        }

        // expenseInternal.expenseType
        if (visColumn.key === 'expenseInternal.expenseType' && visColumn.visible) {
          value = restList[j]?.expenseInternal?.expenseType ? restList[j]?.expenseInternal?.expenseType.name : '—';
        }
        // expense-status
        if (visColumn.key === 'expense-status' && visColumn.visible) {
          value = restList[j].status ? this.translate.instant(`expense.${restList[j].status}`) : '—';
        }
        // matter-contact
        if (visColumn.key === 'matter-contact' && visColumn.visible) {
          value =
            restList[j].matter && restList[j].matter.contact
              ? {
                  name: restList[j].matter.contact.fullName,
                  id: restList[j].matter.contact.id,
                  type: visColumn.type
                }
              : { name: '—' };
        }
        // responsiblePerson
        if (visColumn.key === 'responsiblePerson' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].person.fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // billItem activity
        if (visColumn.key === 'billItem' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].bill
                  ? restList[j][visColumn.key].bill.number
                  : this.translate.instant('activities.filter.status.un_billed'),
                id: restList[j][visColumn.key].bill.id,
                type: visColumn.type,
                status: restList[j].bill && 'nonBill'
              }
            : {
                name: this.translate.instant('activities.filter.status.un_billed'),
                status: 'nonBill'
              };
        }
        // expense-status
        if (visColumn.key === 'nonBillable' && visColumn.visible) {
          value = restList[j].nonBillable
            ? this.translate.instant('time.nonBillable')
            : this.translate.instant('time.billableTime');
        }
        // document-contact
        if (visColumn.key === 'document-contact' && visColumn.visible) {
          value = this.getContact(restList[j])
            ? {
                name: this.getContact(restList[j]).fullName,
                id: this.getContact(restList[j]).id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // document-matter
        if (visColumn.key === 'document-matter' && visColumn.visible) {
          value = this.getMatter(restList[j])
            ? {
                name: this.getMatter(restList[j]).name,
                id: this.getMatter(restList[j]).id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // category document
        if (visColumn.key === 'category' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key].name : '—';
        }
        // activity
        if (visColumn.key === 'activity' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].description,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // activity
        if (visColumn.key === 'activity' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].description,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // task
        if (visColumn.key === 'task' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].name,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // task - task in document
        if (visColumn.key === 'task-task' && visColumn.visible) {
          value =
            restList[j].task && restList[j].task.type === 'task'
              ? {
                  name: restList[j].task.name,
                  id: restList[j].task.id,
                  type: visColumn.type
                }
              : { name: '—' };
        }
        // task - event in document
        if (visColumn.key === 'task-event' && visColumn.visible) {
          value =
            restList[j].task && restList[j].task.type === 'event'
              ? {
                  name: restList[j].task.name,
                  id: restList[j].task.id,
                  type: visColumn.type
                }
              : { name: '—' };
        }
        // bill
        if (visColumn.key === 'bill' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].number,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        if (visColumn.key === 'automation' && visColumn.visible) {
          value = restList[j]
            ? {
              name: restList[j].title,
              id: restList[j].id,
              type: visColumn.type
            }
            : { name: '—' };
        }
        if (visColumn.key === 'automation-status' && visColumn.visible) {
          value = restList[j];
        }


        // mandatory or Order

        if (visColumn.key?.includes('mandatoryOrder') && visColumn.visible) {
          let mandatoryOrderEntity = restList[j]?.orderItem || restList[j]?.mandatory;


          if (mandatoryOrderEntity && visColumn.key === 'mandatoryOrderDate') {
            value = CustomDateAdapter.convertToDate(mandatoryOrderEntity.date);
          }
          if (mandatoryOrderEntity && visColumn.key === 'mandatoryOrderNumber') {
            value = mandatoryOrderEntity.number;
          }
          if (mandatoryOrderEntity && visColumn.key === 'mandatoryOrderDateEnd') {
            value = CustomDateAdapter.convertToDate(mandatoryOrderEntity.dateEnd);
          }
          if (mandatoryOrderEntity && visColumn.key === 'mandatoryOrderContract') {
            value = mandatoryOrderEntity?.contract
            ? {
                name: this.translate.instant('contract.titleShort', {
                  contractNumber: mandatoryOrderEntity?.contract?.number,
                  contractFrom: CustomDateAdapter.convertToDate(new Date(mandatoryOrderEntity?.contract?.date))
                }),
                id: mandatoryOrderEntity?.contract?.id,
                type: visColumn.type
              }
            : { name: '—' };
          }
          if (mandatoryOrderEntity && visColumn.key === 'mandatoryOrderUser') {
            let user = mandatoryOrderEntity?.responsibilities?.length ? mandatoryOrderEntity.responsibilities[0] : mandatoryOrderEntity?.responsible ;

            value = user
            ? {
                name: user.person.fullName,
                id: user.id,
                type: visColumn.type
              }
            : { name: '—' };
          }

          if (mandatoryOrderEntity && visColumn.key === 'mandatoryOrderOrganization') {
            value = mandatoryOrderEntity.organization || '—';
          }

          if (mandatoryOrderEntity && visColumn.key === 'mandatoryOrderContact') {
            value = mandatoryOrderEntity?.contact
            ? {
                name: mandatoryOrderEntity?.contact.fullName,
                id: mandatoryOrderEntity?.contact.id,
                type: visColumn.type
              }
            : { name: '—' };
          }
        }

        if (visColumn.key === 'mandatoryResponsibleContact' && visColumn.visible) {
          value = restList[j]?.mandatory?.responsibleContact
            ? {
                name: restList[j]?.mandatory?.responsibleContact.fullName,
                id: restList[j]?.mandatory?.responsibleContact.id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        if (visColumn.key === 'orderResponsibleContact' && visColumn.visible) {
          value = restList[j]?.orderItem?.responsibleContact
            ? {
                name: restList[j]?.orderItem?.responsibleContact.fullName,
                id: restList[j]?.orderItem?.responsibleContact.id,
                type: visColumn.type
              }
            : { name: '—' };
        }



        // createdBy
        if (visColumn.key === 'createdBy' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key]?.person?.fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // updatedBy
        if (visColumn.key === 'updatedBy' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
              name: restList[j][visColumn.key].person.fullName,
              id: restList[j][visColumn.key].id,
              type: visColumn.type
            }
            : { name: '—' };
        }
         // person
         if (visColumn.key === 'person' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // number bill
        if (visColumn.key === 'bill-number' && visColumn.visible) {
          value = restList[j].number
            ? {
                name: restList[j].number,
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        // accountName
        if (visColumn.key === 'accountName' && visColumn.visible) {
          value = restList[j].accountName
            ? {
                name: restList[j].accountName,
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // accountName Payment
        if (visColumn.key === 'payment-account-name' && visColumn.visible) {

          value = restList[j]?.companyBankAccount?.accountName
          ? {
              name: restList[j]?.companyBankAccount?.accountName,
              id: restList[j]?.companyBankAccount?.id,
              type: visColumn.type
            }
          : { name: '—' };
        }
        // dateInvoice
        if (visColumn.key === 'dateInvoice' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: CustomDateAdapter.convertToDate(restList[j][visColumn.key]),
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        // doc media-createdAt
        if (visColumn.key === 'media-createdAt' && visColumn.visible) {
          value = restList[j].media
            ? {
                name: CustomDateAdapter.convertToDate(new Date(restList[j].media.createdAt)),
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // dateExpire
        if (visColumn.key === 'dateExpire' && visColumn.visible) {
          value = restList[j][visColumn.key] ? CustomDateAdapter.convertToDate(restList[j][visColumn.key]) : '—';
        }
        // bill-balance
        if (visColumn.key === 'bill-balance' && visColumn.visible) {
          value = restList[j].balance ? restList[j].balance : '—';
        }
        // typeFee
        if (visColumn.key === 'typeFee' && visColumn.visible) {
          value = restList[j][visColumn.key] ? this.translate.instant(restList[j][visColumn.key]) : '—';
        }
        // typePay
        if (visColumn.key === 'typePay' && visColumn.visible) {
          value = restList[j][visColumn.key] ? this.translate.instant(restList[j][visColumn.key]) : '—';
        }
        // bill-description
        if (visColumn.key === 'bill-description' && visColumn.visible) {
          value = restList[j].description.length ? restList[j].description : restList[j].descriptionPrePay;
        }
        // client
        if (visColumn.key === 'client' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // clientPayer
        if (visColumn.key === 'clientPayer' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }

        // executor
        if (visColumn.key === 'executor' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // bill-matter
        if (visColumn.key === 'bill-matter' && visColumn.visible) {
          value = restList[j].matter
            ? {
                name: restList[j].matter.name,
                id: restList[j].matter.id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // amountBillItemExpense
        if (visColumn.key === 'amountBillItemExpense' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // sender
        if (visColumn.key === 'sender' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // incomingNumber
        if (visColumn.key === 'incomingNumber' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // recipient
        if (visColumn.key === 'recipient' && visColumn.visible) {
          value = restList[j][visColumn.key] ? restList[j][visColumn.key] : '—';
        }
        // transactions-bill
        if (visColumn.key === 'transactions-bill' && visColumn.visible) {
          value =
            (restList[j].payment && restList[j].payment?.bill) || (restList[j].expense && restList[j].expense?.billItem)
              ? {
                  name: restList[j].payment ? restList[j].payment.bill.number : restList[j].expense.billItem.bill.number,
                  id: restList[j].payment ? restList[j].payment.bill.id : restList[j].expense.billItem.bill.id,
                  type: visColumn.type
                }
              : { name: '—' };
        }
        if (visColumn.key === 'transfer' && visColumn.visible) {
          const id =
            bankAccount.id === restList[j].transfer?.companyBankAccountTo?.id
              ? restList[j].transfer?.companyBankAccountFrom?.id
              : restList[j].transfer?.companyBankAccountTo?.id;
          value = {
            ...this.getTransferName(j, restList, bankAccount),
            id,
            type: visColumn.type
          };
        }
        // payer
        if (visColumn.key === 'payer' && visColumn.visible) {
          value = restList[j][visColumn.key]
            ? {
                name: restList[j][visColumn.key].fullName,
                id: restList[j][visColumn.key].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // vendor
        if (visColumn.key === 'vendor' && visColumn.visible) {
          value =
            restList[j]['expense'] && restList[j]['expense'][visColumn.key]
              ? {
                  name: restList[j]['expense'][visColumn.key].fullName,
                  id: restList[j]['expense'][visColumn.key].id,
                  type: visColumn.type
                }
              : restList[j]['expenseInternal'] && restList[j]['expenseInternal'][visColumn.key]
              ? {
                  name: restList[j]['expenseInternal'][visColumn.key].fullName,
                  id: restList[j]['expenseInternal'][visColumn.key].id,
                  type: visColumn.type
                }
              : { name: '—' };

              if (restList[j]?.['contactBankAccounkInTransaction']) {
                value = {
                  name: restList[j]['contactBankAccounkInTransaction']?.fullName,
                  id: restList[j]['contactBankAccounkInTransaction']?.id,
                  type: visColumn.type
                }
              }

        }
        // transactions-matter
        if (visColumn.key === 'transactions-matter' && visColumn.visible) {
          if (restList[j].type === 'out' && restList[j].expense && restList[j].expense.matter) {
            value = restList[j].expense.matter
              ? {
                  name: restList[j].expense.matter.name,
                  id: restList[j].expense.matter.id,
                  type: visColumn.type
                }
              : { name: '—' };
          } else if (restList[j].type === 'in' && restList[j].payment?.bill && restList[j].payment.bill.matter) {
            value = restList[j].payment.bill.matter
              ? {
                  name: restList[j].payment.bill.matter.name,
                  id: restList[j].payment.bill.matter.id,
                  type: visColumn.type
                }
              : { name: '—' };
          } else {
            value = { name: '—' };
          }
        }

        if (visColumn.key === 'userPortal' && visColumn.visible) {
          value = restList[j]['lastComment']
            ? {
                name: restList[j]['lastComment'].createdBy.person.fullName,
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        if (visColumn.key === 'portalThema' && visColumn.visible) {
          // 1 - має файли і не має в останньому повідомлені
          // 2 - є в останьому файли
          let name = '';
          if (
            restList[j].hasFiles &&
            (!restList[j]?.lastComment?.bills?.length || !restList[j]?.lastComment?.documents?.length)
          ) {
            name = restList[j]['lastComment']
              ? `<span class="item-name"> <span class="j-icon-add-document"></span> ${restList[j].name} </span>`
              : '—';
          } else if (restList[j]?.lastComment?.bills?.length || restList[j]?.lastComment?.documents?.length) {
            name = restList[j]['lastComment']
              ? restList[j].name + this.buildHtmlFromDocsAndBills(restList[j]?.lastComment)
              : '—';
          } else {
            name = restList[j].name;
          }
          value = restList[j].name
            ? {
                name,
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        if (visColumn.key === 'publishedAt' && visColumn.visible) {
          value = restList[j].lastComment?.publishedAt
            ? CustomDateAdapter.getDateFormattedString(restList[j]['lastComment'][visColumn.key], 'DD.MM.YYYY - HH:mm')
            : `<span class="is-draft-item">${this.translate.instant('draft')}</span>`;
        }
        if (visColumn.key === 'transactionsDescription' && visColumn.visible) {
          value = restList[j]?.expense
            ? restList[j]?.expense.description
            : restList[j].expenseInternal
            ? restList[j].expenseInternal.description
            : '—';
        }
        if (visColumn.key === 'deleted' && visColumn.visible) {
          value = restList[j]?.createdBy
            ? {
                name: restList[j].createdBy.fullName,
                id: restList[j].id,
                type: visColumn.type
              }
            : { name: '—' };
        }
        // trashCreatedAt
        if (visColumn.key === 'trashCreatedAt' && visColumn.visible) {
          value = restList[j]?.createdAt ? CustomDateAdapter.convertToUniversalString(restList[j].createdAt) : '—';
        }
        if (visColumn.key === 'trashType' && visColumn.visible) {
          value = restList[j]?.type || '—';
        }

        if (visColumn.key === 'team-status' && visColumn.visible) {
          value = restList[j].companyAccesses[0].enable ? this.translate.instant(restList[j].status) : this.translate.instant('deactive');
        }
        if (visColumn.key === 'templateCategory' && visColumn.visible) {
          value = restList[j]?.type ? this.translate.instant(restList[j]?.type) : '—';
        }
        if (visColumn.key === 'updatedAt' && visColumn.visible) {
          value = restList[j]?.updatedAt ? CustomDateAdapter.convertToUniversalString(restList[j].updatedAt) : '—';
        }

        if (visColumn.key === 'byDefault' && visColumn.visible) {
          value = restList[j]?.default ? '<span class="j-icon-tick"></span>' : '—';
        }
        if (!value && restList[j][visColumn.key] && visColumn.key !== 'libraryColName') {
          value = restList[j][visColumn.key];
        }
        // <span *ngIf="list.default" class="j-icon-tick"></span>
        visColumn.value.push(value);
      }
    }
  }



  getCurrentIconForDocument(list) {
    if (list?.orderItem) {
      return Helpers.getCurrentImgForDocumentUrl('warrant');
    }
    if (list?.mandatory) {
      return Helpers.getCurrentImgForDocumentUrl('attorney');
    }
    return Helpers.getCurrentImgForDocumentUrl(list.media?.url ? list.media.url : '');
  }


  visibleTotalByEntity(entities: string[], entity) {
    return entities.find(str => entity.includes(str));
  }

  checkStatus(item) {
    if (item.status === 'new' || item.status === 'in_work') {
      if (item.type === 'event' && new Date() > new Date(item.endDate)) {
        return 'overdue';
      }
      const today = CustomDateAdapter.today().split('.');
      const endDate = CustomDateAdapter.convertToDate(item.endDate).split('.');

      if (item.type === 'task' && new Date(`${today[2]}-${today[1]}-${today[0]}`) > new Date(`${endDate[2]}-${endDate[1]}-${endDate[0]}`)) {
        return 'overdue';
      }
      return item.status;
    } else {
      return item.status;
    }
  }

  buildHtmlFromDocsAndBills(comment) {
    let listHtml = '';

    for (const bill of comment.bills) {
      listHtml += `<div class="item-doc">
      <span class="j-icon-add-document"></span>
      <span> ${bill.number} </span>
     </div>`;
    }

    for (const bill of comment.documents) {
      listHtml += `<div class="item-doc">
      <span class="j-icon-add-document"></span>
      <span> ${bill.name} </span>
     </div>`;
    }
    return `<div class="list-files"> ${listHtml} </div>`;
  }

  getBalanceVal(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getContact(list) {
    if (list.contact) {
      return list.contact;
    } else if (list.matter) {
      return list.matter.contact;
    } else if (list.correspondence) {
      return list.correspondence.matter ? list.correspondence.matter.contact : null;
    } else if (list.bill) {
      if (list.bill.matter) {
        return list.bill.matter.contact ? list.bill.matter.contact : null;
      }
      return list.bill.client ? list.bill.client : null;
    } else if (list.task) {
      return list.task.matter ? list.task.matter.contact : null;
    } else if (list.activity) {
      return list.activity.matter ? list.activity.matter.contact : null;
    } else if (list.contract) {
      return list.contract ? list.contract.client : null;
    } else {
      return null;
    }
  }

  getMatter(list) {
    if (list.matter) {
      return list.matter;
    } else if (list.correspondence) {
      return list.correspondence.matter ? list.correspondence.matter : null;
    } else if (list.bill) {
      return list.bill.matter ? list.bill.matter : null;
    } else if (list.task) {
      return list.task.matter ? list.task.matter : null;
    } else if (list.activity) {
      return list.activity.matter ? list.activity.matter : null;
    } else {
      return null;
    }
  }

  getTransferName(j, restList, bankAccount) {
    return restList[j]?.type === 'in' && restList[j].payment?.bill
      ? { name: this.translate.instant('transfer.clientsPayment'), toolTip: this.translate.instant('transfer.clientsPayment') }
      : restList[j]?.type === 'in' && restList[j].payment && !restList[j].payment?.bill
      ? { name: this.translate.instant('transfer.openingBalance'), toolTip: this.translate.instant('transfer.openingBalance') }
      : restList[j]?.type === 'out' &&
        restList[j].transfer &&
        bankAccount.id === restList[j].transfer.companyBankAccountFrom.id
      ? {
          name:
            this.translate.instant('transfer.payments') +
            `<span class="transfer-on"> ${this.translate.instant('transfer.paymentTo')}: <span class="link">${
              restList[j].transfer.companyBankAccountTo?.accountName
            }</span></span>`,
          toolTip:
            this.translate.instant('transfer.payments') +
            ` ${this.translate.instant('transfer.paymentTo')}: ${restList[j].transfer.companyBankAccountTo?.accountName}`
        }
      : restList[j]?.type === 'in' &&
        restList[j].transfer &&
        bankAccount.id === restList[j].transfer.companyBankAccountTo.id
      ? {
          name:
            this.translate.instant('transfer.payments') +
            `<span class="transfer-on"> ${this.translate.instant('transfer.paymentFrom')}: <span class="link">${
              restList[j].transfer.companyBankAccountFrom?.accountName
            }</span></span>`,
          toolTip:
            this.translate.instant('transfer.payments') +
            ` ${this.translate.instant('transfer.paymentFrom')}: ${restList[j].transfer.companyBankAccountFrom?.accountName}`
        }
      : restList[j]?.type === 'out' && !restList[j].transfer
      ? { name: this.translate.instant('transfer.outgoingPayment'), toolTip: this.translate.instant('transfer.outgoingPayment') }
              : { name: this.translate.instant('transfer.incomingPayment'), toolTip: this.translate.instant('transfer.incomingPayment') };
  }
}
