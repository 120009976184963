import { Note } from '@app/models/note';

export interface NoteState {
  list: Array<Note>;
  selectNote: Note;
  loading: boolean;
  error: Error;
  ifContact: number;
  ifMatter: number;
}

export const initialNoteState: NoteState = {
  list: null,
  selectNote: null,
  loading: false,
  error: null,
  ifContact: null,
  ifMatter: null
};
