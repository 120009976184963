// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  width: 790px;
  height: 550px;
}
.modal-container header {
  position: relative;
}
.modal-container header .bg-img {
  background-color: #1252AF;
  height: 311px;
}
.modal-container header .close-btn {
  cursor: pointer;
}
.modal-container header .close-btn .j-icon-cross {
  position: absolute;
  top: 21px;
  right: 21px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.modal-container main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 540px;
  margin: auto;
}
.modal-container main .main-text {
  padding: 33px 0px;
}
.modal-container main .main-text .title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 14px;
}
.modal-container main .main-text .description {
  text-align: center;
}
.modal-container main .buy-subs {
  background-color: #1252AF;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.modal-container main .buy-subs:hover {
  background-color: #0f438e;
}
.modal-container main .buy-subs a {
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/other-modals/reports-tariff-modal/reports-tariff-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;AACE;EACE,kBAAA;AACJ;AAAI;EACE,yBAAA;EACA,aAAA;AAEN;AAAI;EACE,eAAA;AAEN;AADM;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AAGR;AAEE;EACE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;AAAJ;AACI;EACE,iBAAA;AACN;AAAM;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAER;AAAM;EACE,kBAAA;AAER;AACI;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AACN;AAAM;EACE,yBAAA;AAER;AAAM;EACE,cAAA;AAER","sourcesContent":[".modal-container {\n  width: 790px;\n  height: 550px;\n\n  header {\n    position: relative;\n    .bg-img {\n      background-color: #1252AF;\n      height: 311px;\n    }\n    .close-btn {\n      cursor: pointer;\n      .j-icon-cross {\n        position: absolute;\n        top: 21px;\n        right: 21px;\n        font-size: 14px;\n        color: #ffffff;\n        cursor: pointer;\n      }\n    }\n  }\n\n  main {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    width: 540px;\n    margin: auto;\n    .main-text {\n      padding: 33px 0px;\n      .title {\n        text-align: center;\n        font-size: 18px;\n        font-weight: 600;\n        margin-bottom: 14px;\n      }\n      .description {\n        text-align: center;\n      }\n    }\n    .buy-subs {\n      background-color: #1252AF;\n      border-radius: 3px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      width: 220px;\n      height: 30px;\n      cursor: pointer;\n      transition: 0.3s;\n      &:hover {\n        background-color: #0f438e;\n      }\n      a {\n        color: #ffffff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
