import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ETeamMemberRoles } from '@app/enums/team-member-roles';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FetchUserRoleById, UpdateTeamMember } from '@app/modules/settings/store/actions/team-member.actions';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TeamMemberEffects } from '@app/modules/settings/store/effects/team-member.effects';
import { TranslateService } from '@ngx-translate/core';
import { IUser } from '@app/models';

@Component({
  selector: 'app-team-permissions-modal',
  templateUrl: './team-permissions-modal.component.html',
  styleUrls: ['./team-permissions-modal.component.scss']
})
export class TeamPermissionsModalComponent implements OnInit {
  public permissionsInviteForm: UntypedFormGroup;
  public templateRoles = [
    { label: this.translate.instant('ROLE_SITE_ADMIN'), value: ETeamMemberRoles.admin, checked: false, disabled: false },
    { label: this.translate.instant('ROLE_SITE_ACCOUNTS_MANAGER'), value: ETeamMemberRoles.accounts, checked: false, disabled: false },
    { label: this.translate.instant('ROLE_SITE_REPORTS_MANAGER'), value: ETeamMemberRoles.reports, checked: false, disabled: false },
    { label: this.translate.instant('ROLE_SITE_BILLING_MANAGER'), value: ETeamMemberRoles.billing, checked: false, disabled: false }
  ];

  private subscriptions = new Subscription();
  currentUser;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { user?: IUser },
    public dialogRef: MatDialogRef<TeamPermissionsModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private teamMemberEffects: TeamMemberEffects,
    private translate: TranslateService,
    private store: Store<IAppState>
  ) {
    if (data && data.user) {
      this.currentUser = JSON.parse(JSON.stringify(data.user));
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.teamMemberEffects.fetchUserRoleById$.subscribe(result => {
        if (result) {
          this.currentUser.roles = JSON.parse(JSON.stringify(result.payload.roles));
          this.createForm();
        }
      })
    );
    if (this.currentUser) {
      this.store.dispatch(new FetchUserRoleById(this.currentUser.companyAccesses[0].id));
    } else {
      this.createForm();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.store.dispatch(
      new UpdateTeamMember(
        {
          roles: this.permissionsInviteForm.value.roles
        },
        this.currentUser.companyAccesses[0].id
      )
    );
    this.closeModal();
  }

  private createForm() {
    this.permissionsInviteForm = this.formBuilder.group({
      roles: this.formBuilder.array((this.currentUser && this.currentUser.roles) || [])
    });

    if (this.currentUser && this.currentUser.roles && this.currentUser.roles.length) {
      if (this.currentUser.roles.includes(ETeamMemberRoles.admin)) {
        this.toggleAdminRole(null, true);
      } else {
        this.templateRoles.map(role => {
          role.checked = this.currentUser.roles.includes(role.value);
        });
      }
    }
  }

  private setRoleToInviteForm(value) {
    const roles = this.permissionsInviteForm.get('roles') as UntypedFormArray;
    if (!roles.value.includes(value)) {
      roles.push(this.formBuilder.control(value));
    }
  }

  private removeRoleInviteForm(value) {
    const roles = this.permissionsInviteForm.get('roles') as UntypedFormArray;
    if (roles.value.includes(value)) {
      const deleteIndex = roles.value.indexOf(value);
      roles.removeAt(deleteIndex);
    }
  }

  public toggleAdminRole(event, value?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const newValue = typeof value !== 'undefined' ? value : !this.templateRoles[0].checked;
    this.templateRoles[0].checked = newValue;

    if (newValue) {
      this.clearInviteFormRoles();
      this.setRoleToInviteForm(this.templateRoles[0].value);
    } else {
      this.clearInviteFormRoles();
    }
    this.manageCheckboxesOnAdminRoleToggle(newValue);
  }

  public toggleRole(event, index, value?) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.templateRoles[index].disabled) {
      const newValue = typeof value !== 'undefined' ? value : !this.templateRoles[index].checked;
      this.templateRoles[index].checked = newValue;

      if (newValue) {
        this.setRoleToInviteForm(this.templateRoles[index].value);
      } else {
        this.removeRoleInviteForm(this.templateRoles[index].value);
      }
    }
  }

  private manageCheckboxesOnAdminRoleToggle(value) {
    for (let i = 1; i <= 3; i++) {
      this.templateRoles[i].checked = value;
      this.templateRoles[i].disabled = value;
    }
  }

  private clearInviteFormRoles() {
    const roles = this.permissionsInviteForm.get('roles') as UntypedFormArray;
    roles.clear();
  }
}
