import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { BankAccountSave } from '@app/models/bank-account-save';
import { ResponseBodyFull } from '../../../models/index';
import { BankAccountsTotal } from '@app/models/interfaces/bankAccounts-total';
import { AuthService } from '@app/auth/_services/auth.service';
import { Helpers } from '@app/helpers/helpers';
import { Paginator } from '@app/models/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {
  private BASE_URL_BASE_URL_EXPENSES_TOTAL = `${environment.apiUrl}/company_bank_account_totals/current?typeObject[]=expense`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translate: TranslateService) {
  }

  getBankAccountTotal(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filter): Observable<BankAccountsTotal> {
    const filters = filter ? JSON.parse(JSON.stringify(filter)) : {};
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (filters) {
      if (!filters?.status) {
        params = params.set('status', 'enabled');
        delete filters?.status;
      }
      if (filters?.status === 'all') {
        delete filters?.status;
      }
      params = Helpers.populateFilters(params, filters);
    }

    if (this.authService.currentUserValue) {
      return this.http
        .get<BankAccountsTotal>(this.BASE_URL_BASE_URL_EXPENSES_TOTAL, { params });
    } else {
      return new Observable<BankAccountsTotal>();
    }
  }

  getBankAccounts(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filter?): Observable<Paginator<BankAccountExtended>> {
    const filters = filter ? JSON.parse(JSON.stringify(filter)) : {};
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (filters) {
      if (!filters?.status) {
        params = params.set('status', 'enabled');
        delete filters?.status;
      }
      if (filters?.status === 'all') {
        delete filters?.status;
      }
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<BankAccountExtended>>(`${environment.apiUrl}/company_bank_accounts`, { params })
        .pipe(map(response => ({ member: response['hydra:member'], ...response.pagination })));
    } else {
      return new Observable<Paginator<BankAccountExtended>>();
    }
  }

  getBankAccountsByContact(contactId: number): Observable<BankAccountExtended[]> {
    return this.http
      .get<ResponseBodyFull<BankAccountExtended>>(`${environment.apiUrl}/company_bank_accounts?contact.id=${contactId}&status[]=enabled`)
      .pipe(map(response => response['hydra:member']));
  }

  getBankAccountsByCurrency(currency: string): Observable<BankAccountExtended[]> {
    return this.http
      .get<ResponseBodyFull<BankAccountExtended>>(`${environment.apiUrl}/company_bank_accounts?currency=${currency}&status[]=enabled`)
      .pipe(map(response => response['hydra:member']));
  }

  getBankAccountById(id: number): Observable<BankAccountExtended> {
    return this.http
      .get<BankAccountExtended>(`${environment.apiUrl}/company_bank_accounts/${id}`);
  }

  addBankAccount(bankAccount: BankAccountSave): Observable<BankAccountExtended> {
    return this.http
      .post<BankAccountExtended>(`${environment.apiUrl}/company_bank_accounts`, bankAccount);
  }

  updateBankAccount(bankAccount: BankAccountSave): Observable<BankAccountExtended> {
    return this.http
      .patch<BankAccountExtended>(`${environment.apiUrl}/company_bank_accounts/${bankAccount.id}`, bankAccount);
  }

  deleteBankAccount(id: number) {
    return this.http.delete(`${environment.apiUrl}/company_bank_accounts/${id}`).pipe();
  }

  addTransfers(data) {
    return this.http.post(`${environment.apiUrl}/transfers`, data).pipe();
  }

  editTransfers(data) {
    return this.http.patch(`${environment.apiUrl}/transfers/${data.id}`, data).pipe();
  }

  getTransfer(id) {
    return this.http.get(`${environment.apiUrl}/transfers/${id}`);
  }

  deleteTransfer(id) {
    return this.http.delete(`${environment.apiUrl}/transfers/${id}`).pipe(map((data) => {
      this.toastrService.error(this.translate.instant('itemDeleted'));
      return data;
    }));
  }
}
