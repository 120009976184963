import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CorrespondenceService } from '@app/modules/main-correspondence/services/correspondence.service';
import {
  CreateCorrespondence,
  CreateCorrespondenceMessage,
  CreateCorrespondenceMessageSuccess,
  CreateCorrespondenceSuccess,
  ECorrespondenceActions,
  GetCorrespondences,
  GetCorrespondenceById,
  GetCorrespondenceDropdown,
  GetCorrespondencesSuccess,
  GetCorrespondenceByIdSuccess,
  GetCorrespondenceDropdownSuccess,
  GetTimeLineSuccess,
  GetTimeline,
  GetCorrespondenceMessage,
  GetCorrespondenceMessageSuccess,
  RemoveCorrespondence,
  GetTrackInfo,
  GetTrackInfoSuccess,
  UpdateCorrespondence,
  UpdateCorrespondenceSuccess,
  CreateCorrespondenceWithDocuments,
  RemoveCorrespondenceSuccess,
  RemoveCorrespondenceFailure,
  GetCorrespondenceByIdFailure,
  CreateCorrespondenceWithDocumentsFailure
} from '@app/store/actions/correspondence.actions';
import { filter, map, mergeMap, share, switchMap, tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AddDocuments } from '../actions/document.actions';
import { of } from 'rxjs';
import { Correspondence } from '@app/models/correspondence';

@Injectable()
export class CorrespondenceEffects {


  fetch$ = createEffect(() => this.actions$.pipe(
    ofType<GetCorrespondences>(ECorrespondenceActions.GET_CORRESPONDENCES),
    switchMap(action => {
      return this.correspondenceService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(response => {
          return [new GetCorrespondencesSuccess(response)];
        })
      );
    }),
    share()
  ));


  fetchDropdown$ = createEffect(() => this.actions$.pipe(
    ofType<GetCorrespondenceDropdown>(ECorrespondenceActions.GET_DROPDOWN_CORRESPONDENCES),
    switchMap(action => {
      return this.correspondenceService.get(action.page, action.itemsPerPage, action.payload).pipe(
        mergeMap(response => {
          return [new GetCorrespondenceDropdownSuccess(response.member)];
        })
      );
    }),
    share()
  ));


  fetchById$ = createEffect(() => this.actions$.pipe(
    ofType<GetCorrespondenceById>(ECorrespondenceActions.GET_CORRESPONDENCE_BY_ID),
    switchMap(action => {
      return this.correspondenceService.getById(action.payload).pipe(
        mergeMap(resp => {
          return [new GetCorrespondenceByIdSuccess(resp)];
        }),
        catchError(error => of(new GetCorrespondenceByIdFailure(error)))
      );
    })
  ));


  track$ = createEffect(() => this.actions$.pipe(
    ofType<GetTrackInfo>(ECorrespondenceActions.GET_TRACK_INFO),
    filter(action => !!action.payload),
    switchMap(action => {
      return this.correspondenceService
        .track(action.payload)
        .pipe(mergeMap(response => (response?.['@id'] && [new GetTrackInfoSuccess(response)]) || []));
    }),
    share()
  ));


  create$ = createEffect(() => this.actions$.pipe(
    ofType<CreateCorrespondence>(ECorrespondenceActions.CREATE_CORRESPONDENCE),
    switchMap(action => {
      return this.correspondenceService.setCorrespondence(action.payload).pipe(
        mergeMap(response => {
          if (response) {
            this.toastrService.success(this.translate.instant('correspondenceAdded'));
            return [new CreateCorrespondenceSuccess(response), new LoadCompanySettingAction()];
          }
        })
      );
    }),
    share()
  ));

  createSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<CreateCorrespondenceSuccess>(ECorrespondenceActions.CREATE_CORRESPONDENCE_SUCCESS),
    tap(x => {
      // this.router.navigateByUrl(`/correspondence/card/${x.payload.id}`);
    })
  ), { dispatch: false });


  createWithDocument$ = createEffect(() => this.actions$.pipe(
    ofType<CreateCorrespondenceWithDocuments>(ECorrespondenceActions.CREATE_CORRESPONDENCE_WITH_DOCUMENTS),
    switchMap(action => {
      return this.correspondenceService.setCorrespondence(action.payload.correspondence).pipe(
        map(resp => {
          action.payload.documents.forEach(x => {
            x.document.correspondence = resp.id;
          });
          return resp;
        }),
        switchMap((res) => {
          this.toastrService.success(this.translate.instant('correspondenceAdded'));
          return [new AddDocuments(action.payload.documents), new CreateCorrespondenceSuccess(res)];
        }),
        catchError((error) => {
          return of(new CreateCorrespondenceWithDocumentsFailure(error));
        })
      );
    }),
    share()
  ));

  update$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCorrespondence>(ECorrespondenceActions.UPDATE_CORRESPONDENCE),
    switchMap(action => {
      return this.correspondenceService.updateCorrespondence(action.payload, action.id).pipe(
        mergeMap((response) => {
          this.toastrService.success(this.translate.instant('correspondenceEdited'));
          return [new UpdateCorrespondenceSuccess(response)];
        })
      );
    }),
    share()
  ));

  remove$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveCorrespondence>(ECorrespondenceActions.REMOVE_CORRESPONDENCE),
    switchMap(action => {
      return this.correspondenceService.deleteCorrespondence(action.payload).pipe(
        mergeMap(res => {
          this.toastrService.error(this.translate.instant('correspondenceDeleted'));
          return of(new RemoveCorrespondenceSuccess(action.payload));
        }),
        catchError(error => of(new RemoveCorrespondenceFailure(error)))
      );
    }),
    share()
  ));


  fetchTimeline$ = createEffect(() => this.actions$.pipe(
    ofType<GetTimeline>(ECorrespondenceActions.GET_TIMELINE_INFO),
    switchMap(action =>
      this.correspondenceService.getTimeline(action.payload).pipe(
        mergeMap(response => [new GetTimeLineSuccess(response)])
      )
    ),
    share()
  ));


  fetchMessage$ = createEffect(() => this.actions$.pipe(
    ofType<GetCorrespondenceMessage>(ECorrespondenceActions.GET_CORRESPONDENCE_MESSAGE),
    switchMap(action =>
      this.correspondenceService.getMessage(action.payload).pipe(
        filter(x => !!x.member?.length),
        mergeMap(response => [new GetCorrespondenceMessageSuccess(response.member)])
      )
    ),
    share()
  ));

  createMessage$ = createEffect(() => this.actions$.pipe(
    ofType<CreateCorrespondenceMessage>(ECorrespondenceActions.CREATE_CORRESPONDENCE_MESSAGE),
    switchMap(action =>
      this.correspondenceService.createMessage(action.payload).pipe(
        filter(x => !!x.id),
        mergeMap(response => [new CreateCorrespondenceMessageSuccess(response)])
      )
    ),
    share()
  ));

  constructor(
    private toastrService: ToastrService,
    private actions$: Actions,
    private router: Router,
    private correspondenceService: CorrespondenceService,
    private translate: TranslateService
  ) {}
}
