import { MatterActions, EMattersActions } from '@app/store/actions/matter.actions';
import { initialMatterState, IMatterState } from '../state/matter.state';

export const matterReducers = (state = initialMatterState, action: MatterActions): IMatterState => {
  switch (action.type) {
    case EMattersActions.CLEAR_MATTERS: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        }
      };
    }
    case EMattersActions.GET_MATTERS: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        }
      };
    }
    case EMattersActions.GET_MATTERS_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case EMattersActions.LOAD_MATTERS_FOR_LIST: {
      return {
        ...state,
        listMatterForSelect: null
      };
    }
    case EMattersActions.LOAD_MATTERS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        listMatterForSelect: action.payload
      };
    }

    case EMattersActions.LOAD_ALL_MATTERS_FOR_LIST: {
      return {
        ...state,
        listAllMatterForSelect: null
      };
    }
    case EMattersActions.LOAD_ALL_MATTERS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        listAllMatterForSelect: action.payload
      };
    }
    case EMattersActions.CLEAR_ALL_MATTERS_FOR_LIST: {
      return {
        ...state,
        listAllMatterForSelect: null
      };
    }

    case EMattersActions.GET_MATTER: {
      return {
        ...state,
        create: null
      };
    }
    case EMattersActions.GET_MATTER_SUCCESS: {
      return {
        ...state,
        create: action.payload
      };
    }
    case EMattersActions.ADD_MATTER_SUCCESS: {
      if (state.paginator.member) {
        state.paginator.member.push(action.payload);
      }
      return {
        ...state
      };
    }
    case EMattersActions.create:
      return { ...state, create: action.payload };

    case EMattersActions.UPDATE_MATTER_SUCCESS: {
      return {
        ...state
      };
    }

    case EMattersActions.DELETE_MATTER_SUCCESS: {
      return {
        ...state,
        paginator: {
          countItems: state.paginator.countItems,
          member: state.paginator.member?.filter(item => item.id !== action.payload),
          totalItems: state.paginator.totalItems,
          currentPage: state.paginator.currentPage,
          itemsPerPage: state.paginator.itemsPerPage,
          lastPage: state.paginator.lastPage
        }
      };
    }
    case EMattersActions.TRACK_MATTER:
      return { ...state, trackContactMatter: action.payload };
    default: {
      return state;
    }
  }
};
