import { initialTeamMembersState } from '@app/modules/settings/store/state/team-member.state';
import { ITeamMemberState } from '@app/models/interfaces/team-member.state';
import { ETeamMemberActions, TeamMemberActions } from '@app/modules/settings/store/actions/team-member.actions';

export const teamMemberReducers = (state = initialTeamMembersState, action: TeamMemberActions): ITeamMemberState => {
  switch (action.type) {
    case ETeamMemberActions.LOAD_COUNTRIES: {
      return {
        ...state,
        countries: null
      };
    }
    case ETeamMemberActions.CLEAR_TEAM_MEMBERS: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        }
      };
    }
    case ETeamMemberActions.CLEAN_SELECTED_MEMBER: {
      return {
        ...state,
        selected: null
      };
    }

    case ETeamMemberActions.LOAD_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload
      };
    }

    case ETeamMemberActions.LOAD_USERS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        listUsersForSelect: action.payload
      };
    }

    case ETeamMemberActions.LOAD_USERS_FOR_ENTITY_LIST: {
      return {
        ...state,
        listUsersForEntitySelect: null
      };
    }
    case ETeamMemberActions.LOAD_USERS_FOR_ENTITY_LIST_SUCCESS: {
      return {
        ...state,
        listUsersForEntitySelect: action.payload
      };
    }

    case ETeamMemberActions.LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST: {
      return {
        ...state,
        listAllAdministratorsAndAccountsForSelect: null
      };
    }
    case ETeamMemberActions.LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        listAllAdministratorsAndAccountsForSelect: action.payload
      };
    }

    case ETeamMemberActions.LOAD_ALL_USERS_FOR_LIST: {
      return {
        ...state,
        listAllUsersForSelect: null
      };
    }
    case ETeamMemberActions.LOAD_ALL_USERS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        listAllUsersForSelect: action.payload
      };
    }

    case ETeamMemberActions.LOAD_ALL_USERS_FOR_REPORTS_LIST: {
      return {
        ...state,
        listAllUsersForReportsSelect: null
      };
    }
    case ETeamMemberActions.LOAD_ALL_USERS_FOR_REPORTS_LIST_SUCCESS: {
      return {
        ...state,
        listAllUsersForReportsSelect: action.payload
      };
    }

    case ETeamMemberActions.GET_TEAM_MEMBERS_SUCCESS: {
      const members = {};

      action.payload.member.map(member => (members[member.id] = member));

      return { ...state, members, paginator: action.payload };
    }

    case ETeamMemberActions.GET_TEAM_MEMBER_BY_ID_SUCCESS: {
      state.members[action.payload.id] = action.payload;

      return { ...state };
    }

    case ETeamMemberActions.FETCHED_USER_ROLES_SUCCESS: {
      return { ...state };
    }

    case ETeamMemberActions.SELECT: {
      return {
        ...state,
        selected: action.payload
      };
    }

    case ETeamMemberActions.INVITED: {
      return {
        ...state,
        invited: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
