import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-docusign-popup',
  templateUrl: './docusign-popup.component.html',
  styleUrls: ['./docusign-popup.component.scss']
})
export class DocusignPopupComponent implements OnInit {

  positionProps: {top: string, left: string};
  @Input() target;
  @Input() position = 'top-right'; // left top right bottom top-right
  @Input() stateDocuSign = false; // false = створити новий true - вийти з акаунту
  @Output() btnAction = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
    if (this.target) {
      const anchorProps = this.target.getBoundingClientRect();
      const clientWidth = document.body.clientWidth;
      switch (this.position) {
        case 'top-right':
          this.positionProps = {top: anchorProps.y - 110 + 'px', left: anchorProps.x + anchorProps.width + 13 + 'px'};
          break;
        case 'bottom':
          this.positionProps = {top: anchorProps.y + anchorProps.height + 9 + 'px', left: anchorProps.x + 'px'};
          break;
        default:
          this.positionProps = {top: anchorProps.y + 'px', left: anchorProps.x + 'px'};
          break;
      }
      if ((anchorProps.x + anchorProps.width + 13) > (clientWidth - 300)) {
        this.positionProps.left = anchorProps.x + anchorProps.width + 13 - 350 + 'px'
      }
    }
  }

}
