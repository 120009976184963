import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeUa from '@angular/common/locales/uk';
import { ErrorHandler, Injectable, InjectionToken, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { ErrorInterceptor, JwtInterceptor, SwitchNotificationsService } from '@app/helpers';
import { HelpersServices } from '@app/helpers/helpers.services';
import { RoutesService } from '@app/helpers/routes.service';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { ContactEffects } from '@app/modules/contacts/store/effects/contact.effects';
import { LayoutModule } from '@app/modules/layout/layout.module';
import { NewsEffects } from '@app/modules/layout/news/store/effects/news.effect';
import { NotificationEffects } from '@app/modules/layout/notifications/store/effects/notification.effect';
import { ActivitiesService } from '@app/modules/main-activities/services/activities.service';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { CorrespondenceService } from '@app/modules/main-correspondence/services/correspondence.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { TeamMemberEffects } from '@app/modules/settings/store/effects/team-member.effects';
import { TeamEffects } from '@app/modules/settings/store/effects/team.effects';
import { TemplateEffects } from '@app/modules/settings/store/effects/template.effects';
import { TeamService } from '@app/modules/team/services/team.service';
import { SharedModule } from '@app/shared/shared.modules';
import { ActivitiesEffects } from '@app/store/effects/activities.effects';
import { BillEffects } from '@app/store/effects/bill.effects';
import { CorrespondenceEffects } from '@app/store/effects/correspondence.effects';
import { MatterEffects } from '@app/store/effects/matter.effects';
import { PaymentEffects } from '@app/store/effects/payment.effects';
import { WebsocketService } from '@app/websocket/websocket.service';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule, DatePickerModule, DateTimePickerModule, TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import {
  AutoCompleteModule,
  ComboBoxModule,
  DropDownListModule,
  DropDownTreeModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { ExchangeRatesService } from './api/exchange-rates.service';
import { TagService } from './api/tag.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { SubscribedGuard } from './auth/_guards/subscribed.guard';
import { AuthEffects } from './auth/_store/effects/auth.effects';
import { PipesModule } from './common/pipes/pipes.module';
import { SafePipe } from './common/pipes/safe.pipe';
import { CustomToasterComponent } from './commonComponents/custom-toaster/custom-toaster.component';
import { BillCreateModalComponent } from './commonComponents/modals/default-modals/bill-create-modal/bill-create-modal.component';
import {
  DocumentAddRelationModalComponent,
} from './commonComponents/modals/default-modals/document-add-relation-modal/document-add-relation-modal.component';
import {
  DocumentFromTemplateUploadModalComponent,
} from './commonComponents/modals/default-modals/document-from-template-upload-modal/document-from-template-upload-modal.component';
import {
  DocumentUploadModalComponent,
} from './commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import {
  EditTransactionModalComponent,
} from './commonComponents/modals/default-modals/edit-transaction-modal/edit-transaction-modal.component';
import { EventInfoModalComponent } from './commonComponents/modals/default-modals/event-info-modal/event-info-modal.component';
import { NoteModalComponent } from './commonComponents/modals/default-modals/note-modal/note-modal.component';
import {
  NotificationSettingsModalComponent,
} from './commonComponents/modals/default-modals/notification-settings-modal/notification-settings-modal.component';
import {
  PasswordChangeModalComponent,
} from './commonComponents/modals/default-modals/password-change-modal/password-change-modal.component';
import { RelationsModalComponent } from './commonComponents/modals/default-modals/relations-modal/relations-modal.component';
import { ReportsCustomModalComponent } from './commonComponents/modals/default-modals/reports-custom-modal/reports-custom-modal.component';
import {
  LibraryCheckboxCreateModalComponent,
} from './commonComponents/modals/default-modals/settings-library-modal/library-checkbox-create-modal/library-checkbox-create-modal.component';
import {
  LibraryCreateModalComponent,
} from './commonComponents/modals/default-modals/settings-library-modal/library-create-modal/library-create-modal.component';
import { TaxCreateModalComponent } from './commonComponents/modals/default-modals/tax-create-modal/tax-create-modal.component';
import { TeamInviteModalComponent } from './commonComponents/modals/default-modals/team-invite-modal/team-invite-modal.component';
import {
  TeamPermissionsModalComponent,
} from './commonComponents/modals/default-modals/team-permissions-modal/team-permissions-modal.component';
import {
  TransferBetweenAccountsModalComponent,
} from './commonComponents/modals/default-modals/transfer-between-accounts-modal/transfer-between-accounts-modal.component';
import { BillEditingModalComponent } from './commonComponents/modals/large-modals/bill-editing-modal/bill-editing-modal.component';
import {
  BillPaymentRegistrationModalComponent,
} from './commonComponents/modals/large-modals/bill-payment-registration-modal/bill-payment-registration-modal.component';
import {
  MatterPlanOfActionModalComponent,
} from './commonComponents/modals/large-modals/matter-plan-of-action-modal/matter-plan-of-action-modal.component';
import { AppsModalComponent } from './commonComponents/modals/other-modals/apps-modal/apps-modal.component';
import { ConverterModalComponent } from './commonComponents/modals/other-modals/converter-modal/converter-modal.component';
import { DisplayNoteModalComponent } from './commonComponents/modals/other-modals/display-note-modal/display-note-modal.component';
import { GroupCreateModalComponent } from './commonComponents/modals/other-modals/group-create-modal/group-create-modal.component';
import { ReportsTariffModalComponent } from './commonComponents/modals/other-modals/reports-tariff-modal/reports-tariff-modal.component';
import { ShareEmailModalComponent } from './commonComponents/modals/other-modals/share-email-modal/share-email-modal.component';
import { SharePublicModalComponent } from './commonComponents/modals/other-modals/share-public-modal/share-public-modal.component';
import { TariffPlanModalComponent } from './commonComponents/modals/other-modals/tariff-plan-modal/tariff-plan-modal.component';
import { TemplatesSaveModalComponent } from './commonComponents/modals/other-modals/templates-save-modal/templates-save-modal.component';
import {
  TrackerErrorPayModalComponent,
} from './commonComponents/modals/other-modals/tracker-error-pay-modal/tracker-error-pay-modal.component';
import {
  TrackerTutorialModalComponent,
} from './commonComponents/modals/other-modals/tracker-tutorial-modal/tracker-tutorial-modal.component';
import { TransactionModalComponent } from './commonComponents/modals/other-modals/transaction-modal/transaction-modal.component';
import { VideoModalComponent } from './commonComponents/modals/other-modals/video-modal/video-modal.component';
import { ExpireModalComponent } from './commonComponents/modals/system-modals/expire-modal/expire-modal.component';
import { FirstLoginModalComponent } from './commonComponents/modals/system-modals/first-login-modal/first-login-modal.component';
import { LoginModalComponent } from './commonComponents/modals/system-modals/login-modal/login-modal.component';
import { NotAvailableModalComponent } from './commonComponents/modals/system-modals/not-available-modal/not-available-modal.component';
import { UserDisabledModalComponent } from './commonComponents/modals/system-modals/user-disabled-modal/user-disabled-modal.component';
import { TwoFactorSettingModalComponent } from './commonComponents/modals/two-factor-setting-modal/two-factor-setting-modal.component';
import {
  CorrespondenceNewModalComponent,
} from './commonComponents/modals/wide-modals/correspondence-new-modal/correspondence-new-modal.component';
import { ContactsModalComponent } from '@app/modules/contacts/components/contacts-modal/contacts-modal.component';
import { ExpenseModalComponent } from './commonComponents/wide-modals/expense-modal/expense-modal.component';
import { InternalExpenseModalComponent } from './commonComponents/wide-modals/internal-expense-modal/internal-expense-modal.component';
import { TimeModalComponent } from './commonComponents/wide-modals/time-modal/time-modal.component';
import { CompressImageService } from './helpers/compress-image.service';
import { DebounceService } from './helpers/debounce.service';
import { ContractService } from './modules/contracts/services/contract.service';
import { HomeDashboardService } from './modules/home/services/home-dashboard.service';
import { ChatService } from './modules/layout/discussion/services/chat.service';
import { CommunicationPublicService } from './modules/layout/discussion/services/communication.service';
import { DiscussionService } from './modules/layout/discussion/services/discussion.service';
import { MatterCardService } from './modules/matter-card/services/matter-card.service';
import { MatterModalService } from './modules/matter-card/services/matter-modal.service';
import { MatterNoteService } from './modules/matter-card/services/matter-note.service';
import { NotesModule } from './modules/notes/notes.module';
import { ReportsService } from './modules/reports/services/reports.service';
import { AccountDeletingService } from './modules/settings/services/account-deleting.service';
import { CompanyPortalSettingsService } from './modules/settings/services/company-portal-settings.service';
import { TemplateService } from './modules/settings/services/template.service';
import { CompanySettingEffects } from './modules/settings/store/effects/company-setting.effects';
import { DocumentcategoryEffects } from './modules/settings/store/effects/document-category.effects';
import { ExpenseTypeEffects } from './modules/settings/store/effects/expense-type.effects';
import { PersonTypeEffects } from './modules/settings/store/effects/person-type.effects';
import { PracticeBranchEffects } from './modules/settings/store/effects/practice-branch.effects';
import { RelationshipTypeEffects } from './modules/settings/store/effects/relationship-type.effects';
import { TimeEntryTypeEffects } from './modules/settings/store/effects/time-entry-type.effects';
import { ColumnsModule } from './shared/columns/columns.module';
import { CommonsModule } from './shared/common/commons.module';
import { MaterialModule } from './shared/material/material.module';
import { BankAccountEffects } from './store/effects/bank-account.effects';
import { CompanyPortalSettingsEffects } from './store/effects/company-portal-settings.effects';
import { ContactCardEffects } from './store/effects/contact-card.effects';
import { ContractEffects } from './store/effects/contract.effects';
import { DocumentEffects } from './store/effects/document.effects';
import { MatterCardEffects } from './store/effects/matter-card.effects';
import { MediaEffects } from './store/effects/media.effects';
import { NoteEffects } from './store/effects/note.effects';
import { TagTypeEffects } from './store/effects/tag.effects';
import { TaskEffects } from './store/effects/task.effects';
import { appReducers, clearState } from './store/reducers/app.reducers';
import { NonBillableModalComponent } from './commonComponents/modals/other-modals/non-billable-modal/non-billable-modal.component';
import { TasksEventsModalComponent } from './commonComponents/wide-modals/task-event-modal/t-e-modal.component';
import { TaskDatepickerComponent } from './commonComponents/wide-modals/task-event-modal/task-datepicker/task-datepicker.component';
import { EventDatepickerComponent } from './commonComponents/wide-modals/task-event-modal/event-datepicker/event-datepicker.component';
import {
  AccountCorrespondenceModalComponent,
} from './commonComponents/modals/other-modals/account-correspondence-modal/account-correspondence-modal.component';
import { AdditionalMatterComponent } from './commonComponents/additional-matter/additional-matter.component';
import {
  CreateAutomationModalComponent,
} from './commonComponents/modals/other-modals/create-automation-modal/create-automation-modal.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { BankAccountState } from '@app/modules/bank-accounts/states/bank-account/bank-account.state';
import { RtMultipleSwitchCaseDirective, RtMultipleSwitchDirective } from 'rt-multiple-switch';
import { DefineFormBlockPipe } from '@app/modules/contacts/pipes/define-form-block.pipe';
import { JnInputComponent } from '@app/modules/jn-form-fields/jn-input/jn-input.component';
import { HideBlockDirective } from '@app/modules/contacts/directives/hide-block.directive';
import { MatRipple } from '@angular/material/core';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from '@app/perfect-scrollbar/perfect-scrollbar.interfaces';
import { PerfectScrollbarModule } from '@app/perfect-scrollbar/perfect-scrollbar.module';
import * as Sentry from '@sentry/browser';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LoaderModule } from './commonComponents/loader/loader.module';
import { SubscriptionLeftModule } from './commonComponents/subscription-left/subscription-left.module';


@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: Record<string, unknown>): void {
    console.error(error);
    Sentry.captureException(error.originalError || error);
  }
}

export const ROOT_REDUCER = new InjectionToken<any>('Root Reducer');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    CustomToasterComponent,
    LoginModalComponent,
    ContactsModalComponent,
    TasksEventsModalComponent,
    EventInfoModalComponent,
    ConfirmModalComponent,
    TimeModalComponent,
    ExpenseModalComponent,
    LibraryCreateModalComponent,
    LibraryCheckboxCreateModalComponent,
    DocumentUploadModalComponent,
    InternalExpenseModalComponent,
    BillCreateModalComponent,
    AdditionalMatterComponent,
    BillPaymentRegistrationModalComponent,
    BillEditingModalComponent,
    CorrespondenceNewModalComponent,
    TeamPermissionsModalComponent,
    TeamInviteModalComponent,
    NoteModalComponent,
    DisplayNoteModalComponent,
    GroupCreateModalComponent,
    PasswordChangeModalComponent,
    NotificationSettingsModalComponent,
    MatterPlanOfActionModalComponent,
    ReportsCustomModalComponent,
    AppsModalComponent,
    VideoModalComponent,
    TemplatesSaveModalComponent,
    FirstLoginModalComponent,
    ConverterModalComponent,
    DocumentFromTemplateUploadModalComponent,
    TransactionModalComponent,
    ExpireModalComponent,
    NotAvailableModalComponent,
    EditTransactionModalComponent,
    UserDisabledModalComponent,
    RelationsModalComponent,
    TaxCreateModalComponent,
    SharePublicModalComponent,
    ShareEmailModalComponent,
    DocumentAddRelationModalComponent,
    TariffPlanModalComponent,
    TrackerTutorialModalComponent,
    TrackerErrorPayModalComponent,
    TwoFactorSettingModalComponent,
    ReportsTariffModalComponent,
    TransferBetweenAccountsModalComponent,
    NonBillableModalComponent,
    TaskDatepickerComponent,
    EventDatepickerComponent,
    AccountCorrespondenceModalComponent,
    CreateAutomationModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule,
    MaterialModule,
    HttpClientModule,
    MatDialogModule,
    MatAutocompleteModule,
    CalendarModule,
    DateTimePickerModule,
    DatePickerModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    PipesModule,
    NgxsModule.forRoot([BankAccountState]),
    NgxsStoragePluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    // StoreModule.forRoot({}),
    // StoreModule.forRoot(appReducers),
    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers: [clearState],
      runtimeChecks: {
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictActionTypeUniqueness: false,
        strictActionWithinNgZone: false,
        strictStateImmutability: false,
        strictStateSerializability: false,
      },
    }),
    EffectsModule.forRoot([
      AuthEffects,
      CorrespondenceEffects,
      MatterEffects,
      MatterCardEffects,
      ActivitiesEffects,
      ContactCardEffects,
      ContractEffects,
      NoteEffects,
      BankAccountEffects,
      PracticeBranchEffects,
      ExpenseTypeEffects,
      TimeEntryTypeEffects,
      DocumentcategoryEffects,
      RelationshipTypeEffects,
      PersonTypeEffects,
      CompanySettingEffects,
      DocumentEffects,
      MediaEffects,
      TaskEffects,
      BillEffects,
      PaymentEffects,
      ContactEffects,
      TeamEffects,
      TeamMemberEffects,
      SharedModule,
      NotificationEffects,
      TemplateEffects,
      NewsEffects,
      CompanyPortalSettingsEffects,
      ColumnsModule,
      TagTypeEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      stateKey: 'router',
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    OverlayModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-full-right',
      toastComponent: CustomToasterComponent,
    }),
    AuthModule,
    MultiSelectModule,
    ComboBoxModule,
    DropDownListModule,
    AutoCompleteModule,
    CalendarModule,
    DatePickerModule,
    NumericTextBoxModule,
    SharedModule,
    NotesModule,
    PerfectScrollbarModule,
    NgxDocViewerModule,
    CommonsModule,
    TimePickerModule,
    DropDownTreeModule,
    RtMultipleSwitchCaseDirective,
    RtMultipleSwitchDirective,
    DefineFormBlockPipe,
    JnInputComponent,
    HideBlockDirective,
    MatRipple,
    MatProgressSpinner,
    LoaderModule,
    SubscriptionLeftModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: ROOT_REDUCER, useValue: appReducers },
    provideEnvironmentNgxMask(),
    HelpersServices,
    CorrespondenceService,
    BillService,
    ContactService,
    MatterService,
    DiscussionService,
    ActivitiesService,
    ContractService,
    TeamService,
    MatterCardService,
    MatterNoteService,
    ReportsService,
    MatterModalService,
    ExchangeRatesService,
    TagService,
    HomeDashboardService,
    AccountDeletingService,
    TemplateService,
    WebsocketService,
    SwitchNotificationsService,
    ChatService,
    RoutesService,
    CompanyPortalSettingsService,
    SubscribedGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    SafePipe,
    CommunicationPublicService,
    DebounceService,
    CompressImageService,
    // { provide: LOCALE_ID, useValue: 'uk' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  // constructor() {
  //   if (environment.production) {
  //     Sentry.init({
  //       dsn: 'https://75c394676fbe2c80a30ead26f4cf8205@o4506954888904704.ingest.us.sentry.io/4506954891132928',
  //       integrations: [
  //         Sentry.replayIntegration({
  //           maskAllText: false,
  //           maskAllInputs: false,
  //         }),
  //       ],
  //       // Session Replay
  //       replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //       replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //     });
  //   }
  // }
}

registerLocaleData(localeUa, 'uk');
