import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatterStage } from '@app/models/matter-stage';
import { AuthService } from '@app/auth/_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MatterStagesService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL = `${environment.apiUrl}/matter_stages`;

  // getMatterStages(practiceId: number) {
  //   return this.http.get<MatterStage>(this.BASE_URL, MatterStage);
  // }

  addMatterStages(MatterStage: MatterStage) {
    return this.http.post<MatterStage>(this.BASE_URL, MatterStage);
  }

  updateMatterStages(MatterStage: MatterStage) {
    const queryString = `${this.BASE_URL}/${MatterStage.id}`;
    return this.http.patch<MatterStage>(queryString, MatterStage);
  }

  deleteMatterStages(id: number) {
    const queryString = `${this.BASE_URL}/${id}`;
    return this.http.delete(queryString);
  }
}
