import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './containers/notes/notes.component';
import { NoteComponent } from './components/note/note.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@app/shared/material/material.module';
import { LoaderModule } from '@app/commonComponents/loader/loader.module';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { PerfectScrollbarModule } from '@app/perfect-scrollbar/perfect-scrollbar.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NotesComponent, NoteComponent, NotesListComponent],
  imports: [CommonModule, DatePickerModule, TranslateModule, MaterialModule, LoaderModule, PerfectScrollbarModule, RouterModule],
  exports: [NotesComponent, NoteComponent, NotesListComponent]
})
export class NotesModule {}
