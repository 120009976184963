import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { Paginator } from '@app/models/paginator';
import { Tag } from '@app/models/tag';
import { Action } from '@ngrx/store';

export enum TagActionEnum {
  GET_ALL_TAGS = '[TAGS] Get All Tags',
  GET_ALL_TAGS_SUCCESS = '[TAGS] Get All Tag success',
  GET_ALL_TAGS_PAGINATOR = '[TAGS] Get All Tags with paginator',
  GET_ALL_TAGS_PAGINATOR_SUCCESS = '[TAGS] Get All Tags with paginator success',
  GET_TAG = '[TAGS] Get Current Tag',
  GET_TAG_SUCCES = '[TAGS] Get Current Tag success',
  ADD_TAG = '[TAGS] Add Tag',
  ADD_TAG_SUCCESS = '[TAGS] Add Tag success',
  UPDATE_TAG = '[TAGS] Update Tag',
  UPDATE_TAG_SUCCESS = '[TAGS] Update Tag success',
  DELETE_TAG = '[TAGS] Delete Tag',
  DELETE_TAG_SUCCESS = '[TAGS] Delete Tag success',
  DELETE_TAG_LIST = '[TAGS] Delete Tag List',
  DELETE_TAG_LIST_SUCCESS = '[TAGS] Delete Tag List success'
}
export class GetAllTags implements Action {
  readonly type = TagActionEnum.GET_ALL_TAGS;
  constructor() {}
}

export class GetAllTagsSuccess implements Action {
  readonly type = TagActionEnum.GET_ALL_TAGS_SUCCESS;
  constructor(public payload: Array<Tag>) {}
}

export class GetAllTagPaginator implements Action {
  readonly type = TagActionEnum.GET_ALL_TAGS_PAGINATOR;
  constructor(public payload: FetchPayload<any>) {}
}

export class GetAllTagPaginatorSuccess implements Action {
  readonly type = TagActionEnum.GET_ALL_TAGS_PAGINATOR_SUCCESS;
  constructor(public payload: Paginator<Tag>) {}
}

export class GetTag implements Action {
  readonly type = TagActionEnum.GET_TAG;
  constructor(public tag_id: string) {}
}

export class GetTagSuccess implements Action {
  readonly type = TagActionEnum.GET_TAG_SUCCES;
  constructor() {}
}

export class AddTag implements Action {
  readonly type = TagActionEnum.ADD_TAG;
  constructor(public tag: Tag) {}
}

export class AddTagSuccess implements Action {
  readonly type = TagActionEnum.ADD_TAG_SUCCESS;
  constructor(public data: Tag) {}
}

export class UpdateTag implements Action {
  readonly type = TagActionEnum.UPDATE_TAG;
  constructor(public tag: Tag) {}
}

export class UpdateTagSuccess implements Action {
  readonly type = TagActionEnum.UPDATE_TAG_SUCCESS;
  constructor() {}
}

export class DeleteTag implements Action {
  readonly type = TagActionEnum.DELETE_TAG;
  constructor(public tagId: number) {}
}

export class DeleteTagSuccess implements Action {
  readonly type = TagActionEnum.DELETE_TAG_SUCCESS;
  constructor() {}
}

export class DeleteTagList implements Action {
  readonly type = TagActionEnum.DELETE_TAG_LIST;
  constructor(public tagIdList: number[]) {}
}

export class DeleteTagListSuccess implements Action {
  readonly type = TagActionEnum.DELETE_TAG_LIST_SUCCESS;
  constructor() {}
}

export type TagActions =
  | GetAllTags
  | GetAllTagsSuccess
  | GetAllTagPaginator
  | GetAllTagPaginatorSuccess
  | GetTag
  | GetTagSuccess
  | AddTag
  | AddTagSuccess
  | UpdateTag
  | UpdateTagSuccess
  | DeleteTag
  | DeleteTagSuccess;
