import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Country, StaffQty, Position } from '@app/models';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { IRegisterDto } from '@app/models/interfaces/register.interface';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { Register } from '@app/auth/_store/actions/auth.actions';
import { selectAuthState } from '@app/auth/_store/selectors/auth.selector';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { LocaleService } from '@app/helpers/locale.service';
import { map, takeUntil } from 'rxjs/operators';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { AuthEffects } from '@app/auth/_store/effects/auth.effects';
import { getPhoneMask } from '@app/helpers';
import { IAuthState } from '@app/auth/_store/state/auth.state';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  readonly spinnerName = 'register-spinner';
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  registerForm: UntypedFormGroup;
  countriesList: Country[];
  currentCountry;
  countryCode: string;
  countryPhoneCode: number;
  staff: Observable<StaffQty[]>;
  position: Observable<Position[]>;
  getState: Observable<IAuthState>;
  state = 1;
  submittedRegisterForm = false;
  haveRef = false;
  loading = false;
  phoneMask: string;
  unsubscribe = new Subject<void>();
  locale$ = this.appSettingsService.getLang;
  showLoader = false;

  constructor(
    private userService: TeamMemberService,
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    private localeService: LocaleService,
    private appSettingsService: AppSettingsService,
    private authEffect: AuthEffects
  ) {
    this.getState = this.store.select(selectAuthState);
    this.initData();
    this.getCountries();



    this.authEffect.register$.subscribe(() => {

      this.hideSpinner();
    });
  }

  ngOnInit() {
    this.initForm();
    this.getState.subscribe(state => {
      if (state.requestStatus) {
        this.state = 2;
        this.getState.subscribe(x => {
          x.requestStatus = false;
        });
      }
    });
    this.onPhoneChanged();
  }

  loadDataByIp() {
    this.localeService.localeSubject$.subscribe((data) => {
      let dataUser = data;
      if (!dataUser && localStorage.getItem('userData')) {
        dataUser = JSON.parse(localStorage.getItem('userData'));
      }
      this.countryCode = dataUser?.countryCode;
      this.countryPhoneCode = dataUser?.callingCode;
      this.registerForm?.get('phone').setValue(this.countryPhoneCode);
      if (this.countryCode) {
        this.currentCountry = this.countriesList.filter(item => item.code === this.countryCode);
        if (this.currentCountry.length > 0) {
          this.registerForm.get('country').setValue(this.currentCountry[0].code, { emitEvent: false });
        }
      }
    });
  }

  getCountries() {
    this.userService
      .getCountries()
      .pipe(
        map(countries => {
          if (countries) {
            this.countriesList = countries.member;
            this.loadDataByIp();
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  initForm(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[A-Za-zА-Яа-яЄІЇЁєіїё]+$/)]],
      lastName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[A-Za-zА-Яа-яЄІЇЁєіїё]+$/)]],
      username: ['', [Validators.required, Validators.email]],
      phone: [this.countryPhoneCode, [Validators.required]],
      country: ['', Validators.required],
      companyName: ['', [Validators.required, Validators.minLength(3)]],
      position: ['', Validators.required],
      employees: ['', Validators.required],
      refCode: [''],
      timeZone: [Intl.DateTimeFormat().resolvedOptions().timeZone]
    });
  }

  onSubmit() {
    this.submittedRegisterForm = true;

    if (this.registerForm.invalid) {
      return;
    }

    const input: IRegisterDto = this.registerForm.value;
    this.openSpinner();
    this.store.dispatch(new Register(input));
  }

  get f() {
    return this.registerForm.controls;
  }

  initData(): void {
    this.staff = this.userService.getStaff();
    this.position = this.userService.getPosition();
  }

  keydownEvent(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  onPhoneChanged() {
    this.registerForm.get('phone').valueChanges.subscribe(val => {
      this.phoneMask = getPhoneMask(val);
    });
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }
}
