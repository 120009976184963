import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { DocumentService } from '@app/modules/reusable/documents/services/document.service';
import { environment } from '@environments/environment';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DocumentExtended } from '@app/models';
import { IDocumentAddRelationModalDate } from '@app/models/document-add-relation-modal-data';
import { DocAddRelationDebounceEnum } from '../../../../enums/debounce-keys.enum';
import { DebounceService } from '../../../../helpers/debounce.service';

@Component({
  selector: 'app-document-add-relation-modal',
  templateUrl: './document-add-relation-modal.component.html',
  styleUrls: ['./document-add-relation-modal.component.scss'],
})
export class DocumentAddRelationModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<DocumentAddRelationModalComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDocumentAddRelationModalDate,
    private documentService: DocumentService,
    private debounceService: DebounceService,
  ) {
  }

  get f() {
    return this.addRelationForm;
  }

  addRelationForm: UntypedFormGroup;
  additionFields = false;
  submittedForm;
  relatedDocumentsSource = [];
  relatedDocumentsTarget: DocumentExtended[] = [];

  selectedTarget: DocumentExtended;

  public sorting = 'Ascending';
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  @ViewChild('relatedDocComboBox', { static: false }) public comboBoxRelatedDoc: ComboBoxComponent;
  filtersDocuments = {
    name: '',
  };

  ngOnInit(): void {
    this.createForm(this.dialogData?.relation);
    this.loadDocuments();
  }

  ngOnDestroy(): void {
    this.debounceService.removeDebounceData();
  }

  onSubmit() {
    this.submittedForm = true;
    this.addRelationForm.updateValueAndValidity();
    if (this.addRelationForm.valid) {
      const newRelationDoc = { ...this.addRelationForm.value };
      if (this.dialogData && this.dialogData.document && !this.dialogData?.relation) {
        newRelationDoc.source = this.dialogData.document.id;
      }
      if (this.dialogData?.relation) {
        newRelationDoc.id = this.dialogData.relation.id;
        newRelationDoc.source = this.dialogData.relation.source.id;
        // newRelationDoc.target = this.dialogData.relation.target.id;
      }
      if (!this.dialogData?.relation) {
        const selectedData = this.relatedDocumentsTarget.find(doc => doc.id === this.addRelationForm.get('target').value);
        newRelationDoc.selected = selectedData;
      }
      this.dialogRef.close(newRelationDoc);
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  private createForm(relation?) {
    this.addRelationForm = this.formBuilder.group({
      source: new UntypedFormControl(null, !this.dialogData?.fromDocument && [Validators.required]),
      target: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl(relation ? relation.description : '', [Validators.maxLength(255)]),
    });
    if (this.dialogData && this.dialogData.document) {
      this.addRelationForm.get('source').disable();
    }
  }

  onFilteringDocuments(e) {
    this.filtersDocuments.name = e.text;
    this.comboBoxRelatedDoc?.showSpinner();
    this.debounceService.loadDataDebounce(
      DocAddRelationDebounceEnum.documents,
      this.documentService, 'getDocuments',
      [environment.defaultPage, environment.countItemInSelect, this.filtersDocuments]);
    this.debounceService.getValue(DocAddRelationDebounceEnum.documents).subscribe(resp => {
      let docs = resp?.member;
      if (this.addRelationForm.get('source').value) {
        docs = docs.filter(doc => doc.id !== this.addRelationForm.get('source').value);
      }
      if (this.dialogData?.docRelations) {
        for (const relation of this.dialogData.docRelations) {
          docs = docs.filter(doc => doc.id !== relation.target.id);
        }
      }
      e.updateData(docs);
      this.comboBoxRelatedDoc?.hideSpinner();
    });
  }

  loadDocuments() {
    if (this.dialogData?.document && !this.dialogData?.relation) {
      this.relatedDocumentsSource.push(this.dialogData.document);
      this.addRelationForm.get('source').setValue(this.dialogData.document.id);
    }
    if (this.dialogData?.relation) {
      this.relatedDocumentsSource.push(this.dialogData.relation.source);
      this.addRelationForm.get('source').setValue(this.dialogData.relation.source.id);
    }

    this.documentService.getDocuments(environment.defaultPage, 50).subscribe(res => {
      if (res?.member) {
        this.relatedDocumentsTarget = res.member;

        if (this.addRelationForm.get('source').value) {
          this.relatedDocumentsTarget = this.relatedDocumentsTarget.filter(doc => doc.id !== this.addRelationForm.get('source').value);
        }
        if (this.dialogData?.docRelations) {
          for (const relation of this.dialogData.docRelations) {
            this.relatedDocumentsTarget = this.relatedDocumentsTarget.filter(doc => doc.id !== relation.target.id);
          }
        }
        if (this.dialogData?.relation) {
          if (this.addRelationForm.get('source').value) {
            this.relatedDocumentsTarget = this.relatedDocumentsTarget.filter(doc => doc.id !== this.addRelationForm.get('source').value);
          }
          if (!this.relatedDocumentsTarget.find(doc => doc.id === this.dialogData.relation.target.id)) {
            this.relatedDocumentsTarget.push(this.dialogData.relation.target);
          }
          this.addRelationForm.get('target').setValue(this.dialogData.relation.target.id);
        }
      }
    });
  }

  selectTargetDoc(e) {
    this.relatedDocumentsTarget = this.relatedDocumentsTarget.filter(doc => doc.id !== e.itemData.id);
    this.relatedDocumentsTarget.push(e.itemData);
  }
}
