import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillStatus } from '@app/enums/bills/bill-status';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';
import { ResponseBodyFull } from '@app/models';
import { Bill } from '@app/models/bill';
import { BillFilters } from '@app/models/bill-filters';
import { BillItemExpence } from '@app/models/bill-item-expence';
import { ActivityTotal } from '@app/models/interfaces/activity-total';
import { BillStatusUpdate } from '@app/models/interfaces/BillStatusUpdate';
import { BillTotal } from '@app/models/interfaces/BillTotal';
import { Paginator } from '@app/models/paginator';
import { environment } from '@environments/environment';
import { map, Observable } from 'rxjs';

@Injectable()
export class BillService {
  constructor(private http: HttpClient) {
  }

  static BASE_URL = `${environment.apiUrl}/bills`;
  private BASE_URL_BILLS_TOTAL = environment.apiUrl + '/bill_totals/current';
  private BASE_URL_ACTIVITY_TOTAL = environment.apiUrl + '/activity_totals';

  get(page = 1, itemsPerPage = environment.defaultItemsCount, billFilters: BillFilters): Observable<Paginator<Bill>> {
    const filters = { ...billFilters };
    if (filters.currency) {
      delete filters.currency;
    }
    if (filters.currencyPayment) {
      filters.currency = filters.currencyPayment;
      delete filters.currencyPayment;
    }
    let params = new HttpParams()
      .set('partial', 'false')
      .set('page', page.toString())
      .set('itemsPerPage', itemsPerPage.toString());

    if (filters && filters.status === 'overdue') {
      filters.status = BillStatus.awaiting;
      const dateOverdue = new Date();
      dateOverdue.setDate(dateOverdue.getDate() - 1);
      filters['dateExpire[before]'] = CustomDateAdapter.convertToUniversalDateString(dateOverdue);
    }

    params = Helpers.populateFilters(params, filters);

    return this.http.get<ResponseBodyFull<Bill>>(BillService.BASE_URL, { params })
      .pipe(map(response => ({ member: response['hydra:member'], ...response.pagination })));
  }

  getById(id: number) {
    return this.http.get<Bill>(`${BillService.BASE_URL}/${id}`);
  }

  deleteBill(id: number) {
    return this.http.delete(`${BillService.BASE_URL}/${id}`);
  }

  setBill(data: Bill) {
    return this.http.post<Bill>(BillService.BASE_URL, data);
  }

  setExpenses(data) {
    return this.http.post<BillItemExpence>(`${environment.apiUrl}/bill_item_expenses`, data);
  }

  deleteBillItemExpense(id: number) {
    return this.http.delete<BillItemExpence>(`${environment.apiUrl}/bill_item_expenses/${id}`);
  }

  deleteBillItemTimeEntries(id: number) {
    return this.http.delete<BillItemExpence>(`${environment.apiUrl}/bill_item_time_entries/${id}`);
  }

  updateBill(id: number, data: Bill | BillStatusUpdate) {
    return this.http.patch<Bill>(`${BillService.BASE_URL}/${id}`, data);
  }

  getHash(id: number, date?) {
    return this.http.patch(
      `${BillService.BASE_URL}/${id}/generate_hash`,
      { expiredHashAt: date },
      { headers: new HttpHeaders().append('content-type', 'application/json') }
    );
  }

  getTotal(billFilters, page, itemsPerPage) {
    const filters = { ...billFilters };

    if (filters && filters.status === 'overdue') {
      filters.status = BillStatus.awaiting;
      const dateOverdue = new Date();
      dateOverdue.setDate(dateOverdue.getDate() - 1);
      filters['dateExpire[before]'] = CustomDateAdapter.convertToUniversalDateString(dateOverdue);
    }
    let params = new HttpParams().set('page', page.toString()).set('itemsPerPage', itemsPerPage.toString());

    if (filters.currency) {
      this.BASE_URL_BILLS_TOTAL = `${environment.apiUrl}/bill_totals/${filters.currency}`;
      delete filters.currency;
    }
    if (filters.currencyPayment) {
      filters.currency = filters.currencyPayment;
      delete filters.currencyPayment;
    }
    params = Helpers.populateFilters(params, filters);

    return this.http.get<BillTotal>(this.BASE_URL_BILLS_TOTAL, { params });
  }

  sendBillToEmail(data, hash, type) {
    return this.http.post<Bill>(`${environment.apiUrl}/bills/${hash}/send-email-${type}`, data);
  }

  getPdf(url) {
    return this.http.get(url, { responseType: 'blob' });
  }

  getPreviouslyTotal(currency = 'UAH', matterIds, dates) {
    let datesFilter = '';
    if (dates) {
      datesFilter += dates.dateFrom ? `&date[after]=${CustomDateAdapter.getDateToNetworkFormat(dates.dateFrom)}` : '';
      datesFilter += dates.dateTo ? `&date[before]=${CustomDateAdapter.getDateToNetworkFormat(dates.dateTo)}` : '';
    }
    return this.http.get<ActivityTotal>(
      `${this.BASE_URL_ACTIVITY_TOTAL}/${currency}?typeObject[]=time_entry&typeObject[]=expense&status=unbilled${this.getFilterForMatter(matterIds)}${datesFilter}`
    );
  }

  getFilterForMatter(ids) {
    let filters = '';
    for (const id of ids) {
      if (id) {
        filters += `&matter.id[]=${id}`;
      }
    }
    return filters;
  }

}
