import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseBodyFull, ActivityExtended } from '@app/models';
import { environment } from '@environments/environment';
import { Helpers } from '@app/helpers/helpers';
import { ActivitiesFilters } from '@app/models/activities-filters';
import { ActivityTotal } from '@app/models/interfaces/activity-total';
import { AuthService } from '@app/auth/_services/auth.service';
import { Paginator } from '@app/models/paginator';
import { Rate } from '@app/models/rate';

@Injectable()
export class ActivitiesService {
  private BASE_URL_ACTIVITIES = `${environment.apiUrl}/activities?typeObject[]=time_entry&typeObject[]=expense`;
  private BASE_URL_ACTIVITIES_TOTAL = `${environment.apiUrl}/activity_totals/current?typeObject[]=time_entry&typeObject[]=expense`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  // ACTIVITIES
  getActivities(
    currentPage = 1,
    itemsPerPage = environment.defaultItemsCount,
    filter: ActivitiesFilters,
    typeActivity
  ): Observable<Paginator<ActivityExtended>> {
    const filters = filter ? JSON.parse(JSON.stringify(filter)) : {};
    let params = new HttpParams().set('partial', 'false');

    let typeFilter = 'typeObject[]=time_entry&typeObject[]=expense';
    switch (typeActivity) {
      case 'time_entries':
        typeFilter = 'typeObject[]=time_entry';
        break;
      case 'expenses':
        typeFilter = 'typeObject[]=expense';
        break;
    }
    this.BASE_URL_ACTIVITIES = `${environment.apiUrl}/activities?${typeFilter}`;

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    // if (filters && (filters.status === 'none' && filters.status === 'all')
    // || filter.status === 'expenses'
    // || filter.status === 'time_entries'
    // ) {
    //   delete filters.status;
    // }
    if (filters) {
      delete filters.type;
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue && this.authService.isCheckCurrentSubscriptionId()) {
      return this.http
        .get<ResponseBodyFull<ActivityExtended>>(this.BASE_URL_ACTIVITIES, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<ActivityExtended>>();
    }
  }

  getActivitiesTotal(
    currentPage = 1,
    itemsPerPage = environment.defaultItemsCount,
    filters: ActivitiesFilters,
    typeActivityTotal
  ): Observable<ActivityTotal> {
    let params = new HttpParams();

    let typeFilter = 'typeObject[]=time_entry&typeObject[]=expense';
    switch (typeActivityTotal) {
      case 'time_entry_totals':
        typeFilter = 'typeObject[]=time_entry';
        break;
      case 'expense_totals':
        typeFilter = 'typeObject[]=expense';
        break;
    }
    this.BASE_URL_ACTIVITIES_TOTAL = `${environment.apiUrl}/activity_totals/current?${typeFilter}`;

    if (filters.currency) {
      this.BASE_URL_ACTIVITIES_TOTAL = `${environment.apiUrl}/${typeActivityTotal}/${filters.currency}?${typeFilter}`;
      delete filters.currency;
    }

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }

    if (this.authService.currentUserValue && this.authService.isCheckCurrentSubscriptionId()) {
      return this.http
        .get<ActivityTotal>(this.BASE_URL_ACTIVITIES_TOTAL, { params });
    } else {
      return new Observable<ActivityTotal>();
    }
  }

  // typeActivity
  getActivityById(id: number, typeActivity): Observable<ActivityExtended> {
    if (this.authService.currentUserValue && this.authService.isCheckCurrentSubscriptionId()) {
      return this.http
        .get<ActivityExtended>(`${environment.apiUrl}/${typeActivity}/${id}`);
    } else {
      return new Observable<ActivityExtended>();
    }
  }

  deleteActivity(id: number, typeActivity) {
    return this.http.delete(`${environment.apiUrl}/${typeActivity}/${id}`).pipe();
  }

  addActivity(activity, typeActivity) {
    return this.http
      .post<ActivityExtended>(`${environment.apiUrl}/${typeActivity}`, activity);
  }

  updateActivity(activity, typeActivity): Observable<ActivityExtended> {
    return this.http
      .patch<ActivityExtended>(`${environment.apiUrl}/${typeActivity}/${activity.id}`, activity);
  }

  getRateMatterUser(matterId, userId) {
    return this.http.get<Rate>(`${environment.apiUrl}/rate/${matterId}/${userId}`);
  }

  checkUnBilledActivitiesForMAtter(matterId: number) {
    return this.http
      .get<ResponseBodyFull<ActivityExtended>>(`${environment.apiUrl}/activities/?typeObject[]=time_entry&partial=false&page=1&itemsPerPage=1&matter.id=${matterId}&status=unbilled`)
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
  }
}
