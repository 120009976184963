import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/_guards/auth.guard';
import { ExpiredGuard } from './auth/_guards/expired.guard';
import { SubscribedGuard } from './auth/_guards/subscribed.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'load',
    loadChildren: () => import('./modules/load-page/load-page.module').then(m => m.LoadPageModule),
    data: { showHeader: false, showSidebar: false, showFooter: false }
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    data: { showHeader: false, showSidebar: false, showFooter: false }
  },
  {
    path: 'bill-share',
    loadChildren: () => import('./modules/bill-share-public/bill-share-public.module').then(m => m.BillSharePublicModule),
    data: { showHeader: false, showSidebar: false, showFooter: false },
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./modules/calendar/calendar-page.module').then(m => m.CalendarPageModule),
    data: { showFooter: false },
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'tasks',
    loadChildren: () => import('./modules/main-tasks/main-tasks.module').then(m => m.MainTasksModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'matters',
    loadChildren: () => import('./modules/main-matters/main-matters.module').then(m => m.MainMattersModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'matter/:id',
    loadChildren: () => import('./modules/matter-card/matter-card.module').then(m => m.MatterCardModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'create-contract',
    loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'create-contract/:id',
    loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'contact/:id',
    loadChildren: () => import('./modules/contact-card/contact-card.module').then(m => m.ContactCardModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'worker',
    loadChildren: () => import('./modules/worker-card/worker-card.module').then(m => m.WorkerCardModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/main-documents/main-documents.module').then(m => m.MainDocumentsModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'bank-accounts',
    loadChildren: () => import('./modules/bank-accounts/bank-accounts.module').then(m => m.BankAccountsModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'activities',
    loadChildren: () => import('./modules/main-activities/main-activities.module').then(m => m.MainActivitiesModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'not-found',
    loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bills',
    loadChildren: () => import('./modules/main-bills/main-bills.module').then(m => m.MainBillsModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'correspondence',
    loadChildren: () => import('./modules/main-correspondence/main-correspondence.module').then(m => m.MainCorrespondenceModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  },
  {
    path: 'trash',
    loadChildren: () => import('./modules/trash/trash.module').then(m => m.TrashModule),
    canActivate: [AuthGuard, ExpiredGuard, SubscribedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
