import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { fadeAnimation } from '@app/helpers/fadeAnimation';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [fadeAnimation]
})
export class AuthComponent implements OnInit, AfterViewInit {
  showLogin: boolean;
  showSignUp: boolean;
  initPage = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.showLogin = this.activatedRoute.snapshot.firstChild.data.showLogin;
    this.showSignUp = this.activatedRoute.snapshot.firstChild.data.showSignUp;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showLogin = this.activatedRoute.firstChild.snapshot.data.showLogin !== false;
        this.showSignUp = this.activatedRoute.firstChild.snapshot.data.showSignUp !== false;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initPage = true;
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
