import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TaskExtended } from '@app/models';
import { TaskEffects } from '@app/store/effects/task.effects';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { DeleteTask } from '@app/store/actions/task.actions';
import { TranslateService } from '@ngx-translate/core';
import { TasksEventsModalComponent } from '@app/commonComponents/wide-modals/task-event-modal/t-e-modal.component';

@Component({
  selector: 'app-event-info-modal',
  templateUrl: './event-info-modal.component.html',
  styleUrls: ['./event-info-modal.component.scss']
})
export class EventInfoModalComponent implements OnInit, OnDestroy {
  task: TaskExtended;
  lang: string;
  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EventInfoModalComponent>,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private taskEffects: TaskEffects,
    private store: Store<IAppState>
  ) {}

  ngOnInit() {
    this.task = this.data[0];
    this.lang = this.data[1];
    const deleteTaskSubscr = this.taskEffects.deleteTask$.pipe().subscribe(() => {
      this.closeModal();
    });
    this.subscription.add(deleteTaskSubscr);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openTaskInfo(id: number) {
    this.router.navigate([`/tasks/card/${id}`]);
    this.closeModal();
  }

  editTask(task: TaskExtended) {
    if (task.type === 'task') {
      this.tasksModal(task);
    } else {
      this.eventsModal(task);
    }
    this.closeModal();
  }

  tasksModal(value: TaskExtended) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'tasks-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { task: value, type: 'task' };
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  eventsModal(value: TaskExtended) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'events-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { task: value, type: 'event' };
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  deleteTask(task: TaskExtended) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteTxt'
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.store.dispatch(new DeleteTask(task.id));
      } else {
        dialogRef.close();
      }
    });
  }

  public closeModal() {
    this.dialogRef.close();
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
}
