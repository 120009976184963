import { MediaExtended } from '@app/models/media-extended';
import { Note } from '@app/models/note';
import { PersonGET } from '@app/models/person-get';
import { PersonType } from '@app/models/person-type';
import { TagMain } from './tag';
import { Team } from './team';
export interface Contact {
  id?: number;
  balance?: number;
  addresses?: Address[];
  bankDetails?: BankDetail[];
  client?: boolean;
  comment?: string;
  emails?: Email[];
  fullName?: string;
  inn?: string;
  messengers?: Messenger[];
  media?: MediaExtended;
  notes?: Note[];
  phones?: Phone[];
  type?: string;
  websites?: Website[];
  contactPerson?: Partial<PersonGET>;
  legalDocuments?: Partial<LegalDocument>[];
  registrationActions?: Partial<RegistrationAction>[];
  responsiblePerson?: PersonGET; // TODO responsiblePerson - це юзер, чому тип  PersonGET?
  contactCompany?: Partial<ContactCompanyGet>; // TODO перевірити типізацію
  canDelete?: boolean;
  companyWorkGroup?: Team;
  relatedCompanyWorkGroup?: Team;
  personType?: Partial<PersonType>;
  tagContacts?: TagMain[];
  countUnreadComments?: number;
}

export interface ContactFinance {
  countDurationUnBilledTimeEntry: string;
  totalAmountUnBilledTimeEntry: string;
  totalAmountUnBilledExpense: string;
  totalAmountBilledPostPay: string;
  totalAmountPostPayPayment: string;
  totalAmountBilledPrePay: string;
  totalAmountPrePayPayment: string;
  barPercentRealization: string;
}

export interface ContactCompanyGet {
  director?: Partial<PersonGET>;
  fullName?: string;
  headPost?: string;
  headReason?: string;
  id?: number;
  legalForm?: string;
  shortName?: string;
}

export interface Phone {
  id?: number;
  phone?: string;
  type?: string; // TODO enum('','personal','work')
  edit?: boolean;
  value?: string;
}

export interface Email {
  id?: number;
  email?: string;
  type?: string; // TODO enum('','personal','work')
  edit?: boolean;
  value?: string;
}

export interface Website {
  id?: number;
  address?: string;
  type?: string; // TODO enum('','personal','work')
  edit?: boolean;
  value?: string;
}

export interface Messenger {
  id?: number;
  username?: string;
  type?: string; // TODO enum('Telegram','WhatsApp','Viber','Skype','Zoom');
  edit?: boolean;
  value?: string;
}

export interface BankDetail {
  id?: number;
  iban?: string;
  name?: string;
  edit?: boolean;
  value?: string;
}

export interface Address {
  city?: string;
  cityDistrict?: string;
  country?: string;
  fullAddress?: string;
  id?: number;
  region?: string;
  regionDistrict?: string;
  street?: string;
  type?: string; // TODO enum('registration','actual','another')
  zipCode?: string;
}

export interface LegalDocument {
  date?: string;
  id?: number;
  name?: string;
  number?: string;
  whoIssuedDocument?: string;
  dateExpire?: string;
}

export interface RegistrationAction {
  date?: string;
  id?: number;
  name?: string;
  number?: string ;
}
