import { ICompanyPortalSettings, ICompanyPortalSettingsFilters } from '@app/models/company-portal-settings';
import { Paginator } from '@app/models/paginator';
import { PROGRESS_STATUSES } from '@app/shared/symbols';

export interface ICompanyPortalSettingsState {
  companyPortalSettings: Paginator<ICompanyPortalSettings>;
  companyPortalSetting: ICompanyPortalSettings;
  companyPortalSettingsFilters: ICompanyPortalSettingsFilters;
  putCompanyUserPortalAccessStatus: PROGRESS_STATUSES;
}

export const initialCompanyPortalSettings: ICompanyPortalSettingsState = {
  companyPortalSettings: null,
  companyPortalSetting: null,
  companyPortalSettingsFilters: null,
  putCompanyUserPortalAccessStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
};
