// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalid-validation {
  border-color: red;
}
.invalid-validation .e-input-group.e-control-wrapper {
  border-color: red;
}

.bill-total-container {
  display: flex;
  margin-top: 23px;
}
.bill-total-container .input-container {
  max-width: 200px;
}
.bill-total-container .input-container.bill-total-display {
  min-width: 130px;
  margin-right: 55px;
}
.bill-total-container .input-container.bill-total-display span {
  display: block;
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  margin-top: 6px;
}

.text-select-field .left-item {
  max-width: calc(100% - 70px);
  width: 100%;
}
.text-select-field .right-item {
  width: 70px;
}

.table-section-template .table-col-bill {
  width: 146px;
}
.table-section-template .table-col-date {
  width: 205px;
}
.table-section-template .table-col-due {
  width: 187px;
}
.table-section-template .table-col-payment {
  width: 200px;
}
.table-section-template .table-row .table-row-bill a,
.table-section-template .table-row .table-row-matter a {
  color: #1252af;
}
.table-section-template .table-row .table-row-payment {
  padding-left: 2px;
}
.table-section-template .table-row .table-row-payment .input-container .text-select-field {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/large-modals/bill-payment-registration-modal/bill-payment-registration-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,aAAA;EACA,gBAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AAEI;EACE,gBAAA;EACA,kBAAA;AAAN;AAEM;EACE,cAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAAR;;AAOE;EACE,4BAAA;EACA,WAAA;AAJJ;AAOE;EACE,WAAA;AALJ;;AAWI;EACE,YAAA;AARN;AAWI;EACE,YAAA;AATN;AAYI;EACE,YAAA;AAVN;AAaI;EACE,YAAA;AAXN;AAgBI;;EAEE,cAAA;AAdN;AAgBI;EACE,iBAAA;AAdN;AAgBI;EACE,gBAAA;AAdN","sourcesContent":[".invalid-validation {\n  border-color: red;\n\n  .e-input-group.e-control-wrapper {\n    border-color: red;\n  }\n}\n\n.bill-total-container {\n  display: flex;\n  margin-top: 23px;\n\n  .input-container {\n    max-width: 200px;\n\n    &.bill-total-display {\n      min-width: 130px;\n      margin-right: 55px;\n\n      span {\n        display: block;\n        color: #222222;\n        font-size: 18px;\n        font-weight: 600;\n        text-align: right;\n        margin-top: 6px;\n      }\n    }\n  }\n}\n\n.text-select-field {\n  .left-item {\n    max-width: calc(100% - 70px);\n    width: 100%;\n  }\n\n  .right-item {\n    width: 70px;\n  }\n}\n\n.table-section-template {\n  .table-col {\n    &-bill {\n      width: 146px;\n    }\n\n    &-date {\n      width: 205px;\n    }\n\n    &-due {\n      width: 187px;\n    }\n\n    &-payment {\n      width: 200px;\n    }\n  }\n\n  .table-row {\n    .table-row-bill a,\n    .table-row-matter a {\n      color: #1252af;\n    }\n    .table-row-payment {\n      padding-left: 2px;\n    }\n    .table-row-payment .input-container .text-select-field {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
