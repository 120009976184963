// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jn-check-box {
  position: relative;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.jn-check-box .jn-check-box__input {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #E2E5EB;
  background-color: #ffffff;
  margin-right: 10px;
  transition: 0.3s ease;
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  appearance: none;
}
.jn-check-box .jn-check-box__input:checked {
  border-color: #1252af;
  background: #1252AF;
}
.jn-check-box .jn-check-box__input:focus, .jn-check-box .jn-check-box__input:hover {
  border-color: rgba(255, 255, 255, 0);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(199, 221, 255) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
}
.jn-check-box:before {
  font-family: "jusnoteicons2";
  position: absolute;
  top: 6px;
  left: 4px;
  font-size: 6px;
  content: "\\e862";
  color: #ffffff;
  transition: 0.3s ease;
  z-index: 1;
  pointer-events: none;
}
.jn-check-box .jn-check-box__title {
  color: #707B86;
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/jn-form-fields/jn-check-box/jn-check-box.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,UAAA;EACA,yCAAA;EACA,wBAAA;EAEA,gBAAA;AAEJ;AAAI;EACE,qBAAA;EACA,mBAAA;AAEN;AACI;EACE,oCAAA;EACA,wQAAA;AACN;AAGE;EACE,4BAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;EACA,UAAA;EACA,oBAAA;AADJ;AAIE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAFJ","sourcesContent":[".jn-check-box {\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: fit-content;\n  .jn-check-box__input {\n    width: 16px;\n    height: 16px;\n    position: relative;\n    border-radius: 5px;\n    border: 1px solid #E2E5EB;\n    background-color: #ffffff;\n    margin-right: 10px;\n    transition: 0.3s ease;\n    cursor: pointer;\n    opacity: 1;\n    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n\n    &:checked {\n      border-color: #1252af;\n      background: #1252AF;\n    }\n\n    &:focus, &:hover {\n      border-color: rgba(255, 255, 255, 0);\n      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(199, 221, 255) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;\n    }\n  }\n\n  &:before {\n    font-family: \"jusnoteicons2\";\n    position: absolute;\n    top: 6px;\n    left: 4px;\n    font-size: 6px;\n    content: \"\\e862\";\n    color: #ffffff;\n    transition: 0.3s ease;\n    z-index: 1;\n    pointer-events: none;\n  }\n\n  .jn-check-box__title {\n    color: #707B86;\n    font-size: 14px;\n    font-weight: 400;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
