// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  height: 94px;
}

.preview-total {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
}
.preview-total .label {
  color: #7b8994;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 2px;
}
.preview-total .amount {
  padding-left: 4px;
  font-size: 17px;
  font-weight: 500;
  min-width: 58px;
}
.preview-total .amount .small-loader .loader {
  margin-left: 20px;
}

:host .fix-height {
  height: 131px;
}
:host .currency-wrapper {
  margin-bottom: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/bill-create-modal/bill-create-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;AACE;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACM;EACE,iBAAA;AACR;;AAKE;EACE,aAAA;AAFJ;AAIE;EACE,mBAAA;AAFJ","sourcesContent":["textarea {\n  height: 94px;\n}\n\n.preview-total {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n  margin-top: 30px;\n  .label {\n    color: #7b8994;\n    font-size: 12px;\n    font-weight: 400;\n    padding-bottom: 2px;\n  }\n\n  .amount {\n    padding-left: 4px;\n    font-size: 17px;\n    font-weight: 500;\n    min-width: 58px;\n    .small-loader {\n      .loader {\n        margin-left: 20px;\n      }\n    }\n  }\n}\n:host {\n  .fix-height {\n    height: 131px;\n  }\n  .currency-wrapper {\n    margin-bottom: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
