import { Roles } from '@app/helpers/check-role';

export const SUBSCRIPTION_ROLES: Roles[] = ['ROLE_SITE_SUBSCRIPTIONS_MANAGER'];

export const BILLING_ROLES: Roles[] = [
  'ROLE_SITE_SUBSCRIPTIONS_MANAGER',
  'ROLE_SITE_ADMIN',
  'ROLE_SITE_ACCOUNTS_MANAGER',
  'ROLE_SITE_BILLING_MANAGER',
  'ROLE_SITE_PORTAL_USER'
];

export const MATTER_FINANCE_BLOCK: Roles[] = ['ROLE_SITE_ADMIN', 'ROLE_SITE_ACCOUNTS_MANAGER', 'ROLE_SITE_BILLING_MANAGER'];

export const SETTINGS_ROUTE_ACCESS: Roles[] = ['ROLE_SITE_ADMIN', 'ROLE_SITE_SUBSCRIPTIONS_MANAGER'];

export const BILL_APPROVE_EDIT: Roles[] = ['ROLE_SITE_ADMIN', 'ROLE_SITE_BILLING_MANAGER'];

export const BILL_TRANSFER_REGISTER: Roles[] = ['ROLE_SITE_ADMIN', 'ROLE_SITE_ACCOUNTS_MANAGER'];
