import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { TagService } from '@app/api/tag.service';
import { listAnimation } from '@app/helpers';
import { Helpers } from '@app/helpers/helpers';
import { Tag, TagBase, TagMain } from '@app/models/tag';
import { GetAllTags } from '@app/store/actions/tag.actions';
import { selectAllTags } from '@app/store/selectors/tag.selectors';
import { IAppState } from '@app/store/state/app.state';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  animations: [listAnimation]
})
export class TagComponent implements OnInit, AfterViewInit, OnDestroy {

  _entityTagList: TagMain[];
  get entityTagList(): TagMain[] {
      return this._entityTagList;
  }
  @Input('entityTagList') set entityTagList(value: TagMain[]) {
    this._entityTagList = Helpers.arrayUnique(value || []);
  }
  @Input('tableView') tableView = false;
  @Input('canCreate') canCreate = true;
  @Input('disableRemove') disableRemove = false;
  @Output() tagSaved = new EventEmitter<TagMain>();
  @Output() tagRemoved = new EventEmitter<TagMain>();

  @ViewChild('tagInput') private tagInput: ElementRef;
  @ViewChild('tagBlock') private tagBlock: ElementRef;
  @ViewChild('tooltip') private tooltip: ElementRef;
  @ViewChild('searchPossibleOptionsWrapper') set content(content: ElementRef) {
    if (content) {
      this.searchPossibleOptionsWrapper = content;
    }
  }

  public linkIndex = 0;
  public startIndex = 0;
  public currentSectionIndex = 0;

  public navigationTriggered = false;
  private mouseEvent: DOMRect;
  private documentHeight: number;
  private searchPossibleOptionsWrapper: ElementRef;
  public visibleToolTip = false;
  public isChangedBlock = {};
  public createNewTag = false;
  public tag: string;
  public allTagList$: Observable<Tag[]>;
  public tagListOptions: Tag[];
  public possibleOptions = false;

  private subscription: Subscription = new Subscription();

  // close creation on escape button
  @HostListener('document:keydown.escape', ['$event']) onKeydownEscapeHandler(event: KeyboardEvent) {
    this.clearTagState();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store$: Store<IAppState>,
    private tagService: TagService,
    private renderer: Renderer2
  ) {}

  trackByMethod(index: number, el: TagMain): number {
    return el?.tag?.id;
  }

  ngOnInit(): void {
    // Init tag list
    if (this.canCreate) {
      this.store$.dispatch(new GetAllTags());
    }
    this.allTagList$ = this.store$.pipe(select(selectAllTags));

    this.subscription.add(
      this.allTagList$
        .pipe(
          tap((tagList: Tag[]) => {
            this.tagListOptions = tagList;
          })
        )
        .subscribe()
    );
  }



  ngAfterViewInit(): void {
    this.renderer.listen('window', 'click', (e: MouseEvent) => {
      if (
        e.target !== this.tagBlock?.nativeElement &&
        (e.target as HTMLElement)?.id !== 'plus' &&
        (e.target as HTMLElement)?.id !== 'save-button'
      ) {
        this.clearTagState();
      }
      if (this.tooltip?.nativeElement && e.target !== this.tooltip?.nativeElement) {
        this.visibleToolTip = false;
        this.openToolTip(null, false);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addNewTag() {
    this.createNewTag = true;
    this.changeDetectorRef.detectChanges();

    this.tagInput.nativeElement.focus();
  }

  getTagListOptions(): Tag[] {
    if (this.possibleOptions) {
      return this.tagListOptions.filter(
        (tag: Tag) =>
          tag?.name.toLocaleLowerCase().startsWith(this.tag.toLocaleLowerCase()) && tag?.id !== this.isTagExistInEntityTagList(tag)?.id
      );
    } else {
      return this.tagListOptions;
    }
  }

  onTagChanged(event: any) {
    this.navigationTriggered = false;
    setTimeout(() => {
      this.setDocumentHeightAndMouthClick();
      if (event?.length > 2) {
        this.possibleOptions = true;
      } else {
        this.possibleOptions = false;
      }
      this.checkSearchOptionTooltip();
    }, 10);
  }

  private setDocumentHeightAndMouthClick() {
    const body = document?.body;
    const html = document?.documentElement;
    this.documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    this.mouseEvent = this.tagInput?.nativeElement?.getBoundingClientRect();
  }

  selectTag(tag: Tag) {
    this.tag = tag.name;
    const tagMain: TagMain = {
      tag
    };
    this._entityTagList.push(tagMain)
    this.tagSaved.next(tagMain);
    this.clearTagState();
  }

  saveTag(event?: Event) {
    event?.preventDefault();
    const newTag: TagBase = {
      name: this.tag
    };

    // check for null or undefined entityTagList
    if (this.entityTagList === null || typeof this.entityTagList === 'undefined') {
      this.entityTagList = [];
    }

    if (this.navigationTriggered) {
      const navigationTag = this.getNavigationSelectedTag();
      this.checkForSaveOrAdTag(navigationTag);
    } else {
      this.checkForSaveOrAdTag(newTag);
    }
  }

  removeTag(event: TagMain) {
    this.entityTagList = this.entityTagList.filter((tagMain: TagMain) => tagMain?.tag?.name !== event?.tag?.name);
    this.tagRemoved.next(event);
  }

  private checkForSaveOrAdTag(userTag) {
    const tagExistInAllList: Tag = this.isTagExistInAllTagList(userTag);
    const tagExistInAllTagList: Tag = this.isTagExistInEntityTagList(userTag);
    this.navigationTriggered = false;

    if (!tagExistInAllList) {
      this.tagService.addTag(userTag).pipe(take(1)).subscribe(tag => {
        const tagMain: TagMain = {tag};
        this.entityTagList.push(tagMain);
        this.clearTagState();
        this.tagSaved.next(tagMain);
        this.store$.dispatch(new GetAllTags());
      })
    } else if (tagExistInAllList && !tagExistInAllTagList) {
      this.selectTag(tagExistInAllList);
    } else {
      this.clearTagState();
    }
  }

  private isTagExistInAllTagList(userTag: TagBase): Tag {
    return this.tagListOptions?.find((tag: Tag) => tag?.name.toLocaleLowerCase() === userTag?.name.toLocaleLowerCase());
  }

  private isTagExistInEntityTagList(userTag: TagBase): Tag {
    return this.entityTagList?.find((tagMain: TagMain) => tagMain?.tag?.name.toLocaleLowerCase() === userTag?.name.toLocaleLowerCase())
      ?.tag;
  }

  private clearTagState(): void {
    this.possibleOptions = false;
    this.checkSearchOptionTooltip();
    this.tag = null;
    this.createNewTag = false;
    this.mouseEvent = null;
  }

  openToolTip(e?, show = true) {
    const tooltip = this.tooltip?.nativeElement;
    this.visibleToolTip = true;
    if (tooltip?.style && show && this.visibleToolTip) {
      setTimeout(() => {
        tooltip.style.top = `${e.y + 10}px`;
        tooltip.style.left = `${e.x - 220}px`;
        tooltip.style.opacity = '1';
        tooltip.style.zIndex = '10';
      }, 0);
    } else {
      tooltip.style.opacity = '0';
      tooltip.style.zIndex = '0';
      tooltip.style.bottom = '0';
      tooltip.style.top = 'unset';
      tooltip.style.left = 'unset';
    }
  }

  // Option list navigation by key up and down
  navigateUsingKey(event) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        this.navigationTriggered = true;
        this.linkIndex === -1 ? (this.linkIndex = 0) : this.linkIndex--;
        this.downTraverse(0, this.getTagListOptions().length);
        break;

      case 40: // this is the ascii of arrow down
        this.navigationTriggered = true;
        this.upTraverse(0, this.getTagListOptions().length);
        this.linkIndex++;
        break;
    }
  }

  downTraverse(sectionIndex: number, listLength) {
    // calls when DOWN key press...
    this.linkIndex === -1 ? ((this.currentSectionIndex = sectionIndex), (this.linkIndex = listLength - 1)) : '';
  }
  upTraverse(sectionIndex: number, listLength: number) {
    // calls when UP key press...
    listLength - 1 <= this.linkIndex ? ((this.currentSectionIndex = sectionIndex), (this.linkIndex = -1)) : '';
  }

  private getNavigationSelectedTag(): Tag {
    return this.getTagListOptions()[this.linkIndex];
  }

  private checkSearchOptionTooltip() {
    if (this.searchPossibleOptionsWrapper) {
      if (this.possibleOptions && this.getTagListOptions()?.length > 0) {
        this.searchPossibleOptionsWrapper.nativeElement.style.height = 'unset';
        this.searchPossibleOptionsWrapper.nativeElement.style.opacity = '1';
        this.searchPossibleOptionsWrapper.nativeElement.style.zIndex = '10';
        this.searchPossibleOptionsWrapper.nativeElement.style.bottom = `${this.documentHeight -
          this.mouseEvent?.top +
          this.mouseEvent?.height}px`;
      } else {
        this.searchPossibleOptionsWrapper.nativeElement.style.height = '0';
        this.searchPossibleOptionsWrapper.nativeElement.style.opacity = '0';
        this.searchPossibleOptionsWrapper.nativeElement.style.zIndex = '0';
        this.searchPossibleOptionsWrapper.nativeElement.style.bottom = '0';
      }
    }
  }
}
