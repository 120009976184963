import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap, share, filter } from 'rxjs/operators';
import {
  CreatePayment,
  CreatePaymentSuccess,
  EPaymentActions,
  GetPayments,
  GetPaymentsSuccess,
  RemovePayment,
  UpdatePayment,
  UpdatePaymentSuccess,
  FetchedTransactions,
  FetchTransactions,
  FetchedTransactionsSummary
} from '@app/store/actions/payment.actions';
import { PaymentService } from '@app/modules/main-bills/services/payment.service';
import { ToastrService } from 'ngx-toastr';
import { GetBillById } from '@app/store/actions/bill.actions';
import { Payment } from '@app/models/payment';
import { TranslateService } from '@ngx-translate/core';
import { Bill } from '@app/models/bill';
import { forkJoin } from 'rxjs';

@Injectable()
export class PaymentEffects {

  create$ = createEffect(() => this.actions$.pipe(
    ofType<CreatePayment>(EPaymentActions.CREATE_PAYMENT),
    switchMap(action => {
      return this.paymentService.createPayment(action.payload).pipe(
        filter(x => !!x),
        mergeMap((response: Payment) => {
          if (response.type === 'transfer') {
            this.toastrService.success(this.translate.instant('transferAdded'));
          }
          if (response.type === 'money') {
            this.toastrService.success(this.translate.instant('paymentAdded'));
          }
          return (response && [new CreatePaymentSuccess(response), new GetBillById((response.bill as Bill).id)]) || [];
        })
      );
    }),
    share()
  ));


  update$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePayment>(EPaymentActions.UPDATE_PAYMENT),
    switchMap(action => {
      return this.paymentService.updatePayment(action.id, action.payload).pipe(
        mergeMap((response: Payment) => {
          this.toastrService.success(this.translate.instant('paymentEdited'));
          return response && [new UpdatePaymentSuccess(response)];
        })
      );
    }),
    share()
  ));


  fetch$ = createEffect(() => this.actions$.pipe(
    ofType<GetPayments>(EPaymentActions.GET_PAYMENTS),
    switchMap(action => {
      return this.paymentService.getPayments(action.page, action.itemsPerPage, action.payload || null).pipe(
        mergeMap(response => {
          return [new GetPaymentsSuccess(response)];
        })
      );
    }),
    share()
  ));


  fetchTransactions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FetchTransactions>(EPaymentActions.FETCH_TRANSACTIONS),
      switchMap(action => {
        return forkJoin([
          this.paymentService.getTransactions(action.payload.page, action.payload.itemsPerPage, action.payload.filters),
          this.paymentService.getTransactionSummary(action.payload.page, action.payload.itemsPerPage, action.payload.filters)
        ]).pipe(
          mergeMap(response => {
            return [
              new FetchedTransactions(response[0]),
              new FetchedTransactionsSummary(response[1] as any)];
          })
        );
      }),
      share()
    );
  });


  remove$ = createEffect(() => this.actions$.pipe(
    ofType<RemovePayment>(EPaymentActions.REMOVE_PAYMENT),
    switchMap(action => {
      return this.paymentService.deletePayment(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('itemDeleted'));
          return [new GetBillById(action.bill)];
        })
      );
    }),
    share()
  ), { dispatch: false });

  constructor(
    private toastrService: ToastrService,
    private actions$: Actions,
    private paymentService: PaymentService,
    private translate: TranslateService
  ) {}
}
