import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppState } from '@app/store/state/app.state';
import { selectAuthState } from '@app/auth/_store/selectors/auth.selector';
import { ForgotConfirm } from '@app/auth/_store/actions/auth.actions';
import { AuthService } from '@app/auth/_services/auth.service';
import { AuthEffects } from '@app/auth/_store/effects/auth.effects';
import { IAuthState } from '@app/auth/_store/state/auth.state';
import { fadeInOutAnimation } from '@app/helpers/fadeAnimation';

@Component({
  selector: 'app-password-create',
  templateUrl: './password-create.component.html',
  styleUrls: ['./password-create.component.scss'],
  animations: [
    fadeInOutAnimation,
  ]
})
export class PasswordCreateComponent implements OnInit {
  registerForm: UntypedFormGroup;
  getState: Observable<IAuthState>;
  loading = false;
  submittedRegisterForm = false;
  hash: string;
  spinnerName = 'password-create-spinner';
  showPass = false;
  showLoader = false;

  hasSpecialChar: boolean = false;
  hasDigit: boolean = false;
  hasLowerCase: boolean = false;
  hasUpperCase: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authEffect: AuthEffects,
    private route: ActivatedRoute,
    private authenticationService: AuthService,
    private store: Store<IAppState>
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.getState = this.store.select(selectAuthState);

    this.authEffect.forgotConfirm$.subscribe(() => {
      this.loading = false;
      this.hideSpinner();
    });
  }

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');

    // tslint:disable-next-line: deprecation
    this.registerForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[0-9a-zA-Z_\\-~!@#\\$%\\^&]{6,}$')
          ]
        ],
        passwordConfirm: ['', [Validators.required, Validators.minLength(8)]]
      },
      { validator: this.checkPasswords }
    );
    this.registerForm.get('password').valueChanges.subscribe(res => {
      this.validatePassword(res);
    })
  }


  checkPasswords(group: UntypedFormGroup): ValidationErrors | null {
    const pass = group.get('password');
    const confirmPass = group.get('passwordConfirm');
    return pass && confirmPass && pass.value === confirmPass.value ? null : { notSame: true };
  }

  get p() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submittedRegisterForm = true;

    if (this.registerForm.invalid) {
      return;
    }
    const payload = {
      password: this.registerForm.value.password,
      hash: this.hash
    };
    this.openSpinner();
    this.loading = true;
    this.store.dispatch(new ForgotConfirm(payload));
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  validatePassword(password) {
    // Перевірка спеціальних символів
    const specialCharRegex = /[!@#$%^&*]/;
    this.hasSpecialChar = specialCharRegex.test(password);

    // Перевірка цифр
    const digitRegex = /\d/;
    this.hasDigit = digitRegex.test(password);

    // Перевірка малих літер
    const lowerCaseRegex = /[a-z]/;
    this.hasLowerCase = lowerCaseRegex.test(password);

    // Перевірка великих літер
    const upperCaseRegex = /[A-Z]/;
    this.hasUpperCase = upperCaseRegex.test(password);
  }
}
