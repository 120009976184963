import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers';
import { AppSettingsService } from '@app/helpers/app-settings.service';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {
  @Input() currentRates;
  @Input() visibleButton;
  @Output() changedRates: EventEmitter<any> = new EventEmitter();
  form: UntypedFormGroup;
  currencies = Helpers.getCurrenciesList();

  @ViewChildren('dropDown') dropDowns;
  filteredCurrencies = [];
  public locale$ = this.appSettingsService.getLang;

  constructor(public fb: UntypedFormBuilder, private appSettingsService: AppSettingsService) {}
  ngOnInit(): void {
    this.createForm();
    this.updateForm(this.currentRates);
    this.setCurrency();
  }
  resetForm() {
    this.form.get('rates').reset();
    this.createForm();

    this.updateForm(this.currentRates);
    this.setCurrency();
  }

  addRateField(values = null) {
    const rates = this.form.get('rates') as UntypedFormArray;
    rates.push(
      this.fb.group({
        currency: (values && values.currency.code) || this.filteredCurrencies[0],
        rate: (values && values.rate) || '0',
        id: (values && values.id) || null
      })
    );

    this.setCurrency();
  }

  setValue(index, data) {
    const rates = this.form.get('rates') as UntypedFormArray;
    rates
      .at(index)
      .get('currency')
      .patchValue(data.value);
  }

  removeField(index) {
    const rates = this.form.get('rates') as UntypedFormArray;
    const deleteCurrency = this.form.get('rates').value[index].currency;
    rates.removeAt(index);
    this.filteredCurrencies.push(deleteCurrency);
    this.dropDowns.forEach(dropDown => {
      dropDown.dataSource = this.filteredCurrencies;
      dropDown.refresh();
    });
  }

  setCurrency() {
    const usedCurrencies = this.form.get('rates').value.map(y => y.currency);

    this.filteredCurrencies = this.currencies.filter(x => usedCurrencies.indexOf(x) === -1);
  }

  changeCurrency(event, index) {
    const oldCurrency = this.form.get('rates').value[index].currency;
    const selectedCurrencyIndex = this.filteredCurrencies.indexOf(event.value);
    if (this.filteredCurrencies.indexOf(oldCurrency) === -1 && oldCurrency !== '') {
      this.filteredCurrencies.push(oldCurrency);
    }
    if (selectedCurrencyIndex !== -1) {
      this.filteredCurrencies.splice(selectedCurrencyIndex, 1);
    }
    this.dropDowns.forEach(dropDown => {
      dropDown.dataSource = this.filteredCurrencies;
      dropDown.refresh();
    });
  }

  private createForm() {
    this.form = this.fb.group({
      rates: this.fb.array([])
    });
  }

  private updateForm(currentRates) {
    currentRates.map(rate => this.addRateField(rate));
  }

  get defaultRate() {
    return this.form.get('rates').get([0]);
  }

  get rates() {
    const rates = this.form.get('rates') as UntypedFormArray;

    return rates.controls.slice(1);
  }

  get disableEdit() {
    return this.currencies.length > this.rates.length + 1 && this.currencies.length !== this.rates.length + 1;
  }

  changeCurrencyOrRate() {
    this.changedRates.emit(this.form.get('rates').value);
  }
}
