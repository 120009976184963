import { CorrespondenceState } from '@app/models/interfaces/correspondence-state';

export const correspondenceState: CorrespondenceState = {
  correspondences: {},
  correspondenceDropdown: null,
  selected: null,
  paginator: null,
  track: null,
  timeline: null,
  notifications: []
};
