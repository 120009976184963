import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { Subscription } from 'rxjs';
import { JnCheckBoxComponent } from '@app/modules/jn-form-fields/jn-check-box/jn-check-box.component';

@Component({
  selector: 'jn-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    FormsModule,
    NgIf,
    JnCheckBoxComponent,
    NgOptimizedImage,
  ],
  templateUrl: './jn-input.component.html',
  styleUrl: './jn-input.component.scss',
})
export class JnInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label: string;
  @Input() placeholder = '';
  @Input() type: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url' = 'text';

  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter();

  @ViewChild('input') input: ElementRef;

  public readonly formControl = new FormControl();
  public isRequired = false;

  private readonly subscription = new Subscription();

  protected readonly Validators = Validators;

  constructor(protected cd: ChangeDetectorRef, public ngControl: NgControl) {
    // Use the `ngControl` to access the related form control.
    // The `NG_VALUE_ACCESSOR` provider was removed from the component, as it is assigned here directly.
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.isRequired = this.ngControl.control.hasValidator(Validators.required);


    if (this.ngControl.control.disabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }

    this.subscription.add(this.ngControl.control.statusChanges.subscribe(() => {
        if (this.ngControl.control.disabled) {
          this.formControl.disable({ emitEvent: false });
        } else {
          this.formControl.enable({ emitEvent: false });
        }
      }),
    );

    this.subscription.add(
      this.formControl.valueChanges.subscribe((value: any) => {
        this.onChange(value);
      }),
    );

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /** Triggered whether the value is changed. Will be overwritten by Angular forms. */
  public onChange?: (value: Date) => void;

  /** Triggered whether the control is touched. Will be overwritten by Angular forms. */
  public onTouched?: () => void;

  /**
   * Required by ControlValueAccessor.
   * Function for overriding the `onChange` class method.
   */
  public registerOnChange(fn: (value: any) => void): void {
    // Override the class method with provided function.
    this.onChange = fn;
  }

  /**
   * Required by ControlValueAccessor.
   * Function for overriding the `onTouched` class method.
   */
  public registerOnTouched(fn: () => void): void {
    // Override the class method with provided function.
    this.onTouched = fn;
  }

  /**
   * Required by ControlValueAccessor.
   * Describes how to update the form model when the value changes.
   */
  public writeValue(value: any): void {
    this.formControl.setValue(value);
  }

  public onBlur(event: FocusEvent): void {
    this.onTouched();
    this.blur.emit(event);
  }

  public focusIn(): void {
    this.input.nativeElement.focus();
  }

  public clearInput($event: MouseEvent): void {
    $event.stopPropagation();
    this.formControl.setValue('');
  }
}
