import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { checkRole } from '@app/helpers/check-role';

@Component({
  selector: 'app-reports-tariff-modal',
  templateUrl: './reports-tariff-modal.component.html',
  styleUrls: ['./reports-tariff-modal.component.scss']
})
export class ReportsTariffModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReportsTariffModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  bgImg = 'reports-modal-bg.svg';
  titleTxt = 'reports.modal.title';
  descriptionTxt = 'reports.modal.description';
  ngOnInit(): void {
    if (this.data?.bgImg) {
      this.bgImg = this.data?.bgImg;
    }
    if (this.data?.titleTxt) {
      this.titleTxt = this.data?.titleTxt;
    }
    if (this.data?.descriptionTxt) {
      this.descriptionTxt = this.data?.descriptionTxt;
    }
  }


  checkRole() {
    return checkRole(['ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ADMIN']);
  }
}
