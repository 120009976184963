import { initialDocumentCategoryState, IDocumentCategoryState } from '../state/document-category.state';
import { DocumentCategoryActions, EDocumentCategoriesActions } from '../actions/document-categories.actions';

export const documentcategoryReducers = (state = initialDocumentCategoryState, action: DocumentCategoryActions): IDocumentCategoryState => {
  switch (action.type) {
    case EDocumentCategoriesActions.LOAD_DOCUMENT_CATEGORIES:
      return {
        ...state,
        loading: true
      };
    case EDocumentCategoriesActions.LOAD_DOCUMENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    case EDocumentCategoriesActions.LOAD_DOCUMENT_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case EDocumentCategoriesActions.GET_DOCUMENT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case EDocumentCategoriesActions.ADD_DOCUMENT_CATEGORY_SUCCESS: {
      state.paginator.member.push(action.payload);
      return {
        ...state
      };
    }
    case EDocumentCategoriesActions.UPDATE_DOCUMENT_CATEGORY_SUCCESS: {
      return {
        ...state
      };
    }

    case EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORY_SUCCESS: {
      return {
        ...state
      };
    }

    case EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORIES_SUCCESS: {
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
