import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { Paginator } from '@app/models/paginator';
import { INews } from '@app/models/interfaces/news.interface';

export const getNewsSelector = createFeatureSelector<Paginator<INews>>('newsState');

export const selectGetAllNews = createSelector(getNewsSelector, (state: Paginator<INews>) => state);

export const selectLastNews = createSelector(getNewsSelector, (state: Paginator<INews>) => {
  return [state.member[0]];
});

export const selectCurrentPageNews = (state: IAppState): number => state.newsState.currentPage;
