import { Action } from '@ngrx/store';
import { MatterFinance } from '@app/models/matter-extended';

export enum MatterCardActionsTypes {
  LOAD_MATTER_FINANCE = '[MATTER_FINANCE] Load Matter Finance',
  LOAD_MATTER_FINANCE_SUCCESS = '[MATTER_FINANCE] Load Matter Finance Success',
  LOAD_MATTER_FINANCE_FAILURE = '[MATTER_FINANCE] Load Matter Finance Failure'
}
export class LoadMatterFinanceAction implements Action {
  readonly type = MatterCardActionsTypes.LOAD_MATTER_FINANCE;

  constructor(public payload: number) {}
}
export class LoadMatterFinanceSuccessAction implements Action {
  readonly type = MatterCardActionsTypes.LOAD_MATTER_FINANCE_SUCCESS;

  constructor(public payload: MatterFinance) {}
}
export class LoadMatterFinanceFailureAction implements Action {
  readonly type = MatterCardActionsTypes.LOAD_MATTER_FINANCE_FAILURE;

  constructor(public payload: Error) {}
}

export type MatterCardActions = LoadMatterFinanceAction | LoadMatterFinanceSuccessAction | LoadMatterFinanceFailureAction;
