import { ContractActions, ContractActionsTypes } from '@app/store/actions/contract.actions';
import { initialContractState, ContractState } from '../state/contract.state';

export const ContractsReducer = (state = initialContractState, action: ContractActions): ContractState => {
  switch (action.type) {
    case ContractActionsTypes.LOAD_CONTRACT:
      return {
        ...state,
        loading: true
      };
    case ContractActionsTypes.LOAD_CONTRACT_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: true
      };
    case ContractActionsTypes.LOAD_CONTRACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case ContractActionsTypes.GET_CONTRACT:
      return {
        ...state,
        loading: true
      };
    case ContractActionsTypes.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        currentContract: action.payload,
        loading: true
      };
    case ContractActionsTypes.GET_CONTRACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case ContractActionsTypes.ADD_CONTRACT:
      return {
        ...state,
        loading: true
      };
    case ContractActionsTypes.ADD_CONTRACT_SUCCESS:
      if (state.list) {
        state.list.push(action.payload);
      }
      return {
        ...state,
        loading: true
      };
    case ContractActionsTypes.ADD_CONTRACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ContractActionsTypes.DELETE_CONTRACT:
      return {
        ...state,
        loading: true
      };
    case ContractActionsTypes.DELETE_CONTRACT_SUCCESS:
      return {
        ...state,
        // list: state.list.filter(item => item.id.toString() !== action.payload),
        loading: true
      };
    case ContractActionsTypes.DELETE_CONTRACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ContractActionsTypes.ADD_TO_STORE_CURRENT_CONTRACT:
      return {
        ...state,
        currentContract: action.payload
      };
    default:
      return state;
  }
};
