import { IAppState } from '@app/store/state/app.state';
import { Correspondence } from '@app/models/correspondence';

export const selectCorrespondencesList = (state: IAppState): Correspondence[] => Object.values(state.correspondenceState.correspondences);

export const selectCorrespondences = (state: IAppState) => state.correspondenceState.correspondences;

export const selectDropDownCorrespondences = (state: IAppState) => state.correspondenceState.correspondenceDropdown;

export const selectPaginationData = (state: IAppState) => state.correspondenceState.paginator;

export const selectTimeline = (state: IAppState) => state.correspondenceState.timeline;

export const selectSelectedId = (state: IAppState) => state.correspondenceState.selected;

export const selectTrackInfo = (state: IAppState) => state.correspondenceState.track;

export const selectNotifications = (state: IAppState) => state.correspondenceState.notifications;

export const selectSelectedCorrespondence = (state: IAppState) => state.correspondenceState.selected;
