import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, share, switchMap, catchError } from 'rxjs/operators';
import { TeamService } from '@app/modules/team/services/team.service';
import {
  ETeamActions,
  GetMyAndAnyoneWorkGroupsSuccess,
  GetAnyoneWithoutSystemWorkGroupsSuccess,
  GetAnyoneWorkGroupsSuccess,
  GetAnyoneWithoutSystemWorkGroups,
  GetMyAndAnyoneWorkGroups,
  GetAnyoneWorkGroups,
  GetWorkGroups,
  GetWorkGroupsSuccess,
  GetWorkGroupById,
  GetWorkGroupByIdSuccess,
  CreateWorkGroup,
  RemoveWorkGroup,
  UpdateWorkGroup,
  GetWorkGroupsWithFiltersSuccess,
  GetWorkGroupsWithFilters,
  CreateWorkGroupSuccess,
  CreateWorkGroupFailure,
  UpdateWorkGroupSuccess
} from '@app/modules/settings/store/actions/team.actions';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { CompanySettingService } from '../../services/company-setting.service';

@Injectable()
export class TeamEffects {

  getWorkGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetWorkGroups>(ETeamActions.GET_WORK_GROUPS),
      switchMap(action => {
        return this.teamService.getAll({'exists[matter]': 'false', 'exists[contact]': 'false', 'status': 'enabled'}).pipe(
          mergeMap(response => {
            return [new GetWorkGroupsSuccess(response)];
          })
        );
      }),
      share()
    );
  });

  getWorkGroupWithFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetWorkGroupsWithFilters>(ETeamActions.GET_WORK_GROUPS_WITH_FILTERS),
      switchMap(action => {
        return this.teamService.getAll(action.filters).pipe(
          mergeMap(response => {
            return [new GetWorkGroupsWithFiltersSuccess(response)];
          })
        );
      }),
      share()
    );
  });

  getWorkGroupById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetWorkGroupById>(ETeamActions.FETCH_TEAM_BY_ID),
      switchMap(action =>
        this.teamService.getTeamById(action.payload).pipe(mergeMap(response => (response && [new GetWorkGroupByIdSuccess(response)]) || []))
      )
    );
  });

  getMyAndAnyoneWorkGroups$ = createEffect( () => {
    return this.actions$.pipe(
      ofType<GetMyAndAnyoneWorkGroups>(ETeamActions.GET_MY_AND_ANYONE_WORK_GROUPS),
      switchMap(action => {
        return this.companySettingService.getCompanySetting().pipe(
          mergeMap(companyData => {
            let useSystemCompanyWorkGroup;
            useSystemCompanyWorkGroup = companyData?.useSystemCompanyWorkGroup;
            return this.teamService.getAll(
              {
                'exists[matter]': 'false', 'status': 'enabled', 'exists[contact]': 'false',
                systems: useSystemCompanyWorkGroup ? 'my-and-anyone' : 'anyone'}).pipe(
              mergeMap(response => {
                return [new GetMyAndAnyoneWorkGroupsSuccess(response)];
              })
            );
          })
        );
      }),
      share()
    );
  });

  getAnyoneWorkGroups$ = createEffect( () => {
    return this.actions$.pipe(
      ofType<GetAnyoneWorkGroups>(ETeamActions.GET_ANYONE_WORK_GROUPS),
      switchMap(action => {
        return this.teamService.getAll({systems: 'anyone', 'status': 'enabled' }).pipe(
          mergeMap(response => {
            return [new GetAnyoneWorkGroupsSuccess(response)];
          })
        );
      }),
      share()
    );
  });

  getAnyoneWithoutSystemWorkGroups$ = createEffect( () => {
    return this.actions$.pipe(
      ofType<GetAnyoneWithoutSystemWorkGroups>(ETeamActions.GET_ANYONE_WITHOUT_SYSTEM_WORK_GROUPS),
      switchMap(action => {
        return this.teamService.getAll({'exists[contact]': 'false', systems: 'anyone', isSystem: 'false', 'status': 'enabled' }).pipe(
          mergeMap(response => {
            return [new GetAnyoneWithoutSystemWorkGroupsSuccess(response)];
          })
        );
      }),
      share()
    );
  });

  createWorkGroup$ = createEffect(() => this.actions$.pipe(
    ofType<CreateWorkGroup>(ETeamActions.CREATE_TEAM),
    switchMap(action => {
      return this.teamService.createTeam(action.payload).pipe(
        mergeMap(response => {
          if (response.id) {
            this.toastrService.success(this.translate.instant('itemAdded'));
            this.updateListGroup();
            return [new CreateWorkGroupSuccess(response)];
          }
        }),
        catchError(error => {
          return of(new CreateWorkGroupFailure(error)); // TODO зробити обробник на помилку
        })
      );
    }),
    share()
  ));

  removeWorkGroup$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveWorkGroup>(ETeamActions.REMOVE_TEAM),
    map(action => {
      return this.teamService.deleteTeam(action.payload).subscribe(() => {
        this.toastrService.error(this.translate.instant('itemDeleted'));
        this.updateListGroup();
      });
    }),
    share()
  ), { dispatch: false });


  updateWorkGroup$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateWorkGroup>(ETeamActions.UPDATE_TEAM),
    switchMap(action => {
      const fromMatterCard = action.payload.fromMatterCard;
      const currentAction = action.payload.currentAction;
      delete action.payload.fromMatterCard;
      delete action.payload.currentAction;

      return this.teamService.updateTeam(action.id, action.payload).pipe(
        filter(x => !!x),
        mergeMap((team) => {
          if (!fromMatterCard) {
            this.showToastrAction(currentAction);
          }
          this.updateListGroup();
          return [new UpdateWorkGroupSuccess(team)];
        })
      );
    }),
    share()
  ));

  showToastrAction(action) {
    switch (action) {
      case 'addedToTheGroup':
      case 'returnedFromTheArchive':
      case 'addedToTheArchive':
        this.toastrService.success(this.translate.instant(action));
        break;
      default:
        this.toastrService.success(this.translate.instant('itemEdited'));
        break;
    }
  }

  updateListGroup() {
    this.store.dispatch(new GetWorkGroups());
    this.store.dispatch(new GetMyAndAnyoneWorkGroups());
    this.store.dispatch(new GetAnyoneWorkGroups());
    this.store.dispatch(new GetAnyoneWithoutSystemWorkGroups());
  }

  constructor(
    private toastrService: ToastrService,
    private actions$: Actions,
    private teamService: TeamService,
    private translate: TranslateService,
    private store: Store<IAppState>,
    private companySettingService: CompanySettingService
  ) {}
}
