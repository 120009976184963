import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap, catchError, tap, share, map } from 'rxjs/operators';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import {
  GetMatters,
  EMattersActions,
  GetMattersSuccess,
  GetMatter,
  GetMatterSuccess,
  AddMatter,
  AddMatterSuccess,
  UpdateMatter,
  UpdateMatterSuccess,
  DeleteMatter,
  DeleteMatterSuccess,
  DeleteMatterFailure,
  UpdateMatterFailure,
  AddMatterFailure,
  GetMatterFailure,
  GetMattersFailure,
  LoadMattersForListSuccess,
  LoadMattersForListFailure,
  LoadMattersForList,
  LoadAllMattersForList,
  LoadAllMattersForListSuccess,
  LoadAllMattersForListFailure,
  UpdateMattersForList,
  UpdateMattersForListSuccess,
  UpdateMattersForListFailure,
  UpdateAllMattersForList,
  UpdateAllMattersForListSuccess,
  UpdateAllMattersForListFailure
} from '../actions/matter.actions';
import { of } from 'rxjs';
import { MatterExtended } from '@app/models/matter-extended';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { LoadCompanySubSetting } from '@app/modules/settings/store/actions/company-setting.actions';
import { TagService } from '@app/api/tag.service';

@Injectable()
export class MatterEffects {

  getMatters$ = createEffect(() => this.actions$.pipe(
    ofType<GetMatters>(EMattersActions.GET_MATTERS),
    switchMap(action => {
      return this.matterService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetMattersSuccess(resp)];
        }),
        catchError(error => of(new GetMattersFailure(error)))
      );
    }),
    share()
  ));


  loadMattersForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadMattersForList>(EMattersActions.LOAD_MATTERS_FOR_LIST),
    switchMap(action => {
      return this.matterService.get(action.payload.page, action.payload.itemsPerPage, { ...action.payload.filters, status: 'open' }).pipe(
        mergeMap(resp => {
          return [new LoadMattersForListSuccess(resp.member)];
        }),
        catchError(error => of(new LoadMattersForListFailure(error)))
      );
    }),
    share()
  ));


  loadAllMattersForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadAllMattersForList>(EMattersActions.LOAD_ALL_MATTERS_FOR_LIST),
    switchMap(action => {
      return this.matterService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new LoadAllMattersForListSuccess(resp.member)];
        }),
        catchError(error => of(new LoadAllMattersForListFailure(error)))
      );
    }),
    share()
  ));


  getMatter$ = createEffect(() => this.actions$.pipe(
    ofType<GetMatter>(EMattersActions.GET_MATTER),
    switchMap(action => {
      if (action.payload == null) {
        return [new GetMatterSuccess(null)];
      }
      return this.matterService.getMatterById(action.payload).pipe(
        mergeMap(resp => {
          return [new GetMatterSuccess(resp as MatterExtended)];
        }),
        catchError(error => of(new GetMatterFailure(error)))
      );
    })
  ));


  addMatter$ = createEffect(() => this.actions$.pipe(
    ofType<AddMatter>(EMattersActions.ADD_MATTER),
    switchMap(action => {
      return this.matterService.setMatter(action.payload).pipe(
        switchMap((matter) => {


          return of(matter);
        }),
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('matterAdded'));
            return of(new AddMatterSuccess(x));
          }
          return of(new AddMatterFailure(new Error()));
        }),
        catchError(error => of(new AddMatterFailure(error)))
      );
    }),
    share()
  ));


  addMatterSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddMatterSuccess>(EMattersActions.ADD_MATTER_SUCCESS),
    tap(x => {
      this.refreshDataAfterChangeMatter();
      this.router.navigateByUrl(`/matter/${x.payload.id}`);
    })
  ), { dispatch: false });


  updateMatter$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateMatter>(EMattersActions.UPDATE_MATTER),
    switchMap(action => {
      return this.matterService.updateMatter(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('matterEdited'));
            return of(new UpdateMatterSuccess(x));
          }
          return of(new UpdateMatterFailure(new Error()));
        }),
        catchError(error => of(new UpdateMatterFailure(error)))
      );
    }),
    share()
  ));


  updateMattersForList$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateMattersForList>(EMattersActions.UPDATE_MATTERS_FOR_LIST),
    switchMap(action => {
      return this.matterService.getMatterById(action.payload).pipe(
        mergeMap(resp => {
          return [new UpdateMattersForListSuccess(resp as MatterExtended)];
        }),
        catchError(error => of(new UpdateMattersForListFailure(error)))
      );
    }),
    share()
  ));


  updateAllMattersForList$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateAllMattersForList>(EMattersActions.UPDATE_ALL_MATTERS_FOR_LIST),
    switchMap(action => {
      return this.matterService.getMatterById(action.payload).pipe(
        mergeMap(resp => {
          return [new UpdateAllMattersForListSuccess(resp as MatterExtended)];
        }),
        catchError(error => of(new UpdateAllMattersForListFailure(error)))
      );
    }),
    share()
  ));


  updateAllMattersForListSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateMatterSuccess>(EMattersActions.UPDATE_MATTER_SUCCESS),
    tap(() => {
      this.refreshDataAfterChangeMatter();
    })
  ), { dispatch: false });


  deleteMatter$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteMatter>(EMattersActions.DELETE_MATTER),
    switchMap(action => {
      return this.matterService.deleteMatter(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('matterDeleted'));
          return of(new DeleteMatterSuccess(action.payload));
        }),
        catchError(error => of(new DeleteMatterFailure(error)))
      );
    }),
    share()
  ));


  deleteMatterSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteMatterSuccess>(EMattersActions.DELETE_MATTER_SUCCESS),
    switchMap(() => {
      this.refreshDataAfterChangeMatter();
      return of(new GetMatterSuccess(null));
    })
  ), { dispatch: false });

  refreshDataAfterChangeMatter(): void {
    this.refreshMatters();
    this.store.dispatch(new LoadCompanySubSetting());
  }

  refreshMatters(): void {
    this.store.dispatch(new LoadAllMattersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
    this.store.dispatch(new LoadMattersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
  }

  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private store: Store<IAppState>,
    private matterService: MatterService,
    private router: Router,
    private translate: TranslateService,
    private tagService: TagService
  ) {}
}
