import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatterStrategy } from '@app/models/matter-strategy';
import { map } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { AuthService } from '@app/auth/_services/auth.service';
import { Paginator } from '@app/models/paginator';

@Injectable()
export class MatterModalService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  private BASE_URL = `${environment.apiUrl}/matter_estimates`;

  getInfo(id): Observable<Paginator<MatterStrategy>> {
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<MatterStrategy>>(this.BASE_URL + '?matter.id=' + `${id}`)
      .pipe(map(response => ({member: response['hydra:member']})));
    } else {
      return new Observable<any>();
    }
  }

  postInfo(data: MatterStrategy): Observable<MatterStrategy> {
    return this.http.post<MatterStrategy>(`${this.BASE_URL}`, data);
  }

  updateInfo(data: MatterStrategy): Observable<MatterStrategy> {
    return this.http.patch<MatterStrategy>(`${this.BASE_URL}/${data.id}`, data);
  }

  deleteInfo(id): Observable<any> {
    return this.http.delete<any>(`${this.BASE_URL}/${id}`).pipe(map(response => response));
  }
}
