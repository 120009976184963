import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/_services/auth.service';
import { Helpers } from '@app/helpers/helpers';
import { IUser, ResponseBodyFull } from '@app/models';
import { Contact } from '@app/models/contact';
import { ContactFilters } from '@app/models/contact-filters';
import { ContactRelationship } from '@app/models/contact-relationship';
import { Paginator } from '@app/models/paginator';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ContactService {
  constructor(private http: HttpClient, private authService: AuthService) {
  }

  static BASE_URL = `${environment.apiUrl}/contacts`;

  private URL_CONTACT_RELATIONSHIP = `${environment.apiUrl}/contact_relationships`;

  get(page = 1, itemsPerPage = environment.defaultItemsCount, filters: ContactFilters = null): Observable<Paginator<Contact>> {
    let copyFilters = JSON.parse(JSON.stringify(filters));
    copyFilters['order[fullName]'] = copyFilters['order[fullName]'] === '-' ? '' : copyFilters['order[fullName]'];

    let params = new HttpParams().set('partial', 'false');
    params = params.set('order[fullName]', 'asc');
    if (page && itemsPerPage) {
      params = params.set('page', page.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (copyFilters) {
      params = Helpers.populateFilters(params, copyFilters, true);
    }
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<Contact>>(ContactService.BASE_URL, { params })
        .pipe(map(response => ({ member: response['hydra:member'], ...response.pagination })));
      ;
    } else {
      return new Observable<Paginator<Contact>>();
    }
  }

  checkName(name) {
    return this.http.get<ResponseBodyFull<Contact>>(`${environment.apiUrl}/contacts?name=${name}`)
      .pipe(map(response => ({ member: response['hydra:member'] })));
  }

  checkInn(inn: string): Observable<ResponseBodyFull<Contact>> {
    return this.http.get<ResponseBodyFull<Contact>>(`${environment.apiUrl}/contacts?inn=${inn}`);
  }

  getById(id: number) {
    if (this.authService.currentUserValue) {
      return this.http.get<Contact>(`${ContactService.BASE_URL}/${id}`);
    } else {
      return new Observable<Contact>();
    }
  }

  getUserById(id: number) {
    return this.http.get<IUser>(`${`${environment.apiUrl}/users`}/${id}`);
  }

  getAll() {
    return this.http.get<Contact>(ContactService.BASE_URL);
  }

  setContact(data: Contact) {
    return this.http.post<Contact>(ContactService.BASE_URL, data);
  }

  removeContact(id: number) {
    return this.http.delete(`${ContactService.BASE_URL}/${id}`);
  }

  editContact(data: Contact, id) {
    return this.http.patch<Contact>(`${ContactService.BASE_URL}/${id}`, data);
  }

  reSendMail(id: number) {
    return this.http.patch<IUser>(`${environment.apiUrl}/users/${id}/resend_invite`, {});
  }

  getContactRelationship(
    currentPage = 1,
    itemsPerPage = environment.defaultItemsCount,
    filters: any = null,
  ): Observable<Paginator<ContactRelationship>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<ContactRelationship>>(this.URL_CONTACT_RELATIONSHIP, { params })
        .pipe(map(response => ({ member: response['hydra:member'], ...response.pagination })));
    } else {
      return new Observable<Paginator<ContactRelationship>>();
    }
  }

  getContactRelationshipById(id: number): Observable<ContactRelationship> {
    if (id == null) {
      return null;
    }

    if (this.authService.currentUserValue) {
      return this.http.get<ContactRelationship>(`${this.URL_CONTACT_RELATIONSHIP}/${id}`);
    } else {
      return new Observable<ContactRelationship>();
    }
  }

  deleteContactRelationship(id) {
    return this.http.delete(`${this.URL_CONTACT_RELATIONSHIP}/${id}`);
  }

  createContactRelationship(data: ContactRelationship): Observable<ContactRelationship> {
    return this.http.post<ContactRelationship>(`${this.URL_CONTACT_RELATIONSHIP}`, data);
  }

  updateContactRelationship(contactRelationship: ContactRelationship): Observable<ContactRelationship> {
    return this.http
      .patch<ContactRelationship>(`${this.URL_CONTACT_RELATIONSHIP}/${contactRelationship.id}`, contactRelationship);
  }

  getRelationTree(id, matterId?) {
    const mId = matterId ? '/' + matterId : '';

    if (this.authService.currentUserValue) {
      return this.http.get(`${environment.apiUrl}/contact_relationships/tree/${id}${mId}`);
    } else {
      return new Observable<any>();
    }
  }
}
