import { initialTimeEntryTypeState, ITimeEntryTypeState } from '../state/time-entry-type.state';
import { TimeEntryTypeActions, ETimeEntryTypesActions } from '../actions/time-entry-types.actions';

export const timeEntryTypeReducers = (state = initialTimeEntryTypeState, action: TimeEntryTypeActions): ITimeEntryTypeState => {
  switch (action.type) {
    case ETimeEntryTypesActions.LOAD_TIME_ENTRY_TYPES:
      return {
        ...state,
        loading: true
      };
    case ETimeEntryTypesActions.LOAD_TIME_ENTRY_TYPES_SUCCESS:
      return {
        ...state,
        list: {data: action.payload.member, success: true},
        loading: false
      };
    case ETimeEntryTypesActions.LOAD_TIME_ENTRY_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ETimeEntryTypesActions.GET_TIME_ENTRY_TYPES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case ETimeEntryTypesActions.ADD_TIME_ENTRY_TYPE_SUCCESS: {
      if (state.list) {
        state.list.data.push(action.payload);
      }
      return {
        ...state
      };
    }
    case ETimeEntryTypesActions.UPDATE_TIME_ENTRY_TYPE_SUCCESS: {
      state.list = {
        success: true,
        data: state.paginator.member
      };

      return {
        ...state
      };
    }

    case ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPES_SUCCESS: {
      if (state.list) {
        for (const payload of action.payload) {
          state.list.data = state.list.data.filter(elem => {
            return +elem.id !== +payload;
          });
        }
      }

      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
