import { Action } from '@ngrx/store';
import { Paginator } from '@app/models/paginator';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { environment } from '@environments/environment';
import { BillingPreferences } from '@app/models/interfaces/billing-preferences';
import { Country, IUser } from '@app/models';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';

export enum ETeamMemberActions {
  GET_TEAM_MEMBERS = '[TEAM MEMBER] Fetch',
  GET_TEAM_MEMBERS_SUCCESS = '[TEAM MEMBER] Fetched',
  GET_TEAM_MEMBER_BY_ID = '[TEAM MEMBER] Fetch by id',
  GET_TEAM_MEMBER_BY_ID_SUCCESS = '[TEAM MEMBER] Fetched by id',
  SELECT = '[TEAM MEMBER] Select',
  INVITE = '[TEAM MEMBER] Invite',
  INVITED = '[TEAM MEMBER] Invited',
  UPDATE_TEAM_MEMBER = '[TEAM MEMBER] UpdateTeamMember',
  UPDATE_TEAM_MEMBER_SUCCESS = '[TEAM MEMBER] UpdateTeamMemberSuccess',
  UPDATE_TEAM_MEMBER_PREFERENCES = '[TEAM MEMBER] UpdateTeamMemberPreferences',
  FETCH_USER_ROLES = '[TEAM MEMBER] Fetch team member role',
  FETCHED_USER_ROLES_SUCCESS = '[TEAM MEMBER] Fetched team member role success',

  LOAD_USERS_FOR_LIST = '[TEAM MEMBER] Load Users for list',
  LOAD_USERS_FOR_LIST_SUCCESS = '[TEAM MEMBER] Load Users for list Success',
  LOAD_USERS_FOR_LIST_FAILURE = '[TEAM MEMBER] Load Users for list Failure',

  UPDATE_USERS_FOR_LIST = '[TEAM MEMBER] Update Users for list',
  UPDATE_USERS_FOR_LIST_SUCCESS = '[TEAM MEMBER] Update Users for list Success',
  UPDATE_USERS_FOR_LIST_FAILURE = '[TEAM MEMBER] Update Users for list Failure',

  LOAD_USERS_FOR_ENTITY_LIST = '[TEAM MEMBER] Load Users for Entity list',
  LOAD_USERS_FOR_ENTITY_LIST_SUCCESS = '[TEAM MEMBER] Load Users for Entity list Success',
  LOAD_USERS_FOR_ENTITY_LIST_FAILURE = '[TEAM MEMBER] Load Users for Entity list Failure',

  LOAD_ALL_USERS_FOR_LIST = '[TEAM MEMBER] Load All Users for list',
  LOAD_ALL_USERS_FOR_LIST_SUCCESS = '[TEAM MEMBER] Load All Users for list Success',
  LOAD_ALL_USERS_FOR_LIST_FAILURE = '[TEAM MEMBER] Load All Users for list Failure',

  LOAD_ALL_USERS_FOR_REPORTS_LIST = '[TEAM MEMBER] Load All Users for reports list',
  LOAD_ALL_USERS_FOR_REPORTS_LIST_SUCCESS = '[TEAM MEMBER] Load All Users for reports list Success',
  LOAD_ALL_USERS_FOR_REPORTS_LIST_FAILURE = '[TEAM MEMBER] Load All Users for reports list Failure',

  UPDATE_ALL_USERS_FOR_LIST = '[TEAM MEMBER] Update All Users for list',
  UPDATE_ALL_USERS_FOR_LIST_SUCCESS = '[TEAM MEMBER] Update All Users for list Success',
  UPDATE_ALL_USERS_FOR_LIST_FAILURE = '[TEAM MEMBER] Update All Users for list Failure',

  LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST = '[TEAM MEMBER] Load All Administrators And Accounts for list',
  LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST_SUCCESS = '[TEAM MEMBER] Load All Administrators And Accounts for list Success',
  LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST_FAILURE = '[TEAM MEMBER] Load All Administrators And Accounts for list Failure',

  LOAD_COUNTRIES = '[TEAM MEMBER] Load Countries for list',
  LOAD_COUNTRIES_SUCCESS = '[TEAM MEMBER] Load Countries for list Success',
  LOAD_COUNTRIES_FAILURE = '[TEAM MEMBER] Load Countries for list Failure',

  CLEAR_TEAM_MEMBERS = '[TEAM MEMBER] Clear Team Members',
  CLEAN_SELECTED_MEMBER = '[TEAM MEMBER] Clean Selected Member',

  UPDATE_USER = '[TEAM MEMBER] Update User',
  UPDATE_USER_SUCCESS = '[TEAM MEMBER] Update User Success',
  UPDATE_USER_FAILURE = '[TEAM MEMBER] Update User Failure',
}
export class LoadCountries implements Action {
  public readonly type = ETeamMemberActions.LOAD_COUNTRIES;

  constructor() {
  }
}

export class LoadCountriesSuccess implements Action {
  public readonly type = ETeamMemberActions.LOAD_COUNTRIES_SUCCESS;

  constructor(public payload: Country[]) {}
}
export class LoadCountriesFailure implements Action {
  public readonly type = ETeamMemberActions.LOAD_COUNTRIES_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadUsersForList implements Action {
  public readonly type = ETeamMemberActions.LOAD_USERS_FOR_LIST;

  constructor(public payload: FetchPayload<TeamMemberFilter>) {}
}
export class LoadUsersForListSuccess implements Action {
  public readonly type = ETeamMemberActions.LOAD_USERS_FOR_LIST_SUCCESS;

  constructor(public payload: IUser[]) {}
}
export class LoadUsersForListFailure implements Action {
  public readonly type = ETeamMemberActions.LOAD_USERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateUsersForList implements Action {
  public readonly type = ETeamMemberActions.UPDATE_USERS_FOR_LIST;

  constructor(public payload: number) {}
}
export class UpdateUsersForListSuccess implements Action {
  public readonly type = ETeamMemberActions.UPDATE_USERS_FOR_LIST_SUCCESS;

  constructor(public payload: IUser) {}
}
export class UpdateUsersForListFailure implements Action {
  public readonly type = ETeamMemberActions.UPDATE_USERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadUsersForEntityList implements Action {
  public readonly type = ETeamMemberActions.LOAD_USERS_FOR_ENTITY_LIST;

  constructor(public payload: FetchPayload<TeamMemberFilter>) {}
}
export class LoadUsersForEntityListSuccess implements Action {
  public readonly type = ETeamMemberActions.LOAD_USERS_FOR_ENTITY_LIST_SUCCESS;

  constructor(public payload: IUser[]) {}
}
export class LoadUsersForEntityListFailure implements Action {
  public readonly type = ETeamMemberActions.LOAD_USERS_FOR_ENTITY_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadAdministratorsAndAccountsForList implements Action {
  public readonly type = ETeamMemberActions.LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST;

  constructor(public payload: FetchPayload<TeamMemberFilter>) {}
}
export class LoadAdministratorsAndAccountsForListSuccess implements Action {
  public readonly type = ETeamMemberActions.LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST_SUCCESS;

  constructor(public payload: IUser[]) {}
}
export class LoadAdministratorsAndAccountsForListFailure implements Action {
  public readonly type = ETeamMemberActions.LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadAllUsersForList implements Action {
  public readonly type = ETeamMemberActions.LOAD_ALL_USERS_FOR_LIST;

  constructor(public payload: FetchPayload<TeamMemberFilter>) {}
}
export class LoadAllUsersForListSuccess implements Action {
  public readonly type = ETeamMemberActions.LOAD_ALL_USERS_FOR_LIST_SUCCESS;

  constructor(public payload: IUser[]) {}
}
export class LoadAllUsersForListFailure implements Action {
  public readonly type = ETeamMemberActions.LOAD_ALL_USERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadAllUsersForReportsList implements Action {
  public readonly type = ETeamMemberActions.LOAD_ALL_USERS_FOR_REPORTS_LIST;

  constructor(public payload: FetchPayload<TeamMemberFilter>) {}
}
export class LoadAllUsersForReportsListSuccess implements Action {
  public readonly type = ETeamMemberActions.LOAD_ALL_USERS_FOR_REPORTS_LIST_SUCCESS;

  constructor(public payload: IUser[]) {}
}
export class LoadAllUsersForReportsListFailure implements Action {
  public readonly type = ETeamMemberActions.LOAD_ALL_USERS_FOR_REPORTS_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateAllUsersForList implements Action {
  public readonly type = ETeamMemberActions.UPDATE_ALL_USERS_FOR_LIST;

  constructor(public payload: number) {}
}
export class UpdateAllUsersForListSuccess implements Action {
  public readonly type = ETeamMemberActions.UPDATE_ALL_USERS_FOR_LIST_SUCCESS;

  constructor(public payload: IUser) {}
}
export class UpdateAllUsersForListFailure implements Action {
  public readonly type = ETeamMemberActions.UPDATE_ALL_USERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class GetTeamMembers implements Action {
  readonly type = ETeamMemberActions.GET_TEAM_MEMBERS;

  constructor(
    public payload: FetchPayload<TeamMemberFilter> = { page: environment.defaultPage, itemsPerPage: environment.defaultItemsCount }
  ) {}
}
export class ClearTeamMembers implements Action {
  readonly type = ETeamMemberActions.CLEAR_TEAM_MEMBERS;

  constructor() {}
}
export class CleanSelectedMember implements Action {
  readonly type = ETeamMemberActions.CLEAN_SELECTED_MEMBER;

  constructor() {}
}

export class Fetched implements Action {
  readonly type = ETeamMemberActions.GET_TEAM_MEMBERS_SUCCESS;

  constructor(public payload: Paginator<IUser>) {}
}


export class FetchById implements Action {
  public readonly type = ETeamMemberActions.GET_TEAM_MEMBER_BY_ID;

  constructor(public payload: number) {}
}

export class FetchUserRoleById implements Action {
  public readonly type = ETeamMemberActions.FETCH_USER_ROLES;

  constructor(public payload: number) {}
}

export class FetchedUserRoleById implements Action {
  public readonly type = ETeamMemberActions.FETCHED_USER_ROLES_SUCCESS;

  constructor(public payload) {}
}

export class FetchedById implements Action {
  public readonly type = ETeamMemberActions.GET_TEAM_MEMBER_BY_ID_SUCCESS;

  constructor(public payload) {}
}

export class SelectTeamMember implements Action {
  readonly type = ETeamMemberActions.SELECT;

  constructor(public payload: number) {}
}

export class Invite implements Action {
  readonly type = ETeamMemberActions.INVITE;

  constructor(public payload) {}
}

export class Invited implements Action {
  readonly type = ETeamMemberActions.INVITED;

  constructor(public payload) {}
}

export class UpdateTeamMember implements Action {
  readonly type = ETeamMemberActions.UPDATE_TEAM_MEMBER;

  constructor(public payload, public id: number) {}
}

export class UpdateTeamMemberSuccess implements Action {
  readonly type = ETeamMemberActions.UPDATE_TEAM_MEMBER_SUCCESS;

  constructor(public payload: IUser) {}
}

export class UpdateTeamMemberPreferences implements Action {
  readonly type = ETeamMemberActions.UPDATE_TEAM_MEMBER_PREFERENCES;

  constructor(public payload: BillingPreferences, public id: number) {}
}

export class UpdateUser implements Action {
  readonly type = ETeamMemberActions.UPDATE_USER;

  constructor(public userId: number, public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = ETeamMemberActions.UPDATE_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateUserFailure implements Action {
  readonly type = ETeamMemberActions.UPDATE_USER_FAILURE;

  constructor(public payload: any) {}
}


export type TeamMemberActions =
  | Fetched
  | GetTeamMembers
  | SelectTeamMember
  | FetchedById
  | FetchById
  | Invite
  | Invited
  | UpdateTeamMember
  | UpdateTeamMemberPreferences
  | UpdateTeamMemberSuccess
  | FetchUserRoleById
  | FetchedUserRoleById
  | LoadUsersForList
  | LoadUsersForListSuccess
  | LoadUsersForListFailure
  | UpdateUsersForList
  | UpdateUsersForListSuccess
  | UpdateUsersForListFailure
  | LoadAllUsersForList
  | LoadAllUsersForListSuccess
  | LoadAllUsersForListFailure
  | LoadAllUsersForReportsList
  | LoadAllUsersForReportsListSuccess
  | LoadAllUsersForReportsListFailure
  | UpdateAllUsersForList
  | UpdateAllUsersForListSuccess
  | UpdateAllUsersForListFailure
  | LoadCountries
  | LoadCountriesSuccess
  | LoadCountriesFailure
  | LoadAdministratorsAndAccountsForList
  | LoadAdministratorsAndAccountsForListSuccess
  | LoadAdministratorsAndAccountsForListFailure
  | ClearTeamMembers
  | CleanSelectedMember
  | LoadUsersForEntityList
  | LoadUsersForEntityListSuccess
  | LoadUsersForEntityListFailure;
