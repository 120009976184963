import { Component, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { TranslateService } from '@ngx-translate/core';
import { TimeFormatter } from '@app/helpers';
import { saveAs } from 'file-saver';
import { environment } from '@environments/environment';
// import ExcelJS from "exceljs";

const ExcelJS = require('exceljs');

@Component({
  selector: 'app-paginator',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input()
  get pageIndex(): number {
    return this.localPageIndex;
  }
  set pageIndex(value: number) {
    this.localPageIndex = Math.max(value, 0);
  }

  @Input()
  get length(): number {
    return this.localLength;
  }
  set length(value) {
    value = value || environment.defaultItemsCount;
    // TODO при зміні кількості сторінок переходии на першу сторінку
    this.localLength = value;
    this.pager = this.getPager(this.pageIndex + 1);
  }
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private timeFormatter: TimeFormatter,
    private router: Router,
    private translate: TranslateService
  ) {
    this.pageSize = this.loadPerPageFromLocalStorage();
  }

  set type(data) {
    this.listType = data;
  }
  perPageArray: number[] = [25, 50, 100, 500];
  pageSize: number = this.loadPerPageFromLocalStorage();
  pager;
  @Output() page = new EventEmitter<number>();

  private localLength = 0;
  private localPageIndex = 0;

  private listType;


  @Input() columns;

  setPerPage(index: number) {
    this.pageSize = this.perPageArray[index];
    this.pageIndex = 0;
    this.pager = this.getPager(this.pageIndex + 1);
    this.page.emit(this.pageIndex);
    this.syncPerPageWithLocalStorage();
    window.scroll(0, 0);
  }

  getPager(currentPage: number = 1) {
    const totalPages = Math.ceil(this.length / this.pageSize);
    if (currentPage > totalPages) {
      currentPage = 1;
      this.setPage(1);
    }

    let startPage: number;
    let endPage: number;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);

    return {
      currentPage,
      totalPages,
      startPage,
      endPage,
      pages
    };
  }

  nextPage() {
    if (this.pager.currentPage === this.pager.totalPages) {
      return;
    }
    this.pageIndex = this.pageIndex + 1;
    this.pager = this.getPager(this.pageIndex + 1);
    this.navigateToPage(this.pageIndex + 1);
    window.scroll(0, 0);
  }

  previousPage() {
    if (this.pager.currentPage === 1) {
      return;
    }
    this.pageIndex = this.pageIndex - 1;
    this.pager = this.getPager(this.pageIndex + 1);
    this.navigateToPage(this.pageIndex + 1);
    window.scroll(0, 0);
  }

  setPage(page: number) {
    this.pageIndex = page - 1;
    this.pager = this.getPager(this.pageIndex + 1);
    this.navigateToPage(page);
    window.scroll(0, 0);
    /*this._cdr.detectChanges();*/
    /*this._changePageSize(this.pageSize);*/
  }

  private navigateToPage(page: number) {
    this.router.navigate([], {
      queryParams: {
        page
      },
      queryParamsHandling: 'merge'
    });
  }

  syncPerPageWithLocalStorage() {
    localStorage.setItem('paginationPerPage', this.pageSize.toString());
  }

  loadPerPageFromLocalStorage() {
    return +localStorage.getItem('paginationPerPage') || environment.defaultItemsCount;
  }
  exportingtoXlsx(type) {
    if (this.listType && this.columns.length) {
      let table;
      table = this.createTable();
      const fileName = this.translate.instant(this.listType + '-export') + '(' + CustomDateAdapter.today() + ')';


      // /* generate workbook and add the worksheet */
      if (type === 'xlsx') {
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // /* save to file */
        // XLSX.writeFile(wb, `${fileName}.xls`);
        // this.exportToXls(table, "Sheet 1", `${fileName}.xls`);
        this.textExcelExport(fileName);
      }
      if (type === 'cvs') {
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {raw: true, display: true});
        const CSV_TYPE = 'text/csv;charset=utf-8';
        const CSV_EXTENSION = '.csv';
        const csvOutput: string = XLSX.utils.sheet_to_csv(ws);
        saveAs(new Blob([csvOutput], { type: CSV_TYPE }), fileName + CSV_EXTENSION);
      }
    }
  }

  exportToXls(table, name, filename) {
    let uri = 'data:application/vnd.ms-excel;base64,';
    let template = `
    <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>
    <body><table>{table}</table></body>
    </html>`
    let base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)))
    }
    let format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        })
    }
    let ctx = {
        worksheet: name || 'Worksheet',
        table: table.innerHTML
    }
    const link = document.getElementById("dlink") as any;
    link.href = uri + base64(format(template, ctx));
    link.download = filename;
    link.traget = "_blank";
    link.click();
  }

  createTable() {
    const table = document.createElement('table');
    const trTitle = document.createElement('tr');
    // формування заголовків
    for (const col of this.columns) {
      if (col?.visible) {
        const th = document.createElement('th');
        th.innerHTML = col.name ? this.translate.instant(col.name) : '';
        trTitle.appendChild(th);
      }
    }
    table.appendChild(trTitle);

    for (let i = 0; i < this.columns[0].value.length; i++) {
      const trRows = document.createElement('tr');

        for (const col of this.columns) {
          if (col?.visible) {
            const tdN = document.createElement('td');
            const item = col.value[i];
            switch (col.key) {
              case 'type':
              case 'task-date':
                tdN.innerHTML = this.translate.instant(col.exportValue[i]);
                break;
              case 'tags':
                let value = '';
                if (col.value[i]?.name) {
                  for (let index = 0; index < col.value[i].name.length; index++) {
                    value += col.value[i].name[index].tag.name + (index !== col.value[i].name.length - 1 ? ', ' : '')
                  }
                }
                tdN.innerHTML = value;
              break;

              case 'date':
              case 'matter-date':
              case 'dateInvoice':
              case 'dateExpire':
              case 'birthDate':
              case 'documentDate':
              case 'media-createdAt':
              case 'estimate-endDate':
                tdN.innerHTML = (item?.name || item).split('.').length > 2 ?  CustomDateAdapter.convertToDateWithDefaulFormat((item?.name || item) as any) : (item?.name || item);
                break;
              default:
                tdN.innerHTML = typeof item === 'object' ?
                (item.name === '-' ? '' : item.name) :
                ((item === '-' || !item) ? '' : item);
                if (!isNaN(item) || !isNaN(item?.name)) {
                  tdN.innerHTML = ((item?.name || item) + '').replace('.', ',');
                }
                break;
            }
            trRows.appendChild(tdN);
          }
        }
      table.appendChild(trRows);
    }

    // створення тоталів
    const totals = document.getElementById('row-total')?.cloneNode(true);
    if (totals) {
      totals.removeChild(totals.lastChild)
      table.appendChild(totals);
    }
    return table;
  }


  async textExcelExport(fileName) {
    const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");
      const exportColumns = [];
      for (const col of this.columns) {
        if (col.visible) {
          exportColumns.push({
            header: col.name ? this.translate.instant(col.name) : '', key: col.key, width: 30
          })
        }
      }

      worksheet.columns = [...exportColumns];

      const numberColls = [];
      for (let i = 0; i < this.columns[0].value.length; i++) {
        const newRow = {};

          for (const col of this.columns) {
            if (col?.visible) {
              const tdN = document.createElement('td');
              const item = col.value[i];
              switch (col.key) {
                case 'type':
                case 'task-date':
                  newRow[col.key] = this.translate.instant(col.exportValue[i]);
                  break;
                case 'tags':
                  let value = '';
                  if (col.value[i]?.name) {
                    for (let index = 0; index < col.value[i].name.length; index++) {
                      value += col.value[i].name[index].tag.name + (index !== col.value[i].name.length - 1 ? ', ' : '')
                    }
                  }
                  newRow[col.key] = value;
                break;

                case 'date':
                case 'matter-date':
                case 'dateInvoice':
                case 'dateExpire':
                case 'birthDate':
                case 'documentDate':
                case 'media-createdAt':
                case 'estimate-endDate':
                  newRow[col.key] = (item?.name || item).split('.').length > 2 ?  CustomDateAdapter.convertToDateWithDefaulFormat((item?.name || item) as any) : (item?.name || item);
                  break;
                default:
                  newRow[col.key] = typeof item === 'object' ?
                  (item.name === '-' ? '' : item.name) :
                  ((item === '-' || !item) ? '' : item);
                  if (!isNaN(item) || !isNaN(item?.name)) {
                    numberColls.push(col.key);
                    newRow[col.key] = +(item?.name || item); // + ''); //.replace('.', ',');
                  }
                  break;
              }

            }

          }
          worksheet.addRow(newRow)

      }

      for (const item of numberColls) {
        worksheet.getColumn(item).numFmt = '#,##0.00';
      }

    const totals = document.getElementById('row-total')?.cloneNode(true) as HTMLTableRowElement;
    const totalRow = {};
    if (totals) {
      totals.removeChild(totals.lastChild);
      for (let i = 0; i < totals.children.length; i++) {
        totalRow[totals.children[i].id] = (totals.children[i] as HTMLTableCellElement).innerText.replace(/\s/g, '');
      }
      worksheet.addRow(totalRow);
    }
    // Збереження файлу
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `${fileName}.xlsx`
    );
  }

  checkVisibleExports() {
    if (this.listType === 'trash' || this.listType === 'team' || this.listType === 'automation') {
      return false;
    }

    return true;
  }
}
