// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.invalid-validation {
  border-color: #e03b4b;
}

.input-container.disabled {
  pointer-events: none;
}

.security-info {
  text-align: center;
}
.security-info .link {
  color: #1252AF;
  cursor: pointer;
}

:host button {
  margin-top: 0;
}
:host .router-tag {
  margin-bottom: 25px;
}
:host form {
  position: relative;
  height: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/components/login/login.component.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;AAAF;;AAEA;EACE,oBAAA;AACF;;AAEA;EAKE,kBAAA;AAHF;AADE;EACE,cAAA;EACA,eAAA;AAGJ;;AAGE;EACE,aAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAGE;EACE,kBAAA;EACA,eAAA;AADJ","sourcesContent":["\ninput.invalid-validation {\n  border-color: #e03b4b;\n}\n.input-container.disabled {\n  pointer-events: none;\n}\n\n.security-info {\n  .link {\n    color: #1252AF;\n    cursor: pointer;\n  }\n  text-align: center;\n}\n\n:host {\n  button {\n    margin-top: 0;\n  }\n\n  .router-tag {\n    margin-bottom: 25px;\n  }\n  form {\n    position: relative;\n    height: inherit;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
