import { MatterExtended, MatterFinance } from '@app/models/matter-extended';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ContactFinance } from '@app/models';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { SelectCreateMatter } from '@app/store/selectors/matter.selector';
import { AuthService } from '@app/auth/_services/auth.service';

@Injectable()
export class MatterCardService {
  constructor(private http: HttpClient, private store: Store<IAppState>, private authService: AuthService) {}
  private matter = new Subject<MatterExtended>();

  getMatter$ = this.matter.asObservable();

  addNode(data: MatterExtended) {
    this.matter.next(data);
  }
  //  {{host}}/matters/13/dashboard_report?currency=UAH
  getFinance(id) {
    let currency;

    this.store.select(SelectCreateMatter).subscribe(matter => {
      if (matter) {
        currency = this.getCurrency(matter.currencyBill);
      }
    });

    if (this.authService.currentUserValue) {
      return this.http
        .get<MatterFinance>(`${environment.apiUrl}/matters/${id}/dashboard_report?currency=${currency}`);
    } else {
      return new Observable<MatterFinance>();
    }
  }
  getCurrency(currency) {
    if (currency && typeof currency === 'object') {
      return currency.code;
    } else {
      return currency;
    }
  }
  getContactFinance(id) {
    return this.http
      .get<ContactFinance>(`${environment.apiUrl}/contacts/${id}/dashboard_report`);
  }
}
