import { Action } from '@ngrx/store';
import { Team } from '@app/models/team';
import { Paginator } from '@app/models/paginator';

export enum ETeamActions {
  GET_WORK_GROUPS = '[TEAM] Get work groups',
  GET_WORK_GROUPS_SUCCESS = '[TEAM] Get work groups success',
  GET_WORK_GROUPS_WITH_FILTERS = '[TEAM] Get work groups with filters',
  GET_WORK_GROUPS_WITH_FILTERS_SUCCESS = '[TEAM] Get work groups with filters success',
  GET_MY_AND_ANYONE_WORK_GROUPS = '[TEAM] Get my and anyone work groups',
  GET_MY_AND_ANYONE_WORK_GROUPS_SUCCESS = '[TEAM] Get my and anyone work groups success',
  GET_ANYONE_WORK_GROUPS = '[TEAM] Get anyone work groups',
  GET_ANYONE_WORK_GROUPS_SUCCESS = '[TEAM] Get anyone work groups success',
  GET_ANYONE_WITHOUT_SYSTEM_WORK_GROUPS = '[TEAM] Get anyone without system work groups',
  GET_ANYONE_WITHOUT_SYSTEM_WORK_GROUPS_SUCCESS = '[TEAM] Get anyone without system work groups success',
  CREATE_TEAM = '[TEAM] Create team',
  CREATE_TEAM_SUCCESS = '[TEAM] Created team success',
  CREATE_TEAM_FAILURE = '[TEAM] Created team Failure',
  UPDATE_TEAM = '[TEAM] Update team',
  UPDATE_TEAM_SUCCESS = '[TEAM] Updated team success',
  UPDATE_TEAM_FAILURE = '[TEAM] Updated team Failure',
  FETCH_TEAM_BY_ID = '[TEAM] Fetch team by id',
  FETCH_TEAM_BY_ID_SUCCESS = '[TEAM] Fetched team by id success',
  FETCH_TEAM_BY_ID_FAILURE = '[TEAM] Fetched team by id Failure',
  REMOVE_TEAM = '[TEAM] Remove team',
}

export class GetWorkGroups implements Action {
  readonly type = ETeamActions.GET_WORK_GROUPS;
}

export class GetWorkGroupsSuccess implements Action {
  readonly type = ETeamActions.GET_WORK_GROUPS_SUCCESS;

  constructor(public payload: Paginator<Team>) {}
}

export class GetWorkGroupsWithFilters implements Action {
  readonly type = ETeamActions.GET_WORK_GROUPS_WITH_FILTERS;
  constructor(public filters = {}) {}

}

export class GetWorkGroupsWithFiltersSuccess implements Action {
  readonly type = ETeamActions.GET_WORK_GROUPS_WITH_FILTERS_SUCCESS;

  constructor(public payload: Paginator<Team>) {}
}


export class GetMyAndAnyoneWorkGroups implements Action {
  readonly type = ETeamActions.GET_MY_AND_ANYONE_WORK_GROUPS;
}

export class GetMyAndAnyoneWorkGroupsSuccess implements Action {
  readonly type = ETeamActions.GET_MY_AND_ANYONE_WORK_GROUPS_SUCCESS;

  constructor(public payload: Paginator<Team>) {}
}

export class GetAnyoneWorkGroups implements Action {
  readonly type = ETeamActions.GET_ANYONE_WORK_GROUPS;
}

export class GetAnyoneWorkGroupsSuccess implements Action {
  readonly type = ETeamActions.GET_ANYONE_WORK_GROUPS_SUCCESS;

  constructor(public payload: Paginator<Team>) {}
}

export class GetAnyoneWithoutSystemWorkGroups implements Action {
  readonly type = ETeamActions.GET_ANYONE_WITHOUT_SYSTEM_WORK_GROUPS;
}

export class GetAnyoneWithoutSystemWorkGroupsSuccess implements Action {
  readonly type = ETeamActions.GET_ANYONE_WITHOUT_SYSTEM_WORK_GROUPS_SUCCESS;

  constructor(public payload: Paginator<Team>) {}
}

export class CreateWorkGroup implements Action {
  readonly type = ETeamActions.CREATE_TEAM;

  constructor(public payload: Team) {}
}

export class CreateWorkGroupSuccess implements Action {
  readonly type = ETeamActions.CREATE_TEAM_SUCCESS;

  constructor(public payload: Team) {}
}

export class CreateWorkGroupFailure implements Action {
  readonly type = ETeamActions.CREATE_TEAM_FAILURE;

  constructor(public error: Error) {}
}

export class RemoveWorkGroup implements Action {
  public readonly type = ETeamActions.REMOVE_TEAM;

  constructor(public payload: number) {}
}

export class UpdateWorkGroup implements Action {
  readonly type = ETeamActions.UPDATE_TEAM;

  constructor(public id: number, public payload) {}
}

export class UpdateWorkGroupSuccess implements Action {
  readonly type = ETeamActions.UPDATE_TEAM_SUCCESS;

  constructor(public payload: Team) {}
}

export class UpdateWorkGroupFailure implements Action {
  readonly type = ETeamActions.UPDATE_TEAM_FAILURE;

  constructor(public error: Error) {}
}

export class GetWorkGroupById implements Action {
  public readonly type = ETeamActions.FETCH_TEAM_BY_ID;

  constructor(public payload: number) {}
}

export class GetWorkGroupByIdSuccess implements Action {
  public readonly type = ETeamActions.FETCH_TEAM_BY_ID_SUCCESS;

  constructor(public payload: Team) {}
}

export class GetWorkGroupByIdFailure implements Action {
  public readonly type = ETeamActions.FETCH_TEAM_BY_ID_FAILURE;

  constructor(public error: Error) {}
}


export type TeamActions =
  | GetMyAndAnyoneWorkGroups
  | GetMyAndAnyoneWorkGroupsSuccess
  | GetWorkGroupsWithFilters
  | GetWorkGroupsWithFiltersSuccess
  | GetAnyoneWorkGroups
  | GetAnyoneWorkGroupsSuccess
  | GetAnyoneWithoutSystemWorkGroups
  | GetAnyoneWithoutSystemWorkGroupsSuccess
  | GetWorkGroupsSuccess
  | GetWorkGroups
  | CreateWorkGroup
  | CreateWorkGroupSuccess
  | CreateWorkGroupFailure
  | UpdateWorkGroup
  | UpdateWorkGroupSuccess
  | UpdateWorkGroupFailure
  | GetWorkGroupById
  | GetWorkGroupByIdSuccess
  | GetWorkGroupByIdFailure
  | RemoveWorkGroup;
