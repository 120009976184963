import { Action } from '@ngrx/store';
import { DocumentExtended } from '@app/models';
import { Paginator } from '@app/models/paginator';
import { DocumentsFetch } from '@app/models/interfaces/documents-fetch';
import { DocumentSave } from '@app/models/document-save';
import { DocumentsFilters } from '@app/models/documents-filters';
import { DocRelation } from '@app/models/doc-relation';
import { ReminderExtended } from '@app/models/reminder-extended';

export enum EDocumentsActions {
  GET_DOCUMENTS = '[Documents] Get Documents',
  GET_DOCUMENTS_SUCCESS = '[Documents] Get Documents Success',
  GET_DOCUMENTS_FAILURE = '[Documents] Get Documents Failure',

  GET_DOCUMENTS_BY_ACTIVITY = '[Documents] Get Documents By Activity',
  GET_DOCUMENTS_BY_ACTIVITY_SUCCESS = '[Documents] Get Documents By Activity Success',
  GET_DOCUMENTS_BY_ACTIVITY_FAILURE = '[Documents] Get Documents By Activity Failure',

  GET_DOCUMENTS_BY_TASK = '[Documents] Get Documents By Task',
  GET_DOCUMENTS_BY_TASK_SUCCESS = '[Documents] Get Documents By Task Success',
  GET_DOCUMENTS_BY_TASK_FAILURE = '[Documents] Get Documents By Task Failure',
  CLEAR_DOCUMENTS_BY_TASK  = '[Documents] Clear Documents By Task',

  GET_DOCUMENTS_BY_CORRESPONDENCE = '[Documents] Get Documents By Correspondence',
  GET_DOCUMENTS_BY_CORRESPONDENCE_SUCCESS = '[Documents] Get Documents By Correspondence Success',
  GET_DOCUMENTS_BY_CORRESPONDENCE_FAILURE = '[Documents] Get Documents By Correspondence Failure',

  GET_DOCUMENTS_BY_CONTRACT = '[Documents] Get Documents By Contract',
  GET_DOCUMENTS_BY_CONTRACT_SUCCESS = '[Documents] Get Documents By Contract Success',
  GET_DOCUMENTS_BY_CONTRACT_FAILURE = '[Documents] Get Documents By Contract Failure',

  GET_DOCUMENTS_BY_BILL = '[Documents] Get Documents By Bill',
  GET_DOCUMENTS_BY_BILL_SUCCESS = '[Documents] Get Documents By Bill Success',
  GET_DOCUMENTS_BY_BILL_FAILURE = '[Documents] Get Documents By Bill Failure',

  GET_DOCUMENT = '[Documents] Get Document',
  GET_DOCUMENT_SUCCESS = '[Documents] Get Document Success',
  GET_DOCUMENT_FAILURE = '[Documents] Get Document Failure',

  ADD_DOCUMENT = '[Documents] Add Document',
  ADD_DOCUMENT_SUCCESS = '[Documents] Add Document Success',
  ADD_DOCUMENT_FAILURE = '[Documents] Add Document Failure',

  ADD_DOCUMENTS = '[Documents] Add Documents',
  ADD_DOCUMENTS_SUCCESS = '[Documents] Add Documents Success',
  ADD_DOCUMENTS_FAILURE = '[Documents] Add Documents Failure',
  UPDATE_DOCUMENT = '[Documents] Update Document',
  UPDATE_DOCUMENT_SUCCESS = '[Documents] Update Document Success',
  UPDATE_DOCUMENT_FAILURE = '[Documents] Update Document Failure',

  UPDATE_TASK_DOCUMENT = '[Documents] Update Task Document',
  UPDATE_TASK_DOCUMENT_SUCCESS = '[Documents] Update Task Document Success',
  UPDATE_TASK_DOCUMENT_FAILURE = '[Documents] Update Task Document Failure',

  DELETE_DOCUMENT = '[Documents] Delete Document',
  DELETE_DOCUMENT_SUCCESS = '[Documents] Delete Document Success',
  DELETE_DOCUMENT_FAILURE = '[Documents] Delete Document Failure',

  DELETE_DOCUMENTS = '[Documents] Delete Documents',
  DELETE_DOCUMENTS_SUCCESS = '[Documents] Delete Documents Success',
  DELETE_DOCUMENTS_FAILURE = '[Documents] Delete Documents Failure',

  DELETE_ACTIVITY_DOCUMENTS = '[Documents] Delete Activity Documents',
  DELETE_ACTIVITY_DOCUMENTS_SUCCESS = '[Documents] Delete Activity Documents Success',
  DELETE_ACTIVITY_DOCUMENTS_FAILURE = '[Documents] Delete Activity Documents Failure',

  DELETE_TASK_DOCUMENTS = '[Documents] Delete Task Documents',
  DELETE_TASK_DOCUMENTS_SUCCESS = '[Documents] Delete Task Documents Success',
  DELETE_TASK_DOCUMENTS_FAILURE = '[Documents] Delete Task Documents Failure',

  CLEAR_DOCUMENTS = '[Documents] Clear Documents'
}
export class ClearDocuments implements Action {
  public readonly type = EDocumentsActions.CLEAR_DOCUMENTS;

  constructor() {}
}

export class GetDocuments implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS;

  constructor(public payload: DocumentsFetch) {}
}

export class GetDocumentsSuccess implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_SUCCESS;

  constructor(public payload: { paginator: Paginator<DocumentExtended>; filters: DocumentsFilters }) {}
}

export class GetDocumentsFailure implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_FAILURE;

  constructor(public error: Error) {}
}

export class GetDocumentsByActivity implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_ACTIVITY;

  constructor(public payload: number, public filters?: DocumentsFilters) {}
}

export class GetDocumentsByActivitySuccess implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_ACTIVITY_SUCCESS;

  constructor(public payload: DocumentExtended[]) {}
}

export class GetDocumentsByActivityFailure implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_ACTIVITY_FAILURE;

  constructor(public error: Error) {}
}

export class GetDocumentsByTask implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_TASK;

  constructor(public payload: number, public filters?: DocumentsFilters) {}
}
export class ClearDocumentsByTask implements Action {
  public readonly type = EDocumentsActions.CLEAR_DOCUMENTS_BY_TASK;

  constructor() {}
}

export class GetDocumentsByTaskSuccess implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_TASK_SUCCESS;

  constructor(public payload: DocumentExtended[]) {}
}

export class GetDocumentsByTaskFailure implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_TASK_FAILURE;

  constructor(public error: Error) {}
}

export class GetDocumentsByBill implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_BILL;

  constructor(public payload: number) {}
}

export class GetDocumentsByBillSuccess implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_BILL_SUCCESS;

  constructor(public payload: DocumentExtended[]) {}
}

export class GetDocumentsByBillFailure implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_BILL_FAILURE;

  constructor(public error: Error) {}
}

export class GetDocumentsByCorrespondence implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_CORRESPONDENCE;

  constructor(public payload: number, public filters?: DocumentsFilters) {}
}

export class GetDocumentsByCorrespondenceSuccess implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_CORRESPONDENCE_SUCCESS;

  constructor(public payload: DocumentExtended[]) {}
}

export class GetDocumentsByCorrespondenceFailure implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_CORRESPONDENCE_FAILURE;

  constructor(public error: Error) {}
}

export class GetDocumentsByContract implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_CONTRACT;

  constructor(public payload: number) {}
}

export class GetDocumentsByContractSuccess implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_CONTRACT_SUCCESS;

  constructor(public payload: DocumentExtended[]) {}
}

export class GetDocumentsByContractFailure implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENTS_BY_CONTRACT_FAILURE;

  constructor(public error: Error) {}
}

export class GetDocument implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENT;

  constructor(public payload: number) {}
}

export class GetDocumentSuccess implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENT_SUCCESS;

  constructor(public payload: DocumentExtended) {}
}

export class GetDocumentFailure implements Action {
  public readonly type = EDocumentsActions.GET_DOCUMENT_FAILURE;

  constructor(public error: Error) {}
}

export class AddDocument implements Action {
  public readonly type = EDocumentsActions.ADD_DOCUMENT;

  constructor(public payload: { document: DocumentSave; mediaFile?: File; reminders?: ReminderExtended; relations?: DocRelation[] }) {}
}

export class AddDocumentSuccess implements Action {
  public readonly type = EDocumentsActions.ADD_DOCUMENT_SUCCESS;

  constructor(public payload: DocumentExtended) {}
}

export class AddDocumentFailure implements Action {
  public readonly type = EDocumentsActions.ADD_DOCUMENT_FAILURE;

  constructor(public error: Error) {}
}

export class AddDocuments implements Action {
  public readonly type = EDocumentsActions.ADD_DOCUMENTS;

  constructor(public payload: { document: DocumentSave; mediaFile: File; reminders?: ReminderExtended; relations?: DocRelation[] }[]) {}
}

export class AddDocumentsSuccess implements Action {
  public readonly type = EDocumentsActions.ADD_DOCUMENTS_SUCCESS;

  constructor(public payload: DocumentExtended[]) {}
}

export class AddDocumentsFailure implements Action {
  public readonly type = EDocumentsActions.ADD_DOCUMENTS_FAILURE;

  constructor(public payload) {}
}

export class UpdateDocument implements Action {
  public readonly type = EDocumentsActions.UPDATE_DOCUMENT;

  constructor(public payload: { document: DocumentSave; relations?: DocRelation[] }) {}
}

export class UpdateDocumentSuccess implements Action {
  public readonly type = EDocumentsActions.UPDATE_DOCUMENT_SUCCESS;

  constructor(public payload: DocumentExtended) {}
}

export class UpdateDocumentFailure implements Action {
  public readonly type = EDocumentsActions.UPDATE_DOCUMENT_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateTaskDocument implements Action {
  public readonly type = EDocumentsActions.UPDATE_TASK_DOCUMENT;

  constructor(public payload: { document: DocumentSave; task: number; relations: DocRelation[] }) {}
}

export class UpdateTaskDocumentSuccess implements Action {
  public readonly type = EDocumentsActions.UPDATE_TASK_DOCUMENT_SUCCESS;

  constructor(public payload: number) {}
}

export class UpdateTaskDocumentFailure implements Action {
  public readonly type = EDocumentsActions.UPDATE_TASK_DOCUMENT_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteDocument implements Action {
  public readonly type = EDocumentsActions.DELETE_DOCUMENT;

  constructor(public payload: number) {}
}

export class DeleteDocumentSuccess implements Action {
  public readonly type = EDocumentsActions.DELETE_DOCUMENT_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteDocumentFailure implements Action {
  public readonly type = EDocumentsActions.DELETE_DOCUMENT_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteDocuments implements Action {
  public readonly type = EDocumentsActions.DELETE_DOCUMENTS;

  constructor(public payload: number[]) {}
}

export class DeleteDocumentsSuccess implements Action {
  public readonly type = EDocumentsActions.DELETE_DOCUMENTS_SUCCESS;

  constructor(public payload: number[]) {}
}

export class DeleteDocumentsFailure implements Action {
  public readonly type = EDocumentsActions.DELETE_DOCUMENTS_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteActivityDocuments implements Action {
  public readonly type = EDocumentsActions.DELETE_ACTIVITY_DOCUMENTS;

  constructor(public payload: { documents: number[]; activity: number }) {}
}

export class DeleteActivityDocumentsSuccess implements Action {
  public readonly type = EDocumentsActions.DELETE_ACTIVITY_DOCUMENTS_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteActivityDocumentsFailure implements Action {
  public readonly type = EDocumentsActions.DELETE_ACTIVITY_DOCUMENTS_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteTaskDocuments implements Action {
  public readonly type = EDocumentsActions.DELETE_TASK_DOCUMENTS;

  constructor(public payload: { documents: number[]; task: number }) {}
}

export class DeleteTaskDocumentsSuccess implements Action {
  public readonly type = EDocumentsActions.DELETE_TASK_DOCUMENTS_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteTaskDocumentsFailure implements Action {
  public readonly type = EDocumentsActions.DELETE_TASK_DOCUMENTS_FAILURE;

  constructor(public error: Error) {}
}

export type DocumentActions =
  | ClearDocuments
  | GetDocuments
  | GetDocumentsSuccess
  | GetDocumentsFailure
  | GetDocumentsByActivity
  | GetDocumentsByActivitySuccess
  | GetDocumentsByActivityFailure
  | GetDocumentsByTask
  | GetDocumentsByTaskSuccess
  | GetDocumentsByTaskFailure
  | ClearDocumentsByTask
  | GetDocumentsByCorrespondence
  | GetDocumentsByCorrespondenceSuccess
  | GetDocumentsByCorrespondenceFailure
  | GetDocumentsByBill
  | GetDocumentsByBillSuccess
  | GetDocumentsByBillFailure
  | GetDocument
  | GetDocumentSuccess
  | GetDocumentFailure
  | AddDocument
  | AddDocumentSuccess
  | AddDocumentFailure
  | AddDocuments
  | AddDocumentsSuccess
  | AddDocumentsFailure
  | UpdateDocument
  | UpdateDocumentSuccess
  | UpdateDocumentFailure
  | UpdateTaskDocument
  | UpdateTaskDocumentSuccess
  | UpdateTaskDocumentFailure
  | DeleteDocument
  | DeleteDocumentSuccess
  | DeleteDocumentFailure
  | DeleteDocuments
  | DeleteDocumentsSuccess
  | DeleteDocumentsFailure
  | DeleteActivityDocuments
  | DeleteActivityDocumentsSuccess
  | DeleteActivityDocumentsFailure
  | DeleteTaskDocuments
  | DeleteTaskDocumentsSuccess
  | DeleteTaskDocumentsFailure
  | GetDocumentsByContract
  | GetDocumentsByContractSuccess
  | GetDocumentsByContractFailure;
