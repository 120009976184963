import { initialMediaState, IMediaState } from '../state/media.state';

import { MediaActions, EMediaActions } from '../actions/media.actions';

export const mediaReducers = (state = initialMediaState, action: MediaActions): IMediaState => {
  switch (action.type) {
    case EMediaActions.ADDED_MEDIA: {
      return {
        ...state,
        addedMedia: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
