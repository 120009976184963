import { Action } from '@ngrx/store';
import { Paginator } from '@app/models/paginator';
import { PracticeBranch } from '@app/models';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum EPracticeBranchesActions {
  GET_PRACTICE_BRANCHES = '[PracticeBranches] Get Practice Areas',
  GET_PRACTICE_BRANCHES_SUCCESS = '[PracticeBranches] Get Practice Areas Success',
  GET_PRACTICE_BRANCHES_FAILURE = '[PracticeBranches] Get Practice Areas Failure',
  ADD_PRACTICE_BRANCH = '[PracticeBranches] Add Practice Area',
  ADD_PRACTICE_BRANCH_SUCCESS = '[PracticeBranches] Add Practice Area Success',
  ADD_PRACTICE_BRANCH_FAILURE = '[PracticeBranches] Add Practice Area Failure',
  UPDATE_PRACTICE_BRANCH = '[PracticeBranches] Update Practice Area',
  UPDATE_PRACTICE_BRANCH_SUCCESS = '[PracticeBranches] Update Practice Area Success',
  UPDATE_PRACTICE_BRANCH_FAILURE = '[PracticeBranches] Update Practice Area Failure',
  DELETE_PRACTICE_BRANCH = '[PracticeBranches] Delete Practice Area',
  DELETE_PRACTICE_BRANCH_SUCCESS = '[PracticeBranches] Delete Practice Area Success',
  DELETE_PRACTICE_BRANCH_FAILURE = '[PracticeBranches] Delete Practice Area Failure',
  DELETE_PRACTICE_BRANCHES = '[PracticeBranches] Delete Practice Areas',
  DELETE_PRACTICE_BRANCHES_SUCCESS = '[PracticeBranches] Delete Practice Areas Success',
  DELETE_PRACTICE_BRANCHES_FAILURE = '[PracticeBranches] Delete Practice Areas Failure'
}

export class GetPracticeBranches implements Action {
  public readonly type = EPracticeBranchesActions.GET_PRACTICE_BRANCHES;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetPracticeBranchesSuccess implements Action {
  public readonly type = EPracticeBranchesActions.GET_PRACTICE_BRANCHES_SUCCESS;

  constructor(public payload: Paginator<PracticeBranch>) {}
}

export class GetPracticeBranchesFailure implements Action {
  public readonly type = EPracticeBranchesActions.GET_PRACTICE_BRANCHES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddPracticeBranch implements Action {
  public readonly type = EPracticeBranchesActions.ADD_PRACTICE_BRANCH;

  constructor(public payload: PracticeBranch) {}
}

export class AddPracticeBranchSuccess implements Action {
  public readonly type = EPracticeBranchesActions.ADD_PRACTICE_BRANCH_SUCCESS;

  constructor(public payload: PracticeBranch) {}
}

export class AddPracticeBranchFailure implements Action {
  public readonly type = EPracticeBranchesActions.ADD_PRACTICE_BRANCH_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdatePracticeBranch implements Action {
  public readonly type = EPracticeBranchesActions.UPDATE_PRACTICE_BRANCH;

  constructor(public payload: PracticeBranch) {}
}

export class UpdatePracticeBranchSuccess implements Action {
  public readonly type = EPracticeBranchesActions.UPDATE_PRACTICE_BRANCH_SUCCESS;

  constructor(public payload: PracticeBranch) {}
}

export class UpdatePracticeBranchFailure implements Action {
  public readonly type = EPracticeBranchesActions.UPDATE_PRACTICE_BRANCH_FAILURE;

  constructor(public payload: Error) {}
}

export class DeletePracticeBranch implements Action {
  public readonly type = EPracticeBranchesActions.DELETE_PRACTICE_BRANCH;

  constructor(public payload: number) {}
}

export class DeletePracticeBranchSuccess implements Action {
  public readonly type = EPracticeBranchesActions.DELETE_PRACTICE_BRANCH_SUCCESS;

  constructor(public payload: number) {}
}

export class DeletePracticeBranchFailure implements Action {
  public readonly type = EPracticeBranchesActions.DELETE_PRACTICE_BRANCH_FAILURE;

  constructor(public payload: Error) {}
}

export class DeletePracticeBranches implements Action {
  public readonly type = EPracticeBranchesActions.DELETE_PRACTICE_BRANCHES;

  constructor(public payload: number[]) {}
}

export class DeletePracticeBranchesSuccess implements Action {
  public readonly type = EPracticeBranchesActions.DELETE_PRACTICE_BRANCHES_SUCCESS;

  constructor(public payload: number[]) {}
}

export class DeletePracticeBranchesFailure implements Action {
  public readonly type = EPracticeBranchesActions.DELETE_PRACTICE_BRANCHES_FAILURE;

  constructor(public payload: Error) {}
}
// Todo duoble
export type PracticeBranchActions =
  | GetPracticeBranches
  | GetPracticeBranchesSuccess
  | GetPracticeBranchesSuccess
  | AddPracticeBranch
  | AddPracticeBranchSuccess
  | AddPracticeBranchSuccess
  | UpdatePracticeBranch
  | UpdatePracticeBranchSuccess
  | UpdatePracticeBranchSuccess
  | DeletePracticeBranch
  | DeletePracticeBranchSuccess
  | DeletePracticeBranchSuccess
  | DeletePracticeBranches
  | DeletePracticeBranchesSuccess
  | DeletePracticeBranchesSuccess;
