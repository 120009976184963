import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthProxyService } from '@app/auth/_services/auth-proxy.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  registerForm: UntypedFormGroup;
  submittedRegisterForm = false;
  state = 1;
  showLoader = false;

  constructor(private formBuilder: UntypedFormBuilder, private authProxyService: AuthProxyService) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }


  submit() {
    this.submittedRegisterForm = true;

    if (this.registerForm.invalid) {
      return;
    }

    const payload = {
      email: this.registerForm.value.email
    };
    this.openSpinner();
    this.authProxyService
      .resetPassword(payload)
      .pipe(take(1))
      .subscribe(() => {
        this.hideSpinner();
      });
    this.state = 2;
  }

  get f() {
    return this.registerForm.controls;
  }


  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }
}
