import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { PracticeBranch, TaskExtended } from '@app/models';
import { MatterExtended } from '@app/models/matter-extended';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { MatterStagesService } from '@app/modules/settings/services/matter-stages.service';
import { GetPracticeBranches } from '@app/modules/settings/store/actions/practice-branches.actions';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { MatterStatus } from '@app/enums/matter-status.enum';
import { checkRole } from '@app/helpers/check-role';
import { MattersComponent } from '@app/modules/reusable/matters/components/matters/matters.component';


@Component({
  selector: 'app-stages-kanban',
  templateUrl: './stages-kanban.component.html',
  styleUrl: './stages-kanban.component.scss',
})
export class StagesKanbanComponent {
  constructor(
    private router: Router,
    private contactService: ContactService,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private matterStagesService: MatterStagesService,
    private matterService: MatterService,
    @Inject(MattersComponent) private parent: MattersComponent
  ) {}

  dragDiretion = null;

  @Output() viewItem = new EventEmitter();
  @Output() editItem = new EventEmitter();
  @Output() completeTask = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  @Output() updateData = new EventEmitter();
  @Input() filters;
  tasksList: TaskExtended[];
  spinnerName = 'kanbanList';
  showLoader = false;
  currentMatters: MatterExtended[];
  practiceBranch: PracticeBranch;
  @Input() practiceBranches: PracticeBranch[];
  @Input() key: string;
  @Input() set currentPracticeBranch(value) {
    this.practiceBranch = value;
    if (value) {
      this.initCurrentStages();
    }
  }
  menuPosition = {
    item: null,
    group: null,
    top: 0,
    left: 0,
    opened: ''
  };

  groups;
  submitNewStage = false;

  openAddNewStage = false;
  newStageName = '';

  draggingItem = false;
  loadAddedNewStage = false;
  heightForItems = {};

  setHeight(): void {
    setTimeout(() => {
      const items = Array.from(document.getElementsByClassName('draggble-box'));
      for (const item of items) {
        this.heightForItems[item.id] = item.clientHeight;
      }
    }, 100);

  }
  startDragging() {
    this.draggingItem = true;
  }
  initCurrentStages(filters?) {
    if (filters) {
      this.filters = filters;
    }
    if (!this.filters?.['order[matterStageUpdatedAt]']) {
      this.filters = {
        ...this.filters,
        ['order[matterStageUpdatedAt]']: 'ASC'
      };
    }
    window.sessionStorage.setItem(this.key, JSON.stringify({ filters: this.filters }));
    this.openSpinner();
    const filtersForGet = {...this.filters};

    if (this.parent?.ifContact) {
      filtersForGet['contact.id'] = this.parent.ifContact.id;
    }

    if (this.parent?.ifUser) {
      filtersForGet['responsibleSolicitor.id'] = this.parent.ifUser.id;
    }
    this.matterService.get(
      1,
      100,
      {
        'contact.id': filtersForGet?.['contact.id'],
        'order[matterStageUpdatedAt]': filtersForGet?.['order[matterStageUpdatedAt]'],
        'responsibleSolicitor.id': filtersForGet?.['responsibleSolicitor.id'],
        'name': filtersForGet?.['name'],
        'status[]': ['open', 'pending'],
        'practiceBranch.id': this.practiceBranch.id,
        status: 'stages'
      }
    ).pipe(take(1)).subscribe(resp => {
      this.groups = this.practiceBranch.matterStages.sort((a, b) => a.orderKanban - b.orderKanban).map(el => ({
        name: el.name,
        key: el.id,
        items: resp.member.filter(matter => matter.matterStage?.id === el.id).map((mat) => ({
          ...mat,
          height: 116
        })),
        scrolled: false,
        orderKanban: el.orderKanban,
      }));
      if (resp.member.filter(matter => !matter.matterStage?.id)?.length) {
        this.groups.unshift({
          name: this.translate.instant('noStageAssigned'),
          key: null,
          items: resp.member.filter(matter => !matter.matterStage?.id).map((mat) => ({
            ...mat,
            height: 116
          })),
          scrolled: false,
          orderKanban: -1,
        })
      }

      this.hideSpinner();
      this.setHeight();
    })
  }


  checkAccesRole() {
    return checkRole(['ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ADMIN'])
  }

  addNewStage() {
    this.openAddNewStage = true;
    setTimeout(() => {
      document.getElementById('newStageInput')?.focus();
    });
  }

  cancelAddNewStage() {
    this.openAddNewStage = false;
    this.newStageName = '';
  }

  saveNewStage() {
    this.submitNewStage = true;
    if (this.newStageName) {
      this.matterStagesService.addMatterStages({
        name: this.newStageName,
        practiceBranch: this.practiceBranch.id,
        orderKanban: this.groups[this.groups.length - 1]?.orderKanban ? this.groups[this.groups.length - 1]?.orderKanban + 100 : 1
      }).pipe(take(1)).subscribe(() => {
        this.submitNewStage = false;
        this.openAddNewStage = false;
        this.newStageName = '';
        this.loadAddedNewStage = true;
        this.store.dispatch(new GetPracticeBranches({ page: 1, itemsPerPage: 1000 }))
      });
    }

  }

  menuStageDisplay(event, group) {
    if (!group) {
      this.menuPosition.opened = '';
      setTimeout(() => {
        this.menuPosition = {
          item: null,
          group: null,
          top: 0,
          left: 0,
          opened: ''
        };
      }, 200);
      return;
    }

    const { elementPosition, topPosition } = this.getOffsetPosition(event, 150);

    this.menuPosition = {
      group,
      item: null,
      top: topPosition ? elementPosition.top + 20 : elementPosition.top,
      left: elementPosition.left + elementPosition.width,
      opened: 'group'
    };
  }

  menuDisplay(data) {

    if (!data) {
      this.menuPosition.opened = '';
      setTimeout(() => {
        this.menuPosition = {
          item: null,
          group: null,
          top: 0,
          left: 0,
          opened: ''
        };
      }, 200);
      return;
    }

    const { event, item } = data;
    const { elementPosition, topPosition } = this.getOffsetPosition(event, 150);

    this.menuPosition = {
      item,
      group: null,
      top: topPosition ? elementPosition.top + 20 : elementPosition.top,
      left: elementPosition.left + elementPosition.width,
      opened: 'item'
    };
  }

  getShortName(name) {
    const names = name?.split(' ');
    return names && names[0][0] + names[1][0];
  }

  getOffsetPosition(event, elementHeight) {
    const elementPosition = event.target.getBoundingClientRect();
    const topPosition = window.innerHeight - elementPosition.top - elementPosition.height < elementHeight;

    return { elementPosition, topPosition };
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  goToWorker(user) {
    this.openSpinner();
    this.contactService.getUserById(user.id).pipe(take(1)).subscribe(res => {
      this.hideSpinner();
      if (res.id) {
        this.router.navigateByUrl(`/worker/${user.id}`);
      }
    });
  }

  onViewItem(item) {
    this.viewItem.emit(item);
  }

  onEditItem(item) {
    this.menuPosition.item = null;
    this.editItem.emit(item);
  }

  completeItem(item) {
    this.menuPosition.item = null;
    this.completeTask.emit(item);
  }

  onDeleteItem(item) {
    this.menuPosition.item = null;
    this.deleteItem.emit(item);
  }

  changeOrderMatter(matter) {
    this.matterService.updateMatter(matter).pipe(take(1)).subscribe(res => {
      if (!this.groups[0]?.key && !this.groups[0].items.length) {
        this.groups.shift();
      }
    });
  }

  changeOrderStage(stage) {
    this.matterStagesService.updateMatterStages(stage).pipe(take(1)).subscribe(res => {
      // if (res.id && tasks.status === 'done') {
      //   // this.openTimeModal(res);
      // }
    });
  }

  drop(event: CdkDragDrop<TaskExtended[]>) {
    this.draggingItem = false;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    console.log(event.item.data);
    console.log(event.container.id);
    if (event.container.id) {
      this.changeOrderMatter({
        matterStage: +event.container.id || null,
        id: event.item.data.id
      });
    }

  }


  dropGroup(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.groups, event.previousIndex, event.currentIndex);

    const previousItem = this.groups[event.currentIndex - 1];
    const nextItem = this.groups[event.currentIndex + 1];
    let newOrder = 1;
    if (previousItem && nextItem) {
      newOrder = (previousItem.orderKanban + nextItem.orderKanban) / 2;
    } else if (previousItem && !nextItem) {
      newOrder = previousItem.orderKanban * 10;
    } else if (!previousItem && nextItem) {
      newOrder = nextItem.orderKanban / 2;
    } else {
      newOrder = event.item.data.id * 100;
    }
    this.groups[event.currentIndex].orderKanban = newOrder > 0 ? newOrder : 1;

    this.changeOrderStage({
      orderKanban: Math.round(newOrder > 0 ? newOrder : 1),
      id: event.item.data.key
    });

  }

  removeStage(group) {
    this.menuStageDisplay(null, false);
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog-br6';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('deleteStage')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('Delete the stage?'),
      mainTxt: this.translate.instant('removeStageDesc')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.matterStagesService.deleteMatterStages(group.key).pipe(take(1)).subscribe(() => this.store.dispatch(new GetPracticeBranches({ page: 1, itemsPerPage: 1000 })));
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  editNameStage(group) {
    this.menuStageDisplay(null, false);
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog-br6';
    confirmDialogConfig.data = {
      textValue: group.name,
      showTextInput: true,
      modalTyle: 'blue',
      yesButtonTxt: this.translate.instant(('button.save')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('editStagName'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result?.action === true) {
        this.matterStagesService.updateMatterStages({id: group.key, name: result.value}).pipe(take(1)).subscribe(() => {
          this.store.dispatch(new GetPracticeBranches({ page: 1, itemsPerPage: 1000 }));
        });
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  changeStatus(matter, status) {
    switch (status) {
      case MatterStatus.closed:
        // з роботи в завершений
        if (matter.status === MatterStatus.open) {
          // якщо поле closedDate не нул - поставити поточну дату,
          if (matter.closedDate) {
            matter.closedDate = CustomDateAdapter.convertToUniversalDateString(new Date());
          }
          // якщо поле pendingDate не нул - сет нул
          if (matter.pendingDate) {
            matter.pendingDate = null;
          }
        }
        // з очікування в завершення
        if (matter.status === MatterStatus.pending) {
          // якщо поле closedDate не нул або нулл - поставити поточну дату
          matter.closedDate = CustomDateAdapter.convertToUniversalDateString(new Date());
          // якщо поле pendingDate не нул - сет нул
          if (matter.pendingDate) {
            matter.pendingDate = null;
          }
        }
        break;
      case MatterStatus.open:
        // з очікування в роботу
        if (matter.status === MatterStatus.pending) {
          // якщо поле pendingDate не нул - сет нул
          if (matter.pendingDate) {
            matter.pendingDate = null;
          }
        }
        // з завершення в роботу
        if (matter.status === MatterStatus.closed) {
          // якщо поле closedDate не нул - сет нул
          if (matter.closedDate) {
            matter.closedDate = null;
          }
        }
        break;
      case MatterStatus.pending:
        // з роботи в очікування
        if (matter.status === MatterStatus.open) {
          // якщо поле closedDate не нул - сет нул
          if (matter.closedDate) {
            matter.closedDate = null;
          }
          // якщо поле pendingDate не нул  або нул- поставити поточну дату
          matter.pendingDate = CustomDateAdapter.convertToUniversalDateString(new Date());
        }
        break;
    }
    this.matterService.updateMatter(
      {
        id: matter.id,
        pendingDate: matter.pendingDate,
        closedDate: matter.closedDate,
        status,
      }
    ).pipe(take(1)).subscribe(() => {
      this.initCurrentStages();
    });
  }

  switchPractice(item: MatterExtended) {
    this.menuDisplay(false);
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog-br6';
    confirmDialogConfig.data = {
      practiceBranch: item.practiceBranch,
      practiceBranches: this.practiceBranches,
      showSelectPractice: true,
      modalTyle: 'blue',
      yesButtonTxt: this.translate.instant(('button.save')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('Switch Practice'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result?.action === true) {
        this.matterService.updateMatter({id: item.id, practiceBranch: result.value, matterStage: null}).pipe(take(1)).subscribe(() => {
          this.store.dispatch(new GetPracticeBranches({ page: 1, itemsPerPage: 1000 }));
        })
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  onScrollWrapper(event, group) {
    if (event.target.scrollTop > 1) {
      group.scrolled = true;
    } else {
      group.scrolled = false;
    }
  }

}
