import { Action } from '@ngrx/store';
import { Note } from '@app/models/note';
import { NoteFilters } from '@app/models/note-filters';

export enum NoteActionsTypes {
  GET_NOTES = '[Notes] Get Notes',
  GET_NOTES_SUCCESS = '[Notes] Get Notes Success',
  GET_NOTES_FAILURE = '[Notes] Get Notes Failure',
  GET_NOTE = '[Notes] Get Note',
  GET_NOTE_SUCCESS = '[Notes] Get Note Success',
  GET_NOTE_FAILURE = '[Notes] Get Notes Failure',
  ADD_NOTE = '[Notes] Add Note',
  ADD_NOTE_SUCCESS = '[Notes] Add Note Success',
  ADD_NOTES_FAILURE = '[Notes] Get Notes Failure',
  UPDATE_NOTE = '[Notes] Update Note',
  UPDATE_NOTE_SUCCESS = '[Notes] Update Note Success',
  UPDATE_NOTES_FAILURE = '[Notes] Get Notes Failure',
  DELETE_NOTE = '[Notes] Delete Note',
  DELETE_NOTE_SUCCESS = '[Notes] Delete Note Success',
  DELETE_NOTE_FAILURE = '[Notes] Get Notes Failure',
  SELECTED_NOTE = '[Notes] Select Note',
  IF_FROM_CONTACT_NOTES = '[Notes] Notes If from Contact',
  IF_FROM_MATTER_NOTES = '[Notes] Notes If from Matter',
  CLEAR_NOTES = '[Notes] Clear Notes'
}
export class ClearNote implements Action {
  public readonly type = NoteActionsTypes.CLEAR_NOTES;

  constructor() {}
}
export class SelectNote implements Action {
  public readonly type = NoteActionsTypes.SELECTED_NOTE;

  constructor(public payload: Note) {}
}
export class IfFromContact implements Action {
  public readonly type = NoteActionsTypes.IF_FROM_CONTACT_NOTES;

  constructor(public payload: number) {}
}
export class IfFromMatter implements Action {
  public readonly type = NoteActionsTypes.IF_FROM_MATTER_NOTES;

  constructor(public payload: number) {}
}
export class GetNotes implements Action {
  readonly type = NoteActionsTypes.GET_NOTES;

  constructor(public payload: NoteFilters) {}
}
export class GetNotesSuccess implements Action {
  readonly type = NoteActionsTypes.GET_NOTES_SUCCESS;

  constructor(public payload: Array<Note>) {}
}
export class GetNotesFailure implements Action {
  readonly type = NoteActionsTypes.GET_NOTES_FAILURE;

  constructor(public payload: Error) {}
}

export class GetNote implements Action {
  public readonly type = NoteActionsTypes.GET_NOTE;

  constructor(public payload: number) {}
}
export class GetNoteSuccess implements Action {
  public readonly type = NoteActionsTypes.GET_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}
export class GetNoteFailure implements Action {
  public readonly type = NoteActionsTypes.GET_NOTE_FAILURE;

  constructor(public payload: Error) {}
}

export class AddNote implements Action {
  public readonly type = NoteActionsTypes.ADD_NOTE;

  constructor(public payload: Note) {}
}
export class AddNoteSuccess implements Action {
  public readonly type = NoteActionsTypes.ADD_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}
export class AddNoteFailure implements Action {
  readonly type = NoteActionsTypes.ADD_NOTES_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateNote implements Action {
  public readonly type = NoteActionsTypes.UPDATE_NOTE;

  constructor(public payload: Note) {}
}
export class UpdateNoteSuccess implements Action {
  public readonly type = NoteActionsTypes.UPDATE_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}
export class UpdateNoteFailure implements Action {
  public readonly type = NoteActionsTypes.UPDATE_NOTES_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteNote implements Action {
  public readonly type = NoteActionsTypes.DELETE_NOTE;

  constructor(public payload: number) {}
}
export class DeleteNoteSuccess implements Action {
  public readonly type = NoteActionsTypes.DELETE_NOTE_SUCCESS;

  constructor(public payload: number) {}
}
export class DeleteNoteFailure implements Action {
  public readonly type = NoteActionsTypes.DELETE_NOTE_FAILURE;

  constructor(public payload: Error) {}
}

export type NoteActions =
  | ClearNote
  | SelectNote
  | IfFromContact
  | IfFromMatter
  | GetNotes
  | GetNotesSuccess
  | GetNotesFailure
  | GetNote
  | GetNoteSuccess
  | GetNoteFailure
  | AddNote
  | AddNoteSuccess
  | AddNoteFailure
  | UpdateNote
  | UpdateNoteSuccess
  | UpdateNoteFailure
  | DeleteNote
  | DeleteNoteSuccess
  | DeleteNoteFailure;
