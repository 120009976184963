import { Injectable } from '@angular/core';
import { IUser, Payload, ResponseBodyAuth } from '@app/models';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { WebsocketService } from '@app/websocket/websocket.service';
import { Router } from '@angular/router';
import { checkRole } from '@app/helpers/check-role';
import { ExpireModalComponent } from '@app/commonComponents/modals/system-modals/expire-modal/expire-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { RoutesService } from '@app/helpers/routes.service';
import { ReviewLawyer } from '@app/models/interfaces/review-lawyer';

@Injectable({ providedIn: 'root' })
export class AuthService {

  // Дмитро Ципліцький
  // Адвокат, керуючий партнер АБ “Дмитра Ципліцького”'
  // `Завдяки системі, я за рік збільшив прибуток від юридичної практики на понад 12%.
  // І кожного дня наша команда продовжує покращувати показники та досягати кращих результатів.`
  public reviews: Array<ReviewLawyer> = [
    {
      name: 'reviews.name.ukraine',
      position: 'reviews.position.ukraine',
      review: 'reviews.review.ukraine',
      mark: 5,
      icon: '/assets/images/reviews/prapor_ukraine.jpeg'
    },
    {
      name: 'reviews.name.AnatoliyTarasenko',
      position: 'reviews.position.AnatoliyTarasenko',
      review: 'reviews.review.AnatoliyTarasenko',
      mark: 5,
      icon: '/assets/images/reviews/Photo-5.jpg'
    },
    {
      name: 'reviews.name.DmitriyTsiplitskiy',
      position: 'reviews.position.DmitriyTsiplitskiy',
      review: 'reviews.review.DmitriyTsiplitskiy',
      mark: 5,
      icon: '/assets/images/reviews/Photo-1.jpg'
    },
    {
      name: 'reviews.name.IgorZaverukha',
      position: 'reviews.position.IgorZaverukha',
      review: 'reviews.review.IgorZaverukha',
      mark: 5,
      icon: '/assets/images/reviews/Photo-2.jpg'
    },
    {
      name: 'reviews.name.YevhenMiroshnykov',
      position: 'reviews.position.YevhenMiroshnykov',
      review: 'reviews.review.YevhenMiroshnykov',
      mark: 5,
      icon: '/assets/images/reviews/Photo-3.jpg'
    },
    {
      name: 'reviews.name.YevhenMaloyvan',
      position: 'reviews.position.YevhenMaloyvan',
      review: 'reviews.review.YevhenMaloyvan',
      mark: 5,
      icon: '/assets/images/reviews/Photo-4.jpg'
    }
  ];

  public currentUserSubject: BehaviorSubject<Payload>;
  public showRefreshModal: BehaviorSubject<boolean>;
  public currentUser: Observable<Payload>;
  public refreshModalStatus: Observable<boolean>;

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly LOCAL_STORAGE_KEY = 'userSettings';

  private destinationUrl: string;
  private loggedUser: string;
  private jwtHelper: JwtHelperService;


  constructor(
    private routesService: RoutesService,
    private http: HttpClient,
    private wsService: WebsocketService,
    private router: Router,
    private dialog: MatDialog
  ) {
    routesService.urlAfterLogin$.subscribe(url => (this.destinationUrl = url));
    this.jwtHelper = new JwtHelperService();
    this.currentUserSubject = new BehaviorSubject<Payload>(this.getPayload());
    this.currentUser = this.currentUserSubject.asObservable();
    this.showRefreshModal = new BehaviorSubject<boolean>(false);
    this.refreshModalStatus = this.showRefreshModal.asObservable();
  }

  public get isExpiredRaw() {
    if (!this.currentUserValue) {
      return true;
    }
    return new Date(this.currentUserValue.expiredAt) < new Date();
  }

  public get currentUserValue(): Payload {
    return this.currentUserSubject.value;
  }


  private static shouldPaySubscription() {
    return checkRole(['ROLE_SITE_SUBSCRIPTIONS_MANAGER']);
  }

  setShowRefreshModal() {
    this.showRefreshModal.next(true);
  }

  login(username: string, password: string): Observable<ResponseBodyAuth> {
    this.removeTokens();
    return this.http
      .post<ResponseBodyAuth>(`${environment.apiUrl}/jwt/auth`, { username, password })
      .pipe(tap(tokens => this.doLoginUser(tokens, username)));
  }

  logout() {
    this.routesService.clearRoutesHistory();
    this.doLogoutUser();
    return true;
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  getExpiryDate() {
    return new Date(this.getPayload().exp * 1000);
  }

  isValidExpiry(interval = 0) {
    if (!this.getPayload()) {
      return false;
    }
    const nowDate = new Date(Date.now() + interval * 1000);

    return this.getExpiryDate() >= nowDate;
  }

  getPayload(): Payload {
    return this.jwtHelper.decodeToken(this.getJwtToken());
  }

  decodeToken(token) {
    return this.jwtHelper.decodeToken(token);
  }

  refreshToken() {
    return this.http
      .post<ResponseBodyAuth>(`${environment.apiUrl}/jwt/refresh`, {
        refresh: this.getRefreshToken()
      })
      .pipe(
        tap((tokens: ResponseBodyAuth) => {
          if (tokens.refresh && tokens.token) {
            this.storeJwtToken(tokens.token);
          }
        })
      );
  }

  refreshUser() {
    this.currentUserSubject = new BehaviorSubject<Payload>(this.getPayload());
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public doLoginUser(tokens: ResponseBodyAuth, username?: string) {
    if (tokens.refresh && tokens.token) {
      this.storeTokens(tokens);
      const user = this.getPayload();
      localStorage.setItem('lastCurrentActive', Date.now() + '');
      this.showRefreshModal.next(false);
      this.loggedUser = username;
      this.currentUserSubject.next(user);
      return true;
    } else {
      throwError('bad token');
      return false;
    }
  }

  private doLogoutUser() {
    this.currentUserSubject.next(null);
    this.loggedUser = null;
    this.removeTokens();
    this.wsService.webSocketDisconnect();
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
    setTimeout(() => {
      this.refreshUser();
    }, 200);
  }

  public storeTokens(tokens: ResponseBodyAuth) {
    localStorage.setItem(this.JWT_TOKEN, tokens.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
    localStorage.removeItem('pageViewCookie'); // pageViewCookie
  }

  getUserById(id: number) {
    return this.http.get<IUser>(`${environment.apiUrl}/users/${id}`);
  }

  checkSubscription(fromModal) {
    if (!this.isLoggedIn()) {
      return;
    }
    if (!this.isExpiredRaw) {
      if (!fromModal) {
        this.router.navigateByUrl(this.destinationUrl || '/');
      }
      return false;
    } else {
      this.handleSubscriptionExpiration();
      return true;
    }
  }

  handleSubscriptionExpiration() {
    if (AuthService.shouldPaySubscription()) {
      this.router.navigateByUrl('/settings/subscription');
    } else {
      this.doLogoutUser();
      this.expireModal();
    }
  }

  private expireModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];
    dialogConfig.backdropClass = 'mat-background-blur';
    this.dialog.open(ExpireModalComponent, dialogConfig);
  }

  public isCheckCurrentSubscriptionId() {
    return this.currentUserValue && this.currentUserValue.currentSubscriptionId;
  }
}
