import { Injectable } from '@angular/core';
import { NotificationsService } from '@app/modules/layout/notifications/services/notifications.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as notificationActions from '../actions/notification.actions';
import { tap, switchMap, mergeMap, share } from 'rxjs/operators';

@Injectable()
export class NotificationEffects {
  constructor(private actions$: Actions, private notificationServices: NotificationsService) {}


  isReadMessage$ = createEffect(() => this.actions$.pipe(
    ofType(notificationActions.ENotificationActions.ISREAD_NOTIFICATION),
    tap((action: any) => {
      if (action.payload.message.isRead === false) {
        this.notificationServices.isReadNotification(action.payload.message.id, { isRead: true }).subscribe();
      } else {
        this.notificationServices.isReadNotification(action.payload.message.id, { isRead: false }).subscribe();
      }
    })
  ), { dispatch: false });


  getNotificatios$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.GetNotifications>(notificationActions.ENotificationActions.GET_NOTIFICATION),
    switchMap(action => {
      return this.notificationServices.getAllNotifications(action.payload.page ? action.payload.page : 1, action.payload.itemsPerPage,  action.payload.filters).pipe(
        mergeMap(resp => {
          return [new notificationActions.GetNotificationsSuccess(resp, action.payload.page, action.payload.renew)];
        })
      );
    }),
    share()
  ));


  getCountUnreadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.GetCountUnreadNotifications>(notificationActions.ENotificationActions.GET_COUNT_UNREAD),
    switchMap(action => {
      return this.notificationServices.getCountUnread().pipe(
        mergeMap(resp => {
          return [new notificationActions.GetCountUnreadNotificationsSuccess(resp)];
        })
      );
    }),
    share()
  ));
}
