export enum TaskStatus {
  new = 'new',
  in_work = 'in_work',
  upcoming = 'upcoming',
  cancel = 'cancel',
  wait = 'wait',
  overdue = 'overdue',
  done = 'done',
  all = 'all'
}
