import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { INews } from '@app/models/interfaces/news.interface';
import { AuthService } from '@app/auth/_services/auth.service';
import { ResponseBodyFull } from '@app/models';
import { map } from 'rxjs/operators';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getAllNews(page = 1, itemsPerPage = 10): Observable<Paginator<INews>> {
    const params = new HttpParams().set('enable', 'true');
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<INews>>(`${environment.apiUrl}/news?partial=false&page=${page}&itemsPerPage=${itemsPerPage}`, {
          params
        })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
      } else {
        return new Observable<any>();
      }
  }

  getNews(id): Observable<INews> {
    const params = new HttpParams();

    if (this.authService.currentUserValue) {
      return this.http.get<INews>(`${environment.apiUrl}/news/${id}`, { params });
    } else {
      return new Observable<any>();
    }
  }
}
