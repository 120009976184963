import { Paginator } from '@app/models/paginator';
import { Action } from '@ngrx/store';
import { PersonType } from '@app/models';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum EPersonTypesActions {
  LOAD_PERSON_TYPES = '[PERSON_TYPES] Load PersonTypes',
  LOAD_PERSON_TYPES_SUCCESS = '[PERSON_TYPES] Load PersonTypes Success',
  LOAD_PERSON_TYPES_FAILURE = '[PERSON_TYPES] Load PersonTypes Failure',
  GET_PERSON_TYPES = '[PersonTypes] Get Person Types',
  GET_PERSON_TYPES_SUCCESS = '[PersonTypes] Get Person Types Success',
  GET_PERSON_TYPES_FAILURE = '[PersonTypes] Get Person Types Failure',
  ADD_PERSON_TYPE = '[PersonTypes] Add Person Type',
  ADD_PERSON_TYPE_SUCCESS = '[PersonTypes] Add Person Type Success',
  ADD_PERSON_TYPE_FAILURE = '[PersonTypes] Add Person Type Failure',
  UPDATE_PERSON_TYPE = '[PersonTypes] Update Person Type',
  UPDATE_PERSON_TYPE_SUCCESS = '[PersonTypes] Update Person Type Success',
  UPDATE_PERSON_TYPE_FAILURE = '[PersonTypes] Update Person Type Failure',
  DELETE_PERSON_TYPE = '[PersonTypes] Delete Person Type',
  DELETE_PERSON_TYPE_SUCCESS = '[PersonTypes] Delete Person Type Success',
  DELETE_PERSON_TYPE_FAILURE = '[PersonTypes] Delete Person Type Failure',
  DELETE_PERSON_TYPES = '[PersonTypes] Delete Person Types',
  DELETE_PERSON_TYPES_SUCCESS = '[PersonTypes] Delete Person Types Success',
  DELETE_PERSON_TYPES_FAILURE = '[PersonTypes] Delete Person Types Failure'
}
export class LoadPersonTypesAction implements Action {
  readonly type = EPersonTypesActions.LOAD_PERSON_TYPES;
}
export class LoadPersonTypesSuccessAction implements Action {
  readonly type = EPersonTypesActions.LOAD_PERSON_TYPES_SUCCESS;

  constructor(public payload: Array<PersonType>) {}
}
export class LoadPersonTypesFailureAction implements Action {
  readonly type = EPersonTypesActions.LOAD_PERSON_TYPES_FAILURE;

  constructor(public payload: Error) {}
}
export class GetPersonTypes implements Action {
  public readonly type = EPersonTypesActions.GET_PERSON_TYPES;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetPersonTypesSuccess implements Action {
  public readonly type = EPersonTypesActions.GET_PERSON_TYPES_SUCCESS;

  constructor(public payload: Paginator<PersonType>) {}
}

export class GetPersonTypesFailure implements Action {
  readonly type = EPersonTypesActions.GET_PERSON_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddPersonType implements Action {
  public readonly type = EPersonTypesActions.ADD_PERSON_TYPE;

  constructor(public payload: PersonType) {}
}

export class AddPersonTypeSuccess implements Action {
  public readonly type = EPersonTypesActions.ADD_PERSON_TYPE_SUCCESS;

  constructor(public payload: PersonType) {}
}

export class AddPersonTypeFailure implements Action {
  readonly type = EPersonTypesActions.ADD_PERSON_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdatePersonType implements Action {
  public readonly type = EPersonTypesActions.UPDATE_PERSON_TYPE;

  constructor(public payload: PersonType) {}
}

export class UpdatePersonTypeSuccess implements Action {
  public readonly type = EPersonTypesActions.UPDATE_PERSON_TYPE_SUCCESS;

  constructor(public payload: PersonType) {}
}

export class UpdatePersonTypeFailure implements Action {
  readonly type = EPersonTypesActions.UPDATE_PERSON_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class DeletePersonType implements Action {
  public readonly type = EPersonTypesActions.DELETE_PERSON_TYPE;

  constructor(public payload: number) {}
}

export class DeletePersonTypeSuccess implements Action {
  public readonly type = EPersonTypesActions.DELETE_PERSON_TYPE_SUCCESS;

  constructor(public payload: number) {}
}

export class DeletePersonTypeFailure implements Action {
  readonly type = EPersonTypesActions.DELETE_PERSON_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class DeletePersonTypes implements Action {
  public readonly type = EPersonTypesActions.DELETE_PERSON_TYPES;

  constructor(public payload: number[]) {}
}

export class DeletePersonTypesSuccess implements Action {
  public readonly type = EPersonTypesActions.DELETE_PERSON_TYPES_SUCCESS;

  constructor(public payload: number[]) {}
}

export class DeletePersonTypesFailure implements Action {
  readonly type = EPersonTypesActions.DELETE_PERSON_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export type PersonTypeActions =
  | LoadPersonTypesAction
  | LoadPersonTypesSuccessAction
  | LoadPersonTypesFailureAction
  | GetPersonTypes
  | GetPersonTypesSuccess
  | GetPersonTypesFailure
  | AddPersonType
  | AddPersonTypeSuccess
  | AddPersonTypeFailure
  | UpdatePersonType
  | UpdatePersonTypeSuccess
  | UpdatePersonTypeFailure
  | DeletePersonType
  | DeletePersonTypeSuccess
  | DeletePersonTypeFailure
  | DeletePersonTypes
  | DeletePersonTypesSuccess
  | DeletePersonTypesFailure;
