import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, withLatestFrom, map, tap, share, filter } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import {
  EDocumentsActions,
  GetDocuments,
  GetDocumentsSuccess,
  AddDocument,
  AddDocumentSuccess,
  DeleteDocument,
  DeleteDocumentSuccess,
  GetDocumentsByActivity,
  GetDocumentsByActivitySuccess,
  GetDocumentsByTask,
  GetDocumentsByTaskSuccess,
  GetDocumentsByCorrespondenceSuccess,
  GetDocumentsByCorrespondence,
  AddDocuments,
  AddDocumentsSuccess,
  DeleteDocuments,
  DeleteDocumentsSuccess,
  GetDocumentSuccess,
  GetDocument,
  DeleteActivityDocuments,
  DeleteActivityDocumentsSuccess,
  GetDocumentsByBill,
  GetDocumentsByBillSuccess,
  DeleteTaskDocuments,
  DeleteTaskDocumentsSuccess,
  UpdateDocument,
  UpdateDocumentSuccess,
  UpdateTaskDocument,
  UpdateTaskDocumentSuccess,
  GetDocumentsByContract,
  GetDocumentsByContractSuccess,
  AddDocumentFailure,
  GetDocumentsFailure,
  GetDocumentsByActivityFailure,
  GetDocumentsByTaskFailure,
  GetDocumentsByCorrespondenceFailure,
  GetDocumentFailure,
  AddDocumentsFailure,
  UpdateDocumentFailure,
  UpdateTaskDocumentFailure,
  DeleteDocumentFailure,
  DeleteDocumentsFailure,
  DeleteActivityDocumentsFailure,
  DeleteTaskDocumentsFailure
} from '../actions/document.actions';
import { MediaService } from '@app/modules/main-documents/services/media.service';
import { environment } from '@environments/environment';
import { DocumentExtended } from '@app/models';
import { DocumentService } from '@app/modules/reusable/documents/services/document.service';

@Injectable()
export class DocumentEffects {

  getDocuments$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocuments>(EDocumentsActions.GET_DOCUMENTS),
    switchMap(action => {
      return this.documentService.getDocuments(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetDocumentsSuccess({ paginator: resp, filters: action.payload.filters })];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetDocumentsFailure(error));
        })
      );
    }),
    share()
  ));

  getDocumentsByActivity$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocumentsByActivity>(EDocumentsActions.GET_DOCUMENTS_BY_ACTIVITY),
    switchMap(action => {
      return this.documentService.getDocuments(1, 100, { 'activity.id': action.payload, ...action.filters }).pipe(
        mergeMap(resp => {
          return [new GetDocumentsByActivitySuccess(resp.member)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetDocumentsByActivityFailure(error));
        })
      );
    }),
    share()
  ));


  getDocumentsByTask$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocumentsByTask>(EDocumentsActions.GET_DOCUMENTS_BY_TASK),
    switchMap(action => {
      // task.id=${taskId}
      return this.documentService.getDocuments(1, 100, { 'task.id': action.payload, ...action.filters }).pipe(
        mergeMap(resp => {
          return [new GetDocumentsByTaskSuccess(resp.member)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetDocumentsByTaskFailure(error));
        })
      );
    }),
    share()
  ));


  getDocumentsByCorrespondence$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocumentsByCorrespondence>(EDocumentsActions.GET_DOCUMENTS_BY_CORRESPONDENCE),
    switchMap(action => {
      return this.documentService.getDocuments(1, 100, { 'correspondence.id': action.payload, ...action.filters }).pipe(
        mergeMap(resp => {
          return [new GetDocumentsByCorrespondenceSuccess(resp.member)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetDocumentsByCorrespondenceFailure(error));
        })
      );
    }),
    share()
  ));


  getDocumentsByContract$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocumentsByContract>(EDocumentsActions.GET_DOCUMENTS_BY_CONTRACT),
    switchMap(action => {
      return this.documentService.getDocumentsByContract(action.payload).pipe(
        mergeMap(resp => {
          return [new GetDocumentsByContractSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetDocumentsByContract(error));
        })
      );
    }),
    share()
  ));

  getDocumentsByBill$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocumentsByBill>(EDocumentsActions.GET_DOCUMENTS_BY_BILL),
    switchMap(action => {
      return this.documentService.getDocumentsByBill(action.payload).pipe(
        filter(x => !!x),
        mergeMap(resp => {
          return [new GetDocumentsByBillSuccess(resp)];
        })
      );
    }),
    share()
  ));


  getDocumentById$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocument>(EDocumentsActions.GET_DOCUMENT),
    switchMap(action => {
      return this.documentService.getDocumentById(action.payload).pipe(
        mergeMap(resp => {
          return [new GetDocumentSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetDocumentFailure(error));
        })
      );
    }),
    share()
  ));


  getDocumentByIdSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocumentSuccess>(EDocumentsActions.GET_DOCUMENT_SUCCESS),
    map(action => action.payload)
  ), { dispatch: false });

  addDocument$ = createEffect(() => this.actions$.pipe(
    ofType<AddDocument>(EDocumentsActions.ADD_DOCUMENT),
    switchMap(action => {
      if (action.payload.mediaFile) {
        return this.mediaService.addMedia(action.payload.mediaFile, 'document').pipe(
          map(x => {
            if (x) {
              action.payload.document.media = x.id;
              return action.payload.document;
            }
          }),
          switchMap(document => {
            if (document) {
              return this.documentService.addDocument(document).pipe(
                switchMap(documentExtended => {
                  if (action.payload.reminders.reminderDate && action.payload.reminders.description) {
                    action.payload.reminders.document = documentExtended.id;
                    this.documentService.postDocumentReminder(action.payload.reminders).subscribe(() => {});
                  }
                  if (action.payload.relations && action.payload.relations.length) {
                    for (const relation of action.payload.relations) {
                      relation.source = documentExtended.id;
                      delete relation.selected;
                      this.documentService.createDocRelationship(relation).subscribe(() => {});
                    }
                  }
                  return of(new AddDocumentSuccess(documentExtended));
                }),
                catchError(error => {
                  // will add error handler later
                  return of(new AddDocumentFailure(error));
                })
              );
            } else {
              return of(new AddDocumentFailure(new Error()));
            }
          }),
          catchError(error => {
            // will add error handler later
            return of(new AddDocumentFailure(error));
          })
        );
      } else {
        return this.documentService.addDocument(action.payload.document).pipe(
          switchMap(documentExtended => {
            if (action.payload.reminders.reminderDate && action.payload.reminders.description) {
              action.payload.reminders.document = documentExtended.id;
              this.documentService.postDocumentReminder(action.payload.reminders).subscribe(() => {});
            }
            return of(new AddDocumentSuccess(documentExtended));
          }),
          catchError(error => {
            // will add error handler later
            return of(new AddDocumentFailure(error));
          })
        );
      }
    }),
    share()
  ));


  addDocumentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddDocumentSuccess>(EDocumentsActions.ADD_DOCUMENT_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetDocuments({
          page: (storeState.documentState.paginator && storeState.documentState.paginator.currentPage) || environment.defaultPage,
          itemsPerPage:
            (storeState.documentState.paginator && storeState.documentState.paginator.itemsPerPage) || environment.defaultOptionsCount,
          filters: storeState.documentState.documentFilters
        })
      );
    })
  ));


  addDocuments$ = createEffect(() => this.actions$.pipe(
    ofType<AddDocuments>(EDocumentsActions.ADD_DOCUMENTS),
    switchMap(action => {
      const methods = [];
      action.payload.forEach(x => {
        if (x.mediaFile) {
          methods.push(
            this.mediaService.addMedia(x.mediaFile, 'document').pipe(
              map(media => {
                x.document.media = media.id;
                return x.document;
              }),
              switchMap(document => {
                return this.documentService.addDocument(document).pipe(
                  switchMap(documentExtended => {
                    if (x.reminders.reminderDate && x.reminders.description) {
                      x.reminders.document = documentExtended.id;
                      this.documentService.postDocumentReminder(x.reminders).subscribe(() => {});
                    }
                    if (x.relations && x.relations.length) {
                      for (const relation of x.relations) {
                        relation.source = documentExtended.id;
                        delete relation.selected;
                        this.documentService.createDocRelationship(relation).subscribe(() => {});
                      }
                    }
                    return of(new AddDocumentSuccess(documentExtended));
                  }),
                  catchError(error => {
                    // will add error handler later
                    return of(new AddDocumentsFailure(error));
                  })
                );
              }),
              catchError((error) => {
                // will add error handler later
                return of(new AddDocumentsFailure(error));
              })
            )
          );
        } else if (x.document.cloudLink) {
          methods.push(
            this.documentService.addDocument(x.document).pipe(
              switchMap(documentExtended => {
                if (x.reminders.reminderDate && x.reminders.description) {
                  x.reminders.document = documentExtended.id;
                  this.documentService.postDocumentReminder(x.reminders).subscribe(() => {});
                }
                if (x.relations && x.relations.length) {
                  for (const relation of x.relations) {
                    relation.source = documentExtended.id;
                    delete relation.selected;
                    this.documentService.createDocRelationship(relation).subscribe(() => {});
                  }
                }
                return of(new AddDocumentSuccess(documentExtended));
              }),
              catchError(error => {
                // will add error handler later
                return of(new AddDocumentFailure(error));
              })
            )
          );
        }
      });
      return forkJoin(methods).pipe(
        mergeMap((x: DocumentExtended[]) => {
          return of(new AddDocumentsSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddDocumentsFailure(error));
        })
      );
    }),
    share()
  ));


  addDocumentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddDocumentsSuccess>(EDocumentsActions.ADD_DOCUMENTS_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetDocuments({
          page: storeState.documentState.paginator.currentPage,
          itemsPerPage: storeState.documentState.paginator.itemsPerPage,
          filters: storeState.documentState.documentFilters
        })
      );
    })
  ));

  updateDocument$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateDocument>(EDocumentsActions.UPDATE_DOCUMENT),
    switchMap(action => {
      return this.documentService.updateDocument(action.payload.document).pipe(
        switchMap(documentExtended => {
          if (action.payload.relations && action.payload.relations.length) {
            for (const relation of action.payload.relations) {
              relation.source = documentExtended.id;
              delete relation.selected;
              this.documentService.createDocRelationship(relation).subscribe(() => {});
            }
          }
          return of(new UpdateDocumentSuccess(documentExtended));
        }),
        catchError(error => {
          // will add error handler later
          return of(new UpdateDocumentFailure(error));
        })
      );
    }),
    share()
  ));


  updateTaskDocument$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTaskDocument>(EDocumentsActions.UPDATE_TASK_DOCUMENT),
    switchMap(action => {
      return this.documentService.updateDocument(action.payload.document).pipe(
        switchMap(document => {
          if (action.payload.relations && action.payload.relations.length) {
            for (const relation of action.payload.relations) {
              relation.source = document.id;
              delete relation.selected;
              this.documentService.createDocRelationship(relation).subscribe(() => {});
            }
          }
          return of(new UpdateTaskDocumentSuccess(action.payload.task));
        }),
        catchError(error => {
          // will add error handler later
          return of(new UpdateTaskDocumentFailure(error));
        })
      );
    }),
    share()
  ));


  updateTaskDocumentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTaskDocumentSuccess>(EDocumentsActions.UPDATE_TASK_DOCUMENT_SUCCESS),
    switchMap(action => {
      return of(new GetDocumentsByTask(action.payload));
    })
  ));


  deleteDocument$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocument>(EDocumentsActions.DELETE_DOCUMENT),
    switchMap(action => {
      return this.documentService.deleteDocument(action.payload).pipe(
        switchMap(() => {
          return of(new DeleteDocumentSuccess(action.payload));
        }),
        catchError(error => {
          // will add error handler later
          return of(new DeleteDocumentFailure(error));
        })
      );
    }),
    share()
  ));


  deleteDocumentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocumentSuccess>(EDocumentsActions.DELETE_DOCUMENT_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetDocuments({
          page: (storeState.documentState.paginator && storeState.documentState.paginator.currentPage) || environment.defaultPage,
          itemsPerPage:
            (storeState.documentState.paginator && storeState.documentState.paginator.itemsPerPage) || environment.defaultOptionsCount,
          filters: storeState.documentState.documentFilters
        })
      );
    })
  ));

  deleteDocumentAndClearMedia$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocumentSuccess>(EDocumentsActions.DELETE_DOCUMENT_SUCCESS),
    tap(() => {})
  ), { dispatch: false });

  deleteDocuments$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocuments>(EDocumentsActions.DELETE_DOCUMENTS),
    switchMap(action => {
      const methods = [];
      action.payload.forEach(x => {
        methods.push(this.documentService.deleteDocument(x));
      });
      return forkJoin(methods).pipe(
        switchMap(() => {
          return of(new DeleteDocumentsSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteDocumentsFailure(error));
        })
      );
    }),
    share()
  ));

  deleteDocumentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocumentsSuccess>(EDocumentsActions.DELETE_DOCUMENTS_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetDocuments({
          page: storeState.documentState.paginator.currentPage,
          itemsPerPage: storeState.documentState.paginator.itemsPerPage,
          filters: storeState.documentState.documentFilters
        })
      );
    })
  ));

  deleteActivityDocuments$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteActivityDocuments>(EDocumentsActions.DELETE_ACTIVITY_DOCUMENTS),
    switchMap(action => {
      const methods = [];
      action.payload.documents.forEach(x => {
        methods.push(this.documentService.deleteDocument(x));
      });
      return forkJoin(methods).pipe(
        switchMap(() => {
          return of(new DeleteActivityDocumentsSuccess(action.payload.activity));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteActivityDocumentsFailure(error));
        })
      );
    }),
    share()
  ));

  deleteActivityDocumentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteActivityDocumentsSuccess>(EDocumentsActions.DELETE_ACTIVITY_DOCUMENTS_SUCCESS),
    switchMap(action => {
      return of(new GetDocumentsByActivity(action.payload));
    })
  ));


  deleteTaskDocuments$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTaskDocuments>(EDocumentsActions.DELETE_TASK_DOCUMENTS),
    switchMap(action => {
      const methods = [];
      action.payload.documents.forEach(x => {
        methods.push(this.documentService.deleteDocument(x));
      });
      return forkJoin(methods).pipe(
        switchMap(() => {
          return of(new DeleteTaskDocumentsSuccess(action.payload.task));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteTaskDocumentsFailure(error));
        })
      );
    }),
    share()
  ));

  deleteTaskDocumentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTaskDocumentsSuccess>(EDocumentsActions.DELETE_TASK_DOCUMENTS_SUCCESS),
    switchMap(action => {
      return of(new GetDocumentsByTask(action.payload));
    })
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private documentService: DocumentService,
    private mediaService: MediaService,
  ) {}
}
