import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  static get currentDateFormat() {
    return this.dateFormat;
  }

  static get currentDateTimeFormat() {
    return this.dateTimeFormat;
  }
  static dateFormat = 'dd.MM.yyyy';
  static dateFilterFormat = 'YYYY-MM-DD';
  static dateTimeFormat = 'dd.MM.yyyy HH:mm';
  static hoursFormat = 'HH:mm';
  static startDate = new Date('1970');

  static getStartOfWeek(): Moment {
    return moment(new Date()).startOf('isoWeek');
  }

  static getEndOfWeek(): Moment {
    return moment(new Date()).endOf('isoWeek');
  }

  static getStartOfMonth(): Moment {
    return moment(new Date()).startOf('month');
  }

  static getEndOfMonth(): Moment {
    return moment(new Date()).endOf('month');
  }

  static getStartOfYear(date = new Date()): Moment {
    return moment(date).startOf('year');
  }

  static getEndOfYear(date = new Date()): Moment {
    return moment(date).endOf('year');
  }

  static getDateFormattedString(date: Date, format: string): string {
    const dateToFormat = moment(date);
    const formattedDate = dateToFormat.format(format);
    return formattedDate;
  }

  static getDateMoment(date: Date): Moment {
    const dateToFormat = moment(date);
    return dateToFormat;
  }

  static convertToUniversalDateString(date: Date | Moment) {
    const dateToFormat = moment(date).startOf('day');
    const formattedDate = dateToFormat.format('YYYY-MM-DD');
    return formattedDate;
  }

  static convertToUniversalString(date) {
    const dateToFormat = moment(date);
    const formattedDate = dateToFormat.format('DD.MM.YYYY HH:mm');
    return formattedDate;
  }

  static convertToUniversalDottedString(date, separator = '-') {
    const dateToFormat = moment(date);
    const formattedDate = dateToFormat.format(`YYYY.MM.DD ${separator} HH:mm`);
    return formattedDate;
  }

  static convertToDate(date: Date, dateFormat = this.dateFormat.toUpperCase()): string {
    return moment(date).format(dateFormat);
  }

  static convertToDateWithDefaulFormat(date: Date): string {
    return moment(date, this.dateFormat.toUpperCase()).format(this.dateFormat.toUpperCase());
  }


  static today(dateFormat = this.dateFormat.toUpperCase()): string {
    return moment().format(dateFormat);
  }

  static getEndOfTheCurrentDayUniversalString(date) {
    return `${CustomDateAdapter.convertToUniversalDateString(date)}T${23}:${59}:${59}`;
  }
  static getStartOfTheCurrentDayUniversalString(date) {
    return `${CustomDateAdapter.convertToUniversalDateString(date)}`;
  }
  static getDateWithTimeZ(date) {
    return `${CustomDateAdapter.convertToUniversalDateString(new Date(date))}T${new Date(date).getHours()}:${new Date(
      date
    ).getMinutes()}:00`;
  }

  static getDateWithTimeUtcZ(date) {
    return `${CustomDateAdapter.convertToUniversalDateString(new Date(date))}T${new Date(date).getUTCHours()}:${new Date(
      date
    ).getUTCMinutes()}:00`;
  }

  static getDateToNetworkFormat(date) {
    const timestamp = Date.parse(date);
    let formatDate = date;

    if (typeof date === 'string' && date.includes('.')) {
      return formatDate;
    }
    if (isNaN(timestamp)) {
     formatDate = new Date(date.split('.').reverse().join('-'));
    } else {
      formatDate = new Date(date);
    }
    return moment(formatDate).format('YYYY-MM-DD');
  }
  static getDiffInHours(startDate, endDate): number {
    return moment(endDate).diff(moment(startDate), 'seconds');
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
