import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RoutesService } from '@app/helpers/routes.service';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router, private routesService: RoutesService) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.saveUrl(state.url);
    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['/auth/login']);
    return false;
  }

  private saveUrl(url: string) {
    this.routesService.setAfterLoginUrl(url);
  }
}
