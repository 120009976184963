// generated by the plop

import { ChatModel, MessageModel } from '@app/chat/symbols/symbols';
import { PROGRESS_STATUSES } from '@app/shared/symbols';

export interface ChatStateModel {
  currentChat: ChatModel;
  isExpanded: boolean;
  chatMessages: Partial<MessageModel>[];
  chatPinnedMessages: Partial<MessageModel>[];
  loadingMessagesStatus: PROGRESS_STATUSES;
  createMessageStatus: PROGRESS_STATUSES;
  readMessagesStatus: PROGRESS_STATUSES;
  updateMessageStatus: PROGRESS_STATUSES;
  changePinStatus: { [messageId: number | string]: PROGRESS_STATUSES };
  removeMessagesStatus: { [messageId: number | string]: PROGRESS_STATUSES };
  countPinMessages: number;
  entityId: number;
  entityName: string;
}

export const CHAT_STATE_DEFAULT: ChatStateModel = {
  currentChat: null,
  chatPinnedMessages: [],
  chatMessages: [],
  isExpanded: false,
  loadingMessagesStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
  createMessageStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
  readMessagesStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
  updateMessageStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
  changePinStatus: {},
  removeMessagesStatus: {},
  countPinMessages: 0,
  entityId: null,
  entityName: null,
};
