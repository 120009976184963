import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, withLatestFrom, share, tap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import {
  GetTasks,
  ETaskActions,
  GetTasksSuccess,
  GetTask,
  GetTaskSuccess,
  AddTask,
  AddTaskSuccess,
  AddTaskWithDocuments,
  AddTaskWithDocumentsSuccess,
  UpdateTask,
  UpdateTaskSuccess,
  DeleteTask,
  DeleteTaskSuccess,
  UpdateTaskStatus,
  UpdateTaskStatusSuccess,
  GetTasksFailure,
  GetTaskFailure,
  AddTaskFailure,
  AddTaskWithDocumentsFailure,
  UpdateTaskFailure,
  UpdateTaskStatusFailure,
  DeleteTaskFailure
} from '@app/store/actions/task.actions';
import { TasksService } from '@app/modules/main-tasks/services/tasks.service';
import { AddDocuments } from '@app/store/actions/document.actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TaskExtended } from '@app/models';
import { environment } from '@environments/environment';
import { GetNotifications } from '@app/modules/layout/notifications/store/actions/notification.actions';

@Injectable()
export class TaskEffects {

  getTasks$ = createEffect(() => this.actions$.pipe(
    ofType<GetTasks>(ETaskActions.GET_TASKS),
    switchMap(action => {
      return this.taskService.getTasks(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetTasksSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetTasksFailure(error));
        })
      );
    }),
    share()
  ));

  getTask$ = createEffect(() => this.actions$.pipe(
    ofType<GetTask>(ETaskActions.GET_TASK),
    switchMap(action => {
      return this.taskService.getTaskById(action.payload).pipe(
        mergeMap(resp => {
          return [new GetTaskSuccess(resp)];
        }),
        catchError(error => {
          // will add error handler later
          return of(new GetTaskFailure(error));
        })
      );
    }),
    share()
  ));

  addTask$ = createEffect(() => this.actions$.pipe(
    ofType<AddTask>(ETaskActions.ADD_TASK),
    switchMap(action => {
      return this.taskService.addTask(action.payload.task).pipe(
        switchMap((res: TaskExtended) => {
          if (action.payload.reminder.check === true) {
            if (action.payload.reminder.reminders.id == null && action.payload.reminder.reminders.reminderDate) {
              action.payload.reminder.reminders.task = res.id;
              this.taskService.postTaskReminder(action.payload.reminder.reminders).subscribe(() => {});
            }
          }
          this.store$.dispatch(new GetNotifications({ page: null, itemsPerPage: 10, renew: true }));

          if (res.type === 'task') {
            this.toastrService.success(this.translate.instant('taskAdded'));
          } else if (res.type === 'event') {
            this.toastrService.success(this.translate.instant('eventAdded'));
          }
          return of(new AddTaskSuccess(res));
        }),
        catchError(error => of(new AddTaskFailure(error)))
      );
    }),
    share()
  ));


  addTaskWithDocuments$ = createEffect(() => this.actions$.pipe(
    ofType<AddTaskWithDocuments>(ETaskActions.ADD_TASK_WITH_DOCUMENTS),
    switchMap(action => {
      return this.taskService.addTask(action.payload.task).pipe(
        map(resp => {
          action.payload.documents.forEach(x => {
            x.document.task = resp.id;
          });
          return resp;
        }),
        switchMap((res: TaskExtended) => {
          if (action.payload.reminder.check === true) {
            if (action.payload.reminder.reminders.id == null && action.payload.reminder.reminders.reminderDate) {
              action.payload.reminder.reminders.task = res.id;
              this.taskService.postTaskReminder(action.payload.reminder.reminders).subscribe(() => {});
            }
          }
          if (res.type === 'task') {
            this.toastrService.success(this.translate.instant('taskAdded'));
            return [new AddDocuments(action.payload.documents), new AddTaskWithDocumentsSuccess(res)];
          } else if (res.type === 'event') {
            this.toastrService.success(this.translate.instant('eventAdded'));
            return [new AddDocuments(action.payload.documents), new AddTaskWithDocumentsSuccess(res)];
          }
          // this.toastrService.success(this.translate.instant('taskAdded'));
          // return [new AddDocuments(action.payload.documents), new AddTaskWithDocumentsSuccess(res)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddTaskWithDocumentsFailure(error));
        })
      );
    }),
    share()
  ));

  addTaskWithDocumentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddTaskWithDocumentsSuccess>(ETaskActions.ADD_TASK_WITH_DOCUMENTS_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      let pageSize = environment.defaultItemsCount;
      let page = 1;
      if (storeState.taskState.paginator) {
        pageSize = storeState.taskState.paginator.itemsPerPage;
        page = storeState.taskState.paginator.currentPage;
      }
      return of(new GetTasks({ itemsPerPage: pageSize, page, filters: storeState.taskState.filters }));
    }),
    catchError((error) => {
      // will add error handler later
      return of(new AddTaskWithDocumentsFailure(error));
    })
  ));

  updateTask$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTask>(ETaskActions.UPDATE_TASK),
    switchMap(action => {
      return this.taskService.updateTask(action.payload.task).pipe(
        map(x => {
          if (x) {
            return x;
          }
        }),
        switchMap((res: TaskExtended) => {
          if (action.payload.reminder) {
            // створення нового нагадування при редагуванні
            if (action.payload.reminder.check === true) {
              if (action.payload.reminder.reminders.id == null && action.payload.reminder.reminders.reminderDate) {
                action.payload.reminder.reminders.task = res.id;
                this.taskService.postTaskReminder(action.payload.reminder.reminders).subscribe(() => {});
              }
            }
            // видалення нагадування при редагуванні
            if (!action.payload.reminder.check && action.payload.reminder.reminders && action.payload.reminder.reminders.id) {
              this.taskService.deleteTaskReminder(action.payload.reminder.reminders).subscribe(() => {});
            }
            // редагування нагадування при редагуванні
            if (action.payload.reminder.check && action.payload.reminder.reminders && action.payload.reminder.reminders.id) {
              this.taskService.putTaskReminder(action.payload.reminder.reminders).subscribe(() => {});
            }
          }
          return of(new UpdateTaskSuccess(res));
        }),
        catchError(error => of(new UpdateTaskFailure(error)))
      );
    }),
    share()
  ));


  updateTaskSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTaskSuccess>(ETaskActions.UPDATE_TASK_SUCCESS),
    tap(x => {
      if (x.payload && x.payload.type === 'task') {
        this.toastrService.success(this.translate.instant('taskEdited'));
      } else if (x.payload && x.payload.type === 'event') {
        this.toastrService.success(this.translate.instant('eventEdited'));
      }
    })
  ), { dispatch: false });


  updateTaskStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTaskStatus>(ETaskActions.UPDATE_TASK_STATUS),
    switchMap(action => {
      return this.taskService.updateTaskStatus(action.payload.id, action.payload.status).pipe(
        mergeMap(x => {
          if (x) {
            if (x.type === 'task') {
              if (x.status === 'done') {
                this.toastrService.success(this.translate.instant('taskDone'));
              } else {
                this.toastrService.success(this.translate.instant('taskEdited'));
              }
            } else if (x.type === 'event') {
              if (x.status === 'done') {
                this.toastrService.success(this.translate.instant('eventDone'));
              } else {
                this.toastrService.success(this.translate.instant('eventEdited'));
              }
            }
            return of(new UpdateTaskStatusSuccess(x));
          }
        }),
        catchError(error => {
          // will add error handler later
          return of(new UpdateTaskStatusFailure(error));
        })
      );
    }),
    share()
  ));


  // updateTaskStatusSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType<UpdateTaskStatusSuccess>(ETaskActions.UPDATE_TASK_STATUS_SUCCESS),
  //   tap(() => {
  //     // this.location.back();
  //   })
  // ), { dispatch: false });


  deleteTask$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTask>(ETaskActions.DELETE_TASK),
    switchMap(action => {
      return this.taskService.deleteTask(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('itemDeleted'));
          this.store$.dispatch(new GetNotifications({ page: null, itemsPerPage: 10, renew: true }));
          return of(new DeleteTaskSuccess(action.payload));
        }),
        catchError(error => {
          // TODO will add error handler later
          return of(new DeleteTaskFailure(error));
        })
      );
    }),
    share()
  ));


  deleteTaskSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteTaskSuccess>(ETaskActions.DELETE_TASK_SUCCESS),
    switchMap(() => {
      return of(new GetTasksSuccess(null));
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private taskService: TasksService,
    private store$: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}
}
