import { CompanySubSettings, CompanySettings } from '@app/models/company-settings';
import { SubscriptionSub } from '@app/models/subscription-sub';
import { SubTariff } from '@app/models/sub-tariff';
import { Tariff } from '@app/models/tariff';

export interface CompanySettingsState {
  companySetting: CompanySettings;
  loading: boolean;
  error: Error;
  subscriptionSubs: SubscriptionSub[];
  companySubSettings: CompanySubSettings[];
  subTariffs: SubTariff[];
  tariffs: Tariff[];
  subscribedSubscription: SubscriptionSub[];
}

export const initialStateCompanySetting: CompanySettingsState = {
  companySetting: null,
  loading: false,
  error: undefined,
  subscriptionSubs: null,
  companySubSettings: null,
  subTariffs: null,
  tariffs: null,
  subscribedSubscription: null
};
