import { ICompanyPortalSettingsState, initialCompanyPortalSettings } from '../state/company-portal-settings.state';
import { CompanyPortalSettingsActions, ECompanyPortalSettingsActions } from '../actions/company-portal-settings.action';
import { PROGRESS_STATUSES } from '@app/shared/symbols';

export const companyPortalSettingsReducers = (
  state = initialCompanyPortalSettings,
  action: CompanyPortalSettingsActions,
): ICompanyPortalSettingsState => {
  switch (action.type) {
    case ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTINGS_SUCCESS: {
      return {
        ...state,
        companyPortalSettings: action.payload,
      };
    }

    case ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTING: {
      return {
        ...state,
        companyPortalSetting: null,
      };
    }
    case ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTING_SUCCESS: {
      return {
        ...state,
        companyPortalSetting: action.payload,
      };
    }
    case ECompanyPortalSettingsActions.POST_COMPANY_PORTAL_SETTINGS_SUCCESS: {
      if (state.companyPortalSettings.member) {
        state.companyPortalSettings.member.push(action.payload);
      }
      return {
        ...state,
      };
    }
    case ECompanyPortalSettingsActions.PUT_COMPANY_PORTAL_SETTINGS_SUCCESS: {

      return {
        ...state,
      };
    }

    case ECompanyPortalSettingsActions.DELETE_COMPANY_PORTAL_SETTINGS_SUCCESS: {
      return {
        ...state,
        companyPortalSettings: {
          countItems: state.companyPortalSettings.countItems,
          member: state.companyPortalSettings.member?.filter(item => item.id !== action.payload),
          totalItems: state.companyPortalSettings.totalItems,
          currentPage: state.companyPortalSettings.currentPage,
          itemsPerPage: state.companyPortalSettings.itemsPerPage,
          lastPage: state.companyPortalSettings.lastPage,
        },
      };
    }

    case ECompanyPortalSettingsActions.PUT_COMPANY_USER_PORTAL_ACCESS: {
      return {
        ...state,
        putCompanyUserPortalAccessStatus: PROGRESS_STATUSES.IN_PROGRESS,
      };
    }

    case ECompanyPortalSettingsActions.PUT_COMPANY_USER_PORTAL_ACCESS_SUCCESS: {
      return {
        ...state,
        putCompanyUserPortalAccessStatus: PROGRESS_STATUSES.SUCCEED,
      };
    }

    case ECompanyPortalSettingsActions.PUT_COMPANY_USER_PORTAL_ACCESS_FAILURE: {
      return {
        ...state,
        putCompanyUserPortalAccessStatus: PROGRESS_STATUSES.INTERRUPTED,
      };
    }


    default: {
      return state;
    }
  }
};
