import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/_services/auth.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';
import { DocumentExtended, ResponseBodyFull } from '@app/models';
import { DocRelation } from '@app/models/doc-relation';
import { DocumentSave } from '@app/models/document-save';
import { DocumentsFilters } from '@app/models/documents-filters';
import { DocHistory } from '@app/models/interfaces/doc-history';
import { Paginator } from '@app/models/paginator';
import { ReminderExtended } from '@app/models/reminder-extended';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  private BASE_URL = `${environment.apiUrl}/document_reminders`;
  private BASE_URL_DOC = `${environment.apiUrl}/documents`;
  internalDateFrom = 'media.createdAt[after]';
  internalDateTo = 'media.createdAt[before]';

  getDocuments(
    currentPage = 1,
    itemsPerPage = environment.defaultItemsCount,
    filters: DocumentsFilters = null
  ): Observable<Paginator<DocumentExtended>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (filters) {
      filters[this.internalDateTo] = filters[this.internalDateTo]
        ? CustomDateAdapter.convertToDate(new Date(filters[this.internalDateTo]), CustomDateAdapter.dateFilterFormat)
        : '';
      filters[this.internalDateFrom] = filters[this.internalDateFrom]
        ? CustomDateAdapter.convertToDate(new Date(filters[this.internalDateFrom]), CustomDateAdapter.dateFilterFormat)
        : '';

      params = Helpers.populateFilters(params, filters, true);
    }
    return this.http
      .get<ResponseBodyFull<DocumentExtended>>(this.BASE_URL_DOC, { params })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
  }
  getDocumentReminder(id): Observable<ReminderExtended[]> {
    return this.http.get<ResponseBodyFull<ReminderExtended>>(this.BASE_URL + `?document.id=${id}`).pipe(map(response => response['hydra:member']));
  }

  postDocumentReminder(reminder) {
    if (reminder.reminderDate != null && reminder.description.length > 0) {
      return this.http.post<ReminderExtended>(this.BASE_URL, reminder);
    }
  }

  putDocumentReminder(reminder) {
    if (reminder.reminderDate != null && reminder.description.length > 0) {
      return this.http.patch<ReminderExtended>(`${this.BASE_URL}/${reminder.id}`, reminder);
    }
  }

  deleteDocumentReminder(reminder) {
    return this.http.delete(`${this.BASE_URL}/${reminder.id}`, reminder);
  }

  getDocumentsByActivity(activityId: number): Observable<DocumentExtended[]> {
    const queryString = `${environment.apiUrl}/documents?activity.id=${activityId}`;
    return this.http.get<ResponseBodyFull<DocumentExtended>>(queryString).pipe(map(response => response['hydra:member']));
  }

  getDocumentsByTask(taskId: number): Observable<DocumentExtended[]> {
    const queryString = `${environment.apiUrl}/documents?task.id=${taskId}`;
    return this.http.get<ResponseBodyFull<DocumentExtended>>(queryString).pipe(map(response => response['hydra:member']));
  }

  getDocumentsByCorrespondence(correspondenceId: number): Observable<DocumentExtended[]> {
    const queryString = `${environment.apiUrl}/documents?correspondence.id=${correspondenceId}`;
    return this.http.get<ResponseBodyFull<DocumentExtended>>(queryString).pipe(map(response => response['hydra:member']));
  }

  getDocumentsByContract(contractId: number): Observable<DocumentExtended[]> {
    const queryString = `${environment.apiUrl}/documents?contract.id=${contractId}`;
    return this.http.get<ResponseBodyFull<DocumentExtended>>(queryString).pipe(map(response => response['hydra:member']));
  }

  getDocumentsByContact(contactId: number): Observable<DocumentExtended[]> {
    const queryString = `${environment.apiUrl}/documents?contact.id=${contactId}`;
    return this.http.get<ResponseBodyFull<DocumentExtended>>(queryString).pipe(map(response => response['hydra:member']));
  }
  getDocumentsByContactCard(contactId: number, itemsPerPage, docCategoryId): Observable<DocumentExtended[]> {
    const queryString = `${environment.apiUrl}/documents?contact.id=${contactId}&itemsPerPage=${itemsPerPage}&category.id=${docCategoryId}`;
    return this.http.get<ResponseBodyFull<DocumentExtended>>(queryString).pipe(map(response => response['hydra:member']));
  }

  getDocumentsByBill(billId: number): Observable<DocumentExtended[]> {
    const queryString = `${environment.apiUrl}/documents?bill.id=${billId}`;
    return this.http.get<ResponseBodyFull<DocumentExtended>>(queryString).pipe(map(response => response['hydra:member']));
  }

  getDocumentById(id: number): Observable<DocumentExtended> {
    return this.http.get<DocumentExtended>(`${environment.apiUrl}/documents/${id}`);
  }

  addDocument(document: DocumentSave): Observable<DocumentExtended> {
    return this.http.post<DocumentExtended>(`${environment.apiUrl}/documents`, document);
  }

  updateDocument(document: DocumentSave): Observable<DocumentExtended> {
    return this.http
      .patch<DocumentExtended>(`${environment.apiUrl}/documents/${document.id}`, document);
  }

  deleteDocument(id: number) {
    return this.http.delete(`${environment.apiUrl}/documents/${id}`);
  }

  getRelationTree(id) {
    // document_relationships
    return this.http.get<DocRelation>(`${environment.apiUrl}/document_relationships/tree/${id}`);
  }
  getRelations(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters: any = null) {
    // document_relationships

    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
      .get<ResponseBodyFull<DocRelation>>(`${environment.apiUrl}/document_relationships`, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<DocRelation>>();
    }
  }
  createDocRelationship(data) {
    return this.http.post<DocRelation>(`${environment.apiUrl}/document_relationships`, data);
  }
  deleteDocRelationship(id) {
    return this.http.delete(`${environment.apiUrl}/document_relationships/${id}`);
  }
  editDocRelationship(data) {
    return this.http.patch<DocRelation>(`${environment.apiUrl}/document_relationships/${data.id}`, data);
  }

  getContactEmails(name) {
    // document_relationships
    return this.http.get<ResponseBodyFull<any>>(`${environment.apiUrl}/contact_emails?name=${name}`).pipe(map(response => response['hydra:member']));
  }
  sendDocEmails(docId, data) {
    // document_relationships
    return this.http.post<DocumentExtended>(`${environment.apiUrl}/documents/${docId}/send-email`, data);
  }

  getDocLogs(idDocument: number) {
    return this.http.get<ResponseBodyFull<DocHistory>>(`${environment.apiUrl}/logs?parent=document&parentId=${idDocument}`).pipe(map(response => response['hydra:member']));
  }
  deleteEmailDocLink(id: number) {
    return this.http.delete(`${environment.apiUrl}/document_email_links/${id}`);
  }

  getDocuSignUrl(id: number) {
    return this.http.get(`${environment.apiUrl}/docusign/document/${id}/get-view-url`);
  }
}
