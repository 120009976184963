import { EventEmitter } from '@angular/core';
import { ICommunication, ICommunicationFilters } from '@app/models/jusnote-connect-massage';
import { Paginator } from '@app/models/paginator';

export class CommunicationPublicService {
  public currentCommunication: ICommunication = null;
  onCommunication: EventEmitter<ICommunication> = new EventEmitter();

  public setCommunication(communication) {
    this.currentCommunication = communication;
    this.onCommunication.emit(this.currentCommunication);
  }

  public listCommunication: Paginator<ICommunication> = null;
  onLoadCommunication: EventEmitter<Paginator<ICommunication>> = new EventEmitter();

  public setCommunications(communications) {
    this.listCommunication = communications;
    this.onLoadCommunication.emit(this.listCommunication);
  }

  public filters: ICommunicationFilters = null;
  onFilters: EventEmitter<ICommunicationFilters> = new EventEmitter();

  public setFilters(filters) {
    this.filters = filters;
    this.onFilters.emit(this.filters);
  }
}
