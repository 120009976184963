import { Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { fadeLeftAnimation } from '@app/helpers/fadeAnimation';
import { Team } from '@app/models/team';
import { ICustomRate } from '@app/models/сustom-rate';
import { TeamService } from '@app/modules/team/services/team.service';
import { environment } from '@environments/environment';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { DebounceService } from '../../helpers/debounce.service';

@Component({
  selector: 'app-custom-rates',
  templateUrl: './custom-rates.component.html',
  styleUrls: ['./custom-rates.component.scss'],
  animations: [fadeLeftAnimation]
})
export class CustomRatesComponent implements OnInit {
  constructor(public fb: UntypedFormBuilder, private teamService: TeamService, private debounceService: DebounceService) {
    this.createForm();
  }

  get customRates(): UntypedFormArray {
    return this.form.get('customRates') as UntypedFormArray;
  }
  customRatesIndex: number[] = [];

  @ViewChild('sample', { static: false })
  public comboBoxObj: ComboBoxComponent;
  allowCurrent = false;
  form: UntypedFormGroup;
  @Input() companyWorkGroups: Team[] = []; // незмінюваний масив
  @Input() currency: string;
  @Input() currentCustomRaiting: ICustomRate[] = []; // кастом рейти які прийшли коли редагування проекту/контракту
  @Input() keyLoadData = 'customRatesCompanyWorkGroup';
  submittedForm = false;
  submittedMatter = false;

  dataCompanyWorkGroups = []; // масиви для кожного комбобокса

  @ViewChildren('dropDown') dropDowns;

  filtersGroup = {
    'exists[matter]': 'false',
    'exists[contact]': 'false',
    status: 'enabled',
    name: ''
  };
  ngOnInit(): void {
    if (this.currentCustomRaiting && this.currentCustomRaiting.length) {
      for (let i = 0; i < this.currentCustomRaiting.length; i++) {
        this.startInitData(this.currentCustomRaiting[i], i);
      }
    }
  }
  createForm() {
    this.form = this.fb.group({
      customRates: this.fb.array([])
    });
  }

  startInitData(raiting: ICustomRate, index: number) {
    this.customRates.push(this.getCustomRate(raiting?.amount, raiting?.id));
    setTimeout(() => {
      const instance = document.querySelectorAll('.customRaitingClass.e-control.e-combobox');
      if ((instance[index] as any).ej2_instances[0]) {
        (instance[index] as any).ej2_instances[0].value = raiting.companyWorkGroup.id;
        (instance[index] as any).ej2_instances[0].addItem({
          name: raiting.companyWorkGroup.name,
          id: raiting.companyWorkGroup.id
        });
      }
    }, 500);
  }

  // В цьому методі НІЧОГО НЕ ЗМІНЮВАТИ
  changeRates() {
    const instance = document.querySelectorAll('.customRaitingClass.e-control.e-combobox');
    setTimeout(() => {
      for (let i = 0; i < instance.length; i++) {
        this.dataCompanyWorkGroups[i] = this.getFilteringGroups(instance[i]['ej2_instances'][0].angularValue);
        (instance[i] as any).ej2_instances[0].dataSource = null;
        (instance[i] as any).ej2_instances[0].listData = null;
        (instance[i] as any).ej2_instances[0].dataSource = this.dataCompanyWorkGroups[i];
        (instance[i] as any).ej2_instances[0].listData = this.dataCompanyWorkGroups[i];
        if (!instance[i]['ej2_instances'][0].angularValue) {
          (instance[i] as any).ej2_instances[0].itemData = null;
        }
      }
    });
  }
  addAdditionField(): void {
    if (this.checkValidRates()) {
      this.dataCompanyWorkGroups[this.customRates.length] = this.getFilteringGroups();
      this.customRates.push(this.getCustomRate());
      this.customRatesIndex['changesCompanyWorkGroups' + 'index'] += 1;
      setTimeout(() => {
        document.getElementById(
          'scrollto-' + 'changesCompanyWorkGroups' + '-' + this.customRatesIndex['changesCompanyWorkGroups' + 'index']
        );
      }, 200);
      this.submittedForm = false;
      return;
    } else {
      this.submittedForm = true;
    }
  }
  getFilteringGroups(withoutId = 0) {
    let selectedIds = this.customRates.controls.map(item => item.value.companyWorkGroup);
    selectedIds = selectedIds.filter(item => item !== withoutId);
    const companyWorkGroups = this.companyWorkGroups.filter(function(e) {
      return this.indexOf(e.id) < 0;
    }, selectedIds);
    return JSON.parse(JSON.stringify(companyWorkGroups));
  }

  getCustomRate(amount = '', id?: number): UntypedFormGroup {
    let customRateFormGroup: UntypedFormGroup;
    customRateFormGroup = this.fb.group({
      companyWorkGroup: this.fb.control(null, [Validators.required]),
      amount: this.fb.control(amount, [Validators.required]),
      currency: this.fb.control(this.currency)
    });
    if (id) {
      customRateFormGroup.addControl('id', this.fb.control(id));
    }
    return customRateFormGroup;
  }

  removeField(index) {
    // оновлення даних в комбо боксі
    this.changeRates();
    // для анімації
    this.customRatesIndex['changesCompanyWorkGroups' + 'index'] -= 1;
    // видаляємо з форми один елемент
    this.customRates.removeAt(index);
  }
  // перевіряє чи можна довати ще один рейт
  checkValidRates() {
    for (const customRate of this.customRates.value) {
      if (!customRate.companyWorkGroup) {
        return false;
      }
    }
    return true;
  }

  currentCustomRates() {
    return this.form.get('customRates').value;
  }
  fixAmount(event, control) {
    setTimeout(() => {
      let temp = event.target.value;
      const currentValue = temp.split('.');
      const countAfter = currentValue[currentValue.length - 1].length;
      if (temp.includes('.') && countAfter === 1) {
        if (currentValue[1] >= 9) {
          currentValue[1] = 5;
          temp = currentValue[0] + '.' + currentValue[1];
        }
      }
      if (temp.includes('.') && countAfter > 2) {
        if (currentValue[1][0] >= 9) {
          temp = currentValue[0] + '.' + '9' + currentValue[1][1];
        } else {
          temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
        }
      }
      control.get('amount').setValue(temp);
    }, 200);

    if ((event.which >= 48 && event.which <= 57) || event.which === 46) {
      return true;
    }
    return false;
  }

  refreshRaiting() {
    while (this.customRates.controls.length !== 0) {
      this.customRates.removeAt(0);
    }
  }

  public onFilteringGroups: EmitType<any> = (e: FilteringEventArgs, index) => {
    this.dropDowns['_results'][index].showSpinner();

    this.filtersGroup.name = e.text;
    const selectedGroups = this.customRates.value.map(item => item.companyWorkGroup);

    this.debounceService.loadDataDebounce(this.keyLoadData + index, this.teamService, 'getAll', [
      this.filtersGroup,
      environment.countItemInSelect,
      selectedGroups
    ]);
    this.debounceService.getValue(this.keyLoadData + index).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.dropDowns['_results'][index].hideSpinner();
    });
  };
}
