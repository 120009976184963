import { Action } from '@ngrx/store';
import { Paginator } from '@app/models/paginator';
import { Template } from '@app/models/template.model';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum ETemplatesActions {
  GET_TEMPLATES = '[Templates] Get Templates',
  GET_TEMPLATES_SUCCESS = '[Templates] Get Templates Success',
  GET_TEMPLATES_FAILURE = '[Templates] Get Templates Failure',
  GET_TEMPLATE = '[Templates] Get Template',
  GET_TEMPLATE_SUCCESS = '[Templates] Get Template Success',
  GET_TEMPLATE_FAILURE = '[Templates] Get Templates Failure',
  ADD_TEMPLATE = '[Templates] Add Template',
  ADD_TEMPLATE_SUCCESS = '[Templates] Add Template Success',
  ADD_TEMPLATES_FAILURE = '[Templates] Get Templates Failure',
  UPDATE_TEMPLATE = '[Templates] Update Template',
  UPDATE_TEMPLATE_SUCCESS = '[Templates] Update Template Success',
  UPDATE_TEMPLATES_FAILURE = '[Templates] Get Templates Failure',
  DELETE_TEMPLATE = '[Templates] Delete Template',
  DELETE_TEMPLATE_SUCCESS = '[Templates] Delete Template Success',
  DELETE_TEMPLATE_FAILURE = '[Templates] Get Templates Failure',
  ADD_TO_STORE_CURRENT_TEMPLATE = '[Templates] Add template to store'
}
export class AddTemplateToStore implements Action {
  public readonly type = ETemplatesActions.ADD_TO_STORE_CURRENT_TEMPLATE;

  constructor(public payload: Template) {}
}

export class GetTemplates implements Action {
  public readonly type = ETemplatesActions.GET_TEMPLATES;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetTemplatesSuccess implements Action {
  public readonly type = ETemplatesActions.GET_TEMPLATES_SUCCESS;

  constructor(public payload: Paginator<Template>) {}
}
export class GetTemplatesFailure implements Action {
  public readonly type = ETemplatesActions.GET_TEMPLATES_FAILURE;

  constructor(public payload: Error) {}
}
export class GetTemplate implements Action {
  public readonly type = ETemplatesActions.GET_TEMPLATE;

  constructor(public payload: number) {}
}

export class GetTemplateSuccess implements Action {
  public readonly type = ETemplatesActions.GET_TEMPLATE_SUCCESS;

  constructor(public payload: Template) {}
}
export class GetTemplateFailure implements Action {
  public readonly type = ETemplatesActions.GET_TEMPLATE_FAILURE;

  constructor(public payload: Error) {}
}
export class AddTemplate implements Action {
  public readonly type = ETemplatesActions.ADD_TEMPLATE;

  constructor(public payload: Template) {}
}

export class AddTemplateSuccess implements Action {
  public readonly type = ETemplatesActions.ADD_TEMPLATE_SUCCESS;

  constructor(public payload: Template) {}
}
export class AddTemplateFailure implements Action {
  readonly type = ETemplatesActions.ADD_TEMPLATES_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateTemplate implements Action {
  public readonly type = ETemplatesActions.UPDATE_TEMPLATE;

  constructor(public payload: Template) {}
}

export class UpdateTemplateSuccess implements Action {
  public readonly type = ETemplatesActions.UPDATE_TEMPLATE_SUCCESS;

  constructor(public payload: Template) {}
}
export class UpdateTemplateFailure implements Action {
  public readonly type = ETemplatesActions.UPDATE_TEMPLATES_FAILURE;

  constructor(public payload: { success: boolean; error: Error }) {}
}
export class DeleteTemplate implements Action {
  public readonly type = ETemplatesActions.DELETE_TEMPLATE;

  constructor(public payload: number) {}
}

export class DeleteTemplateSuccess implements Action {
  public readonly type = ETemplatesActions.DELETE_TEMPLATE_SUCCESS;

  constructor(public payload: number) {}
}
export class DeleteTemplateFailure implements Action {
  public readonly type = ETemplatesActions.DELETE_TEMPLATE_FAILURE;

  constructor(public payload: Error) {}
}

export type TemplateActions =
  | GetTemplates
  | GetTemplatesSuccess
  | GetTemplatesFailure
  | GetTemplate
  | GetTemplateSuccess
  | GetTemplateFailure
  | AddTemplate
  | AddTemplateSuccess
  | AddTemplateFailure
  | UpdateTemplate
  | UpdateTemplateSuccess
  | UpdateTemplateFailure
  | DeleteTemplate
  | DeleteTemplateSuccess
  | DeleteTemplateFailure
  | AddTemplateToStore;
