export enum Currency {
  'USD' = 'USD',
  'EUR' = 'EUR',
  'UAH' = 'UAH',
  'USDT' = 'USDT',
  'EURT' = 'EURT',
  'PLN' = 'PLN',
  'BGN' = 'BGN',
  'CZK' = 'CZK',
  'RON' = 'RON',
  'HRK' = 'HRK',
  'HUF' = 'HUF',
  'SEK' = 'SEK',
  'DKK' = 'DKK',
  'CHF' = 'CHF',
  'GBP' = 'GBP',
  'CAD' = 'CAD',
  'TRY' = 'TRY',
  'AUD' = 'AUD',
  'JPY' = 'JPY',
  'AED' = 'AED'
}
