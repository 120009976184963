// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-template .modal-container {
  width: 540px;
  height: 100%;
}
.modal-template .modal-header-template {
  padding: 14px 19px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-template .modal-header-template .title-text {
  margin: 0;
  color: #1252af;
  font-size: 18px;
  font-weight: 500;
}
.modal-template .modal-header-template .close-modal {
  cursor: pointer;
}
.modal-template .modal-header-template .close-modal span {
  font-size: 14px;
  color: #1252af;
}
.modal-template .modal-body-template {
  padding: 0 19px 26px;
}
.modal-template .modal-body-template .button-container {
  margin-top: 13px;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/other-modals/templates-save-modal/templates-save-modal.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,YAAA;AAAJ;AAGE;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AADJ;AAGI;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AADN;AAII;EACE,eAAA;AAFN;AAIM;EACE,eAAA;EACA,cAAA;AAFR;AAOE;EACE,oBAAA;AALJ;AAOI;EACE,gBAAA;EACA,aAAA;EACA,yBAAA;AALN","sourcesContent":[".modal-template {\n  .modal-container {\n    width: 540px;\n    height: 100%;\n  }\n\n  .modal-header-template {\n    padding: 14px 19px 15px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    .title-text {\n      margin: 0;\n      color: #1252af;\n      font-size: 18px;\n      font-weight: 500;\n    }\n\n    .close-modal {\n      cursor: pointer;\n\n      span {\n        font-size: 14px;\n        color: #1252af;\n      }\n    }\n  }\n\n  .modal-body-template {\n    padding: 0 19px 26px;\n\n    .button-container {\n      margin-top: 13px;\n      display: flex;\n      justify-content: flex-end;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
