// generated by the plop

import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { BankAccountStateModel } from '@app/modules/bank-accounts/states/bank-account/bank-account.model';


export class GetBankAccounts {
  static type = '[bankAccount] GetBankAccounts';

  constructor(public payload: FetchPayload<any>) {
  }
}

export class GetBankAccountsSuccess {
  static type = '[bankAccount] GetBankAccountsSuccess';

  constructor(public payload: BankAccountStateModel['paginator']) {
  }
}

export class GetBankAccountsFail {
  static type = '[bankAccount] GetBankAccountsFail';

  constructor(public error: Error) {
  }
}

export class GetBankAccountsByCurrency {
  static type = '[bankAccount] GetBankAccountsByCurrency';

  constructor(public currency: string) {
  }
}

export class GetBankAccountsByCurrencySuccess {
  static type = '[bankAccount] GetBankAccountsByCurrencySuccess';

  constructor(public payload: BankAccountStateModel['bankAccountsByCurrency']) {
  }
}

export class GetBankAccountsByCurrencyFail {
  static type = '[bankAccount] GetBankAccountsByCurrencyFail';

  constructor(public error: Error) {
  }
}

