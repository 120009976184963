import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

/**
 * my trial key
 * Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhKYVJzWmFZfVpgdVRMYFtbRnRPMyBoS35RckVhWHtfcXRSRGNbWEZ2
 *
 * commercial key
 * ORg4AjUWIQA/Gnt2VVhjQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VjX39dcXNXQWJaVE0=
 *
 * new commercial key office@jusnote.com
 * Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxfd3VRRWZeV012XEU=
 */
registerLicense(environment.syncfusionLicenseKey);

if (environment.production) {
  enableProdMode();
}
declare global {
  interface Window {
    ngRef: any;
  }
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window.ngRef) {
      window.ngRef.destroy();
    }
    window.ngRef = ref;

    // Otherise, log the boot error
  })
  .catch(err => console.error(err));
