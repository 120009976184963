// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-template .link-container-text {
  font-size: 13px;
  color: #637283;
  margin-bottom: 12px;
}
.modal-template .link-container-text a {
  color: #1252af;
  font-weight: 600;
  text-decoration: underline;
}
.modal-template .input-container.checkbox-container {
  display: flex;
}
.modal-template .input-container.checkbox-container.checkbox-bold label {
  font-weight: 400;
}
.modal-template .input-container.checkbox-container .j2-icon-info {
  margin-left: 3px;
}
.modal-template textarea {
  height: 85px;
}
.modal-template .modal-footer {
  background-color: #ffffff;
}
.modal-template .modal-footer {
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/team-invite-modal/team-invite-modal.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAEI;EACE,cAAA;EACA,gBAAA;EACA,0BAAA;AAAN;AAIE;EACE,aAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAII;EACE,gBAAA;AAFN;AAME;EACE,YAAA;AAJJ;AAME;EACE,yBAAA;AAJJ;AAME;EACE,cAAA;AAJJ","sourcesContent":[".modal-template {\n  .link-container-text {\n    font-size: 13px;\n    color: #637283;\n    margin-bottom: 12px;\n\n    a {\n      color: #1252af;\n      font-weight: 600;\n      text-decoration: underline;\n    }\n  }\n\n  .input-container.checkbox-container {\n    display: flex;\n\n    &.checkbox-bold label {\n      font-weight: 400;\n    }\n    .j2-icon-info {\n      margin-left: 3px;\n    }\n  }\n\n  textarea {\n    height: 85px;\n  }\n  .modal-footer {\n    background-color: #ffffff;\n  }\n  .modal-footer {\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
