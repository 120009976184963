import { Action } from '@ngrx/store';
import { Correspondence } from '@app/models/correspondence';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { Paginator } from '@app/models/paginator';
import { TrackInfo } from '@app/models/interfaces/track-info';
import { CorrespondenceMessage } from '@app/models/correspondence-message';
import { DocumentSave } from '@app/models/document-save';
import { CorrespondenceFilters } from '@app/models/correspondence-filters';

export enum ECorrespondenceActions {
  CREATE_CORRESPONDENCE = '[Correspondence] Create',
  CREATE_CORRESPONDENCE_SUCCESS = '[Correspondence] Created',

  CREATE_CORRESPONDENCE_WITH_DOCUMENTS = '[Correspondence] Create Correspondence with documents',
  CREATE_CORRESPONDENCE_WITH_DOCUMENTS_SUCCESS = '[Correspondence] Create Correspondence with documents success',
  CREATE_CORRESPONDENCE_WITH_DOCUMENTS_FAILURE = '[Correspondence] Create Correspondence with documents failure',

  SELECT_CORRESPONDENCE_FROM_STORE = '[Correspondence] Select',
  REMOVE_CORRESPONDENCE = '[Correspondence] Remove',
  REMOVE_CORRESPONDENCE_SUCCESS = '[Correspondence] Remove Success',
  REMOVE_CORRESPONDENCE_FAILURE = '[Correspondence] Remove Failure',

  UPDATE_CORRESPONDENCE = '[Correspondence] Update',
  UPDATE_CORRESPONDENCE_SUCCESS = '[Correspondence] Updated',
  GET_CORRESPONDENCES = '[Correspondence] Fetch',
  GET_CORRESPONDENCES_SUCCESS = '[Correspondence] Fetched',
  GET_DROPDOWN_CORRESPONDENCES = '[Correspondence] Fetch Dropdown',
  GET_DROPDOWN_CORRESPONDENCES_SUCCESS = '[Correspondence] Fetched Dropdown',
  GET_CORRESPONDENCE_BY_ID = '[Correspondence] Fetch by ID',
  GET_CORRESPONDENCE_BY_ID_SUCCESS = '[Correspondence] Fetched by ID',
  GET_CORRESPONDENCE_BY_ID_FAILURE = '[Correspondence] Fetched by ID Failure',
  GET_TRACK_INFO = '[Correspondence] Get track info',
  GET_TRACK_INFO_SUCCESS = '[Correspondence] Track info fetched',
  GET_TIMELINE_INFO = '[Correspondence] Fetch timeline',
  GET_TIMELINE_INFO_SUCCESS = '[Correspondence] Fetched timeline',
  CREATE_CORRESPONDENCE_MESSAGE = '[Correspondence] Create correspondence message',
  GET_CORRESPONDENCE_MESSAGE = '[Correspondence] Get correspondence message',
  GET_CORRESPONDENCE_MESSAGE_SUCCESS = '[Correspondence] Get correspondence message success',
  CREATE_CORRESPONDENCE_MESSAGE_SUCCESS = '[Correspondence] Create correspondence message success',

  CLEAR_CORRESPONDENCE = '[Correspondence] Clear Correspondence'
}

export class CreateCorrespondence implements Action {
  public readonly type = ECorrespondenceActions.CREATE_CORRESPONDENCE;

  constructor(public payload: Correspondence) {}
}

export class CreateCorrespondenceSuccess implements Action {
  public readonly type = ECorrespondenceActions.CREATE_CORRESPONDENCE_SUCCESS;

  constructor(public payload: Correspondence) {}
}

export class CreateCorrespondenceWithDocuments implements Action {
  readonly type = ECorrespondenceActions.CREATE_CORRESPONDENCE_WITH_DOCUMENTS;

  constructor(public payload: { correspondence: Correspondence; documents: { document: DocumentSave; mediaFile: File }[] }) {}
}

export class CreateCorrespondenceWithDocumentsSuccess implements Action {
  readonly type = ECorrespondenceActions.CREATE_CORRESPONDENCE_WITH_DOCUMENTS_SUCCESS;

  constructor(public payload: Correspondence) {}
}

export class CreateCorrespondenceWithDocumentsFailure implements Action {
  readonly type = ECorrespondenceActions.CREATE_CORRESPONDENCE_WITH_DOCUMENTS_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateCorrespondenceSuccess implements Action {
  public readonly type = ECorrespondenceActions.UPDATE_CORRESPONDENCE_SUCCESS;

  constructor(public payload: Correspondence) {}
}

export class GetCorrespondenceFromStore implements Action {
  public readonly type = ECorrespondenceActions.SELECT_CORRESPONDENCE_FROM_STORE;

  constructor(public payload: number) {}
}

export class RemoveCorrespondence implements Action {
  public readonly type = ECorrespondenceActions.REMOVE_CORRESPONDENCE;

  constructor(public payload: number) {}
}

export class RemoveCorrespondenceSuccess implements Action {
  public readonly type = ECorrespondenceActions.REMOVE_CORRESPONDENCE_SUCCESS;

  constructor(public payload: number) {}
}
export class RemoveCorrespondenceFailure implements Action {
  public readonly type = ECorrespondenceActions.REMOVE_CORRESPONDENCE_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateCorrespondence implements Action {
  public readonly type = ECorrespondenceActions.UPDATE_CORRESPONDENCE;

  constructor(public payload: Correspondence, public id: number) {}
}

export class GetCorrespondences implements Action {
  public readonly type = ECorrespondenceActions.GET_CORRESPONDENCES;

  constructor(public payload: FetchPayload<CorrespondenceFilters>) {}
}

export class GetCorrespondenceDropdownSuccess implements Action {
  public readonly type = ECorrespondenceActions.GET_DROPDOWN_CORRESPONDENCES_SUCCESS;

  constructor(public payload: Correspondence[]) {}
}

export class GetCorrespondenceDropdown implements Action {
  public readonly type = ECorrespondenceActions.GET_DROPDOWN_CORRESPONDENCES;

  constructor(public payload = null, public page = null, public itemsPerPage = null) {}
}

export class GetCorrespondenceById implements Action {
  public readonly type = ECorrespondenceActions.GET_CORRESPONDENCE_BY_ID;

  constructor(public payload: number) {}
}

export class GetCorrespondenceByIdSuccess implements Action {
  public readonly type = ECorrespondenceActions.GET_CORRESPONDENCE_BY_ID_SUCCESS;

  constructor(public payload: Correspondence) {}
}

export class GetCorrespondenceByIdFailure implements Action {
  public readonly type = ECorrespondenceActions.GET_CORRESPONDENCE_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

export class GetCorrespondencesSuccess implements Action {
  public readonly type = ECorrespondenceActions.GET_CORRESPONDENCES_SUCCESS;

  constructor(public payload: Paginator<Correspondence>) {}
}

export class GetTrackInfo implements Action {
  public readonly type = ECorrespondenceActions.GET_TRACK_INFO;

  constructor(public payload: string) {}
}

export class GetTrackInfoSuccess implements Action {
  public readonly type = ECorrespondenceActions.GET_TRACK_INFO_SUCCESS;

  constructor(public payload: TrackInfo) {}
}

export class GetTimeline implements Action {
  public readonly type = ECorrespondenceActions.GET_TIMELINE_INFO;

  constructor(public payload: number) {}
}

export class GetTimeLineSuccess implements Action {
  public readonly type = ECorrespondenceActions.GET_TIMELINE_INFO_SUCCESS;

  constructor(public payload) {}
}

export class CreateCorrespondenceMessage implements Action {
  public readonly type = ECorrespondenceActions.CREATE_CORRESPONDENCE_MESSAGE;

  constructor(public payload: { message: string; users: number[]; correspondence: number }) {}
}

export class CreateCorrespondenceMessageSuccess implements Action {
  public readonly type = ECorrespondenceActions.CREATE_CORRESPONDENCE_MESSAGE_SUCCESS;

  constructor(public payload: CorrespondenceMessage) {}
}

export class GetCorrespondenceMessage implements Action {
  public readonly type = ECorrespondenceActions.GET_CORRESPONDENCE_MESSAGE;

  constructor(public payload: number) {}
}

export class GetCorrespondenceMessageSuccess implements Action {
  public readonly type = ECorrespondenceActions.GET_CORRESPONDENCE_MESSAGE_SUCCESS;

  constructor(public payload: CorrespondenceMessage[]) {}
}

export class ClearCorrespondence implements Action {
  public readonly type = ECorrespondenceActions.CLEAR_CORRESPONDENCE;

  constructor() {}
}

export type CorrespondenceActions =
  | GetCorrespondences
  | GetCorrespondencesSuccess
  | GetCorrespondenceByIdFailure
  | GetCorrespondenceByIdSuccess
  | CreateCorrespondence
  | CreateCorrespondenceSuccess
  | CreateCorrespondenceWithDocuments
  | CreateCorrespondenceWithDocumentsSuccess
  | CreateCorrespondenceWithDocumentsFailure
  | GetCorrespondenceFromStore
  | UpdateCorrespondence
  | UpdateCorrespondenceSuccess
  | RemoveCorrespondence
  | RemoveCorrespondenceSuccess
  | RemoveCorrespondenceFailure
  | GetTrackInfo
  | GetTrackInfoSuccess
  | GetCorrespondenceDropdown
  | GetCorrespondenceDropdownSuccess
  | GetTimeline
  | GetTimeLineSuccess
  | GetCorrespondenceMessage
  | CreateCorrespondenceMessage
  | GetCorrespondenceMessageSuccess
  | CreateCorrespondenceMessageSuccess
  | ClearCorrespondence;
