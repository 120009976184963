import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { RelationsModalComponent } from '@app/commonComponents/modals/default-modals/relations-modal/relations-modal.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { Contact, DocumentExtended } from '@app/models';
import G6 from '@antv/g6';
import { Subscription } from 'rxjs';
import { MatterExtended } from '@app/models/matter-extended';
import { DocumentAddRelationModalComponent } from '@app/commonComponents/modals/default-modals/document-add-relation-modal/document-add-relation-modal.component';
import { DocumentService } from '@app/modules/reusable/documents/services/document.service';
import { Helpers } from '@app/helpers/helpers';
import { DocumentEffects } from '@app/store/effects/document.effects';
import { DocRelation } from '@app/models/doc-relation';

@Component({
  selector: 'app-relation-graph',
  templateUrl: './relation-graph.component.html',
  styleUrls: ['./relation-graph.component.scss']
})
export class RelationGraphComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private contactService: ContactService,
    private documentService: DocumentService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private documentEffects: DocumentEffects,
  ) {
    documentEffects.updateDocument$.subscribe(res => {
      setTimeout(() => {
        this.refreshRelations();
      }, 300);
    });
  }
  @Input() contact: Contact;
  @Input() contactId;
  @Input() relationTree;
  @Input() fromMatter;
  @Input() matter: MatterExtended;
  @Output() afterCreateRelation = new EventEmitter();
  @Input() document: DocumentExtended;
  menuToggle = false;
  showLoader = false;

  private subscriptions: Subscription = new Subscription();
  x = 300;
  y = 0;
  preventLevel;
  yLevel = 0;
  // create an array with nodes
  nodes;

  // create an array with edges
  edges;

  data;
  graph;

  selectedItem;

  listItemsRelation = [];

  hoveredItem = -1;
  relationsSpinner = 'relations-spinner';
  docRelations: DocRelation[] = [];
  initialDocRelations: DocRelation[] = [];

  loadingRelations = false;

  getShortlabel(label) {
    let str = label;
    if (label.length >= 18) {
      str = label.substr(0, 15);
      str += '...';
    }
    return str;
  }
  setNodes() {
    const innerNodes = [];
    for (const relationTreeItem of this.relationTree.items) {
      innerNodes.push({
        id: relationTreeItem.id.toString(),
        label: this.getShortlabel(relationTreeItem.name),
        textLabel: relationTreeItem.name,
        labelCfg: {
          position: 'center',
          offset: 15,
          style: {
            fill: '#1252af',
            fontSize: 15
          }
        },
        x: this.returnPositionX(relationTreeItem),
        y: this.returnPositionY(relationTreeItem)
      });
    }
    this.nodes = innerNodes;

    const innerEdges = [];
    for (const relation of this.relationTree.relations) {
      innerEdges.push({
        source: relation.from + '',
        target: relation.to + '',
        type: 'cubic-horizontal',
        id: 'edge' + relation.from + '' + relation.to
      });
    }
    this.edges = innerEdges;
    this.data = {
      nodes: this.nodes,
      edges: this.edges
    };
  }
  returnPositionX(item) {
    if (this.yLevel % 2) {
      this.x -= 10;
    } else {
      this.x += 10;
    }
    if (!item.x) {
      return this.x * item.level === 0 ? 100 : this.x * item.level;
    } else {
      return item.x;
    }
  }
  returnPositionY(item) {
    this.yLevel++;
    if (!item.y) {
      if (this.preventLevel !== item.level) {
        this.preventLevel = item.level;
        this.y = 0;
      }
      if (item.level === 0) {
        return 300;
      }
      return (this.y += 150); // this.y * (item.level +1);;
    } else {
      return item.y;
    }
  }

  ngOnInit() {
    this.setNodes();
    this.initG6Graph();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    delete this.subscriptions;
  }

  refreshRelation(data) {
    this.relationTree = data;
    this.setNodes();
    this.graph.changeData(this.data);
    this.graph.refresh();
    this.selectedFirstItem(this.selectedItem.node);
  }

  initG6Graph() {
    this.graph = new G6.Graph({
      container: 'container',
      width: document.getElementById('container').offsetWidth,
      height: document.getElementById('container').offsetHeight - 50,

      fitCenter: true,
      modes: {
        default: ['drag-canvas', 'zoom-canvas', 'drag-node']
      },
      defaultNode: {
        type: 'rect',
        size: [170, 40],
        style: {
          radius: 20,
          stroke: '#cee0ff',
          fill: '#ffffff',
          lineWidth: 1
        },
        preRect: {
          show: false
        },
        logoIcon: {
          show: false
        },
        stateIcon: {
          show: false
        }
      },
      defaultEdge: {
        style: {
          stroke: '#C1C1C1',
          lineAppendWidth: 10
        }
      }
    });
    this.graph.data(this.data);
    this.graph.render();

    this.graph.on('node:click', ev => {
      this.openSpinner();

      const node = ev.item;
      const edges = node.getEdges();
      this.selectedItem = { id: node._cfg.model.id, fullName: node._cfg.model.label, node };
      if (this.document) {
        this.selectedItem.document = this.relationTree.items.find(item => item.id === this.selectedItem.id);
      }
      if (this.document) {
        if (!this.loadingRelations) {
          this.loadingRelations = true;
          this.getDocumentRelationship();
        }
      } else {
        this.getContactRelationship();
      }
      // change style edges
      for (const edge of this.graph.cfg.edges) {
        edge._cfg.model.style.stroke = '#C1C1C1';
      }
      for (const cNode of this.graph.cfg.nodes) {
        cNode._cfg.model.style.fill = '#ffffff';
        cNode._cfg.model.labelCfg.style.fill = '#1252af';
        cNode._cfg.model.style.stroke = '#cee0ff';
      }

      setTimeout(() => {
        node._cfg.model.style.fill = '#1252af';
        node._cfg.model.labelCfg.style.fill = '#ffffff';
        node._cfg.model.style.stroke = '#1252af';
        for (const edge of edges) {
          if (+edge._cfg.model.source === +node._cfg.id || +edge._cfg.model.target === +node._cfg.id) {
            edge._cfg.model.style.stroke = '#1252af';
          }
        }
        this.graph.refresh();
      }, 200);
    });
    const tooltip = document.getElementsByClassName('relation-tooltip')[0] as HTMLElement;
    this.graph.on('node:mouseenter', ev => {
      tooltip.style.top = ev.clientY - (this.contact ? 60 : 0) - (tooltip.offsetHeight + 50) + 'px';
      tooltip.style.left = ev.canvasX + 30 + 'px';
      tooltip.style.visibility = 'visible';
      const currentR = this.findCurrentNote(ev.item._cfg.model.id);
      let innerH = ev.item._cfg.model.textLabel;
      if (currentR) {
        innerH = `
        <span> ${ev.item._cfg.model.textLabel} </span>
        <div class="description">
          <div class="icon-container"> <i class="j2-icon-comment"> </i> </div>
          <span> ${currentR.description} </span>
        </div>
        `;
      }
      tooltip.innerHTML = innerH;
    });
    this.graph.on('node:mousemove', ev => {
      tooltip.style.top = ev.clientY - (this.contact ? 60 : 0) - (tooltip.offsetHeight + 50) + 'px';
      tooltip.style.left = ev.canvasX + 30 + 'px';
    });
    this.graph.on('node:mouseleave', ev => {
      tooltip.style.visibility = 'hidden';
    });

    this.selectedFirstItem();
  }

  findCurrentNote(targetItemId) {
    let itemRelation;
    this.listItemsRelation.forEach(item => {
      item.relationsType.forEach(relItem => {
        if (relItem.source.id === this.selectedItem.id && relItem.target.id === targetItemId) {
          itemRelation = relItem;
        }
      });
    });
    return itemRelation;
  }

  selectedFirstItem(sNode?) {
    let node = sNode && !sNode.destroyed ? sNode : this.graph.cfg.nodes[0];
    if (sNode && !sNode.destroyed) {
      if (!this.graph.cfg.nodes.find(item => item._cfg.model.id === sNode._cfg.model.id)) {
        node = this.graph.cfg.nodes[0];
      }
    }
    this.selectedItem = { id: node._cfg.model.id, fullName: node._cfg.model.label, node };

    if (this.document) {
      this.selectedItem.document = this.relationTree.items.find(item => item.id === this.selectedItem.id);
    }

    const edges = node.getEdges();
    if (this.document) {
      this.getDocumentRelationship(true);
    } else {
      this.getContactRelationship();
    }
    for (const edge of this.graph.cfg.edges) {
      edge._cfg.model.style.stroke = '#C1C1C1';
    }
    for (const cNode of this.graph.cfg.nodes) {
      cNode._cfg.model.style.fill = '#ffffff';
      cNode._cfg.model.labelCfg.style.fill = '#1252af';
      cNode._cfg.model.style.stroke = '#cee0ff';
    }
    setTimeout(() => {
      node._cfg.model.style.fill = '#1252af';
      node._cfg.model.labelCfg.style.fill = '#ffffff';
      node._cfg.model.style.stroke = '#1252af';
      for (const edge of edges) {
        if (+edge._cfg.model.source === +node._cfg.id || +edge._cfg.model.target === +node._cfg.id) {
          edge._cfg.model.style.stroke = '#1252af';
        }
      }
      this.graph.refresh();
    }, 200);
  }

  goToContact(id) {
    let element;
    this.nodes.forEach(item => {
      if (item.id === id) {
        element = item;
      }
    });
    if (element.type === 'contact') {
      this.contactService.getById(id).subscribe(res => {
        if (res.id) {
          this.router.navigateByUrl(`${element.type}/${id}`);
        }
      });
    } else {
      this.router.navigateByUrl(`${element.type}/${id}`);
    }
  }

  relationsModal() {
    if (this.contact) {
      this.contactOrMaterCreateRelation();
    } else if (this.document) {
      this.documentCreateRelation();
    }
  }

  contactOrMaterCreateRelation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'relations-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      fromContact: true,
      fromMatter: this.fromMatter,
      contact: this.contact,
      matter: this.matter
    };

    const dialogRef = this.dialog.open(RelationsModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.id) {
        this.contactService.createContactRelationship(result).subscribe(() => {
          this.toastrService.success(this.translate.instant('itemAdded'));
          this.refreshRelations();
        });
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  documentCreateRelation(relation?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'relations-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      document: this.document,
      relation,
      docRelations: relation ? this.docRelations : this.initialDocRelations
    };

    const dialogRef = this.dialog.open(DocumentAddRelationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.id) {
        this.documentService.createDocRelationship(result).subscribe(res => {
          this.toastrService.success(this.translate.instant('itemAdded'));
          this.refreshRelations();
        });
      } else if (result?.id) {
        this.documentService.editDocRelationship(result).subscribe(res => {
          this.toastrService.success(this.translate.instant('itemAdded'));
          this.refreshRelations();
        });
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }
  refreshRelations() {
    this.afterCreateRelation.emit();
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  getContactRelationship() {
    const uniqId = [];
    this.listItemsRelation = [];
    let params;
    params = {
      'contact.id': this.selectedItem.id
    };
    // if(this.fromMatter) {
    //   params = {
    //     'contact.id': this.selectedItem.id,
    //     'matter.id': this.matter.id
    //   }
    // }
    const loadContactRelationshipSubsr = this.contactService.getContactRelationship(1, 10000, params).subscribe(res => {
      if (res?.member) {
        for (const rMember of res.member) {
          if (!uniqId.find(item => item.id === rMember.target.id)) {
            uniqId.push(rMember.target);
          }
        }
        for (const uId of uniqId) {
          const relation = [];
          for (const rMember of res.member) {
            if (rMember.target.id === uId.id) {
              relation.push(rMember);
            }
          }
          this.listItemsRelation.push({
            id: uId.id,
            name: uId.fullName,
            type: res.member[0].source.type,
            image: res.member[0].source.media ? res.member[0].source.media.url : null,
            relationsType: relation
          });
        }
        if (!this.listItemsRelation.length && this.contact) {
          this.listItemsRelation.push({
            id: -1,
            name: this.contact.fullName,
            type: this.contact.type ? this.contact.type : 'person',
            image: this.contact.media ? this.contact.media.url : null,
            relationsType: []
          });
        } else if (!this.listItemsRelation.length && this.document) {
          this.listItemsRelation.push({
            id: -1,
            name: this.document.name,
            type: 'person',
            image: null,
            relationsType: []
          });
        }
        this.hideSpinner();
        this.menuToggle = true;
      }
    });
    this.subscriptions.add(loadContactRelationshipSubsr);
  }

  getDocumentRelationship(initial = false) {
    const uniqId = [];
    let params;
    params = {
      'document.id': this.selectedItem.id
    };

    const loadDocRelationshipSubsr = this.documentService.getRelations(1, 10000, params).subscribe(res => {
      if (res?.member) {
        if (initial) {
          this.initialDocRelations = res.member;
        }
        this.listItemsRelation = [];
        this.docRelations = res.member;
        for (const rMember of res.member) {
          if (!uniqId.find(item => +item.id === +rMember.target.id)) {
            uniqId.push(rMember.target);
          }
        }
        for (const uId of uniqId) {
          const relation = [];
          for (const rMember of res.member) {
            if (+rMember.target.id === +uId.id) {
              relation.push(rMember);
            }
          }
          this.listItemsRelation.push({
            id: uId.id,
            name: uId.name,
            relationsType: relation,
            icon: uId.media ? Helpers.getCurrentIconForDocumentUrl(uId.media.url) : Helpers.getCurrentIconForDocumentUrl('cloud')
          });
        }
        if (!this.listItemsRelation.length && this.document) {
          this.listItemsRelation.push({
            id: -1,
            name: this.document.name,
            type: 'person',
            image: null,
            relationsType: []
          });
        }
        this.menuToggle = true;
      }
      this.loadingRelations = false;
      this.hideSpinner();
    });
    this.subscriptions.add(loadDocRelationshipSubsr);
  }

  editDocRelation(item) {
    this.documentCreateRelation(item);
  }

  deleteDocRelation(item) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteTxt'
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.documentService.deleteDocRelationship(item.id).subscribe(res => {
          this.refreshRelations();
          this.toastrService.error(this.translate.instant('itemDeleted'));
        });
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  visibleToolTip(e, item) {
    const tooltip = document.getElementsByClassName('relation-tooltip')[0] as HTMLElement;
    if (item) {
      tooltip.style.visibility = 'visible';
      tooltip.innerHTML = item.relationsType[0].description;
      tooltip.style.top = e.clientY - (tooltip.offsetHeight + 60) + 'px';
      tooltip.style.left = e.clientX - (tooltip.offsetWidth + 200) + 'px';
    } else {
      tooltip.style.visibility = 'hidden';
    }
  }
  hideMenu() {
    this.menuToggle = false;
    const menu = document.getElementsByClassName('relations-menu')[0] as HTMLElement;
    menu.classList.remove('open');
  }
}
