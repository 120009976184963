import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExchangeRatesService } from '@app/api/exchange-rates.service';
import { Bill } from '@app/models/bill';
import { Subscription } from 'rxjs';
import { CompanySettings } from '@app/models/company-settings';
import { AppSettingsService } from '../../../../helpers/app-settings.service';
import { CustomDateAdapter } from '../../../../helpers/custom-date-adapter';

@Component({
  selector: 'app-transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss']
})
export class TransactionModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<TransactionModalComponent>,
    private exchangeService: ExchangeRatesService,
    private appSettingsService: AppSettingsService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      currentBill?: Bill;
      currentCompanySettings?: CompanySettings;
      showDateInput?: boolean;
    }
  ) {}
  private subscription = new Subscription();
  rateBillToCompanySettings = 0;
  rateBillToMatter = 0;
  visibleMatterBlock = false;
  visibleClientBlock = false;
  currencyBill;
  currencyCompany;
  currencyMatter;
  amountMatter = 0;
  amountClient = 0;
  amountTransactionIsMatter = 0;
  amountTransactionIsClient = 0;
  visibleClientText = true;
  textMatter = '';
  visibleMatterText = true;
  amountMaxValueMatter = 0;
  amountMaxValueClient = 0;

  public locale$ = this.appSettingsService.getLang;
  dateFormat = CustomDateAdapter.dateFormat;
  dateTransfer = new Date();
  formSubmitted = false;

  closeModal() {
    this.dialogRef.close(false);
  }
  ngOnInit() {
    // this.amountTransactionIsMatter = this.dialogData ? (this.dialogData.currentBill.amount - this.dialogData.currentBill.balance) : 0;
    if (this.dialogData) {
      this.amountMaxValueMatter = Number(this.dialogData.currentBill.amount) - Number(this.dialogData.currentBill.balance);
      this.currencyBill = this.dialogData.currentBill.currency.code;
      this.currencyMatter = this.dialogData.currentBill.matter.currencyBill.code;
      this.currencyCompany = this.dialogData.currentCompanySettings.currency.code;

      // відбувається тоді, коли в проекта гроші є і їх вистачає
      // і відбувається конвертація валюти біла і проекта
      if (
        Number(this.dialogData.currentBill.matter.balance) > 0 &&
        this.dialogData.currentBill.matter.currencyBill.code !== this.dialogData.currentBill.currency.code
      ) {
        this.visibleMatterBlock = true;
        this.visibleMatterText = false;

        this.ifMatterAvailability();
      } else if (
        Number(this.dialogData.currentBill.matter.balance) > 0 &&
        this.dialogData.currentBill.matter.currencyBill.code === this.dialogData.currentBill.currency.code
      ) {
        // ТУТ НАПИСАТИ ТЕ ЩО, ПРОСТО ВЗЯТИ ГРОШІ З ПРОЕКТА БЕЗ КОНВЕРТАЦІЇ, ЯК В МАЛЕНЬКІЙ МОДАЛІ
        this.visibleMatterText = true;
        this.visibleMatterBlock = true;
        if (this.amountMaxValueMatter < +this.dialogData.currentBill.matter.balance) {
          // this.textMatter = `взяти з проекта ${this.dialogData.currentBill.matter.name}
          // суму ${this.amountMaxValueMatter} у валюті ${this.dialogData.currentBill.matter.currencyBill.code}`;
          this.amountTransactionIsMatter = +this.amountMaxValueMatter;
        } else {
          // this.textMatter = `взяти з проекта ${this.dialogData.currentBill.matter.name}
          // суму ${this.dialogData.currentBill.matter.balance} у валюті ${this.dialogData.currentBill.matter.currencyBill.code}`;
          this.amountTransactionIsMatter = +this.dialogData.currentBill.matter.balance;
        }
      } else if (Number(this.dialogData.currentBill.matter.balance) === 0) {
        this.visibleMatterBlock = false;
      }
      // відбувається тоді, коли  в проекта нема грошей
      // і відбувається конвертація валлюти біла в компаніСетінг і суми клієнта
      if (
        Number(this.dialogData.currentBill.client.balance) > 0 &&
        this.dialogData.currentCompanySettings.currency.code !== this.dialogData.currentBill.currency.code
      ) {
        this.visibleClientBlock = true;
        this.visibleClientText = false;
        this.amountMaxValueClient = this.amountMaxValueMatter - this.amountTransactionIsMatter;

        this.ifClientAvailability();
      } else if (
        Number(this.dialogData.currentBill.client.balance) > 0 &&
        this.dialogData.currentCompanySettings.currency.code === this.dialogData.currentBill.currency.code
      ) {
        // ТУТ НАПИСАТИ ТЕ ЩО, ПРОСТО ВЗЯТИ ГРОШІ З клієнта БЕЗ КОНВЕРТАЦІЇ, ЯК В МАЛЕНЬКІЙ МОДАЛІ

        this.visibleClientText = true;
        this.visibleClientBlock = true;
        this.amountTransactionIsClient = +this.dialogData.currentBill.client.balance;
      } else if (Number(this.dialogData.currentBill.matter.balance) === 0) {
        this.visibleClientBlock = false;
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ifClientAvailability() {
    const subscr = this.exchangeService.getRate(this.currencyCompany, this.currencyBill).subscribe(res => {
      if (res) {
        this.rateBillToCompanySettings = Number((+res.rate).toFixed(6));

        this.changeRateClient(this.rateBillToCompanySettings);
      }
    });
    this.subscription.add(subscr);
  }
  ifMatterAvailability() {
    const subscr = this.exchangeService.getRate(this.currencyMatter, this.currencyBill).subscribe(res => {
      this.rateBillToMatter = Number((+res.rate).toFixed(6));
      this.changeRateMatter(this.rateBillToMatter);
    });
    this.subscription.add(subscr);
  }
  changeRateMatter(res) {
    if (!res || res === 0) {
      res = 0;
      this.amountMatter = 0;
      this.amountTransactionIsMatter = 0;
    } else {
      this.amountTransactionIsMatter =
        (Number(this.dialogData.currentBill.amount) - Number(this.dialogData.currentBill.balance)) / Number(res);
      this.amountMatter = Number(this.amountTransactionIsMatter) / Number(res);

      // тоді коли курс * суму проекту більший за саму суму яку потірбно оплатити
      // тоді сума проекту, яка оплатиться зменшується на максимально можливу по
      // цьому курсу і тій сумі яку потірбно оплатити
      if (Number(res) * Number(this.amountMatter) > Number(this.amountTransactionIsMatter)) {
        this.amountMatter = Number(this.amountTransactionIsMatter) / Number(res);
        this.amountTransactionIsMatter = Number(this.amountMatter) / Number(res);
      }

      // коли при зміні курсу, сума проекту стала більша ніж є насправді,
      // тоді вона оновлюється на максимально можливу
      if (Number(this.amountMatter) > Number(this.dialogData.currentBill.matter.balance)) {
        this.amountMatter = Number(this.dialogData.currentBill.matter.balance);
      }

      // відбуваєтсья тоді, коли при зменшені курсу, сумаMatter * курс є менша ніж
      // загальна сума оплати
      // тоді сума проекту оновлюється
      if (Number(this.amountMatter) / Number(res) < Number(this.amountTransactionIsMatter)) {
        this.amountMatter = Number(this.dialogData.currentBill.matter.balance);
        this.amountTransactionIsMatter = Number(this.amountMatter) / Number(res);
      }

      if (Number(this.amountTransactionIsMatter) > Number(this.amountMaxValueMatter)) {
        this.amountTransactionIsMatter = Number(this.amountMaxValueMatter);
      }
      this.amountMatter = +this.amountMatter;
      this.amountTransactionIsMatter = +this.amountTransactionIsMatter;

      if (Number(this.amountTransactionIsMatter) >= Number(this.amountMaxValueMatter)) {
        this.visibleClientBlock = false;
        this.amountTransactionIsClient = 0;
      }
    }
    if (
      Number(this.amountTransactionIsMatter) < Number(this.amountMaxValueMatter) &&
      Number(this.dialogData.currentBill.client.balance) > 0
    ) {
      this.visibleClientBlock = true;
      this.amountMaxValueClient = this.amountMaxValueMatter - this.amountTransactionIsMatter;

      if (this.visibleClientText) {
        if (this.amountMaxValueClient <= Number(this.dialogData.currentBill.client.balance)) {
          this.amountTransactionIsClient = this.amountMaxValueClient;
        } else if (this.amountMaxValueClient > Number(this.dialogData.currentBill.client.balance)) {
          this.amountTransactionIsClient = Number(this.dialogData.currentBill.client.balance);
        }
      } else {
        this.changeRateClient(this.rateBillToCompanySettings);
      }
    }
  }
  changeRateClient(res) {
    if (!res || +res === 0) {
      res = 0;
      this.amountClient = 0;
      this.amountTransactionIsClient = 0;
    } else {
      this.amountTransactionIsClient =
        (+this.dialogData.currentBill.amount - +this.dialogData.currentBill.balance - +this.amountTransactionIsMatter) * +res;

      this.amountClient = Number(this.dialogData.currentBill.client.balance);
      // скільки потірбно заплатити в гривнях
      // console.log(this.amountTransactionIsClient)

      // скільки в нього є
      // console.log(this.amountClient)

      // тоді коли курс * суму проекту більший за саму суму яку потірбно оплатити
      // тоді сума проекту, яка оплатиться зменшується на максимально можливу по
      // цьому курсу і тій сумі яку потірбно оплатити
      if (Number(this.amountClient) > Number(this.amountTransactionIsClient)) {
        // console.log('1')
        this.amountClient = +Number(this.amountTransactionIsClient).toFixed(2);
        this.amountTransactionIsClient = Number(this.amountClient) / Number(res);
      }

      // коли при зміні курсу, сума проекту стала більша ніж є насправді,
      // тоді вона оновлюється на максимально можливу
      if (Number(this.amountClient) > Number(this.dialogData.currentBill.client.balance)) {
        // console.log('2')

        this.amountClient = Number(this.dialogData.currentBill.client.balance);
      }

      // відбуваєтсья тоді, коли при зменшені курсу, сума client * курс є менша ніж
      // загальна сума оплати
      // тоді сума проекту оновлюється
      if (Number(this.amountClient) / Number(res) < Number(this.amountTransactionIsClient)) {
        // console.log('3')

        this.amountClient = Number(this.dialogData.currentBill.client.balance);
        this.amountTransactionIsClient = Number(this.amountClient) / Number(res);
      }

      if (Number(this.amountTransactionIsClient) > Number(this.amountMaxValueClient)) {
        // console.log('4')

        this.amountTransactionIsClient = Number(this.amountMaxValueClient);
      }
      this.amountClient = +this.amountClient;
      this.amountTransactionIsClient = +this.amountTransactionIsClient;
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if(this.dialogData.showDateInput && !this.dateTransfer) {
      return;
    }
    this.dialogRef.close({
      rateMatter: this.rateBillToMatter,
      rateClient: this.rateBillToCompanySettings,
      amount: this.amountTransactionIsClient + this.amountTransactionIsMatter,
      date: this.dateTransfer
    });
  }
}
