// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-size input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.amount-selector {
  width: 85px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/wide-modals/expense-modal/expense-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,6BAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".default-size input {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.amount-selector {\n  width: 85px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
