import { ActivitiesActions, EActivitiesActions } from '../actions/activities.actions';
import { IActivitiesState, initialActivitiesState } from '../state/activities.state';
import { PROGRESS_STATUSES } from '@app/shared/symbols';

export const activitiesReducers = (state = initialActivitiesState, action: ActivitiesActions): IActivitiesState => {
  switch (action.type) {
    case EActivitiesActions.CLEAR_ACTIVITIES: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        },
        activitiesTotal: null
      };
    }

    case EActivitiesActions.GET_ACTIVITIES: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        }
      };
    }
    case EActivitiesActions.GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }

    case EActivitiesActions.GET_ACTIVITIES_TOTAL: {
      return {
        ...state,
        activitiesTotal: null
      };
    }
    case EActivitiesActions.GET_ACTIVITIES_TOTAL_SUCCESS: {
      return {
        ...state,
        activitiesTotal: action.payload
      };
    }

    // case EActivitiesActions.GET_ACTIVITY: {
    //   return {
    //     ...state,
    //     selectedActivity: null
    //   };
    // }
    case EActivitiesActions.GET_ACTIVITY_SUCCESS: {
      return {
        ...state,
        selectedActivity: action.payload
      };
    }

    case EActivitiesActions.ADD_ACTIVITY: {
      return {
        ...state,
        addActivitiesStatus: PROGRESS_STATUSES.IN_PROGRESS
      };
    }

    case EActivitiesActions.ADD_ACTIVITY_FAILURE: {
      return {
        ...state,
        addActivitiesStatus: PROGRESS_STATUSES.INTERRUPTED
      };
    }

    case EActivitiesActions.ADD_ACTIVITY_SUCCESS: {
      if (state.paginator.member !== null) {
        state.paginator.member.push(action.payload);
      }

      return {
        ...state,
        addActivitiesStatus: PROGRESS_STATUSES.SUCCEED
      };
    }


    case EActivitiesActions.UPDATE_ACTIVITY_SUCCESS: {
      return {
        ...state
      };
    }


    case EActivitiesActions.DELETE_ACTIVITY_SUCCESS: {
      if (action.payload.typeActivity === 'expense_internals') {
        return {
          ...state
        };
      }
      return {
        ...state,
        paginator: {
          countItems: state.paginator.countItems,
          member:
            state.paginator.member && state.paginator.member.length
              ? state.paginator.member.filter(item => item.id !== action.payload.id)
              : state.paginator.member,
          totalItems: state.paginator.totalItems,
          currentPage: state.paginator.currentPage,
          itemsPerPage: state.paginator.itemsPerPage,
          lastPage: state.paginator.lastPage
        }
      };
    }

    default: {
      return state;
    }
  }
};
