import { IAppState } from '../state/app.state';

export const selectBankAccountsPaginator = (state: IAppState) => state.bankAccountState.paginator;

export const selectBankAccountsByContact = (state: IAppState) => state.bankAccountState.bankAccountsByContact;

export const selectBankAccountsByCurrency = (state: IAppState) => state.bankAccountState.bankAccountsByCurrency;

export const selectSelectedBankAccount = (state: IAppState) => state.bankAccountState.selectedBankAccount;

export const selectBankAccountTotal = (state: IAppState) => state.bankAccountState.bankAccountTotal;
