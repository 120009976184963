import { IDocumentState, initialDocumentState } from '../state/document.state';
import { DocumentActions, EDocumentsActions } from '../actions/document.actions';
import { environment } from '@environments/environment';

export const documentReducers = (state = initialDocumentState, action: DocumentActions): IDocumentState => {
  switch (action.type) {
    case EDocumentsActions.GET_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        paginator: action.payload.paginator,
        documentFilters: action.payload.filters
      };
    }

    case EDocumentsActions.GET_DOCUMENTS_BY_ACTIVITY_SUCCESS: {
      return {
        ...state,
        documentsByActivity: action.payload
      };
    }
    case EDocumentsActions.CLEAR_DOCUMENTS: {
      return {
        ...state,
        paginator: {
          member: null,
          countItems: null,
          totalItems: environment.defaultItemsCount,
          currentPage: 1,
          itemsPerPage: environment.defaultItemsCount,
          lastPage: null
        },
        documentFilters: {}
      };
    }

    case EDocumentsActions.GET_DOCUMENTS_BY_TASK_SUCCESS: {
      return {
        ...state,
        documentsByTask: action.payload
      };
    }

    case EDocumentsActions.CLEAR_DOCUMENTS_BY_TASK: {
      return {
        ...state,
        documentsByTask: null
      };
    }

    case EDocumentsActions.GET_DOCUMENTS_BY_CORRESPONDENCE: {
      return {
        ...state,
        documentsByCorrespondence: []
      };
    }

    case EDocumentsActions.GET_DOCUMENTS_BY_CORRESPONDENCE_SUCCESS: {
      return {
        ...state,
        documentsByCorrespondence: action.payload
      };
    }

    case EDocumentsActions.GET_DOCUMENTS_BY_CONTRACT_SUCCESS: {
      return {
        ...state,
        documentsByContract: action.payload
      };
    }

    case EDocumentsActions.GET_DOCUMENTS_BY_BILL_SUCCESS: {
      return {
        ...state,
        documentsByBill: action.payload
      };
    }

    case EDocumentsActions.GET_DOCUMENT_SUCCESS: {
      return {
        ...state,
        selectedDocument: action.payload
      };
    }

    case EDocumentsActions.UPDATE_DOCUMENT_SUCCESS: {
      const idx = state.paginator.member?.findIndex(item => item.id === action.payload?.id);
      if (state.paginator.member && idx) {
        state.paginator.member[idx] = action.payload;
      }

      return {
        ...state,
        paginator: state.paginator,
        selectedDocument: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
