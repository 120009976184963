import { ContactFinance } from '@app/models/contact';

export interface ContactCardState {
  finance: ContactFinance;
  loading: boolean;
  error: Error;
}

export const initialContactCardState: ContactCardState = {
  finance: null,
  loading: false,
  error: null
};
