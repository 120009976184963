import { DocumentSave } from '@app/models/document-save';
import { DocRelation } from '@app/models/doc-relation';
import { ReminderExtended } from '@app/models/reminder-extended';

export enum TypeOfPerson {
  INDIVIDUAL = 'individual',
  LEGAL_ENTITY = 'legal_entity'
}


export interface ContactCompany {
  fullName: string;
  director: number;
  headPost: string;
  id: number;
  headReason: string;
  legalForm: string;
}

export interface ContactPerson {
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: any;
  bornPlace: string;
  nationality: string;
}

export interface RegistrationActions {
  number: string;
  date: any;
  id: number;
}

export interface LegalDocuments {
  id?: number;
  date?: string;
  name?: string;
  number?: string;
  whoIssuedDocument?: string;
  dateExpire?: string;
}

export interface ContactAddress {
  type: string;
  country: string;
  zipCode: string;
  region: string;
  regionDistrict: string;
  city: string;
  cityDistrict: string;
  street: string;
  id: number;
}

export interface ContactEmail {
  id: number;
  email: string;
}


export interface ContactWebsite {
  id: number;
  address: string;
}

export interface ContactMessenger {
  id: number;
  type: string;
  username: string;
}

export interface ContactBankDetail {
  id: number;
  name: string;
  iban: string;
}

export interface ContactDocument {
  document: DocumentSave;
  reminders: ReminderExtended;
  mediaFile: File;
  relations: DocRelation[];
}

export interface ContactPhone {
  id: number;
  phone: string;
}
