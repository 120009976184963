import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private openForMe = false;
  constructor() {}

  set OpenForMe(check) {
    this.openForMe = check;
  }
  get OpenForMe() {
    return this.openForMe;
  }

  formatMsgServerToFront(message) {
    let msg = {};
    const comment = [];
    if (message && typeof message.type !== 'undefined') {
      if (message.type === 'paragraph') {
        for (const messageContent of message.content) {
          if (messageContent.type === 'user') {
            msg = this.getUser(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'text') {
            msg = this.getText(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'matter') {
            msg = this.getMatter(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'task') {
            msg = this.getTask(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'expense') {
            msg = this.getActivity(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'time_entry') {
            msg = this.getActivity(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'contact') {
            msg = this.getContact(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'document') {
            msg = this.getDocument(messageContent);
            comment.push(msg);
          } else if (messageContent.type === 'event') {
            msg = this.getTask(messageContent);
            comment.push(msg);
          }
        }
        // comment.push({
        //   type: 'text',
        //   elementData: {
        //     name: '<br>',
        //     type: 'text'
        //   }
        // });
      }
    } else if (message) {
      msg = {
        type: 'text',
        elementData: {
          name: message,
          type: 'text'
        }
      };
      comment.push(msg);
    }
    return comment;
  }

  getUser(user) {
    return {
      type: user.type,
      elementData: {
        id: user.id,
        name: user.text,
        url: `/worker/${user.id}`,
        type: 'person',
        queryParams: {}
      }
    };
  }
  getText(txt) {
    return {
      type: txt.type,
      elementData: {
        name: txt.text,
        type: 'text',
        queryParams: {}
      }
    };
  }
  getMatter(matter) {
    return {
      type: matter.type,
      elementData: {
        id: matter.id,
        name: matter.text,
        url: [`/matter/${matter.id}`],
        type: 'project',
        queryParams: {}
      }
    };
  }
  getTask(task) {
    return {
      type: task.type,
      elementData: {
        id: task.id,
        name: task.text,
        url: [`/tasks/card/${task.id}`],
        type: 'project',
        queryParams: {}
      }
    };
  }
  getActivity(activity) {
    return {
      type: activity.type,
      elementData: {
        id: activity.id,
        name: activity.text,
        url: activity.type === 'expense' ? [`/activities/card/${activity.id}`] : [`/activities/card/${activity.id}`],
        queryParams: activity.type === 'expense' ? { type: 'expenses' } : { type: 'time_entries' },
        type: 'project'
      }
    };
  }

  getContact(contact) {
    return {
      type: contact.type,
      elementData: {
        id: contact.id,
        name: contact.text,
        url: [`/contact/${contact.id}`],
        type: 'project',
        queryParams: {}
      }
    };
  }
  getDocument(document) {
    return {
      type: document.type,
      elementData: {
        id: document.id,
        name: document.text,
        url: [`/documents/card/${document.id}`],
        type: 'project',
        queryParams: {}
      }
    };
  }

  htmlToJson(html: NodeListOf<HTMLElement>, content) {
    html.forEach(itemNode => {
      !content ? (content = []) : content;
      switch (itemNode.nodeName) {
        case 'P':
          return content.push({
            type: 'paragraph',
            content: this.htmlToJson(itemNode.childNodes as any, content.content)
          });
        case 'DIV':
          return content.push({
            type: 'paragraph',
            content: this.htmlToJson(itemNode.childNodes as any, content.content)
          });
        case '#text':
          return content.push({
            type: 'text',
            content: itemNode.nodeValue
          });
        case 'BR':
          return content.push({
            type: 'text',
            content: '<br>'
          });
        default:
          return content.push({
            type: 'text',
            content: itemNode.nodeValue
          });
      }
    });
    return content;
  }

  jsonToHtml(comment, json, type?) {
    comment.parseMsg += type ? `<${type} >` : '';
    for (const item of json) {
      switch (item.type) {
        case 'paragraph':
          Array.isArray(item.content)
            ? this.jsonToHtml(comment, item.content, 'p')
            : (comment.parseMsg += !item.content ? '&nbsp;' : item.content);
          break;
        case 'text':
          Array.isArray(item.content)
            ? this.jsonToHtml(comment.parseMsg, item.content)
            : (comment.parseMsg += !item.content ? '&nbsp;' : item.content);
          break;
        default:
          break;
      }
    }
    comment.parseMsg += type ? `</${type}>` : '';
  }
}
