import { Paginator } from '@app/models/paginator';
import { Action } from '@ngrx/store';
import { ExpenseType } from '@app/models';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum EExpenseTypesActions {
  LOAD_EXPENSE_TYPES = '[EXPENSE_TYPES] Load Expense Types',
  LOAD_EXPENSE_TYPES_SUCCESS = '[EXPENSE_TYPES] Load Expense Types Success',
  LOAD_EXPENSE_TYPES_FAILURE = '[EXPENSE_TYPES] Load Expense Types Failure',
  LOAD_INTERNAL_EXPENSE_TYPES = '[EXPENSE_TYPES] Load Internal Expense Types',
  LOAD_INTERNAL_EXPENSE_TYPES_SUCCESS = '[EXPENSE_TYPES] Load Internal Expense Types Success',
  LOAD_INTERNAL_EXPENSE_TYPES_FAILURE = '[EXPENSE_TYPES] Load Internal Expense Types Failure',
  GET_EXPENSE_TYPES = '[ExpenseTypes] Get Expense Types',
  GET_EXPENSE_TYPES_SUCCESS = '[ExpenseTypes] Get Expense Types Success',
  GET_EXPENSE_TYPES_FAILURE = '[ExpenseTypes] Get Expense Types Failure',
  ADD_EXPENSE_TYPE = '[ExpenseTypes] Add Expense Type',
  ADD_EXPENSE_TYPE_SUCCESS = '[ExpenseTypes] Add Expense Type Success',
  ADD_EXPENSE_TYPE_FAILURE = '[ExpenseTypes] Add Expense Type Failure',
  UPDATE_EXPENSE_TYPE = '[ExpenseTypes] Update Expense Type',
  UPDATE_EXPENSE_TYPE_SUCCESS = '[ExpenseTypes] Update Expense Type Success',
  UPDATE_EXPENSE_TYPE_FAILURE = '[ExpenseTypes] Update Expense Type Failure',
  DELETE_EXPENSE_TYPE = '[ExpenseTypes] Delete Expense Type',
  DELETE_EXPENSE_TYPE_SUCCESS = '[ExpenseTypes] Delete Expense Type Success',
  DELETE_EXPENSE_TYPE_FAILURE = '[ExpenseTypes] Delete Expense Type Failure',
  DELETE_EXPENSE_TYPES = '[ExpenseTypes] Delete Expense Types',
  DELETE_EXPENSE_TYPES_SUCCESS = '[ExpenseTypes] Delete Expense Types Success',
  DELETE_EXPENSE_TYPES_FAILURE = '[ExpenseTypes] Delete Expense Types Failure'
}
export class LoadExpenseTypesAction implements Action {
  readonly type = EExpenseTypesActions.LOAD_EXPENSE_TYPES;
}
export class LoadExpenseTypesSuccessAction implements Action {
  readonly type = EExpenseTypesActions.LOAD_EXPENSE_TYPES_SUCCESS;

  constructor(public payload: any) {}
}
export class LoadExpenseTypesFailureAction implements Action {
  readonly type = EExpenseTypesActions.LOAD_EXPENSE_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadInternalExpenseTypesAction implements Action {
  readonly type = EExpenseTypesActions.LOAD_INTERNAL_EXPENSE_TYPES;
}
export class LoadInternalExpenseTypesSuccessAction implements Action {
  readonly type = EExpenseTypesActions.LOAD_INTERNAL_EXPENSE_TYPES_SUCCESS;

  constructor(public payload: any) {}
}
export class LoadInternalExpenseTypesFailureAction implements Action {
  readonly type = EExpenseTypesActions.LOAD_INTERNAL_EXPENSE_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export class GetExpenseTypes implements Action {
  public readonly type = EExpenseTypesActions.GET_EXPENSE_TYPES;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetExpenseTypesSuccess implements Action {
  public readonly type = EExpenseTypesActions.GET_EXPENSE_TYPES_SUCCESS;

  constructor(public payload: Paginator<ExpenseType>) {}
}

export class GetExpenseTypesFailure implements Action {
  readonly type = EExpenseTypesActions.GET_EXPENSE_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddExpenseType implements Action {
  public readonly type = EExpenseTypesActions.ADD_EXPENSE_TYPE;

  constructor(public payload: ExpenseType) {}
}

export class AddExpenseTypeSuccess implements Action {
  public readonly type = EExpenseTypesActions.ADD_EXPENSE_TYPE_SUCCESS;

  constructor(public payload: ExpenseType) {}
}

export class AddExpenseTypeFailure implements Action {
  public readonly type = EExpenseTypesActions.ADD_EXPENSE_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateExpenseType implements Action {
  public readonly type = EExpenseTypesActions.UPDATE_EXPENSE_TYPE;

  constructor(public payload: ExpenseType) {}
}

export class UpdateExpenseTypeSuccess implements Action {
  public readonly type = EExpenseTypesActions.UPDATE_EXPENSE_TYPE_SUCCESS;

  constructor(public payload: ExpenseType) {}
}

export class UpdateExpenseTypeFailure implements Action {
  public readonly type = EExpenseTypesActions.UPDATE_EXPENSE_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteExpenseType implements Action {
  public readonly type = EExpenseTypesActions.DELETE_EXPENSE_TYPE;

  constructor(public payload: number) {}
}

export class DeleteExpenseTypeSuccess implements Action {
  public readonly type = EExpenseTypesActions.DELETE_EXPENSE_TYPE_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteExpenseTypeFailure implements Action {
  public readonly type = EExpenseTypesActions.DELETE_EXPENSE_TYPE_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteExpenseTypes implements Action {
  public readonly type = EExpenseTypesActions.DELETE_EXPENSE_TYPES;

  constructor(public payload: number[]) {}
}

export class DeleteExpenseTypesSuccess implements Action {
  public readonly type = EExpenseTypesActions.DELETE_EXPENSE_TYPES_SUCCESS;

  constructor(public payload: number[]) {}
}

export class DeleteExpenseTypesFailure implements Action {
  public readonly type = EExpenseTypesActions.DELETE_EXPENSE_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export type ExpenseTypeActions =
  | LoadExpenseTypesAction
  | LoadExpenseTypesSuccessAction
  | LoadExpenseTypesFailureAction
  | LoadInternalExpenseTypesAction
  | LoadInternalExpenseTypesSuccessAction
  | LoadInternalExpenseTypesFailureAction
  | GetExpenseTypes
  | GetExpenseTypesSuccess
  | GetExpenseTypesFailure
  | AddExpenseType
  | AddExpenseTypeSuccess
  | AddExpenseTypeFailure
  | UpdateExpenseType
  | UpdateExpenseTypeSuccess
  | UpdateExpenseTypeFailure
  | DeleteExpenseType
  | DeleteExpenseTypeSuccess
  | DeleteExpenseTypeFailure
  | DeleteExpenseTypes
  | DeleteExpenseTypesSuccess
  | DeleteExpenseTypesFailure;
