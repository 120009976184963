// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container.disabled {
  pointer-events: none;
}

.resend-code {
  margin: 0;
  margin-bottom: 11px;
}
.resend-code .link {
  color: #1252AF;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/system-modals/login-modal/login-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AACA;EACE,SAAA;EACA,mBAAA;AAEF;AADE;EACE,cAAA;EACA,eAAA;AAGJ","sourcesContent":[".input-container.disabled {\n  pointer-events: none;\n}\n.resend-code {\n  margin: 0;\n  margin-bottom: 11px;\n  .link {\n    color: #1252AF;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
