import { Paginator } from '@app/models/paginator';
import { Tag } from '@app/models/tag';
import { environment } from '@environments/environment';

export interface ITagState {
  paginator: Paginator<Tag>;
  success: boolean;
  data: Tag[];
  page: number;
}

export const initialTagState: ITagState = {
  paginator: {
    member: null,
    countItems: null,
    totalItems: null,
    currentPage: environment.defaultPage,
    itemsPerPage: environment.defaultItemsCount,
    lastPage: null
  },
  success: false,
  data: null,
  page: 1
};
