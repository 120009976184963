import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/_services/auth.service';
import { map, Observable } from 'rxjs';
import { ResponseBodyFull } from '@app/models';
import { ICompanyPortalSettings, ICompanyPortalSettingsFilters } from '@app/models/company-portal-settings';
import { Helpers } from '@app/helpers/helpers';
import { ICompanyUserPortalAccesses } from '@app/models/company-user-portal-accesses';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root',
})
export class CompanyPortalSettingsService {
  constructor(private http: HttpClient, private authService: AuthService) {
  }

  private BASE_URL = `${environment.apiUrl}/company_portal_settings`;
  private URL_USER_ACCESSES = `${environment.apiUrl}/company_user_portal_accesses`;

  get(filters: ICompanyPortalSettingsFilters): Observable<Paginator<ICompanyPortalSettings>> {
    let params = new HttpParams().set('partial', 'false');

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<ICompanyPortalSettings>>(this.BASE_URL, { params })
        .pipe(map(response => ({ member: response['hydra:member'], ...response.pagination })));
      ;
    } else {
      return new Observable<Paginator<ICompanyPortalSettings>>();
    }
  }

  getById(id: number): Observable<ICompanyPortalSettings> {
    if (this.authService.currentUserValue) {
      return this.http.get<ICompanyPortalSettings>(`${this.BASE_URL}/${id}`);
    } else {
      return new Observable<ICompanyPortalSettings>();
    }
  }

  put(data: ICompanyPortalSettings): Observable<ICompanyPortalSettings> {
    if (this.authService.currentUserValue) {
      return this.http.patch<ICompanyPortalSettings>(`${this.BASE_URL}/${data.id}`, data);
    } else {
      return new Observable<ICompanyPortalSettings>();
    }
  }

  post(data: ICompanyPortalSettings): Observable<ICompanyPortalSettings> {
    if (this.authService.currentUserValue) {
      return this.http.post<ICompanyPortalSettings>(this.BASE_URL, data);
    } else {
      return new Observable<ICompanyPortalSettings>();
    }
  }

  delete(id: number) {
    if (this.authService.currentUserValue) {
      return this.http.delete<any>(`${this.BASE_URL}/${id}`);
    } else {
      return new Observable<any>();
    }
  }

  postCompanyUserPortalAccesses(data): Observable<ICompanyUserPortalAccesses> {
    if (this.authService.currentUserValue) {
      return this.http.post<ICompanyUserPortalAccesses>(this.URL_USER_ACCESSES, data);
    } else {
      return new Observable<ICompanyUserPortalAccesses>();
    }
  }

  putCompanyUserPortalAccesses(id: number, enable: boolean): Observable<ICompanyUserPortalAccesses> {
    if (this.authService.currentUserValue) {
      return this.http.patch<ICompanyUserPortalAccesses>(`${this.URL_USER_ACCESSES}/${id}`, { enable });
    } else {
      return new Observable<ICompanyUserPortalAccesses>();
    }
  }
}
