import { ActivityExtended, TaskExtended } from '@app/models';
import { BankAccountExtended } from '@app/models/bank-account-extended';

export enum TypeExpenseEnum {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}
export interface ExpenseModalDataModel {
  expense?: ActivityExtended;
  contact?: { id: number };
  date?: Date;
  responsible?: { id: number };
  matter?: { id: number };
  task?: TaskExtended;
  bankAccount?: BankAccountExtended;
  type: TypeExpenseEnum;
}
