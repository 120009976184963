import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BillService } from '../../services/bill.service';

@Component({
  selector: 'app-bills-preview-bill',
  templateUrl: './bills-preview-bill.component.html',
  styleUrls: ['./bills-preview-bill.component.scss']
})
export class BillsPreviewBillComponent {
  billUrl: SafeResourceUrl;

  @Input('url') set populateUrl(url: string) {
    if (url) {
      console.log(url);
      this.billService.getPdf(url).subscribe(res => {
        const file = new Blob([res], { type: 'text/html' });
        const fileURL = URL.createObjectURL(file);
        this.billUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      });
    }
  }

  constructor(private sanitizer: DomSanitizer, private billService: BillService) {}

  heightFrame = 700;
  afterLoadFrame(e) {
    this.heightFrame = e.path ? e.path[0].contentWindow.document.body.scrollHeight + 50 : e.target.contentWindow.outerHeight + 50;
  }
}
