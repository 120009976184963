import { Component, Input } from '@angular/core';
import { RouteData } from '@app/models';

@Component({
  selector: 'app-tabs-nav',
  templateUrl: './tabs-nav.component.html',
  styleUrls: ['./tabs-nav.component.scss']
})
export class TabsNavComponent {
  @Input() key: string;
  @Input() routesData: RouteData[];
}
