// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-template {
  width: 500px;
}
.modal-template .modal-header {
  background: #ffffff;
  border-bottom: 1px solid #EDEDED;
}
.modal-template .modal-header .title-text {
  color: #222222;
  font-weight: 500;
  margin-left: 20px;
}
.modal-template .modal-body {
  padding: 14px 20px;
}
.modal-template .sub-label {
  margin: 0;
}
.modal-template .modal-footer {
  justify-content: flex-end;
  border-top: none;
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/two-factor-setting-modal/two-factor-setting-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,mBAAA;EACA,gCAAA;AAEJ;AADI;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,SAAA;AAEJ;AAAE;EACE,yBAAA;EACA,gBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".modal-template {\n  width: 500px;\n  .modal-header {\n    background: #ffffff;\n    border-bottom: 1px solid #EDEDED;\n    .title-text {\n      color: #222222;\n      font-weight: 500;\n      margin-left: 20px;\n    }\n  }\n  .modal-body {\n    padding: 14px 20px;\n  }\n  .sub-label {\n    margin: 0;\n  }\n  .modal-footer {\n    justify-content: flex-end;\n    border-top: none;\n    padding-top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
