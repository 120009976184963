import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { disappearanceAnimation } from '@app/helpers';
import { checkTariff } from '@app/helpers/check-tariff';
import { CompanySettings } from '@app/models/company-settings';
import { CompanySettingService } from '@app/modules/settings/services/company-setting.service';
import { take } from 'rxjs';
import { ReportsTariffModalComponent } from '../reports-tariff-modal/reports-tariff-modal.component';
import { Tariff } from '@app/models/tariff';

@Component({
  selector: 'app-account-correspondence-modal',
  templateUrl: './account-correspondence-modal.component.html',
  styleUrls: ['./account-correspondence-modal.component.scss'],
  animations: [disappearanceAnimation]

})
export class AccountCorrespondenceModalComponent implements OnInit {

  accountForm: UntypedFormGroup;
  submittedForm = false;
  proffFields = false;
  errorProff = false;
  showLoader = false;

  checking = false;
  constructor(
    public dialogRef: MatDialogRef<AccountCorrespondenceModalComponent>,
    private companySettingService: CompanySettingService,
    @Inject(MAT_DIALOG_DATA) public data: {companySettings: CompanySettings, tariffs: Tariff[]},
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.proffFields = this.data.companySettings.correspondenceImapConnection.enabled;
    if (!checkTariff([1, 4])) {
      this.proffFields = false;
    }
    this.initForm();
  }

  get f() {
    return this.accountForm;
  }

  onSubmit() {
    if (this.checking) {
      return;
    }
    this.submittedForm = true;
    if (this.accountForm.valid) {
      this.errorProff = false;
      const value = {
        correspondenceEmailName: this.proffFields ? null : this.accountForm.value.correspondenceEmailName,
        correspondenceImapConnection: {
          username: this.proffFields ? this.accountForm.value.username : '',
          password: this.proffFields ? this.accountForm.value.password : '',
          host: this.proffFields ? this.accountForm.value.host : '',
          port: this.proffFields ? +this.accountForm.value.port : 993,
          enabled: this.proffFields ? true : false,
        }
      };
      this.checking = true;
      this.openSpinner();
      this.companySettingService.updateCompanySettings({
        id: this.data.companySettings.id,
        correspondenceEmailName: value.correspondenceEmailName,
        correspondenceImapConnection: value.correspondenceImapConnection
      }).pipe(take(1)).subscribe(
        x => {
          this.checking = false;
          this.hideSpinner();
          this.dialogRef.close(true);
        },
        () => {
          this.checking = false;
          this.errorProff = true;
        }
      );
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  switchType() {
    if (checkTariff([1, 4]) || (!checkTariff([1, 4]) && this.proffFields)) {
      this.proffFields = !this.proffFields;
      this.initForm();
      this.accountForm.updateValueAndValidity();
    } else {
      this.dialog.open(ReportsTariffModalComponent, { data: {
        tariff: this.data.tariffs.find(el => el.id === 4),
        bgImg: 'EmailPremium.png', // тут змінити на ту картинку яка потрібна,
        titleTxt: 'settings.correspondence.modal.title',
        descriptionTxt: 'settings.correspondence.modal.description'
      } }).afterClosed().subscribe(res => {
        if (res) {
          this.dialogRef.close();
        }
      });
    }
  }
  initForm() {
    this.accountForm = this.formBuilder.group({
      correspondenceEmailName: [this.data?.companySettings.correspondenceEmailName || null,
        !this.proffFields ? [Validators.required, Validators.pattern('^[a-z]*$'), Validators.maxLength(16)] : []],
      username: [this.data?.companySettings?.correspondenceImapConnection.username || '', this.proffFields ? [Validators.required] : []],
      password: [this.data?.companySettings?.correspondenceImapConnection.password || '', this.proffFields ? [Validators.required] : []],
      host: [this.data?.companySettings?.correspondenceImapConnection.host || '', this.proffFields ? [Validators.required] : []],
      port: [this.data?.companySettings?.correspondenceImapConnection.port || '993', this.proffFields ? [Validators.required] : []],
    });

  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }
}
