import { TaskStatus } from '@app/enums/task-status.enum';
import { MatterExtended } from './matter-extended';
import { TaskUser } from './task-user';

export interface TaskExtended {
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  location: string;
  priority: string;
  court: boolean;
  type: 'task' | 'event';
  status: TaskStatus;
  estimate?: number;
  matter: MatterExtended;
  taskUsers: TaskUser[];
  id: number;
  countUnreadComments?: number;
  orderCanban?: number;
  dateExpired?: boolean;
  start?: boolean;
  hide?: boolean;
  empty?: boolean;
  origDate?: string;
}

export interface ScheduleDay {
  date: string;
  tasks: TaskExtended[];
  events?: any;
}
