import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Helpers } from '@app/helpers/helpers';
import { DiscussionService } from '@app/modules/layout/discussion/services/discussion.service';
import { environment } from '@environments/environment';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[appAutocomplete]'
})
export class AutocompleteDirective implements OnInit {
  suggestions: string[] = [];
  @Input() control: FormControl;
  private inputEl: HTMLTextAreaElement;
  private suggestionsContainer: HTMLElement;
  private suggestionsListContainer: HTMLElement;
  private suggestionsCloseContainer: HTMLElement;
  private loaderIcon: HTMLElement;
  private topPosition = 0;
  filters = {
    itemsPerPage: environment.itemsCountSearch,
    search: '',
    type: 'activity'
  };
  constructor(private elRef: ElementRef, private discussionService: DiscussionService) { }

  ngOnInit() {
    this.inputEl = this.elRef.nativeElement as HTMLTextAreaElement;
    this.createSuggestionsContainer();

    fromEvent(this.inputEl, 'input').subscribe(() => {
      this.hideSuggestions();
      this.loaderIcon.style.opacity = '0';

    });

    fromEvent(this.inputEl, 'input').pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(() => {
      if (this.inputEl.value.length >= environment.countSearch) {
        this.loaderIcon.style.opacity = '1';
      } else {
        this.loaderIcon.style.opacity = '0';
      }
    });

    fromEvent(this.inputEl, 'input').pipe(
      debounceTime(1500),
      distinctUntilChanged(),
    ).subscribe(() => {

      if (this.inputEl.value.length >= environment.countSearch) {
      this.loaderIcon.style.opacity = '1';
      this.filters.search = this.inputEl.value;
      this.discussionService.getProjectMention(this.filters).subscribe(res => {
            this.suggestions = Helpers.arrayUnique(res.activity.items, 'description').map(el => el.description);
            this.showSuggestions();
            this.loaderIcon.style.opacity = '0';
          });
      } else {
        this.loaderIcon.style.opacity = '0';
        this.hideSuggestions();
      }
    });
  }


  @HostListener('input')
  onInput() {
    if (this.inputEl.value.length >= 3) {
      this.showSuggestions();
    } else {
      this.hideSuggestions();
    }
  }


  private createSuggestionsContainer() {
    this.suggestionsContainer = document.createElement('div');
    this.suggestionsContainer.classList.add('suggestions-container');

    const suggestionsHeaderContainer = document.createElement('div');
    suggestionsHeaderContainer.classList.add('sugg-header');
    suggestionsHeaderContainer.innerHTML = '<span class="j2-icon-autocomplete"></span><span> Інтелектуальний пошук </span>';
    this.suggestionsContainer.appendChild(suggestionsHeaderContainer);

    this.suggestionsListContainer = document.createElement('div');
    this.suggestionsListContainer.classList.add('suggestions-list-container');
    this.suggestionsContainer.appendChild(this.suggestionsListContainer);
    this.inputEl.parentNode.insertBefore(this.suggestionsContainer, this.inputEl.nextSibling);

    this.suggestionsCloseContainer = document.createElement('div');
    this.suggestionsCloseContainer.classList.add('backdrop-close-container');
    this.suggestionsCloseContainer.onclick = () => this.hideSuggestions();
    this.inputEl.parentNode.insertBefore(this.suggestionsCloseContainer, this.inputEl.nextSibling);


    this.loaderIcon = document.createElement('span');
    this.loaderIcon.classList.add('j2-icon-loader');
    this.loaderIcon.style.top = this.inputEl.getBoundingClientRect().height + 'px';

    this.inputEl.parentNode.insertBefore(this.loaderIcon, this.inputEl.nextSibling);
  }

  private showSuggestions() {
    const textareaRect = this.inputEl.getBoundingClientRect();
    const textareaValue = this.inputEl.value;
    const cursorPosition = this.inputEl.selectionStart;
    const lines = textareaValue.substr(0, cursorPosition).split('\n');
    const currentLineNumber = lines.length;
    const top = currentLineNumber * 16;
    const topf = top + textareaRect.top + 11;
    this.topPosition = topf - this.elRef.nativeElement.scrollTop + 40;
    this.suggestionsContainer.style.top = `${this.topPosition}px`;
    this.suggestionsContainer.style.opacity = '1';

    if (this.suggestions.length) {
      this.suggestions.forEach(suggestion => {
        const suggestionEl = document.createElement('div');
        suggestionEl.classList.add('suggestion');

        suggestionEl.innerText = suggestion;
        suggestionEl.addEventListener('click', () => {
          this.replaceText(suggestion);
          this.hideSuggestions();
        });
        this.suggestionsListContainer.appendChild(suggestionEl);
      });

      const maxHeight = textareaRect.bottom - textareaRect.top - (textareaRect.bottom - this.inputEl.scrollTop);
      this.suggestionsContainer.style.maxHeight = `${maxHeight}px`;
      this.suggestionsContainer.style.display = 'block';
      this.suggestionsCloseContainer.style.display = 'block';
      this.suggestionsContainer.style['zIndex'] = '100';

    } else {
      this.hideSuggestions();
    }
  }

  private hideSuggestions() {
    this.suggestionsContainer.style.top = `${this.topPosition - 10}px`;

    this.suggestionsContainer.style.opacity = '0';
    this.suggestionsContainer.style['zIndex'] = '-1';
    this.suggestionsCloseContainer.style.display = 'none';
    this.suggestionsListContainer.innerHTML = '';


  }

  private replaceText(suggestion: string) {
    this.control?.setValue(suggestion);
    this.inputEl.value = suggestion;
  }
}
