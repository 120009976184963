import { Component, Input, OnInit } from '@angular/core';
import { fadeTopAnimation } from '@app/helpers/fadeAnimation';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { SwitchNotificationsService } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { NotificationsService } from '../notifications/services/notifications.service';
import { take } from 'rxjs/operators';
import { CommunicationPublicService } from '../discussion/services/communication.service';
import { CommunicationService } from '@app/modules/reusable/jusnote-connect/services/communication.service';
import { TasksEventsModalComponent } from '@app/commonComponents/wide-modals/task-event-modal/t-e-modal.component';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
  animations: [fadeTopAnimation]
})
export class NotificationPopupComponent implements OnInit {
  @Input() message$: Subject<any>;
  @Input() notifications: any;
  public notificationPopup: any[] = [];

  public decisionJuscourtUrl = environment.decisionJuscourtUrl;

  constructor(
    private router: Router,
    private switchNotificService: SwitchNotificationsService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private notificationsService: NotificationsService,
    private switchNotificationsService: SwitchNotificationsService,
    private communicationPublicService: CommunicationPublicService,
    private communicationService: CommunicationService
  ) {}

  ngOnInit() {
    this.message$.subscribe(r => {
      switch (r.type) {
        case 'juscourt_new_decision':
        case 'juscourt_new_session':
        case 'document_signed':
          r.data = JSON.parse(r.data);
          break;
        case 'comment_communication':
          r.communication = JSON.parse(r.communication);
        default:
          break;
      }

      this.closePopupNotification(r);
    });
    // const r ={
    //   type: 'document_signed',
    //   data: {documentId: 52734, documentName: "sample.pdf"}
    // }
    // this.closePopupNotification(r);

  }

  closePopup(index) {
    this.notificationPopup.splice(index, 1);
  }

  closePopupNotification(message) {
    this.notificationPopup.unshift(message);

    setTimeout(() => {
      const currentIndex = this.notificationPopup[this.notificationPopup.length - 1];
      if (this.notificationPopup.indexOf(currentIndex) !== -1) {
        this.notificationPopup.splice(this.notificationPopup.indexOf(currentIndex), 1);
      }
    }, 15000);
  }

  goToNotificationsList(index) {
    this.notifications.notification = true;
    this.notificationPopup = [];
  }

  getRoute(event) {
    const goRoute = event.target.getAttribute('[routerLink]');
    if (event.target.getAttribute('[routerlink]')) {
      this.router.navigate([goRoute]);
      this.switchNotificService.switchNotifications('disable');
    }
  }

  createEvent(item) {
    this.eventsModal(item);
  }

  eventsModal(courtSession) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'events-modal'];
    dialogConfig.disableClose = true;

    this.notificationsService
      .getCourtSessionData(courtSession.data.sessionId)
      .pipe(take(1))
      .subscribe(res => {
        res.matter = { name: courtSession.data.matterName, id: courtSession.data.matterId };
        dialogConfig.data = { courtSession: res, type: 'event' };

        const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
        dialogRef.backdropClick().subscribe(() => {
          this.confirmCloseModal(dialogRef);
        });
      });
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  openCommunicationInfo(communication) {
    this.router.navigateByUrl(`/contact/${communication.userPortalContactId}/jusnote-connect/all`);
    this.communicationService.getCommunicationById(communication.id).subscribe(resp => {
      this.switchNotificationsService.switchNotifications('communication');
      setTimeout(() => {
        this.communicationPublicService.setCommunication(resp);
      });
    });
  }
}
