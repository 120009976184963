import { correspondenceState } from '@app/store/state/correspondence.state';
import { Correspondence } from '@app/models/correspondence';
import { CorrespondenceActions, ECorrespondenceActions } from '@app/store/actions/correspondence.actions';
import { CorrespondenceState } from '@app/models/interfaces/correspondence-state';

export const correspondenceReducers = (state = correspondenceState, action: CorrespondenceActions): CorrespondenceState => {
  switch (action.type) {
    // case ECorrespondenceActions.SELECT_CORRESPONDENCE_FROM_STORE: {
    //   return {
    //     ...state,
    //     selected: action.payload
    //   };
    // }

    case ECorrespondenceActions.CLEAR_CORRESPONDENCE: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        },
        correspondences: {}
      };
    }

    case ECorrespondenceActions.CREATE_CORRESPONDENCE_SUCCESS: {
      return {
        ...state,
        selected: action.payload
      };
    }

    case ECorrespondenceActions.REMOVE_CORRESPONDENCE_SUCCESS: {
      const { correspondences } = state;

      if (Object.keys(correspondences).length) {
        const idx = (correspondences as Correspondence[]).findIndex(item => item.id === action.payload);
        delete correspondences[idx];
      }
      let selected = state.selected;
      if (state.selected && state.selected.id === action.payload) {
        selected = null;
      }
      return {
        ...state,
        correspondences,
        selected
      };
    }
    case ECorrespondenceActions.UPDATE_CORRESPONDENCE_SUCCESS: {
      const { correspondences } = state;
      if (Object.keys(correspondences).length) {
        const idx = (correspondences as Correspondence[]).findIndex(item => item.id === action.payload.id);
        correspondences[idx] = action.payload;
      }

      return {
        ...state,
        correspondences,
        selected: action.payload
      };
    }
    case ECorrespondenceActions.GET_CORRESPONDENCES: {
      return {
        ...correspondenceState
      };
    }
    case ECorrespondenceActions.GET_CORRESPONDENCE_BY_ID_SUCCESS: {
      const { correspondences } = state;

      return { ...state, selected: action.payload };
    }
    case ECorrespondenceActions.GET_TRACK_INFO: {
      return { ...state, track: null };
    }
    case ECorrespondenceActions.GET_TRACK_INFO_SUCCESS: {
      return { ...state, track: action.payload };
    }
    case ECorrespondenceActions.GET_TIMELINE_INFO_SUCCESS: {
      return { ...state, timeline: action.payload };
    }
    case ECorrespondenceActions.GET_DROPDOWN_CORRESPONDENCES_SUCCESS: {
      return { ...state, correspondenceDropdown: action.payload };
    }
    case ECorrespondenceActions.GET_CORRESPONDENCE_MESSAGE_SUCCESS: {
      return { ...state, notifications: action.payload };
    }
    case ECorrespondenceActions.GET_CORRESPONDENCES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    default:
      return state;
  }
};
