import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { TimerView } from '@app/models/timer-view';
import { TimerCreate } from '@app/models/timer-create';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * WIP
 */
@Injectable({
  providedIn: 'root'
})
export class TimerApi {
  static TIMER_ENDPOINT = `${environment.apiUrl}/timers`;

  constructor(private http: HttpClient) {}

  createTimer(timer: TimerCreate): Observable<TimerView> {
    return this.http.post<TimerView>(TimerApi.TIMER_ENDPOINT, timer);
  }

  stopTimer(id: number) {
    return this.http.delete(`${TimerApi.TIMER_ENDPOINT}/${id}`);
  }
}
