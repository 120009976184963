// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
          :host {
            position: relative;
            overflow: hidden;
            margin: 0 0 6px;
            padding: 10px 10px 10px 10px;
            color: #ffffff;
            pointer-events: all;
            cursor: pointer;
          }
        `, "",{"version":3,"sources":["webpack://./src/app/commonComponents/custom-toaster/custom-toaster.component.ts"],"names":[],"mappings":";UACU;YACE,kBAAkB;YAClB,gBAAgB;YAChB,eAAe;YACf,4BAA4B;YAC5B,cAAc;YACd,mBAAmB;YACnB,eAAe;UACjB","sourcesContent":["\n          :host {\n            position: relative;\n            overflow: hidden;\n            margin: 0 0 6px;\n            padding: 10px 10px 10px 10px;\n            color: #ffffff;\n            pointer-events: all;\n            cursor: pointer;\n          }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
