import { Action } from '@ngrx/store';
import { Paginator } from '@app/models/paginator';
import { Bill } from '@app/models/bill';
import { BillFetch } from '@app/models/interfaces/bill-fetch';
import { BillStatusUpdate } from '@app/models/interfaces/BillStatusUpdate';
import { BillTotal } from '@app/models/interfaces/BillTotal';

export enum EBillActions {
  CREATE_BILL = '[Bill] Create bill',
  CREATE_BILL_SUCCESS = '[Bill] Create Bill Success',
  SELECT_BILL = '[Bill] Select',
  REMOVE_BILL = '[Bill] Remove Bill',
  REMOVE_BILL_SUCCESS = '[Bill] Remove Bill Success',
  REMOVE_BILL_FAILURE = '[Bill] Remove Bill Failure',
  UPDATE_BILL = '[Bill] Update Bill',
  UPDATED_BILL_SUCCESS = '[Bill] Update Bill Success',
  GET_BILLS = '[Bill] Get Bills',
  GET_BILLS_SUCCESS = '[Bill] Get Bills Success',
  GET_BILLS_TOTAL_SUCCESS = '[Bill] Get Bill Total Success',
  GET_BILL_BY_ID = '[Bill] Get Bill By Id',
  GET_BILL_BY_ID_SUCCESS = '[Bill] Get Bill By Id Success',
  CLEAR_BILLS = '[Bill] Clear Bills'
}

export class ClearBills implements Action {
  public readonly type = EBillActions.CLEAR_BILLS;

  constructor() {}
}
export class CreateBill implements Action {
  public readonly type = EBillActions.CREATE_BILL;

  constructor(public payload: Bill) {}
}

export class CreateBillSuccess implements Action {
  public readonly type = EBillActions.CREATE_BILL_SUCCESS;

  constructor(public payload: Bill) {}
}

export class SelectBill implements Action {
  public readonly type = EBillActions.SELECT_BILL;

  constructor(public payload: number) {}
}

export class RemoveBill implements Action {
  public readonly type = EBillActions.REMOVE_BILL;

  constructor(public payload: number) {}
}

export class RemoveBillSuccess implements Action {
  public readonly type = EBillActions.REMOVE_BILL_SUCCESS;

  constructor(public payload: number) {}
}

export class RemoveBillFailure implements Action {
  public readonly type = EBillActions.REMOVE_BILL_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateBill implements Action {
  public readonly type = EBillActions.UPDATE_BILL;

  constructor(public id: number, public payload: Bill | BillStatusUpdate) {}
}

export class UpdateBillSuccess implements Action {
  public readonly type = EBillActions.UPDATED_BILL_SUCCESS;

  constructor(public payload: Bill) {}
}

export class GetBillById implements Action {
  public readonly type = EBillActions.GET_BILL_BY_ID;

  constructor(public payload: number) {}
}

export class GetBillByIdSuccess implements Action {
  public readonly type = EBillActions.GET_BILL_BY_ID_SUCCESS;

  constructor(public payload: Bill) {}
}

export class GetBills implements Action {
  public readonly type = EBillActions.GET_BILLS;

  constructor(public payload: BillFetch) {}
}

export class GetBillsSuccess implements Action {
  public readonly type = EBillActions.GET_BILLS_SUCCESS;

  constructor(public payload: Paginator<Bill>) {}
}

export class GetBillsTotalSuccess implements Action {
  public readonly type = EBillActions.GET_BILLS_TOTAL_SUCCESS;

  constructor(public payload: BillTotal) {}
}

export type BillActions =
  | ClearBills
  | GetBills
  | GetBillsSuccess
  | CreateBill
  | CreateBillSuccess
  | SelectBill
  | UpdateBill
  | UpdateBillSuccess
  | RemoveBill
  | RemoveBillSuccess
  | RemoveBillFailure
  | GetBillById
  | GetBillByIdSuccess
  | GetBillsTotalSuccess;
