import { JwtHelperService } from '@auth0/angular-jwt';
import { Payload } from '@app/models';

/**
 * Why not ENUM
 * @link https://medium.com/@maxheiber/alternatives-to-typescript-enums-50e4c16600b1
 * Also ENUM doesn't give us at least type assurance
 * Yes, I know about ETeamMemberRoles
 * @see ETeamMemberRoles
 */
export type Roles =
  | 'ROLE_SITE_USER'
  | 'ROLE_SITE_BILLING_MANAGER'
  | 'ROLE_SITE_ACCOUNTS_MANAGER'
  | 'ROLE_SITE_REPORTS_MANAGER'
  | 'ROLE_SITE_ADMIN'
  | 'ROLE_SITE_SUBSCRIPTIONS_MANAGER'
  | 'ROLE_SITE_PORTAL_USER';

/**
 * Determine if current User has any of given roles
 * @param roles List of ALLOWED roles
 */
export function checkRole(roles: Roles | Roles[]): boolean {
  /**
   * It will be more comfortable to work with an array
   */
  const requiredRoles = Array.isArray(roles) ? roles : [roles];

  const jwt = new JwtHelperService();

  /**
   * Check JWT for existence in localstorage
   */
  const JWT = localStorage.getItem('JWT_TOKEN');
  if (!JWT) {
    return false;
  }
  /**
   * According to AuthService.getPayload
   * But we cannot inject it here
   */
  const userRoles = jwt.decodeToken(localStorage.getItem('JWT_TOKEN')) as Payload;

  /**
   * @link https://stackoverflow.com/a/39893636
   */
  return requiredRoles.some(role => userRoles.roles.includes(role));
}
