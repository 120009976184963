import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class SubscribedGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.isCheckCurrentSubscriptionId()) {
      return true;
    }
    if(this.authService.currentUserValue) {
      this.router.navigateByUrl('/settings/subscription');
    }
    return false;
  }
}
