import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { IUser } from '@app/models';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { UpdateUserLang } from '@app/auth/_store/actions/auth.actions';

@Component({
  selector: 'app-switch-lang',
  templateUrl: './switch-lang.component.html',
  styleUrls: ['./switch-lang.component.scss']
})
export class SwitchLangComponent implements OnInit {
  constructor(private store: Store<IAppState>) {}
  currentUser: IUser;
  @Input() sidebar = false;
  public languages = {
    en: 'English',
    uk: 'Українська'
  };
  ngOnInit() {
    this.store.select(selectCurrentUser).subscribe(user => {
      this.currentUser = user;
    });
  }
  switchLang(lang: string) {
    const settings = {
      settings: [{ lang, id: this.currentUser.settings[0].id }]
    };
    this.store.dispatch(new UpdateUserLang(settings as any, this.currentUser.id));
  }

  isActive(lang: string) {
    return this.currentUser.settings[0].lang === lang;
  }

  public get getLang() {
    if (this.currentUser) {
      return this.currentUser.settings[0].lang;
    }
    return 'en';
  }
}
