import { Paginator } from '@app/models/paginator';
import { ActivityTotal } from '@app/models/interfaces/activity-total';
import { environment } from '@environments/environment';
import { ActivityExtended } from '@app/models';
import { PROGRESS_STATUSES } from '@app/shared/symbols';

export interface IActivitiesState {
  paginator: Paginator<ActivityExtended>;
  activitiesTotal: ActivityTotal;
  selectedActivity: ActivityExtended;
  addActivitiesStatus: PROGRESS_STATUSES;
}

export const initialActivitiesState: IActivitiesState = {
  paginator: {
    member: null,
    countItems: null,
    totalItems: null,
    currentPage: environment.defaultPage,
    itemsPerPage: environment.defaultItemsCount,
    lastPage: null
  },
  activitiesTotal: null,
  selectedActivity: null,
  addActivitiesStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
};
