import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CheckTariffDirective } from '@app/common/directives/check-tariff.directive';
import { ClickOutsideDirective } from '@app/common/directives/click-outside.directive';
import { DynamicInputDirective } from '@app/common/directives/dynamic-input.directive';
import { CustomRatesComponent } from '@app/commonComponents/custom-rates/custom-rates.component';
import { ImageUploaderComponent } from '@app/commonComponents/image-uploader/image-uploader.component';
import { PermissionBlockComponent } from '@app/commonComponents/permission-block/permission-block.component';
import { RatesComponent } from '@app/commonComponents/rates/rates.component';
import { RelationGraphItemComponent } from '@app/commonComponents/relation-graph/relation-graph-item/relation-graph-item.component';
import { RelationGraphComponent } from '@app/commonComponents/relation-graph/relation-graph/relation-graph.component';
import { TagComponent } from '@app/commonComponents/tags/tag/tag.component';
import { BillsPreviewBillComponent } from '@app/modules/main-bills/components/bills-preview-bill/bills-preview-bill.component';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ComboBoxModule, DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SelectDatePopupComponent } from '../commonComponents/modals/other-modals/select-date-popup/select-date-popup.component';
import { DocusignPopupComponent } from '../commonComponents/popups/docusign-popup/docusign-popup.component';
import { CommonsModule } from './common/commons.module';
import { MaterialModule } from './material/material.module';
import { AutocompleteDirective } from '@app/common/directives/autocomplete.directive';
import { JuscourtPopupComponent } from '@app/commonComponents/popups/juscourt-popup/juscourt-popup.component';
import { ScaleDirective } from '@app/common/directives/scale.directive';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from '@app/perfect-scrollbar/perfect-scrollbar.interfaces';
import { PerfectScrollbarModule } from '@app/perfect-scrollbar/perfect-scrollbar.module';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatDialogClose } from '@angular/material/dialog';
import { LoaderModule } from '@app/commonComponents/loader/loader.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ComboBoxModule,
    NumericTextBoxModule,
    PerfectScrollbarModule,
    RouterModule,
    MaterialModule,
    CommonsModule,
    CalendarModule,
    DatePickerModule,
    DateTimePickerModule,
    CommonModule,
    DropDownListModule,
    MatMenuTrigger,
    MatMenu,
    MatDialogClose,
    LoaderModule
  ],
  declarations: [
    ImageUploaderComponent,
    RatesComponent,
    CustomRatesComponent,
    RelationGraphComponent,
    RelationGraphItemComponent,
    PermissionBlockComponent,
    CheckTariffDirective,
    BillsPreviewBillComponent,
    SelectDatePopupComponent,
    DocusignPopupComponent,
    JuscourtPopupComponent,
    DynamicInputDirective,
    AutocompleteDirective,
    ClickOutsideDirective,
    TagComponent,
    ScaleDirective,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  exports: [
    ImageUploaderComponent,
    RatesComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ComboBoxModule,
    MultiSelectModule,
    CalendarModule,
    DatePickerModule,
    DateTimePickerModule,
    NumericTextBoxModule,
    PermissionBlockComponent,
    CheckTariffDirective,
    CustomRatesComponent,
    RelationGraphComponent,
    BillsPreviewBillComponent,
    PerfectScrollbarModule,
    SelectDatePopupComponent,
    DocusignPopupComponent,
    JuscourtPopupComponent,
    DynamicInputDirective,
    AutocompleteDirective,
    ClickOutsideDirective,
    TagComponent,
    ScaleDirective,
    LoaderModule
  ],
})
export class SharedModule {
}
