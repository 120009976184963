// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .overlay-loader {
  z-index: 9999;
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}
:host .overlay-loader .loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #DADCDF;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
:host .overlay-loader.small .loader {
  transform: scale(0.5);
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/loader/loader.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,MAAA;EACA,OAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,6BAAA;EACA,mCAAA;EACA,sBAAA;EACA,sCAAA;AAAN;AAGM;EACE,qBAAA;AADR;AAII;EACE;IACE,uBAAA;EAFN;EAII;IACE,yBAAA;EAFN;AACF","sourcesContent":[":host {\n  .overlay-loader {\n    z-index: 9999;\n    position: absolute;\n    opacity: 1;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: 0;\n    left: 0;\n\n    .loader {\n      width: 32px;\n      height: 32px;\n      border-radius: 50%;\n      display: inline-block;\n      border-top: 3px solid #DADCDF;\n      border-right: 3px solid transparent;\n      box-sizing: border-box;\n      animation: rotation 1s linear infinite;\n    }\n    &.small {\n      .loader {\n        transform: scale(0.5);\n      }\n    }\n    @keyframes rotation {\n      0% {\n        transform: rotate(0deg);\n      }\n      100% {\n        transform: rotate(360deg);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
