import { EAuthActions } from '@app/auth/_store/actions/auth.actions';
import { contactReducers } from '@app/modules/contacts/store/reducers/contact.reducers';
import { newsReducers } from '@app/modules/layout/news/store/reducers/news.reducers';
import { notificationReducers } from '@app/modules/layout/notifications/store/reducers/notification.reducers';
import { CompanySettingReducer } from '@app/modules/settings/store/reducers/company-setting.reducers';
import { teamMemberReducers } from '@app/modules/settings/store/reducers/team-member.reducers';
import { teamReducers } from '@app/modules/settings/store/reducers/team.reducers';
import { templateReducers } from '@app/modules/settings/store/reducers/template.reducers';
import { activitiesReducers } from '@app/store/reducers/activities.reducers';
import { billReducers } from '@app/store/reducers/bill.reducers';
import { correspondenceReducers } from '@app/store/reducers/correspondence.reducers';
import { matterReducers } from '@app/store/reducers/matter.reducers';
import { paymentReducers } from '@app/store/reducers/payment.reducers';
import { taskReducers } from '@app/store/reducers/task.reducers';
import { routerReducer } from '@ngrx/router-store';
import { authReducers } from '../../auth/_store/reducers/auth.reducers';
import { documentcategoryReducers } from '../../modules/settings/store/reducers/document-category.reducers';
import { expenseTypeReducers } from '../../modules/settings/store/reducers/expense-type.reducers';
import { personTypeReducers } from '../../modules/settings/store/reducers/person-type.reducers';
import { practiceBranchReducers } from '../../modules/settings/store/reducers/practice-branch.reducers';
import { relationshipTypeReducers } from '../../modules/settings/store/reducers/relationship-type.reducers';
import { timeEntryTypeReducers } from '../../modules/settings/store/reducers/time-entry-type.reducers';
import { bankAccountReducers } from './bank-account.reducers';
import { companyPortalSettingsReducers } from './company-portal-settings.reducer';
import { ContactCardReducers } from './contact-card.reducers';
import { ContractsReducer } from './contract.reducers';
import { documentReducers } from './document.reducers';
import { MatterCardReducers } from './matter-card.reducers';
import { mediaReducers } from './media.reducers';
import { notesReducers } from './note.reducers';
import { tagReducers } from './tag.reducers';

export const appReducers = {
  router: routerReducer,
  authState: authReducers,
  bankAccountState: bankAccountReducers,
  correspondenceState: correspondenceReducers,
  matterState: matterReducers,
  activitiesState: activitiesReducers,
  templateState: templateReducers,
  matterCardState: MatterCardReducers,
  contactCardState: ContactCardReducers,
  practiceBranchState: practiceBranchReducers,
  expenseTypeState: expenseTypeReducers,
  timeEntryTypeState: timeEntryTypeReducers,
  documentCategoryState: documentcategoryReducers,
  relationshipTypeState: relationshipTypeReducers,
  personTypeState: personTypeReducers,
  documentState: documentReducers,
  companyState: CompanySettingReducer,
  mediaState: mediaReducers,
  contractState: ContractsReducer,
  noteState: notesReducers,
  billState: billReducers,
  paymentState: paymentReducers,
  teamState: teamReducers,
  contactState: contactReducers,
  taskState: taskReducers,
  teamMemberState: teamMemberReducers,
  notificationState: notificationReducers,
  newsState: newsReducers,
  companyPortalSettingsState: companyPortalSettingsReducers,
  tagState: tagReducers
};
export function clearState(reducer) {
  return (state, action) => {
    if (action.type === EAuthActions.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
