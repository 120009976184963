import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Currency } from '@app/models/currency';
import { Prices } from '@app/models/sub-tariff';
import { SubscriptionSub } from '@app/models/subscription-sub';


@Component({
  selector: 'app-juscourt-popup',
  templateUrl: './juscourt-popup.component.html',
  styleUrls: ['./juscourt-popup.component.scss']
})
export class JuscourtPopupComponent implements OnInit {

  positionProps: {top: string, left: string};
  @Input() target;
  @Input() position = 'top-right'; // left top right bottom top-right
  @Input() stateDocuSign = false; // false = створити новий true - вийти з акаунту
  @Output() btnAction = new EventEmitter<boolean>();
  @Output() infoJuscourtTracker = new EventEmitter();
  @Output() changeJuscourt = new EventEmitter();
  @Output() unSubscribeJuscourt = new EventEmitter();
  @Output() tariffPlanModal = new EventEmitter();

  @Input() activeJuscourt;
  @Input() isCheckJuscourt;
  @Input() checkSubscribedSuspended;
  @Input() subscribedSubscriptionSub;


  constructor() { }

  ngOnInit(): void {
    if (this.target) {
      const anchorProps = this.target.getBoundingClientRect();
      const clientWidth = document.body.clientWidth;
      switch (this.position) {
        case 'top-right':
          this.positionProps = {top: anchorProps.y - 110 + 'px', left: anchorProps.x + anchorProps.width + 13 + 'px'};
          break;
        case 'bottom':
          this.positionProps = {top: anchorProps.y + anchorProps.height + 9 + 'px', left: anchorProps.x + 'px'};
          break;
        default:
          this.positionProps = {top: anchorProps.y + 'px', left: anchorProps.x + 'px'};
          break;
      }
      if ((anchorProps.x + anchorProps.width + 13) > (clientWidth - 300)) {
        this.positionProps.left = anchorProps.x + anchorProps.width + 13 - 350 + 'px'
      }
    }
  }


  infoJuscourtTrackerEmit() {
    this.infoJuscourtTracker.emit();
  }


  getPercentForJuscourt() {
    if (this.activeJuscourt) {
      const date1 = new Date(this.activeJuscourt.startAt);
      const date2 = new Date(this.activeJuscourt.expiredAt);
      // кількість між датою початку і датою завершення (100%)
      const allDays = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
      // кількість між датою початку та сьогодні (скільки дні пройшло)
      const partsDays = Math.ceil(Math.abs(new Date().getTime() - date1.getTime()) / (1000 * 3600 * 24));

      const percent = (partsDays / allDays) * 100;

      return percent > 100 ? 100 : percent;
    } else {
      return 0;
    }
  }

  getCurrentTariff(activeJuscourt: SubscriptionSub): Prices {
    let currentTariff;

    if (activeJuscourt) {
      currentTariff = activeJuscourt.tariff.prices.find(
        prices => prices.currency.code === (activeJuscourt.currency as Currency).code && prices.period === activeJuscourt.period
      );
    }

    return currentTariff;
  }

  changeJuscourtEmit() {
    this.changeJuscourt.emit();
  }
  unSubscribeJuscourtEmit() {
    this.unSubscribeJuscourt.emit();
  }

  tariffPlanModalEmit() {
    this.tariffPlanModal.emit();
  }
}
