import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-event-datepicker',
  templateUrl: './event-datepicker.component.html',
  styleUrls: ['./event-datepicker.component.scss']
})
export class EventDatepickerComponent {

  constructor() { }
  @Input() form: UntypedFormGroup;
  @Input() dateTimeFormat;
  @Input() locale;
  @Input() minEndDate;
  @Input() submittedForm;

  @Output() updateDateEnd = new EventEmitter();

}
