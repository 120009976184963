import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/_services/auth.service';
import { Helpers } from '@app/helpers/helpers';
import { ResponseBodyFull } from '@app/models';
import { Contract } from '@app/models/contract';
import { MatterExtended } from '@app/models/matter-extended';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  BASE_URL = `${environment.apiUrl}/contracts`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  get(filters: any = null) {
    let params = new HttpParams();
    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<Contract>>(this.BASE_URL, { params })
        .pipe(map(response => response['hydra:member']));
    } else {
      return new Observable<Contract[]>();
    }
  }

  getContracts(page = 1, itemsPerPage = environment.defaultItemsCount, copyFilters) {
    let filters = JSON.parse(JSON.stringify(copyFilters));
    let params = new HttpParams().set('partial', 'false');
    if (page && itemsPerPage) {
      params = params.set('page', page.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<Contract>>(this.BASE_URL, { params })
        .pipe(map(response => response['hydra:member']));
    } else {
      return new Observable<Contract[]>();
    }
  }

  deleteContract(id) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  setContract(data: Contract) {
    return this.http.post<Contract>(this.BASE_URL, data);
  }

  updateContract(contract: Contract): Observable<Contract> {
    return this.http.patch<Contract>(`${this.BASE_URL}/${contract.id}`, contract);
  }
  getContract(id: number): Observable<Contract> {
    return this.http.get<Contract>(`${this.BASE_URL}/${id}`);
  }

  // перевіряє чи показувати модалку, при вибрані клієнта в створенні договору
  // приймає id вибраного клієнта
  checkMessage(id) {
    return this.http
      .get<ResponseBodyFull<Contract>>(`${environment.apiUrl}/contacts?exists[matters.contract]=false&id=${id}&matters.status=open`)
      .pipe(map(response => response['hydra:member']));
  }

  // витягуємо проекти для модалки в проекті
  getMattersList(id) {
    return this.http
      .get<ResponseBodyFull<MatterExtended>>(`${environment.apiUrl}/matters?exists[contract]=false&contact.id=${id}&status=open`)
      .pipe(map(response => response['hydra:member']));
  }
}
