import { Contract } from '@app/models/contract';
import { ActivityExtended, Contact, TaskExtended } from '.';
import { Bill } from './bill';
import { Correspondence } from './correspondence';
import { DocumentCategory } from './document-category';
import { ExpenseInternalSave } from './expense-internal-save';
import { ICommunication } from './jusnote-connect-massage';
import { MatterExtended } from './matter-extended';
import { MediaExtended } from './media-extended';
import { TagMain } from './tag';
import { UserGET } from './user-get';

export interface DocumentExtended {
  name: string;
  cloudLink?: string;
  media?: MediaExtended;
  contact?: Contact;
  contract?: Contract;
  matter?: MatterExtended;
  category?: DocumentCategory;
  activity?: ActivityExtended;
  task?: TaskExtended;
  correspondence?: Correspondence;
  expenseInternal?: ExpenseInternalSave;
  bill?: Bill;
  mandatory?: {
    date: Date;
    dateEnd?: Date;
    number?: string;
    organization?: string;
    responsibilities: UserGET[];
    contract?: {
      id: number;
    };
    contact: Contact;
    responsibleContact: Contact;
  };
  orderItem?: {
    date: Date;
    number: string;
    organization: string;
    responsible: UserGET;
    contract: {
      id: number;
    };
    contact: Contact;
    responsibleContact: Contact;
  };
  createdBy: { person: { fullName: string }; id: number };
  id: number;
  countUnreadComments?: number;
  selected?: boolean;
  communications?: ICommunication[];
  eSignature?: {
    id: number;
    service: string;
    status: string;
    url: string;
  };
  tagDocuments?: TagMain[];
}
