import { Contact, DocumentExtended } from '.';
import { BillItemGET } from './bill-item-g-e-t';
import { ExpenseType } from './expense-type';
import { MatterExtended } from './matter-extended';
import { RegCurrencyGET } from './reg-currency-get';
import { TaskExtended } from './task-extended';
import { TimeEntryType } from './time-entry-type';
import { TimerView } from './timer-view';
import { UserGET } from './user-get';

export interface ActivityExtended {
  id?: number;
  billItem?: BillItemGET;
  expense?: { id: number };
  timeEntry?: { id: number };
  approve?: string;
  amount?: string;
  expenseType?: ExpenseType;
  type?: string | ExpenseType | TimeEntryType;
  vendor?: Contact;
  date: string;
  description?: string;
  currency: RegCurrencyGET;
  currencyRate?: number;
  matter?: MatterExtended;
  responsiblePerson?: UserGET;
  user?: { id: number };
  document?: DocumentExtended;
  task?: TaskExtended;
  companyBankAccount?: {
    id: number,
    accountName: string,
    accountNumber: string,
    contact: {id: number, fullName: string}
   };
  rate?: string;
  duration?: number;
  quantity?: string;
  timeEntryType?: TimeEntryType;
  tax1Included: boolean;
  tax2Included: boolean;
  timer?: TimerView;
  nonBillable?: boolean;
  countUnreadComments?: number;
}
