// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .main .wrapper {
  width: 100%;
  min-width: 800px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
}
::ng-deep .main .wrapper .content-block {
  width: 100%;
  min-width: calc(100% - 180px);
}
::ng-deep .main.onboard .components-wrapper {
  max-height: calc(100vh - 60px - 37px);
  min-height: calc(100vh - 60px - 37px);
  height: calc(100vh - 60px - 37px);
}
::ng-deep .main.onboard .scroll-loop, ::ng-deep .main.onboard .change-table-wrapper {
  max-height: calc(100vh - 165px - 37px);
}
::ng-deep .main.onboard .discussion-sidebar {
  top: 100%;
  height: calc(100vh - 97px);
}
::ng-deep .main .app-routing-container {
  position: relative;
  min-height: calc(100vh - 127px);
}
::ng-deep .main .onboarding {
  height: 33px;
  padding: 0 20px;
  background: #F8F8F8;
  display: flex;
  align-items: center;
  letter-spacing: 0.14px;
  color: #707B86;
  width: 100%;
  font-size: 12px;
  border-bottom: 1px solid #f0f4f7;
}
::ng-deep .main .onboarding a {
  text-decoration: underline;
  color: #1252AF;
  transition: 0.2s;
  cursor: pointer;
}
::ng-deep .main .onboarding a:hover {
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAII;EACE,WAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,iBAAA;AAHN;AAIM;EACE,WAAA;EACA,6BAAA;AAFR;AAUM;EACE,qCAAA;EACA,qCAAA;EACA,iCAAA;AARR;AAUM;EACE,sCAAA;AARR;AAUM;EACE,SAAA;EACA,0BAAA;AARR;AAYI;EACE,kBAAA;EACA,+BAAA;AAVN;AAYI;EACE,YAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;EACA,gCAAA;AAVN;AAWM;EACE,0BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AATR;AAUQ;EACE,YAAA;AARV","sourcesContent":["\n::ng-deep {\n  .main {\n\n    .wrapper {\n      width: 100%;\n      min-width: 800px;\n      margin: 0 auto;\n      display: flex;\n      min-height: 100vh;\n      .content-block {\n        width: 100%;\n        min-width: calc(100% - 180px);\n      }\n    }\n    &.onboard {\n      .wrapper {\n        // max-height: calc(100vh - 37px);\n        // min-height: calc(100vh - 37px);\n      }\n      .components-wrapper {\n        max-height: calc(100vh - 60px - 37px);\n        min-height: calc(100vh - 60px - 37px);\n        height: calc(100vh - 60px - 37px);\n      }\n      .scroll-loop, .change-table-wrapper {\n        max-height: calc(100vh - 165px - 37px);\n      }\n      .discussion-sidebar {\n        top: calc(100% );\n        height: calc(100vh - 97px);\n      }\n    }\n\n    .app-routing-container {\n      position: relative;\n      min-height: calc(100vh - 127px);\n    }\n    .onboarding {\n      height: 33px;\n      padding: 0 20px;\n      background: #F8F8F8;\n      display: flex;\n      align-items: center;\n      letter-spacing: 0.14px;\n      color: #707B86;\n      width: 100%;\n      font-size: 12px;\n      border-bottom: 1px solid #f0f4f7;\n      a {\n        text-decoration: underline;\n        color: #1252AF;\n        transition: 0.2s;\n        cursor: pointer;\n        &:hover {\n          opacity: 0.6;\n        }\n      }\n\n    }\n  }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
