import { initialTeamState, ITeamState } from '@app/modules/settings/store/state/team.state';
import { ETeamActions, TeamActions } from '@app/modules/settings/store/actions/team.actions';

export const teamReducers = (state = initialTeamState, action: TeamActions): ITeamState => {
  switch (action.type) {
    case ETeamActions.GET_WORK_GROUPS_SUCCESS: {
      return {
        ...state,
        allWorkGroups: action.payload.member
      };
    }

    case ETeamActions.GET_WORK_GROUPS_WITH_FILTERS_SUCCESS: {
      return {
        ...state,
        workGroups: action.payload.member
      };
    }

    case ETeamActions.GET_MY_AND_ANYONE_WORK_GROUPS_SUCCESS: {
      return {
        ...state,
        myAndAnyoneWorkGroups: action.payload.member
      };
    }

    case ETeamActions.GET_ANYONE_WORK_GROUPS_SUCCESS: {
      return {
        ...state,
        anyoneWorkGroups: action.payload.member
      };
    }

    case ETeamActions.GET_ANYONE_WITHOUT_SYSTEM_WORK_GROUPS_SUCCESS: {
      return {
        ...state,
        anyoneWithoutSystemWorkGroups: action.payload.member
      };
    }

    case ETeamActions.REMOVE_TEAM: {
      // фільтруємо масив, той який є сторі,
      // тобто всі елементи, окрім того що ми тільки що видалили
      state.allWorkGroups = state.allWorkGroups ? state.allWorkGroups.filter(group => +group.id !== +action.payload) : null;
      state.anyoneWithoutSystemWorkGroups = state.anyoneWithoutSystemWorkGroups ? state.anyoneWithoutSystemWorkGroups.filter(group => +group.id !== +action.payload) : null;
      state.anyoneWorkGroups = state.anyoneWorkGroups ? state.anyoneWorkGroups.filter(group => +group.id !== +action.payload) : null;
      state.myAndAnyoneWorkGroups = state.myAndAnyoneWorkGroups ? state.myAndAnyoneWorkGroups.filter(group => +group.id !== +action.payload) : null;
      state.workGroups = state.workGroups ? state.workGroups.filter(group => +group.id !== +action.payload) : null;
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
