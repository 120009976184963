import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BILL_APPROVE_EDIT, BILL_TRANSFER_REGISTER } from '@app/helpers/role-arrays';
import { Bill } from '@app/models/bill';
import { BillStatus } from '@app/enums/bills/bill-status';
import { BillType } from '@app/enums/bills/bill-type';
import { ETeamMemberRoles } from '@app/enums/team-member-roles';
import { IUser, TaskExtended } from '@app/models';
import { ITrash } from '@app/models/interfaces/trash-interface';
import { DocumentService } from '../../../modules/reusable/documents/services/document.service';
import { Router } from '@angular/router';
import { checkRole } from '@app/helpers/check-role';

@Component({
  selector: 'app-row-menu',
  templateUrl: './row-menu.component.html',
  styleUrls: ['./row-menu.component.scss'],
})
export class RowMenuComponent {
  @Output() deleteItem = new EventEmitter<any>();
  @Output() editItem = new EventEmitter<any>();
  @Output() pinItem = new EventEmitter<any>();
  @Output() duplicateItem = new EventEmitter<TaskExtended>();
  @Output() viewItem = new EventEmitter<any>();
  @Output() addSubItem = new EventEmitter<any>();
  @Output() approve = new EventEmitter<Bill>();
  @Output() paymentRegistrationModal = new EventEmitter<Bill>();
  @Output() completeTask = new EventEmitter<TaskExtended>();
  @Output() restoreItem = new EventEmitter<ITrash>();
  @Output() permissionModal = new EventEmitter<IUser>();
  @Output() permissionDeactivate = new EventEmitter<number>();
  @Output() permissionActivate = new EventEmitter<number>();
  @Output() permissionReSendMail = new EventEmitter<number>();
  @Output() openSpinner = new EventEmitter();
  @Output() hideSpinner = new EventEmitter();

  @Input() entity = '';
  @Input() hidden = true;

  @Input() currentUser: IUser;
  public billModifyRoles = BILL_APPROVE_EDIT;
  billsAllowedRoles = BILL_TRANSFER_REGISTER;
  @Input() item;
  readonly adminRole = ETeamMemberRoles.admin;
  readonly subscriptionRole = ETeamMemberRoles.subscription;

  constructor(
    private reminderService: DocumentService,
    private router: Router) {
  }

  onViewItem(item) {
    this.viewItem.emit(item);
  }

  onAddSubItem(item) {
    this.addSubItem.emit(item);
  }

  onApprove(item) {
    this.approve.emit(item);
  }

  onPaymentRegistrationModal(item) {
    this.paymentRegistrationModal.emit(item);
  }

  onEditItem(item) {
    this.editItem.emit(item);
  }

  onDuplicateItem(item) {
    this.duplicateItem.emit(item);
  }

  onPinItem(item) {
    this.pinItem.emit(item);
  }

  onDeleteItem(item) {
    this.deleteItem.emit(item);
  }

  onRestoreItem(item) {
    this.restoreItem.emit(item);
  }

  markAsComplete(task) {
    this.completeTask.emit(task);
  }

  onPermissionModal(item: IUser) {
    this.permissionModal.emit(item);
  }

  isActive(user: IUser): boolean {
    return user.companyAccesses[0].enable;
  }

  onPermissionActivate(id) {
    this.permissionActivate.emit(id);
  }

  onPermissionDeactivate(id) {
    this.permissionDeactivate.emit(id);
  }

  onPermissionReSendMail(id) {
    this.permissionReSendMail.emit(id);
  }

  hiddenEditBtn() {
    if (this.checkActivityEntity() && this.item.billItem) {
      return true;
    }
    if (this.checkActivityEntity() && (this.item.responsiblePerson.id !== this.currentUser.id && !checkRole(['ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ADMIN']))) {
      return true;
    }

    if (
      (this.entity === 'transaction' && this.item.expense && this.item.expense.billItem) ||
      (this.entity === 'transaction' && this.item.type === 'out' && this.item.expense && this.item.expense.billItem)
    ) {
      return true;
    }
    if (this.entity === 'trash') {
      return true;
    }
    return false;
  }

  hiddenDeleteBtn() {
    if (this.item?.createdBy?.id === this.currentUser?.id) {
      return false;
    }
    if (this.checkActivityEntity() && this.item.billItem) {
      return true;
    }
    if (this.entity === 'activity' && this.item.expense && this.item.expense.billItem) {
      return true;
    }

    if (this.checkActivityEntity() && (this.item.responsiblePerson.id !== this.currentUser.id && !checkRole(['ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ADMIN']))) {
      return true;
    }
    if (checkRole(['ROLE_SITE_ACCOUNTS_MANAGER']) && (this.entity.includes('billPayments') || this.entity.includes('transaction') || this.entity.includes('bankAccounts'))) {
      return false;
    }

    if (this.entity.includes('correspondence') && (this.item?.createdBy?.id === this.currentUser?.id)) {
      return false;
    }

    if (!checkRole(['ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ADMIN']) && !this.checkActivityEntity()) {
      return true;
    }

    if (this.entity === 'transaction' && this.item.type === 'out' && this.item.expense && this.item.expense.billItem) {
      return true;
    }
    if (this.entity === 'team-list' || this.entity === 'tax-list') {
      return true;
    }
    if (this.entity.includes('search')) {
      return true;
    }

    return false;
  }

  checkActivityEntity() {
    return (
      this.entity === 'activity' ||
      this.entity === 'matter-activities' ||
      this.entity === 'worker-activities' ||
      this.entity === 'contact-activities'
    );
  }

  hiddenViewBtn() {
    if (
      (this.entity === 'transaction' && this.item.payment && !this.item.payment.bill) ||
      (this.entity === 'transaction' && this.item.type === 'in')
    ) {
      return true;
    }
    if (
      this.entity === 'trash' ||
      this.entity === 'tax-list' ||
      this.entity === 'library-list' ||
      this.entity === 'library-tree-view' ||
      this.entity === 'matter' ||
      this.entity === 'task'
    ) {
      return true;
    }
    if (this.item?.transfer) {
      return true;
    }
    return false;
  }

  // bill
  isDraft(bill: Bill) {
    return bill.status === BillStatus.draft;
  }

  checkBillEntity() {
    return this.entity.includes('bill');
  }

  isPrepay(bill: Bill) {
    return bill.typePay === BillType.prepaid;
  }

  onDocuSign(item) {
    if (!item?.eSignature) {
      this.openSpinner.emit();
      this.reminderService.getDocuSignUrl(item.id).subscribe((resp: any) => {
        window.open(resp.data.url, '_blank');
        this.hideSpinner.emit();
      });
    } else {
      this.router.navigate([`/documents/card/${item.id}`]);
    }
  }

  showDocuSignBtn() {
    return this.entity === 'document' && this.currentUser?.settings && this.currentUser?.settings[0]?.docusignAccount?.enabled;
  }

  checkPin(item) {
    return item?.isPinned ? 'matterUnpin' : 'matterPin';
  }

  getWidthForWrapper(wrapper) {
    return wrapper.children.length;
  }
}
