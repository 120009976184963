import {
  ContactAddress,
  ContactBankDetail,
  ContactCompany,
  ContactDocument,
  ContactEmail,
  ContactMessenger,
  ContactPerson,
  ContactPhone,
  ContactWebsite,
  LegalDocuments,
  RegistrationActions,
  TypeOfPerson,
} from '@app/modules/contacts/symbols/contacts.symbols';
import {
  ContactAddressesControls,
  ContactBankDetailsControls,
  ContactCompanyControls,
  ContactCompanyWorkGroupControls,
  ContactDocumentsControls,
  ContactEmailsControls,
  ContactInnControls,
  ContactLegalDocumentsControls,
  ContactMediaControls,
  ContactMessengersControls,
  ContactPersonControls,
  ContactPersonTypeControls,
  ContactPhonesControls,
  ContactRegistrationActionsControls,
  ContactResponsiblePersonControls,
  ContactTagContactsControls,
  ContactWebsitesControls,
  EmailForPortalControls, TogglePortalOnOffControls,
} from '@app/modules/contacts/symbols/contacts-blocks';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { PersonGET } from '@app/models/person-get';
import { TagMain } from '@app/models/tag';

export enum ContactFormBlock {
  Media = 'media',
  Inn = 'inn',
  ContactCompany = 'contactCompany',
  ContactPerson = 'contactPerson',
  Emails = 'emails',
  Phones = 'phones',
  Websites = 'websites',
  Messengers = 'messengers',
  Addresses = 'addresses',
  BankDetails = 'bankDetails',
  Documents = 'documents',
  ResponsiblePerson = 'responsiblePerson',
  CompanyWorkGroup = 'companyWorkGroup',
  PersonType = 'personType',
  RegistrationActions = 'registrationActions',
  LegalDocuments = 'legalDocuments',
  TagContacts = 'tagContacts',
  EmailForPortal = 'emailForPortal',
  TogglePortalOnOff = 'togglePortalOnOff',
}

export interface ContactAllBlockControls {
  media: any;
  inn: string;
  contactCompany: ContactCompany;
  contactPerson: ContactPerson;
  emails: ContactEmail;
  phones: ContactPhone;
  websites: ContactWebsite;
  messengers: ContactMessenger;
  addresses: ContactAddress;
  bankDetails: ContactBankDetail;
  documents: ContactDocument;
  responsiblePerson: any;
  companyWorkGroup: any;
  personType: number;
  registrationActions: RegistrationActions;
  legalDocuments: LegalDocuments;
  tagContacts: TagMain;
  emailForPortal: string;
  togglePortalOnOff: boolean;
}


export interface ContactFormControls {
  [ContactFormBlock.Media]: FormControl<ContactAllBlockControls['media']>;
  [ContactFormBlock.Inn]: FormControl<ContactAllBlockControls['inn']>;
  [ContactFormBlock.ContactCompany]: FormGroup<TransformToTypedForm<ContactAllBlockControls['contactCompany']>>;
  [ContactFormBlock.ContactPerson]: FormGroup<TransformToTypedForm<ContactAllBlockControls['contactPerson']>>;
  [ContactFormBlock.Emails]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['emails']>>>;
  [ContactFormBlock.Phones]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['phones']>>>;
  [ContactFormBlock.Websites]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['websites']>>>;
  [ContactFormBlock.Messengers]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['messengers']>>>;
  [ContactFormBlock.Addresses]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['addresses']>>>;
  [ContactFormBlock.BankDetails]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['bankDetails']>>>;
  [ContactFormBlock.Documents]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['documents']>>>;
  [ContactFormBlock.ResponsiblePerson]: FormControl<ContactAllBlockControls['responsiblePerson']>;
  [ContactFormBlock.CompanyWorkGroup]: FormControl<ContactAllBlockControls['companyWorkGroup']>;
  [ContactFormBlock.PersonType]: FormControl<ContactAllBlockControls['personType']>;
  [ContactFormBlock.RegistrationActions]: FormGroup<TransformToTypedForm<ContactAllBlockControls['registrationActions']>>;
  [ContactFormBlock.LegalDocuments]: FormGroup<TransformToTypedForm<ContactAllBlockControls['legalDocuments']>>;
  [ContactFormBlock.TagContacts]: FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['tagContacts']>>>;
  [ContactFormBlock.EmailForPortal]: FormControl<ContactAllBlockControls['emailForPortal']>;
  [ContactFormBlock.TogglePortalOnOff]: FormControl<ContactAllBlockControls['togglePortalOnOff']>;
}

export interface ContactControlRelation {
  [ContactFormBlock.Media]: ContactMediaControls;
  [ContactFormBlock.Inn]: ContactInnControls;
  [ContactFormBlock.ContactCompany]: ContactCompanyControls;
  [ContactFormBlock.ContactPerson]: ContactPersonControls;
  [ContactFormBlock.Emails]: ContactEmailsControls;
  [ContactFormBlock.Phones]: ContactPhonesControls;
  [ContactFormBlock.Websites]: ContactWebsitesControls;
  [ContactFormBlock.Messengers]: ContactMessengersControls;
  [ContactFormBlock.Addresses]: ContactAddressesControls;
  [ContactFormBlock.BankDetails]: ContactBankDetailsControls;
  [ContactFormBlock.Documents]: ContactDocumentsControls;
  [ContactFormBlock.ResponsiblePerson]: ContactResponsiblePersonControls;
  [ContactFormBlock.CompanyWorkGroup]: ContactCompanyWorkGroupControls;
  [ContactFormBlock.PersonType]: ContactPersonTypeControls;
  [ContactFormBlock.RegistrationActions]: ContactRegistrationActionsControls;
  [ContactFormBlock.LegalDocuments]: ContactLegalDocumentsControls;
  [ContactFormBlock.TagContacts]: ContactTagContactsControls;
  [ContactFormBlock.EmailForPortal]: EmailForPortalControls;
  [ContactFormBlock.TogglePortalOnOff]: TogglePortalOnOffControls;
}

export type TransformToTypedForm<T> = {
  [K in keyof T]: AbstractControl<T[K]>;
};
export type ContactsConfigs = {
  [key in TypeOfPerson]: ContactFormBlock[];
};


/**
 * Describes fields for each contact.
 */
export const CONTACT_FORM_CONFIGS: ContactsConfigs = {
  [TypeOfPerson.INDIVIDUAL]: [
    ContactFormBlock.Emails,
    ContactFormBlock.Phones,
    ContactFormBlock.Websites,
    ContactFormBlock.Messengers,
    ContactFormBlock.ContactPerson,
    ContactFormBlock.PersonType,
    ContactFormBlock.Inn,
    ContactFormBlock.LegalDocuments,
    ContactFormBlock.Documents,
    ContactFormBlock.Addresses,
    ContactFormBlock.RegistrationActions,
    ContactFormBlock.CompanyWorkGroup,
    ContactFormBlock.ResponsiblePerson,
    ContactFormBlock.BankDetails,
    ContactFormBlock.TagContacts,
    ContactFormBlock.EmailForPortal,
    ContactFormBlock.TogglePortalOnOff,
  ],
  [TypeOfPerson.LEGAL_ENTITY]: [
    ContactFormBlock.Media,
    ContactFormBlock.Inn,
    ContactFormBlock.ContactCompany,
    ContactFormBlock.PersonType,
    ContactFormBlock.Emails,
    ContactFormBlock.Phones,
    ContactFormBlock.Websites,
    ContactFormBlock.Messengers,
    ContactFormBlock.Addresses,
    ContactFormBlock.BankDetails,
    ContactFormBlock.Documents,
    ContactFormBlock.ResponsiblePerson,
    ContactFormBlock.CompanyWorkGroup,
    ContactFormBlock.RegistrationActions,
    ContactFormBlock.TagContacts,
  ],
};


