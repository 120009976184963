import { JwtHelperService } from '@auth0/angular-jwt';
import { Payload } from '@app/models';

export function checkTariff(tariffs: number | number[]): boolean {
  /**
   * It will be more comfortable to work with an array
   */
  const requiredPlans = Array.isArray(tariffs) ? tariffs : [tariffs];
  if (!requiredPlans.length) {
    requiredPlans.push(3);
  }

  const jwt = new JwtHelperService();

  /**
   * Check JWT for existence in localstorage
   */
  const JWT = localStorage.getItem('JWT_TOKEN');
  if (!JWT) {
    return false;
  }
  /**
   * According to AuthService.getPayload
   * But we cannot inject it here
   */
  const userPlan = jwt.decodeToken(localStorage.getItem('JWT_TOKEN')) as Payload;

  /**
   * @link https://stackoverflow.com/a/39893636
   */
  return requiredPlans.some(plan => userPlan.tariffId === plan || userPlan.tariff === plan);
}
