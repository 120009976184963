import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseBodyFull } from '@app/models';
import { map } from 'rxjs/operators';
import { PracticeBranch } from '@app/models/practice-branch';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { Observable } from 'rxjs';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class PracticeBranchService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL = `${environment.apiUrl}/practice_branches`;
  get() {
    const queryString = `${environment.apiUrl}/practice_branches`;
    if (this.authService.currentUserValue) {
      return this.http.get<Array<PracticeBranch>>(queryString);
    } else {
      return new Observable<Array<PracticeBranch>>();
    }
  }

  getPracticeBranches(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters?): Observable<Paginator<PracticeBranch>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }

    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<PracticeBranch>>(this.BASE_URL, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<PracticeBranch>>();
    }
  }

  addPracticeBranch(practiceBranch: PracticeBranch) {
    const queryString = `${environment.apiUrl}/practice_branches`;
    return this.http.post<PracticeBranch>(queryString, practiceBranch);
  }

  updatePracticeBranch(practiceBranch: PracticeBranch) {
    const queryString = `${environment.apiUrl}/practice_branches/${practiceBranch.id}`;
    return this.http.patch<PracticeBranch>(queryString, practiceBranch);
  }

  deletePracticeBranch(id: number) {
    const queryString = `${environment.apiUrl}/practice_branches/${id}`;
    return this.http.delete(queryString);
  }
}
