// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container .timer-container .time-item {
  flex: 1;
  text-align: center;
  padding-left: 14px;
}
.input-container input.time-input:disabled {
  -webkit-text-fill-color: #A6ABB4;
  background: #F8F9FA;
  color: #A6ABB4;
  border-color: #f4f4f4;
}
.input-container input.time-input:disabled:hover {
  cursor: not-allowed;
  box-shadow: none !important;
}
.input-container label.description-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-container label.description-label span {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #858B98;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/wide-modals/time-modal/time-modal.component.scss"],"names":[],"mappings":"AAEI;EACE,OAAA;EACA,kBAAA;EACA,kBAAA;AADN;AAKE;EACE,gCAAA;EACA,mBAAA;EACA,cAAA;EACA,qBAAA;AAHJ;AAKI;EACE,mBAAA;EACA,2BAAA;AAHN;AAOE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AALJ;AAOI;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AALN","sourcesContent":[".input-container {\n  .timer-container {\n    .time-item {\n      flex: 1;\n      text-align: center;\n      padding-left: 14px;\n    }\n  }\n\n  input.time-input:disabled {\n    -webkit-text-fill-color: #A6ABB4;\n    background: #F8F9FA;\n    color: #A6ABB4;\n    border-color: #f4f4f4;\n\n    &:hover {\n      cursor: not-allowed;\n      box-shadow: none !important;\n    }\n  }\n\n  label.description-label {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    span {\n      display: flex;\n      align-items: center;\n      font-size: 13px;\n      font-weight: 400;\n      color: #858B98;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
