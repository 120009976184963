import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { TranslateService } from '@ngx-translate/core';
import { SwitchNotificationsService } from '@app/helpers';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';

@Component({
  selector: 'app-kanban-item',
  templateUrl: './kanban-item.component.html',
  styleUrls: ['./kanban-item.component.scss'],
})
export class KanbanItemComponent {
  @Input() kanbanData;
  @Input() item;
  @Output() tooltipDisplay = new EventEmitter();
  @Output() memberDisplay = new EventEmitter();
  @Output() menuDisplay = new EventEmitter();
  spinnerName = 'kanbanList';
  showLoader = false;

  constructor(
    private router: Router,
    private contactService: ContactService,
    private translateService: TranslateService,
    private switchNotificService: SwitchNotificationsService,
    private store: Store<IAppState>,
  ) {
  }

  emitTooltip(event) {
    this.tooltipDisplay.emit({
      event,
      text: this.kanbanData.type === 'task' ? this.translateService.instant('task') : this.translateService.instant('event'),
    });
  }

  emitMember(event) {
    this.memberDisplay.emit({
      event,
      members: this.kanbanData.taskUsers,
    });
  }

  activeMenu(event) {
    this.menuDisplay.emit({
      event,
      item: this.kanbanData,
    });
  }

  getShortName(name) {
    // return name.match(/\b(\w)/g).join('');
    const names = name.split(' ');
    return names[0][0] + names[1][0];
  }

  goToContact(contact) {
    if (contact) {
      this.openSpinner();
      this.contactService.getById(contact.id).subscribe(res => {
        this.hideSpinner();
        if (res.id) {
          this.router.navigateByUrl(`/contact/${contact.id}`);
        }
      });
    }
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  viewTaskWithChat(task) {
    this.router.navigateByUrl(`/tasks/card/${task.id}`);
    this.switchNotificService.switchNotifications('disable');
  }
}
