import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Rate } from '@app/models/rate';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRatesService {
  private BASE_URL = `${environment.apiUrl}/exchange_rates`;

  constructor(private http: HttpClient) {
  }

  getRate(to, from, date?): Observable<Rate> {
    this.BASE_URL = `${environment.apiUrl}/exchange_rates/${to}?from=${from}`;
    if (date) {
      this.BASE_URL = `${environment.apiUrl}/exchange_rates/${to}?from=${from}&date=${date}`;
    }
    return this.http.get<Rate>(this.BASE_URL);
  }

  getHasPrePay(): Observable<{ hasPrePay: boolean }> {
    return this.http.get<{ hasPrePay: boolean }>(`${environment.apiUrl}/has-prepay`);
  }
}
