import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fadeAnimation } from './helpers';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';

import { IUser } from './models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginModalComponent } from '@app/commonComponents/modals/system-modals/login-modal/login-modal.component';
import { environment } from '@environments/environment';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import { WebsocketService } from '@app/websocket/websocket.service';
import { Subscription } from 'rxjs';
import { LocaleService } from '@app/helpers/locale.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { AuthService } from './auth/_services/auth.service';
import { Store } from '@ngrx/store';
import { IAppState } from './store/state/app.state';
import { Logout } from './auth/_store/actions/auth.actions';
import { RoutesService } from './helpers/routes.service';
import { checkRole } from './helpers/check-role';
import { AuthEffects } from './auth/_store/effects/auth.effects';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { GetTimeEntryTypes } from '@app/modules/settings/store/actions/time-entry-types.actions';

interface IIp {
  ip: string;
}

loadCldr(
  require('cldr-data/main/uk/numbers.json'),
  require('cldr-data/main/uk/ca-gregorian.json'),
  require('cldr-data/main/uk/timeZoneNames.json'),

  require('cldr-data/main/pl/numbers.json'),
  require('cldr-data/main/pl/ca-gregorian.json'),
  require('cldr-data/main/pl/timeZoneNames.json'),

  require('cldr-data/main/de/numbers.json'),
  require('cldr-data/main/de/ca-gregorian.json'),
  require('cldr-data/main/de/timeZoneNames.json'),

  require('cldr-data/main/es/numbers.json'),
  require('cldr-data/main/es/ca-gregorian.json'),
  require('cldr-data/main/es/timeZoneNames.json'),

  require('cldr-data/main/cs/numbers.json'),
  require('cldr-data/main/cs/ca-gregorian.json'),
  require('cldr-data/main/cs/timeZoneNames.json'),

  require('cldr-data/main/fr/numbers.json'),
  require('cldr-data/main/fr/ca-gregorian.json'),
  require('cldr-data/main/fr/timeZoneNames.json'),

  require('cldr-data/supplemental/numberingSystems.json'),

  require('../assets/l10n/weekData.json'), // Manually added UK first day of week
);
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'jusnote2';
  currentUser: IUser;
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  timedOut = false;
  subscription: Subscription;
  ifNowShowModal = false;
  lang: string;
  locale: string;
  userIp: string;
  onboardOpened = false;
  private interval;

  previousUrl: string = null;
  currentUrl: string = null;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wsService: WebsocketService,
    private localeService: LocaleService,
    private appSettingsService: AppSettingsService,
    private authEffect: AuthEffects,
    private routesService: RoutesService,
  ) {
    this.subscription = router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        browserRefresh = router.navigated;
      }
    });
    if (this.authService.currentUserValue) {
      this.authService.refreshToken().subscribe(token => {
        if ('success' in token && 'errors' in token) {
          this.store.dispatch(new Logout());
          window.location.href = `${window.location.origin}/auth/login`;
          // this.router.navigateByUrl('/auth/login');
        }
      });
    }
    authEffect.logout$.subscribe(() => {
      this.onboardOpened = false;
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.store.dispatch(
        new GetTimeEntryTypes({
          page: 1,
          itemsPerPage: 10000,
          filters: { name: '', 'exists[parent]': 'false' },
        }),
      );
      this.store.dispatch(new LoadCompanySettingAction());
    }
    if (!this.authService.isLoggedIn() && !this.ifNowShowModal) {
      this.authService.logout();
    }

    this.authService.currentUserSubject.subscribe(payload => {
      if (payload?.tariffId === 1 && checkRole(['ROLE_SITE_SUBSCRIPTIONS_MANAGER'])) {
        this.onboardOpened = true;
      }
    });

    this.appSettingsService.getLang.subscribe(lang => {
      this.translate.use(lang);
      setCulture(lang);
    });
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    if (!this.authService.isLoggedIn()) {
      this.getUserLocale().subscribe(locale => {
        this.appSettingsService.storeSettings({
          lang: locale,
          showSidebar: true,
        });
        this.translate.use(locale);
        setCulture(locale);
      });
    }

    this.saveAndCheckActiveUser();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });
    this.connectWS();
    this.handleDatepickerTodayButton();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.routesService.setPreviousUrl(this.previousUrl);
    });
    sessionStorage.removeItem('liqPayData');
  }

  connectWS() {
    this.wsService.centrifugo.on('connect', context => {
    });
    this.wsService.centrifugo.on('message', context => {
    });
  }

  getUserLocale() {
    return this.localeService.getUserIp().pipe(
      tap((res: IIp) => localStorage.setItem('userIp', res.ip)),
      switchMap((res: IIp) => {
        return this.localeService.getLocaleByIp(res.ip);
      }),
      map(data => {
        this.localeService.passLocale(data);
        switch (data.countryCode) {
          case 'UKR':
            return 'uk';
          default:
            return 'en';
        }
      }),
    );
  }

  ngOnDestroy() {
    this.authService.logout();
    this.wsService.centrifugo.on('disconnect', context => {
    });
  }

  // відслідковування всього руху мишки і клацання
  saveAndCheckActiveUser(event?) {
    clearInterval(this.interval);

    if (this.authService.isLoggedIn()) {
      this.interval = setInterval(() => {
        const date = new Date(+localStorage.getItem('lastCurrentActive'));
        date.setSeconds(date.getSeconds() + environment.idleTime);
        if (date <= new Date()) {
          this.authService.setShowRefreshModal();
          this.authService.refreshModalStatus.subscribe(data => {
            if (!this.ifNowShowModal && data === true && this.authService.isLoggedIn()) {
              this.viewLoginModal();
            }
          });
        }
      }, 1000);
      setTimeout(() => {
        if (!this.ifNowShowModal) {
          localStorage.setItem('lastCurrentActive', Date.now() + '');
        }
      }, 1500);
    }
  }

  viewLoginModal() {
    this.ifNowShowModal = true;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.backdropClass = 'mat-background-blur';
    clearInterval(this.interval);

    this.dialog
      .open(LoginModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(notRedirect => {
        this.ifNowShowModal = false;
        // зробив коли після закриття модалки на бекграунд, переводимо на /auth/login
        if (notRedirect && notRedirect.status !== true) {
          if (notRedirect.link !== false) {
            this.store.dispatch(new Logout());
            this.router.navigate([notRedirect.link]);
            return;
          }
        }
        if (!notRedirect) {
          this.dialog.closeAll();

          this.store.dispatch(new Logout());
          this.router.navigate(['/auth/login']);
        }
      });
  }

  private handleDatepickerTodayButton() {
    L10n.load({
      uk: {
        datepicker: {
          today: 'Сьогодні',
        },
      },
      ru: {
        datepicker: {
          today: 'Сегодня',
        },
      },
    });
  }
}
