import { Paginator } from '@app/models/paginator';

export interface ResponseBody<T> {
  success: boolean;
  data?: T;
  errors?: { code: string; message: string }[];
}

export interface ResponseBodyFull<T> {
  // success?: boolean;
  // data?: Paginator<T>;
  // errors?: { code: string; message: string }[];
  // @context: "/contexts/Matter"
  // @id: "/matters"
  // @type: "hydra:Collection"
  'hydra:member': T[];
  pagination: {
    countItems: number;
    totalItems: number;
    currentPage: number;
    itemsPerPage: number;
    lastPage: number;
  };
}

export interface Response<T> {
  data?: Paginator<T>;
  success?: boolean;
  errors?: { code: string; message: string };
}


export interface ResponseBodyAuth {
  token?: string;
  refresh?: string;
  errors?: { code: string; message: string }[];
  success?: boolean;
  '2fa'?: boolean;
  usid?: string;
  id?: number;
}
