import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, share, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
  DeleteCompanyPortalSettings,
  DeleteCompanyPortalSettingsFailure,
  DeleteCompanyPortalSettingsSuccess,
  ECompanyPortalSettingsActions,
  GetCompanyPortalSetting,
  GetCompanyPortalSettingFailure,
  GetCompanyPortalSettings,
  GetCompanyPortalSettingsFailure,
  GetCompanyPortalSettingsSuccess,
  GetCompanyPortalSettingSuccess,
  PostCompanyPortalSettings,
  PostCompanyPortalSettingsFailure,
  PostCompanyPortalSettingsSuccess,
  PostCompanyUserPortalAccess,
  PostCompanyUserPortalAccessFailure,
  PostCompanyUserPortalAccessSuccess,
  PutCompanyPortalSettings,
  PutCompanyPortalSettingsFailure,
  PutCompanyPortalSettingsSuccess,
  PutCompanyUserPortalAccesses,
  PutCompanyUserPortalAccessesFailure,
  PutCompanyUserPortalAccessesSuccess,
} from '../actions/company-portal-settings.action';
import { CompanyPortalSettingsService } from '@app/modules/settings/services/company-portal-settings.service';
import { Store } from '@ngrx/store';
import { HelpersServices } from '@app/helpers/helpers.services';

@Injectable()
export class CompanyPortalSettingsEffects {

  putCompanyUserPortalAccesses$ = createEffect(() => this.actions$.pipe(
    ofType<PutCompanyUserPortalAccesses>(ECompanyPortalSettingsActions.PUT_COMPANY_USER_PORTAL_ACCESS),
    switchMap(action => {
      return this.companyPortalSettingsService.putCompanyUserPortalAccesses(action.payload.id, action.payload.enable).pipe(
        mergeMap(resp => {
          return [new PutCompanyUserPortalAccessesSuccess(resp)];
        }),
        catchError(error => of(new PutCompanyUserPortalAccessesFailure(error))),
      );
    }),
    share(),
  ), {dispatch: false});


  postCompanyUserPortalAccesses$ = createEffect(() => this.actions$.pipe(
    ofType<PostCompanyUserPortalAccess>(ECompanyPortalSettingsActions.POST_COMPANY_USER_PORTAL_ACCESS),
    switchMap(action => {
      return this.companyPortalSettingsService.postCompanyUserPortalAccesses(action.payload).pipe(
        mergeMap(resp => {
          return [new PostCompanyUserPortalAccessSuccess(resp)];
        }),
        catchError(error => of(new PostCompanyUserPortalAccessFailure(error))),
      );
    }),
    share(),
  ), {dispatch: false});


  getCompanyPortalSettings$ = createEffect(() => this.actions$.pipe(
    ofType<GetCompanyPortalSettings>(ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTINGS),
    switchMap(action => {
      return this.companyPortalSettingsService.get(action.payload).pipe(
        mergeMap(resp => {
          return [new GetCompanyPortalSettingsSuccess(resp)];
        }),
        catchError(error => of(new GetCompanyPortalSettingsFailure(error))),
      );
    }),
    share(),
  ));


  getCompanyPortalSetting$ = createEffect(() => this.actions$.pipe(
    ofType<GetCompanyPortalSetting>(ECompanyPortalSettingsActions.GET_COMPANY_PORTAL_SETTING),
    switchMap(action => {
      return this.companyPortalSettingsService.getById(action.payload).pipe(
        mergeMap(resp => {
          return [new GetCompanyPortalSettingSuccess(resp)];
        }),
        catchError(error => of(new GetCompanyPortalSettingFailure(error))),
      );
    }),
  ));


  postCompanyPortalSettings$ = createEffect(() => this.actions$.pipe(
    ofType<PostCompanyPortalSettings>(ECompanyPortalSettingsActions.POST_COMPANY_PORTAL_SETTINGS),
    switchMap(action => {
      return this.companyPortalSettingsService.post(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('companyPortalSettingsAdded'));
            return of(new PostCompanyPortalSettingsSuccess(x));
          }
          return of(new PostCompanyPortalSettingsFailure(new Error()));
        }),
        catchError(error => of(new PostCompanyPortalSettingsFailure(error))),
      );
    }),
    share(),
  ));


  putCompanyPortalSettings$ = createEffect(() => this.actions$.pipe(
    ofType<PutCompanyPortalSettings>(ECompanyPortalSettingsActions.PUT_COMPANY_PORTAL_SETTINGS),
    switchMap(action => {
      const currentEvent = action.payload.event;
      delete action.payload.event;
      return this.companyPortalSettingsService.put(action.payload).pipe(
        mergeMap(x => {
          if (x?.['@id']) {
            this.helpersServices.visibleTroast(currentEvent);
            return of(new PutCompanyPortalSettingsSuccess(x));
          }
          if (!x?.['@id']) {
            this.toastrService.error(x['error']?.errors[0]?.message);
          }
          if (!x?.['@id']) {
            this.toastrService.error(x['hydra:description']);
          }
          return of(new PutCompanyPortalSettingsFailure(new Error()));
        }),
        catchError(error => of(new PutCompanyPortalSettingsFailure(error))),
      );
    }),
    share(),
  ));


  puCompanyPortalSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<PutCompanyPortalSettingsSuccess>(ECompanyPortalSettingsActions.PUT_COMPANY_PORTAL_SETTINGS_SUCCESS),
    switchMap(() => {
      this.store.dispatch(new GetCompanyPortalSettings());
      return of(new PostCompanyPortalSettingsSuccess(null));
    }),
  ), { dispatch: false });


  deleteCompanyPortalSettings$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteCompanyPortalSettings>(ECompanyPortalSettingsActions.DELETE_COMPANY_PORTAL_SETTINGS),
    switchMap(action => {
      return this.companyPortalSettingsService.delete(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('companyPortalSettingsDeleted'));
          return of(new DeleteCompanyPortalSettingsSuccess(action.payload));
        }),
        catchError(error => of(new DeleteCompanyPortalSettingsFailure(error))),
      );
    }),
    share(),
  ));


  deleteCompanyPortalSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteCompanyPortalSettingsSuccess>(ECompanyPortalSettingsActions.DELETE_COMPANY_PORTAL_SETTINGS_SUCCESS),
    switchMap(() => {
      this.store.dispatch(new GetCompanyPortalSettings());
      return of(new DeleteCompanyPortalSettingsSuccess(null));
    }),
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private companyPortalSettingsService: CompanyPortalSettingsService,
    private translate: TranslateService,
    private store: Store,
    private helpersServices: HelpersServices,
  ) {
  }
}
