import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeColumnComponent } from '@app/commonComponents/change-column/change-column.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { StagesKanbanComponent } from '@app/commonComponents/stages-kanban/stages-kanban.component';
import { MatterFilterStatus } from '@app/enums/matter-filter-status.enum';
import { generateString } from '@app/helpers';
import { checkRole } from '@app/helpers/check-role';
import { checkTariff } from '@app/helpers/check-tariff';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { RoutesService } from '@app/helpers/routes.service';
import { Contact, IUser, PracticeBranch, RouteData, SortingData } from '@app/models';
import { MatterExtended } from '@app/models/matter-extended';
import { MatterFilters } from '@app/models/matter-filters';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { GetPracticeBranches } from '@app/modules/settings/store/actions/practice-branches.actions';
import { selectPracticeBranchesPaginator } from '@app/modules/settings/store/selectors/practice-branch.selector';
import { LoadContractAction } from '@app/store/actions/contract.actions';
import { ClearMatters, CreateMatter, DeleteMatter, GetMatter, GetMatters, TrackMatter } from '@app/store/actions/matter.actions';
import { MatterEffects } from '@app/store/effects/matter.effects';
import { selectContract } from '@app/store/selectors/contract.selector';
import { selectMattersPaginator } from '@app/store/selectors/matter.selector';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2/base';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-matters',
  templateUrl: './matters.component.html',
  styleUrls: ['./matters.component.scss'],
})
export class MattersComponent implements AfterViewInit, OnDestroy, OnChanges {
  constructor(
    private matterEffect: MatterEffects,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private routesService: RoutesService,
    private store: Store<IAppState>,
    private matterService: MatterService,
    private translate: TranslateService,
  ) {
    this.store.select(selectContract).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadContractAction());
      }
    });

    matterEffect.getMatters$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.hideSpinner());

    matterEffect.deleteMatter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.hideSpinner();
      this.getFilteredData(this.filters);
    });
    this.initTables();
    const params = this.route.snapshot.firstChild?.params?.type || '';
    const key = this.route.snapshot.firstChild.queryParams.key;

    const settings = JSON.parse(sessionStorage.getItem(key));
    if (settings?.filters) {
      this.filters = settings.filters;
    }
    this.activeTab = params;
  }



  @Input() shouldSaveRoute: boolean;
  @Input() routesData: RouteData[];

  @Input() ifContact: Contact;
  @Input() ifUser: IUser;
  @Output() setScrollPositionEvent = new EventEmitter<number>();
  @Input() entityName = 'matter';
  readonly spinnerName = 'matters';
  activeTab = '';
  filters: MatterFilters = {};
  filterToggleButton = false;
  key: string;
  keyType: string;
  routesDataType: RouteData[] = [
    {
      base: 'all',
      labelText: 'table.list'
    },
    {
      base: 'stages',
      labelText: 'stages'
    },
  ];
  restList: MatterExtended[];
  resultsLength = 0;
  searchStringSubject$ = new Subject();
  sentColumns = [];
  topMattersOffset = 0;
  translater = this.translate.instant('common.filter');
  loopItemsScroll = environment.loopItemsScroll;
  loading = true;
  @ViewChild('changeColumn') private changeColumn: ChangeColumnComponent;
  @ViewChild('stageKanbanComponent') private stageKanbanComponent: StagesKanbanComponent;

  @ViewChild('practiceBranches') private combopracticeBranch: DropDownListComponent;

  selectedPracticeBranch = null;


  tableCreateBtns = [{ click: () => this.onCreateMatter(), name: 'matter.add' }];
  showLoader = false;
  private localPage: number;
  private orderedByUser = false;
  private unsubscribe$ = new Subject<void>();
  private columns = [
    {
      draggable: false,
      disabled: true,
      name: 'table.fullName',
      visible: true,
      resizible: true,
      key: 'matter-name',
      value: [],
      route: true,
      type: 'matter',
      sortedBy: false,
    },
    {
      draggable: true,
      disabled: false,
      name: 'common.client',
      visible: true,
      resizible: true,
      key: 'contact',
      value: [],
      route: true,
      type: 'contact',
      sortedBy: false,
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.responsiblePersonOfContacts',
      visible: true,
      resizible: true,
      key: 'contactResponsiblePerson',
      value: [],
      route: true,
      type: 'worker',
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.responsibleSolicitor',
      visible: true,
      resizible: true,
      key: 'responsibleSolicitor',
      value: [],
      route: true,
      type: 'worker',
    },
    {
      draggable: true,
      disabled: false,
      name: 'common.date',
      visible: true,
      resizible: true,
      key: 'matter-date',
      value: [],
      route: false,
      type: 'date',
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.practiceBranch',
      visible: false,
      resizible: true,
      key: 'practiceBranch',
      value: [],
      route: false,
      type: 'practice',
    },
    {
      draggable: true,
      disabled: false,
      name: 'matter.matterStage',
      visible: false,
      resizible: true,
      key: 'matterStage',
      value: [],
      route: false,
      type: 'matterStage',
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.balance-matter',
      visible: false,
      resizible: true,
      key: 'balance',
      value: [],
      route: false,
      type: 'number',
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.number-date-contract',
      visible: false,
      resizible: true,
      key: 'contract',
      value: [],
      route: true,
      type: 'contract',
    },
    {
      draggable: true,
      disabled: false,
      name: 'matter.originatingSolicitor',
      visible: false,
      resizible: true,
      key: 'originatingSolicitor',
      value: [],
      route: true,
      type: 'worker',
    },
    {
      draggable: true,
      disabled: false,
      name: 'matter.companyWorkGroup',
      visible: false,
      resizible: true,
      key: 'companyWorkGroup',
      value: [],
      route: false,
      type: 'name',
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.currencyBill-matter',
      visible: false,
      resizible: true,
      key: 'currencyBill',
      value: [],
      route: false,
      type: 'code',
    },
    {
      draggable: true,
      disabled: false,
      name: 'matter.budgetMatter',
      visible: false,
      resizible: true,
      key: 'budgetAmount',
      value: [],
      route: false,
      type: 'number',
    },
    {
      draggable: true,
      disabled: false,
      name: 'matter.amountMatter',
      visible: false,
      resizible: true,
      key: 'matterAmount',
      value: [],
      route: false,
      type: 'amount',
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.status',
      visible: false,
      resizible: true,
      key: 'matterStatus',
      value: [],
      route: false,
      type: 'string',
    },
    {
      draggable: true,
      disabled: false,
      name: 'matter.description',
      visible: false,
      resizible: true,
      key: 'description',
      value: [],
      route: false,
      type: 'string',
    },
    {
      draggable: true,
      disabled: false,
      name: 'ID',
      visible: false,
      resizible: true,
      key: 'id',
      value: [],
      route: true,
      type: 'matter',
    },
    {
      draggable: true,
      disabled: false,
      name: 'common.tags',
      entity: 'tagMatters',
      visible: false,
      resizible: true,
      key: 'tags',
      value: [],
      route: true,
      type: 'tags',
    },
  ];

  get page(): number {
    return this.localPage ? this.localPage : environment.defaultPage;
  }

  set page(value: number) {
    this.localPage = value;
  }

  get pageSize(): number {
    return this.changeColumn && this.changeColumn.paginator ? this.changeColumn.paginator.pageSize : environment.defaultItemsCount;
  }

  ngOnInit() {
    this.loadPracticeBranche();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    // console.log(this.filters);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initTables();
      this.store
        .select(selectMattersPaginator)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(matters => {
          if (!matters.member) {
            return;
          }
          this.restList = matters.member;
          this.resultsLength = matters.totalItems;
          if (this.changeColumn) {
            this.changeColumn.paginator.type = 'matter';
            this.changeColumn.restList = this.restList;
            this.changeColumn.columns = this.sentColumns;
            this.changeColumn.initTableData();
            setTimeout(() => {
              this.changeColumn.paginator.pager.currentPage = this.page;
              this.changeColumn.paginator.pageIndex = this.page - 1;
              this.scrollMattersTo();
            });
          }

          if (this.countFilters() > 1) {
            this.translater = this.translate.instant('taskAndEvents.filter.filters');
          } else {
            this.translater = this.translate.instant('common.filter');
          }
        });
      setTimeout(() => {
        this.getUrlParams();
      }, 0);
      this.key = generateString(12);
      this.searchStringSubject$
        .pipe(debounceTime(environment.textSearchDebounce), distinctUntilChanged())
        .subscribe((query: string) => this.textFilter(query));
    }, 100);
  }

  ngOnDestroy() {
    sessionStorage.setItem(
      this.key,
      JSON.stringify({
        filters: this.filters,
        page: this.page,
        topOffset: this.topMattersOffset,
        orderedByUser: this.orderedByUser,
      }),
    );
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.searchStringSubject$.unsubscribe();
    if (this.changeColumn?.paginator) {
      this.changeColumn.paginator.type = '';
    }

    delete this.searchStringSubject$;

    this.store.dispatch(new ClearMatters());
  }

  onEditMatter(matter: MatterExtended) {
    // this.store.dispatch(new CreateMatter(matter));

    this.router.navigateByUrl(`/matters/edit/${matter.id}`);
  }

  onCreateMatter() {
    this.store.dispatch(new GetMatter(null));

    if (this.ifContact) {
      this.store.dispatch(new CreateMatter({ contact: this.ifContact }));
    }

    this.router.navigateByUrl('/matters/create');

    if (this.ifContact) {
      this.store.dispatch(new TrackMatter(this.ifContact.id));
    }
  }

  onViewMatter(matter: MatterExtended) {
    this.store.dispatch(new CreateMatter(matter));
    this.router.navigateByUrl(`/matter/${matter.id}`);
  }

  onPinMatter(matter: MatterExtended) {
    this.openSpinner();
    this.matterService.pinnedMatter(!matter.isPinned ?
      { 'pinMatters': [matter.id] } : { 'unpinMatters': [matter.id] })
      .subscribe(() => {
        this.matterEffect.refreshMatters();
        this.store.dispatch(
          new GetMatters({
            page: this.page,
            itemsPerPage: this.pageSize,
            filters: {
              ...this.filters,
              'order[matterStageUpdatedAt]': null
            },
          }),
        );
      });
  }

  checkIsDelete(matter: MatterExtended) {
    if (matter.canDelete === true) {
      this.deleteMatter(matter);
    } else if (matter.canDelete === false) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noButtonTxt: 'cannotDeleteButton',
        title: 'cannotDeleteTitleTxt',
        mainTxt: 'cannotDeleteTxt',
        disableYesButton: true,
        disableNoButton: false,
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          dialogRef.close();
        });
    }
  }

  paginate() {
    this.page = 1;
    this.getFilteredData(this.filters);
    this.setQueryParams();
  }

  selectPractice(practice) {
    this.selectedPracticeBranch = practice;
    sessionStorage.setItem('matterStagePractive', practice.id);
    this.filterToggleButton = false;
  }

  applyFilteredData(filters: MatterFilters) {
    if (this.activeTab !== 'stages') {
      this.getFilteredData(filters);
    } else {
      this.stageKanbanComponent.initCurrentStages(filters);
      this.filterToggleButton = false;
    }
    this.setQueryParams();
  }

  onSortingChange(sortingData: SortingData) {
    switch (sortingData.columnKey) {
      case 'matter-name':
        this.filters['order[name]'] = sortingData.sorting;
        this.filters['order[contact.id]'] = null;
        break;
      case 'contact':
        this.filters['order[contact.id]'] = sortingData.sorting;
        this.filters['order[name]'] = null;
        break;
    }

    this.orderedByUser = true;
    this.applyFilteredData(this.filters);
  }

  countFilters() {
    let count = 0;
    for (const key in this.filters) {
      if (this.filters[key]) {
        count++;
      }
    }
    if (this.activeTab === 'stages') {
      count--;
    }
    return count;
  }

  private initTables() {
    const entityTable = `${this.entityName}Table`;
    const currentWidthsColumnsTable = window.localStorage.getItem(entityTable) ? JSON.parse(window.localStorage.getItem(entityTable)) : {};

    const currentColumnsTable = window.localStorage.getItem(`${this.entityName}SettingTable`)
      ? JSON.parse(window.localStorage.getItem(`${this.entityName}SettingTable`))
      : {};
    this.sentColumns = this.columns;
    if (Object.keys(currentColumnsTable).length === 0) {
      const currentTable = document.getElementsByClassName('table-section-template')[0] as HTMLElement;
      if (currentTable) {
        for (const col of this.sentColumns) {
          col.width = currentTable?.offsetWidth / this.sentColumns.length;
        }
      }
    } else {
      let posIdx = 0;
      for (const key in currentColumnsTable) {
        if (currentColumnsTable.hasOwnProperty(key)) {
          const idx = this.sentColumns.findIndex(item => item.key === key);
          if (idx !== -1) {
            this.sentColumns[idx].visible = currentColumnsTable[key];
            this.sentColumns[idx].position = posIdx;
          }
          posIdx++;
        }
      }
    }

    if (Object.keys(currentWidthsColumnsTable).length !== 0) {
      for (const key in currentWidthsColumnsTable) {
        if (currentWidthsColumnsTable.hasOwnProperty(key)) {
          const idx = this.sentColumns.findIndex(item => item.key === key.replace(this.entityName, ''));
          if (idx !== -1) {
            this.sentColumns[idx].width = currentWidthsColumnsTable[key];
          }
        }
      }
    }
    if (!checkRole(['ROLE_SITE_ADMIN', 'ROLE_SITE_BILLING_MANAGER', 'ROLE_SITE_ACCOUNTS_MANAGER'])) {
      const findIdxColumnContract = this.sentColumns.findIndex(item => item.key === 'contract');
      if (findIdxColumnContract !== -1) {
        this.sentColumns.splice(findIdxColumnContract, 1);
      }
    }
    if (!checkTariff([1, 3, 4])) {
      const findIdxColumnStages = this.sentColumns.findIndex(item => item.key === 'matterStage');
      if (findIdxColumnStages !== -1) {
        this.sentColumns.splice(findIdxColumnStages, 1);
      }
    }
    this.sentColumns.sort((a, b) => a.position - b.position);
  }

  openSettingColumns() {
    this.changeColumn.openSettingMenu();
    this.changeColumn.markForCheck();
  }

  private resetPaginator() {
    this.page = 1;
    if (this.changeColumn?.paginator) {
      this.changeColumn.paginator.pager.currentPage = 1;
    }
  }

  private getUrlParams() {
    const params = this.route.firstChild?.params || this.route.params;
    const queryParams = this.route.firstChild?.queryParams || this.route.queryParams;
    combineLatest([params, queryParams])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(data => {
          if (!data[0] || !data[1]) {
            return null;
          }

          if (data[1].page) {
            this.page = +data[1].page;
          }

          if (!data[1].key) {
            this.key = generateString(12);
            this.router.navigate([`${this.getPath()}/open`], {
              queryParams: {
                key: this.key,
                page: this.page,
              },
              replaceUrl: false,
            });
          } else {
            this.key = data[1].key;
          }
          const settings = JSON.parse(sessionStorage.getItem(data[1].key));
          if (settings?.filters) {
            this.filters = settings.filters;
          }
          this.filters.status = data[0].type ? MatterFilterStatus[data[0].type] : null;
          this.activeTab = data[0].type || '';
          if (!this.activeTab) {
            this.activeTab = this.getParam();
          }
          if (this.activeTab === 'stages' && !checkTariff([1, 3, 4])) {
            this.router.navigate([`${this.getPath()}/open`], {
              queryParams: {
                key: this.key,
                page: this.page,
              },
              replaceUrl: false,
            });
          }
          this.routesDataType[0].base = this.activeTab !== 'stages' ? this.activeTab : 'all';
          this.filters.status = data[0].type ?? this.getParam();

          if (settings?.page) {
            this.page = settings.page;
          }
          if (settings?.topOffset) {
            this.topMattersOffset = +settings.topOffset;
          }
          if (settings?.orderedByUser) {
            this.orderedByUser = settings.orderedByUser;
          }

          this.routesService.setUrl(this.router.url, !this.shouldSaveRoute);
          return data;
        }),
      )
      .subscribe(param => {
        if (!param) {
          this.setQueryParams();
        } else {
          this.getFilteredData(this.filters);
        }
      });
  }

  private setQueryParams() {
    this.router.navigate([`${this.getPath()}/${this.activeTab}`], {
      queryParams: {
        key: this.key,
        page: this.page,
      },
      replaceUrl: true,
    });
  }

  private getPath(): string {
    return this.router.url
      .split('/')
      .slice(0, -1)
      .join('/');
  }

  private getParam() {
    const urlChunks = this.router.url.split('/');
    return urlChunks[urlChunks.length - 1].split('?')[0];
  }

  private scrollMattersTo() {
    this.setScrollPositionEvent?.emit(this.topMattersOffset);
    this.setScrollPositionEvent = null;
  }

  private openSpinner() {
    this.loading = true;
    this.showLoader = true;
  }

  private hideSpinner() {
    this.loading = false;
    this.showLoader = false;
  }

  public textFilter(value: string) {
    if (this.filters.name !== value) {
      this.resetPaginator();
      this.filters = { ...this.filters, name: value };
      if (this.activeTab === 'stages') {
        this.stageKanbanComponent.initCurrentStages(this.filters);
      }
      this.getFilteredData(this.filters);
      this.setQueryParams();
    }
  }

  private getFilteredData(matterFilter: MatterFilters, silent = false) {
    this.filterToggleButton = false;
    this.filters = matterFilter;


    if (matterFilter.status) {
      this.activeTab = matterFilter.status;
    }

    if (this.ifContact) {
      matterFilter['contact.id'] = this.ifContact.id;
    }

    if (this.ifUser) {
      matterFilter['responsibleSolicitor.id'] = this.ifUser.id;
    }
    if (this.filters.status !== 'stages') {
      if (!silent) {
        this.openSpinner();
      }
      this.setSortingDataToColumns();
      window.sessionStorage.setItem(this.key, JSON.stringify({ filters: this.filters }));
      this.initTables();
      matterFilter['order[matterStageUpdatedAt]'] = null;
      this.store.dispatch(
        new GetMatters({
          page: this.page,
          itemsPerPage: this.pageSize,
          filters: matterFilter,
        }),
      );
    } else if (!this.selectedPracticeBranch && this.practiceBranch?.length) {
        this.selectedPracticeBranch = this.practiceBranch[0];
      }

  }

  private deleteMatter(matter: MatterExtended) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteTxt',
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result === true) {
          this.openSpinner();
          this.store.dispatch(new DeleteMatter(matter.id));
        } else {
          dialogRef.close();
        }
      });
  }

  private setSortingDataToColumns() {
    const columnMatterName = this.sentColumns.find(c => c.key === 'matter-name') ?? this.columns.find(c => c.key === 'matter-name');
    if (columnMatterName) {
      columnMatterName.sortedBy = this.filters['order[name]'] ?? true;
    }
    const columnContact = this.sentColumns.find(c => c.key === 'contact') ?? this.columns.find(c => c.key === 'contact');
    if (columnContact) {
      columnContact.sortedBy = this.filters['order[contact.id]'] ?? true;
    }
  }

  loadPracticeBranche() {
    this.store.select(selectPracticeBranchesPaginator).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!data) {
        this.store.dispatch(new GetPracticeBranches({ page: 1, itemsPerPage: 1000 }));
      }
      if (data) {
        this.practiceBranch = data.member;
        const selectedStagePractive = sessionStorage.getItem('matterStagePractive');
        if (selectedStagePractive && this.activeTab === 'stages') {
          this.selectedPracticeBranch = this.practiceBranch.find(el => el.id === +selectedStagePractive);
        } else {
          this.selectedPracticeBranch = this.practiceBranch[0];
        }
        if (this.combopracticeBranch) {
          this.combopracticeBranch.dataSource = this.practiceBranch as any;
          this.combopracticeBranch?.dataBind();
        }
      }
    });
  }

  practiceBranch: PracticeBranch[];
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  public filterPracticeBranch: EmitType<any> = (e: FilteringEventArgs) => {
    if (e.text === '') {
      e.updateData(this.practiceBranch as any);
    } else {
      const filteringData = [];
      this.practiceBranch.forEach(i => {
        if (i.name.toUpperCase().indexOf(e.text.toUpperCase()) !== -1) {
          filteringData.push(i);
        }
      });
      e.updateData(filteringData);
    }
  };
}
