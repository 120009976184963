import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionLeftComponent } from '@app/commonComponents/subscription-left/subscription-left.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubscriptionLeftComponent],
  exports: [SubscriptionLeftComponent],
  imports: [CommonModule, TranslateModule]
})
export class SubscriptionLeftModule {}
