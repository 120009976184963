import { Action } from '@ngrx/store';
import { Paginator } from '@app/models/paginator';
import { MatterExtended } from '@app/models/matter-extended';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { MatterFilters } from '@app/models/matter-filters';

export enum EMattersActions {
  GET_MATTERS = '[Matters] Get Matters',
  GET_MATTERS_SUCCESS = '[Matters] Get Matters Success',
  GET_MATTERS_FAILURE = '[Matters] Get Matters Failure',
  GET_MATTER = '[Matters] Get Matter',
  GET_MATTER_SUCCESS = '[Matters] Get Matter Success',
  GET_MATTER_FAILURE = '[Matters] Get Matters Failure',
  ADD_MATTER = '[Matters] Add Matter',
  ADD_MATTER_SUCCESS = '[Matters] Add Matter Success',
  ADD_MATTERS_FAILURE = '[Matters] Get Matters Failure',
  create = '[Matter] create matter',
  UPDATE_MATTER = '[Matters] Update Matter',
  UPDATE_MATTER_SUCCESS = '[Matters] Update Matter Success',
  UPDATE_MATTERS_FAILURE = '[Matters] Get Matters Failure',
  DELETE_MATTER = '[Matters] Delete Matter',
  DELETE_MATTER_SUCCESS = '[Matters] Delete Matter Success',
  DELETE_MATTER_FAILURE = '[Matters] Get Matters Failure',
  TRACK_MATTER = '[Matter] Track Matter',

  LOAD_MATTERS_FOR_LIST = '[Matters] Load Matters for list',
  LOAD_MATTERS_FOR_LIST_SUCCESS = '[Matters] Load Matters for list Success',
  LOAD_MATTERS_FOR_LIST_FAILURE = '[Matters] Load Matters for list Failure',
  UPDATE_MATTERS_FOR_LIST = '[Matters] Update Matters for list with chosen item',
  UPDATE_MATTERS_FOR_LIST_SUCCESS = '[Matters] Update Matters for list  with chosen item Success',
  UPDATE_MATTERS_FOR_LIST_FAILURE = '[Matters] Update Matters for list  with chosen item Failure',

  LOAD_ALL_MATTERS_FOR_LIST = '[Matters] Load All Matters for list',
  LOAD_ALL_MATTERS_FOR_LIST_SUCCESS = '[Matters] Load All Matters for list Success',
  LOAD_ALL_MATTERS_FOR_LIST_FAILURE = '[Matters] Load All Matters for list Failure',
  UPDATE_ALL_MATTERS_FOR_LIST = '[Matters] Update All Matters for list with chosen item',
  UPDATE_ALL_MATTERS_FOR_LIST_SUCCESS = '[Matters] Update All Matters for list  with chosen item Success',
  UPDATE_ALL_MATTERS_FOR_LIST_FAILURE = '[Matters] Update All Matters for list  with chosen item Failure',
  UPDATE_All_MATTERS_FOR_LIST_SUCCESS = 'UPDATE_All_MATTERS_FOR_LIST_SUCCESS',

  CLEAR_MATTERS = '[Matters] Clear Matters',
  CLEAR_ALL_MATTERS_FOR_LIST = '[Matters] Clear All Matters for list'
}

export class LoadMattersForList implements Action {
  public readonly type = EMattersActions.LOAD_MATTERS_FOR_LIST;

  constructor(public payload: FetchPayload<MatterFilters>) {}
}
export class LoadMattersForListSuccess implements Action {
  public readonly type = EMattersActions.LOAD_MATTERS_FOR_LIST_SUCCESS;

  constructor(public payload: MatterExtended[]) {}
}
export class LoadMattersForListFailure implements Action {
  public readonly type = EMattersActions.LOAD_MATTERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateMattersForList implements Action {
  public readonly type = EMattersActions.UPDATE_MATTERS_FOR_LIST;

  constructor(public payload: number) {}
}
export class UpdateMattersForListSuccess implements Action {
  public readonly type = EMattersActions.UPDATE_MATTERS_FOR_LIST_SUCCESS;

  constructor(public payload: MatterExtended) {}
}
export class UpdateMattersForListFailure implements Action {
  public readonly type = EMattersActions.UPDATE_MATTERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadAllMattersForList implements Action {
  public readonly type = EMattersActions.LOAD_ALL_MATTERS_FOR_LIST;

  constructor(public payload: FetchPayload<MatterFilters>) {}
}
export class LoadAllMattersForListSuccess implements Action {
  public readonly type = EMattersActions.LOAD_ALL_MATTERS_FOR_LIST_SUCCESS;

  constructor(public payload: MatterExtended[]) {}
}
export class LoadAllMattersForListFailure implements Action {
  public readonly type = EMattersActions.LOAD_ALL_MATTERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}
export class ClearAllMattersForList implements Action {
  public readonly type = EMattersActions.CLEAR_ALL_MATTERS_FOR_LIST;
}

export class UpdateAllMattersForList implements Action {
  public readonly type = EMattersActions.UPDATE_ALL_MATTERS_FOR_LIST;

  constructor(public payload: number) {}
}
export class UpdateAllMattersForListSuccess implements Action {
  public readonly type = EMattersActions.UPDATE_ALL_MATTERS_FOR_LIST_SUCCESS;

  constructor(public payload: MatterExtended) {}
}
export class UpdateAllMattersForListFailure implements Action {
  public readonly type = EMattersActions.UPDATE_ALL_MATTERS_FOR_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class GetMatters implements Action {
  public readonly type = EMattersActions.GET_MATTERS;

  constructor(public payload: FetchPayload<MatterFilters>) {}
}
export class GetMattersSuccess implements Action {
  public readonly type = EMattersActions.GET_MATTERS_SUCCESS;

  constructor(public payload: Paginator<MatterExtended>) {}
}
export class GetMattersFailure implements Action {
  public readonly type = EMattersActions.GET_MATTERS_FAILURE;

  constructor(public payload: Error) {}
}

export class GetMatter implements Action {
  public readonly type = EMattersActions.GET_MATTER;

  constructor(public payload: number) {}
}
export class GetMatterSuccess implements Action {
  public readonly type = EMattersActions.GET_MATTER_SUCCESS;

  constructor(public payload: MatterExtended) {}
}
export class GetMatterFailure implements Action {
  public readonly type = EMattersActions.GET_MATTER_FAILURE;

  constructor(public payload: Error) {}
}

export class AddMatter implements Action {
  public readonly type = EMattersActions.ADD_MATTER;

  constructor(public payload: MatterExtended) {}
}
export class AddMatterSuccess implements Action {
  public readonly type = EMattersActions.ADD_MATTER_SUCCESS;

  constructor(public payload: MatterExtended) {}
}
export class AddMatterFailure implements Action {
  readonly type = EMattersActions.ADD_MATTERS_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearMatters implements Action {
  public readonly type = EMattersActions.CLEAR_MATTERS;

  constructor() {}
}

export class CreateMatter implements Action {
  public readonly type = EMattersActions.create;

  constructor(public payload: MatterExtended) {}
}

export class UpdateMatter implements Action {
  public readonly type = EMattersActions.UPDATE_MATTER;

  constructor(public payload: MatterExtended) {}
}
export class UpdateMatterSuccess implements Action {
  public readonly type = EMattersActions.UPDATE_MATTER_SUCCESS;

  constructor(public payload: MatterExtended) {}
}
export class UpdateMatterFailure implements Action {
  public readonly type = EMattersActions.UPDATE_MATTERS_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteMatter implements Action {
  public readonly type = EMattersActions.DELETE_MATTER;

  constructor(public payload: number) {}
}
export class DeleteMatterSuccess implements Action {
  public readonly type = EMattersActions.DELETE_MATTER_SUCCESS;

  constructor(public payload: number) {}
}
export class DeleteMatterFailure implements Action {
  public readonly type = EMattersActions.DELETE_MATTER_FAILURE;

  constructor(public payload: Error) {}
}
// TODO any
export class TrackMatter implements Action {
  public readonly type = EMattersActions.TRACK_MATTER;

  constructor(public payload: any) {}
}

export type MatterActions =
  | GetMatters
  | GetMattersSuccess
  | GetMattersFailure
  | GetMatter
  | GetMatterSuccess
  | GetMatterFailure
  | AddMatter
  | AddMatterSuccess
  | AddMatterFailure
  | ClearMatters
  | CreateMatter
  | ClearAllMattersForList
  | UpdateMatter
  | UpdateMatterSuccess
  | UpdateMatterFailure
  | DeleteMatter
  | DeleteMatterSuccess
  | DeleteMatterFailure
  | TrackMatter
  | LoadMattersForList
  | LoadMattersForListSuccess
  | LoadMattersForListFailure
  | LoadAllMattersForList
  | LoadAllMattersForListSuccess
  | LoadAllMattersForListFailure
  | UpdateMattersForList
  | UpdateMattersForListSuccess
  | UpdateMattersForListFailure
  | UpdateAllMattersForList
  | UpdateAllMattersForListSuccess
  | UpdateAllMattersForListFailure;
