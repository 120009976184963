import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, group, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeTopAnimation, fadeTopRightZerovAnimation, setIndexAnimation, translateTopAnimation } from '@app/helpers/fadeAnimation';
import { ExpenseModalComponent } from '@app/commonComponents/wide-modals/expense-modal/expense-modal.component';
import { TimeModalComponent } from '@app/commonComponents/wide-modals/time-modal/time-modal.component';
import { ContactsModalComponent } from '@app/modules/contacts/components/contacts-modal/contacts-modal.component';
import {
  CorrespondenceNewModalComponent,
} from '@app/commonComponents/modals/wide-modals/correspondence-new-modal/correspondence-new-modal.component';
import { NoteModalComponent } from '@app/commonComponents/modals/default-modals/note-modal/note-modal.component';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { GetMatter } from '@app/store/actions/matter.actions';
import { Observable, Subject, Subscription } from 'rxjs';
import { TimekeeperComponent } from '@app/modules/layout/timekeeper/timekeeper.component';
import { AuthService } from '@app/auth/_services/auth.service';
import { TimekeeperService } from '@app/modules/layout/timekeeper/timekeeper.service';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { WebsocketService } from '@app/websocket/websocket.service';
import { GetCountUnreadNotifications, GetNotifications } from '@app/modules/layout/notifications/store/actions/notification.actions';
import { INotification } from '@app/models/interfaces/notifications.interface';
import { selectCountNewNotification } from '@app/modules/layout/notifications/store/selectors/notification.selectors';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { SwitchNotificationsService, TimeFormatter } from '@app/helpers';
import { GetNews } from '@app/modules/layout/news/store/actions/news.actions';
import { FirstLoginModalComponent } from '@app/commonComponents/modals/system-modals/first-login-modal/first-login-modal.component';
import { filter, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GetCurrentUser } from '@app/auth/_store/actions/auth.actions';
import { SearchTypeMap } from '@app/modules/search/search-type.map';
import { AuthEffects } from '@app/auth/_store/effects/auth.effects';
import { IInfoEntity } from '@app/models/comment';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { ActivityExtended } from '@app/models';
import { CommunicationPublicService } from '../discussion/services/communication.service';
import { CommunicationService } from '@app/modules/reusable/jusnote-connect/services/communication.service';
import { selectLastNews } from '../news/store/selectors/news.selectors';
import { GetDocument } from '../../../store/actions/document.actions';
import { TasksEventsModalComponent } from '@app/commonComponents/wide-modals/task-event-modal/t-e-modal.component';
import { Paginator } from '@app/models/paginator';
import { INews } from '@app/models/interfaces/news.interface';
import { TypeExpenseEnum } from '@app/commonComponents/wide-modals/symbols';
import { Select } from '@ngxs/store';
import { ChatState } from '@app/chat/states/chat/chat.state';
import { ChatStateModel } from '@app/chat/states/chat/chat.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    translateTopAnimation,
    fadeTopAnimation,
    fadeTopRightZerovAnimation,
    setIndexAnimation,
    trigger('fadeBG', [
      transition(':enter', [style({ opacity: 0 }), animate('.240s', style({ opacity: 0.1 }))]),
      transition(':leave', [
        style({ opacity: 0.1 }),
        group([
          animate(
            '.0s ease',
            style({
              opacity: 0,
            }),
          ),
        ]),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(ChatState.currentChat) currentChat$: Observable<ChatStateModel['currentChat']>;

  countNewMessages$ = this.store.pipe(select(selectCountNewNotification));
  companySettings$ = this.store.pipe(select(selectCompanySetting));
  countNewMessages: number;
  activeTimer = 0;
  timerSubscription: Subscription = new Subscription();
  timeEntry: ActivityExtended;
  public message$ = new Subject();
  headerMenu = false;
  closeTimekeeper = false;
  userId: number = this.auth.getPayload()?.id;
  unsubscribe = new Subject<void>();
  public entityInfo: IInfoEntity;
  private audioPing = new Audio();
  openList = false;
  activeBg = false;
  @ViewChild(TimekeeperComponent, { static: false }) timeKeeper: TimekeeperComponent;

  public searchForm: UntypedFormGroup;
  takeNews = true;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private auth: AuthService,
    public timekeeperService: TimekeeperService,
    private wsService: WebsocketService,
    private authService: AuthService,
    private authServiceNotApi: AuthService,
    public switchNotificService: SwitchNotificationsService,
    private newsStore: Store<Paginator<INews>>,
    private formBuilder: UntypedFormBuilder,
    private authEffect: AuthEffects,
    public timeFormatter: TimeFormatter,
    private communicationPublicService: CommunicationPublicService,
    private communicationService: CommunicationService,
    private route: ActivatedRoute,
  ) {
    if (!this.expired() && this.authService.isCheckCurrentSubscriptionId()) {
      wsService.webSocketConnect();
    }
    this.getNotifications(1, 10, true);
    // setInterval(() => {
    //   this.getNotifications(1, 10, true);
    // }, 600000);
    if (this.expired()) {
      return;
    }
    this.getNews();
    this.authEffect.updateUserLang$.subscribe(res => {
      this.getNotifications();
      this.getNews();
    });
    this.countNewMessages$.subscribe(r => {
      this.countNewMessages = r;
    });
  }

  getNotifications(page = 1, itemsPerPage = 10, renew = false) {
    if (this.auth.isLoggedIn()) {
      this.store.dispatch(new GetNotifications({ page, itemsPerPage, renew }));
      this.store.dispatch(new GetCountUnreadNotifications());
    }
  }

  getNews(page = 1, itemsPerPage = 10) {
    this.newsStore.dispatch(new GetNews({ page, itemsPerPage }));
    this.newsStore.select(selectLastNews).pipe(filter(x => this.takeNews)).subscribe(resp => {
      this.showNews(resp[0]);
    });
  }

  showNews(news) {
    if (news && news?.isReaded === false) {
      this.takeNews = false;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ['default-mat-dialog'];
      dialogConfig.data = news;
      this.dialog.open(FirstLoginModalComponent, dialogConfig);
    }
  }

  ngOnInit() {
    this.authService.refreshUser();
    this.store.dispatch(new GetCurrentUser(this.authService.getPayload().id));

    this.searchForm = this.formBuilder.group({
      searchType: 'All',
      searchQuery: ['', [Validators.minLength(1), Validators.required]],
    });

    this.addNewNotification();

    this.route.queryParams.subscribe(params => (this.searchForm.patchValue({ ...params })));

  }

  addNewNotification() {
    this.wsService.centrifugo.subscribe(`notification#${this.userId}`, (message: { seq: number; data: INotification }) => {
      this.checkLoadCommunication(message.data);
      this.checkDocusignMsg(message.data);
      this.message$.next(message.data);
      if (message) {
        this.playNotificationsPing();
        this.getNotifications(1, 10, true);
      }
    });
  }


  headerMenuSwitch() {
    if (this.expired()) {
      return;
    }
    this.headerMenu = !this.headerMenu;
  }

  setActiveBg() {
    setTimeout(() => {
      this.activeBg = !this.activeBg;
    }, 200);
  }

  eventsModal() {
    this.headerMenuSwitch();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'events-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { type: 'event' };
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  tasksModal() {
    this.headerMenuSwitch();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'tasks-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { type: 'task' };
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  expenseModal() {
    this.headerMenuSwitch();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'expense-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { type: TypeExpenseEnum.EXTERNAL };
    const dialogRef = this.dialog.open(ExpenseModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  timeModal() {
    this.headerMenuSwitch();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'time-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TimeModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  contactsModal() {
    this.headerMenuSwitch();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'full-height-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ContactsModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  mattersPage() {
    this.store.dispatch(new GetMatter(null));

    this.router.navigate(['/matters/create']);
    this.headerMenuSwitch();
  }

  correspondenceModal() {
    this.headerMenuSwitch();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'correspondence-new-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CorrespondenceNewModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  noteModal() {
    this.headerMenuSwitch();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'note-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(NoteModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  stopTimer() {
    if (this.timekeeperService.isPending || !this.timekeeperService.activeTimeEntry || this.expired()) {
      return;
    }
    this.timekeeperService.isPending = true;
    this.timekeeperService.removeTimer().pipe(take(1)).subscribe(() => {
      const timeEntryId = this.timekeeperService.activeTimeEntry.id;
      this.timeKeeper.refetchTimeEntries(this.timeKeeper.selectedDate).subscribe(
        res => {
          this.timeKeeper.timeModal(res.find(t => t.id === timeEntryId));
          this.timekeeperService.isPending = false;
        },
        () => {
        },
        () => {
          this.timekeeperService.isPending = false;
        },
      );
    });
  }

  createTimer(currencyCode: string) {
    if (this.timekeeperService.isPending || this.timekeeperService.activeTimeEntry || this.expired()) {
      return;
    }
    this.timekeeperService.isPending = true;
    const responsiblePersonId = this.auth.currentUserValue.id;

    this.timekeeperService
      .createTimer({
        initialValue: 0,
        timeEntry: {
          currency: currencyCode,
          responsiblePerson: responsiblePersonId,
          date: CustomDateAdapter.convertToUniversalString(new Date()),
        },
      })
      .subscribe(() => {
        this.timeKeeper.refetchTimeEntries(this.timeKeeper.selectedDate);
        this.timekeeperService.isPending = false;
      });
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  ngOnDestroy() {
    this.stopTimer();
    this.timekeeperService.logout();
    this.wsService.webSocketDisconnect();
    this.unsubscribe.next();
  }

  clearSearch(input: HTMLInputElement) {
    this.searchForm.get('searchQuery').setValue('');
    input.focus();
  }

  onSearchSubmit() {
    if (this.expired()) {
      return;
    }
    const { searchType, searchQuery } = this.searchForm.getRawValue();
    const url = ['search'];
    if (searchType !== 'All') {
      url.push(SearchTypeMap[searchType]);
    }

    if (!searchQuery) {
      return;
    }

    this.router.navigate(url, {
      queryParams: this.searchForm.getRawValue(),
    });
  }

  private expired() {
    return this.authServiceNotApi.isExpiredRaw;
  }

  switchTimekeeper() {
    if (this.expired()) {
      return;
    }
    this.closeTimekeeper = !this.closeTimekeeper;
  }

  convertSecondsToTableString(duration: number): string {
    const h = duration ? Math.floor(duration / 3600) : 0;
    const m = duration ? Math.floor((duration % 3600) / 60) : 0;
    const s = duration ? duration % 60 : 0;

    return `${this.padTime(h)}:${this.padTime(m)}:${this.padTime(s)}`;
  }

  padTime(t) {
    return t < 10 ? '0' + t : t;
  }

  playNotificationsPing() {
    this.audioPing.src = '/assets/sounds/ping.mp3';
    this.audioPing.volume = 1;
    this.audioPing.play().then(r => r).catch(e => e);
  }

  checkLoadCommunication(msg) {
    if (msg.communication && this.entityInfo.entityId === JSON.parse(msg.communication).userPortalContactId && location.href.includes('jusnote-connect')) {
      this.communicationService.getCommunications(this.communicationPublicService.filters).subscribe();
    }
  }

  checkDocusignMsg(msg) {
    if (msg?.data) {
      const data = JSON.parse(msg.data);
      if (data?.documentId && location.href.includes(`documents/card/${data?.documentId}`)) {
        this.store.dispatch(new GetDocument(+data.documentId));
      }
    }
  }
}

