import { Injectable } from '@angular/core';
import { EMPTY, interval, Observable, of, Subscription } from 'rxjs';
import { TimeEntrySave, ActivityExtended } from '@app/models';
import { TimerApi } from '@app/api/timer.api';
import { TimeEntryApi } from '@app/api/time-entry.api';
import { TimeEntryParams } from '@app/models/interfaces/time-entry-params';
import { TimerView } from '@app/models/timer-view';
import { isNumber } from 'util';
import { shareReplay } from 'rxjs/operators';
import { AuthService } from '@app/auth/_services/auth.service';

interface CreateTimerOptions {
  timeEntry: TimeEntrySave | ActivityExtended;
  initialValue: number;
}

@Injectable()
export class TimekeeperService {
  static MYSQL_DATE_FORMAT = 'YYYY-MM-DD';
  private timer$: Observable<number> = interval(1000);

  public subscription: Subscription;

  public isPending = false;
  showLoader = false;

  activeTimeEntry: ActivityExtended;
  activeTimer = 0;
  isActive = false;
  dateRun = 0;
  timeEntries: ActivityExtended[];

  totalTime = 0;

  constructor(
    private timerApi: TimerApi,
    private timeEntryApi: TimeEntryApi,
    private authService: AuthService
  ) {}

  createTimer(params: CreateTimerOptions): Observable<TimerView> {
    this.showLoader = true;
    this.isPending = true;
    this.activeTimer = params.initialValue || 0;
    this.dateRun = new Date().getTime();
    this.activateTimer(this.activeTimer);
    let result: Observable<TimerView>;
    /**
     * We should
     */
    if (!isNumber(params.timeEntry) && !params.timeEntry.id) {
      result = this.timerApi.createTimer({
        timeEntry: params.timeEntry
      });
    } else {
      let payload;
      if (isNumber(params.timeEntry)) {
        this.activeTimeEntry = params.timeEntry as ActivityExtended;
        payload = params.timeEntry;
      } else {
        this.activeTimeEntry = this.timeEntries.find(t => t.id === params.timeEntry.id);
        payload = params.timeEntry.id;
      }
      result = this.timerApi.createTimer({
        timeEntry: payload
      });
    }
    result = result.pipe(shareReplay(1));
    return result;
  }

  removeTimer(id?: number): Observable<any> {
    this.isPending = true;
    this.showLoader = true;
    this.deactivateTimer();

    let result: Observable<any>;
    result = this.timerApi.stopTimer(id || this.activeTimeEntry.timer.id).pipe(shareReplay(1));
    return result;
  }

  fetchTimeEntries(params?: TimeEntryParams): Observable<any> {
    this.authService.refreshUser();
    if (this.authService.isExpiredRaw) {
      return of(EMPTY);
    }
    let result: Observable<ActivityExtended[]>;

    // TODO добавити фільтр по таймеру
    result = this.timeEntryApi.get({ ...params }).pipe(
      shareReplay({
        refCount: true,
        bufferSize: 1
      })
    );
    result.subscribe(res => {
      this.timeEntries = res;
      this.activeTimeEntry = null;

      this.totalTime = this.timeEntries.reduce((acc, item) => {
        return (acc += item.duration);
      }, 0);

      this.timeEntries.map(timeEntry => {
        if (timeEntry.timer) {
          this.activeTimeEntry = timeEntry;
          // this.activeTimer = this.countCurrentTimer(timeEntry);
          this.dateRun = new Date(timeEntry.timer.dateRun).getTime();
          this.activateTimer(timeEntry.duration);
        }
      });
    });

    return result;
  }
  countCurrentTimer(timeEntry: ActivityExtended) {
    const initialDuration = timeEntry.duration;
    let timerDuration;
    if (timeEntry.timer) {
      timerDuration = +(new Date().getTime() - new Date(timeEntry.timer.dateRun).getTime() + '').slice(0, -3);
    } else {
      timerDuration = 0;
    }
    return timerDuration + initialDuration;
  }

  refreshSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  activateTimer(duration) {
    this.refreshSubscription();
    this.subscription = this.timer$.subscribe(res => {
      this.activeTimer = +(new Date().getTime() - this.dateRun + '').slice(0, -3) + duration;
    });
    this.isActive = true;
  }

  deactivateTimer() {
    this.subscription?.unsubscribe();
    this.isActive = false;
    this.activeTimer = 0;
  }

  logout() {
    this.activeTimeEntry = null;
    this.activeTimer = 0;
    this.isActive = false;
    this.isPending = false;
    this.timeEntries = [];
    this.totalTime = 0;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
