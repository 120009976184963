import { TagActionEnum, TagActions } from '../actions/tag.actions';
import { initialTagState, ITagState } from '../state/tag.state';

export const tagReducers = (state = initialTagState, action: TagActions): ITagState => {
  switch (action.type) {
    case TagActionEnum.GET_ALL_TAGS_SUCCESS: {
      return {
        ...state,
        data: action.payload
      };
    }

    case TagActionEnum.GET_ALL_TAGS_PAGINATOR_SUCCESS: {
      if (action.payload == null) {
        return {
          ...state,
          paginator: {
            countItems: state.paginator.countItems,
            member: null,
            totalItems: state.paginator.totalItems,
            currentPage: state.paginator.currentPage,
            itemsPerPage: state.paginator.itemsPerPage,
            lastPage: state.paginator.lastPage
          }
        };
      }
      return {
        ...state,
        paginator: action.payload
      };
    }

    case TagActionEnum.ADD_TAG_SUCCESS: {
      return {
        ...state
      };
    }

    case TagActionEnum.UPDATE_TAG_SUCCESS: {
      return {
        ...state
      };
    }

    case TagActionEnum.DELETE_TAG_SUCCESS: {
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
