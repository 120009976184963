// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: false,
  syncfusionLicenseKey: 'Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxfeHVQRmBZUkF+W0U=',
  apiUrl: 'https://api-dev2.jusnote.com',
  idleTime: 108000, // seconds
  textSearchDebounce: 1500,
  defaultOptionsCount: 25,
  defaultItemsCount: 25,
  defaultMaxItemsCount: 10000,
  defaultPage: 1,
  countItemInSelect: 25,
  wsToken: 'https://api-dev2.jusnote.com/credentials',
  ws: 'wss://api-dev2.jusnote.com/centrifugo/connection/websocket',
  liqpay: {
    success: '/settings/subscription-complete',
    failure: '/settings/subscription-failure',
    fallback: '/settings/subscription',
    subscribedApps: '/settings/apps',
    url: 'https://www.liqpay.ua/api/3/checkout?data={{data}}&signature={{signature}}'
  },
  docViewer: 'google',
  loopItemsScroll: 10,
  decisionJuscourtUrl: 'https://tracker.juscourt.com/document/',
  juscourtUrl: 'https://tracker.juscourt.com/?caseNumb=',
  portalUrl: 'https://portal.jusnote.com/',
  debounceLoadTime: 300,
  itemsCountSearch: 15,
  countSearch: 6,
  paddleVendorId: 12476
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
