import { initialTaskState, ITaskState } from '../../store/state/task.state';
import { TaskActions, ETaskActions } from '../../store/actions/task.actions';

export const taskReducers = (state = initialTaskState, action: TaskActions): ITaskState => {
  switch (action.type) {
    case ETaskActions.CLEAR_TASKS: {
      return {
        ...state,
        selectedTask: null,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        }
      };
    }

    case ETaskActions.ADD_TASK_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }

    case ETaskActions.ADD_TASK: {
      return {
        ...state,
        selectedTask: null
      };
    }
    case ETaskActions.ADD_TASK_SUCCESS: {
      return {
        ...state,
        selectedTask: action.payload
      };
    }
    case ETaskActions.UPDATE_TASK_STATUS_SUCCESS: {
      if (state.selectedTask && state.selectedTask.id === action.payload.id) {
        state.selectedTask = action.payload;
      }
      return {
        ...state,
        paginator: {
          countItems: state.paginator.countItems,
          member: state.paginator.member ? state.paginator.member.filter(item => item.id !== action.payload.id) : [],
          totalItems: state.paginator.totalItems,
          currentPage: state.paginator.currentPage,
          itemsPerPage: state.paginator.itemsPerPage,
          lastPage: state.paginator.lastPage
        }
      };
    }
    case ETaskActions.UPDATE_TASK_SUCCESS: {
      return {
        ...state
      };
    }

    case ETaskActions.GET_TASKS_SUCCESS: {
      if (action.payload == null) {
        return {
          ...state,
          paginator: {
            countItems: state.paginator.countItems,
            member: null,
            totalItems: state.paginator.totalItems,
            currentPage: state.paginator.currentPage,
            itemsPerPage: state.paginator.itemsPerPage,
            lastPage: state.paginator.lastPage
          }
        };
      }
      return {
        ...state,
        paginator: action.payload
      };
    }

    case ETaskActions.GET_TASK_SUCCESS: {
      return {
        ...state,
        selectedTask: action.payload
      };
    }
    case ETaskActions.DELETE_TASK_SUCCESS: {
      return {
        ...state,
        paginator: {
          countItems: state.paginator.countItems,
          member: state.paginator.member ? state.paginator.member.filter(item => item.id !== action.payload) : [],
          totalItems: state.paginator.totalItems,
          currentPage: state.paginator.currentPage,
          itemsPerPage: state.paginator.itemsPerPage,
          lastPage: state.paginator.lastPage
        }
      };
    }
    default: {
      return state;
    }
  }
};
