// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
h1 {
  margin: 0 0 29px 0;
}

.input-container .weak-password-container {
  display: none;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}
.input-container .weak-password-container span {
  border-radius: 3px;
  background-color: #ebebeb;
  color: #db9330;
  font-size: 14px;
  font-weight: 600;
  margin-right: 6px;
  padding: 1px 15px 2px;
}
.input-container.password-weak .weak-password-container {
  display: block;
}

.check-pass {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.check-pass .item-check {
  display: flex;
  align-items: center;
  gap: 6px;
}
.check-pass .item-check span {
  letter-spacing: 0.14px;
  color: #707B86;
}
.check-pass .item-check::before {
  content: "─";
  width: 6px;
  overflow: hidden;
  position: absolute;
  color: #707B86;
}
.check-pass .item-check.active i {
  opacity: 1;
}
.check-pass .item-check.active::before {
  opacity: 0;
}
.check-pass .item-check i {
  font-size: 6px;
  opacity: 0;
}

.error-text-display {
  font-size: 13px;
  letter-spacing: 0.13px;
  color: #FF0000;
  display: block;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/components/password-create/password-create.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,kBAAA;AAEF;;AAEE;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACE,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AACN;AAII;EACE,cAAA;AAFN;;AAOA;EACE,aAAA;EACA,8BAAA;EACA,QAAA;AAJF;AAKE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAHJ;AAII;EACE,sBAAA;EACA,cAAA;AAFN;AAII;EACE,YAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AAFN;AAKM;EACE,UAAA;AAHR;AAKM;EACE,UAAA;AAHR;AAMI;EACE,cAAA;EACA,UAAA;AAJN;;AAUA;EACE,eAAA;EACA,sBAAA;EACA,cAAA;EACA,cAAA;EACA,mBAAA;AAPF","sourcesContent":["h1 {\n  margin: 0 0 29px 0;\n}\n\n.input-container {\n  .weak-password-container {\n    display: none;\n    color: #222222;\n    font-size: 14px;\n    font-weight: 400;\n    margin-bottom: 8px;\n\n    span {\n      border-radius: 3px;\n      background-color: #ebebeb;\n      color: #db9330;\n      font-size: 14px;\n      font-weight: 600;\n      margin-right: 6px;\n      padding: 1px 15px 2px;\n    }\n  }\n\n  &.password-weak {\n    .weak-password-container {\n      display: block;\n    }\n  }\n}\n\n.check-pass {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 5px;\n  .item-check {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    span {\n      letter-spacing: 0.14px;\n      color: #707B86;\n    }\n    &::before {\n      content: \"─\";\n      width: 6px;\n      overflow: hidden;\n      position: absolute;\n      color: #707B86;\n    }\n    &.active {\n      i {\n        opacity: 1;\n      }\n      &::before {\n        opacity: 0;\n      }\n    }\n    i {\n      font-size: 6px;\n      opacity: 0;\n    }\n  }\n\n}\n\n.error-text-display {\n  font-size: 13px;\n  letter-spacing: 0.13px;\n  color: #FF0000;\n  display: block;\n  margin-bottom: 15px;\n\n  &.error {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
