import { Component, OnInit, ViewChild } from '@angular/core';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from '@app/modules/reusable/jusnote-connect/services/communication.service';
import { AuthService } from '@app/auth/_services/auth.service';
import { DiscussionService } from '../discussion/services/discussion.service';
import { take, mergeMap, map } from 'rxjs/operators';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { DocumentExtended, Contact, TaskExtended } from '@app/models';
import { Bill } from '@app/models/bill';
import { AttachFilesToCommentModalComponent } from '@app/commonComponents/wide-modals/attach-files-to-comment-modal/attach-files-to-comment-modal.component';
import { selectContactFromStore } from '@app/modules/contacts/store/selectors/contact.selector';
import { CommunicationPublicService } from '../discussion/services/communication.service';
import { ICommunication } from '@app/models/jusnote-connect-massage';
import { ChatService } from '../discussion/services/chat.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { SwitchNotificationsService } from '@app/helpers';
import { GetDocumentsByTaskSuccess, AddDocuments } from '@app/store/actions/document.actions';
import { IComment } from '@app/models/comment';
import { Helpers } from '@app/helpers/helpers';
import { DocumentEffects } from '@app/store/effects/document.effects';
import { Subscription } from 'rxjs';
import { TasksEventsModalComponent } from '@app/commonComponents/wide-modals/task-event-modal/t-e-modal.component';

@Component({
  selector: 'app-new-message-communication',
  templateUrl: './new-message-communication.component.html',
  styleUrls: ['./new-message-communication.component.scss']
})
export class NewMessageCommunicationComponent implements OnInit {
  constructor(
    private matterService: MatterService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private toastrService: ToastrService,
    private communicationService: CommunicationService,
    private authService: AuthService,
    private discussionService: DiscussionService,
    private communicationPublicService: CommunicationPublicService,
    private chatService: ChatService,
    public switchNotificService: SwitchNotificationsService,
    private documentEffects: DocumentEffects
  ) {
    this.communicationPublicService.onCommunication.pipe(take(1)).subscribe(communication => {
      this.currentCommunication = communication;
      this.getCommentForCurrentCommunication(communication);
    });

    this.filters = this.communicationPublicService.filters;
    this.communicationPublicService.onFilters.subscribe(filters => {
      this.filters = filters;
    });
  }

  filters;
  visibleMatter = false;
  showLoader = false;
  dontReaded = [];
  readedMsg = false;
  attachedFiles: { documents: DocumentExtended[]; bills: Bill[] };
  contact: Contact;
  editExtendedButton;
  matters = [];
  searchStr = '';
  selectedMatter;
  documents = [];
  content;
  communicationThema = '';
  dataContent;

  currentCommunication: ICommunication;
  comments: IComment[];

  moreControlsButton = false;

  currentUser;

  @ViewChild('listComments') listComments;
  loadedComponent = false;

  selectedNewDocuments;

  doumentSubscriber = new Subscription();

  sel;
  range;
  editableComment: IComment;

  ngOnInit(): void {
    this.getContactFromStore();
    this.currentUser = this.authService.getPayload();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.currentCommunication) {
        this.loadedComponent = true;
      }
    });
    const contentTxt = document.getElementById('content-text');
    contentTxt.setAttribute('data-placeholder', this.translate.instant('startTypingHere'));
  }

  ngOnDestroy() {
    this.doumentSubscriber.unsubscribe();
  }

  getCommentForCurrentCommunication(communication: ICommunication) {
    this.openSpinner();
    this.discussionService
      .get({
        entityName: 'communication',
        entityId: communication.id
      })
      .pipe(take(1))
      .subscribe(comments => {
        this.comments = comments.map(item => {
          item['parseMsg'] = '';
          return item;
        });
        for (const comment of comments) {
          this.chatService.jsonToHtml(comment, comment.content);
          if (!comment.isReaded) {
            if (!this.dontReaded.find(item => item.id === comment.id)) {
              this.dontReaded.push(comment.id);
            }
          }
          this.loadedComponent = true;
        }
        setTimeout(() => {
          if (this.dontReaded.length && !this.readedMsg) {
            this.readedMsg = true;
            this.discussionService
              .pinnedComment(this.currentUser.id, { seenComments: this.dontReaded })
              .pipe(take(1))
              .subscribe(() => {
                this.communicationPublicService.listCommunication.member.forEach(item => {
                  if (item.id === this.currentCommunication.id) {
                    item.lastComment.isReaded = true;
                  }
                });
                for (const item of this.comments) {
                  item.isReaded = true;
                }
              });
          }
        }, 1000);
        setTimeout(() => {
          this.hideSpinner();
          if (this.listComments) {
            this.listComments.nativeElement.scrollTop = this.listComments.nativeElement.scrollHeight - 430;
            this.listComments.nativeElement.style = 'scroll-behavior: smooth;';
          }
          if (this.comments?.length) {
            const contentTxt = document.getElementById('content-text');
            contentTxt.setAttribute('data-placeholder', this.translate.instant('contacts.new-message.textarea-massaging'));
          }
        });
      });
  }

  findMatterByName(e) {
    setTimeout(() => {
      this.searchStr = e.target.value?.trim();
      if (this.searchStr.length >= 2) {
        this.matterService.get(1, 10, { name: this.searchStr }).subscribe(res => {
          this.matters = res.member;
          this.visibleMatter = true;
        });
      }
    }, 0);
  }

  selectMatter(matter) {
    const matterInput = document.getElementById('matter-input') as HTMLInputElement;
    matterInput.value = matter.name;
    this.selectedMatter = matter;
    this.visibleMatter = false;
    this.searchStr = '';
  }

  removeDoc(doc) {
    this.attachedFiles.documents = this.attachedFiles.documents.filter(item => item.id !== doc.id);
  }
  removeNewDoc(newDoc) {
    this.selectedNewDocuments = this.selectedNewDocuments.filter(item => item.document.uid !== newDoc.document.uid);
  }

  removeBill(bill) {
    this.attachedFiles.bills = this.attachedFiles.bills.filter(item => item.id !== bill.id);
  }

  confirmCloseModal(modalRef: MatDialogRef<any>) {
    const confirmDialogConfig = new MatDialogConfig();
    // confirmDialogConfig.disableClose=false;
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
  attachFiles() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.panelClass = ['attach-files-to-comment-modal'];
    dialogConfig.data = {
      selectedDocuments: this.attachedFiles ? this.attachedFiles.documents : [],
      selectedBills: this.attachedFiles ? this.attachedFiles.bills : [],
      selectedNewDocuments: this.selectedNewDocuments,
      contact: this.contact
    };
    const dialogRef = this.dialog.open(AttachFilesToCommentModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.attachedFiles = { documents: res.documents, bills: res.bills };
        this.selectedNewDocuments = res.selectedNewDocuments;
      }
    });
  }

  getContactFromStore() {
    this.store.select(selectContactFromStore).subscribe(contact => {
      this.contact = contact;
    });
  }
  onSubmitClick = false;
  currentMsg = '';
  onSubmit(published) {
    this.editExtendedButton = false;
    const contentTxt = document.getElementById('content-text');
    this.onSubmitClick = true;
    if (!this.currentCommunication && !this.communicationThema.trim()) {
      return;
    }
    if (this.currentMsg.trim()) {
      const div = contentTxt.firstChild as any;
      const newP = document.createElement('p');
      newP.innerHTML = div && (div?.innerHTML ? div.innerHTML : div.nodeValue);
      div.parentNode.replaceChild(newP, div);
      this.dataContent = [];
      this.chatService.htmlToJson(contentTxt.childNodes as any, this.dataContent);
      contentTxt.innerHTML = '';
      // post com
      if (!this.currentCommunication) {
        this.saveCommunication(published);
      } else {
        if (this.editableComment) {
          this.editComment(this.editableComment, published).subscribe(comment => {
            if (this.selectedNewDocuments) {
              this.saveDocuments(comment);
            } else {
              this.getCommentForCurrentCommunication(this.currentCommunication);
            }
          });
        } else {
          this.saveComments(this.currentCommunication, published).subscribe(() => {
            this.communicationService.getCommunications(this.filters).subscribe();
            this.getCommentForCurrentCommunication(this.currentCommunication);
          });
        }
      }
    }
  }

  saveCommunication(published) {
    let newCommunication;
    this.communicationService
      .postCommunication({
        name: this.communicationThema,
        published,
        userPortal: this.contact.contactPerson.user.id,
        matter: this.selectedMatter?.id,
        isReaded: this.authService.currentUserValue.id === this.contact.contactPerson.user.id
      })
      .pipe(
        mergeMap(communication => {
          newCommunication = communication;
          return this.saveComments(communication, published);
        })
      )
      .subscribe(() => {
        this.communicationPublicService.setCommunication(newCommunication);
        this.attachedFiles = { documents: [], bills: [] };
        this.communicationService.getCommunications(this.filters).subscribe();
        this.getCommentForCurrentCommunication(this.currentCommunication);
      });
  }

  saveComments(communication, published) {
    return this.discussionService
      .setComment({
        content: this.dataContent,
        entityId: communication.id,
        entityName: 'communication',
        documents: this.attachedFiles?.documents.map(item => item.id),
        bills: this.attachedFiles?.bills.map(item => item.id),
        published
      })
      .pipe(
        map(resp => {
          const contentTxt = document.getElementById('content-text');
          contentTxt.innerHTML = '';
          this.attachedFiles = { documents: [], bills: [] };
          if (this.selectedNewDocuments) {
            this.saveDocuments(resp);
          }

          return resp;
        })
      );
  }
  saveDocuments(comment: IComment) {
    this.store.dispatch(new AddDocuments(this.selectedNewDocuments));
    this.selectedNewDocuments = [];
    const newDocuments = [];
    this.doumentSubscriber = this.documentEffects.addDocuments$.subscribe((res: any) => {
      for (const addDocSuccess of res.payload) {
        newDocuments.push(addDocSuccess.payload);
      }
      this.editComment(comment, comment.published, comment.documents.concat(newDocuments)).subscribe(res => {
        this.getCommentForCurrentCommunication(this.currentCommunication);
      });
    });
  }

  editComment(comment, published, documents = []) {
    if (this.currentCommunication && this.comments?.length === 1 && this.currentCommunication?.published !== published) {
      this.currentCommunication.published = published;
      this.communicationService.putCommunication(this.currentCommunication).subscribe();
    }
    return this.discussionService
      .updateComment({
        content: this.dataContent,
        entityId: comment.entityId.id,
        entityName: 'communication',
        documents: documents.concat(this.attachedFiles?.documents.map(item => item.id)),
        bills: this.attachedFiles?.bills.map(item => item.id),
        published,
        id: comment.id
      })
      .pipe(
        map(resp => {
          const contentTxt = document.getElementById('content-text');
          contentTxt.innerHTML = '';
          this.attachedFiles = { documents: [], bills: [] };
          this.editableComment = null;
          this.communicationService.getCommunications(this.filters).subscribe();
          this.getCommentForCurrentCommunication(this.currentCommunication);
          return resp;
        })
      );
  }

  getCustomDate(date) {
    return CustomDateAdapter.convertToUniversalDottedString(date, 'o');
  }

  publishComment(comment, published) {
    if (this.currentCommunication && this.comments?.length === 1 && this.currentCommunication?.published !== published) {
      this.currentCommunication.published = published;
      this.communicationService.putCommunication(this.currentCommunication).subscribe();
    }
    comment.published = published;
    this.discussionService.updateComment(comment).subscribe(() => {
      this.getCommentForCurrentCommunication(this.currentCommunication);
      this.communicationService.getCommunications(this.filters).subscribe();
    });
  }

  deleteComment(comment) {
    if (this.comments.length === 1) {
      this.deleteCommunicaton();
    } else {
      this.discussionService.deleteComment(comment.id).subscribe(() => {
        this.getCommentForCurrentCommunication(this.currentCommunication);
      });
    }
  }

  deleteCommunicaton() {
    this.communicationService.deleteCommunication(this.currentCommunication).subscribe(res => {
      this.switchNotificService.switchNotifications('communication');
      this.communicationService.getCommunications(this.filters).subscribe();
    });
  }

  unPublishCommunication() {
    this.currentCommunication.published = false;
    this.communicationService.putCommunication(this.currentCommunication).subscribe(res => {
      this.currentCommunication = res;
    });
  }

  tasksModal(task?: TaskExtended, duplicate?: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      task,
      duplicate,
      type: 'task'
    };
    dialogConfig.panelClass = ['default-mat-dialog', 'tasks-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(new GetDocumentsByTaskSuccess([]));
      });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  eventsModal(task?: TaskExtended, duplicate?: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      task,
      duplicate,
      type: 'event'
    };
    dialogConfig.panelClass = ['default-mat-dialog', 'events-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(new GetDocumentsByTaskSuccess([]));
      });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  inputComment(e) {
    this.currentMsg = e.target.innerText;
  }

  checkPaste(e) {
    this.sel = window.getSelection();
    this.range = this.sel.getRangeAt(0);
    this.range.deleteContents();
    this.currentMsg = e.clipboardData.getData('text/plain').replace(/\n|\t/g, '<br>');
    this.pasteHtmlAtCaret(this.currentMsg);
    return false;
  }

  onEditComment(comment: IComment) {
    comment['open'] = false;
    const contentTxt = document.getElementById('content-text');
    this.editableComment = comment;
    contentTxt.innerHTML = comment.parseMsg;
    this.currentMsg = comment.parseMsg;
    this.attachedFiles = { documents: comment.documents.slice(0), bills: comment.bills.slice(0) };
  }

  linkTransform(str) {
    return Helpers.linkTransform(str);
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  pasteHtmlAtCaret(html) {
    if (window.getSelection) {
      // IE9 and non-IE

      if (this.sel.getRangeAt && this.sel.rangeCount) {
        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        const el = document.createElement('div');
        el.innerHTML = html;
        let frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        this.range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          this.range = this.range.cloneRange();
          this.range.setStartAfter(lastNode);
          this.range.collapse(true);
          this.sel.removeAllRanges();
          this.sel.addRange(this.range);
        }
      }
    } else if (document['selection'] && document['selection'].type !== 'Control') {
      // IE < 9
      document['selection'].createRange().pasteHTML(html);
    }
  }
}
