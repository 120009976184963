import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserDisabledModalComponent } from '@app/commonComponents/modals/system-modals/user-disabled-modal/user-disabled-modal.component';
import { ExpireModalComponent } from '@app/commonComponents/modals/system-modals/expire-modal/expire-modal.component';
import { Logout } from '@app/auth/_store/actions/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/auth/_services/auth.service';
import { Router } from '@angular/router';

export interface ErrorResponse {
  success: false;
  errors: ErrorPayload[];
}

export interface ErrorPayload {
  code: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {
  /**
   * Without `bind(this)` function will crush, because it doesn't see Toastr context
   */
  private errorHandleMap = {
    'security.unauthorized': this.securityUnauthorized.bind(this),
    'kernel.not_found': this.kernelNotFound.bind(this),
    'security.user_disabled': this.securityUserDisabled.bind(this),
    'security.subscriber_expired': this.securitySubscriberExpired.bind(this),
    'security.access_denied': this.securityAccessDenied.bind(this)
  };
  hasCritical: boolean;
  private dialogRef: MatDialogRef<any, any>;

  constructor(
    private authenticationService: AuthService,
    private toastr: ToastrService,
    private store: Store<IAppState>,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService
  ) {}

  handle(errorResponse: ErrorResponse) {
    if (errorResponse['@type'] === 'hydra:Error') {
      this.toastr.error(errorResponse['hydra:description'], null, { closeButton: true, timeOut: 5000 });
    } else if(errorResponse?.['code'] === 401) {
      this.toastr.error(errorResponse?.['message'], null, { closeButton: true, timeOut: 5000 });
    } else {
      const [error] = errorResponse.errors;

      if (this.errorHandleMap[error.code]) {
        this.errorHandleMap[error.code](error);
      } else {
        this.fallbackResolver(error);
      }
    }

  }

  private fallbackResolver(errorPayload: ErrorPayload) {
    this.toastr.error(this.translate.instant(errorPayload.code), null, { closeButton: true, timeOut: 5000 });
  }

  private securityUnauthorized(errorPayload: ErrorPayload) {
    const message = errorPayload.message.split(' ');
    // if (message[message.length - 1] === 'invalid.') {
    //   return;
    // }
    this.authenticationService.logout();

    this.toastr.error(this.translate.instant(errorPayload.code), null, {
      closeButton: true,
      timeOut: 5000
    });
    this.authenticationService.setShowRefreshModal();
    return throwError(errorPayload.message);
  }

  private kernelNotFound(errorPayload: ErrorPayload) {
    this.toastr.error(this.translate.instant(errorPayload.code), null, { closeButton: true, timeOut: 5000 });
  }

  private securityUserDisabled(errorPayload: ErrorPayload) {
    if (this.dialogRef) {
      return;
    }

    this.store.dispatch(new Logout());
    this.router.navigateByUrl('/auth/login');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];
    dialogConfig.backdropClass = 'mat-background-blur';
    this.dialogRef = this.dialog.open(UserDisabledModalComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(() => (this.dialogRef = undefined));
  }

  private securitySubscriberExpired(errorPayload: ErrorPayload) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];
    dialogConfig.backdropClass = 'mat-background-blur';
    this.dialog.open(ExpireModalComponent, dialogConfig);
  }

  private securityAccessDenied(errorPayload: ErrorPayload) {
    this.toastr.error(this.translate.instant(errorPayload.code), null, { closeButton: true, timeOut: 5000 });
  }
}
