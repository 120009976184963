import { Injectable } from '@angular/core';
import { TariffsApi } from '@app/api/tariffs.api';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { Subscription } from '@app/models/subscription';
import { Observable } from 'rxjs';
import { Tariff } from '@app/models/tariff';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { Store } from '@ngrx/store';
import { CompanySettings } from '@app/models/company-settings';
import { IAppState } from '@app/store/state/app.state';
import { LiqPay } from '@app/models/liq-pay';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ResponseBodyFull } from '@app/models';
import { AuthService } from '@app/auth/_services/auth.service';
import { checkRole } from '@app/helpers/check-role';
import { TeamMemberService } from './team-member.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { SubscriptionSub } from '@app/models/subscription-sub';
import { SubscriptionOrder } from '@app/models/subscription-order';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  constructor(
    private tariffsApi: TariffsApi,
    private http: HttpClient,
    private store: Store<IAppState>,
    private authService: AuthService,
    private teamMemberService: TeamMemberService
  ) {
    this.store.select(selectCompanySetting).subscribe(data => {
      if (data) {
        this.companySettings = data;
        // this.companyCurrency = this.companySettings.currency.code;
      }
    });

    this.teamMemberService
      .get(1, 1, {
        status: 'active',
        'companyAccesses.enable': true
      })
      .subscribe(res => {
        if (res.member) {
          this.activeUsers = res.totalItems;
          this.activeUsers$ = res.totalItems;
          this.loadCurrentSubscription();
        }
      });
  }
  public tariffs$: Observable<Tariff[]> = this.fetchTariffs().pipe(
    shareReplay(1),
    map(t => t['hydra:member'])
  );
  public tariffs: Tariff[] = [];

  public availableTariffs: Tariff[];

  public currentSubscription: Subscription;

  public selectedTariff: Tariff;
  public selectedUsers: number;
  public selectedBilling = 'year';

  public companyCurrency = 'EUR';

  public companySettings: CompanySettings;

  public activeUsers: number;
  public activeUsers$: number;

  public callbackSubscription: Subscription;

  private initialDataLoaded = false;

  private SUBSCRIPTIONS_ENDPOINT = `${environment.apiUrl}/subscriptions`;
  private SUBSCRIPTIONS_SUB_ENDPOINT = `${environment.apiUrl}/subscription_subs`;

  loadCurrentSubscription() {
    if (checkRole(['ROLE_SITE_SUBSCRIPTIONS_MANAGER'])) {
      this.getCurrentSubscription().subscribe(res => {
        this.currentSubscription = res;
      });
    }
  }

  fetchTariffs() {
    return this.tariffsApi.getAll().pipe(shareReplay(1));
  }

  getTariff(id: number) {
    return this.availableTariffs.find(t => t.id === id);
  }

  getTotal(sub: Subscription) {
    if (!sub) {
      return 0;
    }
    // @ts-ignore
    const tariff = this.getTariff(sub.tariff.id);
    // @ts-ignore
    const price = tariff.prices.find(p => p.currency.code === sub.currency.code && p.period === sub.period);
    const billingType = sub.period === 'year' ? 12 : 1;
    return sub.countActiveUser * parseFloat(price.amount) * billingType;
  }

  extractPrice(tariff?: Tariff, period = 'month'): number {
    let localTariff = tariff;
    if (!tariff) {
      localTariff = this.selectedTariff;
    }
    if (!localTariff) {
      return 0;
    }
    const companyCurrency = 'EUR'; // this.companySettings.currency.code;
    return +localTariff.prices.find(p => p.currency.code === companyCurrency && p.period === period).amount;
  }

  payCreditCard() {
    this.createSubscription(this.buildSubscriptionPayload()).subscribe(res => {
      if (!res) {
        return;
      }
      this.callbackSubscription = res;
      if (!(this.callbackSubscription.tariff as Tariff).name) {
        this.callbackSubscription.tariff = this.getTariff((this.callbackSubscription.tariff as Tariff).id);
      }
    });
  }

  buildSubscriptionPayload(): Subscription {
    const subscription = {
      countActiveUser: this.selectedUsers,
      period: this.selectedBilling,
      tariff: this.selectedTariff.id,
      contact: this.companySettings.payer.id,
      currency: this.companyCurrency
    };
    return subscription;
  }

  payLiqPay() {
    this.getLiqPay(this.callbackSubscription.id).subscribe(liqpay => {
      const url = this.buildLiqPayCheckoutLink(liqpay);
      window.location.href = url;
      // window.open(url);
    });
  }
  payPaddle() {
    this.getPaddle(this.callbackSubscription.id).subscribe(paddle => {
      window.location.href = paddle.paddle_redirect_url;
      // window.open(url);
    });
  }
  getPayPaddleLink() : Observable<string> {
    return this.getPaddle(this.callbackSubscription.id).pipe(map(paddle => {
      return paddle.paddle_redirect_url;
      // window.open(url);
    })
    );
  }

  buildLiqPayCheckoutLink(liqpay: LiqPay) {
    if (!liqpay) {
      throw Error('No LiqPay data get from backend call back');
    }
    return `https://www.liqpay.ua/api/3/checkout?data=${liqpay.data}&signature=${liqpay.signature}`;
  }

  resetPaymentPage() {
    this.selectedBilling = 'year';
    this.selectedTariff = null;
    this.selectedUsers = 1;
    this.callbackSubscription = null;
  }

  invoiceURL(hash: string) {
    return `${this.SUBSCRIPTIONS_ENDPOINT}/${hash}/invoice`;
  }

  daysLeft() {
    this.authService.refreshUser();
    const user = this.authService.currentUserValue;
    const currentTime = moment().utc();
    const expirationTime = moment(user.expiredAt);

    return expirationTime.diff(currentTime, 'days');
  }

  cancelSubscription(id) {
    return this.unsubscribe(id);
  }

  loadData() {
    if (this.initialDataLoaded) {
      return;
    }
    this.tariffs$.subscribe(tariffs => {
      this.tariffs = tariffs;
      this.availableTariffs = tariffs.filter(ta => ta.id !== 1);
    });
    this.initialDataLoaded = true;
  }

  getSubscription(): Observable<Subscription[]> {
    return this.http.get<ResponseBodyFull<Subscription>>(`${environment.apiUrl}/subscriptions?status[]=active&status[]=disable`)
    .pipe(map(item => (item['hydra:member'])));
  }

  getSubscribedSubscription(): Observable<Subscription[]> {
    return this.http.get<ResponseBodyFull<Subscription>>(`${environment.apiUrl}/subscriptions?subscribed=true`)
    .pipe(map(item => (item['hydra:member'])));
  }

  getCurrentSubscription(): Observable<Subscription> {
    this.authService.refreshUser();
    if (this.authService.currentUserValue.currentSubscriptionId && this.authService.currentUserValue.tariffId !== 1) {
      return this.http.get<Subscription>(
        `${environment.apiUrl}/subscriptions/${this.authService.getPayload().currentSubscriptionId}`
      );
    } else {
      return new Observable<Subscription>();
    }
  }

  createSubscription(payload: Subscription): Observable<Subscription> {
    return this.http.post<Subscription>(this.SUBSCRIPTIONS_ENDPOINT, payload);
  }

  getById(id: string, action): Observable<Subscription | SubscriptionSub> {
    // коли йде доплата
    if (action === 'pay') {
      // коли в під підписці йде доплата
      if (id.includes('-sub')) {
        const expireAtDate = CustomDateAdapter.convertToUniversalDateString(new Date());
        return this.http
          .get<ResponseBodyFull<SubscriptionSub>>(
            `${this.SUBSCRIPTIONS_SUB_ENDPOINT}?status[]=active&status[]=disable&expiredAt[after]=${expireAtDate}`
          )
          .pipe(map(res => res['hydra:member'][0]));
      } else {
        // в основній підписці доплата
        return this.http
          .get<Subscription>(`${environment.apiUrl}/subscriptions/${this.authService.getPayload().currentSubscriptionId}`);
      }
    } else {
      if (id.includes('-sub')) {
        return this.http
          .get<SubscriptionSub>(`${this.SUBSCRIPTIONS_SUB_ENDPOINT}/${id.replace('-sub', '')}`);
      } else {
        return this.http.get<Subscription>(`${this.SUBSCRIPTIONS_ENDPOINT}/${id}`);
      }
    }
  }

  getSubsPaddle(): Observable<Subscription> {
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        return this.getSub();
      }));
  }

  getSub(): Observable<Subscription> {
    return this.http
      .get<Subscription>(`${environment.apiUrl}/subscriptions/${this.authService.getPayload().currentSubscriptionId}`)
      .pipe(map(res => res), catchError(() => {
        return this.authService.refreshToken().pipe(
          switchMap(() => {
            return this.getSub();
          }))
      }))
  }

  getLiqPay(id: number): Observable<LiqPay> {
    return this.http.get(`${this.SUBSCRIPTIONS_ENDPOINT}/${id}/liq-pay`).pipe(map((resp: any) => resp.data));
  }
  getPaddle(id: number) {
    return this.http.get<any>(`${this.SUBSCRIPTIONS_ENDPOINT}/${id}/paddle/redirect-url`).pipe(map((resp: any) => resp.data));
  }
  unsubscribe(id) {
    // todo determine LiqPay and PayPal in future
    return this.http.patch(`${this.SUBSCRIPTIONS_ENDPOINT}/${id}/unsubscribe`, {});
  }

  createSubscriptionOrder(payload): Observable<SubscriptionOrder> {
    return this.http.post<SubscriptionOrder>(`${environment.apiUrl}/subscriptions/upgrade`, payload);
  }

  liqPayUpgradeData(subscription_order_id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/liq-pay/${subscription_order_id}/get-upgrade-data`);
  }
}

export interface LiqPayAddPaymentPayload {
  subscription: number;
  countActiveUser: number;
}
