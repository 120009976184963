import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { Login, LoginSuccess } from '@app/auth/_store/actions/auth.actions';
import { AuthEffects } from '@app/auth/_store/effects/auth.effects';
import { IUser } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<LoginModalComponent>,
    private store: Store<IAppState>,
    private authEffect: AuthEffects,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {
    this.authEffect.login$.subscribe(loginData => {

      if (loginData.payload['detail'] === 'Two factor authentication false') {
        this.codeFail = true;
      } else {
        const payload = loginData.payload;
        this.two_factor_authentication = payload['2fa'];
        if (this.two_factor_authentication) {
          this.loginForm.get('2fa').setValidators([
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(5)]);
          this.submitted = false;
        }
      }
      if (this.resendCode) {
        this.toastrService.success(this.translate.instant('login.two_factor_code.resendPopup'));
      }

      this.hideSpinner();
      this.checkClickSubmit = false;
      if ((loginData as LoginSuccess).payload && (loginData as LoginSuccess).payload.token) {
        this.dialogRef.close({ status: true, link: false });
      }
      if ((loginData as LoginSuccess).payload && !(loginData as LoginSuccess).payload.success) {
        this.hideSpinner();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  // error = '';
  spinnerName = 'login-modal-spinner';
  checkClickSubmit = false;
  showLoader = false;

  two_factor_authentication = false;
  codeFail = false;
  resendCode = false;
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      ['2fa']: ['']
    });
  }

  onSubmit(resend = false): void {
    this.resendCode = resend;
    this.submitted = !resend;
    if ((this.loginForm.invalid || this.checkClickSubmit) && !resend) {
      return;
    }
    const payload = {
      username: this.f.username.value,
      password: this.f.password.value,
      fromModal: true
    } as IUser;
    if (this.two_factor_authentication && !resend) {
      payload['2fa'] = this.f['2fa'].value;
    }

    this.openSpinner();
    this.checkClickSubmit = true;
    this.store.dispatch(new Login(payload));
  }

  linkTo(routingLink) {
    this.dialogRef.close({ status: false, link: routingLink });
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }
  pasteCode() {
    setTimeout(() => {
      this.onSubmit();
    }, 100);
  }
}
