import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Payment } from '@app/models/payment';
import { UpdatePayment } from '@app/store/actions/payment.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';

@Component({
  selector: 'app-edit-transaction-modal',
  templateUrl: './edit-transaction-modal.component.html',
  styleUrls: ['./edit-transaction-modal.component.scss']
})
export class EditTransactionModalComponent implements OnInit {
  transactionForm: UntypedFormGroup;
  item: Payment;
  submittedForm = false;

  constructor(
    public dialogRef: MatDialogRef<EditTransactionModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {}

  ngOnInit() {
    this.createForm();
    this.initFormValues();
  }

  closeModal() {
    this.dialogRef.close();
  }

  private createForm() {
    this.transactionForm = this.formBuilder.group({
      // amount: this.formBuilder.control(this.data.amount, [Validators.required])
      amount: this.formBuilder.control('', [Validators.required])
    });
  }

  checkSubmit() {
    this.submittedForm = true;
  }

  get f() {
    return this.transactionForm;
  }

  private initFormValues() {
    this.transactionForm.get('amount').setValue(this.data.amount);
  }

  saveTransaction() {
    this.checkSubmit();
    this.transactionForm.updateValueAndValidity();
    this.item = {
      amount: this.transactionForm.get('amount').value + ''
    };
    if (+this.item.amount > 0) {
      this.store.dispatch(new UpdatePayment(this.data.paymentId, this.item));
      this.dialogRef.close();
    } else {
      this.transactionForm.get('amount').setValue('');
    }
    if (!this.transactionForm.valid) {
      return;
    }
  }
}
