import { Action } from '@ngrx/store';
import { Payment } from '@app/models/payment';
import { Paginator } from '@app/models/paginator';
import { environment } from '@environments/environment';
import { TransactionFilters } from '@app/models/transaction-filters';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { Transaction, TransactionSummary } from '@app/models/transaction';

export enum EPaymentActions {
  CREATE_PAYMENT = '[Payment] Create',
  CREATE_PAYMENT_SUCCESS = '[Payment] Created',
  UPDATE_PAYMENT = '[Payment] Update',
  UPDATE_PAYMENT_SUCCESS = '[Payment] Updated',
  SELECT_PAYMENT_FROM_STORE = '[Payment] Select',
  GET_PAYMENTS = '[Payment] Fetch',
  GET_PAYMENTS_SUCCESS = '[Payment] Fetched',
  REMOVE_PAYMENT = '[Payment] Remove',
  FETCH_TRANSACTIONS = '[Payment] Fetch transactions',
  FETCHED_TRANSACTION_SUCCESS = '[Payment] Fetched transactions',
  FETCHED_TRANSACTION_SUMMARY_SUCCESS = '[Payment] Fetched Summary transactions'

}

export class CreatePayment implements Action {
  public readonly type = EPaymentActions.CREATE_PAYMENT;

  constructor(public payload: Payment) {}
}

export class CreatePaymentSuccess implements Action {
  public readonly type = EPaymentActions.CREATE_PAYMENT_SUCCESS;

  constructor(public payload: Payment) {}
}

export class UpdatePayment implements Action {
  public readonly type = EPaymentActions.UPDATE_PAYMENT;

  constructor(public id: number, public payload: Payment) {}
}

export class UpdatePaymentSuccess implements Action {
  public readonly type = EPaymentActions.UPDATE_PAYMENT_SUCCESS;

  constructor(public payload: Payment) {}
}

export class GetPaymentFromStore implements Action {
  public readonly type = EPaymentActions.SELECT_PAYMENT_FROM_STORE;

  constructor(public payload: number) {}
}

export class GetPayments implements Action {
  public readonly type = EPaymentActions.GET_PAYMENTS;

  constructor(public payload: number, public page = environment.defaultPage, public itemsPerPage = environment.defaultOptionsCount) {}
}

export class FetchTransactions implements Action {
  public readonly type = EPaymentActions.FETCH_TRANSACTIONS;

  constructor(public payload: FetchPayload<TransactionFilters>) {}
}

export class GetPaymentsSuccess implements Action {
  public readonly type = EPaymentActions.GET_PAYMENTS_SUCCESS;

  constructor(public payload: Paginator<Payment>) {}
}

export class RemovePayment implements Action {
  public readonly type = EPaymentActions.REMOVE_PAYMENT;

  constructor(public payload: number, public bill?: number) {}
}

export class FetchedTransactions implements Action {
  public readonly type = EPaymentActions.FETCHED_TRANSACTION_SUCCESS;

  constructor(public payload: Paginator<Transaction>) {}
}

export class FetchedTransactionsSummary implements Action {
  public readonly type = EPaymentActions.FETCHED_TRANSACTION_SUMMARY_SUCCESS;

  constructor(public payload: TransactionSummary) {}
}

export type PaymentActions =
  | CreatePayment
  | CreatePaymentSuccess
  | GetPaymentFromStore
  | GetPayments
  | GetPaymentsSuccess
  | RemovePayment
  | UpdatePaymentSuccess
  | UpdatePayment
  | FetchTransactions
  | FetchedTransactions
  | FetchedTransactionsSummary;
