import { Action } from '@ngrx/store';
import { INews } from '@app/models/interfaces/news.interface';
import { Paginator } from '@app/models/paginator';

export enum ENewsActions {
  GET_NEWS = '[News] Get News',
  GET_NEWS_SUCCESS = '[News] Get News Success',
  ADD_NEWS = '[News] Add',
  LIKE_NEWS = '[News] Like',
  READ_NEWS = '[News] Read'
}

export class GetNews implements Action {
  public readonly type = ENewsActions.GET_NEWS;
  constructor(public payload: any) {}
}
export class GetNewsSuccess implements Action {
  public readonly type = ENewsActions.GET_NEWS_SUCCESS;
  constructor(public payload: Paginator<INews>, public currentPage?: number, public renew?: boolean) {}
}

export class AddNews implements Action {
  public readonly type = ENewsActions.ADD_NEWS;
  constructor(public payload: { seq: number; data: INews }) {}
}

export class LikeNews implements Action {
  public readonly type = ENewsActions.LIKE_NEWS;
  constructor(public payload: any) {}
}

export class ReadNews implements Action {
  public readonly type = ENewsActions.READ_NEWS;
  constructor(public payload: any) {}
}

export type NewsActions = GetNews | GetNewsSuccess | AddNews | LikeNews | ReadNews;
