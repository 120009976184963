import { Paginator } from '../../../../models/paginator';

import { RelationshipType } from '../../../../models/index';

export interface IRelationshipTypeState {
  paginator: Paginator<RelationshipType>;
  selectedRelationshipType: RelationshipType;
  list: Array<RelationshipType>;
  loading: boolean;
  error: Error;
}

export const initialRelationshipTypeState: IRelationshipTypeState = {
  paginator: null,
  selectedRelationshipType: null,
  list: null,
  loading: false,
  error: undefined
};
