// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1252af;
  border: 2px solid #fff;
  position: absolute;
  top: -4px;
  left: -4px;
}

.stages {
  max-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/reusable/matters/components/matters/matters.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,sBAAA;EAEA,kBAAA;EACA,SAAA;EACA,UAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":["\n.circle {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: #1252af;\n  border: 2px solid #fff;\n\n  position: absolute;\n  top: -4px;\n  left: -4px;\n}\n\n.stages {\n  max-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
