import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map, catchError } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { Paginator } from '@app/models/paginator';
import { ICommunicationFilters, ICommunication } from '@app/models/jusnote-connect-massage';
import { CommunicationPublicService } from '@app/modules/layout/discussion/services/communication.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  constructor(private http: HttpClient, private authService: AuthService, private communicationPublicService: CommunicationPublicService) {}

  private BASE_URL = `${environment.apiUrl}/communications`;

  getCommunications(filters: ICommunicationFilters = null): Observable<Paginator<ICommunication>> {
    let params = new HttpParams().set('partial', 'false');

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    return this.http
      .get<ResponseBodyFull<ICommunication>>(this.BASE_URL, { params })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})))
      .pipe(
        map(response => {
          this.communicationPublicService.setCommunications(response.member);
          return {...response, member: response.member};
        }),
        catchError((error: any) => {
          return new BehaviorSubject<any>(error.error).asObservable();
        })
      );
  }
  getCommunicationById(communicationId) {
    return this.http.get<ICommunication>(`${this.BASE_URL}/${communicationId}`).pipe(
      map(response => {
        this.communicationPublicService.setCommunications(response);
        return response;
      })
    );
  }

  postCommunication(communication) {
    return this.http.post<ICommunication>(this.BASE_URL, communication).pipe(
      map(response => {
        this.communicationPublicService.setCommunication(response);
        return response;
      })
    );
  }

  putCommunication(communication) {
    return this.http.patch<ICommunication>(`${this.BASE_URL}/${communication.id}`, communication);
  }

  deleteCommunication(communication) {
    return this.http.delete(`${this.BASE_URL}/${communication.id}`);
  }

  checkPortalUniqEmail(email): Observable<{ found: boolean }> {
    return this.http.get(`${environment.apiUrl}/users/check-user-exist?email=${email}`).pipe(map((resp: any) => resp.data));
  }
}
