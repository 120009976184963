import { Action } from '@ngrx/store';
import { MediaExtended } from '@app/models/media-extended';

export enum EMediaActions {
  ADD_MEDIA = '[MediaList] Add Media',
  ADDED_MEDIA = '[MediaList] Added Media',
  ADD_MEDIA_FAILURE = '[MediaList] Add Media Failure'

}

export class AddMedia implements Action {
  public readonly type = EMediaActions.ADD_MEDIA;

  constructor(public payload: File, public fileType: string) {}
}

export class AddedMedia implements Action {
  public readonly type = EMediaActions.ADDED_MEDIA;

  constructor(public payload: MediaExtended) {}
}

export class AddMediaFailure implements Action {
  public readonly type = EMediaActions.ADD_MEDIA_FAILURE;

  constructor(public error: Error) {}
}

export type MediaActions = AddMedia | AddedMedia | AddMediaFailure;
