import { NoteActions, NoteActionsTypes } from '@app/store/actions/note.actions';
import { initialNoteState, NoteState } from '../state/note.state';

export const notesReducers = (state = initialNoteState, action: NoteActions): NoteState => {
  switch (action.type) {
    case NoteActionsTypes.CLEAR_NOTES:
      return {
        ...state,
        list: null
      };
    case NoteActionsTypes.SELECTED_NOTE:
      return {
        ...state,
        selectNote: action.payload
      };
    case NoteActionsTypes.IF_FROM_CONTACT_NOTES:
      return {
        ...state,
        ifContact: action.payload
      };
    case NoteActionsTypes.IF_FROM_MATTER_NOTES:
      return {
        ...state,
        ifMatter: action.payload
      };

    case NoteActionsTypes.GET_NOTES:
      return {
        ...state,
        list: null,
        loading: false
      };
    case NoteActionsTypes.GET_NOTES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: true
      };
    case NoteActionsTypes.GET_NOTES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case NoteActionsTypes.ADD_NOTE:
      return {
        ...state,
        loading: true
      };
    case NoteActionsTypes.ADD_NOTE_SUCCESS:
      if (state.ifMatter == null) {
        if (state.ifContact && action.payload.contact.id === state.ifContact) {
          state.list.push(action.payload);
        }
      }
      if (state.ifContact == null && action.payload.matter !== null) {
        if (state.ifMatter && action.payload.matter.id === state.ifMatter) {
          state.list.push(action.payload);
        }
      }
      return {
        ...state,
        loading: true
      };
    case NoteActionsTypes.ADD_NOTES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case NoteActionsTypes.UPDATE_NOTE:
      return {
        ...state,
        loading: true
      };
    case NoteActionsTypes.UPDATE_NOTE_SUCCESS: {
      for (let index = 0; index < state.list.length; index++) {
        if (state.list[index].id === action.payload.id) {
          state.list[index] = action.payload;
        }
      }
      return {
        ...state,
        loading: true
      };
    }
    case NoteActionsTypes.UPDATE_NOTES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case NoteActionsTypes.DELETE_NOTE:
      return {
        ...state,
        loading: true
      };
    case NoteActionsTypes.DELETE_NOTE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload),
        loading: false
      };
    case NoteActionsTypes.DELETE_NOTE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
