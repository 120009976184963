import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, withLatestFrom, share } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import {
  GetPracticeBranches,
  GetPracticeBranchesSuccess,
  AddPracticeBranch,
  AddPracticeBranchSuccess,
  UpdatePracticeBranch,
  UpdatePracticeBranchSuccess,
  DeletePracticeBranch,
  DeletePracticeBranchSuccess,
  EPracticeBranchesActions,
  DeletePracticeBranches,
  DeletePracticeBranchesSuccess,
  GetPracticeBranchesFailure,
  AddPracticeBranchFailure,
  UpdatePracticeBranchFailure,
  DeletePracticeBranchFailure,
  DeletePracticeBranchesFailure
} from '../actions/practice-branches.actions';
import { PracticeBranchService } from '../../services/practice-branch.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PracticeBranchEffects {

  getPracticeBranches$ = createEffect(() => this.actions$.pipe(
    ofType<GetPracticeBranches>(EPracticeBranchesActions.GET_PRACTICE_BRANCHES),
    switchMap(action => {
      return this.practiceBranchService.getPracticeBranches(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetPracticeBranchesSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetPracticeBranchesFailure(error));
        })
      );
    }),
    share()
  ));


  addPracticeBranch$ = createEffect(() => this.actions$.pipe(
    ofType<AddPracticeBranch>(EPracticeBranchesActions.ADD_PRACTICE_BRANCH),
    switchMap(action => {
      return this.practiceBranchService.addPracticeBranch(action.payload).pipe(
        mergeMap(x => {
          return of(new AddPracticeBranchSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddPracticeBranchFailure(error));
        })
      );
    })
  ));


  addPracticeBranchSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddPracticeBranchSuccess>(EPracticeBranchesActions.ADD_PRACTICE_BRANCH_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetPracticeBranches({
          page: storeState.practiceBranchState.paginator.currentPage,
          itemsPerPage: storeState.practiceBranchState.paginator.itemsPerPage
        })
      );
    })
  ));


  updatePracticeBranch$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePracticeBranch>(EPracticeBranchesActions.UPDATE_PRACTICE_BRANCH),
    switchMap(action => {
      return this.practiceBranchService.updatePracticeBranch(action.payload).pipe(
        mergeMap(x => {
          return of(new UpdatePracticeBranchSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new UpdatePracticeBranchFailure(error));
        })
      );
    })
  ));


  updatePracticeBranchSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePracticeBranchSuccess>(EPracticeBranchesActions.UPDATE_PRACTICE_BRANCH_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetPracticeBranches({
          page: storeState.practiceBranchState.paginator.currentPage,
          itemsPerPage: storeState.practiceBranchState.paginator.itemsPerPage
        })
      );
    })
  ));


  deletePracticeBranch$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePracticeBranch>(EPracticeBranchesActions.DELETE_PRACTICE_BRANCH),
    switchMap(action => {
      return this.practiceBranchService.deletePracticeBranch(action.payload).pipe(
        mergeMap(() => {
          return of(new DeletePracticeBranchSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeletePracticeBranchFailure(error));
        })
      );
    })
  ));


  deletePracticeBranchSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePracticeBranchSuccess>(EPracticeBranchesActions.DELETE_PRACTICE_BRANCH_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemDeleted'));
      return of(
        new GetPracticeBranches({
          page: storeState.practiceBranchState.paginator.currentPage,
          itemsPerPage: storeState.practiceBranchState.paginator.itemsPerPage
        })
      );
    })
  ));


  deletePracticeBranches$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePracticeBranches>(EPracticeBranchesActions.DELETE_PRACTICE_BRANCHES),
    switchMap(action => {
      const deleteMethods = [];
      action.payload.forEach(x => {
        deleteMethods.push(this.practiceBranchService.deletePracticeBranch(x));
      });
      return forkJoin(deleteMethods).pipe(
        mergeMap(() => {
          return of(new DeletePracticeBranchesSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeletePracticeBranchesFailure(error));
        })
      );
    })
  ));


  deletePracticeBranchesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePracticeBranchesSuccess>(EPracticeBranchesActions.DELETE_PRACTICE_BRANCHES_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemsDeleted'));
      return of(
        new GetPracticeBranches({
          page: storeState.practiceBranchState.paginator.currentPage,
          itemsPerPage: storeState.practiceBranchState.paginator.itemsPerPage
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private practiceBranchService: PracticeBranchService
  ) {}
}
