export enum MatterFieldsDebounceEnum {
  contacts = 'matterFieldsContacts',
  responsibleSolicitor = 'matterFieldsResponsibleSolicitor',
  originatingSolicitor = 'matterFieldsOriginatingSolicitor',
  notifyUsers = 'matterFieldsNotifyUsers',
  customRatesCompanyWorkGroup = 'matterFieldscustomRatesCompanyWorkGroup',
  individualGroupUsers = 'individualGroupUsers'
}

export enum ContractFieldsDebounceEnum {
  template = 'contractFieldsTemplate',
  client = 'contractFieldsClient',
  executor = 'contractFieldsExecutor',
  customRatesCompanyWorkGroup = 'contractFieldscustomRatesCompanyWorkGroup'
}

export enum AccountFieldsDebounceEnum {
  contacts = 'accountFieldsContacts',
  executor = 'accountFieldsExecutor',
}

export enum BillCreateDebounceEnum {
  contacts = 'billCreateContacts',
}

export enum DocAddRelationDebounceEnum {
  documents = 'docAddRelationDocuments',
}

export enum TasksFilterDebounceEnum {
  contacts = 'tasksFilterContacts',
  matters = 'tasksFilterMatters',
  users = 'tasksFilterUsers',
}

export enum MattersFilterDebounceEnum {
  contacts = 'mattersFilterContacts',
  practiceBranch = 'mattersFilterPracticeBranch',
  originatingSolicitor = 'mattersFilterOriginatingSolicitor',
  responsibleSolicitorId = 'mattersFilterResponsibleSolicitor',
  contactResponsiblePersonId = 'mattersFilterContactResponsiblePerson'
}

export enum DocumentsFilterDebounceEnum {
  contacts = 'documentsFilterContacts',
  legalContacts = 'documentsFilterLegalContacts',
  matters = 'documentsFilterMatters',
  users = 'documentsFilterUsers',
  documentsCategories = 'documentsFilterDocumentsCategories',
}

export enum CorrespondenceFilterDebounceEnum {
  matters = 'correspondenceFilterMatters',
}

export enum ActivitiesFilterDebounceEnum {
  users = 'activitiesFilterUsers',
  contacts = 'activitiesFilterContacts',
  matters = 'activitiesFilterMatters',
  timeEntryTypes = 'activitiesFilterTimeEntryTypes',
  expenseTypes = 'activitiesFilterExpenseTypes',
}

export enum BillFilterDebounceEnum {
  contacts = 'billFilterContacts',
  contactsPayer = 'billFilterContactsPayer',
  matters = 'billFilterMatters',
  originatingSolicitor = 'billFilterOriginatingSolicitor',
  responsibleSolicitorId = 'billFilterResponsibleSolicitor',
  practiceBranch = 'billFilterPracticeBranch',
}

export enum ReportsFilterDebounceEnum {
  responsible = 'reportsFilterResponsible',
  vendors = 'reportsFilterVendors',
  matters = 'reportsFilterMatters',
  payerId = 'reportsFilterPayerId',
  clientFilterId = 'reportsFilterClientFilterId',
  contactId = 'reportsFilterContactId',
  clientId = 'reportsFilterClientId',
  userId = 'reportsFilterUserId',
  originatingSolicitorId = 'reportsFilterOriginatingSolicitorId'
}

export enum ContactsFilterDebounceEnum {
  users = 'contactFiltersUsers',
  personTypes = 'contactFiltersPersonTypes',
}

export enum BankAccountsTransactionsFilterDebounceEnum {
  contacts = 'bankAccountsTransactionsFilterContacts',
  vendors = 'bankAccountsTransactionsFilterVendors',
  matters = 'bankAccountsTransactionsFilterMatters',
}

export enum BankAccountsFieldsDebounceEnum {
  contacts = 'bankAccountsFieldsContacts',
}

export enum TimeModalDebounceEnum {
  matters = 'timeModalMatters',
  timeEntryTypes = 'timeModalTimeEntryTypes',
  responsible = 'timeModalResponsible',
}

export enum TasksModalDebounceEnum {
  matters = 'tasksModalMatters',
  users = 'tasksModalUsers',
}

export enum InternalExpenseModalDebounceEnum {
  contacts = 'internalExpenseContact',
  expenseTypes = 'internalExpenseExpenseTypes',
  users = 'internalExpenseUsers',
}

export enum ExpenseModalDebounceEnum {
  contacts = 'expenseContact',
  matters = 'expenseMatters',
  users = 'expenseUsers',
}

export enum EventModalDebounceEnum {
  matters = 'expenseMatters',
  users = 'expenseUsers',
}

export enum ContactModalDebounceEnum {
  personTypes = 'contactModalPersonTypes',
  contacts = 'contactModalContacts',
  users = 'contactModalUsers',
}

export enum CorrespondenceNewModalDebounceEnum {
  sender = 'correspondenceNewModalSender',
  recipient = 'correspondenceNewModalRecipient',
  senderSignatory = 'correspondenceNewModalSenderSignatory',
  receiver = 'correspondenceNewModalReceiver',
  matters = 'correspondenceNewModalMatters',
  correspondence = 'correspondenceNewModalCorrespondence',
  users = 'correspondenceNewModalUsers',
}

export enum ShareEmailModalDebounceEnum {
  contacts = 'shareEmailModalContacts',
  connectContacts = 'shareEmailModalConnectContacts',
}

export enum GroupCreateModalDebounceEnum {
  users = 'groupCreateModalUsers',
}

export enum MatterPlanOfActionModalDebounceEnum {
  expenseTypes = 'matterPlanOfActionModalExpenseTypes',
}

export enum BillPaymentRegistrationModalDebounceEnum {
  contacts = 'billPaymentRegistrationModalContacts',
  clients = 'billPaymentRegistrationModalClients',
}

export enum BillEditingModalDebounceEnum {
  clientPayer = 'billEditingClientPayer',
  contract = 'billEditingContract',
  executors = 'billEditingExecutors',
  matters = 'billEditingMatters',
  users = 'billEditingUsers',
  usersList = 'additionalUsersFor',
}

export enum RelationsModalDebounceEnum {
  contacts = 'relationsModalContacts',
  types = 'relationsModalTypes',
}

export enum NoteModalDebounceEnum {
  contacts = 'noteModalContacts',
  matters = 'noteModalMatters',
}

export enum DocumentUploadModalDebounceEnum {
  contacts = 'documentUploadContacts',
  legalContacts = 'documentUploadLegalContacts',
  responsibleContacts = 'documentUploadResponsibleContacts',
  contracts = 'documentUploadContracts',
  matters = 'documentUploadMatters',
  documentCategories = 'documentUploadDocumentCategories',
  users = 'documentUploadUsers',
}

export enum DocumentFromTemplateUploadModalDebounceEnum {
  contacts = 'documentFromTemplateUploadContacts',
  matters = 'documentFromTemplateUploadMatters',
}

export enum DocumentAddRelationModalDebounceEnum {
  documents = 'documentAddRelationDocuments',
}

export enum AdditionalMatterDebounceEnum {
  matters = 'additionalMatterMatters',
}
