import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-non-billable-modal',
  templateUrl: './non-billable-modal.component.html',
  styleUrls: ['./non-billable-modal.component.scss']
})
export class NonBillableModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<NonBillableModalComponent>,
  ) {}
  updateNonBillableFieldTimeEntryFalse = false;
  updateNonBillableFieldTimeEntryTrue = true;
  onClickSave() {
    this.dialogRef.close({
      updateNonBillableFieldTimeEntry: this.updateNonBillableFieldTimeEntryTrue === true ? true : false,
    });
  }

  onChangeUpdateFalse(): void {
    this.updateNonBillableFieldTimeEntryTrue = false;
    setTimeout(() => {
      if (!this.updateNonBillableFieldTimeEntryFalse) {
        this.updateNonBillableFieldTimeEntryFalse = true;
      }
    });
  }
  onChangeUpdateTrue(): void {
    this.updateNonBillableFieldTimeEntryFalse = false;
    setTimeout(() => {
      if (!this.updateNonBillableFieldTimeEntryTrue) {
        this.updateNonBillableFieldTimeEntryTrue = true;
      }
    });
  }
}
