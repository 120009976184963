import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { ClearActivities } from '@app/store/actions/activities.actions';
import { ClearBills } from '@app/store/actions/bill.actions';
import { ClearDocuments } from '@app/store/actions/document.actions';
import { ClearTasks } from '@app/store/actions/task.actions';
import { ClearNote } from '@app/store/actions/note.actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HelpersServices {
  constructor(private store: Store<IAppState>, private toastrService: ToastrService, private translate: TranslateService) {}

  // clear data (tasks and Activities and Notes and Documents and Bills)
  // where exit from card matter or contact
  clearData() {
    this.store.dispatch(new ClearActivities());
    this.store.dispatch(new ClearBills());
    this.store.dispatch(new ClearDocuments());
    this.store.dispatch(new ClearTasks());
    this.store.dispatch(new ClearNote());
  }

  visibleTroast(event) {
    switch (event) {
      case 'logo':
        this.toastrService.success(this.translate.instant('logoUpdatedCompanyPortalSettings'));
        break;
      case 'style':
        this.toastrService.success(this.translate.instant('styleUpdatedCompanyPortalSettings'));
        break;
      case 'deleteLogo':
        this.toastrService.success(this.translate.instant('logotypeDeleted'));
        break;
      case 'invoiceSentToClient':
        this.toastrService.success(this.translate.instant('invoiceSentToClient'));
        break;
      case 'documentSentToClient':
        this.toastrService.success(this.translate.instant('documentSentToClient'));
        break;
      case 'nameSaved':
        this.toastrService.success(this.translate.instant('nameSaved'));
        break;

      default:
        this.toastrService.success(this.translate.instant('PutCompanyPortalSettings'));
        break;
    }
  }
}
