import { IAppState } from '@app/store/state/app.state';

export const selectAnyoneWithoutSystemWorkGroups = (state: IAppState) => state.teamState.anyoneWithoutSystemWorkGroups;

export const selectMyAndAnyoneWorkGroups = (state: IAppState) => state.teamState.myAndAnyoneWorkGroups;

export const selectAnyoneWorkGroups = (state: IAppState) => state.teamState.anyoneWorkGroups;

export const selectAllWorkGroups = (state: IAppState) => state.teamState.allWorkGroups;

export const selectWorkGroups = (state: IAppState) => state.teamState.workGroups;
