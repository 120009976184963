import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Subscription } from 'rxjs';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { Currency } from '@app/models/currency';
import { BankAccountService } from '@app/modules/bank-accounts/services/bank-account.service';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { IAppState } from '@app/store/state/app.state';
import { ConverterModalComponent } from '../../other-modals/converter-modal/converter-modal.component';

@Component({
  selector: 'app-transfer-between-accounts-modal',
  templateUrl: './transfer-between-accounts-modal.component.html',
  styleUrls: ['./transfer-between-accounts-modal.component.scss']
})
export class TransferBetweenAccountsModalComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      companyBankAccountFrom?: BankAccountExtended;
      companyBankAccountTo?: BankAccountExtended;
      transfer?: any;
    },
    public dialogRef: MatDialogRef<TransferBetweenAccountsModalComponent>,
    private appSettingsService: AppSettingsService,
    private store: Store<IAppState>,
    private bankAccountsService: BankAccountService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  get f() {
    return this.transferForm;
  }
  private subscription = new Subscription();

  defaultCurrency;
  initBankAccount = true;

  transferForm: UntypedFormGroup;

  bankAccounts;
  dateFormat = CustomDateAdapter.dateFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  submittedForm = false;
  @ViewChild('comboBoxBankAccountFrom', { static: false }) public comboBoxBankAccountFrom: ComboBoxComponent;
  @ViewChild('comboBoxBankAccountTo', { static: false }) public comboBoxBankAccountTo: ComboBoxComponent;

  public locale$ = this.appSettingsService.getLang;

  ngOnInit() {
    this.createForm();
    this.initData();
    if (this.data && this.data.companyBankAccountFrom) {
      this.transferForm
        .get('companyBankAccountFrom')
        .setValue(this.data.companyBankAccountFrom ? this.data.companyBankAccountFrom.id : null);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkSubmit() {
    this.submittedForm = true;
  }

  saveTransfer() {
    this.checkSubmit();
    this.transferForm.updateValueAndValidity();
    if (!this.transferForm.valid) {
      return;
    }
    this.openConvertor();
  }


  closeModal() {
    this.dialogRef.close();
  }

  private createForm() {
    this.transferForm = this.formBuilder.group({
      amount: this.formBuilder.control(this.data?.transfer?.amount || null, [Validators.required, Validators.min(0)]),
      payAt: this.formBuilder.control(
        this.data?.transfer?.payAt ? new Date(this.data?.transfer?.payAt) : new Date(),
        [Validators.required]
      ),
      description: this.formBuilder.control(this.data?.transfer?.description || this.translate.instant('transfer.betweenPersonalAccounts')),
      companyBankAccountTo: this.formBuilder.control(this.data?.transfer?.companyBankAccountTo?.id || null, [Validators.required]),
      companyBankAccountFrom: this.formBuilder.control(this.data?.transfer?.companyBankAccountFrom?.id || null, [Validators.required]),
      rateCompanyBankAccountTo: this.formBuilder.control(this.data?.transfer?.rateCompanyBankAccountTo || null),
    });
  }

  private formDataToTransferSave(isUpdated: boolean) {
    const transfer = {
      id: isUpdated ? this.data.transfer.id : null,
      amount: this.transferForm.get('amount').value.toString(),
      payAt: CustomDateAdapter.convertToUniversalDateString(this.transferForm.get('payAt').value),
      description: this.transferForm.get('description').value,
      companyBankAccountFrom: this.transferForm.get('companyBankAccountFrom').value as number,
      companyBankAccountTo: this.transferForm.get('companyBankAccountTo').value as number,
      rateCompanyBankAccountTo: this.transferForm.get('rateCompanyBankAccountTo').value,
    };
    return transfer;
  }



  initData() {
    const subscr = this.store.pipe(select(selectCompanySetting)).subscribe(data => {
      if (data) {
        this.defaultCurrency = (data.currency as Currency).code;
      }
    });
    this.getBankAccountsByCurrency();
    this.subscription.add(subscr);
  }

  getBankAccountsByCurrency() {
    if (this.comboBoxBankAccountTo) {
      this.comboBoxBankAccountTo.showSpinner();
    }
    if (this.data.transfer) {
      this.setCompanyBankAccount();
    } else {
      this.bankAccountsService.getBankAccounts().subscribe(res => {
        this.bankAccounts = res.member.filter(el => el.id !== this.data.companyBankAccountFrom.id);
        this.setCompanyBankAccount();
      });
    }
  }

  setCompanyBankAccount() {
    if (this.comboBoxBankAccountTo) {
      this.comboBoxBankAccountTo.dataBind();
      this.comboBoxBankAccountTo.hideSpinner();
    }
    if (this.data?.transfer && this.initBankAccount) {
      this.initBankAccount = false;
      this.bankAccounts = [this.data.transfer.companyBankAccountTo];

      this.transferForm
        .get('companyBankAccountTo')
        .setValue(this.data.transfer.companyBankAccountTo ? this.data.transfer.companyBankAccountTo.id : null);
      this.transferForm
        .get('companyBankAccountFrom')
        .setValue(this.data.companyBankAccountFrom ? this.data.companyBankAccountFrom.id : null);
    }
  }

  fixAmount(event) {
    setTimeout(() => {
      let temp = event.target.value;
      const currentValue = temp.split('.');
      if (currentValue.length > 2) {
        currentValue.pop();
        temp = currentValue[0] + '.' + currentValue[1];
      }
      const countAfter = currentValue[currentValue.length - 1].length;
      if (temp.includes('.') && countAfter > 2) {
        if (currentValue[1][0] > 9) {
          temp = currentValue[0] + '.' + '9' + currentValue[1][1];
        } else {
          temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
        }
      }
      this.transferForm.get('amount').setValue(temp);
    }, 100);

    if ((event.which >= 48 && event.which <= 57) || event.which === 46) {
      return true;
    }
    return false;
  }


  openConvertor(close = true) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];
    const currencyBankAccountFrom =
    close ?
      !this.data?.transfer?.id && (this.data.companyBankAccountFrom.currency?.code || this.data.companyBankAccountFrom.currency) :
      this.data.transfer.companyBankAccountFrom.currency?.code;
    const currencyBankAccountTo =
    close ?
      !this.data?.transfer?.id && this.comboBoxBankAccountTo['itemData']['currency']['code'] :
      this.data.transfer.companyBankAccountTo.currency?.code;

    if (currencyBankAccountFrom !== currencyBankAccountTo) {
      dialogConfig.data = {
        fromCurrency: currencyBankAccountFrom,
        toCurrency: currencyBankAccountTo,
        fromBilling: true,
        amount: this.transferForm.get('amount').value
      };
      this.dialog
        .open(ConverterModalComponent, dialogConfig)
        .afterClosed()
        .subscribe(resp => {
          if (resp) {
            this.transferForm.get('rateCompanyBankAccountTo').setValue(+resp.rateClientorMatter);
            if (close) {
              this.dialogRef.close(this.formDataToTransferSave(this.data && this.data.transfer));
            }
          }
        });
    } else {
      if (close) {
        this.dialogRef.close(this.formDataToTransferSave(this.data && this.data.transfer));
      }
    }
  }

  changeRate() {
    this.openConvertor(false);
  }
}
