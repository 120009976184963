import { Directive, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appHideBlock]',
  standalone: true,
})
export class HideBlockDirective {
  private hasView = false;

  @Input() set appHideBlock(condition: boolean) {
    if ((condition || this.isFocused) && !this.hasView) {
      this.hasView = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.hasView = false;
      this.viewContainer.clear();
    }
  }

  private isFocused = false;

  @HostListener('focusin') onFocus() {
    this.isFocused = true;
  }


  @HostListener('focusout') onFocusOut() {
    this.isFocused = false;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
  }
}


