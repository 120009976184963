import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { selectTimeEntryTypes } from '@app/modules/settings/store/selectors/time-entry-type.selector';
import { LoadTimeEntryTypesAction } from '@app/modules/settings/store/actions/time-entry-types.actions';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { selectExpenseTypes } from '@app/modules/settings/store/selectors/expence-type.selector';
import { LoadExpenseTypesAction } from '@app/modules/settings/store/actions/expense-types.actions';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { selectBankAccountsPaginator } from '@app/store/selectors/bank-account.selector';
import { GetBankAccounts } from '@app/store/actions/bank-account.actions';
import { LoadAllContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { selectAllContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeModalComponent } from '@app/commonComponents/wide-modals/time-modal/time-modal.component';
import { selectMatterListForSelect } from '@app/store/selectors/matter.selector';
import { LoadMattersForList } from '@app/store/actions/matter.actions';
import { TimekeeperService } from '@app/modules/layout/timekeeper/timekeeper.service';
import * as moment from 'moment';
import { distinctUntilChanged, first, skipWhile, take, tap } from 'rxjs/operators';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { GetActivities } from '@app/store/actions/activities.actions';
import { ActivityExtended, Contact, IUser } from '@app/models';
import { ActivitiesEffects } from '@app/store/effects/activities.effects';
import { MatterExtended } from '@app/models/matter-extended';
import { BankAccountExtended } from '@app/models/bank-account-extended';

@Component({
  selector: 'app-timekeeper',
  templateUrl: './timekeeper.component.html',
  styleUrls: ['./timekeeper.component.scss']
})
export class TimekeeperComponent implements OnInit, OnDestroy {

  // Todo move it more globally
  static MYSQL_DATE_FORMAT = 'YYYY-MM-DD';

  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  public locale$ = this.appSettingsService.getLang;
  public showTimekeeper = true;
  public toggleCalendar = false;
  public vendors: Contact[];
  public bankAccounts: BankAccountExtended[];
  public expenseTypes: any;
  public specialLocaleForTimekeeper = 'en';
  public selectedDate = new Date();

  private subscription: Subscription = new Subscription();
  private matters: MatterExtended[];
  private timeEntryTypes: any;
  private users: IUser[];


  constructor(
    private translate: TranslateService,
    private router: Router,
    private store: Store<IAppState>,
    private dialog: MatDialog,
    public timekeeperService: TimekeeperService,
    private activitiesEffects: ActivitiesEffects,
    private appSettingsService: AppSettingsService
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      this.activitiesEffects.getActivities$
        .subscribe((v) => {
          this.refetchTimeEntries();
        }));

    /**
     * Не трогать. Выбросит ошибку `setLocale of undefined` при смене языка
     */
    this.subscription.add(
      this.appSettingsService.getLang
        .pipe(
          skipWhile(lang => !lang),
          distinctUntilChanged(),
          tap(() => (this.showTimekeeper = false))
        )
        .subscribe(lang => {
          this.specialLocaleForTimekeeper = lang;
          setTimeout(() => {
            this.showTimekeeper = true;
          }, 100);
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToTimeEntries() {
    this.router.navigate(['/activities/time_entries']).then(() => {
      this.closed.emit(true);
    });
  }

  /**
   * Determine if timeEntry has active timer
   * @param timeEntry ActivityExtended
   */
  isActive(timeEntry: ActivityExtended) {
    if (!this.timekeeperService.activeTimeEntry || !this.timekeeperService.isActive) {
      return false;
    }
    return timeEntry.id === this.timekeeperService.activeTimeEntry.id;
  }

  /**
   * Determine what we should do with click on timer
   * @param timeEntry ActivityExtended
   */
  handleTimer(timeEntry: ActivityExtended) {
    if (this.timekeeperService.isPending || timeEntry.billItem) {
      return;
    }
    this.timekeeperService.isPending = true;

    if (this.isActive(timeEntry)) {
      const currentTimer = this.timekeeperService.countCurrentTimer(timeEntry);
      this.timekeeperService.removeTimer().pipe(take(1)).subscribe(res => {
        timeEntry.duration = currentTimer;
        timeEntry.timer = null;
        this.timeModal(timeEntry);
        this.timekeeperService.isPending = false;
      });
    } else {
      this.timekeeperService
        .createTimer({
          timeEntry,
          initialValue: timeEntry.duration
        })
        .subscribe(() => {
          this.timekeeperService
            .fetchTimeEntries({
              'date[after]': moment(this.selectedDate).format(TimekeeperComponent.MYSQL_DATE_FORMAT),
              'date[before]': moment(this.selectedDate).format(TimekeeperComponent.MYSQL_DATE_FORMAT)
            })
            .subscribe(() => {
              this.timekeeperService.isPending = false;
            });
        });
    }
  }

  getData(data) {
    return data.data;
  }

  editTimeEntry(item: ActivityExtended) {
    if (!item.billItem) {
      this.timeModal(item);
    } else {
      this.router.navigateByUrl(`/activities/card/${item.id}?type=time_entries`);
    }
  }

  newTimeEntry() {
    this.timeModal();
  }

  /**
   * Called on component init, because of ejs-calendar (valueChanged)
   * @param $event Date
   */
  refetchTimeEntries($event?: Date) {
    if ($event) {
      this.selectedDate = $event;
    }
    const result = this.timekeeperService.fetchTimeEntries({
      'date[after]': moment(this.selectedDate).format(TimekeeperComponent.MYSQL_DATE_FORMAT),
      'date[before]': moment(this.selectedDate).format(TimekeeperComponent.MYSQL_DATE_FORMAT)
    });
    result.subscribe();
    this.toggleCalendar = false;
    return result;
  }

  getSelect() {
    this.store.dispatch(new LoadCompanySettingAction());

    this.subscription.add(this.store.select(selectTimeEntryTypes).subscribe(data => {
        if (!data) {
          this.store.dispatch(new LoadTimeEntryTypesAction());
        }
        if (data) {
          this.timeEntryTypes = this.getData(data);
        }
      })
    );

    this.subscription.add(
      this.store.select(selectExpenseTypes).subscribe(data => {
        if (!data) {
          this.store.dispatch(new LoadExpenseTypesAction());
        } else if (data) {
          this.expenseTypes = this.getData(data);
        }
      })
    )
    ;
    this.loadUsers();

    this.subscription.add(this.store.select(selectBankAccountsPaginator).subscribe(bankAccounts => {
      if (!bankAccounts) {
        this.store.dispatch(new GetBankAccounts({ page: 1, itemsPerPage: 250 }));
      } else if (bankAccounts && bankAccounts.member) {
        this.bankAccounts = bankAccounts.member;
      }
    }));

    this.loadContacts();
  }

  loadUsers() {
    this.store.select(selectListUsersForSelect)
      .pipe(first())
      .subscribe(resp => {
        if (resp == null) {
          this.store.dispatch(
            new LoadUsersForList({
              page: environment.defaultPage,
              itemsPerPage: environment.countItemInSelect
            })
          );
        }
        this.users = resp;
      });
  }

  loadContacts() {
    this.store.select(selectAllContactListForSelect)
      .pipe(first())
      .subscribe(data => {
        if (!data) {
          this.store.dispatch(
            new LoadAllContactsForList({
              page: environment.defaultPage,
              itemsPerPage: environment.countItemInSelect
            })
          );
        }
        this.vendors = data;
      });
  }

  loadMatter() {
    this.store.select(selectMatterListForSelect)
      .pipe(first())
      .subscribe(matters => {
        if (!matters) {
          this.store.dispatch(
            new LoadMattersForList({
              page: environment.defaultPage,
              itemsPerPage: environment.countItemInSelect
            })
          );
        }
        if (matters) {
          this.matters = matters;
        }
      });
  }

  timeModal(time?: ActivityExtended) {
    this.loadMatter();

    const dialogConfig = new MatDialogConfig();
    if (time) {
      dialogConfig.data = { time };
    }
    if (time) {
      dialogConfig.data = {
        time,
        users: this.users,
        matters: this.matters,
        timeEntryTypes: this.timeEntryTypes
      };
    } else {
      dialogConfig.data = {
        users: this.users,
        matters: this.matters,
        timeEntryTypes: this.timeEntryTypes
      };
    }
    dialogConfig.panelClass = ['default-mat-dialog', 'time-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TimeModalComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            new GetActivities({
              fetchPayload: {
                page: environment.defaultPage,
                itemsPerPage: environment.defaultItemsCount
              },
              typeActivity: 'activities'
            })
          );
        }
        this.timekeeperService.fetchTimeEntries({
          'date[after]': moment(this.selectedDate).format(TimekeeperComponent.MYSQL_DATE_FORMAT),
          'date[before]': moment(this.selectedDate).format(TimekeeperComponent.MYSQL_DATE_FORMAT)
        });
      });

    dialogRef
      .backdropClick()
      .subscribe(() => {
        this.confirmCloseModal(dialogRef);
      });
  }

  confirmCloseModal(modalRef): void {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  convertSecondsToTableString(duration: number): string {
    const h = duration ? Math.floor(duration / 3600) : 0;
    const m = duration ? Math.floor((duration % 3600) / 60) : 0;
    const s = duration ? duration % 60 : 0;

    return `${this.padTime(h)}:${this.padTime(m)}:${this.padTime(s)}`;
  }

  padTime(t) {
    return t < 10 ? '0' + t : t;
  }
}
