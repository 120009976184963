import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/_services/auth.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';
import { ResponseBodyFull } from '@app/models';
import { Paginator } from '@app/models/paginator';
import { Template } from '@app/models/template.model';
import { environment } from '@environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private BASE_URL = `${environment.apiUrl}/templates`;

  constructor(private http: HttpClient, private authService: AuthService) {}
  get(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters = null) {
    this.BASE_URL = `${environment.apiUrl}/templates`;
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      if (filters.type === 'contactAndMatter') {
        this.BASE_URL = `${environment.apiUrl}/templates?type[]=contact&type[]=matter`;
        filters.type = null;
      }
      params = Helpers.populateFilters(params, filters);
    }

    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<Template>>(this.BASE_URL, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<Template>>();
    }
  }
  getTemplate(id): Observable<Template> {
    if (id == null) {
      return null;
    }
    if (this.authService.currentUserValue) {
      return this.http.get<Template>(`${this.BASE_URL}/${id}`)
    } else {
      return new Observable<Template>();
    }
  }
  addTemplate(data: Template): Observable<Template> {
    const formData = {
      'type': data.type,
      'name': data.name,
      'file': '' as any
    }
    return this.convertFile(data.file).pipe(
      switchMap(str => {
        formData.file = str;
        return this.http.post<Template>(`${this.BASE_URL}`, formData);

      })
    )
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  updateTemplate(template: Template): Observable<Template> {
    const formData = new FormData();
    formData.append('name', template.name);
    formData.append('type', template.type);
    return this.http.patch<Template>(`${this.BASE_URL}/${template.id}`, template);
  }

  deleteTemplate(id) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }
  getDocumentFromTemplate(data): Observable<Blob> {
    const querry = `${environment.apiUrl}/templates/${data.template}/by-data/${data.item}?
    ${data.startDate ? 'dateFilter[after]=' + CustomDateAdapter.convertToUniversalDateString(data.startDate) + '&' : ''}
    ${data.endDate ? 'dateFilter[before]=' + CustomDateAdapter.convertToUniversalDateString(data.endDate) : ''}`;
    return this.http
      .get(querry, {
        responseType: 'blob'
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  downloadFile(file): Observable<Blob> {
    return this.http
      .get(`${file}`, {
        responseType: 'blob'
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }
}
