import { MatterCardActionsTypes, MatterCardActions } from '../actions/matter-card.actions';
import { MatterCardState, initialMatterCardState } from '../state/matter-card.state';

export const MatterCardReducers = (state = initialMatterCardState, action: MatterCardActions): MatterCardState => {
  switch (action.type) {
    case MatterCardActionsTypes.LOAD_MATTER_FINANCE:
      return {
        ...state,
        finance: null,
        loading: true
      };
    case MatterCardActionsTypes.LOAD_MATTER_FINANCE_SUCCESS:
      return {
        ...state,
        finance: action.payload,
        loading: false
      };
    case MatterCardActionsTypes.LOAD_MATTER_FINANCE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
