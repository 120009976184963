import { ITeamMemberState } from '@app/models/interfaces/team-member.state';
import { PROGRESS_STATUSES } from '@app/shared/symbols';

export const initialTeamMembersState: ITeamMemberState = {
  members: {},
  selected: null,
  invited: {},
  paginator: null,
  listUsersForSelect: null,
  listAllUsersForSelect: null,
  listAllAdministratorsAndAccountsForSelect: null,
  listUsersForEntitySelect: null,
  countries: null,
  listAllUsersForReportsSelect: null,
  updateUserStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
};
