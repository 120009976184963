import { DocumentExtended } from '@app/models';
import { Paginator } from '@app/models/paginator';
import { DocumentsFilters } from '@app/models/documents-filters';
import { environment } from '@environments/environment';

export interface IDocumentState {
  paginator: Paginator<DocumentExtended>;
  documentsByActivity: DocumentExtended[];
  documentsByTask: DocumentExtended[];
  documentsByBill: DocumentExtended[];
  documentsByCorrespondence: DocumentExtended[];
  documentsByContract: DocumentExtended[];
  selectedDocument: DocumentExtended;
  documentFilters: DocumentsFilters;
}

export const initialDocumentState: IDocumentState = {
  paginator: {
    member: null,
    countItems: null,
    totalItems: null,
    currentPage: environment.defaultPage,
    itemsPerPage: environment.defaultItemsCount,
    lastPage: null
  },
  documentsByActivity: null,
  documentsByCorrespondence: null,
  documentsByTask: null,
  documentsByBill: null,
  documentsByContract: null,
  selectedDocument: null,
  documentFilters: {}
};
