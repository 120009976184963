import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeEntryType } from '@app/models/time-entry-type';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseBodyFull } from '@app/models';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { ActivitiesFilters } from '@app/models/activities-filters';
import { Paginator } from '@app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class TimeEntryTypesService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL = `${environment.apiUrl}/time_entry_types`;

  get(): Observable<Paginator<TimeEntryType>> {
    const queryString = `${environment.apiUrl}/time_entry_types?partial=false&page=1&itemsPerPage=10000`;
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<TimeEntryType>>(queryString)
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));;
    } else {
      return new Observable<Paginator<TimeEntryType>>();
    }
  }
  getTimeEntryTypes(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters?: ActivitiesFilters): Observable<Paginator<TimeEntryType>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    return this.http
      .get<ResponseBodyFull<TimeEntryType>>(this.BASE_URL, { params })
      .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
  }

  addTimeEntryType(timeEntryType: TimeEntryType) {
    const queryString = `${environment.apiUrl}/time_entry_types`;
    return this.http.post<TimeEntryType>(queryString, timeEntryType);
  }

  updateTimeEntryType(timeEntryType: TimeEntryType) {
    const queryString = `${environment.apiUrl}/time_entry_types/${timeEntryType.id}`;
    return this.http.patch<TimeEntryType>(queryString, timeEntryType);
  }

  deleteTimeEntryType(id: number) {
    const queryString = `${environment.apiUrl}/time_entry_types/${id}`;
    return this.http.delete(queryString);
  }
}
