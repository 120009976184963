// generated by the plop

import { MainMatterStateModel } from './main-matter.model';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';
import { MatterFilters } from '@app/models/matter-filters';

export class LoadMattersForList {
  static type = '[mainMatter] LoadMattersForList';

  constructor(public payload: FetchPayload<MatterFilters>) {
  }
}

export class LoadMattersForListSuccess {
  static type = '[mainMatter] LoadMattersForListSuccess';

  constructor(public payload: MainMatterStateModel['matterExtended']) {
  }
}

export class LoadMattersForListFail {
  static type = '[mainMatter] LoadMattersForListFailure';

  constructor(public error: Error) {
  }
}
