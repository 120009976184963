import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from '@app/models';
import { CompanySettings } from '@app/models/company-settings';

@Component({
  selector: 'app-two-factor-setting-modal',
  templateUrl: './two-factor-setting-modal.component.html',
  styleUrls: ['./two-factor-setting-modal.component.scss']
})
export class TwoFactorSettingModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TwoFactorSettingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData) { }
  companySettings: CompanySettings;
  user: IUser;
  checkedUser2fa = false;
  ngOnInit(): void {
    this.companySettings = this.dialogData.companySetting;
    this.user = this.dialogData.user;
    this.checkedUser2fa = this.user?.settings[0].twoFactorAuthentication;
  }

  closeModal(data?) {
    this.dialogRef.close(data);
  }


  onSubmit() {
    this.closeModal({checkedUser2fa: this.checkedUser2fa});
  }
}
