import { CompanySettingAction, CompanySettingActionTypes } from '../actions/company-setting.actions';
import { CompanySettingsState, initialStateCompanySetting } from '../state/company-setting.state';

export function CompanySettingReducer(state = initialStateCompanySetting, action: CompanySettingAction): CompanySettingsState {
  switch (action.type) {
    case CompanySettingActionTypes.LOAD_COMPANY_SETTING:
      return {
        ...state,
        loading: true
      };
    case CompanySettingActionTypes.LOAD_COMPANY_SETTING_SUCCESS:
      return {
        ...state,
        companySetting: action.payload,
        loading: false
      };
    case CompanySettingActionTypes.LOAD_SUBSCRIPTIONS_SUBS_SUCCESS:
      return {
        ...state,
        subscriptionSubs: action.payload,
        loading: true
      };

    case CompanySettingActionTypes.LOAD_SUBSCRIBED_SUBSCRIPTION_SUB_SUCCESS:
      return {
        ...state,
        subscribedSubscription: action.payload,
        loading: true
      };

    case CompanySettingActionTypes.LOAD_COMPANY_SUB_SETTING_SUCCESS:
      return {
        ...state,
        companySubSettings: action.payload
      };

    case CompanySettingActionTypes.LOAD_SUB_TARIFF_SUCCESS:
      for (const tariff of action.payload) {
        tariff.prices = tariff.prices.filter(price => price.currency.code === 'EUR');
      }

      return {
        ...state,
        subTariffs: action.payload
      };

    case CompanySettingActionTypes.LOAD_TARIFF_SUCCESS:

      return {
        ...state,
        tariffs: action.payload
      };

    case CompanySettingActionTypes.LOAD_COMPANY_SETTING_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
