import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, withLatestFrom, map, share } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import {
  GetDocumentCategories,
  EDocumentCategoriesActions,
  GetDocumentCategoriesSuccess,
  AddDocumentCategory,
  UpdateDocumentCategory,
  DeleteDocumentCategory,
  AddDocumentCategorySuccess,
  UpdateDocumentCategorySuccess,
  DeleteDocumentCategorySuccess,
  DeleteDocumentCategories,
  DeleteDocumentCategoriesSuccess,
  LoadDocumentCategories,
  LoadDocumentCategoriesSuccessAction,
  LoadDocumentCategoriesFailureAction,
  GetDocumentCategoriesFailure,
  AddDocumentCategoryFailure,
  UpdateDocumentCategoryFailure,
  DeleteDocumentCategoryFailure,
  DeleteDocumentCategoriesFailure
} from '../actions/document-categories.actions';
import { DocumentCategoryService } from '../../services/document-category.service';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DocumentcategoryEffects {
   loadDocumentCategories$ = createEffect(() => this.actions$.pipe(
    ofType<LoadDocumentCategories>(EDocumentCategoriesActions.LOAD_DOCUMENT_CATEGORIES),
    mergeMap(() =>
      this.documentCategoryService.get().pipe(
        map(data => new LoadDocumentCategoriesSuccessAction(data)),
        catchError(error => of(new LoadDocumentCategoriesFailureAction(error)))
      )
    )
  ));


  getDocumentCategories$ = createEffect(() => this.actions$.pipe(
    ofType<GetDocumentCategories>(EDocumentCategoriesActions.GET_DOCUMENT_CATEGORIES),
    switchMap(action => {
      return this.documentCategoryService
        .getDocumentCategories(action.payload.page, action.payload.itemsPerPage, action.payload.filters)
        .pipe(
          mergeMap(resp => {
            return [new GetDocumentCategoriesSuccess(resp)];
          }),
          catchError((error) => {
            // will add error handler later
            return of(new GetDocumentCategoriesFailure(error));
          })
        );
    }),
    share()
  ));


  addDocumentCategory$ = createEffect(() => this.actions$.pipe(
    ofType<AddDocumentCategory>(EDocumentCategoriesActions.ADD_DOCUMENT_CATEGORY),
    switchMap(action => {
      return this.documentCategoryService.addDocumentCategory(action.payload).pipe(
        mergeMap(x => {
          return of(new AddDocumentCategorySuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddDocumentCategoryFailure(error));
        })
      );
    })
  ));


  addDocumentCategorySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddDocumentCategorySuccess>(EDocumentCategoriesActions.ADD_DOCUMENT_CATEGORY_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetDocumentCategories({
          page: storeState.documentCategoryState.paginator.currentPage,
          itemsPerPage: storeState.documentCategoryState.paginator.itemsPerPage
        })
      );
    })
  ));


  updateDocumentCategory$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateDocumentCategory>(EDocumentCategoriesActions.UPDATE_DOCUMENT_CATEGORY),
    switchMap(action => {
      return this.documentCategoryService.updateDocumentCategory(action.payload).pipe(
        mergeMap(x => {
          return of(new UpdateDocumentCategorySuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new UpdateDocumentCategoryFailure(error));
        })
      );
    })
  ));


  updateDocumentCategorySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateDocumentCategorySuccess>(EDocumentCategoriesActions.UPDATE_DOCUMENT_CATEGORY_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetDocumentCategories({
          page: storeState.documentCategoryState.paginator.currentPage,
          itemsPerPage: storeState.documentCategoryState.paginator.itemsPerPage
        })
      );
    })
  ));


  deleteDocumentCategory$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocumentCategory>(EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORY),
    switchMap(action => {
      return this.documentCategoryService.deleteDocumentCategory(action.payload).pipe(
        mergeMap(() => {
          return of(new DeleteDocumentCategorySuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteDocumentCategoryFailure(error));
        })
      );
    })
  ));


  deleteDocumentCategorySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocumentCategorySuccess>(EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORY_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemDeleted'));
      return of(
        new GetDocumentCategories({
          page: storeState.documentCategoryState.paginator.currentPage,
          itemsPerPage: storeState.documentCategoryState.paginator.itemsPerPage
        })
      );
    })
  ));


  deleteDocumentCategories$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocumentCategories>(EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORIES),
    switchMap(action => {
      const deleteMethods = [];
      action.payload.forEach(x => {
        deleteMethods.push(this.documentCategoryService.deleteDocumentCategory(x));
      });
      return forkJoin(deleteMethods).pipe(
        mergeMap(() => {
          return of(new DeleteDocumentCategoriesSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeleteDocumentCategoriesFailure(error));
        })
      );
    })
  ));


  deleteDocumentCategoryiesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteDocumentCategoriesSuccess>(EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORIES_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemsDeleted'));
      return of(
        new GetDocumentCategories({
          page: storeState.documentCategoryState.paginator.currentPage,
          itemsPerPage: storeState.documentCategoryState.paginator.itemsPerPage
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private documentCategoryService: DocumentCategoryService,
  ) {}
}
