import { initialContactState } from '@app/modules/contacts/store/state/contact.state';
import { ContactActions, EContactActions } from '@app/modules/contacts/store/actions/contact.actions';
import { ContactState } from '@app/models/interfaces/contact-state';

export const contactReducers = (state = initialContactState, action: ContactActions): ContactState => {
  switch (action.type) {
    case EContactActions.CLEAR_CONTACTS: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null,
        },
      };
    }
    case EContactActions.GET_CONTACTS: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null,
        },
      };
    }
    case EContactActions.GET_CONTACTS_SUCCESS: {
      return {
        ...state,
        paginator: action.payload,
      };
    }
    case EContactActions.LOAD_ALL_CONTACTS_FOR_LIST: {
      return {
        ...state,
        allContactListForSelect: null,
      };
    }
    case EContactActions.LOAD_ALL_CONTACTS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        allContactListForSelect: action.payload,
      };
    }

    case EContactActions.LOAD_PERSON_CONTACTS_FOR_LIST: {
      return {
        ...state,
        persons: null,
      };
    }
    case EContactActions.LOAD_PERSON_CONTACTS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        persons: action.payload,
      };
    }

    case EContactActions.LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST: {
      return {
        ...state,
        activePersonsAndContact: null,
      };
    }
    case EContactActions.LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        activePersonsAndContact: action.payload,
      };
    }

    case EContactActions.LOAD_CONTACTS_FOR_LIST: {
      return {
        ...state,
        contactListForSelect: null,
      };
    }
    case EContactActions.LOAD_CONTACTS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        contactListForSelect: action.payload,
      };
    }

    case EContactActions.UPDATE_CONTACTS_FOR_LIST_SUCCESS: {
      if (state.contactListForSelect?.length) {
        state.contactListForSelect.unshift(action.payload);
      }
      return {
        ...state,
      };
    }

    case EContactActions.LOAD_CONTACTS_FOR_LIST_DIRECTOR: {
      return {
        ...state,
        contactListForSelectDirector: null,
      };
    }
    case EContactActions.LOAD_CONTACTS_FOR_LIST_DIRECTOR_SUCCESS: {
      return {
        ...state,
        contactListForSelectDirector: action.payload,
      };
    }

    case EContactActions.LOAD_ONLY_CLIENTS_FOR_LIST: {
      return {
        ...state,
        allOnlyClientsForSelect: null,
      };
    }
    case EContactActions.LOAD_ONLY_CLIENTS_FOR_LIST_SUCCESS: {
      return {
        ...state,
        allOnlyClientsForSelect: action.payload,
      };
    }

    case EContactActions.UPDATE_ONLY_CLIENTS_FOR_LIST_SUCCESS: {
      if (state.allOnlyClientsForSelect?.length) {
        state.allOnlyClientsForSelect.unshift(action.payload);
      }
      return {
        ...state,
      };
    }

    case EContactActions.CREATE_CONTACT_SUCCESS: {
      state.contacts[action.payload.id] = action.payload;
      state.selectedDirector = action.payload;

      return { ...state };
    }
    case EContactActions.REMOVE_CONTACT_SUCCESS: {
      delete state.contacts[action.payload];

      return { ...state };
    }

    case EContactActions.SELECT_CONTACT: {
      return { ...state, selected: action.payload };
    }
    case EContactActions.GET_CONTACT_BY_ID_SUCCESS: {
      const { contacts } = state;


      contacts[action.payload.id] = action.payload;

      return { ...state, contacts, currentContact: action.payload };
    }

    case EContactActions.UPDATE_CONTACT_SUCCESS: {
      state.contacts[action.payload.id] = action.payload;

      return { ...state, currentContact: action.payload };
    }

    case EContactActions.REMOVE_SELECTED_DIRECTOR: {
      state.selectedDirector = null;

      return { ...state };
    }
    case EContactActions.SELECT_PERSON: {
      return { ...state, selectedPerson: action.payload };
    }
    case EContactActions.REMOVE_SELECTED: {
      state.selected = null;

      return { ...state };
    }
    default:
      return state;
  }
};
