import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckRoleDirective } from '@app/common/directives/check-role.directive';

@NgModule({
  declarations: [CheckRoleDirective],
  imports: [CommonModule],
  exports: [CheckRoleDirective]
})
export class CommonsModule {}
