import { billState } from '@app/store/state/bill.state';
import { BillActions, EBillActions } from '@app/store/actions/bill.actions';
import { BillState } from '@app/models/interfaces/bill-state';

export const billReducers = (state = billState, action: BillActions): BillState => {
  switch (action.type) {
    case EBillActions.CLEAR_BILLS: {
      return {
        ...state,
        bills: [],
        selected: null,
        paginator: null,
        total: null
      };
    }

    case EBillActions.CREATE_BILL_SUCCESS: {
      state.bills.push(action.payload);

      state.selected = action.payload;

      return { ...state };
    }
    case EBillActions.REMOVE_BILL: {
      return {
        ...state
      };
    }
    case EBillActions.GET_BILLS_TOTAL_SUCCESS: {
      return {
        ...state,
        total: action.payload
      };
    }
    case EBillActions.UPDATED_BILL_SUCCESS: {
      const idx = state.bills.findIndex(bill => +bill.id === +action.payload.id);

      if (idx !== -1) {
        state.bills[idx] = action.payload;
      }
      if (state.selected) {
        state.selected = action.payload;
      }
      return {
        ...state
      };
    }
    case EBillActions.GET_BILLS: {
      const bills = state.bills;

      return {
        bills,
        selected: state.selected,
        paginator: { ...state.paginator, member: null, totalItems: 0 },
        total: state.total
      };
    }
    case EBillActions.GET_BILL_BY_ID_SUCCESS: {
      const idx = state.bills.findIndex(bill => +bill.id === +action.payload.id);
      if (idx !== -1) {
        state.bills[idx] = action.payload;
      }
      let idxPagMmember = -1;
      if (state.paginator && state.paginator.member) {
        idxPagMmember = state.paginator.member.findIndex(bill => +bill.id === +action.payload.id);
      }
      if (idxPagMmember !== -1) {
        state.paginator.member[idxPagMmember] = action.payload;
      }
      return { ...state, selected: action.payload };
    }
    case EBillActions.GET_BILLS_SUCCESS: {
      const { member, countItems, totalItems, currentPage, itemsPerPage, lastPage } = action.payload;
      const bills = member;

      return {
        ...state,
        bills,
        paginator: {
          member,
          countItems,
          totalItems,
          currentPage,
          itemsPerPage,
          lastPage
        },
        selected: null
      };
    }
    default:
      return state;
  }
};
