export enum SearchTypeMap {
  matter = 'matters',
  event = 'events',
  contact = 'contacts',
  note = 'notes',
  task = 'tasks',
  activity = 'activities',
  document = 'documents',
  contract = 'contracts',
  All = 'All'
}
