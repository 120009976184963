import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionsSubService } from '@app/modules/settings/services/subscriptions-sub.service';
import { Store, select } from '@ngrx/store';
import {
  selectCompanySetting,
  selectCompanySubSetting,
  selectSubscriptionJuscourt,
  selectSubTariffs
} from '@app/modules/settings/store/selectors/company-setting.selector';
import { IAppState } from '@app/store/state/app.state';
import { SubscriptionSub } from '@app/models/subscription-sub';
import { LoadCompanySubSetting, LoadSubscriptionSubs, LoadSubTariff } from '@app/modules/settings/store/actions/company-setting.actions';
import { SubTariff } from '@app/models/sub-tariff';
import { CompanySettings } from '@app/models/company-settings';

@Component({
  selector: 'app-tariff-plan-modal',
  templateUrl: './tariff-plan-modal.component.html',
  styleUrls: ['./tariff-plan-modal.component.scss']
})
export class TariffPlanModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TariffPlanModalComponent>,
    public subscriptionsSubService: SubscriptionsSubService,
    private store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public data: { data?: CompanySettings; change?: boolean; renew?: boolean; limit: boolean }
  ) {}

  tariffsPlan: SubTariff[];
  companySettings: CompanySettings;
  companySubSettings;
  activeJuscourt: SubscriptionSub;
  isCheckJuscourt;

  ngOnInit(): void {
    this.getCompanySettingData();
    this.loadCompanySubSettings();
  }

  getCompanySettingData() {
    this.store.pipe(select(selectCompanySetting)).subscribe(data => {
      if (data?.id) {
        this.companySettings = data;
      }
    });
    this.store.select(selectSubTariffs).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadSubTariff());
      } else {
        this.tariffsPlan = res.filter(tariff => tariff.subType === 'juscourt');
      }
    });
  }

  payTrackerSub(tariff: SubTariff) {


    // коли користувач вибирає безкоштовну підписку
    if (tariff.type === 'free') {
      this.subscriptionsSubService.createCompanySubSettings({ subType: 'juscourt', company: this.companySettings.id }).subscribe(res => {
        this.store.dispatch(new LoadCompanySubSetting());
        this.store.dispatch(new LoadSubscriptionSubs());
      });
      this.dialogRef.close({ tariff });
      return;
    }


    // коли в користувача є підписка і вона платна, то upgrade
    if (this.activeJuscourt && this.activeJuscourt.tariff.type !== 'free') {
      this.subscriptionsSubService.createSubscriptionOrder({
        subscriptionSubs: this.activeJuscourt.id,
        tariffSubPrice: tariff.prices[0].id,
        period: 'year'
      }).subscribe(() => {
        this.store.dispatch(new LoadCompanySubSetting());
        this.store.dispatch(new LoadSubscriptionSubs());
      });
      this.dialogRef.close({ tariff });
    } else {

      // коли в користувача є підписка і вона безкоштовна, створюємо нову підписку
      if (!this.activeJuscourt) {
        this.subscriptionsSubService.createCompanySubSettings({ subType: 'juscourt', company: this.companySettings.id }).subscribe();
      }
      this.subscriptionsSubService.payCreditCardSubsSibIsActiveJuscourt(tariff, this.companySettings, 'year');
    }
  }

  loadCompanySubSettings() {
    this.store.select(selectCompanySubSetting).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadCompanySubSetting());
      } else {
        this.isCheckJuscourt = res.find(sub => sub.subType === 'juscourt');
      }
    });

    this.store.select(selectSubscriptionJuscourt).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadSubscriptionSubs());
      } else {
        this.activeJuscourt = res[0];
      }
    });
  }
}
