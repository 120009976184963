import { Component, OnInit } from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tracker-tutorial-modal',
  templateUrl: './tracker-tutorial-modal.component.html',
  styleUrls: ['./tracker-tutorial-modal.component.scss']
})
export class TrackerTutorialModalComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<TrackerTutorialModalComponent>, private translate: TranslateService) {}

  sliderList = {
    slide1: {
      image: '/assets/images/plugins/juscourtTracker/juscourtTracker-slider-image1.jpg',
      title: this.translate.instant('juscourtTracker.title'),
      description: this.translate.instant('juscourtTracker.tutorial.slide1.description')
    },
    slide2: {
      image: '/assets/images/plugins/juscourtTracker/juscourtTracker-slider-image2.jpg',
      title: this.translate.instant('juscourtTracker.tutorial.slide2.title'),
      description: this.translate.instant('juscourtTracker.tutorial.slide2.description')
    },
    slide3: {
      image: '/assets/images/plugins/juscourtTracker/juscourtTracker-slider-image3.jpg',
      title: this.translate.instant('juscourtTracker.tutorial.slide3.title'),
      description: this.translate.instant('juscourtTracker.tutorial.slide3.description')
    }
  };

  sliderItems = [
    { count: 1, plugin: this.sliderList.slide1 },
    { count: 2, plugin: this.sliderList.slide2 },
    { count: 3, plugin: this.sliderList.slide3 }
  ];

  animationOver = true;

  ngOnInit(): void {}

  closeModal(): void {
    sessionStorage.removeItem('liqPayData');
    this.dialogRef.close();
  }

  nextSlide() {
    if (this.animationOver) {
      this.animationOver = false;
      this.sliderItems.forEach(element => {
        if (1 === +element.count) {
          element.count = this.sliderItems.length;
        } else {
          element.count--;
        }
      });
      this.animationOver = true;
    }
  }
}
