import { Paginator } from '../../../../models/paginator';

import { ExpenseType } from '../../../../models/index';

export interface IExpenseTypeState {
  paginator: Paginator<ExpenseType>;
  selectedExpenseType: ExpenseType;
  list: {
    success: boolean;
    data: Array<ExpenseType>;
  };
  internalList: Array<ExpenseType>;
  loading: boolean;
  error: Error;
}

export const initialExpenseTypeState: IExpenseTypeState = {
  paginator: null,
  selectedExpenseType: null,
  list: null,
  internalList: null,
  loading: false,
  error: undefined
};
