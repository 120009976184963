import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import {
  CreateContact,
  CreateContactSuccess,
  EContactActions,
  RemoveContact,
  RemoveContactSuccess,
  GetContacts,
  GetContactsSuccess,
  EditContactSuccess,
  EditContact,
  GetContactById,
  GetContactByIdSuccess,
  LoadContactsForList,
  LoadContactsForListSuccess,
  LoadContactsForListFailure,
  LoadAllContactsForList,
  LoadAllContactsForListSuccess,
  LoadAllContactsForListFailure,
  LoadPersonContactsForList,
  LoadPersonContactsForListSuccess,
  LoadPersonContactsForListFailure,
  LoadOnlyClientsForList,
  LoadOnlyClientsForListSuccess,
  LoadOnlyClientsForListFailure,
  LoadContactsForListDirector,
  LoadContactsForListDirectorSuccess,
  LoadContactsForListDirectorFailure,
  LoadActivePersonAndContactsForList,
  LoadActivePersonAndContactsForListSuccess,
  LoadActivePersonAndContactsForListFailure,
  UpdateOnlyClientsForList,
  UpdateOnlyClientsForListSuccess,
  UpdateOnlyClientsForListFailure,
  UpdateContactsForList,
  UpdateContactsForListSuccess,
  UpdateContactsForListFailure
} from '@app/modules/contacts/store/actions/contact.actions';
import { filter, mergeMap, share, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import {
  LoadUsersForList,
  LoadAllUsersForList,
  LoadAdministratorsAndAccountsForList
} from '@app/modules/settings/store/actions/team-member.actions';
import { MatterNoteService } from '@app/modules/matter-card/services/matter-note.service';

@Injectable()
export class ContactEffects {

  loadAllContactsForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadAllContactsForList>(EContactActions.LOAD_ALL_CONTACTS_FOR_LIST),
    switchMap(action => {
      return this.contactService
        .get(action.payload.page, action.payload.itemsPerPage, {
          'order[fullName]': 'ASC'
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadAllContactsForListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadAllContactsForListFailure(error)))
        );
    }),
    share()
  ));


  loadOnlyClientsForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadOnlyClientsForList>(EContactActions.LOAD_ONLY_CLIENTS_FOR_LIST),
    switchMap(action => {
      return this.contactService
        .get(action.payload.page, action.payload.itemsPerPage, {
          'exists[matters]': true
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadOnlyClientsForListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadOnlyClientsForListFailure(error)))
        );
    }),
    share()
  ));


  updateOnlyClientsForList$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateOnlyClientsForList>(EContactActions.UPDATE_ONLY_CLIENTS_FOR_LIST),
    switchMap(action => {
      return this.contactService.getById(action.payload).pipe(
        mergeMap(resp => {
          return [new UpdateOnlyClientsForListSuccess(resp)];
        }),
        catchError(error => of(new UpdateOnlyClientsForListFailure(error)))
      );
    }),
    share()
  ));


  loadPersonContactsForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPersonContactsForList>(EContactActions.LOAD_PERSON_CONTACTS_FOR_LIST),
    switchMap(action => {
      return this.contactService
        .get(action.payload.page, action.payload.itemsPerPage, {
          'order[fullName]': 'ASC',
          type: 'person'
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadPersonContactsForListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadPersonContactsForListFailure(error)))
        );
    }),
    share()
  ));


  loadaActivePersonAndContactsForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadActivePersonAndContactsForList>(EContactActions.LOAD_ACTIVE_PERSON_AND_CONTACTS_FOR_LIST),
    switchMap(action => {
      return this.contactService
        .get(action.payload.page, action.payload.itemsPerPage, {
          'order[fullName]': 'ASC',
          which_active_user: true
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadActivePersonAndContactsForListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadActivePersonAndContactsForListFailure(error)))
        );
    }),
    share()
  ));

  // status: 'active',
  // 'companyAccesses.enable': true

  loadContactsForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadContactsForList>(EContactActions.LOAD_CONTACTS_FOR_LIST),
    switchMap(action => {
      return this.contactService
        .get(action.payload.page, action.payload.itemsPerPage, {
          'order[fullName]': 'ASC',
          which_user_portal: true
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadContactsForListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadContactsForListFailure(error)))
        );
    }),
    share()
  ));


  updateContactsForList$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactsForList>(EContactActions.UPDATE_CONTACTS_FOR_LIST),
    switchMap(action => {
      return this.contactService.getById(action.payload).pipe(
        mergeMap(resp => {
          return [new UpdateContactsForListSuccess(resp)];
        }),
        catchError(error => of(new UpdateContactsForListFailure(error)))
      );
    }),
    share()
  ));


  loadContactsForListDirector$ = createEffect(() => this.actions$.pipe(
    ofType<LoadContactsForListDirector>(EContactActions.LOAD_CONTACTS_FOR_LIST_DIRECTOR),
    switchMap(action => {
      return this.contactService
        .get(action.payload.page, action.payload.itemsPerPage, {
          'order[fullName]': 'ASC',
          type: 'person',
          which_active_user: true,
          ...action.payload.filters
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadContactsForListDirectorSuccess(resp.member)];
          }),
          catchError(error => of(new LoadContactsForListDirectorFailure(error)))
        );
    }),
    share()
  ));


  create$ = createEffect(() => this.actions$.pipe(
    ofType<CreateContact>(EContactActions.CREATE_CONTACT),
    switchMap(action => {
      return this.contactService.setContact(action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          if (response.id) {
            this.toastrService.success(this.translate.instant('contactAdded'));
            if (action.payload.comment) {
              this.noteService.addNote({
                description: action.payload.comment,
                subject: response.fullName,
                contact: {id: response.id}}).subscribe();
            }
          } else {
            this.toastrService.error('Something went wrong! Please try again.', 'ERROR');
          }
          this.refreshContacts();
          return [new CreateContactSuccess(response, action.isNestedPerson, action.isMatter, action.createMatter)];
        })
      );
    }),
    share()
  ));


  remove$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveContact>(EContactActions.REMOVE_CONTACT),
    switchMap(action => {
      return this.contactService.removeContact(action.payload).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant('itemDeleted'));
          this.refreshContacts();
          return [new RemoveContactSuccess(action.payload)];
        })
      );
    }),
    share()
  ));


  fetch$ = createEffect(() => this.actions$.pipe(
    ofType<GetContacts>(EContactActions.GET_CONTACTS),
    switchMap(action =>
      this.contactService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        filter(x => !!x?.member),
        mergeMap(response => [new GetContactsSuccess(response)])
      )
    ),
    share()
  ));


  getById$ = createEffect(() => this.actions$.pipe(
    ofType<GetContactById>(EContactActions.GET_CONTACT_BY_ID),
    switchMap(action =>
      this.contactService.getById(action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => [new GetContactByIdSuccess(response)])
      )
    ),
    share()
  ));


  edit$ = createEffect(() => this.actions$.pipe(
    ofType<EditContact>(EContactActions.UPDATE_CONTACT),
    switchMap(action =>
      this.contactService.editContact(action.payload, action.id).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          if (response.id) {
            this.toastrService.success(this.translate.instant('contactEdited'));
          } else {
            this.toastrService.error('Something went wrong! Please try again.', 'ERROR');
          }
          this.refreshContacts();
          return [new EditContactSuccess(response, action.createMatter)];
        })
      )
    ),
    share()
  ));

  refreshContacts() {
    this.store.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
    this.store.dispatch(
      new LoadAdministratorsAndAccountsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect })
    );
    this.store.dispatch(new LoadAllUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));

    this.store.dispatch(new LoadOnlyClientsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
    this.store.dispatch(new LoadAllContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
    this.store.dispatch(new LoadContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
    this.store.dispatch(new LoadPersonContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
  }
  constructor(
    private actions$: Actions,
    private contactService: ContactService,
    private toastrService: ToastrService,
    private store: Store<IAppState>,
    private noteService: MatterNoteService,
    private translate: TranslateService
  ) {}
}
