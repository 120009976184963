import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SwitchNotificationsService {
  public notifications = {
    notification: false,
    news: false,
    chat: false,
    communication: false
  };

  constructor() {}

  switchNotifications(element) {
    if (element === 'notification') {
      this.notifications.news = false;
      this.notifications.notification = !this.notifications.notification;
    } else if (element === 'news') {
      this.notifications.notification = false;
      this.notifications.news = !this.notifications.news;
    } else if (element === 'chat') {
      this.notifications.notification = false;
      this.notifications.news = false;
      this.notifications.chat = !this.notifications.chat;
    } else if (element === 'disable') {
      this.notifications.news = false;
      this.notifications.notification = false;
      this.notifications.chat = false;
      this.notifications.communication = false;
    } else if (element === 'communication') {
      this.notifications.news = false;
      this.notifications.notification = false;
      this.notifications.chat = false;
      this.notifications.communication = !this.notifications.communication;
    }
  }
}
