export interface Payload {
  companySettingsId: number;
  accountId: number;
  companyId: number;
  exp: number;
  iat: number;
  roles: Array<string>;
  username: string;
  id: number;
  hasAutoPaySubscription?: boolean;
  expiredAt?: any;
  tariff: any;
  tariffId: any;
  currentSubscriptionId: number;
  locale?: string;
}
