import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { environment } from '@environments/environment';
import { Team } from '@app/models/team';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { Paginator } from '@app/models/paginator';

@Injectable()
export class TeamService {
  private BASE_URL = `${environment.apiUrl}/company_work_groups`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  get(currentPage = 1, itemsPerPage = environment.defaultItemsCount) {
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<Team>>(`${this.BASE_URL}?partial=false&page=${currentPage}&itemsPerPage=${itemsPerPage}`)
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<Team>>();
    }
  }

  // TODO check
  getAll(filters = {}, itemsPerPage = environment.defaultMaxItemsCount, exludeGroups?): Observable<Paginator<Team>> {
    let params = new HttpParams();
    params = Helpers.populateFilters(params, filters);
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<Team>>(`${this.BASE_URL}?partial=false&page=1&itemsPerPage=${itemsPerPage}${this.getExludeGroupsFilter(exludeGroups)}`, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<Team>>();
    }
  }
  getExludeGroupsFilter(ids) {
    let strFilter = '';
    for (let i = 0; i < ids?.length; i++) {
       if (ids[i] && typeof ids[i] === 'number' ) {
        strFilter += `&exclude[]=${ids[i]}`;
      }
    }
    return strFilter;
  }
  createTeam(data): Observable<Team> {
    return this.http.post<Team>(`${this.BASE_URL}`, data);
  }

  getTeamById(id: number): Observable<Team> {
    if (this.authService.currentUserValue) {
      return this.http.get<Team>(`${this.BASE_URL}/${id}`);
    } else {
      return new Observable<Team>();
    }
  }

  deleteTeam(id: number) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  updateTeam(id: number, team: Team): Observable<Team> {
    return this.http.patch<Team>(`${this.BASE_URL}/${id}`, team);
  }
}
