import { Paginator } from '@app/models/paginator';
import { MatterExtended } from '@app/models/matter-extended';
import { environment } from '@environments/environment';

export interface IMatterState {
  paginator: Paginator<MatterExtended>;
  // selectedMatter: MatterExtended;
  listMatterForSelect: MatterExtended[];
  listAllMatterForSelect: MatterExtended[];
  create: MatterExtended;
  trackContactMatter: {
    contact?: number;
    responsible?: number;
  };
}

export const initialMatterState: IMatterState = {
  paginator: {
    member: null,
    countItems: null,
    totalItems: null,
    currentPage: environment.defaultPage,
    itemsPerPage: environment.defaultItemsCount,
    lastPage: null
  },
  listMatterForSelect: null,
  listAllMatterForSelect: null,
  create: null,
  trackContactMatter: { contact: null, responsible: null }
};
