import { PaymentState } from '@app/models/interfaces/payment-state';

export const paymentState: PaymentState = {
  payments: {},
  transactions: {},
  selected: null,
  paginator: null,
  transactionPaginator: null,
  transactionSummary: null
};
