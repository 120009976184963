import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AuthProxyService } from './_services/auth-proxy.service';
import { AuthService } from './_services/auth.service';
import { AuthRoutingModule } from './auth.routing';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { AuthComponent } from './components/auth.component';
import { PipesModule } from '@app/common/pipes/pipes.module';
import { RegisterComponent } from './components/register/register.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { PasswordCreateComponent } from './components/password-create/password-create.component';
import { ComboBoxModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { PerfectScrollbarModule } from '@app/perfect-scrollbar/perfect-scrollbar.module';
import { NgxMaskDirective } from 'ngx-mask';
import { LoaderModule } from '@app/commonComponents/loader/loader.module';

@NgModule({
  declarations: [
    LoginComponent,
    AuthComponent,
    RegisterComponent,
    ForgotComponent,
    PasswordCreateComponent,
    ReviewsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    TranslateModule,
    PipesModule,
    DropDownListModule,
    ComboBoxModule,
    PerfectScrollbarModule,
    NgxMaskDirective,
    LoaderModule
  ],
  providers: [
    AuthGuard,
    {
      provide: AuthProxyService,
      useClass: AuthProxyService
    },
    {
      provide: AuthService,
      useClass: AuthService
    }
  ]
})
export class AuthModule {
}
