import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadCompanySettingAction,
  CompanySettingActionTypes,
  LoadCompanySettingSuccessAction,
  LoadCompanySettingFailureAction,
  UpdateCompanySettingAction,
  UpdateCompanySettingSuccessAction,
  LoadSubscriptionSubs,
  LoadSubscriptionSubSuccess,
  LoadCompanySubSetting,
  LoadCompanySubSettingSuccess,
  LoadSubTariff,
  LoadSubTariffSuccess,
  LoadSubscribedSubscriptionSuccess,
  LoadSubscribedSubscription,
  LoadTariff,
  LoadTariffSuccess,
  UpdateCompanySettingFailureAction
} from '../actions/company-setting.actions';
import { switchMap, mergeMap, catchError, tap, share } from 'rxjs/operators';
import { CompanySettingService } from '../../services/company-setting.service';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SubscriptionsSubService } from '../../services/subscriptions-sub.service';
import { TariffsApi } from '@app/api/tariffs.api';


@Injectable()
export class CompanySettingEffects {

  loadCompanySetting$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCompanySettingAction>(CompanySettingActionTypes.LOAD_COMPANY_SETTING),
    switchMap(() =>
      this.companySettingService.getCompanySetting().pipe(
        mergeMap(data => {
          if (data) {
            return of(new LoadCompanySettingSuccessAction(data));
          }
        }),
        catchError(error => {
          return of(new LoadCompanySettingFailureAction(error));
        })
      )
    ),
    share()
  ));

  loadSubscriptionSubs$ = createEffect(() => this.actions$.pipe(
    ofType<LoadSubscriptionSubs>(CompanySettingActionTypes.LOAD_SUBSCRIPTIONS_SUBS),
    switchMap(() =>
      this.subscriptionsSubService.getSubscriptionSub().pipe(
        mergeMap(data => {
          if (data) {
            return [new LoadSubscriptionSubSuccess(data)];
          }
        })
      )
    ),
    share()
  ));

  loadSubTariff$ = createEffect(() => this.actions$.pipe(
    ofType<LoadSubTariff>(CompanySettingActionTypes.LOAD_SUB_TARIFF),
    switchMap(action =>
      this.tariffsApi.getSubTariff().pipe(
        mergeMap(data => {
          if (data) {
            return [new LoadSubTariffSuccess(data, action.payload)];
          }
        })
      )
    ),
    share()
  ));


  loadTariff$ = createEffect(() => this.actions$.pipe(
    ofType<LoadTariff>(CompanySettingActionTypes.LOAD_TARIFF),
    switchMap(() =>
      this.tariffsApi.getAll().pipe(
        mergeMap(data => {
          if (data?.['hydra:member']) {
            return [new LoadTariffSuccess(data['hydra:member'])];
          }
        })
      )
    ),
    share()
  ));


  loadSubscribedSubscription$ = createEffect(() => this.actions$.pipe(
    ofType<LoadSubscribedSubscription>(CompanySettingActionTypes.LOAD_SUBSCRIBED_SUBSCRIPTION_SUB),
    switchMap(() =>
      this.subscriptionsSubService.getSubscribedSubscriptionSub().pipe(
        mergeMap(data => {
          if (data) {
            return [new LoadSubscribedSubscriptionSuccess(data)];
          }
        })
      )
    ),
    share()
  ));


  loadCompanySubSetting$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCompanySubSetting>(CompanySettingActionTypes.LOAD_COMPANY_SUB_SETTING),
    switchMap(() =>
      this.subscriptionsSubService.getCompanySubSettings().pipe(
        mergeMap(data => {
          if (data) {
            return [new LoadCompanySubSettingSuccess(data)];
          }
        })
      )
    ),
    share()
  ));


  updateCompanySetting$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCompanySettingAction>(CompanySettingActionTypes.UPDATE_COMPANY_SETTING),
    switchMap(action => {
      const withoutRoute = action.payload['withoutRoute'];
      delete action.payload['withoutRoute'];
      return this.companySettingService.updateCompanySettings(action.payload).pipe(
        mergeMap(x => {
          x['withoutRoute'] = withoutRoute;
          return of(new UpdateCompanySettingSuccessAction(x));
        }),
        catchError((error) => {
          return of(new UpdateCompanySettingFailureAction(error));
        })
      );
    }),
    share()
  ));

  updateCompanySettingSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCompanySettingSuccessAction>(CompanySettingActionTypes.UPDATE_COMPANY_SETTING_SUCCESS),
    tap((data) => {
      if (!data.payload['withoutRoute']) {
        this.toastrService.success(this.translate.instant('itemEdited'));
        this.router.navigateByUrl(`/settings`);
      }
    })
  ), { dispatch: false });

  updateCompanySettingFailure$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCompanySettingFailureAction>(CompanySettingActionTypes.UPDATE_COMPANY_SETTING_FAILURE),
    tap((error) => {
      console.log(error);
      // TODO catch error
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private companySettingService: CompanySettingService,
    private subscriptionsSubService: SubscriptionsSubService,
    private tariffsApi: TariffsApi
  ) {}
}
