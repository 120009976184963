import { Paginator } from '@app/models/paginator';
import { Action } from '@ngrx/store';
import { TimeEntryType } from '@app/models';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum ETimeEntryTypesActions {
  LOAD_TIME_ENTRY_TYPES = '[TIME_ENTRY_TYPES] Load TimeEntryTypes',
  LOAD_TIME_ENTRY_TYPES_SUCCESS = '[TIME_ENTRY_TYPES] Load TimeEntryTypes Success',
  LOAD_TIME_ENTRY_TYPES_FAILURE = '[TIME_ENTRY_TYPES] Load TimeEntryTypes Failure',
  GET_TIME_ENTRY_TYPES = '[TimeEntryTypes] Get Time Entry Types',
  GET_TIME_ENTRY_TYPES_SUCCESS = '[TimeEntryTypes] Get Time Entry Types Success',
  GET_TIME_ENTRY_TYPES_FAILURE = '[TimeEntryTypes] Get Time Entry Types Failure',
  ADD_TIME_ENTRY_TYPE = '[TimeEntryTypes] Add Time Entry Type',
  ADD_TIME_ENTRY_TYPE_SUCCESS = '[TimeEntryTypes] Add Time Entry Type Success',
  ADD_TIME_ENTRY_TYPE_FAILURE = '[TimeEntryTypes] Add Time Entry Type Failure',
  UPDATE_TIME_ENTRY_TYPE = '[TimeEntryTypes] Update Time Entry Type',
  UPDATE_TIME_ENTRY_TYPE_SUCCESS = '[TimeEntryTypes] Update Time Entry Type Success',
  UPDATE_TIME_ENTRY_TYPE_FAILURE = '[TimeEntryTypes] Update Time Entry Type Failure',
  DELETE_TIME_ENTRY_TYPE = '[TimeEntryTypes] Delete Time Entry Type',
  DELETE_TIME_ENTRY_TYPE_SUCCESS = '[TimeEntryTypes] Delete Time Entry Type Success',
  DELETE_TIME_ENTRY_TYPE_FAILURE = '[TimeEntryTypes] Delete Time Entry Type Failure',
  DELETE_TIME_ENTRY_TYPES = '[TimeEntryTypes] Delete Time Entry Types',
  DELETE_TIME_ENTRY_TYPES_SUCCESS = '[TimeEntryTypes] Delete Time Entry Types Success',
  DELETE_TIME_ENTRY_TYPES_FAILURE = '[TimeEntryTypes] Delete Time Entry Types Failure'
}

export class LoadTimeEntryTypesAction implements Action {
  readonly type = ETimeEntryTypesActions.LOAD_TIME_ENTRY_TYPES;
}

export class LoadTimeEntryTypesSuccessAction implements Action {
  readonly type = ETimeEntryTypesActions.LOAD_TIME_ENTRY_TYPES_SUCCESS;

  constructor(public payload: Paginator<TimeEntryType>) {
  }
}

export class LoadTimeEntryTypesFailureAction implements Action {
  readonly type = ETimeEntryTypesActions.LOAD_TIME_ENTRY_TYPES_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetTimeEntryTypes implements Action {
  public readonly type = ETimeEntryTypesActions.GET_TIME_ENTRY_TYPES;

  constructor(public payload: FetchPayload<any>) {
  }
}

export class GetTimeEntryTypesSuccess implements Action {
  public readonly type = ETimeEntryTypesActions.GET_TIME_ENTRY_TYPES_SUCCESS;

  constructor(public payload: Paginator<TimeEntryType>) {
  }
}

export class GetTimeEntryTypesFailure implements Action {
  readonly type = ETimeEntryTypesActions.GET_TIME_ENTRY_TYPES_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddTimeEntryType implements Action {
  public readonly type = ETimeEntryTypesActions.ADD_TIME_ENTRY_TYPE;

  constructor(public payload: TimeEntryType) {
  }
}

export class AddTimeEntryTypeSuccess implements Action {
  public readonly type = ETimeEntryTypesActions.ADD_TIME_ENTRY_TYPE_SUCCESS;

  constructor(public payload: TimeEntryType) {
  }
}

export class AddTimeEntryTypeFailure implements Action {
  readonly type = ETimeEntryTypesActions.ADD_TIME_ENTRY_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateTimeEntryType implements Action {
  public readonly type = ETimeEntryTypesActions.UPDATE_TIME_ENTRY_TYPE;

  constructor(public payload: TimeEntryType) {
  }
}

export class UpdateTimeEntryTypeSuccess implements Action {
  public readonly type = ETimeEntryTypesActions.UPDATE_TIME_ENTRY_TYPE_SUCCESS;

  constructor(public payload: TimeEntryType) {
  }
}

export class UpdateTimeEntryTypeFailure implements Action {
  readonly type = ETimeEntryTypesActions.UPDATE_TIME_ENTRY_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteTimeEntryType implements Action {
  public readonly type = ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPE;

  constructor(public payload: number[]) {
  }
}

export class DeleteTimeEntryTypeSuccess implements Action {
  public readonly type = ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPE_SUCCESS;

  constructor() {
  }
}

export class DeleteTimeEntryTypeFailure implements Action {
  readonly type = ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteTimeEntryTypes implements Action {
  public readonly type = ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPES;

  constructor(public payload: number[]) {
  }
}

export class DeleteTimeEntryTypesSuccess implements Action {
  public readonly type = ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPES_SUCCESS;

  constructor(public payload: number[]) {
  }
}

export class DeleteTimeEntryTypesFailure implements Action {
  readonly type = ETimeEntryTypesActions.DELETE_TIME_ENTRY_TYPES_FAILURE;

  constructor(public payload: Error) {
  }
}

export type TimeEntryTypeActions =
  | LoadTimeEntryTypesAction
  | LoadTimeEntryTypesSuccessAction
  | LoadTimeEntryTypesFailureAction
  | GetTimeEntryTypes
  | GetTimeEntryTypesSuccess
  | GetTimeEntryTypesFailure
  | AddTimeEntryType
  | AddTimeEntryTypeSuccess
  | AddTimeEntryTypeFailure
  | UpdateTimeEntryType
  | UpdateTimeEntryTypeSuccess
  | UpdateTimeEntryTypeFailure
  | DeleteTimeEntryType
  | DeleteTimeEntryTypeSuccess
  | DeleteTimeEntryTypeFailure
  | DeleteTimeEntryTypes
  | DeleteTimeEntryTypesSuccess
  | DeleteTimeEntryTypesFailure;
