import { IAppState } from '@app/store/state/app.state';
import { Bill } from '@app/models/bill';

export const selectBillIfChangeState = (state: IAppState) => state.billState;

export const selectBillsList = (state: IAppState): Bill[] => Object.values(state.billState.bills);

export const selectPaginationData = (state: IAppState) => state.billState.paginator;

export const selectSelectedBill = (state: IAppState) => state.billState.selected;

export const selectTotal = (state: IAppState) => state.billState.total;
