import { Injectable } from '@angular/core';
import { TagService } from '@app/api/tag.service';
import { IAppState } from '@app/store/state/app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { mergeMap, share, switchMap } from 'rxjs/operators';
import {
  AddTag,
  AddTagSuccess,
  DeleteTag,
  DeleteTagList,
  DeleteTagListSuccess,
  DeleteTagSuccess,
  GetAllTagPaginator,
  GetAllTagPaginatorSuccess,
  GetAllTags,
  GetAllTagsSuccess,
  TagActionEnum,
  UpdateTag,
  UpdateTagSuccess
} from '../actions/tag.actions';

@Injectable()
export class TagTypeEffects {
  constructor(
    private actions$: Actions,
    private tagService: TagService,
    private store$: Store<IAppState>,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}

  getAllTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllTags>(TagActionEnum.GET_ALL_TAGS),
      switchMap(action => {
        return this.tagService.getAllTags().pipe(
          mergeMap((res) => {
            return [new GetAllTagsSuccess(res['hydra:member'])];
          })
        );
      }),
      share()
    )
  );

  getAllTagPaginator$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllTagPaginator>(TagActionEnum.GET_ALL_TAGS_PAGINATOR),
      switchMap(action => {
        return this.tagService.getAllTagPaginator(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
          mergeMap(res => {
            return [new GetAllTagPaginatorSuccess(res)];
          })
        );
      }),
      share()
    )
  );

  addTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddTag>(TagActionEnum.ADD_TAG),
      switchMap(action => {
        return this.tagService.addTag(action.tag).pipe(
          mergeMap(res => {
            this.store$.dispatch(new GetAllTagPaginator({ page: 1, itemsPerPage: 10000 }));
            return of(new AddTagSuccess(res));
          })
        );
      }),
      share()
    )
  );

  updateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTag>(TagActionEnum.UPDATE_TAG),
      switchMap(action => {
        return this.tagService.updateTag(action.tag).pipe(
          mergeMap(() => {
            this.store$.dispatch(new GetAllTagPaginator({ page: 1, itemsPerPage: 10000 }));
            return of(new UpdateTagSuccess());
          })
        );
      }),
      share()
    )
  );

  deleteTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTag>(TagActionEnum.DELETE_TAG),
      switchMap(action => {
        return this.tagService.deleteTag(action.tagId).pipe(
          mergeMap(() => {
            this.toastrService.error(this.translate.instant('itemDeleted'));
            this.store$.dispatch(new GetAllTagPaginator({ page: 1, itemsPerPage: 10000 }));
            return of(new DeleteTagSuccess());
          })
        );
      }),
      share()
    )
  );

  deleteTagList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTagList>(TagActionEnum.DELETE_TAG_LIST),
      switchMap(action => {
        const deletedTadList = [];
        action.tagIdList.forEach(x => {
          deletedTadList.push(this.tagService.deleteTag(x));
        });
        return forkJoin(deletedTadList).pipe(
          mergeMap(() => {
            this.toastrService.error(this.translate.instant('itemsDeleted'));
            this.store$.dispatch(new GetAllTagPaginator({ page: 1, itemsPerPage: 10000 }));
            return of(new DeleteTagListSuccess());
          })
        );
      })
    )
  );
}
