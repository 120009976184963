import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { Login } from '@app/auth/_store/actions/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import { IUser } from '@app/models';
import { AuthEffects } from '@app/auth/_store/effects/auth.effects';
import { ILogin } from '@app/models/iLogin';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { disappearanceAnimation } from '@app/helpers';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [disappearanceAnimation]
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  submitted = false;
  loading: boolean;

  two_factor_authentication = false;
  codeFail = false;
  resendCode = false;
  showPass = false;
  constructor(
    private store: Store<IAppState>,
    private formBuilder: UntypedFormBuilder,
    private authEffect: AuthEffects,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {
    this.authEffect.login$.subscribe((resp) => {
      if (resp.payload['detail'] === 'Two factor authentication false') {
        this.codeFail = true;
      } else {
        const payload = resp.payload;
        this.two_factor_authentication = payload['2fa'];
        if (this.two_factor_authentication) {
          this.loginForm.get('2fa').setValidators([
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(5)]);
          this.submitted = false;
        }
      }
      if (this.resendCode) {
        this.toastrService.success(this.translate.instant('login.two_factor_code.resendPopup'));
      }
      this.loading = false;
    });
  }

  ngOnInit() {
    this.dialog.closeAll();

    this.initForm();
  }


  initForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.maxLength(20)]],
      ['2fa']: ['']
    });
  }

  onSubmit(resend = false): void {
    this.resendCode = resend;
    this.submitted = !resend;
    if (this.loginForm.invalid && !resend) {
      return;
    }
    const payload = {
      username: this.form.username,
      password: this.form.password
    } as IUser;
    if (this.two_factor_authentication && !resend) {
      payload['2fa'] = this.form['2fa'];
    }
    this.loading = true;
    this.store.dispatch(new Login(payload));
  }

  get form(): ILogin {
    return this.loginForm && this.loginForm.value;
  }

  pasteCode() {
    setTimeout(() => {
      this.onSubmit();
    }, 100);
  }
}
