import { Action } from '@ngrx/store';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { BankAccountSave } from '@app/models/bank-account-save';
import { Paginator } from '@app/models/paginator';
import { BankAccountsTotal } from '@app/models/interfaces/bankAccounts-total';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum EBankAccountsActions {
  GET_BANK_ACCOUNTS = '[BankAccounts] Get Bank Accounts',
  GET_BANK_ACCOUNTS_SUCCESS = '[BankAccounts] Get Bank Accounts Success',
  GET_BANK_ACCOUNTS_FAILURE = '[BankAccounts] Get Bank Accounts Failure',
  GET_BANK_ACCOUNTS_BY_CURRENCY = '[BankAccounts] Get Bank Accounts By Currency',
  GET_BANK_ACCOUNTS_BY_CURRENCY_SUCCESS = '[BankAccounts] Get Bank Accounts By Currency Success',
  GET_BANK_ACCOUNTS_BY_CURRENCY_FAILURE = '[BankAccounts] Get Bank Accounts By Currency Failure',
  GET_BANK_ACCOUNT = '[BankAccounts] Get Bank Account',
  GET_BANK_ACCOUNT_SUCCESS = '[BankAccounts] Get Bank Account Success',
  GET_BANK_ACCOUNT_FAILURE = '[BankAccounts] Get Bank Account Failure',
  GET_BANK_ACCOUNT_TOTAL = '[BankAccounts] Get Bank Account Total',
  GET_BANK_ACCOUNT_TOTAL_SUCCESS = '[BankAccounts] Get Bank Account Total Success',
  GET_BANK_ACCOUNT_TOTAL_FAILURE = '[BankAccounts] Get Bank Account Total Failure',
  ADD_BANK_ACCOUNT = '[BankAccounts] Add Bank Account',
  ADD_BANK_ACCOUNT_SUCCESS = '[BankAccounts] Add Bank Account Success',
  ADD_BANK_ACCOUNT_FAILURE = '[BankAccounts] Add Bank Account Failure',
  UPDATE_BANK_ACCOUNT = '[BankAccounts] Update Bank Account',
  UPDATE_BANK_ACCOUNT_SUCCESS = '[BankAccounts] Update Bank Account Success',
  UPDATE_BANK_ACCOUNT_FAILURE = '[BankAccounts] Update Bank Account Failure',
  DELETE_BANK_ACCOUNT = '[BankAccounts] Delete Bank Account',
  DELETE_BANK_ACCOUNT_SUCCESS = '[BankAccounts] Delete Bank Account Success',
  DELETE_BANK_ACCOUNT_FAILURE = '[BankAccounts] Delete Bank Account Failure',
  CLEAR_BANK_ACCOUNTS = '[BankAccounts] Clear Bank Accounts'
}

export class GetBankAccounts implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNTS;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetBankAccountsSuccess implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNTS_SUCCESS;

  constructor(public payload: Paginator<BankAccountExtended>) {}
}

export class GetBankAccountsFailure implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNTS_FAILURE;

  constructor(public error: Error) {}
}

export class GetBankAccountsByCurrency implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNTS_BY_CURRENCY;

  constructor(public payload: string) {}
}

export class GetBankAccountsByCurrencySuccess implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNTS_BY_CURRENCY_SUCCESS;

  constructor(public payload: BankAccountExtended[]) {}
}

export class GetBankAccountsByCurrencyFailure implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNTS_BY_CURRENCY_FAILURE;

  constructor(public error: Error) {}
}

export class GetBankAccount implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNT;

  constructor(public payload: number) {}
}

export class GetBankAccountSuccess implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNT_SUCCESS;

  constructor(public payload: BankAccountExtended) {}
}

export class GetBankAccountFailure implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNT_FAILURE;

  constructor(public error: Error) {}
}

export class AddBankAccount implements Action {
  public readonly type = EBankAccountsActions.ADD_BANK_ACCOUNT;

  constructor(public payload: BankAccountSave) {}
}

export class AddBankAccountSuccess implements Action {
  public readonly type = EBankAccountsActions.ADD_BANK_ACCOUNT_SUCCESS;

  constructor(public payload: BankAccountExtended) {}
}

export class AddBankAccountFailure implements Action {
  public readonly type = EBankAccountsActions.ADD_BANK_ACCOUNT_FAILURE;

  constructor(public error: Error) {}
}

export class UpdateBankAccount implements Action {
  public readonly type = EBankAccountsActions.UPDATE_BANK_ACCOUNT;

  constructor(public payload: BankAccountSave) {}
}

export class UpdateBankAccountSuccess implements Action {
  public readonly type = EBankAccountsActions.UPDATE_BANK_ACCOUNT_SUCCESS;

  constructor(public payload: BankAccountExtended) {}
}

export class UpdateBankAccountFailure implements Action {
  public readonly type = EBankAccountsActions.UPDATE_BANK_ACCOUNT_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteBankAccount implements Action {
  public readonly type = EBankAccountsActions.DELETE_BANK_ACCOUNT;

  constructor(public payload: number) {}
}

export class DeleteBankAccountSuccess implements Action {
  public readonly type = EBankAccountsActions.DELETE_BANK_ACCOUNT_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteBankAccountFailure implements Action {
  public readonly type = EBankAccountsActions.DELETE_BANK_ACCOUNT_FAILURE;

  constructor(public error: Error) {}
}

export class GetBankAccountTotal implements Action {
  readonly type = EBankAccountsActions.GET_BANK_ACCOUNT_TOTAL;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetBankAccountTotalSuccess implements Action {
  readonly type = EBankAccountsActions.GET_BANK_ACCOUNT_TOTAL_SUCCESS;

  constructor(public payload: BankAccountsTotal) {}
}

export class GetBankAccountTotalFailure implements Action {
  public readonly type = EBankAccountsActions.GET_BANK_ACCOUNT_TOTAL_FAILURE;

  constructor(public error: Error) {}
}

export class ClearBankAccounts implements Action {
  readonly type = EBankAccountsActions.CLEAR_BANK_ACCOUNTS;
}

export type BankAccountActions =
  | AddBankAccount
  | AddBankAccountSuccess
  | AddBankAccountFailure
  | ClearBankAccounts
  | DeleteBankAccount
  | DeleteBankAccountSuccess
  | DeleteBankAccountFailure
  | GetBankAccounts
  | GetBankAccountsSuccess
  | GetBankAccountsFailure
  | GetBankAccount
  | GetBankAccountSuccess
  | GetBankAccountFailure
  | GetBankAccountsByCurrency
  | GetBankAccountsByCurrencySuccess
  | GetBankAccountsByCurrencyFailure
  | GetBankAccountTotal
  | GetBankAccountTotalSuccess
  | GetBankAccountTotalFailure
  | UpdateBankAccount
  | UpdateBankAccountSuccess
  | UpdateBankAccountFailure;
