export enum ExpenseTypeEnum {
  COMMON = 'common',
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export interface ExpenseType {
  id?: number;
  name: string;
  type: ExpenseTypeEnum;
  parentId?: number;
  parent?: string;
  children?: ExpenseType[];
}
