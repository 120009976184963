import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, withLatestFrom, map, share } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import {
  GetPersonTypes,
  EPersonTypesActions,
  GetPersonTypesSuccess,
  AddPersonType,
  AddPersonTypeSuccess,
  UpdatePersonType,
  UpdatePersonTypeSuccess,
  DeletePersonType,
  DeletePersonTypeSuccess,
  DeletePersonTypes,
  DeletePersonTypesSuccess,
  LoadPersonTypesFailureAction,
  LoadPersonTypesAction,
  LoadPersonTypesSuccessAction,
  GetPersonTypesFailure,
  AddPersonTypeFailure,
  DeletePersonTypesFailure
} from '../actions/person-types.actions';
import { PersonTypeService } from '../../services/person-type.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PersonTypeEffects {
   loadPersonTypes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPersonTypesAction>(EPersonTypesActions.LOAD_PERSON_TYPES),
    mergeMap(() =>
      this.personTypeService.getPersonTypes(1, 1000).pipe(
        map(data => new LoadPersonTypesSuccessAction(data.member)),
        catchError(error => of(new LoadPersonTypesFailureAction(error)))
      )
    )
  ));


  getPersonTypes$ = createEffect(() => this.actions$.pipe(
    ofType<GetPersonTypes>(EPersonTypesActions.GET_PERSON_TYPES),
    switchMap(action => {
      return this.personTypeService.getPersonTypes(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new GetPersonTypesSuccess(resp)];
        }),
        catchError((error) => {
          // will add error handler later
          return of(new GetPersonTypesFailure(error));
        })
      );
    }),
    share()
  ));


  addPersonType$ = createEffect(() => this.actions$.pipe(
    ofType<AddPersonType>(EPersonTypesActions.ADD_PERSON_TYPE),
    switchMap(action => {
      return this.personTypeService.addPersonType(action.payload).pipe(
        mergeMap(x => {
          return of(new AddPersonTypeSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new AddPersonTypeFailure(error));
        })
      );
    })
  ));


  addPersonTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddPersonTypeSuccess>(EPersonTypesActions.ADD_PERSON_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetPersonTypes({
          page: storeState.personTypeState.paginator.currentPage,
          itemsPerPage: storeState.personTypeState.paginator.itemsPerPage
        })
      );
    })
  ));


  updatePersonType$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePersonType>(EPersonTypesActions.UPDATE_PERSON_TYPE),
    switchMap(action => {
      return this.personTypeService.updatePersonType(action.payload).pipe(
        mergeMap(x => {
          return of(new UpdatePersonTypeSuccess(x));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new UpdatePersonType(error));
        })
      );
    })
  ));


  updatePersonTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePersonTypeSuccess>(EPersonTypesActions.UPDATE_PERSON_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      return of(
        new GetPersonTypes({
          page: storeState.personTypeState.paginator.currentPage,
          itemsPerPage: storeState.personTypeState.paginator.itemsPerPage
        })
      );
    })
  ));


  deletePersonType$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePersonType>(EPersonTypesActions.DELETE_PERSON_TYPE),
    switchMap(action => {
      return this.personTypeService.deletePersonType(action.payload).pipe(
        mergeMap(() => {
          return of(new DeletePersonTypeSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeletePersonType(error));
        })
      );
    })
  ));


  deletePersonTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePersonTypeSuccess>(EPersonTypesActions.DELETE_PERSON_TYPE_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemDeleted'));
      return of(
        new GetPersonTypes({
          page: storeState.personTypeState.paginator.currentPage,
          itemsPerPage: storeState.personTypeState.paginator.itemsPerPage
        })
      );
    })
  ));


  deletePersonTypes$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePersonTypes>(EPersonTypesActions.DELETE_PERSON_TYPES),
    switchMap(action => {
      const deleteMethods = [];
      action.payload.forEach(x => {
        deleteMethods.push(this.personTypeService.deletePersonType(x));
      });
      return forkJoin(deleteMethods).pipe(
        mergeMap(() => {
          return of(new DeletePersonTypesSuccess(action.payload));
        }),
        catchError((error) => {
          // will add error handler later
          return of(new DeletePersonTypesFailure(error));
        })
      );
    })
  ));


  deletePersonTypesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePersonTypesSuccess>(EPersonTypesActions.DELETE_PERSON_TYPES_SUCCESS),
    withLatestFrom(this.store$),
    switchMap(([, storeState]) => {
      this.toastrService.error(this.translate.instant('itemsDeleted'));
      return of(
        new GetPersonTypes({
          page: storeState.personTypeState.paginator.currentPage,
          itemsPerPage: storeState.personTypeState.paginator.itemsPerPage
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private personTypeService: PersonTypeService,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {}
}
