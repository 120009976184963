// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-red,
.menu-red span {
  color: #b22b2b;
}

.menu-green,
.menu-green .menu-icon span {
  color: #2db67d;
}

.j2-icon-pinned {
  color: #fb310d !important;
}

.column-menu {
  height: 100%;
}
.column-menu .column-menu-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 65px;
  margin-left: auto;
  overflow: hidden;
}
.column-menu .column-menu-wrapper .column-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 23px;
  height: 23px;
  border-radius: 6px;
}
.column-menu .column-menu-wrapper .column-item .menu-icon {
  display: flex;
  height: inherit;
  width: inherit;
  align-items: center;
  justify-content: center;
}
.column-menu .column-menu-wrapper .column-item .menu-icon span {
  height: auto;
  color: #8C97A2;
}
.column-menu .column-menu-wrapper .column-item:hover {
  background: #ECEEF2;
}
.column-menu .column-menu-wrapper .column-item:hover .menu-icon span {
  color: #414853;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/change-column/row-menu/row-menu.component.scss"],"names":[],"mappings":"AACE;;EAEE,cAAA;AAAJ;;AAKE;;EAEE,cAAA;AAFJ;;AAKA;EACE,yBAAA;AAFF;;AAKA;EACE,YAAA;AAFF;AAIE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAFJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AADN;AAGM;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;AADR;AAEQ;EACE,YAAA;EACA,cAAA;AAAV;AAGM;EACE,mBAAA;AADR;AAGU;EACE,cAAA;AADZ","sourcesContent":[".menu-red {\n  &,\n  span {\n    color: #b22b2b;\n  }\n}\n\n.menu-green {\n  &,\n  .menu-icon span {\n    color: #2db67d;\n  }\n}\n.j2-icon-pinned {\n  color: #fb310d !important;\n}\n\n.column-menu {\n  height: 100%;\n\n  .column-menu-wrapper {\n    display: flex;\n    align-items: center;\n    height: 100%;\n    padding-left: 65px;\n    margin-left: auto;\n    overflow: hidden;\n    .column-item {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      gap: 3px;\n      width: 23px;\n      height: 23px;\n      border-radius: 6px;\n\n      .menu-icon {\n        display: flex;\n        height: inherit;\n        width: inherit;\n        align-items: center;\n        justify-content: center;\n        span {\n          height: auto;\n          color: #8C97A2;\n        }\n      }\n      &:hover {\n        background: #ECEEF2;\n        .menu-icon {\n          span {\n            color: #414853;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
