import { IAuthState, initialAuthState } from '../state/auth.state';
import { AuthActions, EAuthActions } from '../actions/auth.actions';

export const authReducers = (state = initialAuthState, action: AuthActions): IAuthState => {
  switch (action.type) {
    case EAuthActions.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: !!action.payload,
        user: null,
        errorMessage: null
      };
    }
    case EAuthActions.SAVE_JWT_PAYLOAD: {
      return {
        ...state,
        userPayload: action.payload
      };
    }
    case EAuthActions.LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload?.['hydra:description']
      };
    }
    case EAuthActions.REGISTER_SUCCESS: {
      return {
        ...state,
        requestStatus: !!action.payload.id
      };
    }
    case EAuthActions.REGISTER_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload?.['hydra:description'] || ''
      };
    }
    case EAuthActions.GET_CURRENT_USER: {
      return { ...state };
    }

    case EAuthActions.GET_CURRENT_USER_SUCCESS: {
      return { ...state, user: action.payload };
    }

    case EAuthActions.UPDATE_USER_SUCCESS: {
      return { ...state, user: action.payload };
    }

    case EAuthActions.UPDATE_SIDEBAR_STATUS_SUCCESS: {
      return { ...state, user: action.payload };
    }

    case EAuthActions.OPTIMISTIC_UPDATE_VIBER: {
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            [0]: {
              ...state.user.settings[0],
              viberAccount: {
                ...state.user.settings[0].viberAccount,
                enabled: action.payload
              }
            }
          }
        }
      };
    }

    case EAuthActions.OPTIMISTIC_UPDATE_TELEGRAM: {
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            [0]: {
              ...state.user.settings[0],
              telegramAccount: {
                ...state.user.settings[0].telegramAccount,
                enabled: action.payload
              }
            }
          }
        }
      };
    }

    case EAuthActions.UPDATE_USER_LANG_SUCCESS: {
      return { ...state, user: action.payload };
    }

    case EAuthActions.LOGOUT:
    case EAuthActions.FORGOT_CONFIRM:
    case EAuthActions.FORGOT_CONFIRM_SUCCESS:
    case EAuthActions.FORGOT_CONFIRM_FAILURE:
    default: {
      return state;
    }
  }
};
