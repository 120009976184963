export interface TimeEntrySave {
  id?: number;
  duration?: number;
  rate?: string;
  timeEntryType?: number;
  date: string;
  description?: string;
  currency: string;
  matter?: number;
  responsiblePerson: number;
  document?: number;
  task?: number;
  nonBillable?: boolean;
}
