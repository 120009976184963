import { initialTemplateState, ITemplateState } from '../state/template.state';
import { TemplateActions, ETemplatesActions } from '../actions/template.actions';

// export const templateReducers = (state = initialTemplateState, action: TemplateActions): ITemplateState => {
export const templateReducers = (state = initialTemplateState, action: TemplateActions): ITemplateState => {
  switch (action.type) {
    case ETemplatesActions.GET_TEMPLATES: {
      return {
        ...state,
        paginator: {
          countItems: null,
          member: null,
          totalItems: null,
          currentPage: null,
          itemsPerPage: null,
          lastPage: null
        }
      };
    }
    case ETemplatesActions.GET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case ETemplatesActions.GET_TEMPLATE: {
      return {
        ...state
      };
    }
    case ETemplatesActions.GET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        currentTemplate: action.payload
      };
    }
    case ETemplatesActions.ADD_TEMPLATE_SUCCESS: {
      if (state.paginator.member !== null) {
        state.paginator.member.push(action.payload);
      }
      return {
        ...state,
        currentTemplate: action.payload
      };
    }
    case ETemplatesActions.UPDATE_TEMPLATE:
      return {
        ...state
      };
    case ETemplatesActions.UPDATE_TEMPLATE_SUCCESS: {
      for (let index = 0; index < state.paginator?.member?.length; index++) {
        if (state.paginator.member[index].id === action.payload.id) {
          state.paginator.member[index] = action.payload;
        }
      }
      return {
        ...state
      };
    }

    case ETemplatesActions.DELETE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        paginator: {
          countItems: state.paginator.countItems - 1,
          member: state.paginator.member.filter(item => item.id !== action.payload),
          totalItems: state.paginator.totalItems - 1,
          currentPage: state.paginator.currentPage,
          itemsPerPage: state.paginator.itemsPerPage,
          lastPage: state.paginator.lastPage
        }
      };
    }
    default: {
      return state;
    }
  }
};
