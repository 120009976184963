import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { checkTariff } from '@app/helpers/check-tariff';

@Directive({
  selector: '[appCheckTariff]'
})
export class CheckTariffDirective {
  private viewRef: EmbeddedViewRef<any> | null = null;
  private plans: any;

  @Input()
  set appCheckTariff(tariff: number | number[]) {
    this.plans = tariff;
    this.viewRef = null;
    this.check();
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  check() {
    const isAllowed = checkTariff(this.plans);
    if (isAllowed) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
    }
  }
}
