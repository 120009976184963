import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { SharedEnumService } from '@app/enums/shared-enum.service';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { Template } from '@app/models/template.model';
import { AuthService } from '@app/auth/_services/auth.service';

@Component({
  selector: 'app-templates-save-modal',
  templateUrl: './templates-save-modal.component.html',
  styleUrls: ['./templates-save-modal.component.scss']
})
export class TemplatesSaveModalComponent implements OnInit {
  templateTypes;
  submittedForm = false;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  sharedData = new SharedEnumService(this.translate);

  constructor(
    public dialogRef: MatDialogRef<TemplatesSaveModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { template?: Template; file?: File },
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private appSettingsService: AppSettingsService,
    private authService: AuthService
  ) {}
  templateForm: UntypedFormGroup;
  public locale$ = this.appSettingsService.getLang;

  ngOnInit() {
    this.getTypeTemplate();
    this.createForm();
  }

  getTypeTemplate() {
    this.templateTypes = this.sharedData.enumTemplateType;
    if (this.authService.currentUserValue.tariffId === 2) {
      this.templateTypes = this.templateTypes.filter(item => item.value !== 'bill');
    }
  }

  private createForm() {
    this.templateForm = this.formBuilder.group({
      id: this.formBuilder.control(this.data && this.data.template ? this.data.template.id : null),
      name: this.formBuilder.control(this.data && this.data.template ? this.data.template.name : null, [Validators.required]),
      type: this.formBuilder.control(this.data && this.data.template ? this.data.template.type : 'contact', [Validators.required])
    });
    if (this.data && this.data.file) {
      this.templateForm.patchValue({
        name: this.data.file.name.substr(0, this.data.file.name.length - 5)
      });
    }
  }
  onSubmit() {
    this.submittedForm = true;
    if (!this.templateForm.valid) {
      return;
    }
    this.dialogRef.close(this.templateForm.value);
  }
  get f() {
    return this.templateForm;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
}
