import { initialPersonTypeState, IPersonTypeState } from '../state/person-type.state';

import { PersonTypeActions, EPersonTypesActions } from '../actions/person-types.actions';

export const personTypeReducers = (state = initialPersonTypeState, action: PersonTypeActions): IPersonTypeState => {
  switch (action.type) {
    case EPersonTypesActions.LOAD_PERSON_TYPES:
      return {
        ...state,
        loading: true
      };
    case EPersonTypesActions.LOAD_PERSON_TYPES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    case EPersonTypesActions.LOAD_PERSON_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case EPersonTypesActions.GET_PERSON_TYPES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload,
        list: action.payload.member
      };
    }
    case EPersonTypesActions.ADD_PERSON_TYPE_SUCCESS: {
      return {
        ...state
      };
    }
    case EPersonTypesActions.UPDATE_PERSON_TYPE_SUCCESS: {
      return {
        ...state
      };
    }

    case EPersonTypesActions.DELETE_PERSON_TYPE_SUCCESS: {
      return {
        ...state
      };
    }

    case EPersonTypesActions.DELETE_PERSON_TYPES_SUCCESS: {
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
