// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container textarea {
  height: 83px;
}

.modal-template.default-modal .modal-footer .text-container {
  margin-right: auto;
  margin-left: inherit;
}
.modal-template.default-modal .modal-footer .button-container {
  width: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/relations-modal/relations-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGE;EACE,kBAAA;EACA,oBAAA;AAAJ;AAEE;EACE,YAAA;AAAJ","sourcesContent":[".input-container textarea {\n  height: 83px;\n}\n\n.modal-template.default-modal .modal-footer {\n  .text-container {\n    margin-right: auto;\n    margin-left: inherit;\n  }\n  .button-container {\n    width: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
