import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fadeTopAnimation } from '@app/helpers/fadeAnimation';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/_services/auth.service';
import { DocumentSave } from '@app/models/document-save';

import { DeleteActivityDocuments, GetDocumentsByActivity, AddDocuments } from '@app/store/actions/document.actions';
import { Contact, ExpenseType, IUser, ActivityExtended } from '@app/models';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectBankAccountsByCurrency } from '@app/store/selectors/bank-account.selector';
import { GetBankAccountsByCurrency } from '@app/store/actions/bank-account.actions';
import { Subscription } from 'rxjs';
import { Helpers } from '@app/helpers/helpers';
import { IAppState } from '@app/store/state/app.state';
import { selectAllContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { selectCurrentActivityDocuments } from '@app/store/selectors/document.selectors';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { ExpenseInternalSave } from '@app/models/expense-internal-save';
import { ToastrService } from 'ngx-toastr';
import { DocumentUploadModalComponent } from '@app/commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { LoadAllContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { environment } from '@environments/environment';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectInternalExpenseTypes } from '@app/modules/settings/store/selectors/expence-type.selector';
import { LoadInternalExpenseTypesAction } from '@app/modules/settings/store/actions/expense-types.actions';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { ComboBoxComponent, DropDownTreeComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { UpdateActivity, AddActivity } from '@app/store/actions/activities.actions';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { DocRelation } from '@app/models/doc-relation';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { ReminderExtended } from '@app/models/reminder-extended';
import { DebounceService } from '../../../helpers/debounce.service';
import { InternalExpenseModalDebounceEnum } from '../../../enums/debounce-keys.enum';

@Component({
  selector: 'app-internal-expense-modal',
  templateUrl: './internal-expense-modal.component.html',
  styleUrls: ['./internal-expense-modal.component.scss'],
  animations: [fadeTopAnimation]
})
export class InternalExpenseModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<InternalExpenseModalComponent>,
    private translate: TranslateService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      internalExpense?: ActivityExtended;
      bankAccount?: BankAccountExtended;
    },
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private contactService: ContactService,
    private teamMemberService: TeamMemberService,
    private store: Store<IAppState>,
    private toastr: ToastrService,
    private appSettingsService: AppSettingsService,
    private toastrService: ToastrService,
    private debounceService: DebounceService
  ) {}

  get f() {
    return this.internalExpenseForm;
  }
  private subscription = new Subscription();
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  dateFormat = CustomDateAdapter.dateFormat;
  internalExpenseForm: UntypedFormGroup;
  submittedForm = false;
  payer: Contact;
  users: IUser[];
  vendors: Contact[];
  bankAccounts: BankAccountExtended[];
  currencies = Helpers.getCurrenciesList();
  expenseTypes: ExpenseType[]; // = this.store.pipe(select(selectExpenseTypes));
  public fieldsExpenseTypes: Object;
  @ViewChild('dropdowntree') dropdownExpenseTree: DropDownTreeComponent;

  // pairs of documents and media to save them when expense is saved
  documentsToAdd: { document: DocumentSave; mediaFile: File; reminders: ReminderExtended; relations: DocRelation[] }[] = [];
  // documents ids to delete when expense is saved
  documentsToDelete: number[] = [];
  // documents to show in list
  documentsToShow: { name: string; id?: number; media: { type: 'document' } }[] = [];
  fileData: File = null;

  enabled = false;

  initBankAccounts = false;

  @ViewChild('file', { static: false }) inputFile: ElementRef;
  public locale$ = this.appSettingsService.getLang;
  public sorting = 'Ascending';

  filtersContacts = {
    'order[fullName]': 'ASC',
    name: ''
  };
  @ViewChild('contactsComboBox', { static: false }) public comboBoxContact: ComboBoxComponent;

  filtersUser: TeamMemberFilter = {
    name: '',
    status: 'active',
    'companyAccesses.enable': true
  };
  descIn = 'unfocus';

  @ViewChild('usersComboBox', { static: false }) public comboBoxUser: ComboBoxComponent;

  ngOnInit() {
    this.createForm();
    if (this.data.bankAccount) {
      this.store.dispatch(new GetBankAccountsByCurrency(this.data.bankAccount.currency.code));
    }
    if (this.data.internalExpense) {
      this.store.dispatch(new GetBankAccountsByCurrency(this.data.internalExpense.currency.code));
    }
    this.populateInternalData();
    this.checkIsManyCurrency();
  }

  checkIsManyCurrency() {
    const subscr = this.store.pipe(select(selectCompanySetting)).subscribe(data => {
      if (!data.isManyCurrency) {
        this.enabled = true;
      }
    });
    this.subscription.add(subscr);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  saveExpenseAndClose() {
    this.checkSubmit();
    this.internalExpenseForm.updateValueAndValidity();
    if (!this.internalExpenseForm.valid) {
      return;
    }

    if (this.data && this.data.internalExpense) {
      this.updateExpense();
    } else {
      // this.internalExpenseForm.get('vendor').setValue(this.payer.id);
      this.addExpense();
    }
    this.dialogRef.close(true);
  }

  saveExpenseAndClear(): void {
    this.checkSubmit();
    this.internalExpenseForm.updateValueAndValidity();
    if (!this.internalExpenseForm.valid) {
      return;
    }
    if (this.data && this.data.internalExpense) {
      this.updateExpense();
    } else {
      this.addExpense();
    }
    this.documentsToShow = [];
    this.documentsToAdd = [];
    this.documentsToDelete = [];
    if (this.data && this.data.internalExpense) {
      delete this.data.internalExpense;
    }
    this.resetFormValues();
  }

  saveExpenseAndContinue(): void {
    this.checkSubmit();
    this.internalExpenseForm.updateValueAndValidity();
    if (!this.internalExpenseForm.valid) {
      return;
    }
    if (this.data && this.data.internalExpense) {
      this.updateExpense();
    } else {
      this.addExpense();
    }
    this.documentsToShow = [];
    this.documentsToAdd = [];
    this.documentsToDelete = [];
    if (this.data && this.data.internalExpense) {
      delete this.data.internalExpense;
    }
  }

  populateInternalData() {
    this.setCurrency();

    if (this.data.bankAccount && this.data.bankAccount.contact) {
      this.payer = this.data.bankAccount.contact;
    }

    if (this.data && this.data.internalExpense) {
      this.payer = this.data.internalExpense.companyBankAccount?.contact;
      this.store.dispatch(new GetDocumentsByActivity(this.data.internalExpense.id));
      this.initFormValues(this.data.internalExpense);
      const documentsSubscr = this.store.pipe(select(selectCurrentActivityDocuments)).subscribe(x => {
        this.documentsToShow = [];
        if (x && x.length > 0) {
          this.documentsToShow = x.map(doc => {
            return { name: doc.name, id: doc.id, media: { type: 'document' } };
          });
        }
      });
      this.subscription.add(documentsSubscr);
    }
    this.setSelectModels();
  }

  onFileChanged(fileInput) {
    this.fileData = fileInput.target.files[0] as File;
    if (!Helpers.checkSizeFile(this.fileData, 20)) {
      this.fileData = null;
      this.toastrService.error(this.translate.instant('documents.fileTooBig20'));
      return;
    }
    if (this.fileData) {
      this.documentUploadModal(this.fileData);
    }
    this.inputFile.nativeElement.value = null;
  }

  checkSubmit() {
    this.submittedForm = true;
  }

  setBankAccount() {
    this.internalExpenseForm.get('companyBankAccount').patchValue(this.data.bankAccount ? this.data.bankAccount.id : null);
    if (this.data.internalExpense && !this.data.bankAccount) {
      this.internalExpenseForm.get('companyBankAccount').setValue(this.data.internalExpense.companyBankAccount ? +this.data.internalExpense.companyBankAccount.id : null);
    }
  }

  setCurrency() {
    this.internalExpenseForm.get('currency').patchValue(this.data.bankAccount ? this.data.bankAccount.currency.code : null);
  }

  updatePayer(bankAccount) {
    if (bankAccount && bankAccount.itemData) {
      this.payer = bankAccount.itemData.contact;
    }
  }

  updateSelectValue(fieldName) {
    const fieldValue = this.internalExpenseForm.get(fieldName);
    if (fieldValue) {
      this.internalExpenseForm.get(fieldName).patchValue(fieldValue.value);
    }
  }

  changeCurrency(event) {
    const currency = event.value;
    this.internalExpenseForm.get('companyBankAccount').reset();
    if (currency && currency !== this.internalExpenseForm.get('currency')) {
      this.store.dispatch(new GetBankAccountsByCurrency(currency));
    }
  }

  deleteDocument(document: { name: string; id?: number; media: { type: 'document' } }) {
    if (document.id) {
      this.documentsToDelete.push(document.id);
    } else {
      const position = this.documentsToAdd.indexOf(this.documentsToAdd.filter(x => x.document.name === document.name)[0]);
      if (position !== -1) {
        this.documentsToAdd.splice(position, 1);
      }
    }
    this.documentsToShow.splice(this.documentsToShow.indexOf(document), 1);
  }

  private updateExpense(): void {
    const expense = this.formDataToExpenseSave(true);

    expense.expenseType = this.dropdownExpenseTree?.value ? +this.dropdownExpenseTree?.value[0] : null;

    this.store.dispatch(new UpdateActivity({ activity: expense, typeActivity: 'expense_internals' }));
    if (this.documentsToAdd.length > 0) {
      this.store.dispatch(new AddDocuments(this.documentsToAdd));
    }
    if (this.documentsToDelete.length > 0) {
      this.store.dispatch(new DeleteActivityDocuments({ documents: this.documentsToDelete, activity: expense.id }));
    }
  }

  private addExpense(): void {
    const expense = this.formDataToExpenseSave(false);

    expense.expenseType = this.dropdownExpenseTree?.value ? +this.dropdownExpenseTree?.value[0] : null;

    if (this.documentsToAdd.length > 0) {
      this.store.dispatch(new AddActivity({ activity: expense, typeActivity: 'expense_internals', documents: this.documentsToAdd }));
    } else {
      this.store.dispatch(new AddActivity({ activity: expense, typeActivity: 'expense_internals' }));
    }
  }

  private isAccountPayable(accountId: number, amount: string) {
    const account = this.bankAccounts.filter(x => x.id === accountId)[0];
    const isPayable = account && +account.balance >= +amount;
    if (!isPayable) {
      // для того,щоб підставити значення в переклади, потрібно звести їх до одного параметра, а не до 3, як тут.
      // тоді можна буде попробувати шось типу this.translate.instant('AccountError', { param: account.accountName });
      this.toastr.error(
        `На рахунку ${account.accountName} наявна сума ${account.balance} ${account.currency.code}.
      Сума недостатня для проведення цієї трансакції`,
        'ERROR',
        { closeButton: true, timeOut: 5000 }
      );
    }
    return isPayable;
  }

  private formDataToExpenseSave(isUpdated: boolean): ExpenseInternalSave {
    const internalExpense: ExpenseInternalSave = {
      id: isUpdated ? this.data.internalExpense.id : null,
      amount: this.internalExpenseForm.get('amount').value.toString(),
      vendor: this.internalExpenseForm.get('vendor').value ? (this.internalExpenseForm.get('vendor').value as number) : null,
      currency: this.internalExpenseForm.get('currency').value,
      date: CustomDateAdapter.convertToUniversalDateString(this.internalExpenseForm.get('date').value),
      description: this.internalExpenseForm.get('description').value,
      responsiblePerson: this.internalExpenseForm.get('responsiblePerson').value as number,
      expenseType: this.internalExpenseForm.get('expenseType').value as number,
      companyBankAccount: this.internalExpenseForm.get('companyBankAccount').value as number
    };
    return internalExpense;
  }

  private documentUploadModal(file: File) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { fileName: file.name, skipMatter: true };
    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    const subscr = dialogRef.afterClosed().subscribe(x => {
      if (x) {
        x.document.activity = this.data.internalExpense ? this.data.internalExpense['@id'] : null;
        this.documentsToAdd.push({ document: x.document, mediaFile: this.fileData, reminders: x.reminders, relations: x.relations });
        this.documentsToShow.push({ name: x.name, media: { type: 'document' } });
      }
    });
    this.subscription.add(subscr);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }
  private setSelectModels(): void {
    this.loadUsers();

    this.loadContacts();
    this.loadExpenseTypes();
    this.store.pipe(select(selectBankAccountsByCurrency)).subscribe(x => {
      if (x) {
        this.bankAccounts = x;

        if (!this.initBankAccounts && this.bankAccounts.length) {
          this.initBankAccounts = true;
          this.setBankAccount();
        }
      }
    });
  }

  private resetFormValues(): void {
    this.internalExpenseForm.get('amount').reset();
    this.internalExpenseForm.get('vendor').reset();
    this.internalExpenseForm.get('matter').reset();
    this.internalExpenseForm.get('expenseType').reset();
    this.internalExpenseForm.get('date').setValue(new Date());
    this.internalExpenseForm.get('description').setValue('');
    this.internalExpenseForm.get('currency').setValue(this.data.bankAccount.currency.code);
    this.internalExpenseForm.get('responsiblePerson').setValue(this.authService.currentUserValue.id);
    this.internalExpenseForm.get('companyBankAccount').setValue(this.data.bankAccount.id);
    this.payer = this.data.bankAccount.contact;
    this.submittedForm = false;
  }

  private createForm() {
    this.internalExpenseForm = this.formBuilder.group({
      amount: this.formBuilder.control(null, [Validators.required, Validators.min(0)]),
      vendor: this.formBuilder.control(null),
      matter: this.formBuilder.control([Validators.required]),
      expenseType: this.formBuilder.control(null),
      currency: this.formBuilder.control(null, [Validators.required]),
      date: this.formBuilder.control(new Date(), [Validators.required]),
      description: this.formBuilder.control(''),
      responsiblePerson: this.formBuilder.control(this.authService.currentUserValue.id, [Validators.required]),
      companyBankAccount: this.formBuilder.control(null, [Validators.required])
    });
  }

  private initFormValues(expense: ActivityExtended): void {
    this.internalExpenseForm.get('amount').setValue(expense.amount.toString());
    this.internalExpenseForm.get('vendor').setValue(expense.vendor ? expense.vendor.id : null);
    this.internalExpenseForm.get('matter').setValue(expense.matter ? expense.matter.id : null);
    this.internalExpenseForm.get('expenseType').setValue(expense.expenseType ? expense.expenseType.id : null);
    this.internalExpenseForm.get('currency').setValue(expense.currency.code);
    this.internalExpenseForm.get('date').setValue(new Date(expense.date));
    this.internalExpenseForm.get('description').setValue(expense.description);
    this.internalExpenseForm.get('responsiblePerson').setValue(expense.responsiblePerson ? expense.responsiblePerson.id : null);
    this.internalExpenseForm.get('companyBankAccount').setValue(expense.companyBankAccount ? +expense.companyBankAccount.id : null);
  }

  public closeModal() {
    this.dialogRef.close();
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
  loadContacts() {
    this.subscription.add(
      this.store.select(selectAllContactListForSelect).subscribe(data => {
        if (!data) {
          this.store.dispatch(new LoadAllContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (data) {
          this.vendors = data;
          if (this.data && this.data.internalExpense) {
            if (!this.vendors.find(vendor => vendor.id === this.data.internalExpense?.vendor?.id)) {
              this.vendors.push(this.data.internalExpense.vendor);
            }
            this.internalExpenseForm.get('vendor').setValue(this.data.internalExpense.vendor ? this.data.internalExpense.vendor.id : null);
          }
        }
      })
    );
  }
  loadUsers() {
    this.subscription.add(
      this.store.select(selectListUsersForSelect).subscribe(resp => {
        if (resp == null) {
          this.store.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (resp) {
          this.users = resp;
          this.internalExpenseForm
            .get('responsiblePerson')
            .setValue(this.authService.currentUserValue ? this.authService.currentUserValue.id : null);
          if (this.data && this.data.internalExpense) {
            this.internalExpenseForm
              .get('responsiblePerson')
              .setValue(this.data.internalExpense.responsiblePerson ? this.data.internalExpense.responsiblePerson.id : null);
          }
        }
      })
    );
  }
  loadExpenseTypes() {
    this.subscription.add(
      this.store.select(selectInternalExpenseTypes).subscribe(data => {
        if (!data) {
          this.store.dispatch(new LoadInternalExpenseTypesAction());
        } else if (data) {
          this.expenseTypes = data;
          this.fieldsExpenseTypes = { dataSource: this.expenseTypes, value: 'id', parentValue: 'parentId', text: 'name', child: 'children' };
          if (this.data && this.data.internalExpense) {
            this.internalExpenseForm.get('expenseType').setValue(this.data.internalExpense.expenseType ? this.data.internalExpense.expenseType.id : null);
            setTimeout(() => {
              this.dropdownExpenseTree && (this.dropdownExpenseTree.value = this.data.internalExpense.expenseType ? [this.data.internalExpense.expenseType.id + ''] : []);
            }, 100);
          }
        }
      })
    );
  }
  onDropDownCreated(e) {
    setTimeout(() => {
      this.dropdownExpenseTree && (this.dropdownExpenseTree.value = this.data.internalExpense?.expenseType ? [this.data.internalExpense.expenseType.id + ''] : []);
    }, 100);
  }
  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersContacts.name = e.text;
    this.comboBoxContact?.showSpinner();
    this.debounceService.loadDataDebounce(
      InternalExpenseModalDebounceEnum.contacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(InternalExpenseModalDebounceEnum.contacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.vendors = resp?.member as any;
      this.comboBoxContact?.hideSpinner();
    });
  }

  public onFilteringUsers: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersUser.name = e.text;
    this.comboBoxUser?.showSpinner();
    this.debounceService.loadDataDebounce(
      InternalExpenseModalDebounceEnum.users,
      this.teamMemberService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersUser]);
    this.debounceService.getValue(InternalExpenseModalDebounceEnum.users).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxUser?.hideSpinner();
    });
  }

  public filterExpenseTypes: EmitType<any> = (e: FilteringEventArgs) => {
    if (e.text === '') {
      e.updateData(this.expenseTypes as any);
    } else {
      const filteringData = [];
      this.expenseTypes.forEach(i => {
        if (i.name.toUpperCase().indexOf(e.text.toUpperCase()) !== -1) {
          filteringData.push(i);
        }
      });
      e.updateData(filteringData);
    }
  }

  fixAmount(event) {
    setTimeout(() => {
      let temp = event.target.value;
      const currentValue = temp.split('.');
      if (currentValue.length > 2) {
        currentValue.pop();
        temp = currentValue[0] + '.' + currentValue[1];
      }
      const countAfter = currentValue[currentValue.length - 1].length;
      if (temp.includes('.') && countAfter > 2) {
        if (currentValue[1][0] > 9) {
          temp = currentValue[0] + '.' + '9' + currentValue[1][1];
        } else {
          temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
        }
      }
      this.internalExpenseForm.get('amount').setValue(temp);
    }, 100);

    if ((event.which >= 48 && event.which <= 57) || event.which === 46) {
      return true;
    }
    return false;
  }
}
