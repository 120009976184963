import { IAppState } from '@app/store/state/app.state';

export const selectCompanySetting = (state: IAppState) => state.companyState.companySetting;
export const selectSubscriptionJuscourt = (state: IAppState) =>
  state.companyState.subscriptionSubs && state.companyState.subscriptionSubs.filter(subs => subs.subType === 'juscourt');

export const selectSubscriptionDocusign = (state: IAppState) =>
  state.companyState.subscriptionSubs && state.companyState.subscriptionSubs.filter(subs => subs.subType === 'docusign');

export const selectCompanySubSetting = (state: IAppState) => state.companyState.companySubSettings;

export const selectSubTariffs = (state: IAppState) => state.companyState.subTariffs;

export const selectTariffs = (state: IAppState) => state.companyState.tariffs;

export const selectSubscribedSubscription = (state: IAppState) => state.companyState.subscribedSubscription;

export const selectAllCompanySetting = (state: IAppState) => state.companyState;
