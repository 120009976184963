import { initialNotificationState } from '@app/modules/layout/notifications/store/state/notification.state';
import { ENotificationActions } from '@app/modules/layout/notifications/store/actions/notification.actions';
import * as Actions from '../actions/notification.actions';
export const notificationReducers = (state = initialNotificationState, action: Actions.NotificationActions) => {
  switch (action.type) {
    case ENotificationActions.GET_NOTIFICATION_SUCCESS: {
      let nextPage = state.page;
      if (action.payload.member.length) {
        nextPage += 1;
      }
      if (!action.currentPage) {
        nextPage = 1;
      }
      if (action.renew) {
        state.data = [];
        nextPage = 2;
      }
      action.payload.member = state.data.concat(action.payload.member);

      return { ...state, ...action.payload, data: action.payload.member, page: nextPage };
    }
    case ENotificationActions.ISREAD_NOTIFICATION: {
      const messages = state.data;
      if (action.payload.message.isRead === false) {
        messages[action.payload.id] = { ...action.payload.message, isRead: true };
      } else {
        messages[action.payload.id] = { ...action.payload.message, isRead: false };
      }
      return { ...state, data: messages };
    }
    case ENotificationActions.ADD_NOTIFICATION: {
      const data = state.data;
      if (action.payload.data.comment) {
        action.payload.data.comment = JSON.parse(action.payload.data.comment);
        data.unshift(action.payload.data);
      }
      return { ...state, data };
    }

    case ENotificationActions.GET_COUNT_UNREAD_SUCCESS: {
      return { ...state, countUnRead: action.payload };
    }

    default:
      return state;
  }
};
