import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dynamic-input]'
})
export class DynamicInputDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.resize();
  }

  @HostListener('keypress') onKeyPress() {
    this.resize();
  }

  @HostListener('keydown') onKeyDown() {
    this.resize();
  }

  private resize() {
    setTimeout(() => {
      this.el.nativeElement.setAttribute('size', this.el.nativeElement.value.length === 0 ? 1 : this.el.nativeElement.value.length + 1);
    }, 0);
  }
}
