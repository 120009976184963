import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, share, switchMap, tap } from 'rxjs/operators';

import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
  AddActivity,
  AddActivityFailure,
  AddActivitySuccess,
  DeleteActivity,
  DeleteActivityFailure,
  DeleteActivitySuccess,
  EActivitiesActions,
  GetActivities,
  GetActivitiesFailure,
  GetActivitiesSuccess,
  GetActivitiesTotal,
  GetActivitiesTotalFailure,
  GetActivitiesTotalSuccess,
  GetActivity,
  GetActivityFailure,
  GetActivitySuccess,
  UpdateActivity,
  UpdateActivityFailure,
  UpdateActivitySuccess
} from '../actions/activities.actions';
import { ActivityExtended } from '@app/models';
import { ActivitiesService } from '@app/modules/main-activities/services/activities.service';
import { AddDocuments } from '../actions/document.actions';

@Injectable()
export class ActivitiesEffects {

  getActivities$ = createEffect(() => this.actions$.pipe(
    ofType<GetActivities>(EActivitiesActions.GET_ACTIVITIES),
    switchMap(action => {
      return this.activitiesService
        .getActivities(
          action.payload.fetchPayload.page,
          action.payload.fetchPayload.itemsPerPage,
          action.payload.fetchPayload.filters,
          action.payload.typeActivity
        )
        .pipe(
          mergeMap(resp => {
            return [new GetActivitiesSuccess(resp)];
          }),
          catchError(error => of(new GetActivitiesFailure(error)))
        );
    }),
    share()
  ));

  getActivitiesTotal$ = createEffect(() => this.actions$.pipe(
    ofType<GetActivitiesTotal>(EActivitiesActions.GET_ACTIVITIES_TOTAL),
    switchMap(action => {
      return this.activitiesService
        .getActivitiesTotal(
          action.payload.fetchPayload.page,
          action.payload.fetchPayload.itemsPerPage,
          action.payload.fetchPayload.filters,
          action.payload.typeActivityTotal
        )
        .pipe(
          mergeMap(resp => {
            return [new GetActivitiesTotalSuccess(resp)];
          }),
          catchError(error => of(new GetActivitiesTotalFailure(error)))
        );
    }),
    share()
  ));


  getActivity$ = createEffect(() => this.actions$.pipe(
    ofType<GetActivity>(EActivitiesActions.GET_ACTIVITY),
    switchMap(action => {
      if (action.payload == null) {
        return [new GetActivitySuccess(null)];
      }
      return this.activitiesService.getActivityById(action.payload.id, action.payload.typeActivity).pipe(
        mergeMap(resp => {
          return [new GetActivitySuccess(resp as ActivityExtended)];
        }),
        catchError(error => of(new GetActivityFailure(error)))
      );
    })
  ));


  addActivity$ = createEffect(() => this.actions$.pipe(
    ofType<AddActivity>(EActivitiesActions.ADD_ACTIVITY),
    switchMap(action => {
      return this.activitiesService.addActivity(action.payload.activity, action.payload.typeActivity).pipe(
        switchMap(resp => {
          if (action.payload.documents) {
            action.payload.documents.forEach(x => {
              x.document.activity = resp['@id'];
            });
            this.toastrService.success(this.translate.instant(`${action.payload.typeActivity}Added`));
            return [new AddActivitySuccess(resp), new AddDocuments(action.payload.documents)];
          }
          this.toastrService.success(this.translate.instant(`${action.payload.typeActivity}Added`));
          return of(new AddActivitySuccess(resp));
        }),
        catchError(error => of(new AddActivityFailure(error)))
      );
    }),
    share()
  ));

  addActivitySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddActivitySuccess>(EActivitiesActions.ADD_ACTIVITY_SUCCESS),
    tap(() => {
    })
  ), { dispatch: false });


  updateActivity$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateActivity>(EActivitiesActions.UPDATE_ACTIVITY),
    switchMap(action => {
      return this.activitiesService.updateActivity(action.payload.activity, action.payload.typeActivity).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant(`${action.payload.typeActivity}Edited`));
            return of(new UpdateActivitySuccess(x));
          }
          return of(new UpdateActivityFailure(new Error()));
        }),
        catchError(error => of(new UpdateActivityFailure(error)))
      );
    }),
    share()
  ));


  updateActivitySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateActivitySuccess>(EActivitiesActions.UPDATE_ACTIVITY_SUCCESS),
    tap(() => {
      // this.location.back();
    })
  ), { dispatch: false });


  deleteActivity$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteActivity>(EActivitiesActions.DELETE_ACTIVITY),
    switchMap(action => {
      return this.activitiesService.deleteActivity(action.payload.id, action.payload.typeActivity).pipe(
        mergeMap(() => {
          this.toastrService.error(this.translate.instant(`${action.payload.typeActivity}Deleted`));
          return of(new DeleteActivitySuccess(action.payload));
        }),
        catchError(error => of(new DeleteActivityFailure(error)))
      );
    }),
    share()
  ));


  deleteActivitySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteActivitySuccess>(EActivitiesActions.DELETE_ACTIVITY_SUCCESS),
    switchMap(() => {
      return of(new GetActivitySuccess(null));
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private activitiesService: ActivitiesService,
    private translate: TranslateService
  ) {
  }
}
