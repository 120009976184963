import { Paginator } from '@app/models/paginator';
import { TaskExtended } from '@app/models';
import { TasksFilters } from '@app/models/tasks-filters';
import { environment } from '@environments/environment';

export interface ITaskState {
  paginator: Paginator<TaskExtended>;
  selectedTask: TaskExtended;
  filters: TasksFilters;
}

export const initialTaskState: ITaskState = {
  paginator: {
    member: null,
    countItems: null,
    totalItems: null,
    currentPage: environment.defaultPage,
    itemsPerPage: environment.defaultItemsCount,
    lastPage: null
  },
  selectedTask: null,
  filters: {}
};
