import { Injectable } from '@angular/core';
import { NewsService } from '@app/modules/layout/news/services/news.service';
import * as newsActions from '../actions/news.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, share, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@app/auth/_services/auth.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';

@Injectable()
export class NewsEffects {
  constructor(
    private actions$: Actions,
    private newsService: NewsService,
    private teamMemberService: TeamMemberService,
    private authService: AuthService
  ) {}


  getNews$ = createEffect(() => this.actions$.pipe(
    ofType<newsActions.GetNews>(newsActions.ENewsActions.GET_NEWS),
    switchMap(action => {
      return this.newsService.getAllNews(action.payload.page ? action.payload.page : 1, action.payload.itemsPerPage).pipe(
        mergeMap(resp => {
          return [new newsActions.GetNewsSuccess(resp, action.payload.page, action.payload.renew)];
        })
      );
    }),
    share()
  ));


  likeNews$ = createEffect(() => this.actions$.pipe(
    ofType(newsActions.ENewsActions.LIKE_NEWS),
    tap((action: any) => {
      this.teamMemberService.changeOneUser(this.authService.getPayload().id, { likeNews: [`news/${action.payload.news.id}`] }).subscribe();
    })
  ), { dispatch: false });


  isReadedNews$ = createEffect(() => this.actions$.pipe(
    ofType(newsActions.ENewsActions.READ_NEWS),
    tap((action: any) => {
      this.teamMemberService.changeOneUser(this.authService.getPayload().id, { readNews: [`news/${action.payload.id}`] }).subscribe();
    })
  ), { dispatch: false });
}
