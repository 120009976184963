import { Paginator } from '@app/models/paginator';
import { Action } from '@ngrx/store';
import { DocumentCategory } from '../../../../models/index';
import { FetchPayload } from '@app/models/interfaces/fetch-payload';

export enum EDocumentCategoriesActions {
  LOAD_DOCUMENT_CATEGORIES = '[DOCUMENT_CATEGORIES] Load Document Categories',
  LOAD_DOCUMENT_CATEGORIES_SUCCESS = '[DOCUMENT_CATEGORIES] Load Document Categories Success',
  LOAD_DOCUMENT_CATEGORIES_FAILURE = '[DOCUMENT_CATEGORIES] Load Document Categories Failure',
  GET_DOCUMENT_CATEGORIES = '[DocumentCategories] Get Document Categories',
  GET_DOCUMENT_CATEGORIES_SUCCESS = '[DocumentCategories] Get Document Categories Success',
  GET_DOCUMENT_CATEGORIES_FAILURE = '[DocumentCategories] Get Document Categories Failure',
  ADD_DOCUMENT_CATEGORY = '[DocumentCategories] Add Document Category',
  ADD_DOCUMENT_CATEGORY_SUCCESS = '[DocumentCategories] Add Document Category Success',
  ADD_DOCUMENT_CATEGORY_FAILURE = '[DocumentCategories] Add Document Category Failure',
  UPDATE_DOCUMENT_CATEGORY = '[DocumentCategories] Update Document Category',
  UPDATE_DOCUMENT_CATEGORY_SUCCESS = '[DocumentCategories] Update Document Category Success',
  UPDATE_DOCUMENT_CATEGORY_FAILURE = '[DocumentCategories] Update Document Category Failure',
  DELETE_DOCUMENT_CATEGORY = '[DocumentCategories] Delete Document Category',
  DELETE_DOCUMENT_CATEGORY_SUCCESS = '[DocumentCategories] Delete Document Category Success',
  DELETE_DOCUMENT_CATEGORY_FAILURE = '[DocumentCategories] Delete Document Category Failure',
  DELETE_DOCUMENT_CATEGORIES = '[DocumentCategories] Delete Document Categories',
  DELETE_DOCUMENT_CATEGORIES_SUCCESS = '[DocumentCategories] Delete Document Categories Success',
  DELETE_DOCUMENT_CATEGORIES_FAILURE = '[DocumentCategories] Delete Document Categories Failure'

}

export class LoadDocumentCategories implements Action {
  readonly type = EDocumentCategoriesActions.LOAD_DOCUMENT_CATEGORIES;
}

export class LoadDocumentCategoriesSuccessAction implements Action {
  readonly type = EDocumentCategoriesActions.LOAD_DOCUMENT_CATEGORIES_SUCCESS;

  constructor(public payload: Paginator<DocumentCategory>) {}
}

export class LoadDocumentCategoriesFailureAction implements Action {
  readonly type = EDocumentCategoriesActions.LOAD_DOCUMENT_CATEGORIES_FAILURE;

  constructor(public payload: Error) {}
}

export class GetDocumentCategories implements Action {
  public readonly type = EDocumentCategoriesActions.GET_DOCUMENT_CATEGORIES;

  constructor(public payload: FetchPayload<any>) {}
}

export class GetDocumentCategoriesSuccess implements Action {
  public readonly type = EDocumentCategoriesActions.GET_DOCUMENT_CATEGORIES_SUCCESS;

  constructor(public payload: Paginator<DocumentCategory>) {}
}

export class GetDocumentCategoriesFailure implements Action {
  readonly type = EDocumentCategoriesActions.GET_DOCUMENT_CATEGORIES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddDocumentCategory implements Action {
  public readonly type = EDocumentCategoriesActions.ADD_DOCUMENT_CATEGORY;

  constructor(public payload: DocumentCategory) {}
}

export class AddDocumentCategorySuccess implements Action {
  public readonly type = EDocumentCategoriesActions.ADD_DOCUMENT_CATEGORY_SUCCESS;

  constructor(public payload: DocumentCategory) {}
}

export class AddDocumentCategoryFailure implements Action {
  readonly type = EDocumentCategoriesActions.ADD_DOCUMENT_CATEGORY_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateDocumentCategory implements Action {
  public readonly type = EDocumentCategoriesActions.UPDATE_DOCUMENT_CATEGORY;

  constructor(public payload: DocumentCategory) {}
}

export class UpdateDocumentCategorySuccess implements Action {
  public readonly type = EDocumentCategoriesActions.UPDATE_DOCUMENT_CATEGORY_SUCCESS;

  constructor(public payload: DocumentCategory) {}
}

export class UpdateDocumentCategoryFailure implements Action {
  readonly type = EDocumentCategoriesActions.UPDATE_DOCUMENT_CATEGORY_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteDocumentCategory implements Action {
  public readonly type = EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORY;

  constructor(public payload: number) {}
}

export class DeleteDocumentCategorySuccess implements Action {
  public readonly type = EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORY_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteDocumentCategoryFailure implements Action {
  readonly type = EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORY_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteDocumentCategories implements Action {
  public readonly type = EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORIES;

  constructor(public payload: number[]) {}
}

export class DeleteDocumentCategoriesSuccess implements Action {
  public readonly type = EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORIES_SUCCESS;

  constructor(public payload: number[]) {}
}

export class DeleteDocumentCategoriesFailure implements Action {
  readonly type = EDocumentCategoriesActions.DELETE_DOCUMENT_CATEGORIES_FAILURE;

  constructor(public payload: Error) {}
}

export type DocumentCategoryActions =
  | LoadDocumentCategories
  | LoadDocumentCategoriesSuccessAction
  | LoadDocumentCategoriesFailureAction
  | GetDocumentCategories
  | GetDocumentCategoriesSuccess
  | GetDocumentCategoriesFailure
  | AddDocumentCategory
  | AddDocumentCategorySuccess
  | AddDocumentCategoryFailure
  | UpdateDocumentCategory
  | UpdateDocumentCategorySuccess
  | UpdateDocumentCategoryFailure
  | DeleteDocumentCategory
  | DeleteDocumentCategorySuccess
  | DeleteDocumentCategoryFailure
  | DeleteDocumentCategories
  | DeleteDocumentCategoriesSuccess
  | DeleteDocumentCategoriesFailure;
