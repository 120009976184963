import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

import { AppSettingsService } from '../../../../helpers/app-settings.service';
import { CustomDateAdapter } from '../../../../helpers/custom-date-adapter';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-select-date-popup',
  templateUrl: './select-date-popup.component.html',
  styleUrls: ['./select-date-popup.component.scss']
})
export class SelectDatePopupComponent implements OnInit {
  constructor(private appSettingsService: AppSettingsService,) {}
  locale$ = this.appSettingsService.getLang;
  minDate = CustomDateAdapter.today();
  dateFrom: string;
  dateTo: string;
  dateFromVis: string;
  dateToVis: string;
  dateFormat = CustomDateAdapter.dateFormat;
  filterToggleButton = false
  selectedDates = false;
  @Output() applyEmit = new EventEmitter();

  ngOnInit() {}

  updateDateEnd(dateStart) {
    if (dateStart && dateStart.value) {
      this.minDate = dateStart.value;
      if (dateStart.value > this.dateTo && this.dateTo) {
        this.dateTo = moment(dateStart.value).format(CustomDateAdapter.dateFilterFormat);
      }
    } else {
      this.minDate = '1970-01-01';
    }
  }

  discardFilters(menu: MatMenu) {
    this.dateFrom = null;
    this.dateTo = null;
    this.dateFromVis = null;
    this.dateToVis = null;
    this.filterToggleButton = false;
    this.applyEmit.emit();
    menu.closed.emit();
  }

  applyFilters(menu: MatMenu) {
    this.applyEmit.emit(
      {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      });
    this.dateFromVis = this.dateFrom;
    this.dateToVis = this.dateTo;
    this.filterToggleButton = false;
    this.selectedDates = true;
    menu.closed.emit();
  }

  pipeDate(date) {
    return date ? CustomDateAdapter.convertToDate(date) : '';
  }

}
