import { Component, OnInit } from '@angular/core';
import { selectSelectedNote } from '@app/store/selectors/note.selector';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { Note } from '@app/models/note';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';

@Component({
  selector: 'app-display-note-modal',
  templateUrl: './display-note-modal.component.html',
  styleUrls: ['./display-note-modal.component.scss']
})
export class DisplayNoteModalComponent implements OnInit {
  readonly dateFormat = CustomDateAdapter.dateFormat;

  note: Note;
  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.getNotes();
  }

  getNotes() {
    this.store.select(selectSelectedNote).subscribe(data => {
      if (data) {
        this.note = data;
      }
    });
  }

  linkTransform(str) {
    return Helpers.linkTransform(str);
  }
}
