import { Action } from '@ngrx/store';
import { INotification } from '@app/models/interfaces/notifications.interface';
import { Paginator } from '@app/models/paginator';

export enum ENotificationActions {
  GET_NOTIFICATION = '[Notification] Get',
  GET_NOTIFICATION_SUCCESS = '[Notification] Get Success',
  ISREAD_NOTIFICATION = '[Notification] IsRead',
  ADD_NOTIFICATION = '[Notification] Add',
  GET_COUNT_UNREAD = '[Notification] Get Count Unread',
  GET_COUNT_UNREAD_SUCCESS = '[Notification] Get Count Unread Success'
}
export class GetNotifications implements Action {
  public readonly type = ENotificationActions.GET_NOTIFICATION;
  constructor(public payload: any) {}
}
export class GetNotificationsSuccess implements Action {
  public readonly type = ENotificationActions.GET_NOTIFICATION_SUCCESS;
  constructor(public payload: Paginator<INotification>, public currentPage?: number, public renew?: boolean) {}
}
export class GetCountUnreadNotifications implements Action {
  public readonly type = ENotificationActions.GET_COUNT_UNREAD;
  constructor() {}
}
export class GetCountUnreadNotificationsSuccess implements Action {
  public readonly type = ENotificationActions.GET_COUNT_UNREAD_SUCCESS;

  constructor(public payload: number) {}
}

export class IsReadNotification implements Action {
  public readonly type = ENotificationActions.ISREAD_NOTIFICATION;
  constructor(public payload: any) {}
}

export class AddNotification implements Action {
  public readonly type = ENotificationActions.ADD_NOTIFICATION;
  constructor(public payload: { seq: number; data: INotification }) {}
}

export type NotificationActions =
  | GetNotifications
  | GetNotificationsSuccess
  | IsReadNotification
  | AddNotification
  | GetCountUnreadNotifications
  | GetCountUnreadNotificationsSuccess;
