import { ContactCardActionsTypes, ContactCardActions } from '../actions/contact-card.actions';
import { ContactCardState, initialContactCardState } from '../state/contact-card.state';

export const ContactCardReducers = (state = initialContactCardState, action: ContactCardActions): ContactCardState => {
  switch (action.type) {
    case ContactCardActionsTypes.LOAD_CONTACT_FINANCE:
      return {
        ...state,
        finance: null,
        loading: true
      };
    case ContactCardActionsTypes.LOAD_CONTACT_FINANCE_SUCCESS:
      return {
        ...state,
        finance: action.payload,
        loading: false
      };
    case ContactCardActionsTypes.LOAD_CONTACT_FINANCE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
