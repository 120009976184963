// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-container .field-row-container {
  justify-content: space-between;
  margin: 0;
}
.responsive-container .correspondenceStatus label {
  line-height: 15px;
  margin-top: 16px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/wide-modals/correspondence-new-modal/correspondence-new-modal.component.scss"],"names":[],"mappings":"AACE;EACE,8BAAA;EACA,SAAA;AAAJ;AAGI;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;AADN","sourcesContent":[".responsive-container {\n  .field-row-container {\n    justify-content: space-between;\n    margin: 0;\n  }\n  .correspondenceStatus {\n    label {\n      line-height: 15px;\n      margin-top: 16px;\n      font-weight: 400;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
