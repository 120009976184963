import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { ContactsModalComponent } from '@app/modules/contacts/components/contacts-modal/contacts-modal.component';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { disappearanceAnimation, fadeInOutAnimation } from '@app/helpers/fadeAnimation';
import { ActivityExtended, Contact, DocumentCategory, DocumentExtended, IUser } from '@app/models';
import { Bill } from '@app/models/bill';
import { Contract } from '@app/models/contract';
import { Correspondence } from '@app/models/correspondence';
import { DocRelation } from '@app/models/doc-relation';
import { DocumentSave } from '@app/models/document-save';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { MatterExtended } from '@app/models/matter-extended';
import { TagMain } from '@app/models/tag';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { ContactEffects } from '@app/modules/contacts/store/effects/contact.effects';
import { ContractService } from '@app/modules/contracts/services/contract.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { DocumentService } from '@app/modules/reusable/documents/services/document.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { GetDocumentCategories } from '@app/modules/settings/store/actions/document-categories.actions';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectDocumentCategoriesPaginator } from '@app/modules/settings/store/selectors/document-category.selector';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { LoadMattersForList } from '@app/store/actions/matter.actions';
import { selectMatterListForSelect } from '@app/store/selectors/matter.selector';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, tap } from 'rxjs/operators';
import { DocumentUploadModalDebounceEnum } from '../../../../enums/debounce-keys.enum';
import { DebounceService } from '../../../../helpers/debounce.service';
import { DocumentAddRelationModalComponent } from '../document-add-relation-modal/document-add-relation-modal.component';
import { CompanySettings } from '@app/models/company-settings';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';

@Component({
  selector: 'app-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss'],
  animations: [disappearanceAnimation, fadeInOutAnimation],
})
export class DocumentUploadModalComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<DocumentUploadModalComponent>,
    private contactService: ContactService,
    private matterService: MatterService,
    private teamMemberService: TeamMemberService,
    private reminderService: DocumentService,
    private appSettingsService: AppSettingsService,
    private contractService: ContractService,
    private translate: TranslateService,
    private contactEffect: ContactEffects,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fileName?: string;
      skipMatter: boolean;
      bill?: number;
      document?: DocumentExtended;
      edit?: boolean;
      withContact?: boolean;
      ifCloud?: boolean;
      ifSelectedMatter?: MatterExtended;
    },
    private formBuilder: UntypedFormBuilder,
    private store$: Store<IAppState>,
    private dialog: MatDialog,
    private debounceService: DebounceService,
  ) {
    const subscCreateContact = contactEffect.create$.subscribe(res => {
      this.contactObj.dataSource = [];
      this.documentForm.get('contact').setValue('');
      this.creatingContact = res.payload;
      if (this.contacts && this.creatingContact) {
        if (!this.contacts.find(item => item.id === this.creatingContact.id)) {
          this.contacts.push(this.creatingContact);
        }

        setTimeout(() => {
          this.contactObj.dataSource = this.contacts as any;
          this.contactObj.dataBind();
          this.documentForm.get('contact').setValue(this.creatingContact.id);
        }, 0);
      }
    });
    this.subscription.add(subscCreateContact);
  }

  get f() {
    return this.documentForm;
  }

  public entityTagList$: BehaviorSubject<TagMain[]> = new BehaviorSubject<TagMain[]>(null);
  private subscription = new Subscription();
  readonly dateFormat = CustomDateAdapter.dateFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  public sorting = 'Ascending';
  companySettings: CompanySettings;

  contacts: Contact[];
  legalContacts: Contact[];
  responsibleContacts: Contact[];
  matters: MatterExtended[];
  representatives: IUser[];
  contracts: Contract[];
  documentCategories: DocumentCategory[];

  documentForm: UntypedFormGroup;
  disAdditional = false;
  additionFields = false;
  remindToggleButton = false;
  remindFields = false;
  radioSwitchAttorney = false;
  radioSwitchWarrant = false;
  dateTimeFormat = CustomDateAdapter.dateTimeFormat;
  submittedForm = false;

  initialContact: Contact;
  initialMatter: MatterExtended;
  initialEntityName: string;
  initialEntity: Contact | MatterExtended | Correspondence | Bill | ActivityExtended;

  creatingContact: Contact;

  @ViewChild('contact', { static: false })
  // create object for contact comboBox
  public contactObj: ComboBoxComponent;

  @ViewChild('contract', { static: false })
  // create object for contact comboBox
  public contractObj: ComboBoxComponent;

  @ViewChild('legalContact', { static: false })
  // create object for legalContact comboBox
  public legalContactObj: ComboBoxComponent;

  @ViewChild('responsibleContact', { static: false })
  // create object for responsibleContact comboBox
  public responsibleContactObj: ComboBoxComponent;

  @ViewChild('matter', { static: false })
  // create object for matter comboBox
  public matterObj: ComboBoxComponent;

  @ViewChild('dateReminders', { static: false })
  public dateReminders;
  firstInitData = false;

  descripionReminders = '';
  newDate = new Date();
  todayDate = new Date();
  reminderId = null;
  descrValid = false;
  dateValid = false;
  reminder: {
    id?: number;
    description?: string;
    reminderDate?: Date;
  };

  docRelations: DocRelation[] = [];
  disMatter = false;

  filtersContacts = {
    'order[fullName]': 'ASC',
    which_user_portal: true,
    name: '',
  };

  filtersResponsibleContacts = {
    'order[fullName]': 'ASC',
    name: '',
  };

  filterMatters = {
    name: '',
    status: 'open',
  };

  filtersUser: TeamMemberFilter = {
    name: '',
    status: 'active',
    'companyAccesses.enable': true,
  };

  @ViewChild('usersComboBox', { static: false }) public comboBoxUser: ComboBoxComponent;
  public locale$ = this.appSettingsService.getLang;


  disableContact = false;
  disableMatter = false;

  ngOnInit() {
    this.createForm();
    this.getCompanySettings();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.data?.document?.cloudLink) {
        this.data.ifCloud = true;
      }
      this.setSelectModels();
      this.getReminders();
      this.getRelations();
      if (this.data && this.data.ifSelectedMatter && this.data.ifSelectedMatter.contact) {
        this.loadContracts(this.data.ifSelectedMatter.contact.id, false);
      }

      // Init document tags
      this.entityTagList$.next(this.data.document?.tagDocuments);

      this.subscription.add(
        this.documentForm.valueChanges
          .pipe(
            tap(_ => {
              this.entityTagList$.next(this.getDocumentForm()?.tagDocuments);
            }),
          )
          .subscribe(),
      );
    });
  }

  getReminders() {
    if (this.data.document) {
      this.subscription.add(
        this.reminderService.getDocumentReminder(this.data.document.id).pipe(take(1)).subscribe(info => {
          if (info.length !== 0) {
            this.reminder = info[0];
            this.remindFields = true;
            this.reminderId = info[0].id;
            this.descripionReminders = info[0].description;
            this.newDate = info[0].reminderDate;
          }
        }),
      );
      this.initFormValues();
      this.getCurrentMatter();
      this.disableContact = true;
      this.disableMatter = true;
    } else {
      this.firstInitData = true;
    }
  }

  getRelations() {
    if (this.data?.document) {
      this.documentService
        .getRelations(1, 1000, { 'document.id': this.data.document.id })
        .pipe(take(1))
        .subscribe(res => {
          this.docRelations = res.member;
          this.docRelations = this.docRelations.map(relation => {
            relation.selected = relation.target;
            return relation;
          });
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  // функції для витягу дати та опису нагадування з html
  changeDesc(event) {
    this.descripionReminders = event.srcElement.value;
    if (!(this.descripionReminders.length <= 0 && this.remindFields)) {
      this.descrValid = false;
    }
  }

  chandeDate() {
    this.newDate = this.dateReminders.value;
    const date5 = new Date();
    date5.setMinutes(date5.getMinutes() + 5);
    if (!(date5 > this.newDate || (this.newDate == null && this.remindFields))) {
      this.dateValid = false;
    }
  }

  startDatePending() {
    return this.todayDate;
  }

  onSubmit() {
    this.submittedForm = true;
    this.documentForm.updateValueAndValidity();
    if (!this.documentForm.valid) {
      return;
    }
    if (this.newDate == null && this.descripionReminders.length <= 0 && this.remindFields) {
      return;
    }

    const date5 = new Date();
    date5.setMinutes(date5.getMinutes() + 5);
    if ((date5 > this.newDate || this.newDate == null) && this.remindFields) {
      this.dateValid = true;
      return;
    }
    if (this.descripionReminders.length <= 0 && this.remindFields) {
      this.descrValid = true;
      return;
    }

    let document: DocumentSave = {
      name: this.documentForm.get('name').value,
      cloudLink: this.data.ifCloud ? this.documentForm.get('cloudLink').value : null,
      category: this.documentForm.get('documentCategory').value ? (this.documentForm.get('documentCategory').value as number) : null,
      tagDocuments: this.documentForm.get('tagDocuments').value,
      id: this.data?.document?.id,
      orderItem: null,
      mandatory: null
    };
    if ((!this.disableContact || !this.disableMatter) && this.documentForm.get('contact')?.value) {
      document = {
        ...document,
        activity: null,
        bill: null,
        contact: this.documentForm.get('contact').value,
        contract: null,
        correspondence: null,
        matter: null,
        task: null,
      };
    }
    if (!this.disableMatter && this.documentForm.get('matter')?.value) {
      document.matter = this.documentForm.get('matter').value;
      document = {
        ...document,
        activity: null,
        bill: null,
        contact: null,
        contract: null,
        correspondence: null,
        matter: this.documentForm.get('matter').value,
        task: null,
      };
    }
    if (this.radioSwitchAttorney) {
      document = {
        ...document,
        mandatory: {
          dateEnd: this.documentForm.get('validUntilDate').value,
          date: this.documentForm.get('creationDate').value,
          number: this.documentForm.get('attorneyNumber').value,
          contract: this.documentForm.get('contract').value,
          responsibilities: [this.documentForm.get('representative').value],
          organization: this.documentForm.get('stateBody').value,
          contact: this.documentForm.get('legalContact').value,
          responsibleContact: this.documentForm.get('responsibleContact').value,
        },
        orderItem: null
      }
    }

    if (this.radioSwitchWarrant) {
      document = {
        ...document,
        orderItem: {
          date: this.documentForm.get('creationDate').value,
          number: this.documentForm.get('attorneyNumber').value,
          contract: this.documentForm.get('contract').value,
          responsible: this.documentForm.get('representative').value,
          organization: this.documentForm.get('stateBody').value,
          contact: this.documentForm.get('legalContact').value,
          responsibleContact: this.documentForm.get('responsibleContact').value,
        },
        mandatory: null

      }
    }


    this.docRelations = this.docRelations.filter(relation => !relation['@id']);
    const payload = {
      document,
      reminders: {
        reminderDate: this.newDate,
        description: this.descripionReminders,
        id: this.reminderId,
      },
      check: this.remindFields,
      relations: this.docRelations,
    };
    if (this.reminder) {
      if (this.reminder.description === this.descripionReminders && this.reminder.reminderDate === this.newDate) {
        payload.reminders = null;
      }
    }

    this.dialogRef.close(payload);
  }

  switchAdditionFields() {
    this.additionFields = !this.additionFields;
    if (this.additionFields) {
      this.dialogRef.addPanelClass('document-upload-additions');
      this.radioSwitchWarrant = true;
    } else {
      this.resetDataAttorneyWarrant();
      this.radioSwitchAttorney = false;
      this.radioSwitchWarrant = false;
      this.dialogRef.removePanelClass('document-upload-additions');
    }
    this.setAdditionalValidators();
  }

  // onCloudError(event) {
  //   if (this.f.get(['cloudLink']).errors) {
  //     event.preventDefault();
  //     this.dialogRef.addPanelClass('document-cloudLink-error');
  //   } else {
  //     this.dialogRef.removePanelClass('document-cloudLink-error');
  //   }
  // }

  resetDataAttorneyWarrant() {
    this.documentForm.patchValue({
      representative: null,
      contract: null,
      attorneyNumber: null,
      validUntilDate: null,
      creationDate: null,
      stateBody: null,
      legalContact: null,
      responsibleContact: null,
    });
  }

  radioSwitch(radioEl) {
    this.documentForm.get('attorneyNumber').enable();
    this.documentForm.get('stateBody').enable();

    this.disAdditional = true;
    this.documentForm.patchValue({
      attorneyNumber: '',
      stateBody: '',
    });

    switch (radioEl) {
      case 'attorney': {
        if (this.radioSwitchAttorney) {
          this.radioSwitchAttorney = false;
        } else {
          this.radioSwitchWarrant = false;
          this.radioSwitchAttorney = true;
        }
        break;
      }
      case 'warrant': {
        if (this.radioSwitchWarrant) {
          this.radioSwitchWarrant = false;
        } else {
          this.radioSwitchAttorney = false;
          this.radioSwitchWarrant = true;
        }
        break;
      }
    }
    if (!(this.radioSwitchAttorney || this.radioSwitchWarrant)) {
      this.resetDataAttorneyWarrant();
    }
    this.setAdditionalValidators();
    if (!this.radioSwitchAttorney && !this.radioSwitchWarrant) {
      this.disAdditional = false;
      this.documentForm.get('attorneyNumber').disable();
      this.documentForm.get('stateBody').disable();
    } else {
      this.documentForm.get('legalContact').setValue(this.documentForm.get('contact').value);
    }
  }

  remindValue(event) {
    this.remindFields = event.target.checked;
    if (!event.target.checked) {
      // this.newDate = null;
    } else {
      const minutes = Math.ceil((new Date().getMinutes() + 30) / 10) * 10;
      this.todayDate.setMinutes(minutes);
      this.newDate = this.todayDate;
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  private createForm() {
    if (this.data && this.data.document) {
      if (this.data.document.mandatory || this.data.document.orderItem) {
        this.disAdditional = true;
      }
    }

    this.documentForm = this.formBuilder.group({
      name: this.formBuilder.control(this.data.fileName, [Validators.required]),
      cloudLink: this.formBuilder.control(
        null,
        this.data.ifCloud && [
          Validators.required,
          Validators.pattern(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi),
        ],
      ),
      documentCategory: this.formBuilder.control(null),
      creationDate: this.formBuilder.control(null, this.additionFields ? [Validators.required] : null),
      validUntilDate: this.formBuilder.control(null),
      attorneyNumber: this.formBuilder.control('',
        this.additionFields && this.radioSwitchWarrant ? [Validators.required] : null,
      ),
      contract: this.formBuilder.control(null),
      representative: this.formBuilder.control('', this.additionFields ? [Validators.required] : null),
      stateBody: this.formBuilder.control('',
        this.additionFields && this.radioSwitchWarrant ? [Validators.required] : null,
      ),
      tagDocuments: this.formBuilder.control([]),
      legalContact: this.formBuilder.control(null),
      responsibleContact: this.formBuilder.control(null),
    });

    if (!this.data.skipMatter || (this.data.document && !this.data.edit)) {
      this.documentForm.addControl('contact', this.formBuilder.control(null, [Validators.required]));
      this.documentForm.addControl('matter', this.formBuilder.control(null));
    }

    if (!this.data.skipMatter || (this.data.document && this.data.edit)) {
      this.documentForm.addControl('contact', this.formBuilder.control(null));
      this.documentForm.addControl('matter', this.formBuilder.control(null));
    }
  }

  private initFormValues() {
    this.disMatter = true;

    this.documentForm.get('name').setValue(this.data.document.name);
    if (this.data.ifCloud) {
      this.documentForm.get('cloudLink').setValue(this.data.document.cloudLink);
    }
    this.documentForm.get('documentCategory').setValue(this.data.document.category ? this.data.document.category.id : null);

    if (this.data.document.mandatory) {
      this.documentForm.get('creationDate').setValue(this.data.document.mandatory.date);
      this.documentForm.get('validUntilDate').setValue(this.data.document.mandatory.dateEnd);
      this.documentForm.get('attorneyNumber').setValue(this.data.document.mandatory.number);
      this.documentForm.get('contract').setValue(this.data.document.mandatory.contract ? this.data.document.mandatory.contract.id : null);
      this.documentForm.get('representative').setValue(this.data.document.mandatory.responsibilities[0].id);
      this.documentForm.get('stateBody').setValue(this.data.document.mandatory.organization);
      this.radioSwitchAttorney = true;
      this.additionFields = true;
      this.dialogRef.addPanelClass('document-upload-additions');
    }
    if (this.data.document.orderItem) {
      this.documentForm.get('creationDate').setValue(this.data.document.orderItem.date);
      this.documentForm.get('attorneyNumber').setValue(this.data.document.orderItem.number);
      this.documentForm.get('contract').setValue(this.data.document?.orderItem?.contract?.id);
      this.documentForm.get('representative').setValue(this.data.document.orderItem.responsible.id);
      this.documentForm.get('stateBody').setValue(this.data.document.orderItem.organization);
      this.radioSwitchWarrant = true;
      this.additionFields = true;
      this.dialogRef.addPanelClass('document-upload-additions');
    }

    this.documentForm.get('tagDocuments').patchValue(this.data.document?.tagDocuments);

    this.setAdditionalValidators();
  }

  private getDocumentForm() {
    return this.documentForm.getRawValue();
  }

  getDataIfMatter() {
    if (this.data.document.matter && this.data.document.matter) {
      this.initialContact = this.data.document.matter.contact;
      this.initialMatter = this.data.document.matter;
      this.initialEntityName = 'matter';
      this.initialEntity = this.data.document.matter;

      this.chekContactInDataSource(this.data.document.matter.contact);
      this.documentForm.patchValue({
        matter: this.data.document.matter.id,
        contact: (this.data.document.matter.contact && this.data.document.matter.contact.id) || null,
      });
      setTimeout(() => {
        this.firstInitData = true;
      }, 500);
    }
  }

  getDataIfContact() {
    if (this.data.document && this.data.document.contact) {
      this.initialContact = this.data.document.contact;
      this.initialMatter = undefined;
      this.initialEntityName = 'contact';
      this.initialEntity = this.data.document.contact;
      this.chekContactInDataSource(this.data.document.contact);
      this.documentForm.patchValue({
        contact: (this.data.document.contact && this.data.document.contact.id) || null,
      });
      setTimeout(() => {
        this.firstInitData = true;
      }, 500);
    }
  }

  // getDataIfContract() {
  //   if (this.data.document && this.data.document.contract) {
  //     this.chekContactInDataSource(this.data.document.contract.client);
  //     this.documentForm.patchValue({
  //       contact: (this.data.document.contract.client && this.data.document.contract.client.id) || null
  //     });
  //   }
  // }
  getDataIfActivities() {
    if (this.data.document.activity && this.data.document.activity.matter) {
      this.initialContact = this.data.document.activity.matter.contact;
      this.initialMatter = this.data.document.activity.matter;
      this.initialEntityName = 'activity';
      this.initialEntity = this.data.document.activity;

      this.chekContactInDataSource(this.data.document.activity.matter.contact);
      this.documentForm.patchValue({
        matter: this.data.document.activity.matter.id,
        contact: (this.data.document.activity.matter.contact && this.data.document.activity.matter.contact.id) || null,
      });
      setTimeout(() => {
        this.firstInitData = true;
      }, 500);
    }
  }

  getDataIfBills() {
    if (this.data.document.bill && this.data.document.bill.client) {
      this.initialContact = this.data.document.bill.client;
      this.initialMatter = this.data.document.bill.matter;
      this.initialEntityName = 'bill';
      this.initialEntity = this.data.document.bill;

      this.chekContactInDataSource(this.data.document.bill.client);
      if (this.data.document.bill.matter) {
        this.documentForm.patchValue({
          matter: this.data.document.bill.matter.id,
          contact: this.data.document.bill.client.id,
        });
      } else {
        this.documentForm.patchValue({
          contact: this.data.document.bill.client.id,
        });
      }
      setTimeout(() => {
        this.firstInitData = true;
      }, 500);
    }
  }

  getDataIfTask() {
    if (this.data.document.task && this.data.document.task.matter) {
      this.initialContact = this.data.document.task.matter.contact;
      this.initialMatter = this.data.document.task.matter;
      this.initialEntityName = 'task';
      this.initialEntity = this.data.document.task;

      this.chekContactInDataSource(this.data.document.task.matter.contact);
      this.documentForm.patchValue({
        matter: this.data.document.task.matter.id,
        contact: (this.data.document.task.matter.contact && this.data.document.task.matter.contact.id) || null,
      });

      setTimeout(() => {
        this.firstInitData = true;
      }, 500);
    }
  }

  getDataIfCorrespondence() {
    if (this.data.document.correspondence && this.data.document.correspondence.matter) {
      this.initialContact = this.data.document.correspondence.matter.contact;
      this.initialMatter = this.data.document.correspondence.matter;
      this.initialEntityName = 'correspondence';
      this.initialEntity = this.data.document.correspondence;

      this.chekContactInDataSource(this.data.document.correspondence.matter.contact);
      this.documentForm.patchValue({
        matter: this.data.document.correspondence.matter.id,
        contact: (this.data.document.correspondence.matter.contact && this.data.document.correspondence.matter.contact.id) || null,
      });
      setTimeout(() => {
        this.firstInitData = true;
      }, 500);
    } else if (this.data.document.correspondence) {
      this.documentForm.get('contact').disable();
    }
  }

  chekContactInDataSource(contact) {
    if (this.contacts) {
      for (const itemContact of this.contacts) {
        if (+itemContact.id === +contact.id) {
          return;
        }
      }
      this.contacts.push(contact);
    }
  }

  // function to add or remove validators dependently on
  // type of document - attorney or warrantly
  private setAdditionalValidators() {

    if (!this.radioSwitchAttorney && !this.radioSwitchWarrant) {
      this.documentForm.get('attorneyNumber').clearValidators();
      this.documentForm.get('contract').clearValidators();
      this.documentForm.get('stateBody').clearValidators();
      this.documentForm.get('creationDate').clearValidators();
      this.documentForm.get('representative').clearValidators();
      this.documentForm.get('validUntilDate').clearValidators();
      this.documentForm.get('responsibleContact').clearValidators();

      this.documentForm.get('contract').updateValueAndValidity();
      this.documentForm.get('attorneyNumber').updateValueAndValidity();
      this.documentForm.get('stateBody').updateValueAndValidity();
      this.documentForm.get('creationDate').updateValueAndValidity();
      this.documentForm.get('representative').updateValueAndValidity();
      this.documentForm.get('validUntilDate').updateValueAndValidity();
      this.documentForm.get('responsibleContact').updateValueAndValidity();
    }

    if (this.radioSwitchAttorney) {
      this.documentForm.get('creationDate').setValidators([Validators.required]);
      this.documentForm.get('representative').setValidators([Validators.required]);
      this.documentForm.get('creationDate').updateValueAndValidity();
      this.documentForm.get('representative').updateValueAndValidity();

      this.documentForm.get('attorneyNumber').clearValidators();
      this.documentForm.get('attorneyNumber').updateValueAndValidity();
      this.documentForm.get('contract').clearValidators();
      this.documentForm.get('contract').updateValueAndValidity();
      this.documentForm.get('stateBody').clearValidators();
      this.documentForm.get('stateBody').updateValueAndValidity();
      this.documentForm.get('responsibleContact').clearValidators();
      this.documentForm.get('responsibleContact').updateValueAndValidity();
    }

    if (this.radioSwitchWarrant) {
      this.documentForm.get('creationDate').setValidators([Validators.required]);
      this.documentForm.get('representative').setValidators([Validators.required]);
      this.documentForm.get('stateBody').setValidators([Validators.required]); // impossible to save order without organization
      this.documentForm.get('attorneyNumber').setValidators([Validators.required]); // impossible to save order without number
      this.documentForm.get('contract').updateValueAndValidity();
      this.documentForm.get('attorneyNumber').updateValueAndValidity();
      this.documentForm.get('stateBody').updateValueAndValidity();
      this.documentForm.get('creationDate').updateValueAndValidity();
      this.documentForm.get('representative').updateValueAndValidity();
      this.documentForm.get('responsibleContact').setValidators([Validators.required]);
      this.documentForm.get('responsibleContact').updateValueAndValidity();
    }
  }


  resetValue() {
    if (this.data.document) {
      this.data.document.contact = null;
      this.data.document.matter = null;
      this.data.document.task = null;
      this.data.document.correspondence = null;
      this.data.document.activity = null;
      this.data.document.bill = null;
      this.data.document.contract = null;
    }
  }

  onClientChanged() {
    if (this.firstInitData) {
      this.resetValue();
      if (this.contactObj.value) {
        this.documentForm.patchValue({
          matter: null,
          contract: null,
        });
        this.loadContracts(this.contactObj.value, false);
        // clear the existing selection
        this.matterObj.text = null;

        this.matterObj.enabled = false;
        setTimeout(() => {
          const filterSubscr = this.matterService
            .get(environment.defaultPage, environment.countItemInSelect, { 'contact.id': +this.contactObj.value })
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(x => {
              this.matters = x.member;
            });

          // enable the city ComboBox
          this.matterObj.enabled = true;
          this.matterObj.query = new Query().where('contact.id', 'equal', this.contactObj.value);
          // bind the property change to city ComboBox
          this.matterObj.dataBind();
          this.subscription.add(filterSubscr);
        }, 500);
      }
      if (this.contactObj.value == null) {
        this.documentForm.patchValue({
          matter: null,
        });
        // clear the existing selection
        this.matterObj.text = null;

        this.matterObj.enabled = false;
      }
    }
  }

  private setSelectModels(): void {
    this.loadContacts();
    this.loadUsers();
    this.loadDocumentCategories();
  }

  getCurrentDocumentCattegory() {
    for (const documentCategory of this.documentCategories) {
      if (documentCategory.isSystem) {
        this.documentForm.patchValue({
          documentCategory: documentCategory.id,
        });
        break;
      }
    }
  }

  getCurrentMatter() {
    this.matterService
      .get(environment.defaultPage, environment.countItemInSelect, { 'contact.id': this.getCurrentContactId() })
      .pipe(take(1))
      .subscribe(x => {
        this.matters = x.member;
        if (this.data.document.correspondence) {
          this.documentForm.patchValue({
            matter: this.data.document.correspondence.matter ? this.data.document.correspondence.matter.id : null,
          });
        } else if (this.data.document.matter) {
          this.documentForm.patchValue({
            matter: this.data.document.matter ? this.data.document.matter.id : null,
          });
        } else if (this.data.document.bill && this.data.document.bill.matter) {
          this.documentForm.patchValue({
            matter: this.data.document.bill.matter ? this.data.document.bill.matter.id : null,
          });
        } else if (this.data.document.task && this.data.document.task.matter) {
          this.documentForm.patchValue({
            matter: this.data.document.task.matter ? this.data.document.task.matter.id : null,
          });
        } else if (this.data.document.activity && this.data.document.activity.matter) {
          this.documentForm.patchValue({
            matter: this.data.document.activity.matter ? this.data.document.activity.matter.id : null,
          });
        }
      });
  }

  getCurrentContactId() {
    if (this.data.document.contact) {
      return this.data.document.contact.id;
    } else if (this.data.document.matter) {
      return this.data.document.matter.contact.id;
    } else if (this.data.document.correspondence && this.data.document.correspondence.matter) {
      return this.data.document.correspondence.matter.contact.id;
    } else if (this.data.document.bill && this.data.document.bill.matter) {
      return this.data.document.bill.matter.contact.id;
    } else if (this.data.document.task && this.data.document.task.matter) {
      return this.data.document.task.matter.contact.id;
    } else if (this.data.document.activity && this.data.document.activity.matter) {
      return this.data.document.activity.matter.contact.id;
    }
  }

  startDate() {
    return this.documentForm.value.creationDate;
  }

  loadMatter() {
    this.subscription.add(
      this.store$.select(selectMatterListForSelect).subscribe(matters => {
        if (!matters) {
          this.store$.dispatch(new LoadMattersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (matters) {
          this.matters = matters;
          if (this.data.document && this.data.document.matter) {
            this.documentForm.get('matter').setValue(this.data.document.matter ? this.data.document.matter.id : null);
          }
        }
      }),
    );
  }

  loadUsers() {
    this.subscription.add(
      this.store$.select(selectListUsersForSelect).subscribe(resp => {
        if (resp == null) {
          this.store$.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        this.representatives = resp;
        if (this.data.document && this.data.document.mandatory) {
          this.documentForm.get('representative').setValue(this.data.document.mandatory.responsibilities[0].id);
        }
        if (this.data.document && this.data.document.orderItem) {
          this.documentForm.get('representative').setValue(this.data.document.orderItem.responsible.id);
        }
      }),
    );
  }

  loadContacts() {

    this.contactService.get(environment.defaultPage, environment.countItemInSelect, this.filtersResponsibleContacts).pipe(take(1)).subscribe(x => {
      this.responsibleContacts = JSON.parse(JSON.stringify(x.member));
      if (this.responsibleContacts && (this.data.document?.mandatory?.responsibleContact || this.data.document?.orderItem?.responsibleContact)) {
        const responsibleContact = this.data.document?.mandatory?.responsibleContact || this.data.document?.orderItem?.responsibleContact;
        if (!this.responsibleContacts.find(item => item.id === responsibleContact.id)) {
          this.responsibleContacts.push(responsibleContact);
        }
        this.documentForm.get('responsibleContact').setValue('');
        this.responsibleContactObj?.dataBind();

        setTimeout(() => {
          this.documentForm.patchValue({
            responsibleContact: responsibleContact.id,
          });
        }, 0);
      }
    })

    this.contactService.get(environment.defaultPage, environment.countItemInSelect, this.filtersContacts).pipe(take(1)).subscribe(x => {
      this.contacts = x.member;
      this.legalContacts = JSON.parse(JSON.stringify(this.contacts));
      if (this.data.document) {
        this.getDataIfMatter();
        this.getDataIfContact();
        // this.getDataIfContract();
        this.getDataIfActivities();
        this.getDataIfBills();
        this.getDataIfTask();
        this.getDataIfCorrespondence();
      }
      if (this.documentForm.get('contact')) {
        this.loadContracts(this.documentForm.get('contact').value);
      }

      if (this.contacts && this.creatingContact) {
        if (!this.contacts.find(item => item.id === this.creatingContact.id)) {
          this.contacts.push(this.creatingContact);
          this.legalContacts.push(this.creatingContact);
        }
        this.documentForm.get('contact').setValue('');
        this.contactObj?.dataBind();

        setTimeout(() => {
          this.documentForm.patchValue({
            contact: this.creatingContact.id,
          });
        }, 0);
      }

      if (this.legalContacts && (this.data.document?.mandatory?.contact || this.data.document?.orderItem?.contact)) {
        const legalContact = this.data.document?.mandatory?.contact || this.data.document?.orderItem?.contact;
        if (!this.legalContacts.find(item => item.id === legalContact.id)) {
          this.legalContacts.push(legalContact);
        }
        this.documentForm.get('legalContact').setValue('');
        this.legalContactObj?.dataBind();

        setTimeout(() => {
          this.documentForm.patchValue({
            legalContact: legalContact.id,
          });
        }, 0);
      }

    });
  }

  loadDocumentCategories() {
    this.store$.select(selectDocumentCategoriesPaginator).subscribe(data => {
      if (!data) {
        this.store$.dispatch(
          new GetDocumentCategories({
            page: 1,
            itemsPerPage: 10000,
          }),
        );
      }
      if (data) {
        this.documentCategories = data.member;
        if (this.data.withContact) {
          this.documentForm
            .get('documentCategory')
            .setValue(
              this.documentCategories.find(item => item.isSystem === true)
                ? this.documentCategories.find(item => item.isSystem === true).id
                : null,
            );
        }
        if (this.data.document) {
          this.documentForm.get('documentCategory').setValue(this.data.document.category ? this.data.document.category.id : null);
        }
      }
    });
  }

  loadContracts(contactId, setInitialData = true) {
    this.subscription.add(
      this.contractService.get({ 'client.id': contactId }).subscribe(data => {
        this.contracts = data.map(el => {
          el['fieldName'] = `${this.translate.instant('contract.titleShort', {
            contractNumber: el.number,
            contractFrom: CustomDateAdapter.convertToDate(new Date(el.date)),
          })}`;
          return el;
        });
        if (setInitialData) {
          if (this.data.document && this.data.document.mandatory) {
            this.documentForm
              .get('contract')
              .setValue(this.data.document.mandatory.contract ? this.data.document.mandatory.contract.id : null);
          }
          if (this.data.document && this.data.document.orderItem) {
            this.documentForm.get('contract').setValue(this.data.document.orderItem.contract?.id);
          }
        }
      }),
    );
  }

  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersContacts.name = e.text;
    this.contactObj?.showSpinner();
    this.debounceService.loadDataDebounce(DocumentUploadModalDebounceEnum.contacts, this.contactService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filtersContacts,
    ]);
    this.debounceService.getValue(DocumentUploadModalDebounceEnum.contacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.contacts = resp?.member as any;
      this.contactObj?.hideSpinner();
    });
  };

  public onFilteringLegalContacts: EmitType<any> = (e: FilteringEventArgs) => {
    // this.filtersContacts.name = e.text;
    const filtersLegalContacts = {
      'order[fullName]': 'ASC',
      which_user_portal: true,
      name:  e.text,
      // 'mandatory.contact.id' => 'exact',
      // 'orderItem.contact.id' => 'exact',
    }
    this.legalContactObj?.showSpinner();
    this.debounceService.loadDataDebounce(DocumentUploadModalDebounceEnum.legalContacts, this.contactService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      filtersLegalContacts,
    ]);
    this.debounceService.getValue(DocumentUploadModalDebounceEnum.legalContacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.legalContacts = resp?.member as any;
      this.legalContactObj?.hideSpinner();
    });
  };

  public onFilteringResponsibleContacts: EmitType<any> = (e: FilteringEventArgs) => {
    const filtersResponsibleContacts = {
      'order[fullName]': 'ASC',
      name:  e.text,
    }
    this.responsibleContactObj?.showSpinner();
    this.debounceService.loadDataDebounce(DocumentUploadModalDebounceEnum.responsibleContacts, this.contactService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      filtersResponsibleContacts,
    ]);
    this.debounceService.getValue(DocumentUploadModalDebounceEnum.responsibleContacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.responsibleContacts = resp?.member as any;
      this.responsibleContactObj?.hideSpinner();
    });
  };

  public onFilteringContracts: EmitType<any> = (e: FilteringEventArgs) => {
    const contractFilter = {
      number: e.text
    }
    this.contractObj?.showSpinner();
    this.debounceService.loadDataDebounce(DocumentUploadModalDebounceEnum.contracts, this.contractService, 'getContracts', [
      environment.defaultPage,
      environment.countItemInSelect,
      contractFilter,
    ]);
    this.debounceService.getValue(DocumentUploadModalDebounceEnum.contracts).subscribe(resp => {
      this.contracts = resp.map(el => {
        el['fieldName'] = `${this.translate.instant('contract.titleShort', {
          contractNumber: el.number,
          contractFrom: CustomDateAdapter.convertToDate(new Date(el.date)),
        })}`;
        return el;
      });
      e.updateData(this.contracts as any);
      this.contracts = resp?.member as any;
      this.contractObj?.hideSpinner();
    });
  };


  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.filterMatters.name = e.text;
    this.filterMatters['contact.id'] = this.contactObj.value;
    this.matterObj?.showSpinner();
    this.debounceService.loadDataDebounce(DocumentUploadModalDebounceEnum.matters, this.matterService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filterMatters,
    ]);
    this.debounceService.getValue(DocumentUploadModalDebounceEnum.matters).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.matters = resp?.member as any;
      this.matterObj?.hideSpinner();
    });
  };

  public onFilteringUsers: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersUser.name = e.text;
    this.comboBoxUser.showSpinner();
    this.debounceService.loadDataDebounce(DocumentUploadModalDebounceEnum.users, this.teamMemberService, 'get', [
      environment.defaultPage,
      environment.countItemInSelect,
      this.filtersUser,
    ]);
    this.debounceService.getValue(DocumentUploadModalDebounceEnum.users).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.representatives = resp?.member as any;
      this.comboBoxUser?.hideSpinner();
    });
  };

  addDocumentRelation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      fromDocument: true,
      docRelations: this.docRelations,
      document: this.data.document,
    };
    const dialogRef = this.dialog.open(DocumentAddRelationModalComponent, dialogConfig);

    dialogRef.backdropClick().pipe(take(1)).subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        this.docRelations.push({ ...res });
      }
    });
  }

  createContact() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'full-height-modal'];
    dialogConfig.disableClose = true;

    dialogConfig.data = { fromDocument: true };

    const dialogRef = this.dialog.open(ContactsModalComponent, dialogConfig);

    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant('button.close'),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  removeRelation(relation, i) {
    this.docRelations.splice(i, 1);
    if (relation.id) {
      this.documentService
        .deleteDocRelationship(relation.id)
        .pipe(take(1))
        .subscribe();
    }
  }

  public filterDocumentCategories: EmitType<any> = (e: FilteringEventArgs) => {
    if (e.text === '') {
      e.updateData(this.documentCategories as any);
    } else {
      const filteringData = [];
      this.documentCategories.forEach(i => {
        if (i.name.toUpperCase().indexOf(e.text.toUpperCase()) !== -1) {
          filteringData.push(i);
        }
      });
      e.updateData(filteringData);
    }
  };

  // Document tag logic
  onTagSaved(event: TagMain) {
    const document = this.getDocumentForm();
    const exist = document?.tagDocuments.find((tagMain: TagMain) => tagMain?.tag?.name === event?.tag?.name);
    if (!exist) {
      delete event?.tag?.count;
      document.tagDocuments.push(event);
      this.documentForm.get('tagDocuments').patchValue(document?.tagDocuments);
    }
  }

  onTagRemoved(event: TagMain) {
    const document = this.getDocumentForm();
    const tags = document.tagDocuments.filter((tagMain: TagMain) => tagMain?.tag?.name !== event?.tag?.name);
    this.documentForm.get('tagDocuments').patchValue(tags);
  }

  unlockContact() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'button.cancel',
      title: 'deleteTitleTxt',
      mainTxt: 'documents.change.contact',
    };

    this.dialog
      .open(ConfirmModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === true) {
          this.disableContact = false;
          this.disableMatter = false;
          this.documentForm.get('contact').enable();
        }
      });
  }

  unlockMatter() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'button.cancel',
      title: 'deleteTitleTxt',
      mainTxt: 'documents.change.matter',
    };

    this.dialog
      .open(ConfirmModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === true) {
          this.disableMatter = false;
          if (!this.documentForm.get('contact').value) {
            this.disableContact = false;
            this.documentForm.get('contact').enable();
          }
        }
      });
  }

  getHeightModal(docModalBody: HTMLDivElement) {
    const docModalHeight = docModalBody.getBoundingClientRect().height + 170;
    const percentHeight = window.innerHeight / 100 * 4;
    if (docModalHeight > (window.innerHeight - percentHeight)) {
      return '96vh';
    }
    return 'auto';
  }

  private getCompanySettings(): void {
    this.store$.select(selectCompanySetting).subscribe(x => {
      if (x) {
        this.companySettings = x;
      } else {
        this.store$.dispatch(new LoadCompanySettingAction());
      }
    });
  }
}
