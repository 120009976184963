// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-rates .remove-addition-field {
  bottom: 10px;
  right: -27px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/rates/rates.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,YAAA;AAAJ","sourcesContent":[".app-rates {\n  .remove-addition-field {\n    bottom: 10px;\n    right: -27px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
