export interface ExpenseSave {
  id?: number;
  amount?: string;
  expenseType: number;
  vendor?: number;
  date: string;
  description?: string;
  currency: string;
  matter?: number;
  responsiblePerson: number;
  task?: number;
  companyBankAccount?: number;
}
