import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseBodyFull } from '@app/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/_services/auth.service';
import { environment } from '@environments/environment';
import { MessageModel, MessagesFilter } from '@app/chat/symbols/symbols';
import { Helpers } from '@app/helpers/helpers';

@Injectable()
export class ChatService {

  private audioPing = new Audio();
  private BASE_URL = `${environment.apiUrl}/comments`;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getMessages(filters?: Partial<MessagesFilter>): Observable<MessageModel[]> {
    let params = new HttpParams();
    params = params.set('page', '1').set('itemsPerPage', '100').set('partial', 'false');
    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }

    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<MessageModel>>(this.BASE_URL, { params })
        .pipe(
          map(response => (response['hydra:member'])),
        );
    } else {
      return new Observable<any>();
    }
  }

  createMessage(message: Partial<MessageModel>): Observable<MessageModel> {
    return this.http.post<MessageModel>(`${this.BASE_URL}`, message);
  }

  updateMessage(message: Partial<MessageModel>): Observable<MessageModel> {
    return this.http.patch<MessageModel>(`${this.BASE_URL}/${message.id}`, message);
  }

  removeMessage(messageId: number): Observable<void> {
    return this.http.delete<void>(`${this.BASE_URL}/${messageId}`);
  }


  readMessages(messageIds: number[]): Observable<MessageModel[]> {
    return this.http.post<ResponseBodyFull<MessageModel>>
    (`${this.BASE_URL}/read`, { comments: messageIds.map(id => `/comments/${id}`) })
      .pipe(map(response => response['hydra:member']));
  }

  pinMessage(messageId: number, userId: number): Observable<MessageModel[]> {
    return this.http.patch<ResponseBodyFull<MessageModel>>
    (`${environment.apiUrl}/users/${userId}`, { pinComments: [messageId] })
      .pipe(map(response => response['hydra:member']));
  }

  unpinMessage(messageId: number, userId: number): Observable<MessageModel[]> {
    return this.http.patch<ResponseBodyFull<MessageModel>>
    (`${environment.apiUrl}/users/${userId}`, { unpinComments: [messageId] })
      .pipe(map(response => response['hydra:member']));
  }

  getPinMessagesCount(userId: number, filters?: Partial<MessagesFilter>): Observable<number> {
    let params = new HttpParams();

    params = params
      .set('itemsPerPage', '0')
      .set('partial', 'false')
      .set('pinUsers.id', userId)
      .set('entityName', filters.entityName)
      .set('entityId', filters.entityId);


    return this.http.get<ResponseBodyFull<MessageModel>>(this.BASE_URL, { params })
      .pipe(map(response => response.pagination.totalItems));
  }

  playNotificationsPing() {
    this.audioPing.src = '/assets/sounds/ping.mp3';
    this.audioPing.volume = 1;
    this.audioPing.play().then(r => r).catch(e => e);
  }

}
