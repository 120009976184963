import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { MatterExtended } from '@app/models/matter-extended';
import { LoadAllMattersForList } from '@app/store/actions/matter.actions';
import { selectAllMatterListForSelect } from '@app/store/selectors/matter.selector';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Contact } from '@app/models';
import { Note } from '@app/models/note';
import { LoadAllContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { selectAllContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { AddNote, UpdateNote, SelectNote } from '@app/store/actions/note.actions';
import { selectSelectedNote } from '@app/store/selectors/note.selector';
import * as moment from 'moment';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { INoteModalData } from '@app/models/note-modal-data';
import { DebounceService } from '@app/helpers/debounce.service';
import { NoteModalDebounceEnum } from '@app/enums/debounce-keys.enum';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<NoteModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private matterService: MatterService,
    private contactService: ContactService,
    public store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public dialogData: INoteModalData,
    private appSettingsService: AppSettingsService,
    private debounceService: DebounceService
  ) {}
  get f() {
    return this.noteGroup;
  }
  matters: Array<MatterExtended>;
  matter: MatterExtended = null;
  contact: Contact = null;
  contacts: Array<Contact>;
  noteGroup: UntypedFormGroup;
  submittedForm = false;
  note: Note;
  editNote: Note = null;
  dateFormat = CustomDateAdapter.dateFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  public modalSwitch = false;
  ifEditStyle = false;
  private subscriptions = new Subscription();

  public sorting = 'Ascending';

  filtersContacts = {
    'order[fullName]': 'ASC',
    name: ''
  };
  @ViewChild('contactsComboBox', { static: false }) public comboBoxContact: ComboBoxComponent;

  filterMatters = {
    name: ''
  };
  @ViewChild('mattersComboBox', { static: false }) public comboBoxMatter: ComboBoxComponent;
  public locale$ = this.appSettingsService.getLang;

  ifEditNote = false;

  ngOnInit() {
    this.createForm();
    if (this.dialogData && !this.dialogData.note) {
      this.getNotes();
    }
    if (this.dialogData && this.dialogData.note) {
      this.ifEditNote = true;
      this.initFormValuesIfEdit(this.dialogData.note);
    }
    this.store.dispatch(new SelectNote(null));
    if (this.editNote) {
      this.initFormValuesIfEdit(this.editNote);
    }
    this.loadMatter();
    this.loadContacts();
  }

  ngOnDestroy(): void {
    this.debounceService.removeDebounceData();
  }

  ifMatterDis() {
    this.matter = this.dialogData ? this.dialogData.matter : null;
    if (this.matter) {
      this.ifEditStyle = true;
      this.modalSwitch = true;
      this.noteGroup.patchValue({
        matter: this.matter.id,
        contact: null
      });
      this.noteGroup.get('matter').disable();
      this.noteGroup.get('contact').disable();
    }
  }
  ifContactDis() {
    this.contact = this.dialogData ? this.dialogData.contact : null;
    if (this.contact) {
      this.ifEditStyle = true;
      this.modalSwitch = false;
      this.noteGroup.patchValue({
        contact: this.contact.id,
        matter: null
      });
      this.noteGroup.get('contact').disable();
      this.noteGroup.get('matter').disable();
    }
  }
  onSubmit() {
    this.formDataToNote();
    this.checkSubmit();
    if (this.noteGroup.valid && !this.editNote) {
      this.store.dispatch(new AddNote(this.note));
      this.closeModal();
    } else if (this.noteGroup.valid && this.editNote) {
      this.note.id = this.editNote.id;
      this.store.dispatch(new UpdateNote(this.note));
      this.closeModal();
    }
  }
  private initFormValuesIfEdit(note: Note): void {
    if (note && note !== null) {
      this.noteGroup.patchValue({
        subject: note.subject,
        description: note.description,
        matter: note.matter ? note.matter.id : null,
        contact: note.contact ? note.contact.id : null,
        date: note.date ? moment(note.date).format('DD.MM.YYYY') : CustomDateAdapter.today()
      });
      if (this.noteGroup.value.matter) {
        this.modalSwitch = true;
        this.noteGroup.get('matter').disable();
        this.noteGroup.get('contact').disable();
      } else {
        this.modalSwitch = false;
        this.noteGroup.get('contact').disable();
        this.noteGroup.get('matter').disable();
      }
    }
  }
  checkSubmit() {
    this.submittedForm = true;
  }
  getNotes() {
    this.store.select(selectSelectedNote).subscribe(note => {
      if (note) {
        this.ifEditNote = true;
        this.editNote = note;
      }
    });
  }
  loadContacts() {
    this.subscriptions.add(
      this.store.select(selectAllContactListForSelect).subscribe(contacts => {
        if (!contacts) {
          this.store.dispatch(
            new LoadAllContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect })
          );
        }
        if (contacts) {
          this.contacts = contacts;
          if (this.dialogData && this.dialogData.contact && !contacts.find(item => item.id === this.dialogData.contact.id)) {
            this.contacts.push(this.dialogData.contact);
          }
          this.ifContactDis();
        }
      })
    );
  }
  loadMatter() {
    this.subscriptions.add(
      this.store.select(selectAllMatterListForSelect).subscribe(matters => {
        if (!matters) {
          this.store.dispatch(new LoadAllMattersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (matters) {
          this.matters = matters;
          if (this.dialogData && this.dialogData.matter && !matters.find(item => item.id === this.dialogData.matter.id)) {
            this.matters.push(this.dialogData.matter);
          }
          this.ifMatterDis();
        }
      })
    );
  }

  createForm() {
    this.noteGroup = this.formBuilder.group({
      subject: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),

      description: new UntypedFormControl(''),

      matter: new UntypedFormControl(null),

      contact: new UntypedFormControl(null, [Validators.required]),

      date: new UntypedFormControl(CustomDateAdapter.today())
    });
  }
  private clearForm() {
    this.noteGroup.reset();
    this.noteGroup.patchValue({
      date: CustomDateAdapter.today(),
      description: ''
    });
  }
  private formDataToNote() {
    this.note = this.noteGroup.getRawValue();
    this.note.date = this.noteGroup.value.date;
  }

  modalSwitchToggle(state) {
    if (!this.dialogData) {
      if (!this.modalSwitch === state) {
        this.modalSwitch = state;
        this.clearForm();
      }
    }
    if (!this.modalSwitch) {
      this.noteGroup.get('matter').disable();
      this.noteGroup.get('contact').enable();
    } else {
      this.noteGroup.get('contact').disable();
      this.noteGroup.get('matter').enable();
    }
  }

  public closeModal() {
    this.dialogRef.close();
    this.store.dispatch(new SelectNote(null));
  }
  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersContacts.name = e.text;
    this.comboBoxContact?.showSpinner();
    this.debounceService.loadDataDebounce(
      NoteModalDebounceEnum.contacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(NoteModalDebounceEnum.contacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxContact?.hideSpinner();
    });
  }

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.filterMatters.name = e.text;
    this.comboBoxMatter?.showSpinner();
    this.debounceService.loadDataDebounce(
      NoteModalDebounceEnum.matters,
      this.matterService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filterMatters]);
    this.debounceService.getValue(NoteModalDebounceEnum.matters).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxMatter?.hideSpinner();
    });
  }
}
