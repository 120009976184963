import { Team } from '@app/models/team';

export interface ITeamState {
  myAndAnyoneWorkGroups: Team[];
  anyoneWithoutSystemWorkGroups: Team[];
  anyoneWorkGroups: Team[];
  allWorkGroups: Team[];
  workGroups: Team[];
}

export const initialTeamState: ITeamState = {
  myAndAnyoneWorkGroups: null,
  anyoneWithoutSystemWorkGroups: null,
  anyoneWorkGroups: null,
  allWorkGroups: null,
  workGroups: null
};
