import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Tariff } from '@app/models/tariff';
import { map } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { SubTariff } from '@app/models/sub-tariff';
import { AuthService } from '@app/auth/_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TariffsApi {
  static SUBSCRIPTIONS_ENDPOINT = `${environment.apiUrl}/tariffs?prices.currency.code=EUR&id[]=2&id[]=3&id[]=4`;
  private TARIFF_SUB_ENDPOINT = `${environment.apiUrl}/tariff_subs`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  getAll(): Observable<ResponseBodyFull<Tariff>> {
    return this.http.get<ResponseBodyFull<Tariff>>(TariffsApi.SUBSCRIPTIONS_ENDPOINT);
  }

  getById(id: number, checkSub: boolean): Observable<Tariff> {
    if (checkSub) {
      return this.http.get<Tariff>(`${this.TARIFF_SUB_ENDPOINT}/${id}`);
    } else {
      return this.http.get<Tariff>(`${TariffsApi.SUBSCRIPTIONS_ENDPOINT}/${id}`);
    }
  }

  getSubTariff() {
    if (this.authService.currentUserValue) {
      return this.http.get<ResponseBodyFull<SubTariff>>(this.TARIFF_SUB_ENDPOINT).pipe(map(subscription => subscription['hydra:member']));
    } else {
      return new Observable<SubTariff[]>();
    }
  }
}
