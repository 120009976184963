import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DocumentService } from '@app/modules/reusable/documents/services/document.service';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { Bill } from '@app/models/bill';
import { BillType } from '@app/enums/bills/bill-type';
import { Helpers } from '@app/helpers/helpers';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { DocumentExtended } from '@app/models';
import { DocumentUploadModalComponent } from '@app/commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { ToastrService } from 'ngx-toastr';
import { generateString } from '@app/helpers';

@Component({
  selector: 'app-attach-files-to-comment-modal',
  templateUrl: './attach-files-to-comment-modal.component.html',
  styleUrls: ['./attach-files-to-comment-modal.component.scss']
})
export class AttachFilesToCommentModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AttachFilesToCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private documentService: DocumentService,
    private billService: BillService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private toastrService: ToastrService
  ) {}

  activeTab = 'document';
  searchStr = '';

  spinnerName = 'attach-file-modal';
  showLoader = false;

  selectedNewDocuments = [];

  documents: DocumentExtended[] = [];

  bills: Bill[] = [];

  selectedDocuments: DocumentExtended[] = [];

  selectedBills: Bill[] = [];

  @ViewChild('file') private inputFile: ElementRef;
  fileData;
  newDocuments = [];

  ngOnInit(): void {
    this.selectedDocuments = this.data.selectedDocuments;
    this.selectedBills = this.data.selectedBills;
    this.newDocuments = this.data.selectedNewDocuments ? [].concat(this.data.selectedNewDocuments) : [];
    this.selectedNewDocuments = this.data.selectedNewDocuments ? [].concat(this.data.selectedNewDocuments) : [];

    this.searchDocument('');
    this.searchBill('');
  }

  attachFiles() {
    this.dialogRef.close({
      documents: this.selectedDocuments,
      bills: this.selectedBills,
      selectedNewDocuments: this.selectedNewDocuments
    });
  }

  changeTab(tabName) {
    this.activeTab = tabName;
  }

  searchDocument(name) {
    this.openSpinner();
    this.documentService.getDocuments(1, 10, { name }).subscribe(res => {
      this.hideSpinner();
      this.documents = res.member.map(item => {
        item.selected = !!this.selectedDocuments.find(sDoc => sDoc.id === item.id);
        return item;
      });
    });
  }

  searchBill(search) {
    this.openSpinner();
    this.billService.get(1, 10, { search }).subscribe(res => {
      this.hideSpinner();
      this.bills = res.member.map(item => {
        item.selected = !!this.selectedBills.find(sBill => sBill.id === item.id);
        return item;
      });
    });
  }

  findItems() {
    if (this.activeTab === 'document') {
      this.searchDocument(this.searchStr);
    } else {
      this.searchBill(this.searchStr);
    }
  }

  isPrepay(bill: Bill) {
    return bill && bill.typePay === BillType.prepaid;
  }
  getCurrentIconForDocument(list) {
    return Helpers.getCurrentImgForDocumentUrl(list?.media?.url || '');
  }

  getLocaleDate(date) {
    return CustomDateAdapter.convertToUniversalDateString(date);
  }

  selectBill(bill: Bill) {
    bill.selected = !bill.selected;
    if (bill.selected) {
      this.selectedBills.push(bill);
    } else {
      this.selectedBills = this.selectedBills.filter(item => item.id !== bill.id);
    }
  }

  selectDocument(document: DocumentExtended) {
    document.selected = !document.selected;
    if (document.selected) {
      this.selectedDocuments.push(document);
    } else {
      this.selectedDocuments = this.selectedDocuments.filter(item => item.id !== document.id);
    }
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  selectNewDocument(newDoc) {
    newDoc.document.selected = !newDoc.document.selected;
    if (newDoc.document.selected) {
      this.selectedNewDocuments.push(newDoc);
    } else {
      this.selectedNewDocuments = this.selectedNewDocuments.filter(item => item.document.uid !== newDoc.document.uid);
    }
  }

  onFileChanged(fileInput) {
    this.fileData = fileInput.target.files[0] as File;
    if (!Helpers.checkSizeFile(this.fileData, 20)) {
      this.fileData = null;
      this.toastrService.error(this.translate.instant('documents.fileTooBig20'));
      return;
    }
    if (this.fileData) {
      this.documentUploadModal(this.fileData);
    }
    this.inputFile.nativeElement.value = null;
  }

  addDocumentFromCloud() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.data = { skipMatter: false, ifCloud: true };

    const data = { skipMatter: true, withContact: true, ifCloud: true };
    dialogConfig.data = data;
    dialogConfig.panelClass = [
      'default-mat-dialog',
      'document-cloud-upload-modal',
      data.skipMatter ? '' : 'document-cloud-upload-big-modal'
    ];

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        if (x.document.cloudLink) {
          x.document.contact = this.data.contact.id;
          x.document['uid'] = generateString(12);
          x.document['selected'] = true;
          this.newDocuments.push({
            document: x.document,
            reminders: x.reminders,
            relations: x.relations
          });
          this.selectedNewDocuments.push({
            document: x.document,
            reminders: x.reminders,
            relations: x.relations
          });
        }
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  private documentUploadModal(file: File) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const data = { fileName: file.name, skipMatter: true, withContact: true };

    dialogConfig.data = data;
    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        if (file) {
          if (this.data.contact?.id) {
            x.document.contact = this.data.contact.id;
          }
          if (this.data.bill?.id) {
            x.document.bill = this.data.bill.id;
          }
          x.document['uid'] = generateString(12);
          x.document['selected'] = true;
          this.newDocuments.push({
            document: x.document,
            media: this.fileData,
            reminders: x.reminders,
            relations: x.relations
          });
          this.selectedNewDocuments.push({
            document: x.document,
            media: this.fileData,
            reminders: x.reminders,
            relations: x.relations
          });
        }
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  confirmCloseModal(modalRef: MatDialogRef<any>) {
    const confirmDialogConfig = new MatDialogConfig();
    // confirmDialogConfig.disableClose=false;
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
}
