import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ExchangeRatesService } from '@app/api/exchange-rates.service';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { ConverterModalComponent } from '@app/commonComponents/modals/other-modals/converter-modal/converter-modal.component';
import { ExpenseModalComponent } from '@app/commonComponents/wide-modals/expense-modal/expense-modal.component';
import { TimeModalComponent } from '@app/commonComponents/wide-modals/time-modal/time-modal.component';
import { BillFeeEnum } from '@app/enums/bills/bill-fee.enum';
import { BillType } from '@app/enums/bills/bill-type';
import { SharedEnumService } from '@app/enums/shared-enum.service';
import { TimeFormatter } from '@app/helpers';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { Helpers } from '@app/helpers/helpers';
import { ActivityExtended, Contact, ExpenseType, IUser, TimeEntryType } from '@app/models';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { Bill } from '@app/models/bill';
import { CompanySettings } from '@app/models/company-settings';
import { Contract } from '@app/models/contract';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { Taxes } from '@app/models/taxes';
import { BankAccountService } from '@app/modules/bank-accounts/services/bank-account.service';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { LoadActivePersonAndContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { selectActivePersonAndContactsForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { ContractService } from '@app/modules/contracts/services/contract.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { ExpenseTypesService } from '@app/modules/settings/services/expense-types.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { TimeEntryTypesService } from '@app/modules/settings/services/time-entry-types.service';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { UpdateBill } from '@app/store/actions/bill.actions';
import { AddDocument, AddDocumentSuccess } from '@app/store/actions/document.actions';
import { ActivitiesEffects } from '@app/store/effects/activities.effects';
import { DocumentEffects } from '@app/store/effects/document.effects';
import { selectSelectedBill } from '@app/store/selectors/bill.selector';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteComponent, ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { BillEditingModalDebounceEnum } from '@app/enums/debounce-keys.enum';
import { DebounceService } from '@app/helpers/debounce.service';
import { DocumentUploadModalComponent } from '../../default-modals/document-upload-modal/document-upload-modal.component';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { JnInputComponent } from '@app/modules/jn-form-fields/jn-input/jn-input.component';

export interface BillEditingModalComponentData {
  bill: Bill;
}

@Component({
  selector: 'app-bill-editing-modal',
  templateUrl: './bill-editing-modal.component.html',
  styleUrls: ['./bill-editing-modal.component.scss'],
})
export class BillEditingModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<BillEditingModalComponent>,
    private translate: TranslateService,
    private store: Store<IAppState>,
    private appSettingsService: AppSettingsService,
    private formBuilder: UntypedFormBuilder,
    private expenseTypesApi: ExpenseTypesService,
    private matterService: MatterService,
    private bankAccountService: BankAccountService,
    private timeEntryTypesApi: TimeEntryTypesService,
    private activitiesEffects: ActivitiesEffects,
    private documentEffects: DocumentEffects,
    private timeFormatter: TimeFormatter,
    private dialog: MatDialog,
    private teamMemberService: TeamMemberService,
    private contactService: ContactService,
    private exchangeService: ExchangeRatesService,
    private contractService: ContractService,
    private billService: BillService,
    @Inject(MAT_DIALOG_DATA) public dialogData: BillEditingModalComponentData,
    private debounceService: DebounceService,
  ) {
    this.bill = this.dialogData.bill;
    this.lang = this.sharedData.enumLang;
    this.billPaymentFee = this.sharedData.enumTypeFee;
    this.checkIsManyCurrency();

    this.createForm(this.dialogData.bill);
    this.subscribeToBill();
    this.subscribeToTimeEntryCreation();
    this.subscribeToExpenseCreation();
    this.subscribeDocument();
  }

  @ViewChild('executorBankName') public executorBankName: JnInputComponent;
  @ViewChild('clientBankName') public clientBankName: JnInputComponent;
  @ViewChild('clientAddress') public clientAddress: AutoCompleteComponent;
  @ViewChild('clientBankNumber') public clientBankNumber: ComboBoxComponent;
  @ViewChild('executorAddress') public executorAddress: AutoCompleteComponent;
  @ViewChild('executorBankNumber') public executorBankNumber: ComboBoxComponent;
  @ViewChild('clientPayer') public clientPayer: ComboBoxComponent;
  @ViewChild('contractComboBox') public contractComboBox: ComboBoxComponent;

  readonly today = new Date();
  public sorting = 'Ascending';
  matters;
  showLoader = false;

  bill: Bill;
  billEditForm: UntypedFormGroup;
  additionFields = false;
  minDate: Date;
  editableToggle = {
    clientAddress: false,
    clientBankNumber: false,
    clientBankName: false,
    executorAddress: false,
    executorBankNumber: false,
    executorBankName: false,
    changeExecutor: false,
    clientPayer: false,
    contract: false,
  };
  taxEnable = false;
  taxEnableAddition = false;
  currencyDrop = false;
  discountEnable = false;

  showAmount = false;
  activitySubTotal = 0;
  activityUnbilledTotal = 0;
  expenseSubTotal = 0;
  client: Contact;
  executorAddressResults = [];
  executorBankNumberResults = [];
  executorBankList: BankAccountExtended[] = [];
  clientAddressResults = [];
  clientBankNumberResults = [];
  contractor;
  contacts: Contact[];
  contracts: Contract[];
  contract: Contract;
  bankAccountsByExecutor: BankAccountExtended[] = [];
  users: IUser[];
  dataTimeEntriesUsers = [];
  dataExpensesUsers = [];
  expenseTypes: ExpenseType[];
  timeEntryTypes: TimeEntryType[] = [];
  spinnerName = 'bill-edit-spinner';

  billPaymentFee;

  lang;

  dateFormat = CustomDateAdapter.dateFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  currency = Helpers.getCurrenciesList();
  formSubmitted = false;

  sharedData = new SharedEnumService(this.translate);

  private readonly currencyPattern = '^[0-9]*[.,]?[0-9]+$';
  private subscriptions = new Subscription();

  enabled = true;
  public locale$ = this.appSettingsService.getLang;

  checkClickSubmit = false;

  @ViewChildren('executorBankNumberCombo') executorBankNumberCombo;
  @ViewChildren('executorAddressCombo') executorAddressCombo;

  @ViewChildren('clientBankNumberCombo') clientBankNumberCombo;
  @ViewChildren('clientAddressCombo') clientAddressCombo;
  currentPayer;
  // create object for contact comboBox
  @ViewChild('mattersComboBox', { static: false }) public mattersComboBox: ComboBoxComponent;
  filtersMatters = {
    'contact.id': null,
    name: '',
  };

  @ViewChild('usersCombo', { static: false }) public comboBoxUser: ComboBoxComponent;
  filtersUsers: TeamMemberFilter = {
    status: 'active',
    'companyAccesses.enable': true,
    name: '',
  };

  @ViewChild('executorComboBox', { static: false })
  // create object for contact comboBox
  public executorComboBox: ComboBoxComponent;
  filtersContacts = {
    'order[fullName]': 'ASC',
    which_active_user: true,
    name: '',
  };
  changeRateDate = false;
  uniqCurrency = [];

  tempCurrencyBill;

  fileData: File;
  fileName;
  @ViewChild('file', { static: false }) inputFile: ElementRef;

  errorMaxSizeFile = false;

  amountModelDiscount;

  companySetting: CompanySettings;

  get itemTimeEntries(): UntypedFormArray {
    return this.billEditForm.get('itemTimeEntries') as UntypedFormArray;
  }

  get itemExpenses(): UntypedFormArray {
    return this.billEditForm.get('itemExpenses') as UntypedFormArray;
  }

  get isPostpay() {
    return this.billEditForm.get('typePay').value === BillType.postpaid;
  }

  get descriptionTypePaid() {
    return this.isPostpay ? 'description' : 'descriptionPrePay';
  }

  get isFixed() {
    return this.billEditForm.get('typeFee').value === BillFeeEnum.fixed;
  }

  get isValidForm() {
    return this.billEditForm.valid;
  }

  get f() {
    return this.billEditForm;
  }

  get total(): number {
    return this.isFixed ? this.billEditForm.get('amountTimeEntry').value : this.activitySubTotal + this.expenseSubTotal;
  }

  get discount() {
    if (this.f.get('discountType').value === 'rate' && this.isFixed) {
      if (this.f.get('discountType').value === 'rate') {
        return +((this.billEditForm.get('amountTimeEntry').value / 100) * this.billEditForm.get('amountDiscount').value);
      }
    } else if (this.f.get('discountType').value === 'rate' && !this.isFixed) {
      let amountDiscountHourly = 0;
      if (this.f.get('discountType').value === 'rate') {
        for (let i = 0; i < this.itemTimeEntries.controls.length; i++) {
          amountDiscountHourly += (this.calculateActivityAmount(i) / 100) * this.billEditForm.get('amountDiscount').value;
        }
        return amountDiscountHourly;
      }
    } else {
      return +this.billEditForm.get('amountDiscount').value;
    }
  }

  get totalWithTaxAndDiscount() {
    if (this.isFixed) {
      return (
        this.billEditForm.get('amountTimeEntry').value -
        this.discount +
        (this.taxEnable ? this.taxesRate(1) : 0) +
        (this.taxEnableAddition ? this.taxesRate(2) : 0) +
        (this.f.get('expensesIncluded').value ? this.calculateExpenceSubTotal() : 0)
      );
    } else {
      return (
        this.calculateActivitySubTotal() +
        this.calculateExpenceSubTotal() -
        this.discount +
        (this.taxEnable ? this.taxesRate(1) : 0) +
        (this.taxEnableAddition ? this.taxesRate(2) : 0)
      );
    }
  }

  get taxes() {
    return this.billEditForm.get('taxes');
  }

  // приймає номер податку (1 або 2)
  taxesRate(tax) {
    if (!this.f.get('taxes').value) {
      return 0;
    }
    if (this.isFixed) {
      return this.amountTaxIfFixed(tax);
    }
    // hourly
    else {
      return this.amountTaxIfHourly(tax);
    }
  }

  // приймає номер податку (1 або 2)
  amountTaxIfHourly(tax) {
    let plusTaxFromEspenses = 0;
    // рахуємо суму податку в витратах
    for (let i = 0; i < this.itemExpenses.controls.length; i++) {
      if (this.itemExpenses.controls[i].value[`tax${tax}Included`]) {
        plusTaxFromEspenses += (this.calculateExpenseAmount(i) / 100) * +this.billEditForm.get('taxes').value[`tax${tax}Rate`];
      }
    }
    let rateDiscountAmount = this.billEditForm.get('amountDiscount').value;

    if (this.f.get('discountType').value === 'amount') {
      rateDiscountAmount = (this.discount / this.calculateActivitySubTotal()) * 100;
    }
    // рахуємо суму податку в часі
    for (let i = 0; i < this.itemTimeEntries.controls.length; i++) {
      if (this.itemTimeEntries.controls[i].value[`tax${tax}Included`]) {
        plusTaxFromEspenses +=
          ((this.calculateActivityAmount(i) - (this.calculateActivityAmount(i) / 100) * rateDiscountAmount) / 100) *
          this.billEditForm.get('taxes').value[`tax${tax}Rate`];
      }
    }
    return plusTaxFromEspenses;
  }

  // приймає номер податку (1 або 2)
  amountTaxIfFixed(tax) {
    let plusTaxFromEspenses = 0;
    if (this.f.get('expensesIncluded').value) {
      for (const expense of this.itemExpenses.controls) {
        if (expense.value[`tax${tax}Included`]) {
          plusTaxFromEspenses +=
            ((expense.value.amount * expense.value.currencyRate) / 100) * +this.billEditForm.get('taxes').value[`tax${tax}Rate`];
        }
      }
    }
    return (
      ((this.billEditForm.get('amountTimeEntry').value - this.discount) / 100) * +this.billEditForm.get('taxes').value[`tax${tax}Rate`] +
      plusTaxFromEspenses
    );
  }

  ngOnInit(): void {
    this.loadUsers();
    this.loadContacts();

    window.onbeforeunload = event => {
      // для Interner Explorer
      // tslint:disable-next-line: deprecation
      const e = event || window.event;
      const myMessage = 'Вы действительно хотите покинуть страницу, не сохранив данные?';
      // Для Internet Explorer і Firefox
      if (e) {
        e.returnValue = myMessage;
      }
      // Для Safari і Chrome
      return myMessage;
    };
  }

  ngOnDestroy(): void {
    window.onbeforeunload = null;
    this.subscriptions.unsubscribe();
    delete this.subscriptions;
    this.debounceService.removeDebounceData();
  }

  checkIsManyCurrency() {
    const subscr = this.store.pipe(select(selectCompanySetting)).subscribe(data => {
      if (data?.id) {
        this.companySetting = data;
        if (!data.isManyCurrency) {
          this.enabled = data.isManyCurrency;
        }
      }
    });
    this.subscriptions.add(subscr);
  }

  closeModal(data?): void {
    this.dialogRef.close(data);
  }

  onSubmit(checkApproveOrMail?) {
    if (this.billEditForm.valid && !this.checkClickSubmit) {
      const formValue: Bill = { ...this.billEditForm.value };
      this.checkClickSubmit = true;
      formValue.amountTimeEntry = formValue.amountTimeEntry.toString();
      formValue.amountDiscount = formValue.amountDiscount.toString();
      formValue.dateInvoice = CustomDateAdapter.convertToDate(new Date(formValue.dateInvoice));
      formValue.dateExpire = CustomDateAdapter.convertToDate(new Date(formValue.dateExpire));
      formValue.clientAddress = !!formValue.clientAddress ? formValue.clientAddress : '';
      formValue.executorAddress = !!formValue.executorAddress ? formValue.executorAddress : '';
      formValue.clientBankNumber = !!formValue.clientBankNumber ? formValue.clientBankNumber : '';
      formValue.executorBankNumber = !!formValue.executorBankNumber ? formValue.executorBankNumber : '';
      formValue.contract = !!+formValue.contract ? formValue.contract : null;

      if (this.billEditForm.get('descriptionToggle').value === false && !this.isFixed) {
        formValue.description = '';
      }
      if (checkApproveOrMail === 'approve') {
        formValue.status = 'awaiting_payment';
      }
      if (!this.taxEnable) {
        formValue.taxes = null;
      } else {
        if (!this.taxEnableAddition) {
          formValue.taxes.tax2Rate = null;
        }
      }

      if (!formValue.amountDiscount) {
        formValue.amountDiscount = '0.00';
      }

      if (formValue.itemTimeEntries) {
        if (formValue.itemTimeEntries.length > 0) {
          formValue.itemTimeEntries.map((timeEntry, index) => {
            formValue.itemTimeEntries[index].user = timeEntry.user && timeEntry.user.toString();
            formValue.itemTimeEntries[index].type = timeEntry.type && timeEntry.type.toString();
            formValue.itemTimeEntries[index].rate = timeEntry.rate && timeEntry.rate.toString();
            formValue.itemTimeEntries[index].duration = Math.round(timeEntry.duration * 3600);
            formValue.itemTimeEntries[index].currencyRate = +timeEntry.currencyRate;
            // if (!formValue.itemTimeEntries.id) {
            //   delete formValue.itemTimeEntries.id;
            // }
          });
        } else {
          delete formValue.itemTimeEntries;
        }
      }
      if (formValue.itemExpenses) {
        if (formValue.itemExpenses.length > 0) {
          formValue.itemExpenses.map((timeEntry, index) => {
            formValue.itemExpenses[index].user = timeEntry.user ? timeEntry.user.toString() : null;
            formValue.itemExpenses[index].type = timeEntry.type ? timeEntry.type.toString() : null;
            formValue.itemExpenses[index].currencyRate = +timeEntry.currencyRate;

            // if (!formValue.itemExpenses.id) {
            //   delete formValue.itemExpenses.id;
            // }
          });
        } else {
          delete formValue.itemExpenses;
        }
      }

      this.store.dispatch(new UpdateBill(this.bill.id, formValue));
      this.closeModal(checkApproveOrMail);
    } else {
      this.formSubmitted = true;
    }
  }

  addTimeEntry(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'time-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { matter: this.bill.matter };
    const dialogRef = this.dialog.open(TimeModalComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(res => {
      if (res) {
        this.openSpinner();
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  addExpence(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'expense-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { matter: this.bill.matter };
    const dialogRef = this.dialog.open(ExpenseModalComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe(res => {
      if (res) {
        this.openSpinner();
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  deleteActivity(activity, index): void {
    const itemTimeEntries = this.billEditForm.get('itemTimeEntries') as UntypedFormArray;
    itemTimeEntries.removeAt(index);
    if (activity.value && activity.value.id) {
      this.billService.deleteBillItemTimeEntries(activity.value.id).pipe(take(1)).subscribe();
    }
  }

  deleteExpense(expense, index): void {
    const itemExpenses = this.billEditForm.get('itemExpenses') as UntypedFormArray;
    itemExpenses.removeAt(index);
    if (expense.value && expense.value.id) {
      this.billService.deleteBillItemExpense(expense.value.id).pipe(take(1)).subscribe();
    }
  }

  calculateActivitySubTotal(): number {
    let sum = 0;
    const itemTimeEntries = this.billEditForm.get('itemTimeEntries') as UntypedFormArray;
    itemTimeEntries.controls.map((currentItem, index) => {
      sum += this.calculateActivityAmount(index);
    });
    this.activitySubTotal = sum;
    return this.activitySubTotal;
  }

  calculateActivityUnbilledTotal(): number {
    let sum = 0;
    const itemTimeEntries = this.billEditForm.get('itemTimeEntries') as UntypedFormArray;
    itemTimeEntries.controls.map((currentItem, index) => {
      sum += currentItem.value.nonBillable ? this.calculateActivityAmount(index, false) : 0;
    });
    this.activityUnbilledTotal = sum;
    return sum;
  }

  calculateExpenceSubTotal(): number {
    let sum = 0;
    const itemExpenses = this.billEditForm.get('itemExpenses') as UntypedFormArray;
    itemExpenses.controls.map((currentItem, index) => {
      sum += this.calculateExpenseAmount(index);
    });
    this.expenseSubTotal = sum;
    return sum;
  }

  populateItemExpenses(itemExpenses): UntypedFormArray {
    const formArray = this.formBuilder.array([]) as UntypedFormArray;
    if (itemExpenses && itemExpenses.length > 0) {
      itemExpenses.map(expense => {
        formArray.push(this.populateBillExpense(expense));
      });
    }
    return formArray;
  }

  populateItemTimeEntries(itemTimeEntries): UntypedFormArray {
    const formArray = this.formBuilder.array([]) as UntypedFormArray;
    if (itemTimeEntries && itemTimeEntries.length > 0) {
      itemTimeEntries.map(timeEntry => {
        formArray.push(this.populateBillTimeEntry(timeEntry));
      });
    }
    return formArray;
  }

  populateTaxes(taxes: Taxes): UntypedFormGroup {
    if (taxes) {
      this.taxEnable = true;
      if (+taxes.tax2Rate !== 0) {
        this.taxEnableAddition = true;
      }
      return this.formBuilder.group({
        tax1Amount: this.formBuilder.control(taxes.tax1Amount),
        tax1Name: this.formBuilder.control(taxes.tax1Name || this.companySetting.tax1Name),
        tax1Rate: this.formBuilder.control(+taxes.tax1Rate > 0 ? taxes.tax1Rate : this.companySetting.tax1Rate, [
          Validators.required,
          Validators.min(0.01),
          Validators.max(100),
        ]),
        tax2Amount: this.formBuilder.control(taxes.tax2Amount),
        tax2Name: this.formBuilder.control(taxes.tax2Name || this.companySetting.tax2Name),
        tax2Rate: this.formBuilder.control(
          +taxes.tax2Rate > 0 ? taxes.tax2Rate : this.companySetting.tax2Rate,
          this.taxEnableAddition ? [Validators.required, Validators.min(0.01), Validators.max(100)] : [],
        ),
      }) as UntypedFormGroup;
    } else {
      return this.formBuilder.group({
        tax1Name: this.formBuilder.control(this.companySetting.tax1Name),
        tax1Rate: this.formBuilder.control(this.companySetting.tax1Rate),
        tax2Name: this.formBuilder.control(this.companySetting.tax2Name),
        tax2Rate: this.formBuilder.control(this.companySetting.tax2Rate),
      }) as UntypedFormGroup;
    }
  }

  calculateActivityAmount(index, amountWithUnbilled = true): number {
    const activity = this.billEditForm.get('itemTimeEntries').get(index.toString()).value;
    if (!activity.duration || (amountWithUnbilled && activity.nonBillable)) {
      return 0;
    }
    const duration = (activity.duration + '').split(':');
    const seconds = activity.duration * 3600; // +duration[0] * 60 * 60 + (isNaN(+duration[1] * 60) ? 0 : +duration[1] * 60);
    const countHours = seconds ? this.timeFormatter.simpleSecondsToHours(seconds) : 0;
    return +(+activity.rate * countHours * activity.currencyRate).toFixed(2);
  }

  calculateExpenseAmount(index) {
    const expense = this.billEditForm.get('itemExpenses').get(index.toString()).value;

    return +(expense.amount * expense.currencyRate).toFixed(6);
  }

  syncActivityChange(event) {
    const syncActivityField = this.billEditForm.get('syncActivities');
    if (event) {
      syncActivityField.patchValue(event.target.checked);
    }
  }

  private control(value, optional = false): UntypedFormControl {
    return this.formBuilder.control(value, optional ? [] : Validators.required);
  }

  private formatDuration(duration) {
    return this.timeFormatter.convertSecondsToHoursQuantityString(duration);
  }


  changeClientPayerHandle(client) {
    this.currentPayer = client.itemData || this.dialogData.bill.client;

    // let selectedClient = client.itemData ? this.currentPayer : this.dialogData.bill.client;
    this.clientBankNumberResults = [];
    this.clientAddressResults = [];
    this.billEditForm.patchValue({
      clientAddress: this.currentPayer.addresses?.length ? this.currentPayer.addresses[0].fullAddress : '',
      clientBankNumber: this.currentPayer.bankDetails?.length ? this.currentPayer.bankDetails[0].iban : '',
      clientBankName: '',
      contract: null,
    });

    this.currentPayer.addresses?.forEach(item => {
      if (item.fullAddress) {
        this.clientAddressResults.push(item.fullAddress);
      }
    });
    this.currentPayer.bankDetails?.forEach(item => {
      this.clientBankNumberResults.push(item.iban);
    });
    this.populateClientBankNumbers(this.currentPayer);
    this.loadContracts(this.currentPayer);
  }

  loadContracts(client) {
    this.subscriptions.add(
      this.contractService.get({ 'client.id': client.id }).subscribe(res => {
        this.contracts = res;
        this.contracts = res.map(el => {
          el['fieldName'] = `${this.translate.instant('contract.title',
            { contractNumber: el.number, contractFrom: CustomDateAdapter.convertToDate(new Date(el.date)) })}`;
          return el;
        });

        this.billEditForm.controls.contract.setValue(this.bill?.contract?.id);
      }),
    );
  }

  changeContractHandle(contract) {
    if (contract.itemData?.number) {
      this.contract = contract.itemData;
    } else {
      setTimeout(() => {
        this.billEditForm.get('contract').setValue(null);
      });
    }
  }

  changeExecutorHandle(executor) {
    if (executor && executor.itemData) {
      this.contractor = executor.itemData;
      this.executorBankNumberResults = [];
      this.executorBankList = [];
      this.executorAddressResults = [];
      this.billEditForm.patchValue({
        executorAddress: executor.itemData.addresses?.length ? executor.itemData.addresses[0].fullAddress : '',
        executorBankNumber: executor.itemData.bankDetails?.length ? executor.itemData.bankDetails[0].iban : '',
        executorBankName: '',
      });
      executor.itemData.addresses.forEach(item => {
        if (item.fullAddress) {
          this.executorAddressResults.push(item.fullAddress);
        }
      });
      this.executorBankList = executor.itemData.bankDetails;
      executor.itemData.bankDetails.forEach(item => {
        this.executorBankNumberResults.push(item.iban);
      });
      this.populateExecutorBankNumbers(executor);
    }
  }

  private populateBillTimeEntry(timeEntry: ActivityExtended, isFromTimeEntry = false): UntypedFormGroup {
    const type = (timeEntry.timeEntryType && timeEntry.timeEntryType.id) || (timeEntry.type && (timeEntry.type as TimeEntryType).id);
    const user = (timeEntry.responsiblePerson && timeEntry.responsiblePerson.id) || (timeEntry.user && timeEntry.user.id);
    const durationString = (timeEntry.duration && this.formatDuration(timeEntry.duration)) || 0;
    const rate = timeEntry.rate.toString() || null;
    const date = (timeEntry.date && new Date(timeEntry.date)) || null;
    const timeEntryId = ((isFromTimeEntry && timeEntry.id) || timeEntry.timeEntry.id).toString();

    return this.formBuilder.group({
      id: (!isFromTimeEntry && timeEntry.id) || null,
      timeEntry: this.control(timeEntryId, true),
      timeEntryType: this.control(type, true),
      responsiblePerson: this.control(user),
      date: this.control(date, true),
      description: this.control(timeEntry.description || '', true),
      duration: this.formBuilder.control(durationString, [Validators.required]),
      durationHours: this.timeFormatter.simpleSecondsToHours(timeEntry.duration || 0),
      rate: this.formBuilder.control(rate, [Validators.pattern(this.currencyPattern)]),
      currency: this.control((timeEntry.currency && timeEntry.currency.code) || null),
      currencyRate: this.control((timeEntry.currencyRate && timeEntry.currencyRate) || 0),
      tax1Included: this.control((timeEntry.tax1Included && timeEntry.tax1Included) || false),
      tax2Included: this.control((timeEntry.tax2Included && timeEntry.tax2Included) || false),
      nonBillable: this.control(timeEntry.nonBillable),
    });
  }

  private populateBillExpense(expense: ActivityExtended, isFromExpense = false): UntypedFormGroup {
    const type = (expense.expenseType && expense.expenseType.id) || (expense.type && (expense.type as ExpenseType).id) || null;
    const user = (expense.responsiblePerson && expense.responsiblePerson.id) || (expense.user && expense.user.id) || null;

    return this.formBuilder.group({
      id: (!isFromExpense && expense.id) || null,
      expense: this.control(((isFromExpense && expense.id) || expense.expense.id).toString(), true),
      expenseType: this.control(type, true),
      responsiblePerson: this.control(user),
      date: this.control((expense.date && new Date(expense.date)) || null, true),
      description: this.control(expense.description || '', true),
      amount: this.formBuilder.control(expense.amount.toString() || null, [Validators.pattern(this.currencyPattern)]),
      currency: this.control((expense.currency && expense.currency.code) || null),
      currencyRate: this.control((expense.currencyRate && expense.currencyRate) || 0),
      tax1Included: this.control((expense.tax1Included && expense.tax1Included) || false),
      tax2Included: this.control((expense.tax2Included && expense.tax2Included) || false),
    });
  }

  private populateExecutorBankNumbers(executor) {
    this.bankAccountService.getBankAccountsByContact(executor.value).subscribe(res => {
      this.executorBankList = res;
      res.forEach(item => {
        this.executorBankNumberResults.push(item.accountNumber);
      });
      this.executorBankNumberCombo.forEach(dropDown => {
        dropDown.dataSource = this.executorBankNumberResults;
        dropDown.refresh();
      });
      this.executorAddressCombo.forEach(dropDown => {
        dropDown.dataSource = this.executorAddressResults;
        dropDown.refresh();
      });
      if (this.executorBankNumberResults.length) {
        this.billEditForm.patchValue({
          executorBankNumber: this.executorBankNumberResults[0],
        });
      }
    });
  }

  private populateClientBankNumbers(payer) {
    this.bankAccountService.getBankAccountsByContact(payer.id).subscribe(res => {
      res.forEach(item => {
        this.clientBankNumberResults.push(item.accountNumber);
      });
      this.clientBankNumberCombo.forEach(dropDown => {
        dropDown.dataSource = this.clientBankNumberResults;
        dropDown.refresh();
      });
      this.clientAddressCombo.forEach(dropDown => {
        dropDown.dataSource = this.clientAddressResults;
        dropDown.refresh();
      });
      if (this.clientBankNumberResults.length) {
        this.billEditForm.patchValue({
          clientBankNumber: this.clientBankNumberResults[0],
        });
      }
    });
  }

  private createForm(billData: Bill): void {
    this.minDate = new Date(billData.dateInvoice) || new Date();
    this.amountModelDiscount = billData.amountDiscount;
    this.billEditForm = this.formBuilder.group({
      dateInvoice: this.control(new Date(billData.dateInvoice) || new Date()),
      dateExpire: this.control(new Date(billData.dateExpire) || new Date()),
      matter: this.control((billData.matter && billData.matter.id) || null, true),
      executor: this.control(billData.executor.id || '', true),
      status: this.control(billData.status, true),
      description: this.control(billData.description || '', true),
      descriptionToggle: this.control(!!billData.description || false, false),
      descriptionPrePay: this.control(billData.descriptionPrePay, true),
      detailedDescription: this.control(billData.detailedDescription, true),
      amountTimeEntry: this.control(billData.amountTimeEntry || 0, true),
      amountDiscount: this.control(billData.amountDiscount, true),
      discountType: this.control(billData.discountType, true),
      currency: this.control(billData.currency.code),
      typePay: this.control(billData.typePay),
      typeFee: this.control(billData.typeFee, true),
      number: this.control(billData.number),
      lang: this.control(billData.lang, true),
      client: this.control(billData.client.id),
      executorAddress: this.control(billData.executorAddress || '', true),
      executorBankNumber: this.control(billData.executorBankNumber || '', true),
      clientAddress: this.control(billData.clientAddress || '', true),
      clientBankNumber: this.control(billData.clientBankNumber || '', true),
      syncActivities: this.control(true),
      expensesIncluded: this.control(billData.expensesIncluded),
      itemExpenses: this.populateItemExpenses(billData.itemExpenses),
      itemTimeEntries: this.populateItemTimeEntries(billData.itemTimeEntries),
      executorSignatoryInvoice: this.control((billData.executorSignatoryInvoice && billData.executorSignatoryInvoice.id) || null, true),
      taxes: this.populateTaxes(billData.taxes),
      annotation: this.control(billData.annotation || '', true),
      termsAndConditions: this.control(billData.termsAndConditions || '', true),
      clientPayer: this.control(billData.clientPayer?.id || null, true),
      contract: this.control(billData.contract?.id || null, true),
      showNonBillableBillTimeEntry: this.control(billData.showNonBillableBillTimeEntry, true),
      executorBankName: this.control(billData.executorBankName || '', true),
      clientBankName: this.control(billData.clientBankName || '', true),
    });
    this.currentPayer = billData?.clientPayer || billData.client;
    this.contract = billData?.contract;
    if (this.currentPayer) {
      this.loadContracts(this.currentPayer);
    }
    this.tempCurrencyBill = billData.currency.code;
    if (!this.isPostpay) {
      this.f.get('typeFee').disable();
    }
  }

  private subscribeToBill() {
    this.subscriptions.add(
      this.store.select(selectSelectedBill).subscribe(item => {
        if (item) {
          this.bill = item;
          this.getMatter();

          if (this.isPostpay) {
            this.populateDropdownData();
          }
        }
      }),
    );
  }

  private populateDropdownData() {
    this.openSpinner();
    this.expenseTypesApi
      .getExpenseTypes()
      .pipe(map(x => x.member as ExpenseType[]))
      .subscribe(types => {
        this.expenseTypes = types;
        this.hideSpinner();
      });
    this.subscriptions.add(
      this.timeEntryTypesApi
        .getTimeEntryTypes()
        .pipe(map(x => x.member as TimeEntryType[]))
        .subscribe(types => {
          this.timeEntryTypes = types;
          this.hideSpinner();
        }),
    );
  }

  private subscribeToTimeEntryCreation() {
    this.activitiesEffects.addActivity$.subscribe(response => {
      this.hideSpinner();
      if (response && response.payload.type === 'time_entry') {
        const form = this.billEditForm.get('itemTimeEntries') as UntypedFormArray;
        response.payload.tax1Included = this.taxEnable;
        response.payload.tax2Included = this.taxEnableAddition;
        form.push(this.populateBillTimeEntry(response.payload, true));
        this.syncCurrentCurrencyRate('time', form.length - 1);
        const usrs = this.users.map(el => el);
        const user = response.payload.responsiblePerson || response.payload.user;
        if (!usrs.find(us => us.id === +user.id)) {
          usrs.push({
            ...user.person.contact,
            id: user.person.id,
            person: { fullName: user.person.fullName, id: user.person.id },
          });
        }
        this.dataTimeEntriesUsers[this.dataTimeEntriesUsers.length] = usrs;
      }
    });
  }

  private subscribeToExpenseCreation() {
    this.subscriptions.add(
      this.activitiesEffects.addActivity$.subscribe(response => {
        this.hideSpinner();
        if (response && response.payload.type === 'expense') {
          const form = this.billEditForm.get('itemExpenses') as UntypedFormArray;
          response.payload.tax1Included = this.taxEnable;
          response.payload.tax2Included = this.taxEnableAddition;
          form.push(this.populateBillExpense(response.payload, true));
          this.syncCurrentCurrencyRate('expense', form.length - 1);
          const usrs = this.users.map(el => el);
          ;
          const user = response.payload.responsiblePerson || response.payload.user;
          if (!usrs.find(us => us.id === +user.id)) {
            usrs.push({
              ...user.person.contact,
              id: user.person.id,
              person: { fullName: user.person.fullName, id: user.person.id },
            });
          }
          this.dataExpensesUsers[this.dataExpensesUsers.length] = usrs;
        }
      }),
    );
  }

  private subscribeDocument() {
    this.subscriptions.add(
      this.documentEffects.addDocument$.subscribe((res: AddDocumentSuccess) => {
        if (res && !res.payload) {
          this.fileData = null;
        }
      }),
    );
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  getMatter() {
    this.filtersMatters['contact.id'] = this.bill.client.id;
    this.subscriptions.add(
      this.matterService.get(environment.defaultPage, environment.countItemInSelect, this.filtersMatters).subscribe((x: any) => {
        this.matters = x.member;
        if (this.bill.matter && !this.matters.find(item => item.id === this.bill.matter.id)) {
          this.matters.push(this.bill.matter);
        }
      }),
    );
  }

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersMatters.name = e.text;
    this.filtersMatters['contact.id'] = this.bill.client.id;
    this.debounceService.loadDataDebounce(
      BillEditingModalDebounceEnum.matters,
      this.matterService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersMatters]);
    this.debounceService.getValue(BillEditingModalDebounceEnum.matters).subscribe(resp => {
      e.updateData(resp?.member as any);
    });
  };

  public onFilteringUsers: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersUsers.name = e.text;
    this.comboBoxUser?.showSpinner();
    this.debounceService.loadDataDebounce(
      BillEditingModalDebounceEnum.users,
      this.teamMemberService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersUsers]);
    this.debounceService.getValue(BillEditingModalDebounceEnum.users).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxUser?.hideSpinner();
    });
  };

  public onFilteringExecutors: EmitType<any> = (e: FilteringEventArgs, comboEl, keyData) => {
    this.filtersContacts.name = e.text;
    this[comboEl]?.showSpinner();
    this.debounceService.loadDataDebounce(
      keyData,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(keyData).subscribe(resp => {
      e.updateData(resp?.member as any);
      this[comboEl]?.hideSpinner();
    });
  };

  loadUsers() {
    this.subscriptions.add(
      this.store.select(selectListUsersForSelect).subscribe(resp => {
        if (resp == null) {
          this.store.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
        }
        if (resp) {
          this.users = resp;
          if (!resp.find(item => +item.id === +this.bill.executorSignatoryInvoice.id)) {
            const usr = {
              person: {
                id: this.bill.executorSignatoryInvoice.id,
                fullName: this.bill.executorSignatoryInvoiceName,
              },
            };
            this.users.push(usr);
          }

          for (let index = 0; index < this.dialogData.bill.itemTimeEntries.length; index++) {
            const usrs = this.users.map(el => el);
            const user = this.dialogData.bill.itemTimeEntries[index].responsiblePerson || this.dialogData.bill.itemTimeEntries[index].user;
            if (!usrs.find(us => us.id === +user.id)) {
              usrs.push({
                ...user.person.contact,
                id: user.id,
                person: { fullName: user.person.fullName, id: user.person.id },
              });
            }
            this.dataTimeEntriesUsers[index] = usrs;
          }

          for (let index = 0; index < this.dialogData.bill.itemExpenses.length; index++) {
            const usrs = this.users.map(el => el);
            ;
            const user = this.dialogData.bill.itemExpenses[index].responsiblePerson || this.dialogData.bill.itemExpenses[index].user;
            if (!usrs.find(us => us.id === +user.id)) {
              usrs.push({
                ...user.person.contact,
                id: user.id,
                person: { fullName: user.person.fullName, id: user.person.id },
              });
            }
            this.dataExpensesUsers[index] = usrs;
          }

          this.billEditForm.get('executorSignatoryInvoice').setValue(this.bill.executorSignatoryInvoice.id);
        }
      }),
    );
  }

  loadContacts() {
    this.subscriptions.add(
      this.store.select(selectActivePersonAndContactsForSelect).subscribe(data => {
        if (!data) {
          this.store.dispatch(
            new LoadActivePersonAndContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }),
          );
        }
        if (data) {
          this.contacts = data;
          if (!data.find(item => +item.id === +this.bill.executor.id)) {
            this.contacts.push(this.bill.executor);
          }
          this.openSpinner();
          this.bankAccountService.getBankAccountsByContact(this.bill.executor.id).subscribe(res => {
            this.executorBankList = res;
            res.forEach(item => {
              this.executorBankNumberResults.push(item.accountNumber);
            });
            this.hideSpinner();
          });
          this.contractor = this.bill.executor;
          this.bill.executor.addresses.forEach(item => {
            if (item.fullAddress) {
              this.executorAddressResults.push(item.fullAddress);
            }
          });

          if (!data.find(item => item.id === this.bill.client.id)) {
            this.contacts.push(this.bill.client);
          }
          this.client = this.bill.client;
          this.bill.client.addresses.forEach(item => {
            if (item.fullAddress) {
              this.clientAddressResults.push(item.fullAddress);
            }
          });
          this.bill.client.bankDetails.forEach(item => {
            this.clientBankNumberResults.push(item.iban);
          });

          this.billEditForm.controls.clientPayer.setValue(this.bill?.client?.id);
        }
      }),
    );
  }

  fixDuration(event, form, control) {
    setTimeout(() => {
      let temp = event.target.value.replace(/[^.\d]+/g, '')
        .replace(/^([^\.]*\.)|\./g, '$1');
      this[form].controls[event.path[0].id].get(control).setValue((+temp).toFixed(2));
    }, 100);
  }

  fixCurrencyRate(e, type, i) {
    setTimeout(() => {
      if (type === 'expense') {
        const preg = this.itemExpenses.controls[i]
          .get('currencyRate')
          .value.replace(/[^.\d]+/g, '')
          .replace(/^([^\.]*\.)|\./g, '$1');
        this.itemExpenses.controls[i].get('currencyRate').setValue((+preg).toFixed(4));
      }
      if (type === 'time') {
        const preg = this.itemTimeEntries.controls[i]
          .get('currencyRate')
          .value.replace(/[^.\d]+/g, '')
          .replace(/^([^\.]*\.)|\./g, '$1');
        this.itemTimeEntries.controls[i].get('currencyRate').setValue((+preg).toFixed(4));
      }
    }, 100);
  }

  // зміна дати інвойсу
  changeDateInvoice(e) {
    this.minDate = e.value || this.today;
    this.changeRateDate = true;
    this.uniqCurrency = [];
    if (this.bill.typePay !== 'prepay') {
      this.syncTimesAndExpenses();
    }
  }

  changeCurrencyBill(e) {
    e.preventDefault();
    this.changeRateDate = false;
    this.uniqCurrency = [];
    if (this.bill.typePay !== 'prepay') {
      setTimeout(() => {
        this.syncTimesAndExpenses(this.tempCurrencyBill);
        this.f.get('currency').setValue(this.tempCurrencyBill);
      }, 100);
    } else {
      setTimeout(() => {
        this.f.get('currency').setValue(this.tempCurrencyBill);
      }, 100);
    }
    this.currencyDrop = false;
  }

  changeBillCurrency(e) {
    this.tempCurrencyBill = e.itemData.value;
  }

  // сихронізація конкретного ітема
  syncCurrentCurrencyRate(type, i) {
    // tslint:disable-next-line: deprecation
    event.stopPropagation();
    // tslint:disable-next-line: deprecation
    event.preventDefault();
    if (type === 'time') {
      this.uniqCurrency = [];
      this.syncTimeItemRate(i);
    }
    if (type === 'expense') {
      this.uniqCurrency = [];
      this.syncExpenseItemRate(i);
    }
  }

  syncTimeItemRate(i) {
    const currencyCurrentTime = this.itemTimeEntries.controls[i].value.currency;
    const currencyBill = this.billEditForm.get('currency').value;
    if (currencyCurrentTime !== currencyBill) {
      this.openSpinner();
      this.exchangeService
        .getRate(currencyBill, currencyCurrentTime, CustomDateAdapter.convertToUniversalDateString(this.minDate))
        .subscribe(res => {
          if (res) {
            this.itemTimeEntries.controls[i].get('currencyRate').setValue((+res.rate).toFixed(6));
          }
          this.hideSpinner();
        });
    } else {
      this.itemTimeEntries.controls[i].get('currencyRate').setValue(1);
    }
  }

  syncExpenseItemRate(i) {
    const currencyCurrentExpense = this.itemExpenses.controls[i].value.currency;
    const currencyBill = this.billEditForm.get('currency').value;
    if (currencyCurrentExpense !== currencyBill) {
      this.openSpinner();
      this.exchangeService
        .getRate(currencyBill, currencyCurrentExpense, CustomDateAdapter.convertToUniversalDateString(this.minDate))
        .subscribe(res => {
          if (res) {
            this.itemExpenses.controls[i].get('currencyRate').setValue((+res.rate).toFixed(6));
          }
          this.hideSpinner();
        });
    } else {
      this.itemExpenses.controls[i].get('currencyRate').setValue(1);
    }
  }

  // сихронізація всіх або витрат або часу
  syncAllCurrencyRate(who) {
    // tslint:disable-next-line: deprecation
    event.preventDefault();
    this.uniqCurrency = [];
    if (who === 'expense' && this.uniqCurrency[0]) {
      this.syncAllExpenseRates();
      this.changeRateDate = false;
      this.getAllRate(this.uniqCurrency[0].fromC, this.uniqCurrency[0].toC, 0);
    }
    if (who === 'time' && this.uniqCurrency[0]) {
      this.syncAllTimeRates();
      this.changeRateDate = false;
      this.getAllRate(this.uniqCurrency[0].fromC, this.uniqCurrency[0].toC, 0);
    }
  }

  getAllRate(currencyBill, expCurrency, i) {
    this.openSpinner();
    this.exchangeService.getRate(currencyBill, expCurrency, CustomDateAdapter.convertToUniversalDateString(this.minDate)).subscribe(res => {
      this.uniqCurrency[i].rate = (+res.rate).toFixed(6);
      i++;
      if (i < this.uniqCurrency.length) {
        this.getAllRate(this.uniqCurrency[i].fromC, this.uniqCurrency[i].toC, i);
      } else {
        this.hideSpinner();
        this.openConvertorModal();
      }
    });
  }

  syncAllExpenseRates(currency?) {
    const currencyBill = currency ? currency : this.billEditForm.get('currency').value;
    for (const expense of this.itemExpenses.controls) {
      if (!this.uniqCurrency.find(item => item.toC === expense.value.currency) && currencyBill !== expense.value.currency) {
        this.uniqCurrency.push({
          fromC: currencyBill,
          toC: expense.value.currency,
          rate: 1,
          who: 'expense',
        });
      } else if (currency === expense.value.currency) {
        expense.get('currencyRate').setValue(1);
      }
    }
  }

  syncTimesAndExpenses(currency?) {
    this.syncAllExpenseRates(currency);
    this.syncAllTimeRates(currency);
    for (const curr of this.uniqCurrency) {
      curr.who = 'all';
      curr.fromC = currency ? currency : curr.fromC;
    }
    if (this.uniqCurrency[0]) {
      this.openSpinner();
      this.getAllRate(this.uniqCurrency[0].fromC, this.uniqCurrency[0].toC, 0);
    }
  }

  openConvertorModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'events-modal'];
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      amount: 1,
      fromBilling: true,
      fromBillEdit: true,
      uniqCurrency: this.uniqCurrency,
      titleBillEditData: this.changeRateDate
        ? `На ${CustomDateAdapter.convertToDate(this.minDate)} року курси становлять Встановити дані курси для усіх записів інвойсу?`
        : '',
    };
    const dialogRef = this.dialog.open(ConverterModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result[0].who === 'all') {
          this.changeRatesInExpenses(result);
          this.changeRatesInTimes(result);
        }
        if (result[0].who === 'time') {
          this.changeRatesInTimes(result);
        }
        if (result[0].who === 'expense') {
          this.changeRatesInExpenses(result);
        }
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  changeRatesInExpenses(res) {
    for (const expense of this.itemExpenses.controls) {
      const curentValue = res.find(item => item.toC === expense.value.currency);
      expense.get('currencyRate').setValue(curentValue ? curentValue.rate : 1);
    }
  }

  changeRatesInTimes(res) {
    for (const timeEntries of this.itemTimeEntries.controls) {
      const curentValue = res.find(item => item.toC === timeEntries.value.currency);
      timeEntries.get('currencyRate').setValue(curentValue ? curentValue.rate : 1);
    }
  }

  syncAllTimeRates(currency?) {
    const currencyBill = currency ? currency : this.billEditForm.get('currency').value;
    for (const timeEntries of this.itemTimeEntries.controls) {
      if (!this.uniqCurrency.find(item => item.toC === timeEntries.value.currency) && currencyBill !== timeEntries.value.currency) {
        this.uniqCurrency.push({
          fromC: currencyBill,
          toC: timeEntries.value.currency,
          amount: timeEntries.value.amount,
          rate: 1,
          who: 'time',
        });
      } else if (currency === timeEntries.value.currency) {
        timeEntries.get('currencyRate').setValue(1);
      }
    }
  }

  keydownEvent(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  calculateTotal() {
    // початкова сума без початкових витрат
    let startTotal = +this.bill.amountTimeEntry - +this.bill.amountBillItemExpense;

    // додаємо до цієї суми введені користувачем витрати
    startTotal += this.calculateExpenceSubTotal();

    // змінюємо amount на новий
    this.billEditForm.get('amountTimeEntry').setValue(startTotal);

    // повертаємо для візуалки тотал
    return startTotal;
  }

  changeTaxEnable() {
    this.taxEnable = !this.taxEnable;
    this.taxEnableAddition = false;
    this.changeTax(1);

    if (this.taxEnable) {
      this.taxes.get('tax1Rate').setValidators([Validators.required, Validators.min(0.01), Validators.max(100)]);
      this.taxes.get('tax2Rate').clearValidators();
      this.taxes.get('tax1Rate').updateValueAndValidity();
      this.taxes.get('tax2Rate').updateValueAndValidity();
    } else {
      this.taxes.get('tax1Rate').clearValidators();
      this.taxes.get('tax2Rate').clearValidators();
      this.taxes.get('tax1Rate').updateValueAndValidity();
      this.taxes.get('tax2Rate').updateValueAndValidity();
    }

    this.itemTimeEntries.controls.map(item => {
      item.get('tax1Included').setValue(this.taxEnable);
    });
    this.itemExpenses.controls.map(item => {
      item.get('tax1Included').setValue(this.taxEnable);
    });
  }

  changeTaxEnableAddition(check) {
    this.taxEnableAddition = check;
    this.changeTax(2);

    if (this.taxEnableAddition) {
      this.taxes.get('tax1Rate').setValidators([Validators.required, Validators.min(0.01), Validators.max(100)]);
      this.taxes.get('tax2Rate').setValidators([Validators.required, Validators.min(0.01), Validators.max(100)]);
      this.taxes.get('tax1Rate').updateValueAndValidity();
      this.taxes.get('tax2Rate').updateValueAndValidity();
    } else {
      this.taxes.get('tax2Rate').clearValidators();
      this.taxes.get('tax2Rate').updateValueAndValidity();
    }
    this.itemTimeEntries.controls.map(item => {
      item.get('tax2Included').setValue(this.taxEnableAddition);
    });
    this.itemExpenses.controls.map(item => {
      item.get('tax2Included').setValue(this.taxEnable);
    });
  }

  changeTax(numberTax) {
    this.taxes.patchValue({
      tax1Rate: numberTax === 1 && this.taxEnable ? this.companySetting.tax1Rate : this.taxes.value.tax1Rate,
      tax2Rate: this.companySetting.tax2Rate,
    });
  }

  onlyNumber(event, taxOrDiscount) {
    if ((event.which >= 48 && event.which <= 57) || event.which === 46) {
      setTimeout(() => {
        let temp = event.target.value;
        const currentValue = temp.split('.');
        if (currentValue.length > 2) {
          currentValue.pop();
          temp = currentValue[0] + '.' + currentValue[1];
        }
        const countAfter = currentValue[currentValue.length - 1].length;
        if (temp.includes('.') && countAfter > 2) {
          if (currentValue[1][0] > 9) {
            temp = currentValue[0] + '.' + '9' + currentValue[1][1];
          } else {
            temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
          }
        }
        if (taxOrDiscount === 'amountDiscount' || taxOrDiscount === 'amountTimeEntry') {
          this.f.get(taxOrDiscount).setValue(temp);
          if (taxOrDiscount === 'amountDiscount' && this.f.get('discountType').value === 'rate') {
            if (+this.f.get(taxOrDiscount).value > 100) {
              this.f.get(taxOrDiscount).setValue('100');
            }
          }
        } else {
          this.taxes.get(taxOrDiscount).setValue(temp);
          if (+this.taxes.get(taxOrDiscount).value > 100) {
            this.taxes.get(taxOrDiscount).setValue('100');
          }
        }
      }, 10);
      return true;
    }

    return false;
  }

  fixAmountRefresh(control) {
    setTimeout(() => {
      this.f.get(control).setValue(this.f.get(control).value);
    }, 10);
  }

  changeDiscountType(e) {
    if (e.itemData.type === 'rate') {
      if (+this.f.get('amountDiscount').value > 100) {
        this.f.get('amountDiscount').setValue('100');
      }
    }
    this.f.get('discountType').setValue(e.itemData.type);
  }

  blurAutocomplete(field) {
    this.editableToggle[field] = false;
  }

  visibleAutocomplete(field) {
    this.editableToggle[field] = true;
    setTimeout(() => {
      switch (field) {
        case 'clientAddress':
          this.clientAddress.focusIn();
          break;
        case 'clientBankNumber':
          this.clientBankNumber.focusIn();
          break;
        case 'executorAddress':
          this.executorAddress.focusIn();
          break;
        case 'executorBankNumber':
          this.executorBankNumber.focusIn();
          break;
        case 'clientPayer':
          this.clientPayer.focusIn();
          break;
        case 'contract':
          this.contractComboBox?.focusIn();
          break;
        case 'clientBankName':
          this.clientBankName.focusIn();
          break;
        case 'executorBankName':
          this.executorBankName.focusIn();
          break;
      }
    }, 300);
  }

  onFileChanged(fileInput) {
    this.fileData = fileInput.target.files[0] as File;
    this.errorMaxSizeFile = false;
    const filesize = (this.fileData.size / 1024 / 1024).toFixed(4); // MB

    if (+filesize >= 9.9) {
      this.fileData = null;
      this.errorMaxSizeFile = true;
      return;
    }

    if (this.fileData) {
      this.fileName = this.fileData.name;

      this.documentUploadModal(this.fileData);
    }
    this.inputFile.nativeElement.value = null;
  }

  private documentUploadModal(file: File) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { fileName: file.name, skipMatter: true };
    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(x => {
        if (x) {
          x.document.bill = this.bill.id;
          this.fileName = x.document.name;
          document.getElementById('fileName').innerHTML = this.fileName;

          this.store.dispatch(
            new AddDocument({
              document: x.document,
              mediaFile: this.fileData,
              reminders: x.reminders,
              relations: x.relations,
            }),
          );
        } else {
          this.fileData = null;
        }
      }),
    );
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  changeExecutor() {
    this.editableToggle.changeExecutor = true;
    setTimeout(() => {
      this.executorComboBox.focusIn();
    }, 200);
  }

  public get billEditingModalDebounceEnum(): typeof BillEditingModalDebounceEnum {
    return BillEditingModalDebounceEnum;
  }

  checkVisibleRow(item) {
    if (this.billEditForm.get('showNonBillableBillTimeEntry').value) {
      return true;
    } else if (!this.billEditForm.get('showNonBillableBillTimeEntry').value && item.value.nonBillable) {
      return false;
    } else {
      return true;
    }
  }

  public onFilteringUsersInList: EmitType<any> = (e: FilteringEventArgs, idx) => {
    const filtersUsers = {
      status: 'active',
      'companyAccesses.enable': true,
      name: e.text,
    };

    this.comboBoxUser?.showSpinner();
    this.debounceService.loadDataDebounce(
      BillEditingModalDebounceEnum.usersList + idx,
      this.teamMemberService, 'get',
      [environment.defaultPage, environment.countItemInSelect, filtersUsers]);
    this.debounceService.getValue(BillEditingModalDebounceEnum.usersList + idx).subscribe(resp => {
      e.updateData(resp?.member as any);
    });
  };

  public selectClientBankNumber($event: any) {
    const candidate = this.currentPayer.bankDetails.find(item => item.iban === $event.itemData?.value);
    if (candidate) {
      this.billEditForm.patchValue({
        clientBankName: candidate.name,
      });
    }
  }

  public selectExecutorBankNumber($event: any) {
    const candidate = this.executorBankList.find(item => item.accountNumber === $event.itemData?.value);
    if (candidate) {
      this.billEditForm.patchValue({
        executorBankName: candidate.accountName,
      });
    }
  }
}
