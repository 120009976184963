import { Action } from '@ngrx/store';
import { ContactFinance } from '@app/models/contact';

export enum ContactCardActionsTypes {
  LOAD_CONTACT_FINANCE = '[CONTACT_FINANCE] Load Contact Finance',
  LOAD_CONTACT_FINANCE_SUCCESS = '[CONTACT_FINANCE] Load Contact Finance Success',
  LOAD_CONTACT_FINANCE_FAILURE = '[CONTACT_FINANCE] Load Contact Finance Failure'
}
export class LoadContactFinanceAction implements Action {
  readonly type = ContactCardActionsTypes.LOAD_CONTACT_FINANCE;

  constructor(public payload: number) {}
}
export class LoadContactFinanceSuccessAction implements Action {
  readonly type = ContactCardActionsTypes.LOAD_CONTACT_FINANCE_SUCCESS;

  constructor(public payload: ContactFinance) {}
}
export class LoadContactFinanceFailureAction implements Action {
  readonly type = ContactCardActionsTypes.LOAD_CONTACT_FINANCE_FAILURE;

  constructor(public payload: Error) {}
}

export type ContactCardActions = LoadContactFinanceAction | LoadContactFinanceSuccessAction | LoadContactFinanceFailureAction;
