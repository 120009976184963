import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TaskExtended, TaskSave, ResponseBodyFull } from '@app/models';
import { TasksFilters } from '@app/models/tasks-filters';
import { TaskStatus } from '@app/enums/task-status.enum';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { Paginator } from '@app/models/paginator';
import { TaskReminder } from '@app/models/reminder-extended';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  private BASE_URL = `${environment.apiUrl}/tasks`;

  private REMIND_URL = `${environment.apiUrl}/task_reminders`;

  getTasks(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters: TasksFilters): Observable<Paginator<TaskExtended>> {
    filters.fromDashboard = undefined;
    const sendFilters = { ...filters };
    this.BASE_URL = `${environment.apiUrl}/tasks`;
    sendFilters.clearFilters = undefined;
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (sendFilters && (sendFilters.status === 'upcoming' || sendFilters.status === 'overdue')) {
      this.BASE_URL = `${environment.apiUrl}/tasks?status[]=new&status[]=in_work`;
      // &startDate[after]=${CustomDateAdapter.convertToUniversalDateString(new Date())}

      if (sendFilters.status === 'overdue') {
        this.BASE_URL = `${environment.apiUrl}/tasks?status[]=new&status[]=in_work&endDate[before]=${new Date().toISOString()}`;
      }

      sendFilters.status = null;
    } else if (sendFilters && sendFilters.status && sendFilters.status !== 'upcoming') {
      this.BASE_URL = `${environment.apiUrl}/tasks`;
    }
    if (sendFilters.status === 'calendar') {
      this.BASE_URL = `${environment.apiUrl}/tasks?status[]=new&status[]=in_work`;
      sendFilters.status = null;
    }

    if (sendFilters) {
      params = Helpers.populateFilters(params, sendFilters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<TaskExtended>>(this.BASE_URL, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<TaskExtended>>();
    }
  }

  getTaskReminder(id): Observable<TaskReminder[]> {
    return this.http.get<ResponseBodyFull<TaskReminder>>(this.REMIND_URL + `?task.id=${id}`)
    .pipe(map(response => (response['hydra:member'])));

  }
  // task.id=

  postTaskReminder(reminder) {
    if (reminder.reminderDate != null) {
      return this.http.post<TaskReminder>(this.REMIND_URL, reminder);
    }
  }

  putTaskReminder(reminder) {
    if (reminder.reminderDate != null) {
      return this.http.patch<TaskReminder>(`${this.REMIND_URL}/${reminder.id}`, reminder);
    }
  }

  deleteTaskReminder(reminder) {
    return this.http.delete<TaskReminder>(`${this.REMIND_URL}/${reminder.id}`, reminder);
  }

  getTaskByCorrespondence(id: number): Observable<TaskExtended[]> {
    return this.http
      .get<ResponseBodyFull<TaskExtended>>(`${environment.apiUrl}/tasks?correspondence.id=${id}`)
    .pipe(map(response => (response['hydra:member'])));
  }

  getTaskById(id: number): Observable<TaskExtended> {
    return this.http.get<TaskExtended>(`${environment.apiUrl}/tasks/${id}`);
  }

  getTasksByStatus(status: TaskStatus, currentPage = 1, itemsPerPage = environment.defaultItemsCount): Observable<TaskExtended[]> {
    return this.http
      .get<ResponseBodyFull<TaskExtended>>(`${environment.apiUrl}/tasks?status=${status}&page=${currentPage}&itemsPerPage=${itemsPerPage}`)
      .pipe(map(response => (response['hydra:member'])));
  }

  addTask(task: TaskExtended | TaskSave): Observable<TaskExtended> {
    return this.http.post<TaskExtended>(`${environment.apiUrl}/tasks`, task);
  }

  updateTask(task: TaskExtended | TaskSave): Observable<TaskExtended> {
    if (task && task.id) {
      return this.http.patch<TaskExtended>(`${environment.apiUrl}/tasks/${task.id}`, task);
    }
    return new Observable<TaskExtended>();
  }

  updateTaskStatus(id: number, status: TaskStatus): Observable<TaskExtended> {
    return this.http
      .patch<TaskExtended>(`${environment.apiUrl}/tasks/${id}`, { status });
  }

  deleteTask(id: number) {
    return this.http.delete(`${environment.apiUrl}/tasks/${id}`);
  }

  changeOrderCanbanTasks(task) {
    return this.http.patch<TaskExtended>(`${environment.apiUrl}/tasks/${task.id}`, {
      orderCanban: task.orderCanban,
      status: task.status
    });
  }
}
