import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[scale]'
})
export class ScaleDirective {
  constructor(private element: ElementRef) {}
  // scale = 1;

  @HostListener("wheel", ["$event"])
  public zoom(event) {
    //  event.preventDefault();
    //  this.scale += event.deltaY * -0.01;
    //  this.scale = Math.min(Math.max(.125, this.scale), 4);
    //  this.element.nativeElement.style.transform = `scale(${this.scale})`;

    event.preventDefault();
    var xs = (event.clientX - this.pointX) / this.scale,
      ys = (event.clientY - this.pointY) / this.scale,
      delta = (event.wheelDelta ? event.wheelDelta : -event.deltaY);
    (delta > 0) ? (this.scale *= 1.2) : (this.scale /= 1.2);
    this.pointX = event.clientX - xs * this.scale;
    this.pointY = event.clientY - ys * this.scale;

    this.setTransform();
    }


    scale = 1;
    panning = false;
    pointX = 0;
    pointY = 0;
    start = { x: 0, y: 0 };

  setTransform() {
    this.element.nativeElement.style.transform = "translate(" + this.pointX + "px, " + this.pointY + "px) scale(" + this.scale + ")";
  }

  // zoom.onmousedown = function (e) {
  //   e.preventDefault();
  //   start = { x: e.clientX - pointX, y: e.clientY - pointY };
  //   panning = true;
  // }

  // zoom.onmouseup = function (e) {
  //   panning = false;
  // }

  // zoom.onmousemove = function (e) {
  //   e.preventDefault();
  //   if (!panning) {
  //     return;
  //   }
  //   pointX = (e.clientX - start.x);
  //   pointY = (e.clientY - start.y);
  //   setTransform();
  // }


}
