import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounce } from '@syncfusion/ej2-base';
import { environment } from '../../environments/environment';

/**
 * data типу {key1: BehaviorSubject, key2: BehaviorSubject ... key999: BehaviorSubject}
 * service - звідки викликати метод
 * meth - назва методу в сервісі
 * args - параметри які передаються в метод
 */
@Injectable()
export class DebounceService {

  private data = {};
  private service;
  private meth;
  private args;

  /**
   * функція debounce, яка приймає ключ
   * викликає метод з сервіса
   * і записує дані в об'єкт по ключу
   */
  private load = debounce((key) => {
    this.service[this.meth](...this.args).subscribe(res => {
      this.data[key].next(res);
    });
  }, environment.debounceLoadTime);

  /**
   *
   * @param key ключ даних певного компоненту
   * @param service сервіс з якого виконується запит
   * @param meth метод з якого витягуються дані
   * @param args параметри у метод сервіса
   */
  loadDataDebounce(key, service, meth, args) {
    this.service = service;
    this.meth = meth;
    this.args = args;
    if (!Object.prototype.hasOwnProperty.call(this.data, key)) {
      this.data[key] = new BehaviorSubject<any>(null);
    }
    this.load(key);
  }

  /**
   *
   * @param key ключ даних певного компоненту
   * @returns повертає Observable, на якому встановлюється підписка і по ключу повертають потрібні дані
   */
  public getValue(key): Observable<any> {
    return this.data[key];
  }

  /**
   * видаляє дані з об'єкту коли виконується в компоненті ngOnDestroy
   */
  removeDebounceData() {
    for (const key in this.data) {
      if (Object.prototype.hasOwnProperty.call(this.data, key)) {
        delete this.data[key];
      }
    }
  }
}
