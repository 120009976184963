import { Component, OnInit, Inject, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { map, take, mergeMap } from 'rxjs/operators';
import { DocumentService } from '@app/modules/reusable/documents/services/document.service';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { environment } from '@environments/environment';
import { Contact, DocumentExtended } from '@app/models';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { Bill } from '@app/models/bill';
import { AttachFilesToCommentModalComponent } from '@app/commonComponents/wide-modals/attach-files-to-comment-modal/attach-files-to-comment-modal.component';
import { ChatService } from '@app/modules/layout/discussion/services/chat.service';
import { CommunicationService } from '@app/modules/reusable/jusnote-connect/services/communication.service';
import { DiscussionService } from '@app/modules/layout/discussion/services/discussion.service';
import { CommunicationPublicService } from '@app/modules/layout/discussion/services/communication.service';
import { HelpersServices } from '@app/helpers/helpers.services';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { IComment } from '@app/models/comment';
import { AddDocuments } from '@app/store/actions/document.actions';
import { DocumentEffects } from '@app/store/effects/document.effects';
import { DebounceService } from '../../../../helpers/debounce.service';
import { ShareEmailModalDebounceEnum } from '../../../../enums/debounce-keys.enum';
import { Helpers } from '@app/helpers/helpers';
import { fadeTopAnimation } from '@app/helpers/fadeAnimation';

@Component({
  selector: 'app-share-email-modal',
  templateUrl: './share-email-modal.component.html',
  styleUrls: ['./share-email-modal.component.scss'],
  animations: [fadeTopAnimation]
})
export class ShareEmailModalComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<ShareEmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private appSettingsService: AppSettingsService,
    private billService: BillService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private documentService: DocumentService,
    private contactService: ContactService,
    private dialog: MatDialog,
    private chatService: ChatService,
    private communicationService: CommunicationService,
    private discussionService: DiscussionService,
    private communicationPublicService: CommunicationPublicService,
    private helpersServices: HelpersServices,
    private store: Store<IAppState>,
    private documentEffects: DocumentEffects,
    private debounceService: DebounceService
  ) {}
  public locale$ = this.appSettingsService.getLang;
  contacts;
  findsInitialContacts;
  contact;
  ifEmailDoc = this.dialogData.ifEmailDoc;
  file = this.dialogData.file;
  docViewer = environment.docViewer;
  connect = this.dialogData.connect;
  list;
  connectContacts: Contact[];
  accessList;

  heightForList = '508px';
  @ViewChild('viewer', { static: false }) viewer: ElementRef;

  message = this.ifEmailDoc
    ? ''
    : 'Dear partner, \nThank you for your business. Your invoice can be viewed, printed and downloaded as PDF from the link below. You can also choose to pay it online.';

  emails = []; // 'qqqq@gmail.com', 'ggwark@gmail.com'
  checkSubmit = false;

  idEmails = 0;

  range;
  sel;

  showLoaderSendEmail = false;
  showLoaderPreview = false;
  showLoaderBill = false;

  // for emails
  nextElementEmail;
  visibleListEmails = false;
  topPositionListContact;
  leftPositionListContact;

  @ViewChild('contactComboBox', { static: false })
  public contactComboBox: ComboBoxComponent;
  @ViewChild('emailInput', { static: false }) emailInput: ElementRef;

  currentMsg = '';
  attachedFiles: { documents: DocumentExtended[]; bills: Bill[] };
  connectContact: Contact;
  selectedNewDocuments;

  loadContactEmails = false;

  selectedContact = false;
  dataContent;
  descIn = 'unfocus';

  ngOnInit() {
    if (this.connect) {
      this.loadConnectContacts().pipe(take(1)).subscribe(resp => {
        this.connectContacts = resp.member;
      });
      this.heightForList = '565px';
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.ifEmailDoc) {
        const billFrame = document.getElementById('share-bill-iframe');
        billFrame.setAttribute('src', this.dialogData.invoiceUrl);
      } else {
        if (this.checkFile() === 'doc') {
          this.showLoaderPreview = true;
          setTimeout(() => {
            this.showLoaderPreview = false;
          }, 5000);
        }
        this.accessList = this.dialogData.document.documentEmailLinks;
        this.documentService.getContactEmails('').subscribe(res => {
          this.findsInitialContacts = res;
          for (const sendsList of this.accessList) {
            res = res.filter(contact => sendsList.email !== contact.email);
          }
          this.contacts = res.map(contact => {
            contact.nameEmail = `${contact.name} (${contact.email})`;
            return contact;
          });
        });
      }
      this.addHeight();
    });
  }

  onSubmit() {
    if (this.ifEmailDoc && !this.connect) {
      this.checkSubmit = true;
      if (!this.contact || isNaN(this.contact) || !this.message) {
        return false;
      }
      const payload = {
        contact: this.contact,
        message: this.message
      };
      this.sendMessToEmail(payload);
    } else if (this.connect) {
      this.submitConnect();
    } else {
      this.getAllEmails();
      const data = {
        text: this.message,
        emails: this.emails
      };
      this.checkSubmit = true;
      if (!data.emails.length || !this.emailsValid()) {
        return false;
      }
      if (!this.dialogData.bill.hash || !this.dialogData.bill.expiredHashAt || new Date(this.dialogData.bill.expiredHashAt) < new Date()) {
        this.showLoaderBill = true;
        this.billService
          .getHash(this.dialogData.bill.id)
          .subscribe((response: any) => {
            this.showLoaderBill = false;
            this.billService.sendBillToEmail(data, response.hash, this.dialogData.rightState).subscribe(res => {
              this.showLoaderBill = false;
              this.toastrService.success(this.translate.instant('bill.afterSendMail'));
              this.dialogRef.close(res);
            });
          });
      } else {
        this.billService.sendBillToEmail(data, this.dialogData.bill.hash, this.dialogData.rightState).subscribe(res => {
          this.showLoaderBill = false;
          this.toastrService.success(this.translate.instant('bill.afterSendMail'));
          this.dialogRef.close(res);
        });
      }
    }
  }

  emailsValid() {

    let checkEmails = true;
    for (const email of this.emails) {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      if (emailRegex.test(email.trim()) === false) {
        checkEmails = false;
      }
    }
    return checkEmails;
  }

  sendMessToEmail(payload) {
    this.contacts = null;
    this.showLoaderSendEmail = true;
    this.documentService
      .sendDocEmails(this.dialogData.document.id, payload)
      .pipe(take(1))
      .subscribe(res => {
        this.showLoaderSendEmail = false;
        this.accessList.push(res);
        this.dialogData.document.documentEmailLinks = this.accessList;
        this.contact = null;
        this.message = '';
        this.checkSubmit = false;
        this.toastrService.success(this.translate.instant('documents.accessToDocument'));
        for (const sendsList of this.accessList) {
          this.contacts = this.findsInitialContacts.filter(contact => sendsList.email !== contact.email);
        }
        setTimeout(() => {
          const comboObj = (document.getElementById('contactComboBox') as any).ej2_instances[0];
          comboObj.dataSource = this.contacts;
          comboObj.dataBind();
        }, 200);
        this.addHeight();
      });
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  checkFile() {
    // TODO добавити під різні типи картинок
    if (this.file && (this.file.mimeType === 'image/png' || this.file.mimeType === 'image/jpeg')) {
      return 'image';
    } else {
      return 'doc';
    }
  }

  ngOnDestroy() {
    delete this.viewer;
    this.debounceService.removeDebounceData();
  }

  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.contactComboBox?.showSpinner();
    this.debounceService.loadDataDebounce(
      ShareEmailModalDebounceEnum.contacts,
      this.documentService, 'getContactEmails',
      [e.text]);
    this.debounceService.getValue(ShareEmailModalDebounceEnum.contacts).subscribe(x => {
      for (const sendsList of this.accessList) {
        x = x.filter(contact => sendsList.email !== contact.email);
      }
      const findsContacts = x.map(contact => {
        contact.nameEmail = `${contact.name} (${contact.email})`;
        return contact;
      });
      e.updateData(findsContacts as any);
      this.contactComboBox?.hideSpinner();
    });
  }

  isNaN(str) {
    return isNaN(str);
  }

  deleteItem(id: number) {
    this.contacts = null;
    this.documentService
      .deleteEmailDocLink(id)
      .pipe(take(1))
      .subscribe(res => {
        this.toastrService.success(this.translate.instant('documents.accessClosed'));
      });
    this.accessList = this.accessList.filter(res => res.id !== id);
    this.dialogData.document.documentEmailLinks = this.accessList;
    for (const sendsList of this.accessList) {
      this.contacts = this.findsInitialContacts.filter(contact => sendsList.email !== contact.email);
    }
    if (!this.accessList.length) {
      this.contacts = this.findsInitialContacts;
    }

    setTimeout(() => {
      const comboObj = (document.getElementById('contactComboBox') as any).ej2_instances[0];
      comboObj.dataSource = this.contacts;
      comboObj.dataBind();
    }, 200);
    this.addHeight();
  }
  loaded(x) {
    this.showLoaderPreview = false;
    const t = x;
  }

  // for height animation
  addHeight() {
    setTimeout(() => {
      if (this.ifEmailDoc) {
        const list = document.getElementsByClassName('access-list')[0] as HTMLElement;
        let listHeight = 0;
        if (list) {
          listHeight = list.offsetHeight;
        }
        if (listHeight === 0) {
          this.heightForList = '508px';
        } else if (listHeight === 108) {
          this.heightForList = '620px';
        } else {
          this.heightForList = '710px';
        }
      } else {
        this.heightForList = '508px';
      }
      if (this.connect) {
        this.heightForList = '565px';
      }
    }, 0);
  }

  middlewareInputWithUser(em, element, ifBlur = false) {
    if (element) {
      element.remove();
    }
    if (!ifBlur) {
      this.pasteHtmlAtCaret(this.getHtmlWithEmail(em));
    } else {
      this.emailInput.nativeElement.innerHTML += this.getHtmlWithEmail(em);
    }

    this.addEventsForDeleteEmails();
  }

  addEventsForDeleteEmails() {
    // Добавлення нової події на видалення елементу
    setTimeout(() => {
      const allCross = (document.getElementsByClassName('remove-email') as unknown) as HTMLElement[];
      for (let i = 0; i < allCross.length; i++) {
        allCross[i].onclick = e => {
          e.target['parentElement'].remove();
          this.getAllEmails();
          if (!this.emails.length) {
            const inputEmails = document.getElementById('editable-email');
            inputEmails.innerHTML = '';
          }
        };
      }
      this.getAllEmails();
    }, 100);
  }

  getHtmlWithEmail(em) {
    const email = em.trim();
    const validEmail = Helpers.checkValidEmail(email) ? '' : 'error';

    const innerEmail = `
    <span class="container-email email-mention ${validEmail}" contenteditable=false>
    ${email}
    <i class="j-icon-cross remove-email"></i>
    </span> &nbsp;`;

    return innerEmail;
  }

  checkPressKeys(e) {
    // добавлення нового емейла по пробілу і ентеру
    let nextEmail = '';
    const containerEmails = document.getElementById('editable-email') as any;

    containerEmails.childNodes.forEach(element => {
      if (element.nodeName === '#text') {
        const clearNewEmail = element.data.replace('&nbsp;', '').replace(/\s+/g, '');
        if (clearNewEmail.length > 2) {
          nextEmail = clearNewEmail;
          this.nextElementEmail = element;
          if (e.code !== 'Space' && e.code !== 'Enter') {
            this.findEmailUsers(nextEmail);
          }
        } else {
          this.visibleListEmails = false;
        }
      }
    });
    if (e.code === 'Space' || e.code === 'Enter') {
      e.preventDefault();
      if (nextEmail.length >= 3 && nextEmail !== '&nbsp;') {
        this.sel = window.getSelection();
        this.range = this.sel.getRangeAt(0);
        if (e.code === 'Enter' && this.contacts.length) {
          this.selectContact(this.contacts[0]);
        } else {
          this.middlewareInputWithUser(nextEmail, this.nextElementEmail);
        }
      } else {
        e.preventDefault();
        return false;
      }
    }
    // fix last email
    if (e.code === 'Backspace') {
      const containerEmails = document.getElementById('editable-email') as any;
      if (containerEmails.childNodes.length < 2 && containerEmails.children.length === 1) {
        containerEmails.children[containerEmails.children.length - 1].remove();
      }
    }
    setTimeout(() => {
      this.getAllEmails();
    }, 100);
  }
  outEmail() {
    setTimeout(() => {
      let nextEmail;
      const containerEmails = document.getElementById('editable-email') as any;
      containerEmails?.childNodes.forEach(element => {
        if (element.nodeName === '#text') {
          nextEmail = element.data;
          nextEmail = nextEmail.replace('&nbsp;', '');
          if (nextEmail.trim().length > 3) {
            this.sel = window.getSelection();
            this.range = this.sel.getRangeAt(0);
            if (!this.contacts.length) {
              this.middlewareInputWithUser(nextEmail, element, true);
            }
          }
        }
      });
    });
  }
  pasteHtmlAtCaret(html) {
    if (window.getSelection) {
      // IE9 and non-IE
      if (this.sel.getRangeAt && this.sel.rangeCount) {
        this.range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        const el = document.createElement('div');
        el.innerHTML = html;
        let frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        this.range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          this.range = this.range.cloneRange();
          this.range.setStartAfter(lastNode);
          this.range.collapse(true);
          this.sel.removeAllRanges();
          this.sel.addRange(this.range);
        }
      }
    } else if (document['selection'] && document['selection'].type != 'Control') {
      // IE < 9
      document['selection'].createRange().pasteHTML(html);
    }
  }

  getAllEmails() {
    const inputEmails = document.getElementById('editable-email');
    const childInputEmail = (inputEmails.children as any) as HTMLElement[];
    const emails = [];
    for (let i = 0; i < childInputEmail.length; i++) {
      emails.push(childInputEmail[i].innerText.trim());
    }
    this.emails = emails;
  }
  findEmailUsers(email) {
    setTimeout(() => {
      this.visibleListEmails = true;
      const sel = window.getSelection() as any;
      if (sel.focusNode.previousElementSibling) {
        const clientRect = sel.focusNode.previousElementSibling.getBoundingClientRect();
        this.topPositionListContact = clientRect.top + 31 + 'px';
        this.leftPositionListContact = clientRect.left + sel.focusNode.previousElementSibling.clientWidth + 'px';
      } else {
        const clientRect = document.getElementById('editable-email').getBoundingClientRect();
        this.topPositionListContact = clientRect.top + 35 + 'px';
        this.leftPositionListContact = clientRect.left + 'px';
      }
    }, 100);
    if (!this.loadContactEmails) {
      this.loadContactEmails = true;
      this.documentService.getContactEmails(email).subscribe(x => {
        this.contacts = x;
        this.loadContactEmails = false;
      });
    }
  }
  selectContact(contact) {
    this.selectedContact = true;
    const containerEmails = document.getElementById('editable-email') as HTMLElement;
    let checkReplace = false;
    containerEmails.childNodes.forEach(element => {

      if (
        element.nodeName === '#text' &&
        !checkReplace &&
        element.textContent.trim().length &&
        (contact.email.toLowerCase().trim().includes(element.textContent.toLowerCase().trim())
          || contact.name.toLowerCase().trim().includes(element.textContent.toLowerCase().trim()))
      ) {
        const div = document.createElement('div');
        div.innerHTML = this.getHtmlWithEmail(contact.email);
        containerEmails.replaceChild(div.firstElementChild, element);
        containerEmails.innerHTML += '&nbsp;';
        this.addEventsForDeleteEmails();
        checkReplace = true;
      }
    });

    this.visibleListEmails = false;
    this.contacts = [];
    setTimeout(() => {
      this.selectedContact = false;
    }, 500);
  }

  loadConnectContacts(name = '') {
    return this.contactService.get(environment.defaultPage, environment.countItemInSelect, {
      'contactPerson.user.type': 'user_portal',
      name
    });
  }

  public onFilteringConnectContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.contactComboBox?.showSpinner();
    this.debounceService.loadDataDebounce(
      ShareEmailModalDebounceEnum.connectContacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, {
        'contactPerson.user.type': 'user_portal',
        name: e.text
      }]);
    this.debounceService.getValue(ShareEmailModalDebounceEnum.connectContacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.contactComboBox?.hideSpinner();
    });
  }

  inputComment(e) {
    this.currentMsg = e.target.innerText;
  }

  removeDoc(doc) {
    this.attachedFiles.documents = this.attachedFiles.documents.filter(item => item.id !== doc.id);
    if (!this.attachedFiles?.documents?.length && !this.attachedFiles?.bills?.length && !this.selectedNewDocuments.length) {
      this.heightForList = '565px';
    }
  }
  removeNewDoc(newDoc) {
    this.selectedNewDocuments = this.selectedNewDocuments.filter(item => item.document.uid !== newDoc.document.uid);
    if (!this.attachedFiles?.documents?.length && !this.attachedFiles?.bills?.length && !this.selectedNewDocuments.length) {
      this.heightForList = '565px';
    }
  }

  removeBill(bill) {
    this.attachedFiles.bills = this.attachedFiles.bills.filter(item => item.id !== bill.id);
    if (!this.attachedFiles?.documents?.length && !this.attachedFiles?.bills?.length) {
      this.heightForList = '565px';
    }
  }

  attachFilesToComment() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.panelClass = ['attach-files-to-comment-modal'];

    dialogConfig.data = {
      selectedDocuments: this.attachedFiles ? this.attachedFiles.documents : [],
      selectedBills: this.attachedFiles ? this.attachedFiles.bills : [],
      selectedNewDocuments: this.selectedNewDocuments,
      contact: this.dialogData.contact,
      bill: this.dialogData.bill,
    };
    const dialogRef = this.dialog.open(AttachFilesToCommentModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.attachedFiles = { documents: res.documents, bills: res.bills };
        this.selectedNewDocuments = res.selectedNewDocuments;
        if (this.attachedFiles?.documents.length > 0 || this.attachedFiles?.bills.length > 0 || this.selectedNewDocuments?.lenght) {
          this.heightForList = '616px';
        }
      }
    });
  }
  submitConnect() {
    const contentTxt = document.getElementById('content-text').cloneNode(true);
    this.checkSubmit = true;
    if (this.currentMsg.trim()) {
      const div = contentTxt.firstChild as any;
      const newP = document.createElement('p');
      newP.innerHTML = div && (div?.innerHTML ? div.innerHTML : div.nodeValue);
      div.parentNode.replaceChild(newP, div);
      this.dataContent = [];
      this.chatService.htmlToJson(contentTxt.childNodes as any, this.dataContent);
      this.saveCommunication(true);
    }
  }

  saveCommunication(published) {
    this.contactService
      .getById(this.contact)
      .pipe(
        mergeMap(contact => {
          return this.communicationService
            .postCommunication({
              name: this.dialogData.bill
                ? this.translate.instant('receivedNewInvoicePortal') + this.dialogData.bill.number
                : this.translate.instant('receivedNewDocumentPortal') + this.dialogData.document.name,
              published,
              userPortal: contact.contactPerson.user.id
            })
            .pipe(
              mergeMap(communication => {
                this.communicationPublicService.setCommunication(communication);
                return this.saveComments(communication, published);
              })
            );
        })
      )
      .subscribe(res => {
        this.helpersServices.visibleTroast(this.dialogData.bill ? 'invoiceSentToClient' : 'documentSentToClient');
        this.dialogRef.close(res);
      });
  }

  saveComments(communication, published) {
    const idsDocuments = this.attachedFiles?.documents.map(item => item.id) || [];
    if (this.dialogData?.document) {
      idsDocuments.push(this.dialogData?.document?.id);
    }
    const idsbills = this.attachedFiles?.bills.map(item => item.id) || [];
    if (this.dialogData?.bill) {
      idsbills.push(this.dialogData?.bill?.id);
    }
    return this.discussionService
      .setComment({
        content: this.dataContent,
        entityId: communication.id,
        entityName: 'communication',
        documents: idsDocuments.filter(this.onlyUnique),
        bills: idsbills.filter(this.onlyUnique),
        published
      })
      .pipe(
        map(resp => {
          const contentTxt = document.getElementById('content-text');
          contentTxt.innerHTML = '';
          this.attachedFiles = { documents: [], bills: [] };
          if (this.selectedNewDocuments) {
            this.saveDocuments(resp);
          }
          return resp;
        })
      );
  }
  saveDocuments(comment: IComment, oldDocuments = []) {
    this.store.dispatch(new AddDocuments(this.selectedNewDocuments));
    this.selectedNewDocuments = [];
    const newDocuments = [];
    this.documentEffects.addDocuments$.subscribe((res: any) => {
      for (const addDocSuccess of res.payload) {
        newDocuments.push(addDocSuccess.payload);
      }
      this.editComment(comment, comment.published, oldDocuments.concat(newDocuments)).subscribe();
    });
  }
  editComment(comment, published, documents = []) {
    return this.discussionService.updateComment({
      documents: documents.concat(this.attachedFiles?.documents.map(item => item.id)),
      bills: this.attachedFiles?.bills.map(item => item.id),
      id: comment.id
    });
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  checkPaste(e) {
    this.sel = window.getSelection();
    this.range = this.sel.getRangeAt(0);
    this.range.deleteContents();
    this.pasteHtmlAtCaret(e.clipboardData.getData('text'));
    return false;
  }
}
