import { ContactState } from '@app/models/interfaces/contact-state';
import { environment } from '@environments/environment';

export const initialContactState: ContactState = {
  contacts: {},
  currentContact: null,
  persons: null,
  selectedDirector: null,
  selectedPerson: null,
  selected: null,
  paginator: {
    member: null,
    countItems: null,
    totalItems: environment.defaultItemsCount,
    currentPage: 1,
    itemsPerPage: environment.defaultItemsCount,
    lastPage: null
  },
  contactListForSelect: null,
  allContactListForSelect: null,
  allOnlyClientsForSelect: null,
  contactListForSelectDirector: null,
  activePersonsAndContact: null
};
