import { CompanySettings } from '@app/models/company-settings';

export class CorrespondenceSettings {
  static templateChar = '{{ num }}';

  static incomingNumber(settings: CompanySettings): string {
    return (
      settings && settings.correspondenceInNumPattern.replace(CorrespondenceSettings.templateChar, settings.correspondenceInNum.toString())
    );
  }

  static outgoingNumber(settings: CompanySettings): string {
    return (
      settings &&
      settings.correspondenceOutNumPattern.replace(CorrespondenceSettings.templateChar, settings.correspondenceOutNum.toString())
    );
  }
}
