import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  reviews = [];
  reviewCount = 0;

  constructor(private authService: AuthService) {
    this.reviews = this.authService.reviews;
  }

  ngOnInit() {}

  onListForward() {
    if (this.reviews.length - 1 > this.reviewCount) {
      this.reviewCount++;
    } else {
      this.reviewCount = 0;
    }
  }

  onListBackward() {
    if (this.reviewCount - 1 >= 0) {
      this.reviewCount--;
    } else {
      this.reviewCount = this.reviews.length - 1;
    }
  }
}
