import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contacts/services/contact.service';

@Component({
  selector: 'app-item-stages',
  templateUrl: './item-stages.component.html',
  styleUrl: './item-stages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemStagesComponent {
  @Input() kanbanData;
  @Input() item;
  @Output() menuDisplay = new EventEmitter();

  spinnerName = 'stageList';
  showLoader = false;

  constructor(
    private router: Router,
    private contactService: ContactService,
  ) {}

  activeMenu(event) {
    this.menuDisplay.emit({
      event,
      item: this.kanbanData,
    });
  }

  goToContact(contact) {
    if (contact) {
      this.openSpinner();
      this.contactService.getById(contact.id).subscribe(res => {
        this.hideSpinner();
        if (res.id) {
          this.router.navigateByUrl(`/contact/${contact.id}`);
        }
      });
    }
  }

  goToWorker(user) {
    this.contactService.getUserById(user.id).subscribe(res => {
      this.hideSpinner();
      if (res.id) {
        this.router.navigateByUrl(`/worker/${user.id}`);
      }
    });
  }

  goToMatter(matter) {
    this.router.navigateByUrl(`/matter/${matter.id}`);
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  getCountDay(date) {
    let Difference_In_Time = new Date().getTime() - new Date(date || new Date()).getTime();
    let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    return Difference_In_Days || 1;
  }
}
