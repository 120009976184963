import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { SharedEnumService } from '@app/enums/shared-enum.service';
import { Contact, IUser } from '@app/models';
import { MatterExtended } from '@app/models/matter-extended';
import { TasksFilters } from '@app/models/tasks-filters';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { IAppState } from '@app/store/state/app.state';
import { AddTaskFilters } from '@app/store/actions/task.actions';
import { selectCurrentTasksState } from '@app/store/selectors/task.selectors';
import { selectContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { LoadContactsForList, UpdateContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { LoadUsersForList, UpdateUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { LoadMattersForList, UpdateMattersForList } from '@app/store/actions/matter.actions';
import { selectMatterListForSelect } from '@app/store/selectors/matter.selector';
import { TaskStatus } from '@app/enums/task-status.enum';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { DebounceService } from '../../../../../helpers/debounce.service';
import { TasksFilterDebounceEnum } from '../../../../../enums/debounce-keys.enum';
import { PriorityStatus } from '@app/enums/task-priority.enum';

@Component({
  selector: 'app-tasks-filter',
  templateUrl: './tasks-filter.component.html',
  styleUrls: ['./tasks-filter.component.scss'],
})
export class TasksFilterComponent implements OnInit, OnDestroy {
  constructor(
    private contactService: ContactService,
    private formBuilder: UntypedFormBuilder,
    private matterService: MatterService,
    private route: ActivatedRoute,
    private store: Store<IAppState>,
    private teamMemberService: TeamMemberService,
    private translate: TranslateService,
    private debounceService: DebounceService,
  ) {
    this.types = this.sharedData.enumTaskType;
  }

  @Input() filters: TasksFilters;
  @Input() ifContact?: Contact;
  @Input() ifMatter?: MatterExtended;
  @Input() responsibleId?: number;
  @Input() kanbanEnable?: boolean;
  @Input() ifUser?: IUser;
  @Output() filtersApplied = new EventEmitter<any>();

  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  readonly dateFormat = CustomDateAdapter.dateFormat;
  readonly matterId = 'matter.id';
  readonly matterContactId = 'matter.contact.id';
  readonly responsiblePersonsId = 'taskUsers.user.id';
  readonly dateFrom = 'startDate[after]';
  readonly dateTo = 'startDate[before]';
  readonly sorting = 'Ascending';
  readonly taskTypes = [
    {
      label: this.translate.instant('all'),
      id: TaskStatus.all,
    },
    {
      label: this.translate.instant('taskAndEvents.filter.upcoming'),
      id: TaskStatus.upcoming,
    },
    {
      label: this.translate.instant('taskAndEvents.filter.canceled'),
      id: TaskStatus.cancel,
    },
    {
      label: this.translate.instant('taskAndEvents.filter.completed'),
      id: TaskStatus.done,
    },
    {
      label: this.translate.instant('taskAndEvents.filter.overdue'),
      id: TaskStatus.overdue,
    },
  ];

  readonly priorityTypes = [
    {
      label: this.translate.instant('all'),
      id: PriorityStatus.all,
    },
    {
      label: this.translate.instant('taskAndEvents.priority.high'),
      id: PriorityStatus.high,
      iconCss: `j2-icon-${PriorityStatus.high}-priority`,
    },
    {
      label: this.translate.instant('taskAndEvents.priority.medium'),
      id: PriorityStatus.medium,
      iconCss: `j2-icon-${PriorityStatus.medium}-priority`,
    },
    {
      label: this.translate.instant('taskAndEvents.priority.low'),
      id: PriorityStatus.low,
      iconCss: `j2-icon-${PriorityStatus.low}-priority`,
    },
  ];

  contacts: Contact[];
  dateStartAfter: string;
  dateEndBefore: string;
  filterForm: UntypedFormGroup;
  matters: MatterExtended[];
  sharedData = new SharedEnumService(this.translate);
  types;
  users: IUser[];

  @ViewChild('contactsComboBox') private comboBoxContact: ComboBoxComponent;
  @ViewChild('mattersComboBox') private comboBoxMatter: ComboBoxComponent;
  @ViewChild('usersComboBox') private comboBoxUser: ComboBoxComponent;
  private filtersContacts = {
    'order[fullName]': 'ASC',
    name: '',
  };
  private filterMatters = {
    name: '',
    status: 'open',
    'contact.id': null,
  };
  private filtersUser: TeamMemberFilter = {
    name: '',
    status: 'active',
    'companyAccesses.enable': true,
  };
  private mattersSelectUnsubscribe$: Subject<void>;
  private unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.createForm();
    this.loadMatters();
    this.loadContacts();
    this.loadUsers();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.mattersSelectUnsubscribe$?.next();
    this.mattersSelectUnsubscribe$?.complete();
    this.debounceService.removeDebounceData();
  }

  applyFilters() {
    const formValue = { ...this.filterForm.value };

    if (formValue[this.matterId] && !this.matters.some(m => m.id === formValue[this.matterId])) {
      this.store.dispatch(new UpdateMattersForList(formValue[this.matterId]));
    }
    if (formValue[this.responsiblePersonsId] && !this.users.some(u => u?.id === formValue[this.responsiblePersonsId])) {
      this.store.dispatch(new UpdateUsersForList(formValue[this.responsiblePersonsId]));
    }
    if (formValue[this.matterContactId] && !this.contacts.some(c => c.id === formValue[this.matterContactId])) {
      this.store.dispatch(new UpdateContactsForList(formValue[this.matterContactId]));
    }
    if (this.ifContact && !this.ifMatter) {
      formValue[this.matterContactId] = -this.ifContact.id;
    }
    if (formValue[this.dateFrom]) {
      formValue[this.dateFrom] = CustomDateAdapter.convertToUniversalDateString(formValue[this.dateFrom]);
    }
    if (formValue[this.dateTo]) {
      formValue[this.dateTo] = CustomDateAdapter.getEndOfTheCurrentDayUniversalString(formValue[this.dateTo]);
    }

    if (this.ifMatter) {
      formValue[this.matterId] = -this.ifMatter.id;
    }

    this.filtersApplied.emit(formValue);
  }

  discardFilters() {
    this.filters = {};
    this.clearFilters();
    this.ifFromCard();
    this.filters.status = 'all';
    this.filters.priority = '';

    this.filterForm.get('status').setValue(TaskStatus.all);
    if (this.filters && this.filters['startDate[after]']) {
      this.filters['startDate[after]'] = CustomDateAdapter.convertToUniversalDateString(new Date(this.filters['startDate[after]']));
    }
    if (this.filters && this.filters['startDate[before]']) {
      this.filters['startDate[before]'] = CustomDateAdapter.convertToUniversalDateString(new Date(this.filters['startDate[before]']));
    }
    if (this.filters && (this.filters.status === 'all' || this.filters.status === 'done')) {
      this.filters['order[endDate]'] = 'desc';
      this.filters.status = null;
    } else {
      this.filters['order[endDate]'] = null;
    }
    this.filters.clearFilters = true;
    this.store.dispatch(new AddTaskFilters(this.filters));
    this.filtersApplied.emit(this.filters);
  }

  onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.comboBoxContact?.showSpinner();
    this.filtersContacts.name = e.text;
    this.debounceService.loadDataDebounce(
      TasksFilterDebounceEnum.contacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(TasksFilterDebounceEnum.contacts).pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        e.updateData(resp?.member as any);
        this.contacts = resp?.member;
        this.comboBoxContact?.hideSpinner();
      });
  };

  onContactChanged(contact) {
    const contactId = contact.itemData?.id;
    if (this.matters) {
      this.mattersSelectUnsubscribe$.next();
      this.mattersSelectUnsubscribe$.complete();
    }
    this.filterMatters['contact.id'] = contactId;
    if (!this.ifContact && this.filterMatters['contact.id']) {
      setTimeout(() => {
        this.comboBoxMatter?.showSpinner();
      });
    }
    if (contactId) {
      this.mattersSelectUnsubscribe$ = new Subject<void>();
      this.matterService
        .get(environment.defaultPage, environment.countItemInSelect, this.filterMatters)
        .pipe(takeUntil(this.mattersSelectUnsubscribe$))
        .subscribe(x => {
          this.matters = x.member;
          if (this.matters.some(m => m.contact.id === this.filters[this.matterContactId])) {
            setTimeout(() => this.filterForm.get([this.matterId]).setValue(this.filters[this.matterId]));
          }
          if (!x.member.some((m: MatterExtended) => m.id === this.filters[this.matterId])) {
            this.filterForm.get([this.matterId]).reset();
          }
          setTimeout(() => this.comboBoxMatter?.hideSpinner());
        });
    } else {
      this.filterForm.get([this.matterId]).reset();
      this.loadMatters();
    }
  }

  onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.comboBoxMatter?.showSpinner();
    this.filterMatters.name = e.text;
    this.debounceService.loadDataDebounce(
      TasksFilterDebounceEnum.matters,
      this.matterService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filterMatters]);
    this.debounceService.getValue(TasksFilterDebounceEnum.matters).pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.matters = resp?.member;
        e.updateData(resp?.member as any);
        this.comboBoxMatter?.hideSpinner();
      });
  };

  onFilteringUsers: EmitType<any> = (e: FilteringEventArgs) => {
    this.comboBoxUser?.showSpinner();
    this.filtersUser.name = e.text;
    this.debounceService.loadDataDebounce(
      TasksFilterDebounceEnum.users,
      this.teamMemberService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersUser]);
    this.debounceService.getValue(TasksFilterDebounceEnum.users).pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        e.updateData(resp?.member as any);
        this.users = resp?.member;
        this.comboBoxUser?.hideSpinner();
      });
  };

  private createForm() {
    this.filterForm = this.formBuilder.group({
      [this.matterId]: new UntypedFormControl({ value: null, disabled: false }),
      [this.matterContactId]: new UntypedFormControl({ value: null, disabled: false }),
      [this.responsiblePersonsId]: new UntypedFormControl({ value: null, disabled: false }),
      [this.dateFrom]: new UntypedFormControl(null),
      [this.dateTo]: new UntypedFormControl(null),
      status: new UntypedFormControl(null),
      priority: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
    });

    this.store
      .select(selectCurrentTasksState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.filterForm.patchValue({
          [this.dateFrom]: data.filters['startDate[after]'] ? new Date(data.filters['startDate[after]']) : null,
          [this.dateTo]: data.filters['startDate[before]'] ? new Date(data.filters['startDate[before]']) : null,
          [this.responsiblePersonsId]: data.filters['responsiblePersons.id'],
          // [this.responsiblePersonsId]: this.ifUser ? this.ifUser.id : data['responsiblePersons.id'],
          type: data.filters.type,
        });
        this.loadParams();
      });
  }

  private loadParams() {
    this.route.firstChild.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      setTimeout(() => this.filterForm.get('status').setValue(params.status || TaskStatus.upcoming));
    });

    this.route.firstChild.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(queryParams => {
      const settings = JSON.parse(sessionStorage.getItem(queryParams.key));
      if (!settings?.filters) {
        return;
      }
      this.filters = settings.filters;
      if (settings.filters[this.matterId]) {
        this.filterForm.get([this.matterId]).setValue(this.filters[this.matterId]);
      }
      if (settings.filters[this.matterContactId]) {
        this.filterForm.get([this.matterContactId]).setValue(this.filters[this.matterContactId]);
      }
      if (settings.filters.type) {
        this.filterForm.get('type').setValue(this.filters.type);
      }
      if (settings.filters[this.responsiblePersonsId]) {
        this.filterForm.get([this.responsiblePersonsId]).setValue(this.filters[this.responsiblePersonsId]);
      }
      if (settings.filters[this.dateFrom]) {
        this.filterForm.get([this.dateFrom]).setValue(new Date(this.filters[this.dateFrom]));
      }
      if (settings.filters[this.dateTo]) {
        this.filterForm.get([this.dateTo]).setValue(new Date(this.filters[this.dateTo]));
      }
      if (settings.filters['priority']) {
        this.filterForm.get('priority').setValue(this.filters['priority']);
      }

    });
  }

  private ifFromCard() {
    if (this.ifContact) {
      if (!this.contacts?.find(matter => matter.id === this.ifContact.id)) {
        this.contacts?.push(this.ifContact);
      }
      this.filterForm.patchValue({
        [this.matterContactId]: this.ifContact.id,
      });
      this.filterForm.get([this.matterContactId]).disable();
    }
    if (this.ifMatter) {
      if (!this.matters?.find(matter => matter.id === this.ifMatter.id)) {
        this.matters?.push(this.ifMatter);
      }
      this.filterForm.patchValue({
        [this.matterId]: this.ifMatter.id,
      });
      this.filterForm.get([this.matterId]).disable();
    }
    if (this.ifUser) {
      if (!this.users?.find(user => user.id === this.ifUser.id)) {
        this.users?.push(this.ifUser);
      }
      setTimeout(() => {
        const r = this.users;
        this.filterForm.get([this.responsiblePersonsId]).setValue(this.ifUser.id);
        this.filterForm.get([this.responsiblePersonsId]).disable();
      });
    }
  }

  private loadMatters() {
    this.mattersSelectUnsubscribe$ = new Subject<void>();
    this.store
      .select(selectMatterListForSelect)
      .pipe(takeUntil(this.mattersSelectUnsubscribe$))
      .subscribe(matters => {
        if (!matters) {
          this.store.dispatch(
            new LoadMattersForList({
              page: environment.defaultPage,
              itemsPerPage: environment.countItemInSelect,
              filters: { 'contact.id': this.ifContact?.id },
            }),
          );
          return;
        }
        this.matters = matters;
        this.ifFromCard();
        setTimeout(() => this.comboBoxMatter?.hideSpinner());
      });
  }

  private loadUsers() {
    this.store
      .select(selectListUsersForSelect)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (!resp) {
          this.store.dispatch(
            new LoadUsersForList({
              page: environment.defaultPage,
              itemsPerPage: environment.countItemInSelect,
            }),
          );
          return;
        }
        this.users = resp;
        this.ifFromCard();
      });
  }

  private loadContacts() {
    this.store
      .select(selectContactListForSelect)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (!data) {
          this.store.dispatch(
            new LoadContactsForList({
              page: environment.defaultPage,
              itemsPerPage: environment.countItemInSelect,
            }),
          );
          return;
        }

        this.contacts = data;
        this.ifFromCard();
      });
  }

  private clearFilters() {
    this.filterForm.reset();
  }
}
