import { Component, OnInit } from '@angular/core';
import { SubscriptionsService } from '@app/modules/settings/services/subscriptions.service';
import { Router } from '@angular/router';
import { checkRole } from '@app/helpers/check-role';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SUBSCRIPTION_ROLES } from '@app/helpers/role-arrays';
import { AuthService } from '@app/auth/_services/auth.service';

@Component({
  selector: 'app-subscription-left',
  templateUrl: './subscription-left.component.html',
  styleUrls: ['./subscription-left.component.scss']
})
export class SubscriptionLeftComponent implements OnInit {
  constructor(
    private subscriptionsService: SubscriptionsService,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}
  daysLeft = {
    days: 0
  };
  shouldNotify = false;
  user;
  // коли підписка закінчилась true  - коли менше одного дня
  expired = false;

  ngOnInit() {
    this.getUserData();
  }
  getUserData() {
    this.authService.refreshUser();
    this.user = this.authService.currentUserValue;
    if (new Date(this.user.expiredAt) <= new Date()) {
      this.expired = true;
    }
    const lessThanWeekLeft = this.subscriptionsService.daysLeft() <= 7;
    const hasAutoPay = this.user.hasAutoPaySubscription;

    if (!hasAutoPay && lessThanWeekLeft) {
      this.daysLeft.days = this.subscriptionsService.daysLeft() + 1;
      this.shouldNotify = true;
    }
  }

  buySubscription() {
    if (checkRole(SUBSCRIPTION_ROLES)) {
      return this.router.navigateByUrl('/settings/subscription');
    } else {
      this.showAccessModal();
    }
  }

  showAccessModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      noButtonTxt: 'subscriptionLeft.accessDenied.button',
      title: 'subscriptionLeft.accessDenied.title',
      mainTxt: 'subscriptionLeft.accessDenied.body',
      disableYesButton: true,
      disableNoButton: false
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
    });
  }
}
