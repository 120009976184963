export interface TaskSave {
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  location: string;
  court: boolean;
  type: string;
  status: string; //'new' | 'in_work' | 'cancel' | 'overdue' | 'done';
  estimate?: number;
  matter: number;
  correspondence?: number;
  taskUsers: {
    id?: number;
    user: number;
  }[];
  id: number;
  courtSession?: number;
  priority?: string;
}
