import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-disabled-modal',
  templateUrl: './user-disabled-modal.component.html',
  styleUrls: ['./user-disabled-modal.component.scss']
})
export class UserDisabledModalComponent implements OnInit {
 
  constructor() {}

  ngOnInit() {}
}
