import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { DebounceService } from '@app/helpers/debounce.service';
import { fadeInOutAnimation } from '@app/helpers/fadeAnimation';
import { Contact, IUser } from '@app/models';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { Tag, TagMain } from '@app/models/tag';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { ContactEffects } from '@app/modules/contacts/store/effects/contact.effects';
import { CommunicationService } from '@app/modules/reusable/jusnote-connect/services/communication.service';
import { PersonTypeService } from '@app/modules/settings/services/person-type.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent, SortOrder } from '@syncfusion/ej2-angular-dropdowns';
import { PerfectScrollbarComponent } from '@app/perfect-scrollbar/perfect-scrollbar.component';
import { BehaviorSubject, combineLatest, combineLatestWith, merge, Observable, of, startWith, Subscription, zip } from 'rxjs';
import { CreateMatter, GetMatter, TrackMatter } from '@app/store/actions/matter.actions';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {
  CreateContact,
  EditContact,
  GetContactById,
  LoadContactsForListDirector,
  SelectContact,
} from '@app/modules/contacts/store/actions/contact.actions';
import { AddDocuments, DeleteDocuments } from '@app/store/actions/document.actions';
import {
  GetCompanyPortalSettings,
  PostCompanyUserPortalAccess,
  PutCompanyUserPortalAccesses,
} from '@app/store/actions/company-portal-settings.action';
import { putCompanyUserPortalAccessStatus, selectCompanyPortalSettings } from '@app/store/selectors/company-portal-settings.selector';
import { catchError, debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PROGRESS_STATUSES } from '@app/shared/symbols';
import { LoadCountries, LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectCountries, selectListUsersForSelect, updateUserStatus } from '@app/modules/settings/store/selectors/team-member.selector';
import { LoadPersonTypesAction } from '@app/modules/settings/store/actions/person-types.actions';
import { selectPersonTypes } from '@app/modules/settings/store/selectors/person-types.selector';
import { environment } from '@environments/environment';
import { selectAnyoneWorkGroups, selectMyAndAnyoneWorkGroups } from '@app/modules/settings/store/selectors/team.selector';
import { GetAnyoneWorkGroups, GetMyAndAnyoneWorkGroups } from '@app/modules/settings/store/actions/team.actions';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { ContactModalDebounceEnum } from '@app/enums/debounce-keys.enum';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { DocumentSave } from '@app/models/document-save';
import {
  DocumentUploadModalComponent,
} from '@app/commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import { ContactDocument, TypeOfPerson } from '@app/modules/contacts/symbols/contacts.symbols';
import {
  CONTACT_FORM_CONFIGS,
  ContactAllBlockControls,
  ContactControlRelation,
  ContactFormBlock,
  ContactFormControls,
  TransformToTypedForm,
} from '@app/modules/contacts/symbols/contacts-form-config';
import { isArray, isEqual } from 'lodash';
import { selectContactFromStore, selectContactListForSelectDirector } from '@app/modules/contacts/store/selectors/contact.selector';
import { ICountryByIp } from '@app/helpers/locale.service';
import { CompanyPortalSettingsService } from '@app/modules/settings/services/company-portal-settings.service';

export interface ContactsModalData {
  fromContactCard?: boolean;
  id?: number;
  ifMatter?: boolean;
  fromDocument?: boolean;
  isNestedPerson?: boolean;
  type?: string;
}

@Component({
  selector: 'app-contacts-modal',
  templateUrl: './contacts-modal.component.html',
  styleUrls: ['./contacts-modal.component.scss'],
  animations: [fadeInOutAnimation],
})
export class ContactsModalComponent implements OnInit, OnDestroy {
  @ViewChild('contactPerfectScroll', { static: false }) perfectScroll: PerfectScrollbarComponent;
  @ViewChild('comboBoxContact', { static: false }) public comboBoxContact: ComboBoxComponent;

  protected readonly PROGRESS_STATUSES = PROGRESS_STATUSES;
  protected readonly TypeOfPerson = TypeOfPerson;

  public creatingSpinner = false;
  public creatingContactAndMatterSpinner = false;
  public contactsForm: FormGroup<ContactFormControls>;
  public readonly contactFormBlock = ContactFormBlock;
  public readonly currentContact$ = new BehaviorSubject<Contact>(null);
  public readonly personsTypes$ = this.store.select(selectPersonTypes);

  public checkInnStatus$: BehaviorSubject<PROGRESS_STATUSES> = new BehaviorSubject<PROGRESS_STATUSES>(PROGRESS_STATUSES.NOT_INITIALIZED);
  public checkNameStatus$: BehaviorSubject<PROGRESS_STATUSES> = new BehaviorSubject<PROGRESS_STATUSES>(PROGRESS_STATUSES.NOT_INITIALIZED);
  public checkSomethingStatus$: Observable<PROGRESS_STATUSES>;

  public access$: Observable<{ name: string, id: number }[]>;
  public responsibleUsers$: Observable<{ id: number, fullName: string }[]>;
  public entityTagList$: BehaviorSubject<TagMain[]> = new BehaviorSubject<Tag[]>(null);
  public mode = TypeOfPerson.INDIVIDUAL;
  public expandedAll = false;

  public spinnerName = 'contact-modal-spinner';
  public contactsIndex: number[] = [];
  public readonly messenger: { id: number, name: string }[] = [
    { id: 1, name: 'Telegram' },
    { id: 2, name: 'Skype' },
    { id: 3, name: 'WhatsApp' },
    { id: 4, name: 'Viber' },
  ];
  public filterUser: TeamMemberFilter = { status: 'active', 'companyAccesses.enable': true, name: '' };
  public sorting: SortOrder = 'Ascending';
  public filtersContacts = {
    type: 'person',
    which_active_user: true,
    name: '',
  };

  public portalIsConnected$: Observable<boolean>;
  public ifFindConnectInfo$: Observable<boolean>;
  public checkEmailForPortalStatus$:
    BehaviorSubject<PROGRESS_STATUSES> = new BehaviorSubject<PROGRESS_STATUSES>(PROGRESS_STATUSES.NOT_INITIALIZED);

  public readonly countriesList$ = this.store.select(selectCountries);
  public countriesListForSelector$: Observable<{ name: string, code: string }[]>;
  public locale$ = this.appSettingsService.getLang;
  public readonly selectCompanySettings$ = this.store.select(selectCompanyPortalSettings);
  public readonly selectContact$ = this.store.select(selectContactFromStore);
  public readonly persons$ = this.store.select(selectContactListForSelectDirector);

  public personsForSelect$: BehaviorSubject<{ fullName: string; id: number; }[]> = new BehaviorSubject([]);

  public readonly searchPersonsValue$ = new BehaviorSubject('');
  public readonly searchUsersValue$ = new BehaviorSubject('');


  private readonly countryCode =
    localStorage.getItem('userData') && (JSON.parse(localStorage.getItem('userData')) as ICountryByIp)?.countryCode;

  private readonly putCompanyUserPortalAccessStatus$ = this.store.select(putCompanyUserPortalAccessStatus);


  public readonly locationType: { name: string, value: string }[] = [
    { name: this.translate.instant('registration'), value: 'registration' },
    { name: this.translate.instant('actual'), value: 'actual' },
    { name: this.translate.instant('another'), value: 'another' },
  ];
  private documentToRemove = [];
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  existedIconUrl: string;
  dateFormat = CustomDateAdapter.dateFormat;
  today = new Date();
  invalidBankAccounts = false;


  private readonly updateUserStatus$ = this.store.select(updateUserStatus);
  private readonly usersForSelect$ = this.store.select(selectListUsersForSelect);
  private readonly selectAnyoneWorkGroups$ = this.store.select(selectAnyoneWorkGroups);
  private readonly selectMyAndAnyoneWorkGroups$ = this.store.select(selectMyAndAnyoneWorkGroups);
  private currentContactId: number;
  private readonly subscriptions = new Subscription();
  private _contactBlocks: ContactFormBlock[];

  /**
   for specific task.
   before set values form send request for update or create portal
   */
  private contactData: Contact;


  /** For condition for skip check by INN of NAME */
  private previewInn: string;
  private previewName: string;

  showLoader = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ContactsModalData,
    public dialogRef: MatDialogRef<ContactsModalComponent>,
    private dialog: MatDialog,
    private userService: TeamMemberService,
    private contactService: ContactService,
    private personTypeService: PersonTypeService,
    private contactEffects: ContactEffects,
    private store: Store<IAppState>,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private communicationService: CommunicationService,
    private debounceService: DebounceService,
    private companyPortalSettingsService: CompanyPortalSettingsService,
  ) {
  }

  get contactBlocks(): ContactFormBlock[] {
    return this._contactBlocks;
  }

  ngOnInit(): void {
    this.subscriptions.add(this.persons$
      .pipe(
        filter(persons => !!persons),
        map(persons => persons.map(person => ({ fullName: person.fullName, id: person.contactPerson.id }))),
      ).subscribe(persons => this.personsForSelect$.next(persons)),
    );

    this.countriesListForSelector$ = this.countriesList$.pipe(
      filter(countries => !!countries),
      map(countries => countries.map(country => ({ name: country.name, code: country.code }))),
    );

    this.checkSomethingStatus$ = merge(
      this.checkNameStatus$,
      this.checkInnStatus$,
      this.checkEmailForPortalStatus$,
    );

    if (this.data) {
      this.mode = this.data.type === 'company' ? TypeOfPerson.LEGAL_ENTITY : TypeOfPerson.INDIVIDUAL;
    }

    /** create formGroup by mode */
    this.applyConfigToForm(this.mode);

    /** load required data */
    if (!this.data?.isNestedPerson) {

      if (this.data?.id) {
        this.openSpinner();
        this.store.dispatch(new SelectContact(this.data.id));
        this.store.dispatch(new GetContactById(this.data.id));
        this.store.dispatch(new GetAnyoneWorkGroups());
      } else {
        this.store.dispatch(new GetMyAndAnyoneWorkGroups());
        this.contactsForm.controls.emailForPortal.disable();
      }

      this.store.dispatch(new GetCompanyPortalSettings());
      this.store.dispatch(new LoadPersonTypesAction());
      this.store.dispatch(new LoadCountries());
    }

    /** Set default country for control from localstorage */
    this.subscriptions.add(
      this.countriesList$.pipe(filter(countries => !!countries && !!countries?.length)).subscribe(countries => {
        if (this.countryCode && !this.data?.id) {
          this.contactsForm.controls.addresses.controls.forEach(control => {
            control.controls.country.setValue(this.countryCode);
          });
        }
      }),
    );


    /** after create new contact (close modals, redirect, load other information) */
    this.subscriptions.add(
      this.contactEffects.create$.subscribe(createdContact => {
        this.currentContactId = createdContact.payload.id;
        this.hideSpinner();

        this.createDocuments(createdContact.payload.id);

        if (this.data && createdContact.isNestedPerson) {
          this.dialog.openDialogs[1].close(createdContact);
        }
        if (this.data && createdContact.isMatter) {
          this.dialogRef.close(createdContact.payload);
        }
        if (!createdContact.isNestedPerson && !createdContact.isMatter && !createdContact.createMatter) {
          this.router.navigate(['/contact', createdContact.payload.id, 'dashboard']);
          this.dialogRef.close(createdContact.payload);
        }
        if (createdContact.createMatter) {
          this.dialogRef.close(createdContact.payload);
          this.store.dispatch(new GetMatter(null));
          this.store.dispatch(new CreateMatter({ contact: createdContact.payload }));
          this.router.navigateByUrl(`/matters/create`);
          this.store.dispatch(new TrackMatter(createdContact.payload.id));
        }
      }),
    );

    /** after update contact load more information and other processes */
    this.subscriptions.add(
      this.contactEffects.edit$.subscribe(editedContact => {
        this.createUserPortal(editedContact.payload);
        if (editedContact.createMatter) {
          this.dialogRef.close(editedContact.payload);
          this.store.dispatch(new GetMatter(null));
          this.store.dispatch(new CreateMatter({ contact: editedContact.payload }));
          this.router.navigateByUrl(`/matters/create`);
          this.store.dispatch(new TrackMatter(editedContact.payload.id));
        }

        this.dialogRef.close();
      }),
    );


    /** user list for set access to contact */
    this.access$ = merge(
      this.selectMyAndAnyoneWorkGroups$,
      this.selectAnyoneWorkGroups$,
    ).pipe(filter(teams => !!teams), map(teams => teams.map(team => ({ name: team.name, id: team.id }))));


    /** patch form after get contact */
    this.subscriptions.add(
      zip(
        [
          this.selectContact$.pipe(filter(contact => !!contact && !!this.data?.id)),
          this.persons$.pipe(filter(persons => !!persons)),
          this.personsTypes$.pipe(filter(types => !!types)),
          this.countriesList$.pipe(filter(countries => !!countries && !!countries.length)),
          this.usersForSelect$.pipe(filter(users => !!users && !!users.length)),
          this.selectCompanySettings$.pipe(filter(cps => !!cps)),
          this.access$.pipe(filter(teams => !!teams.length)),
        ],
      )
        .pipe(debounceTime(500))
        .subscribe(([contact, persons]) => {
          this.expandedAll = true;
          /** define contact mode */
          if (!this.data?.isNestedPerson) {
            this.mode = contact.type === 'company' ? TypeOfPerson.LEGAL_ENTITY : TypeOfPerson.INDIVIDUAL;
          }

          /** this is specific logic for portal settings */
          this.contactData = contact;

          /** create form by mode after set values */
          if (!this.data?.isNestedPerson) {
            this.applyConfigToForm(this.mode);
          }


          if (!persons.map(person => person.id).includes(contact.contactCompany?.director?.id)) {
            this.personsForSelect$.next([...this.personsForSelect$.value, {
              id: contact.contactCompany?.director?.id,
              fullName: contact.contactCompany?.director?.fullName,
            }]);
          }

          /** stream for contact */
          this.currentContact$.next(contact);

          /** set values to form */
          this.contactsForm.patchValue(contact as unknown as any);


          this.contactsForm.controls.companyWorkGroup.setValue(contact.companyWorkGroup?.id, { emitEvent: false });

          this.contactsForm.patchValue({ emailForPortal: contact?.contactPerson?.user?.email }, { emitEvent: false });

          if (contact?.contactPerson?.user?.email) {
            this.contactsForm.controls.emailForPortal.setValue(contact?.contactPerson?.user?.email);
            this.contactsForm.controls.emailForPortal.disable();
          }

          if (this.mode === TypeOfPerson.INDIVIDUAL && !!contact?.contactPerson?.user?.companyUserPortalAccesses[0]?.enable) {
            this.contactsForm.controls.togglePortalOnOff
              .setValue(!!contact?.contactPerson?.user?.companyUserPortalAccesses[0]?.enable, { emitEvent: false });
          }


          this.contactsForm.controls.personType.patchValue(contact.personType?.id, { emitEvent: false });
          this.contactsForm.controls?.legalDocuments?.patchValue(contact.legalDocuments?.[0], { emitEvent: false });
          this.contactsForm.controls.registrationActions.patchValue(contact.registrationActions?.[0], { emitEvent: false });
          this.contactsForm.patchValue({ responsiblePerson: contact.responsiblePerson?.id }, { emitEvent: false });


          this.contactsForm.controls.contactCompany?.controls.director
            .setValue(contact?.contactCompany?.director?.id, { emitEvent: false });


          if (contact.addresses.length) {
            /** Before to create new group we need to remove all groups, because it was created by default */
            this.removeAllGroupFromControlArray(this.contactsForm.controls.addresses);

            if (!this.contactsForm.controls.addresses.length) {
              contact.addresses.forEach((addresses, index) => {
                this.contactsForm.controls.addresses.push(this.createAddressFormGroup);
                this.contactsForm.controls.addresses.controls[index].patchValue(addresses, { emitEvent: false });
              });
            }
          }

          if (contact.emails.length) {
            this.removeAllGroupFromControlArray(this.contactsForm.controls.emails);
            if (!this.contactsForm.controls.emails.length) {
              contact.emails.forEach((email, index) => {
                this.contactsForm.controls.emails.push(this.createEmailFormGroup);
                this.contactsForm.controls.emails.controls[index].patchValue(email, { emitEvent: false });
              });
            }
          }

          if (contact.phones.length) {
            this.removeAllGroupFromControlArray(this.contactsForm.controls.phones);
            if (!this.contactsForm.controls.phones.length) {
              contact.phones.forEach((phones, index) => {
                this.contactsForm.controls.phones.push(this.createPhoneFormGroup);
                this.contactsForm.controls.phones.controls[index].patchValue(phones, { emitEvent: false });
              });
            }
          }

          if (contact.websites.length) {
            this.removeAllGroupFromControlArray(this.contactsForm.controls.websites);
            if (!this.contactsForm.controls.websites.length) {
              contact.websites.forEach((websites, index) => {
                this.contactsForm.controls.websites.push(this.createWebsiteFormGroup);
                this.contactsForm.controls.websites.controls[index].patchValue(websites, { emitEvent: false });
              });
            }
          }

          if (contact.messengers.length) {
            this.removeAllGroupFromControlArray(this.contactsForm.controls.messengers);
            if (!this.contactsForm.controls.messengers.length) {
              contact.messengers.forEach((messengers, index) => {
                this.contactsForm.controls.messengers.push(this.createMessengerFormGroup);
                this.contactsForm.controls.messengers.controls[index].patchValue(messengers, { emitEvent: false });
              });
            }
          }

          if (contact.bankDetails.length) {
            this.removeAllGroupFromControlArray(this.contactsForm.controls.bankDetails);
            if (!this.contactsForm.controls.bankDetails.length) {
              contact.bankDetails.forEach((bankDetails, index) => {
                this.contactsForm.controls.bankDetails.push(this.createBankDetailFormGroup);
                this.contactsForm.controls.bankDetails.controls[index].patchValue(bankDetails, { emitEvent: false });
              });
            }
          }


          if (contact.tagContacts.length) {
            this.removeAllGroupFromControlArray(this.contactsForm.controls.tagContacts);
            if (!this.contactsForm.controls.tagContacts.length) {
              contact.tagContacts.forEach((tag, index) => {
                this.contactsForm.controls.tagContacts.push(this.createTagFormGroup);
                this.contactsForm.controls.tagContacts.controls[index].patchValue(tag, { emitEvent: false });
              });
            }
            this.entityTagList$.next(contact.tagContacts);
          }

          this.hideSpinner();
        }),
    );


    /** if contact of portal for contact was changed and saved we update data in store  */
    this.subscriptions.add(
      merge(this.updateUserStatus$, this.putCompanyUserPortalAccessStatus$)
        .pipe(filter(status => status === PROGRESS_STATUSES.SUCCEED))
        .subscribe({
          next: () => this.store.dispatch(new GetContactById(this.currentContactId)),
        }),
    );

    this.responsibleUsers$ = this.usersForSelect$
      .pipe(
        combineLatestWith(this.currentContact$),
        map(([users, contact]: [IUser[], Contact]) => {
          let responsibleUsers: IUser[] = [];

          if (isArray(users)) {
            responsibleUsers = [...users];
          }
          if (contact) {
            if (contact.responsiblePerson && !users.find(item => item.id === contact.responsiblePerson?.id)) {
              responsibleUsers.push(contact.responsiblePerson);
            }
          }
          return responsibleUsers.map(user => ({ fullName: user?.person?.fullName, id: user?.id }));
        }),
      );

    /** define connect to portal */
    this.portalIsConnected$ = this.selectCompanySettings$.pipe(map(cps => !!cps?.member[0]));

    this.subscriptions.add(
      this.searchPersonsValue$.pipe(debounceTime(500)).subscribe(value => {
        this.comboBoxContact?.showSpinner();
        this.store.dispatch(new LoadContactsForListDirector({
          page: environment.defaultPage,
          itemsPerPage: environment.countItemInSelect,
          filters: { ...this.filtersContacts, name: value },
        }));
      }),
    );

    this.subscriptions.add(
      this.searchUsersValue$.pipe(debounceTime(500)).subscribe(value => {
        this.comboBoxContact?.showSpinner();
        this.store.dispatch(
          new LoadUsersForList({
            page: environment.defaultPage,
            itemsPerPage: environment.countItemInSelect,
            filters: { ...this.filterUser, name: value },
          }));
      }),
    );

  }

  ngOnDestroy(): void {
    this.store.dispatch(new SelectContact(null));
    this.subscriptions.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  removeAllGroupFromControlArray(formArray: FormArray) {
    for (let i = 0; i < formArray.length; i++) {
      /** Before to create new group we need to remove all groups, because it was created by default */
      formArray.removeAt(i);
    }
  }

  createContact(currentContact: Contact, persons: Contact[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'full-height-modal', 'director'];
    dialogConfig.data = { isNestedPerson: true };

    this.dialog
      .open(ContactsModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1), filter(contact => !!contact?.payload))
      .subscribe(result => {
        if (!currentContact) {
          currentContact = { contactCompany: { director: result.payload.contactPerson } };
        } else {
          currentContact.contactCompany.director = result.payload.contactPerson;
        }

        if (!persons.find(contact => contact.id === result.payload.id)) {
          persons.unshift(result.payload.contactPerson);
        }

        const instance = document.querySelectorAll('#comboBoxContactPersons.e-control.e-combobox');
        (instance[0] as any).ej2_instances[0].dataSource = null;
        (instance[0] as any).ej2_instances[0].listData = null;
        (instance[0] as any).ej2_instances[0].dataSource = persons;
        (instance[0] as any).ej2_instances[0].listData = persons;

        if (!(instance[0] as any).ej2_instances[0].angularValue) {
          (instance[0] as any).ej2_instances[0].itemData = null;
        }
        this.contactsForm.controls.contactCompany.controls.director
          .patchValue(currentContact.contactCompany.director.id);
      });
  }


  private get createEmailFormGroup(): UntypedFormGroup {
    const validators = [RxwebValidators.unique(), RxwebValidators.email()];

    return this.formBuilder.group({
      email: [null, [RxwebValidators.compose({ validators })]],
      id: this.formBuilder.control(null),
    });

  }

  private get createPhoneFormGroup(): UntypedFormGroup {
    const validators = [Validators.minLength(8), Validators.maxLength(25)];

    return this.formBuilder.group({
      phone: this.formBuilder.control(null, validators),
      id: this.formBuilder.control(null),
    });
  }

  private get createWebsiteFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      address: this.formBuilder.control(null, [Validators.minLength(10), Validators.maxLength(100)]),
      id: this.formBuilder.control(null),
    });
  }

  private get createMessengerFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      type: this.formBuilder.control(null),
      username: this.formBuilder.control(''),
      id: this.formBuilder.control(null),
    });
  }

  private get createDocumentFormGroup(): FormGroup<TransformToTypedForm<ContactDocument>> {
    return new FormGroup<TransformToTypedForm<ContactDocument>>({
      document: new FormControl(),
      reminders: new FormControl(),
      mediaFile: new FormControl(),
      relations: new FormArray([]),
    });
  }

  private get createAddressFormGroup(): UntypedFormGroup {
    const isFirstAddress = !this.contactsForm?.controls?.addresses.length;
    return this.formBuilder.group({
      type: this.formBuilder.control(this.locationType[isFirstAddress ? 0 : 1].value),
      country: this.formBuilder.control(''),
      zipCode: this.formBuilder.control('', [Validators.maxLength(10)]),
      region: this.formBuilder.control('', [Validators.maxLength(50)]),
      regionDistrict: this.formBuilder.control('', [Validators.maxLength(50)]),
      city: this.formBuilder.control('', [Validators.maxLength(50)]),
      cityDistrict: this.formBuilder.control('', [Validators.maxLength(50)]),
      street: this.formBuilder.control('', [Validators.maxLength(150)]),
      id: this.formBuilder.control(null),
    });

  }

  private get createTagFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: this.formBuilder.control(null),
      tag: this.formBuilder.group({
        id: this.formBuilder.control(null),
        name: this.formBuilder.control(null),
      }),
    });
  }

  private get createBankDetailFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.maxLength(100)]),
      iban: this.formBuilder.control(''),
      id: this.formBuilder.control(null),
    });
  }

  private addField(type: string, required: boolean): void {
    switch (type) {
      case 'email': {
        this.contactsForm.controls.emails.push(this.createEmailFormGroup);
        break;
      }
      case 'phone': {
        this.contactsForm.controls.phones.push(this.createPhoneFormGroup);
        break;
      }
      case 'website': {
        this.contactsForm.controls.websites.push(this.createWebsiteFormGroup);
        break;
      }
      case 'messenger': {
        this.contactsForm.controls.messengers.push(this.createMessengerFormGroup);
        break;
      }
      case 'document': {
        this.contactsForm.controls.documents.push(this.createDocumentFormGroup);
        break;
      }
      case 'location': {
        this.contactsForm.controls.addresses.push(this.createAddressFormGroup);
        break;
      }
      case 'bankaccount': {
        this.contactsForm.controls.bankDetails.push(this.createBankDetailFormGroup);
        break;
      }
    }
  }

  public removeField(index: number, fieldName: string): void {
    this.getFieldType(fieldName).removeAt(index);
    this.contactsIndex[fieldName + 'index'] -= 1;
  }

  private getFieldType(type: string): FormArray {
    switch (type) {
      case 'email': {
        return this.contactsForm.controls.emails;
      }
      case 'phone': {
        return this.contactsForm.controls.phones;
      }
      case 'website': {
        return this.contactsForm.controls.websites;
      }
      case 'messenger': {
        return this.contactsForm.controls.messengers;
      }
      case 'document': {
        return this.contactsForm.controls.documents;
      }
      case 'location': {
        return this.contactsForm.controls.addresses;
      }
      case 'bankaccount': {
        return this.contactsForm.controls.bankDetails;
      }
    }
  }

  public onSubmit(createMatter?: boolean): void {
    this.markAllControlsAsDirty(this.contactsForm);
    this.contactsForm.markAllAsTouched();
    if (this.contactsForm.invalid) {
      return;
    }
    this.invalidBankAccounts = false;
    this.contactsForm.value.bankDetails.forEach((b: any, index: number) => {
      if ((b?.name !== '' || b?.iban !== '') && (b?.name === '' || b?.iban === '')) {
        this.invalidBankAccounts = true;
      }
    });
    if (this.invalidBankAccounts) {
      return;
    }

    if (createMatter) {
      this.creatingContactAndMatterSpinner = true;
    } else {
      this.creatingSpinner = true;
    }

    combineLatest([this.checkNameStatus$, this.checkInnStatus$, this.checkEmailForPortalStatus$])
      .pipe(
        takeUntil(this.checkSomethingStatus$.pipe(filter(status => status === PROGRESS_STATUSES.INTERRUPTED))),

        filter((statuses) =>
          statuses.every(status => status !== PROGRESS_STATUSES.IN_PROGRESS && status !== PROGRESS_STATUSES.INTERRUPTED),
        ),
        take(1),
      )
      .subscribe({
        next: () => {

          if (this.contactsForm.valid) {
            this.contactsForm.value.websites.forEach((w: any, index: number) => {
              if (w?.address === '') {
                (this.contactsForm.controls.websites).removeAt(index);
              }
            });
            this.contactsForm.value.phones.forEach((p: any, index: number) => {
              if (p?.phone === '') {
                (this.contactsForm.controls.phones).removeAt(index);
              }
            });
            this.contactsForm.value.emails.forEach((e: any, index: number) => {
              if (e?.email === '') {
                (this.contactsForm.controls.emails).removeAt(index);
              }
            });
            this.contactsForm.value.messengers.forEach((m: any, index: number) => {
              if (!m?.type || m?.username === '') {
                (this.contactsForm.controls.messengers).removeAt(index);
              }
            });
            this.contactsForm.value.bankDetails.forEach((b: any, index: number) => {
              if (b?.name === '' || b?.iban === '') {
                (this.contactsForm.controls.bankDetails).removeAt(index);
              }
            });
            this.contactsForm.value.documents.forEach((d: any, index: number) => {
              if (d === '') {
                (this.contactsForm.controls.documents).removeAt(index);
              }
            });
            this.createOrEditContact(createMatter);
          }
        },
      });
  }

  private markAllControlsAsDirty(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control) {
        control.markAsDirty();
        if (control.hasOwnProperty('controls')) {
          this.markAllControlsAsDirty(control);
        }
      }
    });
  }

  private createOrEditContact(createMatter: boolean) {
    const contactData = {
      ...this.contactsForm.value,
      id: this.data?.id,

      ...(this.contactsForm.controls.contactPerson?.value ?
        {
          contactPerson: {
            ...this.contactsForm.value.contactPerson,
            birthDate: this.contactsForm.value?.contactPerson?.birthDate ?
              CustomDateAdapter.convertToUniversalDateString(this.contactsForm.value.contactPerson.birthDate) :
              null,
          },
        } : {}),

      ...(this.contactsForm.controls.contactCompany?.value ?
        { contactCompany: this.contactsForm.controls.contactCompany.value } : {}),

      legalDocuments: [this.contactsForm.value.legalDocuments].filter(el => el?.number),

      registrationActions: [{
        id: this.contactsForm.value.registrationActions.id,
        date: this.contactsForm.value.registrationActions.date,
        number: this.contactsForm.value.registrationActions.number,
      }].filter(el => el?.number),

      personType: this.contactsForm.value.personType,
      tagContacts: this.contactsForm.value.tagContacts?.length ?
        this.contactsForm.value.tagContacts.map(tag => {
          if (!tag.id) {
            return { tag: `tags/${tag.tag.id}` } as unknown;
          } else {
            return { tag: `tags/${tag.tag.id}`, id: tag.id } as unknown;
          }
        }) : [],

      emails: this.contactsForm.value.emails?.filter(el => el.email),
      addresses: this.contactsForm.value.addresses?.filter(el => el.country),
      phones: this.contactsForm.value.phones?.filter(el => el.phone),
      websites: this.contactsForm.value.websites?.filter(el => el.address),
      messengers: this.contactsForm.value.messengers?.filter(el => el.type && el.username),
      bankDetails: this.contactsForm.value.bankDetails?.filter(el => el.name && el.iban),
      inn: this.contactsForm.value.inn || '',
      documents: this.contactsForm.value.documents?.filter(el => el.document),
    } as Contact;

    if (this.data?.id) {
      this.store.dispatch(new EditContact(contactData, this.data.id, createMatter));
      this.createDocuments(this.data.id);
    } else {
      this.openSpinner();
      this.store.dispatch(new CreateContact(contactData, this.isNested(), this.isMatter(), createMatter));
    }

    if (this.documentToRemove.length) {
      this.store.dispatch(new DeleteDocuments(this.documentToRemove));
    }

  }

  public checkInnContact() {
    const inn = this.contactsForm.value.inn;
    if (this.contactsForm.controls.inn.invalid || inn === this.currentContact$.value?.inn || !inn || inn === this.previewInn) {
      return;
    }
    this.previewInn = inn;
    this.checkInnStatus$.next(PROGRESS_STATUSES.IN_PROGRESS);
    this.contactService.checkInn(inn)
      .pipe(
        switchMap(response => {
          const duplicateContactName = [];
          if (response['hydra:member']?.length) {
            for (const item of response['hydra:member']) {
              duplicateContactName.push(`<a href='contact/${item.id}/dashboard'> ${item.fullName}</a>`);
            }

            const confirmDialogConfig = new MatDialogConfig();
            confirmDialogConfig.panelClass = 'default-mat-dialog';
            confirmDialogConfig.data = {
              modalTyle: 'blue',
              yesButtonTxt: this.translate.instant('contact.checkNameWarningBtnContiniue'),
              noButtonTxt: this.translate.instant('button.cancel'),
              title: this.translate.instant('contact.checkNameWarningTitle'),
              mainTxt: this.translate.instant('contact.checkNameWarningDescription', { linkContact: duplicateContactName }),
              checkNameContact: true,
            };
            return this.dialog.open(ConfirmModalComponent, confirmDialogConfig)
              .afterClosed()
              .pipe(
                take(1),
              );
          } else {
            this.checkInnStatus$.next(PROGRESS_STATUSES.SUCCEED);
            return of(null);
          }
        }),
      )
      .pipe(
        catchError(() => {
          this.checkInnStatus$.next(PROGRESS_STATUSES.INTERRUPTED);
          return of(null);
        }),
      )
      .subscribe({
        next: (res) => {
          if (res === false) {
            this.checkInnStatus$.next(PROGRESS_STATUSES.INTERRUPTED);
            this.dialogRef.close();
          } else {
            this.checkInnStatus$.next(PROGRESS_STATUSES.SUCCEED);
          }
        },
      });
  }

  public checkNameContact() {
    if (!this.data?.id) {
      let fullName = '';
      if (
        this.mode === TypeOfPerson.INDIVIDUAL &&
        this.contactsForm.value.contactPerson.lastName &&
        this.contactsForm.value.contactPerson.firstName
      ) {
        fullName = this.contactsForm.value.contactPerson.lastName + ' ' + this.contactsForm.value.contactPerson.firstName;
      } else if (this.mode === TypeOfPerson.LEGAL_ENTITY && this.contactsForm.value.contactCompany.fullName) {
        fullName = this.contactsForm.value.contactCompany.fullName;
      }

      if (fullName === this.previewName) {
        return;
      }

      if (fullName) {
        this.previewName = fullName;
        this.checkNameStatus$.next(PROGRESS_STATUSES.IN_PROGRESS);
        this.contactService.checkName(fullName)
          .pipe(
            switchMap(response => {
              const duplicateContactName = [];
              if (response.member?.length) {
                for (const item of response.member) {
                  duplicateContactName.push(`<a href='contact/${item.id}/dashboard'> ${item.fullName}</a>`);
                }

                const confirmDialogConfig = new MatDialogConfig();
                confirmDialogConfig.panelClass = 'default-mat-dialog';
                confirmDialogConfig.data = {
                  modalTyle: 'blue',
                  yesButtonTxt: this.translate.instant('contact.checkNameWarningBtnContiniue'),
                  noButtonTxt: this.translate.instant('button.cancel'),
                  title: this.translate.instant('contact.checkNameWarningTitle'),
                  mainTxt: this.translate.instant('contact.checkNameWarningDescription', { linkContact: duplicateContactName }),
                  checkNameContact: true,
                };
                return this.dialog.open(ConfirmModalComponent, confirmDialogConfig)
                  .afterClosed()
                  .pipe(
                    take(1),
                  );
              } else {
                this.checkNameStatus$.next(PROGRESS_STATUSES.SUCCEED);
                return of(null);
              }
            }),
          ).pipe(
          catchError(() => {
            this.checkNameStatus$.next(PROGRESS_STATUSES.INTERRUPTED);
            return of(null);
          }),
        )
          .subscribe({
            next: (res) => {
              if (res === false) {
                this.checkNameStatus$.next(PROGRESS_STATUSES.INTERRUPTED);
                this.dialogRef.close();
              } else {
                this.checkNameStatus$.next(PROGRESS_STATUSES.SUCCEED);
              }
            },
          });
      }
    }
  }


  toRemove(id: number) {
    if (id) {
      this.documentToRemove.push(id);
    }
  }

  scrollToField(fieldName: string) {
    setTimeout(() => {
      this.perfectScroll.directiveRef.scrollToElement('#scrollto-' + fieldName + '-' + this.contactsIndex[fieldName + 'index'], 0, 850);
    }, 50);
  }


  addDocument(index: number, target: any) {
    if (target) {
      const mediaFile = target.files[0];
      this.documentUploadModal(mediaFile).pipe(take(1)).subscribe((document: DocumentSave) => {
        if (document) {
          const item = this.contactsForm.controls.documents.get([index]);

          if (item && item.value && item.value.document && item.value.document.id) {
            this.documentToRemove.push(item.value.document.id);
          }
          item.patchValue({ document, mediaFile });
        }
      });
    }
  }

  addAdditionField(fieldName: string, skipScroll = true, required?: boolean): void {
    this.addField(fieldName, required);
    this.contactsIndex[fieldName + 'index'] = (this.contactsIndex[fieldName + 'index'] || 0) + 1;
    if (skipScroll) {
      this.scrollToField(fieldName);
    }
  }

  isNested(currentContact?: Contact): boolean {
    return !!this.data?.isNestedPerson || !!currentContact?.contactPerson?.user?.id;
  }

  isMatter(): boolean {
    return this.data && (this.data.ifMatter || this.data.fromDocument);
  }

  modalSwitchToggle(mode: TypeOfPerson): void {
    this.mode = mode;
    this.applyConfigToForm(mode);
    if (!this.data?.id && mode === TypeOfPerson.INDIVIDUAL) {
      if (this.contactsForm.controls?.togglePortalOnOff?.value) {
        this.contactsForm.controls.emailForPortal.enable();
      } else {
        this.contactsForm.controls.emailForPortal.disable();
      }
    }
  }

  private applyConfigToForm(mode: TypeOfPerson) {
    const blocks = CONTACT_FORM_CONFIGS[mode];
    this._contactBlocks = blocks;
    const formControls = {};
    Object.values(blocks).forEach(formBlock => {
      Object.assign(formControls, this.getContactControls(formBlock));
    });

    if (this.contactsForm) {
      if (!isEqual(Object.keys(formControls).sort(), Object.keys(this.contactsForm.controls).sort())) {

        for (const control of Object.keys(this.contactsForm.controls)) {
          if (!Object.keys(formControls).includes(control)) {
            this.contactsForm.removeControl(control as any);
          }
        }

        for (const control of Object.keys(formControls)) {
          if (!Object.keys(this.contactsForm.controls).includes(control)) {
            this.contactsForm.addControl(control as any, formControls[control]);
          }
        }
      }
    } else {
      this.contactsForm = new FormGroup<ContactFormControls>(formControls as ContactFormControls);
    }


    if (blocks.includes(ContactFormBlock.TogglePortalOnOff)) {

      /** email for portal. this stream for define to need field status: enable/disable */
      this.subscriptions.add(
        this.contactsForm.controls.togglePortalOnOff.valueChanges
          .pipe(
            startWith(this.contactsForm.controls.togglePortalOnOff.value),
          )
          .subscribe({
            next: (status) => {
              if (status) {
                this.contactsForm.controls?.emailForPortal?.enable();
              } else {
                this.contactsForm.controls?.emailForPortal?.disable();
              }
            },
          }),
      );

      /** load more information related portal */
      this.ifFindConnectInfo$ = combineLatest([
          this.contactsForm.controls.emailForPortal.valueChanges.pipe(debounceTime(500)),
          this.currentContact$,
        ],
      )
        .pipe(
          tap(() => this.checkEmailForPortalStatus$.next(PROGRESS_STATUSES.IN_PROGRESS)),
          map(([email, contact]) => contact?.contactPerson?.user?.email !== email),
          switchMap((isFind) => {
            if (isFind && this.contactsForm.controls.emailForPortal.valid) {
              return this.communicationService.checkPortalUniqEmail(this.contactsForm.value.emailForPortal).pipe(map(res => res.found));
            } else {
              return of(false);
            }
          }),
          tap((isFind) => {
            if (isFind) {
              this.contactsForm.controls.emailForPortal.setErrors({ unique: true });
            }
            this.checkEmailForPortalStatus$.next(PROGRESS_STATUSES.SUCCEED);
          }),
          catchError(error => {
            this.checkEmailForPortalStatus$.next(PROGRESS_STATUSES.INTERRUPTED);
            return of(false);
          }),
        );

    }

  }

  private getContactControls(calendarBlockName: ContactFormBlock): ContactControlRelation[ContactFormBlock] {
    switch (calendarBlockName) {
      case ContactFormBlock.Media:
        return {
          media: new FormControl(),
        };
      case ContactFormBlock.Inn:
        return {
          inn: new FormControl(null, [Validators.maxLength(45), Validators.minLength(8)]),
        };
      case ContactFormBlock.ContactCompany:
        return {
          contactCompany: new FormGroup({
            fullName: new FormControl(null, [Validators.maxLength(255), Validators.required]),
            director: new FormControl(null),
            headPost: new FormControl(''),
            id: new FormControl(null),
            headReason: new FormControl(''),
            legalForm: new FormControl(''),
          }),
        };
      case ContactFormBlock.ContactPerson:
        return {
          contactPerson: new FormGroup({
            firstName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
            lastName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
            middleName: new FormControl('', [Validators.maxLength(20)]),
            birthDate: new FormControl(null),
            bornPlace: new FormControl(''),
            nationality: new FormControl(''),
          }),
        };
      case ContactFormBlock.Emails:
        return {
          emails: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['emails']>>>([this.createEmailFormGroup]),
        };
      case ContactFormBlock.Phones:
        return {
          phones: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['phones']>>>([this.createPhoneFormGroup]),
        };
      case ContactFormBlock.Websites:
        return {
          websites: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['websites']>>>([this.createWebsiteFormGroup]),
        };
      case ContactFormBlock.Messengers:
        return {
          messengers: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['messengers']>>>
          ([this.createMessengerFormGroup]),
        };
      case ContactFormBlock.Addresses:
        return {
          addresses: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['addresses']>>>([this.createAddressFormGroup]),
        };
      case ContactFormBlock.BankDetails:
        return {
          bankDetails: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['bankDetails']>>>
          ([this.createBankDetailFormGroup]),
        };
      case ContactFormBlock.Documents:
        return {
          documents: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['documents']>>>([this.createDocumentFormGroup]),
        };
      case ContactFormBlock.ResponsiblePerson:
        return {
          responsiblePerson: new FormControl<ContactAllBlockControls['responsiblePerson']>(null),
        };
      case ContactFormBlock.CompanyWorkGroup:
        return {
          companyWorkGroup: new FormControl<ContactAllBlockControls['companyWorkGroup']>(null),
        };
      case ContactFormBlock.PersonType:
        return {
          personType: new FormControl<ContactAllBlockControls['personType']>(null),
        };
      case ContactFormBlock.RegistrationActions:
        return {
          registrationActions: new FormGroup({
            number: new FormControl(null, [Validators.maxLength(50)]),
            date: new FormControl(null),
            id: new FormControl(null),
          }),
        };
      case ContactFormBlock.LegalDocuments:
        return {
          legalDocuments: new FormGroup({
            number: new FormControl(null, [Validators.maxLength(50)]),
            whoIssuedDocument: new FormControl(''),
            date: new FormControl(null),
            dateExpire: new FormControl(null),
          }),
        };
      case ContactFormBlock.TagContacts:
        return {
          tagContacts: new FormArray<FormGroup<TransformToTypedForm<ContactAllBlockControls['tagContacts']>>>
          ([]),
        };
      case ContactFormBlock.EmailForPortal:
        return {
          emailForPortal: new FormControl(null, [Validators.email, Validators.required]),
        };
      case ContactFormBlock.TogglePortalOnOff:
        return {
          togglePortalOnOff: new FormControl(false),
        };

    }
  }


  public deleteImage() {
    this.setContactFormIconUrl(null);
    this.existedIconUrl = null;
  }

  private createDocuments(contactId: number) {
    const documentsArr = [];

    this.contactsForm.controls.documents.value.map(value => {
      if (value?.document && !value.document.id) {
        const mediaDocumentReminders = {
          document: { ...value.document.document, contact: contactId },
          reminders: value.reminders,
          mediaFile: value.mediaFile,
          relations: value.relations,
        };
        documentsArr.push(mediaDocumentReminders);
      }
    });
    this.store.dispatch(new AddDocuments(documentsArr));
  }


  private documentUploadModal(file: File) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { fileName: file.name, skipMatter: true, withContact: true };

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    dialogRef.backdropClick().pipe(take(1)).subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
    return dialogRef.afterClosed();
  }

  public setContactFormIconUrl(value: any) {
    this.contactsForm.controls.media.setValue(value);
  }

  private confirmCloseModal(modalRef: MatDialogRef<any>) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant('button.close'),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().pipe(take(1), filter(res => !!res)).subscribe(() => modalRef.close());
  }

  public onFilteringPersonsTypes: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersContacts.name = e.text;
    this.debounceService.loadDataDebounce(ContactModalDebounceEnum.personTypes, this.personTypeService, 'getPersonTypes', [
      environment.defaultPage,
      environment.countItemInSelect,
      { name: e.text },
    ]);
    this.debounceService.getValue(ContactModalDebounceEnum.personTypes).subscribe(resp => {
      e.updateData(resp?.member as any);
    });
  };

  public changeTypePerson(event: any) {
    const type = event.itemData;
    if (!type) {
      this.expandedAll = false;
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      modalTyle: 'blue',
      yesButtonTxt: 'contact.modal.accept',
      noButtonTxt: 'button.cancel',
      title: 'contact.modal.warningTypeTitle',
      mainTxt: 'contact.modal.warningTypeText',
      disableYesButton: false,
      disableNoButton: false,
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.expandedAll = result;
      if (!result) {
        this.contactsForm.controls.personType.setValue(null);
      }
    });
  }

  public openSpinner() {
    this.showLoader = true;
  }

  public hideSpinner() {
    this.showLoader = false;
  }

  checkIban(e: any, idx: number): boolean {
    let currentIban = e.target.value;
    currentIban = currentIban.replace('&nbsp;', '');
    // регулярка яка видаляє всі пробіли
    currentIban = currentIban.replace(/\s+/g, '');
    if (currentIban.length > 31) {
      // рахує кількість пробілів
      const countEmpty = e.target.value.split(' ').length - 1;
      this.contactsForm.controls.bankDetails.controls[idx].get('iban').setValue(e.target.value.substr(0, 31 + countEmpty));
    }

    let tempIban = e.target.value;
    tempIban = tempIban.replace('&nbsp;', '');
    tempIban = tempIban.replace(/\s+/g, '');
    if (tempIban.length >= 31 && e.keyCode !== 32) {
      return false;
    }
  }


  createUserPortal(contact: Contact): void {
    if (this.contactsForm.controls?.emailForPortal.invalid || this.mode === TypeOfPerson.LEGAL_ENTITY) {
      return;
    }


    if (
      this.contactsForm.controls?.emailForPortal.valid &&
      this.mode === TypeOfPerson.INDIVIDUAL &&
      contact.contactPerson?.user?.companyUserPortalAccesses[0] &&
      contact?.contactPerson?.user?.email !== this.contactsForm.value.emailForPortal
    ) {
      this.userService.changeOneUser(contact.contactPerson.user.id, { email: this.contactsForm.value.emailForPortal })
        .subscribe(res => {
          this.store.dispatch(new GetContactById(contact.id));
        });
      return;
    }

    if (!contact?.contactPerson?.user || !contact?.contactPerson?.user?.companyUserPortalAccesses[0]) {

      this.companyPortalSettingsService
        .postCompanyUserPortalAccesses({
          enable: this.contactsForm.value.togglePortalOnOff,
          user: {
            email: this.contactsForm.value.emailForPortal,
            person: {
              id: contact.contactPerson.id,
            },
          },
        })
        .subscribe(() => {
          this.store.dispatch(new GetContactById(contact.id));
        });
    } else if (this.data?.id && contact?.contactPerson?.user?.companyUserPortalAccesses[0]) {
      this.companyPortalSettingsService
      .putCompanyUserPortalAccesses(
        contact?.contactPerson?.user?.companyUserPortalAccesses[0].id,
        this.contactsForm.value.togglePortalOnOff,
      )
      .subscribe(() => {
        this.store.dispatch(new GetContactById(contact.id));
      });
    }
  }

  changeEmailToNew(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'button.cancel',
      title: 'deleteTitleTxt',
      mainTxt: 'textModalIfChangeEmailPortal',
    };

    this.dialog
      .open(ConfirmModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1), filter(res => res))
      .subscribe(result => {
        this.contactsForm.controls.emailForPortal.enable();
      });
  }


  onTagSaved(event: TagMain) {
    const tags = this.contactsForm.controls.tagContacts.value;
    const exist = !!tags.find((tagMain: TagMain) => tagMain?.tag?.name === event?.tag?.name);

    if (!exist) {
      delete event?.tag?.count;
      const tag = this.createTagFormGroup;
      tag.patchValue({ tag: event.tag });
      this.contactsForm.controls.tagContacts.push(tag);
    }
  }

  onTagRemoved(event: TagMain) {
    const index = this.contactsForm.controls.tagContacts.value.findIndex((tagMain: TagMain) => tagMain?.tag?.name === event?.tag?.name);
    this.contactsForm.controls.tagContacts.removeAt(index);
  }
}
