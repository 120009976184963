import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  LoadContactFinanceAction,
  ContactCardActionsTypes,
  LoadContactFinanceSuccessAction,
  LoadContactFinanceFailureAction
} from '../actions/contact-card.actions';
import { ContactFinance } from '@app/models';
import { MatterCardService } from '@app/modules/matter-card/services/matter-card.service';

@Injectable()
export class ContactCardEffects {
  
  getContactFinance$ = createEffect(() => this.actions$.pipe(
    ofType<LoadContactFinanceAction>(ContactCardActionsTypes.LOAD_CONTACT_FINANCE),
    switchMap(action => {
      return this.matterCardService.getContactFinance(action.payload).pipe(
        mergeMap(resp => {
          return [new LoadContactFinanceSuccessAction(resp as ContactFinance)];
        }),
        catchError(error => of(new LoadContactFinanceFailureAction(error)))
      );
    })
  ));

  constructor(private actions$: Actions, private matterCardService: MatterCardService) {}
}
