import { initialRelationshipTypeState, IRelationshipTypeState } from '../state/relationship-type.state';
import { RelationshipTypeActions, ERelationshipTypesActions } from '../actions/relationship-types.actions';

export const relationshipTypeReducers = (state = initialRelationshipTypeState, action: RelationshipTypeActions): IRelationshipTypeState => {
  switch (action.type) {
    case ERelationshipTypesActions.LOAD_RELATIONSHIP_TYPES:
      return {
        ...state,
        loading: true
      };
    case ERelationshipTypesActions.LOAD_RELATIONSHIP_TYPES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    case ERelationshipTypesActions.LOAD_RELATIONSHIP_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ERelationshipTypesActions.GET_RELATIONSHIP_TYPES_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case ERelationshipTypesActions.ADD_RELATIONSHIP_TYPE_SUCCESS: {
      return {
        ...state
      };
    }
    case ERelationshipTypesActions.UPDATE_RELATIONSHIP_TYPE_SUCCESS: {
      return {
        ...state
      };
    }

    case ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPE_SUCCESS: {
      return {
        ...state
      };
    }

    case ERelationshipTypesActions.DELETE_RELATIONSHIP_TYPES_SUCCESS: {
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};
