import { Paginator } from '../../../../models/paginator';

import { DocumentCategory } from '../../../../models/index';

export interface IDocumentCategoryState {
  paginator: Paginator<DocumentCategory>;
  selectedDocumentCategory: DocumentCategory;
  list: Paginator<DocumentCategory>;
  loading: boolean;
  error: Error;
}

export const initialDocumentCategoryState: IDocumentCategoryState = {
  paginator: null,
  selectedDocumentCategory: null,
  list: null,
  loading: false,
  error: undefined
};
