import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { LoadRelationshipTypesAction } from '@app/modules/settings/store/actions/relationship-types.actions';
import { selectRelationshipTypes } from '@app/modules/settings/store/selectors/relationship-type.selector';
import { Contact, RelationshipType } from '@app/models';
import { selectContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { LoadContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { environment } from '@environments/environment';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { MatterExtended } from '@app/models/matter-extended';
import { IRelationModalData } from '@app/models/relation-modal-data';
import { DebounceService } from '../../../../helpers/debounce.service';
import { RelationsModalDebounceEnum } from '../../../../enums/debounce-keys.enum';

@Component({
  selector: 'app-relations-modal',
  templateUrl: './relations-modal.component.html',
  styleUrls: ['./relations-modal.component.scss']
})
export class RelationsModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<RelationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IRelationModalData,
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    private contactService: ContactService,
    private appSettingsService: AppSettingsService,
    private debounceService: DebounceService
  ) {}
  get f() {
    return this.contactRelationshipGroup;
  }
  contacts: Contact[];
  matters: MatterExtended[];
  types: RelationshipType[];
  public sorting = 'Ascending';
  public locale$ = this.appSettingsService.getLang;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  submittedForm = false;
  contactRelationshipGroup: UntypedFormGroup;

  filtersContacts = {
    'order[fullName]': 'ASC',
    name: ''
  };
  @ViewChild('contactsComboBox', { static: false }) public comboBoxContact: ComboBoxComponent;
  public closeModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.createForm();
    this.loadContacts();
    this.loadMatters();
    this.getRelationshipTypes();
  }

  ngOnDestroy(): void {
    this.debounceService.removeDebounceData();
  }

  createForm() {
    this.contactRelationshipGroup = this.formBuilder.group({
      source: new UntypedFormControl(this.dialogData && this.dialogData.relation ? this.dialogData.relation.source : null, [Validators.required]),
      description: new UntypedFormControl(this.dialogData && this.dialogData.relation ? this.dialogData.relation.description : ''),
      target: new UntypedFormControl(this.dialogData && this.dialogData.relation ? this.dialogData.relation.target : null, [Validators.required]),
      type: new UntypedFormControl(this.dialogData && this.dialogData.relation ? this.dialogData.relation.type : null, [Validators.required]),
      direction: new UntypedFormControl('forward')
    });
    if (this.dialogData && this.dialogData.fromContact) {
      this.contactRelationshipGroup.get('source').disable();
    }
    if (this.dialogData && this.dialogData.relationPerson) {
      this.contactRelationshipGroup.get('target').disable();
    }

    if (this.dialogData && this.dialogData.fromMatter) {
      this.contactRelationshipGroup.addControl(
        'matter',
        new UntypedFormControl(this.dialogData && this.dialogData.matter ? this.dialogData.matter.id : null, [Validators.required])
      );
      this.contactRelationshipGroup.get('matter').disable();
    }
  }
  onSubmit() {
    this.checkSubmit();
    if (this.contactRelationshipGroup.valid) {
      const payload = { ...this.contactRelationshipGroup.value };
      payload.target = this.dialogData.relationPerson ? this.dialogData.relationPerson.id : payload.target + '';
      payload.type += '';
      if (this.dialogData && this.dialogData.contact) {
        payload.source = this.dialogData.contact.id + '';
      }
      if (this.dialogData && this.dialogData.matter) {
        payload.matter = this.dialogData.matter.id + '';
      }
      if (this.dialogData.relation) {
        payload.id = this.dialogData.relation.id;
        payload.direction = this.dialogData.relation.direction;
      }
      this.dialogRef.close(payload);
    }
  }
  checkSubmit() {
    this.submittedForm = true;
  }
  private getRelationshipTypes() {
    this.store.pipe(select(selectRelationshipTypes)).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadRelationshipTypesAction());
      }

      this.types = res;
      if (res) {
        if (this.dialogData && this.dialogData.relation) {
          this.contactRelationshipGroup.get('type').setValue(this.dialogData.relation.type.id);
        }
      }
    });
  }

  loadContacts() {
    this.store.select(selectContactListForSelect).subscribe(data => {
      if (!data) {
        this.store.dispatch(new LoadContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
      }
      if (data) {
        this.contacts = data;
        if (this.dialogData && this.dialogData.contact) {
          if (!this.contacts.find(x => x.id === this.dialogData.contact.id)) {
            this.contacts.push(this.dialogData.contact);
          }
          this.contactRelationshipGroup.get('source').setValue(this.dialogData.contact.id);
        }

        if (this.dialogData && this.dialogData.matter) {
          if (!this.contacts.find(x => x.id === this.dialogData.matter.contact.id)) {
            this.contacts.push(this.dialogData.matter.contact);
          }
          this.contactRelationshipGroup.get('source').setValue(this.dialogData.matter.contact.id);
          this.contactRelationshipGroup.get('source').disable();
        }

        if (this.dialogData && this.dialogData.relation) {
          if (!this.contacts.find(x => x.id === this.dialogData.relation.target.id)) {
            this.contacts.push(this.dialogData.relation.target);
          }
          this.contactRelationshipGroup.get('target').setValue(this.dialogData.relation.target.id);
        }

        if (this.dialogData && this.dialogData.relationPerson) {
          if (!this.contacts.find(x => x.id === this.dialogData.relationPerson.id)) {
            this.contacts.push(this.dialogData.relationPerson);
          }
          this.contactRelationshipGroup.get('target').setValue(this.dialogData.relationPerson.id);
        }
      }
    });
  }

  loadMatters() {
    this.matters = [];
    if (this.dialogData && this.dialogData.matter) {
      this.matters.push(this.dialogData.matter);
    }
  }

  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersContacts.name = e.text;
    this.comboBoxContact?.showSpinner();
    this.debounceService.loadDataDebounce(
      RelationsModalDebounceEnum.contacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(RelationsModalDebounceEnum.contacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxContact?.hideSpinner();
      this.contacts = resp?.member as any;
    });
  }

  public filterTypes: EmitType<any> = (e: FilteringEventArgs) => {
    if (e.text === '') {
      e.updateData(this.types as any);
    } else {
      const filteringData = [];
      this.types.forEach(i => {
        if (i.name.toUpperCase().indexOf(e.text.toUpperCase()) !== -1) {
          filteringData.push(i);
        }
      });
      e.updateData(filteringData);
    }
  }
}
