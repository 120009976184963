import { Paginator } from '../../../../models/paginator';

import { PracticeBranch } from '../../../../models/index';

export interface IPracticeBranchState {
  paginator: Paginator<PracticeBranch>;
  selectedPracticeBranch: PracticeBranch;
  list: Array<PracticeBranch>;
  loading: boolean;
  error: Error;
}

export const initialPracticeBranchState: IPracticeBranchState = {
  paginator: null,
  selectedPracticeBranch: null,
  list: null,
  loading: false,
  error: undefined
};
