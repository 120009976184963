// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container .text-container {
  margin-bottom: 66px;
  padding-right: 70px;
}
.content-container .text-container h6 {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.content-container .text-container p {
  color: #637283;
  font-size: 13px;
  margin: 0;
}
.content-container .support-team-contacts {
  border: none;
  padding: 0;
  margin-bottom: 39px;
}
.content-container .support-team-contacts .contact-info {
  margin-bottom: 0;
}

.modal-template .modal-footer .button-container {
  justify-content: space-between;
}
.modal-template .modal-footer .button-container p {
  color: #637283;
  font-size: 13px;
  margin: 0;
}
.modal-template .modal-footer .button-container p a {
  text-decoration: underline;
}

textarea {
  height: 165px;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/reports-custom-modal/reports-custom-modal.component.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;AAAN;AAGI;EACE,cAAA;EACA,eAAA;EACA,SAAA;AADN;AAKE;EACE,YAAA;EACA,UAAA;EACA,mBAAA;AAHJ;AAKI;EACE,gBAAA;AAHN;;AASE;EACE,8BAAA;AANJ;AAQI;EACE,cAAA;EACA,eAAA;EACA,SAAA;AANN;AAQM;EACE,0BAAA;AANR;;AAYA;EACE,aAAA;AATF","sourcesContent":[".content-container {\n  .text-container {\n    margin-bottom: 66px;\n    padding-right: 70px;\n\n    h6 {\n      color: #222222;\n      font-size: 16px;\n      font-weight: 600;\n      margin: 0;\n    }\n\n    p {\n      color: #637283;\n      font-size: 13px;\n      margin: 0;\n    }\n  }\n\n  .support-team-contacts {\n    border: none;\n    padding: 0;\n    margin-bottom: 39px;\n\n    .contact-info {\n      margin-bottom: 0;\n    }\n  }\n}\n\n.modal-template {\n  .modal-footer .button-container {\n    justify-content: space-between;\n\n    p {\n      color: #637283;\n      font-size: 13px;\n      margin: 0;\n\n      a {\n        text-decoration: underline;\n      }\n    }\n  }\n}\n\ntextarea {\n  height: 165px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
