import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseBodyFull } from '@app/models';
import { environment } from '@environments/environment';
import { Helpers } from '@app/helpers/helpers';
import { AuthService } from '@app/auth/_services/auth.service';
import { SearchResponse } from '@app/models/search-response';
import { IComment } from '@app/models/comment';

@Injectable()
export class DiscussionService {
  private BASE_URL = `${environment.apiUrl}/comments`;
  readonly BASE_URL_USERS = `${environment.apiUrl}/users`;

  private BASE_URL_SEARCH = `${environment.apiUrl}/search`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  get(filters: any = null) {
    let params = new HttpParams().set('partial', 'false');
    params = params.set('page', '1').set('itemsPerPage', '10000');

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<IComment>>(this.BASE_URL, { params })
        .pipe(map(response => (response['hydra:member'])));
    } else {
      return new Observable<any>();
    }
  }

  getCommentById(id: number): Observable<IComment> {
    if (id == null) {
      return new Observable<IComment>();
    }

    if (this.authService.currentUserValue) {
      return this.http.get<IComment>(`${this.BASE_URL}/${id}`);
    } else {
      return new Observable<IComment>();
    }
  }

  deleteComment(id) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  setComment(comment: IComment): Observable<IComment> {
    return this.http.post<IComment>(`${this.BASE_URL}`, comment);
  }

  updateComment(comment: IComment): Observable<IComment> {
    return this.http.patch<IComment>(`${this.BASE_URL}/${comment.id}`, comment);
  }
  pinnedComment(userId, data): Observable<IComment> {
    return this.http.patch<IComment>(`${this.BASE_URL_USERS}/${userId}`, data);
  }

  getProjectMention(filters?) {
    let params = new HttpParams();

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<SearchResponse>>(this.BASE_URL_SEARCH, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<any>();
    }
  }
}
