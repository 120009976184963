import { initialBankAccountState, IBankAccountState } from '../state/bank-account.state';
import { BankAccountActions, EBankAccountsActions } from '../actions/bank-account.actions';

export const bankAccountReducers = (state = initialBankAccountState, action: BankAccountActions): IBankAccountState => {
  switch (action.type) {
    case EBankAccountsActions.GET_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        paginator: action.payload
      };
    }
    case EBankAccountsActions.GET_BANK_ACCOUNT_TOTAL_SUCCESS: {
      return {
        ...state,
        bankAccountTotal: action.payload
      };
    }
    case EBankAccountsActions.GET_BANK_ACCOUNTS_BY_CURRENCY_SUCCESS: {
      return {
        ...state,
        bankAccountsByCurrency: action.payload
      };
    }

    case EBankAccountsActions.GET_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        selectedBankAccount: action.payload
      };
    }
    case EBankAccountsActions.ADD_BANK_ACCOUNT_SUCCESS: {
      if (state.paginator?.member) {
        state.paginator.member.push(action.payload);
      }
      return {
        ...state
      };
    }
    case EBankAccountsActions.UPDATE_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state
      };
    }

    case EBankAccountsActions.DELETE_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state
      };
    }

    case EBankAccountsActions.CLEAR_BANK_ACCOUNTS: {
      return {
        ...state,
        paginator: null
      };
    }

    default: {
      return state;
    }
  }
};
