import { INews } from '@app/models/interfaces/news.interface';
import { Paginator } from '@app/models/paginator';

export const initialNewsState: Paginator<INews> = {
  member: [],
  countItems: 0,
  totalItems: 0,
  currentPage: 1,
  itemsPerPage: 0,
  lastPage: 1,
};
