import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { ExpenseSave } from '@app/models/expense-save';
import { ActivityExtended, Contact, ExpenseType, IUser } from '@app/models';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { fadeTopAnimation } from '@app/helpers/fadeAnimation';
import { DocumentSave } from '@app/models/document-save';
import {
  DocumentUploadModalComponent,
} from '@app/commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import { IAppState } from '@app/store/state/app.state';
import { select, Store as StoreNgrx } from '@ngrx/store';
import { AddDocuments, DeleteDocuments } from '@app/store/actions/document.actions';
import { selectCurrentActivityDocuments } from '@app/store/selectors/document.selectors';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { AuthService } from '@app/auth/_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Helpers } from '@app/helpers/helpers';
import { MatterFilters } from '@app/models/matter-filters';
import { selectExpenseTypes, selectInternalExpenseTypes } from '@app/modules/settings/store/selectors/expence-type.selector';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { LoadAllContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { selectAllContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { environment } from '@environments/environment';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent, DropDownTreeComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { MatterExtended } from '@app/models/matter-extended';
import { AddActivity, UpdateActivity } from '@app/store/actions/activities.actions';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { DocRelation } from '@app/models/doc-relation';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { ReminderExtended } from '@app/models/reminder-extended';
import { Currency } from '@app/models/currency';
import { DebounceService } from '@app/helpers/debounce.service';
import { ExpenseModalDebounceEnum } from '@app/enums/debounce-keys.enum';
import { ExpenseModalDataModel, TypeExpenseEnum } from '@app/commonComponents/wide-modals/symbols';
import { Select, Store } from '@ngxs/store';
import { BankAccountState } from '@app/modules/bank-accounts/states/bank-account/bank-account.state';
import { BankAccountStateModel } from '@app/modules/bank-accounts/states/bank-account/bank-account.model';
import { PROGRESS_STATUSES } from '@app/shared/symbols';
import { GetBankAccountsByCurrency } from '@app/modules/bank-accounts/states/bank-account/bank-account.actions';
import { debounceTime, distinctUntilChanged, filter, first, map, take, tap } from 'rxjs/operators';
import { LoadMattersForList } from '@app/modules/main-matters/states/main-matter/main-matter.actions';
import { MainMatterState } from '@app/modules/main-matters/states/main-matter/main-matter.state';
import { MainMatterStateModel } from '@app/modules/main-matters/states/main-matter/main-matter.model';
import { LoadExpenseTypesAction, LoadInternalExpenseTypesAction } from '@app/modules/settings/store/actions/expense-types.actions';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';

enum documentType {
  document = 'document'
}

interface Media {
  type: documentType;
}

interface DocumentModel {
  name: string;
  id?: number;
  media: Media;
}

interface ExpenseTypeModel {
  dataSource: ExpenseType[];
  value: string;
  parentValue: string;
  text: string;
  child: string;
}

@Component({
  selector: 'app-expense-modal',
  templateUrl: './expense-modal.component.html',
  styleUrls: ['./expense-modal.component.scss'],
  animations: [fadeTopAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseModalComponent implements OnInit, OnDestroy {
  @Select(BankAccountState.getBankAccountsByCurrency)
  getBankAccountsByCurrency$: Observable<BankAccountStateModel['bankAccountsByCurrency']>;

  @Select(BankAccountState.getLoadBankAccountsByCurrencyStatus)
  getLoadBankAccountsByCurrencyStatus$: Observable<BankAccountStateModel['loadBankAccountsByCurrencyStatus']>;

  @Select(MainMatterState.matterExtended) matterExtended$: Observable<MainMatterStateModel['matterExtended']>;
  @Select(MainMatterState.loadMatterExtendedStatus) loadMatterExtendedStatus$: Observable<MainMatterStateModel['loadMatterExtendedStatus']>;

  @ViewChild('file', { static: false }) inputFile: ElementRef;
  @ViewChild('bankAccountComboBox', { static: false }) public comboBoxBankAccount: ComboBoxComponent;
  @ViewChild('contactsComboBox', { static: false }) public comboBoxContact: ComboBoxComponent;
  @ViewChild('mattersComboBox', { static: false }) public comboBoxMatter: ComboBoxComponent;
  @ViewChild('usersComboBox', { static: false }) public comboBoxUser: ComboBoxComponent;
  @ViewChild('dropdowntree') dropdownExpenseTree: DropDownTreeComponent;

  public documentsToShow$: BehaviorSubject<DocumentModel[]> = new BehaviorSubject([]);
  public matters$: Observable<MatterExtended[]>;
  public vendors$: Observable<Contact[]>;
  public users$: Observable<IUser[]>;
  public locale$ = this.appSettingsService.getLang;

  public readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  public readonly expenseForm = new FormGroup({
    amount: new FormControl(null, [Validators.required, Validators.min(0)]),
    vendorId: new FormControl(null),
    matterId: new FormControl(this.data && this.data.matter ? this.data.matter.id : null, [Validators.required]),
    expenseTypeId: new FormControl(null),
    currency: new FormControl(null, [Validators.required]),
    date: new FormControl(this.data && this.data.date ? new Date(this.data.date) : new Date(), [Validators.required]),
    description: new FormControl(''),
    responsiblePersonId: new FormControl(this.authService.currentUserValue.id, [Validators.required]),
    bankAccountId: new FormControl(null),
  });

  public currencies = Helpers.getCurrenciesList();
  public dateFormat = CustomDateAdapter.dateFormat;
  public descIn = 'unfocus';
  public fieldsExpenseTypes$: Observable<ExpenseTypeModel>;
  public readOnlyAmount = false;
  public sorting = 'Ascending';

  private readonly subscription = new Subscription();

  private filtersContacts = {
    'order[fullName]': 'ASC',
    name: '',
  };
  private filtersUser: TeamMemberFilter = {
    name: '',
    status: 'active',
    'companyAccesses.enable': true,
  };
  private filterMatters: MatterFilters = {
    name: '',
    status: 'open',
  };
  private fileData: File = null;
  private defaultCurrency: any;
  private initBankAccount = true;
  // pairs of documents and media to save them when expense is saved
  private documentsToAdd: { document: DocumentSave; mediaFile: File; reminders: ReminderExtended; relations: DocRelation[] }[] = [];
  // documents ids to delete when expense is saved
  private documentsToDelete: number[] = [];

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ExpenseModalDataModel,
    public dialogRef: MatDialogRef<ExpenseModalComponent>,
    private dialog: MatDialog,
    private storeNgrx: StoreNgrx<IAppState>,
    private contactService: ContactService,
    private matterService: MatterService,
    private teamMemberService: TeamMemberService,
    private store: Store,
    private authService: AuthService,
    private toastr: ToastrService,
    private appSettingsService: AppSettingsService,
    private debounceService: DebounceService,
  ) {
  }

  ngOnInit(): void {
    const currentUser$ = this.storeNgrx.pipe(select(selectCurrentUser));


    this.expenseForm.patchValue({ matterId: this.data?.expense?.matter.id || this.data?.matter?.id });
    this.matters$ = of([this.data.matter]);


    this.users$ = combineLatest([
      this.storeNgrx.select(selectListUsersForSelect),
      currentUser$,
    ])
      .pipe(
        filter(([users, currentUser]) => !!users && !!currentUser),
        first(),
        map(([users, currentUser]) => {
          const currentUserExists = users.some(user => user.id === currentUser.id);
          if (!currentUserExists) {
            return { users: [...users, currentUser], currentUser };
          }
          return { users, currentUser };
        }),
        map(({ users, currentUser }) => {
            this.expenseForm.patchValue({ responsiblePersonId: currentUser.id });
            return users;
          },
        ),
      );

    if (this.data.type === TypeExpenseEnum.INTERNAL) {
      this.storeNgrx.dispatch(new LoadInternalExpenseTypesAction());
      this.fieldsExpenseTypes$ = this.storeNgrx.select(selectInternalExpenseTypes)
        .pipe(
          filter(data => !!data),
          first(),
          tap(() => {
            if (this.data?.expense) {
              this.expenseForm.patchValue({ expenseTypeId: this.data.expense?.expenseType?.id });

              if (this.dropdownExpenseTree) {
                this.dropdownExpenseTree.value = this.data.expense.expenseType ? [this.data.expense.expenseType.id + ''] : [];
              }
            }
          }),
          map(data => ({ dataSource: data, value: 'id', parentValue: 'parentId', text: 'name', child: 'children' })),
        );

    } else {
      this.storeNgrx.dispatch(new LoadExpenseTypesAction());
      this.fieldsExpenseTypes$ = this.storeNgrx.select(selectExpenseTypes)
        .pipe(
          filter(data => !!data),
          first(),
          tap(() => {
            if (this.data?.expense) {
              this.expenseForm.patchValue({ expenseTypeId: this.data.expense?.expenseType?.id });

              if (this.dropdownExpenseTree) {
                this.dropdownExpenseTree.value = this.data.expense.expenseType ? [this.data.expense.expenseType.id + ''] : [];
              }
            }
          }),
          map(data => ({ dataSource: data.data, value: 'id', parentValue: 'parentId', text: 'name', child: 'children' })),
        );

    }
    this.storeNgrx.dispatch(new LoadAllContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
    this.storeNgrx.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));

    if (this.data?.task) {
      this.expenseForm.controls.matterId.disable();
    }

    if (this.data?.matter) {
      this.expenseForm.controls.matterId.disable();
    }
    if (this.data?.responsible) {
      this.expenseForm.controls.responsiblePersonId.disable();
    }

    this.initBankAccount = !!this.initBankAccount && !!this.data?.expense;

    if (this.data?.bankAccount && this.initBankAccount) {
      this.readOnlyAmount = true;
      this.initBankAccount = false;
    }


    this.expenseForm.patchValue({ responsiblePersonId: this.authService?.currentUserValue?.id });

    if (this.data?.expense) {
      this.setFormValues(this.data.expense);

      this.storeNgrx.pipe(
        select(selectCurrentActivityDocuments),
        filter(data => !!data),
        first(),
      )
        .subscribe(documents => {
          if (!!documents?.length) {
            this.documentsToShow$.next(
              documents.map(doc => ({ name: doc.name, id: doc.id, media: { type: 'document' } } as DocumentModel),
              ),
            );
          }
        });
    }


    this.subscription.add(
      this.getLoadBankAccountsByCurrencyStatus$.subscribe({
        next: status => {
          if (this.comboBoxBankAccount) {
            if (status === PROGRESS_STATUSES.IN_PROGRESS) {
              this.comboBoxBankAccount.showSpinner();
            } else {
              this.comboBoxBankAccount.hideSpinner();
            }
          }
        },
      }),
    );


    this.subscription.add(
      this.storeNgrx
        .pipe(select(selectCompanySetting), filter(data => !!data))
        .subscribe(data => {
          this.defaultCurrency = (data.currency as Currency).code;
          this.readOnlyAmount = !data.isManyCurrency;
        }));


    this.loadMatters();
    this.initData();


    this.subscription.add(
      this.expenseForm.controls.currency.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe({
          next: value => this.store.dispatch(new GetBankAccountsByCurrency(value)),
        }),
    );


    this.vendors$ = this.storeNgrx.select(selectAllContactListForSelect).pipe(
      filter(vendors => !!vendors),
      map(vendors => {
        if (this.data?.expense?.vendor) {
          if (!vendors.find(vendor => vendor.id === this.data.expense.vendor.id)) {
            return [...vendors, this.data.expense.vendor];
          }
        }
        return vendors;
      }),
      tap(() => {
        if (this.data?.expense?.vendor) {
          this.expenseForm.patchValue({
            vendorId: this.data?.expense?.vendor?.id,
          });
        }
      }),
    );

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  public onFileChanged(fileInput): void {
    this.fileData = fileInput.target.files[0] as File;
    if (!Helpers.checkSizeFile(this.fileData, 20)) {
      this.fileData = null;
      this.toastr.error(this.translate.instant('documents.fileTooBig20'));
      return;
    }
    if (this.fileData) {
      this.documentUploadModal(this.fileData);
    }
    this.inputFile.nativeElement.value = null;
  }

  public deleteDocument(document: DocumentModel, index: number): void {
    if (document.id) {
      this.documentsToDelete.push(document.id);
    } else {
      const position = this.documentsToAdd.indexOf(this.documentsToAdd.filter(x => x.document.name === document.name)[0]);
      if (position !== -1) {
        this.documentsToAdd.splice(position, 1);
      }
    }
    this.documentsToShow$.next(this.documentsToShow$.value.filter((_, i) => i !== index));
  }

  public saveExpenseAndClose(): void {
    this.expenseForm.markAsDirty();
    this.expenseForm.controls.matterId.markAsDirty();
    this.expenseForm.controls.amount.markAsDirty();
    this.expenseForm.updateValueAndValidity();
    if (this.expenseForm.invalid) {
      return;
    }

    if (this.data?.expense) {
      this.updateExpense();
    } else {
      this.addExpense();
    }
    this.dialogRef.close(true);
  }

  public saveExpenseAndClear(): void {
    this.expenseForm.markAsDirty();
    this.expenseForm.controls.matterId.markAsDirty();
    this.expenseForm.controls.amount.markAsDirty();
    this.expenseForm.updateValueAndValidity();

    if (this.expenseForm.invalid) {
      return;
    }
    if (this.data?.expense) {
      this.updateExpense();
      delete this.data.expense;
    } else {
      this.addExpense();
    }

    this.documentsToShow$.next([]);
    this.documentsToAdd = [];
    this.documentsToDelete = [];

    this.resetFormValues();
  }

  public saveExpenseAndContinue(): void {
    this.expenseForm.markAsDirty();
    this.expenseForm.controls.matterId.markAsDirty();
    this.expenseForm.controls.amount.markAsDirty();
    this.expenseForm.updateValueAndValidity();
    if (!this.expenseForm.valid) {
      return;
    }
    if (this.data && this.data.expense) {
      this.updateExpense();
    } else {
      this.addExpense();
    }
    this.documentsToShow$.next([]);
    this.documentsToAdd = [];
    this.documentsToDelete = [];
    if (this.data && this.data.expense) {
      delete this.data.expense;
    }
  }

  public onDropDownCreated(): void {
    if (this.dropdownExpenseTree && this.data?.expense) {
      const expenseTypeId = this.data.expense.expenseType?.id;
      this.dropdownExpenseTree.value = expenseTypeId ? [expenseTypeId + ''] : [];
    }
  }

  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs): void => {
    this.filtersContacts.name = e.text;
    this.comboBoxContact?.showSpinner();
    this.debounceService.loadDataDebounce(
      ExpenseModalDebounceEnum.contacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(ExpenseModalDebounceEnum.contacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.vendors$ = of(resp?.member);
      this.comboBoxContact?.hideSpinner();
    });
  };

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs): void => {
    this.filterMatters.name = e.text;
    this.comboBoxMatter?.showSpinner();
    this.debounceService.loadDataDebounce(
      ExpenseModalDebounceEnum.matters,
      this.matterService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filterMatters]);
    this.debounceService.getValue(ExpenseModalDebounceEnum.matters).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.matters$ = of(resp?.member);
      this.comboBoxMatter?.hideSpinner();
    });
  };

  public onFilteringUsers: EmitType<any> = (e: FilteringEventArgs): void => {
    this.filtersUser.name = e.text;
    this.comboBoxUser?.showSpinner();
    this.debounceService.loadDataDebounce(
      ExpenseModalDebounceEnum.users,
      this.teamMemberService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersUser]);
    this.debounceService.getValue(ExpenseModalDebounceEnum.users).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.users$ = of(resp?.member);
      this.comboBoxUser?.hideSpinner();
    });
  };

  public fixAmount(event: any): boolean {
    let temp = event.target.value;
    const currentValue = temp.split('.');
    if (currentValue.length > 2) {
      currentValue.pop();
      temp = currentValue[0] + '.' + currentValue[1];
    }
    const countAfter = currentValue[currentValue.length - 1].length;
    if (temp.includes('.') && countAfter > 2) {
      if (currentValue[1][0] > 9) {
        temp = currentValue[0] + '.' + '9' + currentValue[1][1];
      } else {
        temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
      }
    }
    this.expenseForm.patchValue({ amount: temp });

    return (event.which >= 48 && event.which <= 57) || event.which === 46;

  }

  private confirmCloseModal(modalRef: MatDialogRef<DocumentUploadModalComponent, any>): void {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === true) {
          modalRef.close();
          confirmDialogRef.close();
        } else {
          confirmDialogRef.close();
        }
      });
  }

  private loadMatters(): void {
    if (this.data?.contact) {
      if (this.expenseForm.controls.matterId.disabled) {
        return;
      }
      if (this.data?.expense) {
        this.expenseForm.controls.matterId.disable();
      }
      this.filterMatters['contact.id'] = this.data.contact.id;
      this.matters$ = this.matterService
        .get(environment.defaultPage, environment.countItemInSelect, this.filterMatters)
        .pipe(
          filter(matter => !!matter),
          map(matter => matter.member),
          map(matter => {
            if (this.data?.expense?.matter) {
              if (!matter.find(item => item.id === this.data.expense.matter.id)) {
                return [...matter, this.data.expense.matter];
              }
            }
            return matter;
          }),
          tap(() => this.expenseForm.patchValue({ matterId: this.data?.expense?.matter.id || this.data?.matter?.id })),
        );
    } else {
      this.store.dispatch(new LoadMattersForList({
        page: environment.defaultPage,
        itemsPerPage: +localStorage.getItem('paginationPerPage') || environment.defaultItemsCount,
      }));

      this.matters$ = this.matterExtended$
        .pipe(
          filter(matter => !!matter),
          map(matter => matter.member),
          map(matter => {
            if (this.data?.expense?.matter) {
              if (!matter.find(item => item.id === this.data.expense.matter.id)) {
                return [...matter, this.data.expense.matter];
              }
            }
            return matter;
          }),
        );
    }
  }

  private setFormValues(expense: ActivityExtended): void {
    this.expenseForm.patchValue({
      amount: expense.amount.toString(),
      vendorId: expense?.vendor?.id,
      matterId: expense?.matter?.id,
      expenseTypeId: expense?.expenseType?.id,
      currency: expense.currency.code,
      date: new Date(expense.date),
      description: expense.description || this.data?.task?.name,
      responsiblePersonId: expense?.responsiblePerson.id,
      bankAccountId: expense?.companyBankAccount?.id,
    });

  }

  private resetFormValues(): void {
    this.expenseForm.reset();
    this.expenseForm.markAsUntouched();
  }

  private formDataToExpenseSave(isUpdated: boolean): ExpenseSave {
    const expense: ExpenseSave = {
      id: isUpdated ? this.data.expense.id : null,
      amount: this.expenseForm.get('amount').value.toString(),
      vendor: this.expenseForm.get('vendorId').value ? (this.expenseForm.get('vendorId').value as number) : null,
      currency: this.expenseForm.get('currency').value,
      date: CustomDateAdapter.convertToUniversalDateString(this.expenseForm.get('date').value),
      description: this.expenseForm.get('description').value,
      matter: this.expenseForm.get('matterId').value as number,
      responsiblePerson: this.expenseForm.get('responsiblePersonId').value as number,
      expenseType: this.expenseForm.get('expenseTypeId').value as number,
      task: this.data && this.data.task ? this.data.task.id : this.data?.expense?.task ? this.data?.expense?.task.id : null,
      companyBankAccount: this.expenseForm.get('bankAccountId').value as number,
    };
    return expense;
  }

  private addExpense(): void {
    const expense = this.formDataToExpenseSave(false);

    expense.expenseType = this.dropdownExpenseTree?.value ? +this.dropdownExpenseTree?.value[0] : null;
    if (this.documentsToAdd.length > 0) {
      this.storeNgrx.dispatch(new AddActivity({ activity: expense, typeActivity: 'expenses', documents: this.documentsToAdd }));
    } else {
      this.storeNgrx.dispatch(new AddActivity({ activity: expense, typeActivity: 'expenses' }));
    }
  }

  private updateExpense(): void {
    const expense = this.formDataToExpenseSave(true);

    expense.expenseType = this.dropdownExpenseTree?.value ? +this.dropdownExpenseTree?.value[0] : null;
    this.storeNgrx.dispatch(new UpdateActivity({ activity: expense, typeActivity: 'expenses' }));
    if (this.documentsToAdd.length > 0) {
      this.storeNgrx.dispatch(new AddDocuments(this.documentsToAdd));
    }
    if (this.documentsToDelete.length > 0) {
      this.storeNgrx.dispatch(new DeleteDocuments(this.documentsToDelete));
    }
  }

  private documentUploadModal(file: File): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { fileName: file.name, skipMatter: true };

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);

    this.subscription.add(dialogRef.afterClosed().subscribe(x => {
      if (x) {
        x.document.activity = this.data && this.data.expense ? this.data.expense['@id'] : null;
        this.documentsToAdd.push({ document: x.document, mediaFile: this.fileData, reminders: x.reminders, relations: x.relations });
        this.documentsToShow$.next([...this.documentsToShow$.value, { name: x.document.name, media: { type: documentType.document } }]);
      }
    }));
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  private initData(): void {
    if (this.data?.bankAccount) {
      this.store.dispatch(new GetBankAccountsByCurrency(this.data.bankAccount.currency.code));
      this.expenseForm.patchValue({ currency: this.data.bankAccount.currency.code });
    } else {
      this.store.dispatch(new GetBankAccountsByCurrency(this.defaultCurrency));
      this.expenseForm.patchValue({ currency: this.defaultCurrency });
    }


  }
}
