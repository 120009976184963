import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {
  transform(value: Date, separator: string) {
    return moment(value).format('DD.MM.yyyy' + '<span>' + `${ separator}` + '</span>' + 'HH:mm');
  }
}
