import { Component, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { filter, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { ISharedPublicModalData } from '@app/models/shared-public-modal-data';

@Component({
  selector: 'app-share-public-modal',
  templateUrl: './share-public-modal.component.html',
  styleUrls: ['./share-public-modal.component.scss']
})
export class SharePublicModalComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<SharePublicModalComponent>,
    private appSettingsService: AppSettingsService,
    private billService: BillService,
    @Inject(MAT_DIALOG_DATA) public dialogData: ISharedPublicModalData,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}
  public locale$ = this.appSettingsService.getLang;
  readonly dateFormat = CustomDateAdapter.dateFormat;

  dateHash;

  minDate = new Date();
  @ViewChild('datePickerHash', { static: false }) public datePickerHash: DatePickerComponent;

  linkFile;

  ngAfterViewInit() {
    const billFrame = document.getElementById('share-bill-iframe');
    billFrame.setAttribute('src', this.dialogData.invoiceUrl);
    setTimeout(() => {
      this.dateHash = this.dialogData.bill.expiredHashAt
        ? new Date(this.dialogData.bill.expiredHashAt)
        : new Date(new Date().setMonth(new Date().getMonth() + 1));
    });
    setTimeout(() => {
      this.datePickerHash.focusOut();
      if (!this.dialogData.bill.hash || !this.dialogData.bill.expiredHashAt || new Date(this.dialogData.bill.expiredHashAt) < new Date()) {
        this.generateHash();
      }
      this.linkFile = `${this.dialogData.invoiceUrl}-preview`; // act-preview
    }, 200);
  }

  closeModal() {
    this.dialogRef.close(false);
  }
  generateHash(e?) {
    if (e) {
      e.preventDefault();
    }

    if (!this.dateHash) {
      return;
    }
    this.billService
      .getHash(this.dialogData.bill.id, this.dateHash)
      .subscribe((response: any) => {
        this.linkFile = `${environment.apiUrl}/bills/${response.hash}/${this.dialogData.rightState}-preview`; // act-preview
      });
  }

  copyLink(element) {
    if (this.linkFile) {
      element.select();
      document.execCommand('copy');
      element.setSelectionRange(0, 0);
      this.toastrService.success(this.translate.instant('common.copyLink'));
      setTimeout(() => {
        document.getElementById('link-Node').blur();
      }, 0);
    }
  }
  changeDate() {
    this.linkFile = '';
  }
}
