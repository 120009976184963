// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container.checkbox-container label {
  display: inline-flex;
}
.input-container.checkbox-container label .j2-icon-info {
  margin-left: 5px;
}

.modal-template .modal-body {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/modals/default-modals/settings-library-modal/library-create-modal/library-create-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAIE;EACE,UAAA;AADJ","sourcesContent":[".input-container.checkbox-container label {\n  display: inline-flex;\n\n  .j2-icon-info {\n    margin-left: 5px;\n  }\n}\n\n.modal-template {\n  .modal-body {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
