import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  GetCountUnreadNotifications,
  GetNotifications,
  IsReadNotification,
} from '@app/modules/layout/notifications/store/actions/notification.actions';
import {
  selectCurrentPageNotification,
  selectNotifications,
} from '@app/modules/layout/notifications/store/selectors/notification.selectors';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  NotificationSettingsModalComponent,
} from '@app/commonComponents/modals/default-modals/notification-settings-modal/notification-settings-modal.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { SwitchNotificationsService } from '@app/helpers';
import { ChatService } from '../discussion/services/chat.service';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { take } from 'rxjs/operators';
import { NotificationEffects } from './store/effects/notification.effect';
import { CommunicationPublicService } from '../discussion/services/communication.service';
import { CommunicationService } from '@app/modules/reusable/jusnote-connect/services/communication.service';
import { TasksEventsModalComponent } from '@app/commonComponents/wide-modals/task-event-modal/t-e-modal.component';
import { NotificationsService } from './services/notifications.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public messages;
  public decisionJuscourtUrl = environment.decisionJuscourtUrl;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    public switchNotificService: SwitchNotificationsService,
    private store: Store<IAppState>,
    public chatService: ChatService,
    private notificationEffects: NotificationEffects,
    private notificationServices: NotificationsService,
    private communicationPublicService: CommunicationPublicService,
    private communicationService: CommunicationService,
  ) {
    notificationEffects.getNotificatios$.subscribe(() => this.showLoader = false);
  }

  readonly spinnerName = 'notifications';
  onlyNotread = false;
  // for tracker
  endSubscribe = false;
  showLoader = false;

  ngOnInit() {
    this.store.select(selectNotifications).subscribe(mess => {
      this.messages = mess;
    });
  }

  getRoute(event) {
    const goRoute = event.target.getAttribute('[routerLink]');
    if (event.target.getAttribute('[routerlink]')) {
      this.router.navigate([goRoute]);
      this.switchNotificService.switchNotifications('disable');
    }
  }

  isRead(message, id) {
    this.store.dispatch(new IsReadNotification({ id, message }));
  }

  openNotificationSettingsModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'notification-settings-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(NotificationSettingsModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  closeNotifications() {
    this.switchNotificService.switchNotifications('disable');
  }

  goToMsg(msg) {

    switch (msg.comment.entityName) {
      case 'bill':
        this.router.navigate([`/bills/card/${msg.comment.entityId}`], {queryParams: {chat: true}});
        break;
      case 'correspondence':
        this.router.navigate([`/correspondence/card/${msg.comment.entityId}`], {queryParams: {chat: true}});
        break;
      case 'document':
        this.router.navigate([`/documents/card/${msg.comment.entityId}`], {queryParams: {chat: true}});
        break;
      case 'activity':
        this.router.navigate([`/activities/card/${msg.comment.entityId}`], {queryParams: {chat: true}});
        break;
      case 'task':
        this.router.navigate([`/tasks/card/${msg.comment.entityId}`], {queryParams: {chat: true}});
        break;
      default:
        this.router.navigate([`/${msg.comment.entityName}/${msg.comment.entityId}`], {queryParams: {chat: true}});

    }


    this.switchNotificService.switchNotifications('disable');
  }


  onScrollingToEnd() {
    this.showLoader = true;
    let page = 2;
    this.store
      .select(selectCurrentPageNotification)
      .pipe(take(1))
      .subscribe(res => {
        page = res;
        this.getNotifications(page, 10);
      });
  }

  getNotifications(page = 2, itemsPerPage = 10, renew = false) {
    const filters = this.onlyNotread ? { isRead: (!this.onlyNotread).toString() } : {};

    this.store.dispatch(new GetNotifications({ page, itemsPerPage, renew, filters }));
  }

  createNewEvent(msg) {
    this.eventsModal(msg);
  }

  eventsModal(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'events-modal'];
    dialogConfig.disableClose = true;
    msg.courtSession.matter = { name: msg.data.matterName, id: msg.data.matterId };

    dialogConfig.data = { courtSession: msg.courtSession, type: 'event' };

    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getNotifications(1, 20, true);
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  viewEvent(task) {
    this.router.navigate([`/tasks/card/${task.id}`]);
  }

  openCommunicationInfo(communication) {
    this.router.navigateByUrl(`/contact/${communication.userPortalContactId}/jusnote-connect/all`);
    this.communicationService.getCommunicationById(communication.id).subscribe(resp => {
      this.switchNotificService.switchNotifications('communication');
      setTimeout(() => {
        this.communicationPublicService.setCommunication(resp);
      });
    });
  }

  checkVisibleCustomTemplate(msg) {
    return msg?.type === 'document_signed' || msg?.comment?.entityName === 'communication';
  }

  changeOnlyNotRead() {
    this.onlyNotread = !this.onlyNotread;
    this.getNotifications(1, 10, true);
  }

  readAll() {
    const reqs = [];
    for (let i = 0; i < this.messages.length; i++) {
      if (!this.messages[i].isRead) {
        reqs.push(this.notificationServices.readNotification(this.messages[i].id, { isRead: true }));
      }
    }
    forkJoin(reqs).subscribe(() => {
      this.store.dispatch(new GetCountUnreadNotifications());
      this.getNotifications(1, 10, true);
    });
  }
}
