import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap, catchError, map, share } from 'rxjs/operators';
import {
  GetNotes,
  NoteActionsTypes,
  GetNotesSuccess,
  GetNote,
  GetNoteSuccess,
  AddNote,
  AddNoteSuccess,
  UpdateNote,
  UpdateNoteSuccess,
  DeleteNote,
  DeleteNoteSuccess,
  DeleteNoteFailure,
  UpdateNoteFailure,
  AddNoteFailure,
  GetNoteFailure,
  GetNotesFailure
} from '../actions/note.actions';
import { of } from 'rxjs';
import { Note } from '@app/models/note';
import { MatterNoteService } from '@app/modules/matter-card/services/matter-note.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NoteEffects {
  
  getNotes$ = createEffect(() => this.actions$.pipe(
    ofType<GetNotes>(NoteActionsTypes.GET_NOTES),
    switchMap(action => {
      if (action.payload == null) {
        return [];
      }
      return this.noteService.getAll(action.payload).pipe(
        mergeMap(resp => {
          return [new GetNotesSuccess(resp)];
        }),
        catchError(error => of(new GetNotesFailure(error)))
      );
    }),
    share()
  ));
  
  getNote$ = createEffect(() => this.actions$.pipe(
    ofType<GetNote>(NoteActionsTypes.GET_NOTE),
    switchMap(action => {
      return this.noteService.getNoteById(action.payload).pipe(
        mergeMap(resp => {
          return [new GetNoteSuccess(resp as Note)];
        }),
        catchError(error => of(new GetNoteFailure(error)))
      );
    }),
    share()
  ));

  
  getNoteSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<GetNoteSuccess>(NoteActionsTypes.GET_NOTE_SUCCESS),
    map(action => {
      return action.payload;
    })
  ), { dispatch: false });

  
  addNote$ = createEffect(() => this.actions$.pipe(
    ofType<AddNote>(NoteActionsTypes.ADD_NOTE),
    switchMap(action => {
      return this.noteService.addNote(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('noteAdded'));
            return of(new AddNoteSuccess(x));
          }
          return of(new AddNoteFailure(new Error()));
        }),
        catchError(error => of(new AddNoteFailure(error)))
      );
    })
  ));

  
  addNoteSuccess$ = createEffect(() => this.actions$.pipe(ofType<AddNoteSuccess>(NoteActionsTypes.ADD_NOTE_SUCCESS)), { dispatch: false });

  
  updateNote$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateNote>(NoteActionsTypes.UPDATE_NOTE),
    switchMap(action => {
      return this.noteService.updateNote(action.payload).pipe(
        mergeMap(x => {
          if (x) {
            this.toastrService.success(this.translate.instant('noteEdited'));
            return of(new UpdateNoteSuccess(x));
          }
          return of(new UpdateNoteFailure(new Error()));
        }),
        catchError(error => of(new UpdateNoteFailure(error)))
      );
    })
  ));

  
  deleteNote$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteNote>(NoteActionsTypes.DELETE_NOTE),
    switchMap(action => {
      return this.noteService.deleteNote(action.payload).pipe(
        mergeMap(() => {
          return of(new DeleteNoteSuccess(action.payload));
        }),
        catchError(error => of(new DeleteNoteFailure(error)))
      );
    })
  ));

  
  deleteNoteSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteNoteSuccess>(NoteActionsTypes.DELETE_NOTE_SUCCESS),
    switchMap(() => {
      this.toastrService.error(this.translate.instant('noteDeleted'));
      return of(new GetNoteSuccess(null));
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private noteService: MatterNoteService,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}
}
