import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-change-modal',
  templateUrl: './password-change-modal.component.html',
  styleUrls: ['./password-change-modal.component.scss']
})
export class PasswordChangeModalComponent implements OnInit {
  passwordChangeForm: UntypedFormGroup;
  submittedChangePassForm = false;

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private teamMemberService: TeamMemberService,
  ) {}

  ngOnInit() {
    this.createForm();
  }

  onSubmit() {
    this.submittedChangePassForm = true;
    this.passwordChangeForm.updateValueAndValidity();
    if (!this.passwordChangeForm.valid) {
      return;
    }

    delete this.passwordChangeForm.value.confirmNewPassword;
    this.teamMemberService.changePassword(this.passwordChangeForm.value).pipe(take(1)).subscribe(() => {
      this.toastrService.success(this.translate.instant('passwordChanged'));
      this.closeModal();
    })
  }

  checkPasswords(group: UntypedFormGroup): ValidationErrors | null {
    const pass = group.get('password');
    const confirmPass = group.get('confirmNewPassword');
    return pass && confirmPass && pass.value === confirmPass.value ? null : { notSame: true };
  }

  closeModal() {
    this.dialogRef.close();
  }

  private createForm() {
    this.passwordChangeForm = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[0-9a-zA-Z_\\-~!@#\\$%\\^&]{6,}$')
          ]
        ],
        confirmNewPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
      },
      { validator: this.checkPasswords }
    );
  }

  get f(): UntypedFormGroup {
    return this.passwordChangeForm;
  }

  get p() {
    return this.passwordChangeForm.controls;
  }
}
