import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdditionalMatterComponent } from '@app/commonComponents/additional-matter/additional-matter.component';
import { BillType } from '@app/enums/bills/bill-type';
import { SharedEnumService } from '@app/enums/shared-enum.service';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { Helpers } from '@app/helpers/helpers';
import { Contact } from '@app/models';
import { CompanySettings } from '@app/models/company-settings';
import { MatterExtended } from '@app/models/matter-extended';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { LoadContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { selectContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { BillService } from '@app/modules/main-bills/services/bill.service';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { LoadCompanySettingAction } from '@app/modules/settings/store/actions/company-setting.actions';
import { selectCompanySetting } from '@app/modules/settings/store/selectors/company-setting.selector';
import { CreateBill } from '@app/store/actions/bill.actions';
import { BillEffects } from '@app/store/effects/bill.effects';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Subscription } from 'rxjs';

import { BillCreateDebounceEnum } from '../../../../enums/debounce-keys.enum';
import { DebounceService } from '../../../../helpers/debounce.service';
import { PerfectScrollbarComponent } from '@app/perfect-scrollbar/perfect-scrollbar.component';

@Component({
  selector: 'app-bill-create-modal',
  templateUrl: './bill-create-modal.component.html',
  styleUrls: ['./bill-create-modal.component.scss'],
})
export class BillCreateModalComponent implements AfterViewInit, OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<BillCreateModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private translate: TranslateService,
    private store: Store<IAppState>,
    private matterService: MatterService,
    private billService: BillService,
    private billEffects: BillEffects,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contact?: Contact;
      matter?: MatterExtended;
    },
    private appSettingsService: AppSettingsService,
    private debounceService: DebounceService,
  ) {
    this.billPaymentFee = this.sharedData.enumTypeFee;
  }

  get isPostpay() {
    return this.billForm.get('typePay').value === BillType.postpaid;
  }

  get validAmount() {
    return !this.isFixed || this.billForm.get('amountTimeEntry').value > 0;
  }

  get isFixed() {
    return this.billForm.get('typeFee').value === 'fixed';
  }

  get f() {
    return this.billForm;
  }

  get minDateValue() {
    return this.minDate || CustomDateAdapter.startDate;
  }

  currencies = Helpers.getCurrenciesList();

  enabled = false;
  billForm: UntypedFormGroup;
  companySettings: CompanySettings;
  clients: Contact[] = [];
  matters: MatterExtended[];
  additionListMatters: MatterExtended[] = [];
  sharedData = new SharedEnumService(this.translate);

  dateFormat = CustomDateAdapter.dateFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;
  minDate = new Date();
  modalSwitch: boolean;
  billPaymentFee;
  formSubmitted = false;

  previouslyTotal;
  previouslyCurrency;
  ifSelectedMatter = false;
  loaderForPreviouslyTotal = true;
  private createdSubscription: Subscription;
  public locale$ = this.appSettingsService.getLang;

  @ViewChild('cbillPerfectScroll', { static: false }) perfectScroll: PerfectScrollbarComponent;
  @ViewChild('additionalMatters', { static: false }) additionalMattersComponent: AdditionalMatterComponent;


  changeTypeFeePostPay = 'hourly';
  filtersMatters = {
    name: '',
    'contact.id': null,
    status: 'open',
  };
  public sorting = 'Ascending';

  filtersContacts = {
    'order[fullName]': 'ASC',
    name: '',
  };
  @ViewChild('contactsComboBox', { static: false }) public comboBoxContact: ComboBoxComponent;
  checkClickSubmit = false;

  changeTypeFee(e) {
    if (e.itemData.value === 'hourly') {
      this.f.get('amountTimeEntry').clearValidators();
    } else {
      setTimeout(() => {
        this.f.get('amountTimeEntry').setValidators([Validators.max(9999999999.99), Validators.required]);
      });
    }
    this.f.get('amountTimeEntry').updateValueAndValidity();

    this.scrollToField();
  }

  ngOnInit() {
    this.createForm();
    if (!this.data.matter || !this.data.contact) {
      this.matters = [this.data.matter];
      this.clients = [this.data.matter?.contact];
      if (this.data.matter?.contact) {
        this.ifFromContact([this.data.matter?.contact]);
      }

      if (!!this.data.matter) {
        const dates = {
          dateFrom: this.billForm.get('dateFrom').value,
          dateTo: this.billForm.get('dateTo').value,
        };
        this.ifSelectedMatter = true;
        this.billService
          .getPreviouslyTotal(this.data.matter.currencyInvoice.code, this.matters.map(item => item.id), dates).subscribe(res => {
          this.loaderForPreviouslyTotal = false;
          if (res.amount) {
            this.previouslyTotal = res.amount;
            this.previouslyCurrency = res.currency;
          }
        });
      }
    }

    this.modalSwitch = this.data?.matter?.typePay === 'postpay';

    this.createdSubscription = this.subscribeToCreatedEffect();
    setTimeout(() => {
      this.perfectScroll?.directiveRef.update();
    }, 500);

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadContacts();
    });
  }

  scrollToField() {
    setTimeout(() => {
      this.perfectScroll.directiveRef.scrollToElement('#bill-amountTimeEntry', -30, 300);
    }, 50);
  }

  ngOnDestroy() {
    this.createdSubscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  onSubmit() {
    this.formSubmitted = true;
    let matter = this.additionalMattersComponent.form.get('mainMatter').value;
    this.billForm.get('matter').setValue(matter);
    if (this.data && this.data.matter) {
      matter = this.data.matter.id;
      this.billForm.get('matter').setValue(matter);
    }

    if (this.billForm.invalid) {
      return;
    }
    this.billForm.enable();
    const data = { ...this.billForm.value };
    data.matter = matter ? matter : null;

    data.additionalMatters = this.additionalMattersComponent.form.get('additionalMatters').value.filter(id => id !== matter);
    if (data.typePay === 'postpay' && data.typeFee === 'hourly') {
      data.amountTimeEntry = '0';
      this.billForm.removeControl('amountTimeEntry');
      if (!this.billForm.valid) {
        this.billForm.addControl('amountTimeEntry', this.formBuilder.control('', [Validators.max(9999999999.99), Validators.required]));
      }
    }

    if (this.billForm.valid && this.validAmount && !this.checkClickSubmit) {
      this.billForm.get('matter').disable();
      this.billForm.get('client').disable();

      this.checkClickSubmit = true;
      data.dateInvoice = CustomDateAdapter.convertToUniversalDateString(data.dateInvoice);
      data.dateExpire = CustomDateAdapter.convertToUniversalDateString(data.dateExpire);
      if (data.dateFrom) {
        data.dateFrom = CustomDateAdapter.convertToUniversalDateString(data.dateFrom);
      }
      if (data.dateTo) {
        data.dateTo = CustomDateAdapter.convertToUniversalDateString(data.dateTo);
      }

      data.amountTimeEntry = data.amountTimeEntry.toString();
      this.store.dispatch(new CreateBill(data));
    }
  }

  modalSwitchToggle(state): void {
    // const PANEL_CLASS = 'bill-create-payment-modal';
    if (!state) {
      this.changeTypeFeePostPay = this.billForm.get('typeFee').value;
      this.billForm.get('matter').clearValidators();

      this.billForm.patchValue({
        typePay: BillType.prepaid,
        typeFee: 'fixed',
        description: this.data?.matter?.descriptionPostPay,
        currency: this.companySettings.currency.code,
      });

      this.billForm.get('amountTimeEntry').setValidators([Validators.required]);
      this.billForm.get('amountTimeEntry').updateValueAndValidity();

    } else {
      // this.dialogRef.addPanelClass(PANEL_CLASS);
      this.billForm.get('matter').setValidators([Validators.required]);

      this.billForm.patchValue({
        typePay: BillType.postpaid,
        typeFee: this.changeTypeFeePostPay,
        descriptionPrePay: this.data?.matter?.descriptionPrePay,
      });

      if (this.billForm.get('amountTimeEntry') && this.billForm.get('typeFee').value === 'hourly') {
        this.billForm.get('amountTimeEntry').clearValidators();
        this.billForm.get('amountTimeEntry').updateValueAndValidity();
      }
      this.billForm.get('dateFrom').patchValue(null);
      this.billForm.get('dateTo').patchValue(null);

    }
    this.billForm.get('matter').updateValueAndValidity();
    this.modalSwitch = state;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  changeDate(event) {
    this.minDate = (event && event.value) || new Date();
  }

  changeMatter(matter) {
    if (!matter) {
      this.ifSelectedMatter = false;
      this.billForm.patchValue({
        currency: this.getCurrence(this.companySettings.currency),
        descriptionPrePay: '',
        detailedDescription: false,
        description: '',
        lang: this.companySettings.lang,
      });
    } else {
      this.loaderForPreviouslyTotal = true;
      this.ifSelectedMatter = true;
      this.billForm.patchValue({
        amountTimeEntry: matter.amount,
        currency: matter.currencyInvoice.code,
        descriptionPrePay: matter.descriptionPrePay,
        detailedDescription: matter.detailedDescription,
        description: matter.descriptionPostPay,
        lang: matter.lang,
      });

      if (this.modalSwitch) {
        this.billForm.patchValue({
          typeFee: matter.typeFee,
        });
      }


      setTimeout(() => {
        const mattersIds = this.additionalMattersComponent.form.get('additionalMatters').value.map(el => el);
        mattersIds.push(this.additionalMattersComponent.selectedMainMatter.id);
        if (mattersIds.length) {
          const dates = {
            dateFrom: this.billForm.get('dateFrom').value,
            dateTo: this.billForm.get('dateTo').value,
          };

          this.billService.getPreviouslyTotal(matter.currencyInvoice.code, mattersIds, dates).subscribe(res => {
            this.loaderForPreviouslyTotal = false;
            if (res.amount) {
              this.previouslyTotal = res.amount;
              this.previouslyCurrency = res.currency;
            }
          });
        } else {
          this.loaderForPreviouslyTotal = false;
        }
      }, 100);
    }
  }

  changeDates(dates) {
    this.billForm.get('dateFrom').patchValue(dates ? dates.dateFrom : null);
    this.billForm.get('dateTo').patchValue(dates ? dates.dateTo : null);
    setTimeout(() => {
      this.changeMatter(this.additionalMattersComponent.selectedMainMatter);
    });
  }

  detailedDescription(event) {
    this.billForm.get('detailedDescription').patchValue(event.target.checked);
  }

  selectClient(event) {
    this.billForm.get('currency').patchValue(this.companySettings.currency.code);
    if (this.additionalMattersComponent) {
      this.additionalMattersComponent.changeClient(event?.id);
    }
    if (event) {
      this.filtersMatters['contact.id'] = event.id;
      this.getMatters();
    }
  }

  compareTwoDates() {
    const dateInvoice = this.billForm.get('dateInvoice');
    const dateExpire = this.billForm.get('dateExpire');

    if (new Date(dateInvoice.value) > new Date(dateExpire.value)) {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() + this.companySettings.defaultPayCountDays);

      dateExpire.patchValue(newDate);
    }
  }

  private subscribeToCreatedEffect(): Subscription {
    return this.billEffects.create$.subscribe(bill => {
      this.closeModal();
      this.router.navigate([`bills/card/${bill.payload.id}`]);
    });
  }

  ifFromContact(contacts) {
    if (this.data && this.data.contact) {
      // якщо в селекті немає контакту, з якого ми сюди перейшли, то
      // ми його добавляємо в селект, і тільки після того присвоюємо в
      // селект значення
      if (!contacts.find(x => x.id === this.data.contact.id)) {
        this.clients.push(this.data.contact);
      }

      this.billForm.patchValue({
        client: this.data.contact.id,
      });
      this.billForm.get('client').disable();
      this.billForm.get('matter').enable();
      this.selectClient(this.data.contact);
    }

    if (this.data && this.data.matter) {
      // якщо в селекті немає контакту, з якого ми сюди перейшли, то
      // ми його добавляємо в селект, і тільки після того присвоюємо в
      // селект значення
      if (!contacts.find(x => x.id === this.data.matter.contact && this.data.matter.contact.id)) {
        this.clients.push(this.data.matter.contact);
      }
      if (this.data.matter.contact) {
        this.selectClient(this.data.matter.contact);
        this.billForm.patchValue({
          client: this.data.matter.contact.id,
        });
      }

      // tslint:disable-next-line:no-unused-expression
      this.data.matter.contact && this.billForm.get('client').disable();
      this.ifFromMatter();
    }
  }

  ifFromMatter() {
    if (this.data && this.data.matter) {
      // якщо в селекті немає контакту, з якого ми сюди перейшли, то
      // ми його добавляємо в селект, і тільки після того присвоюємо в
      // селект значення
      if (!(this.matters && this.matters.find(x => x.id === this.data.matter.id))) {
        this.matters ? this.matters.push(this.data.matter) : this.matters = [this.data.matter];
      }

      this.modalSwitchToggle(this.data.matter.typePay === 'postpay');
      if (this.data.matter.typePay === 'postpay') {
        this.billForm.patchValue({
          typePay: this.data.matter.typePay,
          typeFee: this.data.matter.typeFee,
          amountTimeEntry: this.data.matter.amount === '0.00' ? null : this.data.matter.amount,
          currency: this.data.matter.currencyInvoice.code,
          descriptionPrePay: this.data.matter.descriptionPrePay,
          detailedDescription: this.data.matter.detailedDescription,
          description: this.data.matter.descriptionPostPay,
          lang: this.data.matter.lang,
        });
      }

      this.billForm.patchValue({
        matter: this.data.matter.id,
      });
      this.billForm.get('matter').disable();
    }
  }

  private getMatters() {
    this.matterService.get(environment.defaultPage, environment.countItemInSelect, this.filtersMatters).subscribe(x => {
      this.matters = x.member;
      this.ifFromMatter();
    });

    this.matterService.get(environment.defaultPage, 1000, { ...this.filtersMatters, 'groups[]': 'id' }).subscribe(x => {
      this.additionListMatters = x.member;
    });
  }

  private createForm(): void {
    this.billForm = this.formBuilder.group({
      dateInvoice: this.control(new Date(), true),
      dateExpire: this.control('', true),
      matter: this.control(null, true),
      executor: this.control(null, true),
      status: this.control('draft', true),
      description: this.control('', true),
      descriptionPrePay: this.control('', true),
      detailedDescription: this.control(false, true),
      expensesIncluded: this.control(false, true),
      amountTimeEntry: this.formBuilder.control(null, [Validators.required]),
      currency: this.control(''),
      typePay: this.control(BillType.prepaid),
      typeFee: this.control('fixed'),
      // number: this.control(''),
      lang: this.control('', true),
      client: this.control(''),
      dateFrom: this.control(null, true),
      dateTo: this.control(null, true),
    });
    this.getCompanySettings();
    this.billForm.get('matter').disable();
    this.billForm.get('dateExpire').setValidators([Validators.required]);
    this.billForm.get('dateInvoice').setValidators([Validators.required]);
  }

  private getCompanySettings(): void {
    this.store.select(selectCompanySetting).subscribe(x => {
      if (x) {
        this.companySettings = x;
        const dateExpire = new Date();
        dateExpire.setDate(dateExpire.getDate() + x.defaultPayCountDays);

        this.billForm.patchValue({
          dateExpire,
          currency: x.currency.code,
          lang: x.lang,
          executor: x.payer.id,
          description: this.modalSwitch ? this.data?.matter?.descriptionPrePay : this.data?.matter?.descriptionPostPay,
          descriptionPrePay: this.data?.matter?.descriptionPrePay,
        });

        // this.setUpNumber();
        if (!x.isManyCurrency) {
          this.enabled = true;
        }
      } else {
        this.store.dispatch(new LoadCompanySettingAction());
      }
    });
  }

  getCurrence(currency) {
    return currency.code;
  }

  private control(value, optional = false): UntypedFormControl {
    return this.formBuilder.control(value, optional ? [] : Validators.required);
  }

  loadContacts() {
    this.createdSubscription = this.store.select(selectContactListForSelect).subscribe(data => {
      if (!data) {
        this.store.dispatch(new LoadContactsForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
      }
      if (data) {
        this.clients = data;
        this.ifFromContact(data);
      }
    });
  }

  public onFilteringContacts: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersContacts.name = e.text;
    this.comboBoxContact?.showSpinner();

    this.debounceService.loadDataDebounce(
      BillCreateDebounceEnum.contacts,
      this.contactService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersContacts]);
    this.debounceService.getValue(BillCreateDebounceEnum.contacts).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.clients = resp?.member as any;
      this.comboBoxContact?.hideSpinner();
    });
  };

  fixAmount(event) {
    setTimeout(() => {
      let temp = event.target.value;
      const currentValue = temp.split('.');
      if (currentValue.length > 2) {
        currentValue.pop();
        temp = currentValue[0] + '.' + currentValue[1];
      }
      const countAfter = currentValue[currentValue.length - 1].length;
      if (temp.includes('.') && countAfter > 2) {
        if (currentValue[1][0] > 9) {
          temp = currentValue[0] + '.' + '9' + currentValue[1][1];
        } else {
          temp = currentValue[0] + '.' + currentValue[1][0] + currentValue[1][1];
        }
      }
      this.billForm.get('amountTimeEntry').setValue(temp);
    }, 200);

    if ((event.which >= 48 && event.which <= 57) || event.which === 46) {
      return true;
    }
    return false;
  }
}
